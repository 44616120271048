<template>
<div> 
    <div v-if="type=='input_text'">
        <p class="mb-1 f-90 fw-500">Input text</p>
        <b-form-input v-model="structure.pre_text" placeholder="입력폼 앞쪽에 나타날 텍스트" @update="handleChange"></b-form-input>
        <b-form-input v-model="structure.placeholder" placeholder="텍스트 입력 가이드" @update="handleChange"></b-form-input>
        <b-form-input v-model="structure.after_text" placeholder="입력폼 뒤쪽에 나타날 텍스트" @update="handleChange"></b-form-input>
    </div>

    <div v-else-if="type=='input_number'" class="">
        <p class="mb-1 f-90 fw-500">Input number</p>
        <b-form-input v-model="structure.pre_text" placeholder="입력폼 앞쪽에 나타날 텍스트" @update="handleChange"></b-form-input>
        <b-form-input v-model="structure.placeholder" placeholder="텍스트 입력 가이드" @update="handleChange"></b-form-input>
        <b-form-input v-model="structure.after_text" placeholder="입력폼 뒤쪽에 나타날 텍스트" @update="handleChange"></b-form-input>
    </div>
    
    <div v-else-if="type=='checkbox'">
        <b-table-simple class="bor-table f-95">
            <b-thead><b-tr>
                <b-td> text </b-td><b-td> value </b-td> <b-td> description </b-td> <b-td> 삭제 </b-td>
            </b-tr></b-thead>
            <b-tbody>
            <b-tr v-for="(item, index) in structure" :key="item.id">
                <b-td><b-form-input v-model="item.text" @update="handleChange">{{ item.text }}</b-form-input> </b-td>
                <b-td><b-form-input v-model="item.value" @update="handleChange">{{ item.value }}</b-form-input></b-td>
                <b-td><b-form-checkbox v-model="is_description_available[index]" @input="manageDescriptionAvailability(item, index)" value="true" unchecked-value="false"> 
                        <b-form-input :disabled="!is_description_available[index]" placeholder="상세 내용 폼에 표시될 가이드텍스트를 입력해 주세요" v-model="item.placeholder" @update="handleChange">
                            {{ item.placeholder }}
                        </b-form-input>
                    </b-form-checkbox>
                </b-td>
                <b-td>
                    <b-button @click="deleteCheckboxItem(index)" class="" size="sm"><fa icon="trash-alt"></fa></b-button>
                </b-td>
            </b-tr>
            </b-tbody>
        </b-table-simple>
        <div class="text-right">
            <b-button @click="addCheckboxItem()" variant="blue" size="sm">체크박스 선택지 추가</b-button>
        </div>
    </div>

    <div v-else-if="type=='table'">
        <b-form-input v-model="table_header" placeholder="ex. 구분, FY2017, FY2018, FY2019" class="">{{table_header}}</b-form-input>
        <b-table-simple class="bor-table f-95"> 
            <b-tbody>
            <b-tr>
                <b-td></b-td>
                <b-td v-for="(col_item, index) in table_header_col" :key="col_item.id"> {{ alphabet[index] }} </b-td>
                <b-td>관리</b-td>
            </b-tr>
            <b-tr>
                <b-td></b-td>
                <b-td v-for="col_item in table_header_col" :key="col_item.id">{{ col_item }}</b-td>
                <b-td></b-td>
            </b-tr>
            <b-tr v-for="(row_item, row_index) in structure" :key="row_item.id">
                <b-td> {{ row_index + 1 }} </b-td>
                <b-td v-for="(col_item, col_index) in table_header_col" :key="col_item.id">
                    <div @click="editCell(row_item, col_item, row_index, col_index)"> 
                        <div v-if="row_item.noteditables && row_item.noteditables.includes(col_item)" v-html="row_item[col_item]"></div>
                        <div v-else-if="row_item.dropdown && row_item.dropdown[col_item]">
                            <b-dropdown :id="`dropdown-${item_idx}-${element_idx}-${row_index}-${col_index}`" :text="row_item[col_item]">
                                <b-dropdown-item v-for="drop_item in row_item.dropdown[col_item]" :key="drop_item.id">{{ drop_item }}</b-dropdown-item>
                                <b-dropdown-item-button style="font-size:0.8em; color:#FFFFFF; background-color:#0033FF" @click="editCell(row_item, col_item, row_index, col_index)">수정</b-dropdown-item-button>
                            </b-dropdown>
                        </div>
                        <div v-else-if="row_item.calculation && row_item.calculation[col_item]">
                            ( {{ row_item.calculation[col_item] }} )
                        </div>
                        <div v-else> 
                            <b-form-input v-model="row_item[col_item]" :disabled="true">{{row_item[col_item]}}</b-form-input> 
                        </div>
                    
                        <!-- 셀 에디터 모달 --> 
                        <b-modal @ok="handleCellEditorOK($event, row_item, col_item, row_index, col_index)" title="테이블 셀 설정" :ref="`table-cell-editor-${item_idx}-${element_idx}-${row_index}-${col_index}`" :id="`table-cell-editor-${item_idx}-${element_idx}-${row_index}-${col_index}`">
                       
                            <b-form-select v-model="option_cell_selected" class="mb-3">
                                <option value="input_text">텍스트 입력 폼</option> 
                                <option value="dropdown">드롭다운 선택지</option> 
                                <option value="text">수정불가 텍스트</option>
                                <option value="calculation">계산식</option>
                            </b-form-select>
                               
                            <!-- 텍스트 입력 폼일 경우 -->
                            <div v-if="option_cell_selected=='input_text'" class="d-flex justify-content-between editing-dfs">
                                기본 값
                                <b-form-input placeholder="미리 입력될 기본 값을 입력해주세요" v-model="row_item[col_item]" class="w-75">{{ row_item[col_item] }}</b-form-input>
                            </div>
                            <div v-if="option_cell_selected=='input_text'" class="d-flex justify-content-between editing-dfs">
                                가이드텍스트
                                <b-form-input placeholder="입력 가이드를 입력해주세요" v-model="row_item.placeholder[col_item]" class="w-75"> {{ row_item.placeholder[col_item] }}</b-form-input>
                            </div>

                            <!-- 드롭다운 선택지일 경우 -->
                            <div v-if="option_cell_selected=='dropdown'" class="mb-3 editing-dfs">
                                선택지
                                <b-form-input placeholder="선택지 항목을 쉼표로 구분하여 입력해주세요" v-model="dropdown" @update="updateCellDropdown(row_item,col_item,row_index,col_index)" ></b-form-input>
                            </div>
                            <div v-if="option_cell_selected=='dropdown'" class="d-flex justify-content-between align-items-center editing-dfs p-2 border rounded f-90 ">
                                미선택시 기본 값:
                                <b-form-input placeholder="기본값을 입력해 주세요" v-model="row_item[col_item]" class="w-75"></b-form-input>
                            </div>

                            <!-- 텍스트 선택지일 경우 -->
                            <div v-if="option_cell_selected=='text'" class="editing-dfs">
                                표시 텍스트
                                <b-form-input placeholder="셀에 직접 표시될 내용을 입력해 주세요" v-model="row_item[col_item]" >{{row_item[col_item]}}</b-form-input>
                            </div>

                            <!-- 계산식일 경우 -->
                            <div v-if="option_cell_selected=='calculation'"  class="editing-dfs">
                                계산식
                                <b-form-input :state="calculation_status" placeholder="계산식을 입력해 주세요(ex. A1+B1)" v-model="calculation_string"> {{ calculation_string }} </b-form-input>
                                {{ calculation_status_message }}
                            </div>
                        </b-modal>                      
                    </div>
                </b-td>
                <b-td>
                    <b-button variant="icon" size="sm" @click="addDuplicatedRow(row_item, row_index)">복제</b-button>
                    <b-button variant="icon" size="sm" @click="deleteRow(row_item, row_index)">삭제</b-button>
                </b-td>
            </b-tr>
            </b-tbody>
        </b-table-simple>
    
    </div>

    <div v-else>
        <b-dropdown :text="type" :disabled="type!='세부요소 선택'" variant="dark">
            <b-dropdown-item @click="selectType('input_text')">input_text</b-dropdown-item>
            <b-dropdown-item @click="selectType('input_number')">input_number</b-dropdown-item>
            <b-dropdown-item @click="selectType('checkbox')">checkbox</b-dropdown-item>
            <b-dropdown-item @click="selectType('table')">table</b-dropdown-item>
        </b-dropdown>
    </div>
    
</div>
</template>
<script>
import * as ih from '@/util'

export default {
    data(){
        return {
            type: '세부요소 선택', 
            result: '',
            structure: [],
            table_header: '',
            
            is_description_available: [],

            option_cell_selected: '',
            alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
            
            dropdown: '',

            calculation_string: '',
            calculation_validated_str: '',
            calculation_status: false,
            calculation_status_message: ''
            
            //type: 'input_text', // input_number, input_text
            //result: '',
            //structure: {
            //    pre_text: '시험삼아',
            //    after_text: '해보았습니다',
            //    placeholder: '입력',
            //}

            //type: 'checkbox', // checkbox
            //result: [],
            //structure: [
            //     {
            ///         "text": "이사회 의장이 독립이사임", 
            //          "value": "이사회 의장이 독립이사", 
            //          "description": ""
            //      }, 
            //      {
            //          "text": "이사회 의장이 전 CEO 또는 전 의장임", 
            //          "value": "이사회 의장이 전 CEO 또는 전 의장", 
            //          "description": ""
            //      }, 
            //      {
            //          "text": "이사회 의장이 기타비상무이사임", 
            //          "value": "이사회 의장이 기타비상무이사", 
            //          "description": ""
            //      }, 
            //      {
            //          "text": "이사회 의장이 CEO 또는 사내이사임", 
            //          "value": "이사회 의장이 CEO 또는 사내이사", 
            //          "description": ""
            //      }
            //  ]

            //type: 'table'
            //result: '',
            //table_header: '구분, FY2017, FY2018, FY2019, 단위'
            //structure: [
            //     {
            //          "구분": "산업재해도수율(LTIFR)-직접고용"
            ///         "FY2017": "0",
            //          "FY2018": "0",
            //          "FY2019": "0",
            //          "단위": "0",
            //      },
            //  ]

        }
    },
    props:{
        item_idx: {type: Number},
        element_idx: {type: Number}, 
        element_item: { type: Object }
    },
    computed: {
        table_header_col: {
            get() {
                return ih.stringToArray(this.table_header)
            }
        }
    },
    methods:{
        handleChange(){
            let new_item = {
                type: this.type, 
                result: this.result, 
                structure: this.structure
            }
            if(this.type == 'table'){
                new_item.table_header = ih.stringToArray(this.table_header)
            }
            // console.log('event-emit' + this.item_idx + this.element_idx + new_item)
            this.$emit('change', this.item_idx, this.element_idx, new_item)
        },
        selectType(target){
            this.structure = {}
            if(target=='input_text' || target=='input_number'){
                let new_item = {
                    pre_text: '',
                    after_text: '',
                    placeholder: ''
                }
                this.structure = new_item
            }
            else if(target=='checkbox'){
                this.result = []
                this.structure = []
            }
            else if(target=='table'){
                this.result = []
                this.structure = [{
                    '구분': '값',
                    'FY2018': '',
                    'FY2019': '',
                    'FY2020': '',
                    dropdown: {},
                    placeholder: {},
                    noteditables: []
                }]
                this.table_header = '구분, FY2018, FY2019, FY2020'
            }
            this.type = target
            this.handleChange()
        },
        manageDescriptionAvailability(item, index){
            Vue.nextTick( () => {
                //해당 checkbox 아이템에 대해 description을 쓴다고 체크한 것이면
                if(this.is_description_available[index] == 'true'){
                    Vue.set(item, 'description', "")
                    Vue.set(item, 'placeholder', "")
                }
                //description을 쓰지 않는다고 체크한 것이면
                else {
                    delete item.description
                    delete item.placeholder
                }

                this.handleChange()

            })
        },
        addCheckboxItem(){
            let new_checkbox_item = {
                text: '',
                value: ''
            }
            this.structure.push(new_checkbox_item)
            this.handleChange()
        },
        deleteCheckboxItem(index){
            this.structure.splice(index,1)
            this.handleChange()
        },
        initiateDescriptionAvailability(){
            for (let i=0; i<this.structure.length; i++)
            {
                if( this.structure[i].hasOwnProperty('description')) this.is_description_available[i] = true
                else this.is_description_available[i] = false
            }
        },
        editCell(row_item, col_item, row_index, col_index){
            //window.alert(row_index + '행' + col_index + '열을 클릭했습니다.')

            //순차적으로 판단하여 현재 셀이 input_text인지, dropdown인지, noteditable text인지, calculation인지 구분하여 초기화
            this.option_cell_selected = 'input_text'
            if(row_item.hasOwnProperty('dropdown') && row_item.dropdown[col_item]) {
                this.option_cell_selected = 'dropdown'
                this.dropdown = ih.arrayToString(row_item.dropdown[col_item])
            }
            if(row_item.hasOwnProperty('calculation') && row_item.calculation[col_item]) {
                this.option_cell_selected = 'calculation'
                this.calculation_string = row_item.calculation[col_item]
            }
            if(row_item.hasOwnProperty('noteditables') && row_item.noteditables.includes(col_item)) {
                this.option_cell_selected = 'text'
            }
            this.$bvModal.show(`table-cell-editor-${this.item_idx}-${this.element_idx}-${row_index}-${col_index}`)
        },
        handleCellEditorOK(bvModalEvt, row_item, col_item, row_index, col_index){

            bvModalEvt.preventDefault()

            //순차적으로 판단하여 저장 전 cell 상태변경을 각 셀 데이터에 반영
            if(this.option_cell_selected == 'input_text'){
                if(row_item.dropdown) delete row_item.dropdown[col_item]
                if(row_item.noteditables){
                    let idx = row_item.noteditables.findIndex( item => { return item == col_item })
                    if (idx > -1) row_item.noteditables.splice(idx, 1)
                }
                if(row_item.calculation){
                    delete row_item.calculation[col_item]
                }
                Vue.nextTick( () => {
                    this.$bvModal.hide(`table-cell-editor-${this.item_idx}-${this.element_idx}-${row_index}-${col_index}`)
                })
            }
            else if(this.option_cell_selected == 'dropdown'){
                if(row_item.placeholder) delete row_item.placeholder[col_item]
                if(!row_item.dropdown) row_item.dropdown={}
                row_item.dropdown[col_item] = ih.stringToArray(this.dropdown)
                if(row_item.noteditables){
                    let idx = row_item.noteditables.findIndex ( item => { return item == col_item })
                    if (idx > -1) row_item.noteditables.splice(idx, 1)
                }
                if(row_item.calculation) delete row_item.calculation[col_item]
                
                Vue.nextTick( () => {
                    this.$bvModal.hide(`table-cell-editor-${this.item_idx}-${this.element_idx}-${row_index}-${col_index}`)
                })
            }
            else if(this.option_cell_selected == 'text'){
                if(row_item.placeholder) delete row_item.placeholder[col_item]
                if(row_item.dropdown) delete row_item.dropdown[col_item]
                if(row_item.calculation) delete row_item.calculation[col_item]
                if(!row_item.noteditables) row_item.noteditables = []
                row_item.noteditables.push(col_item)
                Vue.nextTick( () => {
                    this.$bvModal.hide(`table-cell-editor-${this.item_idx}-${this.element_idx}-${row_index}-${col_index}`)
                })
            }
            else if(this.option_cell_selected == 'calculation'){
                if(row_item.placeholder) delete row_item.placeholder[col_item]
                if(row_item.dropdown) delete row_item.dropdown[col_item]
                if(row_item.noteditables){
                    let idx = row_item.noteditables.findIndex( item => { return item == col_item })
                    if (idx > -1) row_item.noteditables.splice(idx, 1)
                }
                if(this.calculation_status != true){
                    return false
                }
                else{
                    if(!row_item.calculation) row_item.calculation = {}
                    row_item.calculation[col_item]=this.calculation_validated_str
                    Vue.nextTick( () => {
                        this.$bvModal.hide(`table-cell-editor-${this.item_idx}-${this.element_idx}-${row_index}-${col_index}`)
                    })
                }
            }
            else { 
                console.log('열행 번호에 맞는 내용이 없음')
            }
            this.handleChange()

        },
        updateCellDropdown(row_item, col_item, row_index, col_index){
            Vue.nextTick( () => {
                if(!row_item.dropdown) row_item.dropdown = {}
                row_item.dropdown[col_item] = ih.stringToArray(this.dropdown)
                this.handleChange()
            })
        },
        addDuplicatedRow(row_item, row_index){
            let new_item = ih.deepCopy(row_item)
            this.structure.splice(row_index, 0, new_item)
            this.handleChange()
        },
        deleteRow(row_item, row_index){
            this.structure.splice(row_index, 1)
            this.handleChange()
        }
    },
    created(){
        if(this.element_item.type!=undefined&&this.element_item.type!=''&&this.element_item.type!=null){
            this.type = this.element_item.type
        }
        this.result = this.element_item.result
        this.structure = this.element_item.structure
        this.table_header = ih.arrayToString(this.element_item.table_header)
        this.table_header_ea = ih.arrayToString(this.element_item.table_header_ea)
        this.table_header_ca = ih.arrayToString(this.element_item.table_header_ca)

        if(this.type == 'checkbox'){
            this.initiateDescriptionAvailability()
        }
    },
    updated(){
        // console.log(this.table_header);
        // console.log(this.table_header_col);

    }
}
</script>