<template>
<div class="py-5"> 
    <div class="mb-2 tail-line-dark-sm">{{$t(`남은 기간`)}} </div>
    <div v-if="check_campaign_completed!=true" class="f-90 mb-45" :class="left_days<1?'red':''"> 
        <span class="f-160">{{ left_days }}</span> {{$t(`일`)}} <span class="ml-1 f-160">{{left_time}}</span> {{$t(`시간`)}}
    </div>
    <div v-else class="f-90 mb-45 gray-550">
        {{$t(`- 답변기간이 아닙니다 -`)}}
    </div>

    <div class="mb-2 tail-line-dark-sm"> {{$t(`전체 제출 및 완료`)}}</div>
    <template v-if="is_progress_rate_loaded==true">
        <make-all-complete-button :btn_size="'sm'" :btn_variant="variant_by_filled_rate" class="mb-2" :disable_status="check_submitted==true||check_campaign_completed==true">
            <fa icon="flag" class="mr-1"></fa>{{check_submitted==false? $t(`최종 제출하기`): $t(`전체 제출됨`)}}
        </make-all-complete-button>
        <div v-if="check_submitted==false&&overall_filled==true&&check_campaign_completed!=true" class="f-85" :class="overall_filled==true?'red red-border':'gray-600'"> <fa icon="info-circle" class="mr-1"></fa>
            {{$t(`모든 문항에 답변하셨다면 전체 제출하기를 클릭해주세요.`)}}
        </div>
        <div  v-else-if="check_submitted==true&&check_campaign_completed!=true" class="f-85 gray-600"> <fa icon="grin-alt" class="mr-1 f-90"></fa>
            {{$t(`제출되었습니다!`)}} 
            <!-- {{$t(`답변기간 내에는 개별 문항의 우측 하단에서 버튼을 풀고 수정할 수 있습니다.`)}} -->
        </div>
    </template>

    <div class="pt-5 mb-2 tail-line-dark-sm">{{$t(`참여자 리스트`)}}</div>
    <div v-for="(user, index) in users_in_company" :key="user.id" class="f-85 mb-2">
        <template v-if="checkUserExcluded(user.excluded_campaigns)!=true">
            <p class="mb-0">{{ index+1 }}. {{ user.user_name }}
                <b-badge v-if="user.is_admin>=1" variant="basic" class="ml-1"> 
                    <span v-if="user.is_admin>=3">{{ user.is_admin==4 ? $t(`최고 관리자`) : $t(`캠페인 평가자`) }}</span>
                    <span v-else>{{ user.is_admin==2 ? $t(`외부 평가자`) : $t(`캠페인 리더`) }}</span>
                </b-badge>
            </p>
            <p class="mb-0 ml-3">{{ user.e_mail }} </p>          
        </template> 
    </div>
    <div class="pt-2 mb-5">
        <b-button v-if="is_admin>=1" v-b-modal.user-registration variant="dark-border" size="sm"><fa icon="user-plus" class="mr-1"></fa>{{$t(`참여자 관리`)}}</b-button>
    </div>

    <div class="mb-2 tail-line-dark-sm">{{$t(`인쇄 및 다운로드`)}}</div>
    <!-- <div class="position-absolute print-aside"></div> -->
    <div v-if="isIE!=true" class="mb-5"> <print-to-pdf :btn_size="'sm'"> <fa icon="print" class="mr-1"></fa> {{$t(`인쇄 및 다운로드`)}} </print-to-pdf></div>
    <div v-else class="mb-5 border-gray-400 bgray-300 rounded p-2 p-xl-3 f-85 gray-600"> <fa icon="info-circle" class="mr-1"></fa>
        {{$t(`인터넷 익스플로러에서는 인쇄 및 다운로드를 사용하실 수 없습니다.`)}} {{$t(`크롬(Chrome) 또는 엣지(Edge) 브라우저를 사용해주세요.`)}} 
    </div>
    <!-- <div> 해당없음 포함 진행률 50% 이상일 경우 제출 권고 문구 띄우기 </div> -->
    <b-modal id="user-registration" size="lg" :title="$t(`참여자 등록하기`)" hide-footer> <user-registration-form></user-registration-form> </b-modal>


</div>
</template>

<script>
import { mapState, mapActions, mapMutations} from 'vuex'
import PrintToPDF from '@/components/print_to_pdf/PrintToPDF'
import MakeAllCompleteButton from '@/components/main_layout/MakeAllCompleteButton'
import UserRegistrationForm from '../managing_user/UserRegistrationForm'

export default {
    data() {
        return {
            
        }
    },
    computed:{
        ...mapState(['campaign_in_progress', 'is_admin', 'company_id']),
        ...mapState('campaign_users', ['users_in_company', 'total_filled_rate', 'is_filled_rate_loaded', 'total_progress_rate', 'is_progress_rate_loaded']),
        ...mapState({
            question_code: state => state.question_in_progress.question_code
        }),
        progress_rate(){
            return this.$store.getters.getProgressRate
        },
        isIE() {
            if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (navigator.userAgent.toLowerCase().indexOf("msie") != -1) )
                {return true }
            else return false
        },
        check_campaign_completed(){
            if(this.campaign_in_progress.campaign_state =='답변중'||this.campaign_in_progress.campaign_state=='생성/편집'){
                return false
            }else return true
        },
        left_days(){
            let gap = this.campaign_in_progress.end_date.getTime() - new Date().getTime()
            return Math.floor(gap / (1000 * 60 * 60 * 24)); // 남은 일수       
        },
        left_time(){
            let time_gap = new Date(0, 0, 0, 0, 0, 0, this.campaign_in_progress.end_date - new Date()); 
            return time_gap.getHours() // 남은 시간
        },
        overall_filled(){
            if (this.is_filled_rate_loaded==true){
                if (this.total_filled_rate.length>0){
                    if ((this.total_filled_rate[0].filled_rate*100) >40){
                        return true
                    }else return false
                }else return false                
            }else return false
        },
        variant_by_filled_rate(){
            if (this.is_filled_rate_loaded==true){
                if(this.overall_filled==true&&this.total_progress_rate[0].completed_rate<1&&this.progress_rate<100){
                    return 'red-blinking'
                }else return 'dark-border'
            }else return 'dark-border'
        },
        check_submitted(){
            if(this.progress_rate>this.total_progress_rate[0].completed_rate*100){
                if(this.progress_rate==100) return true
                else return false
            }
            else {
                if(this.total_progress_rate[0].completed_rate>=1) return true
                else return false     
            }    
        }
    },
    methods:{
        ...mapMutations('campaign_users', ['update_filled_rate_loaded', 'update_progress_rate_loaded']),
        ...mapActions('campaign_users',['readProgressRate', 'readFilledRate']),
        checkUserExcluded(user_excluded_campaigns){
            let excluded_idx = user_excluded_campaigns.findIndex( exc => exc == this.campaign_in_progress.campaign_name)
            if (excluded_idx > -1 ){
                return true
            }
            else return false
        }
    },
    created(){
        this.readProgressRate({campaign_id:this.campaign_in_progress.campaign_id, company_id: this.company_id})
        this.readFilledRate({campaign_id:this.campaign_in_progress.campaign_id, company_id: this.company_id})
        // console.log(this.campaign_in_progress);
    },
    updated(){
        // console.log(this.total_filled_rate);
    },
    beforeDestroy(){
        this.update_filled_rate_loaded(false)
        this.update_progress_rate_loaded(false)

    },
    watch:{
        question_code:{
            immediate: true,
            handler(newV){
                if(newV){
                    this.readFilledRate({campaign_id:this.campaign_in_progress.campaign_id, company_id: this.company_id})
                    this.readProgressRate({campaign_id:this.campaign_in_progress.campaign_id, company_id: this.company_id})
                }
            }
        }
    },
    components: {
        'print-to-pdf': PrintToPDF,
        MakeAllCompleteButton: MakeAllCompleteButton,
        UserRegistrationForm: UserRegistrationForm
    }


}
</script>

<style>

</style>