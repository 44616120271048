<template>
<div class="h-100 px-5 d-flex flex-column justify-content-between align-items-between">
    <section class="pt-3">
        <h1 class="pt-5 mb-5 tail-line-dark"> {{ $t(reporting_company.company_name) }} {{$t(`ESG 자가진단 결과`)}} </h1>
        <hr class="border-0 pt-3">

        <b-card class="border-0 bgray-200 p-3 py-5 f-140 mt-5 line-height-lg">
            <h3 class="mb-5"> 
                {{$t('2021 GS칼텍스 협력사 지속가능경영 자가점검에 참여해 주셔서 감사드립니다.')}} 
            </h3>
            <div class="mb-3"> 
                {{$t(`GS칼텍스는 협력사와의 상생경영의 일환으로 양사의 지속 성장 가능성을 증진하고자 제3자 전문기관을 통한 협력사 ESG 자가점검을 진행하였습니다.`)}}
            </div>
            <div class="mb-3"> 
                {{$t(`본 협력사 ESG자가점검은 협력사의 지속가능경영 수준을 진단하고, 리스크 요소를 파악하여 향후 개선 활동을 지원하기 위함입니다.`)}} {{$t(`이는 GS칼텍스와 협력사에 대한 공급망 전반의 지속가능성을 향상시키고, 동반성장 하기 위한 과정으로서 이외 다른 목적으로는 사용되지 않습니다.`)}}
            </div>
            <div class="mb-3">
                {{$t(`평가는 귀사의 인권 및 노동, 안전보건, 환경, 윤리 및 경영시스템 4가지 카테고리에 대하여 정책의 수립, 실행, 컴플라이언스 분야를 살펴보았습니다.`)}} {{$t(`진단의 결과는 귀사가 평가항목에 응답해주신 답변을 근거로 점수화 한 것입니다.`)}}
            </div>
            <div class="mb-3"> 
                {{$t(`결과보고서에는 평가에 참여한 전체 기업의 부문별 평균점수와 최고 점수를 살펴보실 수 있으며, 지표별 귀사의 ESG 우수사항과 개선사항이 안내되어 있습니다.`)}}
            </div>
            <div class="mb-3"> 
                {{$t(`본 자가진단의 결과가 앞으로 귀사의 ESG 향상과 지속가능한 발전에 작은 도움이 되기를 바랍니다.`)}}
            </div>
            <div class=""> 
                {{$t(`다시 한번, 2021 GS칼텍스 협력사 ESG 자가점검에 참여해주셔서 대단히 감사드립니다.`)}}
            </div>
        </b-card>
    </section>

    <section>
        <b-card class="border-dotted f-110">
            <div class="mb-3">
                {{$t(`금번 진단을 위해 귀사가 작성하신 데이터 및 진단의 결과는 GS칼텍스 및 ESG 진단 위탁기관인㈜퀀티파이드이에스지 외 외부 또는 제3자에 공유, 전달 또는 배포되지 않습니다.`)}}
            </div>
            <p class="mb-2">* {{$t(`본 진단에 대해 보다 상세한 문의가 있으실 경우 아래로 문의 부탁드립니다.`)}}</p>
            <p class="mb-0 ml-3"> {{$t(`퀀티파이드이에스지(QESG)`)}} <span class="ml-3">info@qesg.co.kr / 02-6956-2410</span> </p>
        </b-card>
    </section>
</div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    data() {
        return {
            local_campaign:''
        }
    },
    props:{
        is_printing: { type: Boolean, default: false }
    },
    computed:{
        ...mapState('cr_scores', ['reporting_company', 'company_info']),
        ...mapState(['view_mode', 'campaign_in_progress', 'campaign'])

    },
    created(){
        if (this.view_mode=='provider'){
            this.local_campaign = this.campaign_in_progress
        } else {
            this.local_campaign = this.campaign
        }
    }
}
</script>

<style>

</style>