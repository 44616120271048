<template>
    <div class="overflow-auto" style="">
        <b-table-simple responsive class="f-90">
            <b-thead>
                <b-tr class="gray-600">
                <b-td style="max-width:60px">번호</b-td><b-td>선택 제외</b-td><b-td>회사명</b-td><b-td>사업자번호</b-td><b-td>담당자</b-td><b-td>담당자 제외</b-td>
                </b-tr>
            </b-thead>
            <!-- <b-tbody v-for="(item, index) in target_companies" :key="item.index" class="py-1">
                <b-tr v-for="x in item.all_users" :key="x.id">
                    <b-td > {{ index + 1}} </b-td>
                    <b-td > <b-button variant="icon-dark" :disabled="disabled" @click="deleteCompany(item)" class="p-0 f-120"><fa icon="minus-square"></fa></b-button></b-td>
                    <b-td> {{ item.company_name }} </b-td>
                    <b-td> {{ item.company_code }} </b-td>
                    <b-td> {{ x.user_name }} {{ x.user_e_mail }}
                    </b-td>
                    <b-td><b-form-checkbox></b-form-checkbox> </b-td>                    
                </b-tr>
            </b-tbody> -->
            <b-tbody v-for="(item, index) in target_companies" :key="item.index" class="py-1">
                <b-tr >
                    <b-td > {{ index + 1}} </b-td>
                    <b-td > <b-button variant="icon-dark" :disabled="disabled" @click="deleteCompany(item)" class="p-0 f-120"><fa icon="minus-square"></fa></b-button></b-td>
                    <b-td> {{ item.company_name }} </b-td>
                    <b-td> {{ item.company_code }} </b-td>
                    <b-td> <div v-for="x in item.all_users" :key="x.id">{{ x.user_name }} {{ x.user_e_mail }} </div>
                    </b-td>
                    <b-td><div v-for="x in item.all_users" :key="x.id"><b-form-checkbox ></b-form-checkbox> </div> </b-td>                    
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <b-button @click="saveExcludedUsers">임시 버튼</b-button>
    </div>
</template>

<script>
export default { 
    data(){
        return {
            target_companies:[
                {company_name: 'company 1',
                company_code: '333-222-3333',
                all_users:[
                    {user_id: 412, user_name: '김혜주', e_mail:'hjkim@qesg.co.kr'},
                    {user_id: 588, user_name: '박지민', e_mail:'jmp@qesg.co.kr'}
                ]},
                {company_name: 'company 2',
                company_code: '111-222-8888',
                all_users:[
                    {user_id:'', user_name: '네이트', e_mail:'khj00200@nate.com'},
                    {user_id:'', user_name: '다음', e_mail:'khj00200@hanmail.net'}
                ]},
                
            ]
        }
    },
    props:{
        notice: { type:Object, default: ()=>({}) },
        disabled: {type: Boolean, default: false}
    },
    computed: {
        portfolio_companies() {
            return this.$store.state.portfolio.portfolio_target_companies
        },
        selected_company(){
            return this.$store.state.selected_company
        }
    },
    methods: { 
        deleteCompany(deletable_company){
            this.$store.commit('delete_portfolio_target_company', deletable_company)
        },
        setNewPortfolio() {
            let temp_portfolio_target_comp = []

            if (this.notice.notice_id != undefined && this.notice.notice_id != '') {            
                for(var i=0; i<this.notice.target_company.length; i++) {
                    temp_portfolio_target_comp.push( {
                        company_id : this.notice.target_company[i].company_id,
                        company_name : this.notice.target_company[i].company_name,
                        company_code : this.notice.target_company[i].company_code
                    })
                }
                let temp_portfolio = {
                    portfolio_name : '',
                    portfolio_description : '',
                    portfolio_target_companies : temp_portfolio_target_comp
                }

                this.$store.commit('update_portfolio', temp_portfolio)
            }   
        },
        saveExcludedUsers(){
            const path = this.$store.state.backend_host + '/save_excluded_user'
            let temp_campaign_id = 76
            let temp_user_list = [412, 588]

            let formData = new FormData()
            formData.append('campaign_id', temp_campaign_id)
            formData.append('user_id_list', JSON.stringify(temp_user_list))

            return axios.post(path, formData)
            .then(response =>{
                console.log(response);
            })

        },
        initiateTargetCompanies(){
            this.target_companies
        }

    },
    created(){
        // this.$store.commit('update_portfolio', { portfolio_name: '', portfolio_description: '', portfolio_target_companies: [] })
        this.setNewPortfolio()
        this.initiateTargetCompanies()
    },
    watch:{
        // selected_company(newVal){
        //     if (newVal){
        //         this.target_companies.push()
        //     }
        // }
       
    }
}
</script>

<style scoped>

</style>
