<template>
<div class="audit-report-sheet">
    <h2 class="pt-3 mb-5 tail-line-dark">{{$t(`전문가 종합의견`)}}</h2>

    <div v-if="is_conclusion_by_company_loaded==true" class="">
        <b-card class="bgray-100 border-0 mb-5 f-120">
            <div v-if="$i18n.locale=='en'" v-html="conclusion_by_company.conclusion_e" class=""></div>
            <div v-else v-html="conclusion_by_company.conclusion" class=""></div>
        </b-card>

        <div class="d-flex flex-wrap">
            <b-card v-for="(item, index) in recommendations_by_company" :key="index" 
            class="mr-2 bg-transparent no-word-break" 
            style="width:47%"> 
                <b-badge variant="basic" class="mb-2">{{$t(`개선의견`)}} {{ index+1 }}.</b-badge><div class="mb-2 f-140 fw-500"> {{$i18n.locale=='en'? item.title_e : item.title}}</div>
                <div v-if="$i18n.locale=='en'" v-html="item.recommendation_e" class="f-100"></div>
                <div v-else v-html="item.recommendation" class="f-120"></div>

            </b-card>
        </div>
    </div>
</div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'

export default {
    data() {
        return {
            
        }
    },
    computed:{
        ...mapState('reviews', ['conclusion_by_company', 'is_conclusion_by_company_loaded', 'recommendations_by_company', 'is_recommendations_by_company_loaded']),

    },
    methods:{

    },
    beforeDestroy(){

    }

}
</script>

<style>
.audit-report-sheet {
    font-size: 1.1rem;
    color: var(--gray-900);
    white-space: pre-line;
}

</style>