<template>
<div>
    <b-container class="pt-5">
        <!-- <improve-a></improve-a> -->
        <improve-b></improve-b>
    </b-container>        
    <hr class="border-0 bottom-space">
</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ImproveA from './improve_corrective/ImproveA.vue'
import ImproveB from './improve_corrective/ImproveB.vue'

export default {
    data() {
        return {
        }
    },
    computed:{
        
        
    },
    methods:{
       
    },
    created(){
       
    },
    components:{
        ImproveA,
        ImproveB
    }

}
</script>

<style scoped>

</style>