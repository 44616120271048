<template>
<b-badge :style="determineColors"> {{ user_name }} </b-badge>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    props: {
        user_name: { type: String, required: true },
        user_id: { type: [Number, String], required: true}
    },
    computed: {
        determineColors(){
            let determined_number = Number(this.user_id) % 10
            let color_array = [
                '#C471D3', 
                '#FF7788',
                '#237CEB',
                '#01ADB9',
                '#9EEA52',
                '#20C988',
                '#4BC1FE',
                '#FFB639',
                '#D83D54',
                '#7D5BFB'
            ]


            return { 'background-color': color_array[determined_number] }
        }
    }
}
</script>