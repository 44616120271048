<template>
<div>
    <b-container fluid class="pt-5">
        <b-row no-gutters class="d-flex align-items-stretch">
            <b-col>
                <h1 class="pb-4 fw-600"> {{ question_name }} </h1>
                <!--<div class="pb-5 mb-4">
                    <b-textarea v-model="overall_comment.overall_comment" type='textarea' rows="6" 
                    :placeholder="`'${question_name}' 문항에 대한 종합 의견을 작성해주세요`">
                    </b-textarea>
                    <div :class="validation_style" class="mt-2 float-left"><fa v-if="validation_icon!=''" :icon="validation_icon"></fa> {{ validation_comment }} </div>
                    <div v-if="overall_comment.id" class="text-right f-90 gray-600 mt-2">Auditor : {{overall_comment.author_name}}, {{overall_comment.time_stamp.slice(0,10)}}</div>
                </div>-->

                <div v-if="subs_loaded && subs.length > 0">
                    <div v-for="(item, index) in subs" :key="item.index" class="mb-45 d-flex justify-content-between">
                        <!-- 기업 답변 영역 -->
                        <div class="border-0 mb-3 w-50 bgray-100 p-3">
                            <sub-component 
                            :sub_type="item.sub_type"
                            :item.sync="item"
                            :is_disabled_by_parent="true"
                            :audit_mode="true"
                            :print_mode="true"
                            @update-pipeline="handleUpdatePipeline"
                            :is_final_sub_component="index==subs.length-1? true: false"
                            @load-finished="handleLoadFinished"
                            ></sub-component>
                        </div>
                        
                        <!-- Auditor 평가영역 -->
                        <div class="border-0 bm-3 w-50 p-3" >
                            <evaluation-component 
                            :sub_code="item.sub_code"
                            :sub_description="item.sub_description"
                            >
                            </evaluation-component>
                        </div>                                   

                        <hr class="border-0 pb-5">  
                    </div>
                </div>
            </b-col>        
        </b-row>   
        <hr class="border-0 mb-5 py-5">
    </b-container>
</div>
</template>

<script>
import TemplateTree from '../editing_template/TemplateTree'
import EvaluationComponent from '@/components/managing_campaigns/answer_review/EvaluationComponent'
import debounce from 'lodash.debounce'
import AuditPrintButton from '@/components/print_to_pdf/AuditPrintButton'
import * as th from 'tree-helper'
//import axios from 'axios'

export default {
    data() {
        return {
            pipeline_key: Math.random()*1000, 

            save_state:'not_saved',

            validation_comment:'',
            validation_icon:'',
            validation_style:'',

            subs: [],
            subs_loaded: false,

            overall_comment: '',
            is_comment_loaded: false,

            question_name: '',
            comment: '',
            question_info_box: '',
            is_writable: '',
            is_visible: '',
            question_contents: []
        }
    },
    props:{
        question_code: { type: String },
    },
    computed:{
        target_company(){
            return this.$store.state.questions.answer_company
        },
        template_tree(){
            return this.$store.state.template_tree
        },
        target_template_contents(){
            return this.template_tree.template_contents
        },
        is_evaluate_loaded(){
            return this.$store.state.score.is_evaluate_loaded
        },
        evaluate_results(){
            return this.$store.state.score.evaluate_results
        },
        sub_evaluation(){
            if (this.is_evaluate_loaded==true){
                let sub_evaluations = []
                let s_code = []
                this.subs.forEach(item => {
                    s_code.push(item.sub_code)
                })
                for (let i=0; i<s_code.length; i++){
                    let temp_result = []
                    this.evaluate_results.forEach(item =>{
                        if (item.sub_code == s_code[i]){
                            temp_result.push({
                                evaluation_name: item.evaluation_name,
                                score: item.score,
                                satisfied: item.satisfied,
                                author_id: item.author_id,
                                author_name: item.author_name,
                                audit_comment: item.audit_comment,
                                time_stamp: item.time_stamp,
                                sub_code: item.sub_code,
                                formula: item.formula,
                            })
                        }
                    })
                    sub_evaluations[s_code[i]] = temp_result
                }
                return sub_evaluations      
            }      
        },

    },
    methods:{
        initializeQuestion(question_code){
            th.breadthFirstSearch(this.target_template_contents, node => {
                if(node.question_code == question_code){
                    this.comment = node.comment
                    this.question_name = node.question_name
                    this.question_infobox = node.question_infobox
                    this.is_writable = node.is_writable
                    this.is_visible = node.is_visible

                    if(typeof(node.question_contents)=='string') {
                        this.question_contents = JSON.parse(node.question_contents)
                    }
                    else{
                        this.question_contents = node.question_contents
                    }
                    this.is_completed = node.is_completed
                }
            })

            if(this.question_contents.length==0){
                this.handleLoadFinished()
            }
            this.readSubsInit(this.question_contents)
        },     
        readOverallComment(campaign_id, company_id, question_code){
            const path = this.$store.state.backend_host + '/read_audit_overall_comment'

            return axios.get(path, {params:{
                campaign_id: campaign_id,
                company_id: company_id,
                question_code: question_code }
            })
            .then(response=>{
                this.overall_comment = response.data
                this.is_comment_loaded = true
            })
        },
        readSubsInit(question_contents){
            this.subs=[]
            this.subs_loaded=false

            var promises = []
            const path = this.$store.state.backend_host + '/read_sub_init'

            question_contents.forEach(function (sub_code) {
                promises.push(
                    axios.get(path, { params: {sub_code: sub_code}})
                    .catch( error => { console.log(error)})
                )}                
            )

            let temp_result = []
            return Promise.all(promises)
            .then( results => {
                    this.$emit('load-started', this.question_code)
                    results.forEach(
                        response => {                            
                            if (response != undefined){
                                let result = response.data
                                let new_sub = {}
                                new_sub.sub_code = result.sub_code
                                new_sub.sub_name = result.sub_name
                                new_sub.sub_description = result.sub_description
                                new_sub.sub_condition = result.sub_condition
                                new_sub.sub_info = result.sub_info
                                new_sub.sub_type = result.sub_type
                                new_sub.sub_data_header = result.sub_data_header
                                new_sub.sub_data_contents = result.sub_data_contents
                                new_sub.sub_priority = result.sub_priority
                                new_sub.sub_parent = result.sub_parent
                                new_sub.sub_child = result.sub_child
                                new_sub.tags = result.tags
                                new_sub.has_pipeline = result.has_pipeline
                            
                                new_sub.question_code=result.question_code
                                temp_result.push(new_sub)
                            }
                        })    
            })
            .finally( () => {
                this.subs=temp_result
                this.subs_loaded=true
            })
        },
        handleLoadFinished(){
            this.$emit('load-finished', this.question_code)
        },
        handleUpdatePipeline(){
            this.pipeline_key += 1
        }
    },
    created(){
        this.initializeQuestion(this.question_code)
        this.readOverallComment(this.target_campaign_id, this.target_company.company_id, this.question_code)
    },
    watch: {
        save_state(newValue) {
            if (newValue == 'saving') {
                this.validation_comment = this.$t(`저장 중 입니다.`)
                this.validation_icon = 'hourglass'
                this.validation_style = 'ih-validation-saving'
            }
            else if (newValue == 'saved') {
                this.validation_comment = this.$t(`저장 되어있습니다`)
                this.validation_icon = 'check'
                this.validation_style = 'ih-validation-saved'
            }
            else if (newValue == 'resaved') {
                this.validation_comment = this.$t(`변경 저장 되었습니다`)
                this.validation_icon = 'check'
                this.validation_style = 'ih-validation-resaved'
            }
        },
    },
    components:{
        TemplateTree, 
        AuditPrintButton,
        EvaluationComponent        
    },
}
</script>