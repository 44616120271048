<script>
import { Doughnut } from 'vue-chartjs'

export default {
    extends: Doughnut,
    data() {
        return {

        }
    },
    props: {
        category_name: { type: String },
        progress_rate: { type: Number },
        background_color: { type: Array }
    },
    computed: {
        chartData() {
            let return_object = {
               datasets: [{
                    data: [this.progress_rate, 100-this.progress_rate],
                    backgroundColor : this.background_color,
                    weight: 0.5,
                    borderWidth: 0
                }],
                labels: [this.$t('완료'), this.$t('미완료')],
                
            }
            return return_object
        }, 
        options() {
            let return_object = {
                cutoutPercentage: 80,
                title: {
                    display: true,
                    text: [ this.category_name ],
                    padding: 0
                },
                legend: {
                    labels: {
                        fontSize: 0
                    }
                },
                tooltips: {callbacks: {
                        label: function(tooltipItems, data) {
                            return data.labels[tooltipItems.index] + ': ' + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] + '%';
 
                        }
                    }
                },
            }
            return return_object
        }
    },
    mounted() {
        this.renderChart(this.chartData, this.options)        
    },
    watch:{
        progress_rate:{
            handler(newVal, oldVal) {
                if(this.progress_rate != '') {
                    this.$emit('progress-received', true);
                    //console.log('진행률을 받았습니다.');
                    this.renderChart(this.chartData, this.options)
                }
            }
        }
    },
    // created(){
    //     if(this.progress_rate == '') {
    //         this.$emit('progress_received', false)
    //         console.log('진행률이 들어오지 않았습니다.');
    //     }else{
    //         this.$emit('progress_received', true);
    //     }        
    // }

}
</script>