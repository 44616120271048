//User Service 중 Auditor의 리뷰의견 및 개선과제 읽고 저장하는 Vuex Store

const reviews = {
    namespaced: true,
    state: {
        campaign_id: '',
        reviews: [ {
            company_id: '',
            audit_conclusion: {
                auditor_name: '',
                conclusion: '',
                time_stamp: ''
            },
            audit_recommendations: [
                {
                    rec_code: '', 
                    auditor_name: '',
                    title: '',
                    recommendation: '',
                    time_stamp: ''
                },
            ],
            review_results: [
                {   sub_code: '',
                    auditor_name: '',
                    review_comment: '',
                    task_comment: '',
                    time_stamp: ''
                }
            ],
            corrective_actions:[
                {
                    rec_code: '',
                    action_plan: '',
                    timeframe: '',
                    time_stamp:''
                }
            ],
            action_submit: {   
                submit: false,
                time_stamp: ''
            },
            monitoring_confirm: {   
                confirmed: false,
                time_stamp: ''
            }

        }], //캠페인별 리뷰 객체

        is_reviews_loaded: false,
        is_review_saved: false,

        is_conclusions_loaded: false,
        is_recommendations_loaded: false,

        is_corrective_actions_loaded: false,
        is_action_submit_loaded: false,
        is_monitoring_confirmed_loaded: false,

        //provider 사이드에서 결과보고서 불러올 때는 reviews가 아닌, 아래 conclusion_by_company 및 recommendations_by_company 사용
        conclusion_by_company: {
            auditor_name: '',
            conclusion: '',
            time_stamp: ''
        },
        is_conclusion_by_company_loaded: false,
        recommendations_by_company:[
            {
                rec_code: '', 
                auditor_name: '',
                title: '',
                recommendation: '',
                time_stamp: ''
            },
        ],
        is_recommendations_by_company_loaded: false,
    },

    mutations: {
        add_or_update_review( state, { company_id, sub_code, auditor_name, review_comment, task_comment, time_stamp }){
            let temp_reviews = [...state.reviews ]

            let new_review_obj = {
                sub_code: sub_code, 
                auditor_name: auditor_name,
                review_comment: review_comment, 
                task_comment: task_comment,
                time_stamp: time_stamp
            }

            let idx = temp_reviews.findIndex(item => item.company_id == company_id)
            if (idx >-1){
                let review_idx = temp_reviews[idx].review_results.findIndex(item => item.sub_code == sub_code)
                if(review_idx > -1){ //update
                    temp_reviews[idx].review_results.splice(review_idx, 1, new_review_obj)                   
                }
                else{ //add
                    temp_reviews[idx].review_results.push(new_review_obj)
                }
            }
            else {
                let reviews = []
                reviews.push(new_review_obj)
                temp_reviews.push( {
                    company_id: company_id,
                    audit_conclusion: {},
                    audit_recommendations: [],
                    review_results: reviews
                })
            }
            state.reviews = temp_reviews
        },
        add_or_update_audit_conclusion( state, { company_id, auditor_name, conclusion, time_stamp })
        {
            let temp_reviews = [ ...state.reviews ]
            let new_conclusion_obj = {
                auditor_name: auditor_name,
                conclusion: conclusion,
                time_stamp: time_stamp
            }

            let idx = temp_reviews.findIndex(item => item.company_id == company_id)
            if( idx > -1 ){
                temp_reviews[idx].audit_conclusion = new_conclusion_obj
            }
            else{
                let new_company_obj = {
                    company_id: company_id,
                    audit_conclusion: new_conclusion_obj,
                    audit_recommendations: [],
                    review_results: []
                }
                temp_reviews.push(new_company_obj)
            }

            state.reviews = temp_reviews

        },
        add_or_update_audit_recommendation( state, { rec_code, company_id, auditor_name, title, recommendation, time_stamp }){
            let temp_reviews = [ ...state.reviews ]
            let new_recommendation_obj = {
                rec_code: rec_code, 
                auditor_name: auditor_name,
                title: title,
                recommendation: recommendation,
                time_stamp: time_stamp
            }

            let idx = temp_reviews.findIndex(item => item.company_id == company_id)
            if (idx >-1){
                let recommendation_idx = temp_reviews[idx].audit_recommendations.findIndex(item => item.rec_code == rec_code)
                if (recommendation_idx > -1){ //update
                    temp_reviews[idx].audit_recommendations.splice(recommendation_idx, 1, new_recommendation_obj)
                }
                else {
                    temp_reviews[idx].audit_recommendations.push(new_recommendation_obj)
                }
            }
            else {
                let new_audit_recommendations = []
                new_audit_recommendations.push(new_recommendation_obj)
                temp_reviews.push( {
                    company_id: company_id,
                    audit_conclusion: {},
                    audit_recommendations: new_audit_recommendations,
                    review_results: []
                })
            }
            state.reviews = temp_reviews

        },
        add_or_update_monitoring_confirm( state, new_confirm ){
            // new_confirm은 { confirm_id, company_id, confirmed, time_stamp }로 구성된 객체
            let temp_reviews = [ ...state.reviews ]
            let new_confirmed = {
                confirmed: new_confirm.confirmed,
                time_stamp: new_confirm.time_stamp
            }
            
            let idx = temp_reviews.findIndex(item => item.company_id == new_confirm.company_id)

            if(idx >-1){
                Vue.set(temp_reviews[idx], 'monitoring_confirm', new_confirmed )        
            }
            else {
                let monitoring_confirm = {}
                    monitoring_confirm = new_confirmed
                    new_reviews.push( {
                        company_id: z.company_id,
                        monitoring_confirm: monitoring_confirm
                    })
            }
            state.reviews = temp_reviews
        },
        delete_review( state, { company_id, sub_code }){
            let temp_reviews = [ ...state.reviews ]

            let idx = temp_reviews.findIndex(item=> item.company_id == company_id)
            if (idx > -1){
                let review_idx = temp_reviews[idx].review_results.findIndex(item=> item.sub_code == sub_code)
                if(review_idx > -1){
                    temp_reviews[idx].review_results.splice(review_idx, 1)
                }
            }

            state.reviews = temp_reviews
        },
        reset_reviews(state){
            state.reviews = []
            state.is_reviews_loaded = false
        },
        update_review_saved(state, mode){
            state.is_review_saved = mode
        },
        initiate_reviews(state, response_array ){
            // response_array 는 [{ company_id, sub_code, auditor_name, review_comment, task_comment, time_stamp }... ] 이렇게 생김
            let new_reviews = []

            response_array.forEach( item => {                
                let idx = new_reviews.findIndex(review_item => review_item.company_id == item.company_id)
                
                let new_review_obj = {
                    sub_code: item.sub_code, 
                    auditor_name: item.auditor_name,
                    review_comment: item.review_comment, 
                    task_comment: item.task_comment,
                    time_stamp: item.time_stamp
                }

                if(idx > -1){
                    if (new_reviews[idx].hasOwnProperty('review_results')==false) new_reviews[idx].review_results = [] 
                    new_reviews[idx].review_results.push(new_review_obj) 
                } 
                else{
                    let reviews = []
                    reviews.push(new_review_obj)
                    new_reviews.push( {
                        company_id: item.company_id,
                        review_results: reviews
                    })
                }
            })
            state.reviews = new_reviews
            state.is_reviews_loaded = true
        },
        initiate_conclusion(state, response_array ){
            //response_array = [ { 'conclusion_id', 'campaign_id', 'company_id', 'auditor_name', 'conclusion', 'time_stamp'}]
            let new_reviews = [ ...state.reviews ]

            new_reviews.forEach(company_item => {
                if(company_item.hasOwnProperty('audit_conclusion')==false) {
                    company_item.audit_conclusion = {
                        conclusion_id: null,
                        auditor_name: '',
                        conclusion: '',
                        time_stamp: '',
                    }
                }
            })

            response_array.forEach( item => {
                let idx = new_reviews.findIndex(company_item => company_item.company_id == item.company_id)
                let new_conclusion_obj = {
                    conclusion_id: item.conclusion_id, 
                    auditor_name: item.auditor_name, 
                    conclusion: item.conclusion, 
                    time_stamp: item.time_stamp
                }

                if(idx > -1){
                    Vue.set(new_reviews[idx], 'audit_conclusion', new_conclusion_obj)
                }
                else{ //혹시 몰라서...
                    new_reviews.push( {
                        company_id: item.company_id,
                        audit_conclusion: new_conclusion_obj
                    })
                }
            })
            state.reviews = new_reviews            
            state.is_conclusion_loaded = true
        },
        initiate_recommendations(state, response_array ){
            //response_array = [{'rec_code', 'campaign_id', 'company_id', 'auditor_name', 'title', 'recommendation', 'time_stamp'},...]
            let new_reviews = [ ...state.reviews ]

            new_reviews.forEach(company_item => {
                if(company_item.hasOwnProperty('audit_recommendations')==false) {
                    company_item.audit_recommendations = []
                    
                }
            })

            response_array.forEach( item => {                
                let idx = new_reviews.findIndex(review_item => review_item.company_id == item.company_id)
                
                let new_recommendation_obj = {
                    rec_code: item.rec_code, 
                    auditor_name: item.auditor_name, 
                    title: item.title, 
                    recommendation: item.recommendation, 
                    time_stamp: item.time_stamp
                }

                if(idx > -1){
                    new_reviews[idx].audit_recommendations.push(new_recommendation_obj) 
                } 
                else{ // 혹시 몰라서...
                    let audit_recommendations = []
                    audit_recommendations.push(new_recommendation_obj)
                    new_reviews.push( {
                        company_id: item.company_id,
                        audit_recommendations: audit_recommendations
                    })
                }
            })
            state.reviews = new_reviews
            state.is_recommendations_loaded = true
        },
        delete_recommendation( state, { company_id, rec_code }){
            let temp_reviews = [ ...state.reviews ]

            let idx = temp_reviews.findIndex(item=> item.company_id == company_id)
            if (idx > -1){
                let recommendation_idx = temp_reviews[idx].audit_recommendations.findIndex(item=> item.rec_code == rec_code)
                if(recommendation_idx > -1){
                    temp_reviews[idx].audit_recommendations.splice(recommendation_idx, 1)
                }
            }
            state.reviews = temp_reviews
        },
        initiate_corrective_actions(state, response_array){
            //response_array = [{'rec_code','action_id', 'company_id', 'action_plan', 'timeframe', 'author_id', 'time_stamp', 'user_name'}]
            let new_reviews = [...state.reviews]

            new_reviews.forEach(company_x => {
                if (company_x.hasOwnProperty('corrective_actions')==false){
                    company_x.corrective_actions = []
                }
            })

            response_array.forEach( x => {
                let idx = new_reviews.findIndex(action_item => action_item.company_id == x.company_id)

                let new_action_obj = {
                    rec_code: x.rec_code,
                    action_plan: x.action_plan,
                    timeframe: x.timeframe, 
                    time_stamp: x.time_stamp
                }

                if (idx > -1){
                    new_reviews[idx].corrective_actions.push(new_action_obj)
                }
                else {
                    let corrective_actions = []
                    corrective_actions.push(new_action_obj)
                    new_reviews.push( {
                        company_id: x.company_id,
                        corrective_actions:corrective_actions
                    })
                }
            })
            state.reviews = new_reviews
            state.is_corrective_actions_loaded = true
          
        },
        initiate_action_submit(state, response_array){
            //response_array = [{'company_id', 'campaign_id', 'submit', 'time_stamp'}]
            let new_reviews = [...state.reviews]

            new_reviews.forEach(company_y => {
                if (company_y.hasOwnProperty('action_submit')==false){
                    company_y.action_submit = []
                }
            })

            response_array.forEach( y => {
                let idx = new_reviews.findIndex(i => i.company_id == y.company_id)

                let new_submit = {
                    submit: y.submit,
                    time_stamp: y.time_stamp
                }

                if (idx >-1){
                    new_reviews[idx].action_submit = new_submit
                }
                else {//혹시 몰라서..
                    let action_submit = {}
                    action_submit = new_submit
                    new_reviews.push( {
                        company_id: y.company_id,
                        action_submit: action_submit
                    })
                }
                state.reviews = new_reviews            
                state.is_action_submit_loaded = true
            })
        },
        initiate_monitoring_confirm( state, response_array ){
            //response_array = ['confirmed_id','company_id', 'confirmed', 'time_stamp']
            let new_reviews = [...state.reviews]

            new_reviews.forEach(company_z => {
                if (company_z.hasOwnProperty('monitoring_confirm')==false){
                    company_z.monitoring_confirm = []
                }
            })
            
            response_array.forEach(z => {
                let idx = new_reviews.findIndex(item => item.company_id == z.company_id)

                let new_confirmed = {
                    confirmed: z.confirmed,
                    time_stamp: z.time_stamp
                }

                if (idx >-1){
                    new_reviews[idx].monitoring_confirm = new_confirmed
                }
                else {//혹시 몰라서..
                    let monitoring_confirm = {}
                    monitoring_confirm = new_confirmed
                    new_reviews.push( {
                        company_id: z.company_id,
                        monitoring_confirm: monitoring_confirm
                    })
                }
                state.reviews = new_reviews            
                state.is_monitoring_confirmed_loaded = true
            })
        },
        
        update_conclusion_by_company(state, new_conclusion){
            state.conclusion_by_company = new_conclusion
        },
        update_is_conclusion_by_company_loaded(state, mode){
            state.is_conclusion_by_company_loaded = mode
        },
        update_recommendations_by_company(state, new_recommendation){
            state.recommendations_by_company = new_recommendation
        },
        update_is_recommendations_by_company_loaded(state, mode){
            state.is_recommendations_by_company_loaded = mode
        }
        // update_conclusions_saved(state, mode){
        //     state.is_conclusions_saved = mode
        // }
    },

    getters: {

    },

    actions: {
        initiateReviews({state, commit, dispatch, rootState }, campaign_id ){
            const path = rootState.backend_host + '/read_subs_review'

            commit('reset_reviews')

            return axios.get(path, { params: {
                campaign_id: campaign_id
            }})
            .then( response => {
                // console.log(response.data)
                commit('initiate_reviews', response.data)
                // response.data 는 [{ company_id, sub_code, auditor_name, review_comment, task_comment, time_stamp }...] 이렇게 생김
                //console.log(state.reviews)
            })
            .catch( error => {
                console.log(error)
            })
            .finally( () => {
                dispatch('initiateConclusion', campaign_id)
                .then( () => {
                    dispatch('initiateRecommendations', campaign_id)
                    .then(() => {
                        dispatch('initiateCorrectiveActions', campaign_id)
                        .then(() => {
                            dispatch('initiateActionSubmit', campaign_id)
                            .then(()=>{
                                dispatch('initiateMonitoringConfirm', campaign_id)
                            })
                        })
                    })
                })              
            })
        },
        initiateConclusion({commit, rootState}, campaign_id){
            const path = rootState.backend_host + '/read_audit_conclusions'

            return axios.get(path, { params : {
                campaign_id : campaign_id
            }})
            .then( response => {
                // console.log(response.data);
                if(response.data.result&&response.data.result.length>0){
                    commit('initiate_conclusion', response.data)
                }
                // response.data는 [ { 'conclusion_id', 'campaign_id', 'company_id', 'auditor_name', 'conclusion', 'time_stamp'}]
            })
        },
        initiateRecommendations({commit, rootState}, campaign_id){
            const path = rootState.backend_host + '/read_audit_recommendations'

            return axios.get(path, { params: {
                campaign_id: campaign_id
            }})
            .then( response => {
                commit('initiate_recommendations', response.data)
                // response.data는 ['rec_code', 'campaign_id', 'company_id', 'auditor_name', 'title', 'recommendation', 'time_stamp']
            })
            
        },
        initiateCorrectiveActions({commit, rootState}, campaign_id){
            const path = rootState.backend_host + '/read_corrective_actions'
            return axios.get(path, {params:{campaign_id: campaign_id
            }})
            .then(response => {
                commit('initiate_corrective_actions', response.data)
            })
        },
        initiateActionSubmit({commit, rootState}, campaign_id){
            const path = rootState.backend_host + '/read_action_submit'
            return axios.get(path, { params:{
                campaign_id: campaign_id
            }})
            .then( response => {
                commit('initiate_action_submit', response.data)
            })
        },
        initiateMonitoringConfirm({commit, rootState}, campaign_id){
            const path = rootState.backend_host + '/read_monitoring_confirm'
            return axios.get(path, {params:{
                campaign_id: campaign_id
            }})
            .then(response => {
                commit('initiate_monitoring_confirm', response.data)
            })
        },
        readConclusionbyCompany({commit, rootState}, {campaign_id, company_id}){
            const path = rootState.backend_host + '/read_audit_conclusions'

            return axios.get(path, { params : {
                campaign_id : campaign_id,
                company_id : company_id
            }})
            .then( response => {
                commit('update_conclusion_by_company', response.data)
                if (response.data.conclusion !=undefined){
                    commit('update_is_conclusion_by_company_loaded', true)
                }
                // response.data는 [ { 'conclusion_id', 'campaign_id', 'company_id', 'auditor_name', 'conclusion', 'time_stamp'}]
            })
            .catch(error => console.log(error))
        },
        readRecommendationsbyCompany({commit, rootState}, {campaign_id, company_id}){
            const path = rootState.backend_host + '/read_audit_recommendations'

            return axios.get(path, { params: {
                campaign_id: campaign_id,
                company_id: company_id
            }})
            .then( response => {
                //console.log(response.data);
                commit('update_recommendations_by_company', response.data)
                if (response.data){
                    commit('update_is_recommendations_by_company_loaded', true)
                }
                // response.data는 ['rec_code', 'campaign_id', 'company_id', 'auditor_name', 'title', 'recommendation', 'time_stamp']
            })
            .catch(error => console.log(error))
        },
        saveSubReview({ commit, rootState }, {campaign_id, company_id, sub_code, auditor_name, review_comment, task_comment }) {
            commit('update_review_saved', false)
            const path = rootState.backend_host + '/save_sub_review'

            const formData = new FormData()
            formData.append('campaign_id', campaign_id)
            formData.append('company_id', company_id)
            formData.append('sub_code', sub_code)
            formData.append('auditor_name', auditor_name)
            formData.append('review_comment', review_comment)
            formData.append('task_comment', task_comment)

            return axios.post(path, formData)
            .then( response => {
                console.log(response.data)
                let new_obj = {
                    company_id: company_id, 
                    sub_code: sub_code, 
                    auditor_name: auditor_name, 
                    review_comment: review_comment, 
                    task_comment: task_comment, 
                    time_stamp: response.data.time_stamp
                }
                commit('add_or_update_review', new_obj )
                commit('update_review_saved', true)
                return new_obj
            })

        },
        saveAuditConclusion({commit, rootState}, { campaign_id, company_id, auditor_name, conclusion }){
            const path = rootState.backend_host + '/save_audit_conclusion'

            let formData = new FormData()
            formData.append('campaign_id', campaign_id)
            formData.append('company_id', company_id)
            formData.append('auditor_name', auditor_name)
            formData.append('conclusion', conclusion)

            return axios.post(path, formData)
            .then( response => {
                console.log(response)
                let new_conclusion_obj = {
                    conclusion_id: response.data.conclusion_id,
                    company_id: response.data.company_id, 
                    auditor_name: response.data.auditor_name, 
                    conclusion: response.data.conclusion, 
                    time_stamp: response.data.time_stamp                 
                }
                commit('add_or_update_audit_conclusion', new_conclusion_obj )
                return new_conclusion_obj              
            })
        },
        saveAuditRecommendation({commit, rootState}, { rec_code, campaign_id, company_id, auditor_name, title, recommendation }){
            // console.log('왔다!')
            const path = rootState.backend_host + '/save_audit_recommendation'

            let formData = new FormData()
            formData.append('campaign_id', campaign_id)
            formData.append('company_id', company_id)
            formData.append('rec_code', rec_code)
            formData.append('auditor_name', auditor_name)
            formData.append('title', title)
            formData.append('recommendation', recommendation)

            return axios.post(path, formData)
            .then( response => {
                // console.log(response)
                let new_recommendation_obj = {
                    rec_code: response.data.rec_code,
                    company_id: response.data.company_id, 
                    auditor_name: response.data.auditor_name,
                    title: response.data.title, 
                    recommendation: response.data.recommendation, 
                    time_stamp: response.data.time_stamp                 
                }
                commit('add_or_update_audit_recommendation', new_recommendation_obj )
                return new_recommendation_obj              
            })
        },
        deleteRecommendation({commit, rootState}, { company_id, rec_code }){
            const path = rootState.backend_host + '/delete_audit_recommendation'
            console.log(rec_code);

            let formData = new FormData()
            formData.append('rec_code', rec_code)

            return axios.post(path, formData)
            .then( response => {
                commit('delete_recommendation', {company_id: company_id, rec_code: rec_code})
                return response
            })

        },
        saveMonitoringConfirm({commit, rootState}, { company_id, campaign_id, mode}){
            const path = rootState.backend_host + '/save_monitoring_confirm'

            let formData = new FormData()
            formData.append('campaign_id', campaign_id)
            formData.append('company_id', company_id)
            formData.append('confirmed', mode)

            return axios.post(path, formData)
            .then (response => {
                console.log(response.data);
                let new_confirm = {
                    confirm_id: response.data.confirm_id,
                    company_id: response.data.company_id,
                    confirmed: response.data.confirmed,
                    time_stamp: response.data.time_stamp
                }
                commit('add_or_update_monitoring_confirm', new_confirm)
                return response
            })
        }
        
    }
}

export default reviews