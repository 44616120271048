<template>
<div :style="styleObject" class="initial-of-campaign"> {{ campaign_name.substring(0,2) }} </div>
</template>
<script>
export default {
    data(){
        return {

        }

    },
    props:{
        campaign_name: { type: String, required: true },
        campaign_id: { type: Number, required: true }
    },
    computed: {
        styleObject() {
            let determined_color = this.campaign_id % 10
            let color_array = [
                '#C471D3', 
                '#FF7788',
                '#237CEB',
                '#01ADB9',
                '#9EEA52',
                '#20C988',
                '#4BC1FE',
                '#FFB639',
                '#D83D54',
                '#7D5BFB'
            ]

            return {
                'border': `1px solid ${color_array[determined_color]}`,
                'color': color_array[determined_color]
            }
        }
    }
}
</script>
<style scoped>

.initial-of-campaign {
    font-size: 1.1rem;
    font-weight: 600;
    height: 40px;
    border-radius: 0.5rem;
    text-align: center;
    padding-top: 0.4rem;
    background-color: var(--gray-50);
    z-index: 100;
}
</style>