<template>
    <div>
        <b-button class="goTop" v-if="isVisible" @click="backToTop">
            <fa icon="angle-up" aria-hidden="true"></fa> 맨 위로 이동
        </b-button>
    </div>
</template>
<script>
export default {
  data() {
    return {
        position: {x:0, y:0}
    }
  },
  computed: {
    isVisible: {
        get(){
            if(this.position.y <= 0.3) return false
            else return true
        }
    }
  },
  methods: {
    backToTop() {
      window.scrollTo({
          top: 0,
          behavior: 'smooth'
      })
    }
  },
  created(){
        if (!this.$isServer) {
        this._scrollListener = () => {
          // window.pageX/YOffset is equivalent to window.scrollX/Y, but works in IE
          // We round values because high-DPI devies can provide some really nasty subpixel values
          this.position = {
            x: Math.round(window.pageXOffset),
            y: Math.round(window.pageYOffset)
          }
        }

        // Call listener once to detect initial position
        this._scrollListener()

        // When scrolling, update the position
        window.addEventListener('scroll', this._scrollListener)
      }
  },
  mounted() {
  },
  beforeDestroy() {
    // Detach the listener when the component is gone
    window.removeEventListener('scroll', this._scrollListener)
  }
}
</script>
<style scoped>
.goTop {
  border-radius: 5px;
  background-color: rgba(0,0,0,0)!important;
  position: fixed;
  width: 92px;
  height: 30px;
  display: block;
  left:calc(50vw - 46px);
  bottom: 2.5rem;
  border: none;
  padding: 0.15rem;
  z-index: 999;
  color: #3f3f3f;
}

.goTop .fa {
    color: #3f3f3f;
    font-size: 40px;
}

.goTop:hover {
    background-color: rgba(0,0,0,0.5)!important;
}


</style>