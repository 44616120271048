
import { library } from '@fortawesome/fontawesome-svg-core'
//import { fas } from '@fortawesome/free-solid-svg-icons'
import { faCheck, faHourglass, faSignInAlt, faSignOutAlt, faCaretLeft, faCaretRight,faInfo, faInfoCircle, faSave,faEdit, faPen, faTrashAlt, faSearch, faPlus, faAngleDoubleUp, faAngleDoubleDown, faAngleDoubleRight, faAngleDoubleLeft, faArrowAltCircleDown, faAngleRight,faAngleLeft, faAngleDown, faAngleUp, faUpload, faCheckCircle as fasCheckCircle, faDownload, faEllipsisH, faArrowRight, faArrowLeft
        , faCloudUploadAlt, faStream, faFolder, faFolderOpen, faAddressBook as fasAddressBook, faListAlt, faCommentAlt, faSyncAlt, faPaperclip, faDoorOpen, faBook, faQuestion, faBullhorn, faCommentDots as fasCommentDots, faPaperPlane, faChartPie, faHome, faUserCog, faUserEdit, faUserCheck, faUser as fasUser, faUsers, faCompass, faInbox, faEnvelope, faEnvelopeOpen, faPhone, faMapMarkerAlt
        , faMinus, faLock, faLockOpen, faFlag, faBuilding as fasBuilding, faPlay, faStop, faGripVertical, faChartBar, faChartLine, faGlobeAsia, faDiceD20, faFileExport, faGlobe, faFileAlt, faFileContract, faRetweet, faPause, faBold, faItalic, faStrikethrough, faUnderline, faCubes, faParagraph, faListUl, faListOl, faQuoteRight, faCode, faRulerHorizontal, faUndo, faRedo, faTable,
        faCaretUp, faCaretDown, faPlusSquare as fasPlusSquare, faCalendarAlt, faList, faHeadset, faQuoteLeft, faLink, faChalkboardTeacher, faCopyright, faCopy, faHistory, faUserPlus, faCheckSquare, faMinusSquare as fasMinusSquare, faUserTie, faSort, faSortDown, faSortUp, faPrint, faExclamationCircle, faMapMarkedAlt, faCarSide, faRocket, faParking, faMapMarker, faExpand,
        faSortAmountDownAlt, faSortAmountUpAlt, faExclamationTriangle, faUserSecret, faGrinAlt, faTemperatureLow, faDove, faSquare as fasSquare, faTasks, faBookOpen
        } from '@fortawesome/free-solid-svg-icons'

import { faUser as farUser, faBell as farBell, faEye as farEye, faFlag as farFlag, faBuilding as farBuilding, faAddressBook as farAddressBook, faCommentDots as farCommentDots, faCheckCircle as farCheckCircle, faPlusSquare as farPlusSquare,faMinusSquare as farMinusSquare,
            faSquare as farSquare
        } from '@fortawesome/free-regular-svg-icons'

import { faConnectdevelop, faDigitalOcean, faHubspot
        } from '@fortawesome/free-brands-svg-icons'


import '@/assets/custom.scss'
import '@/assets/master.css'
import '@/assets/master_basic.css'
import '@/assets/master2.css'
import '@/assets/master3.css'


library.add(faCheck, faHourglass, faSignInAlt, faSignOutAlt, faCaretLeft, faCaretRight,faInfo, faInfoCircle, faSave, faEdit,faPen, faTrashAlt, faSearch, faPlus, faAngleDoubleUp, faAngleDoubleDown, faAngleDoubleRight, faAngleDoubleLeft, faArrowAltCircleDown, faAngleRight, faAngleLeft, faAngleDown, faAngleUp, faUpload, fasCheckCircle, faDownload, faEllipsisH, faArrowRight, faArrowLeft
    , faCloudUploadAlt, faStream, faFolder, faFolderOpen, fasAddressBook, faListAlt, faCommentAlt, faSyncAlt, faPaperclip, faDoorOpen, faBook, faQuestion, faBullhorn, fasCommentDots, faPaperPlane, faChartPie, faHome, faUserCog, faUserEdit, faUserCheck, fasUser, faUsers, faCompass, faInbox, faEnvelope, faEnvelopeOpen, faPhone, faMapMarkerAlt, faMapMarkedAlt, faMapMarker, faExpand
    , faMinus, faLock, faLockOpen, faFlag, fasBuilding, faPlay, faStop, faGripVertical, faChartBar, faChartLine, faGlobeAsia, faDiceD20, faFileExport, faGlobe, faFileAlt, faFileContract, faRetweet, faPause, faBold, faItalic, faStrikethrough, faUnderline, faCubes, faParagraph, faListUl, faListOl, faQuoteRight, faCode, faRulerHorizontal, faUndo, faRedo, faTable, faList, faCarSide, 
    faRocket, faParking, faUserSecret, faGrinAlt, faTemperatureLow, faDove, fasSquare, faTasks, faBookOpen
    , faCaretUp, faCaretDown, fasPlusSquare, faCalendarAlt, faHeadset, faQuoteLeft, faLink, faChalkboardTeacher, faCopyright, faCopy, faHistory, faUserPlus, faCheckSquare, fasMinusSquare, faUserTie, faSortAmountDownAlt, faSortAmountUpAlt, faExclamationTriangle)
    
    library.add( farUser, farBell, farEye, farFlag, farBuilding, farAddressBook, farCommentDots, farCheckCircle, farPlusSquare,farMinusSquare, faSort, faSortDown, faSortUp, faPrint, faExclamationCircle,
        farSquare)
    
    library.add(faConnectdevelop, faDigitalOcean, faHubspot)
    //library.add( fas )