<template>
<div>
    <b-container class="text-right position-relative" v-if="answer_company.company_name != undefined"> 
        <audit-print-button :btn_variant="'subtop'" @modal-opened="is_printing=true" @modal-closed="is_printing=false" :d_none="is_printing==true? 'd-none':''">{{$t(`답변 전체 인쇄/다운로드`)}}<fa icon="print" class="ml-2"></fa></audit-print-button>
    </b-container>
    <b-container fluid :class="answer_audit_mode!='conclusion'?'m-0 px-2 px-md-5':'m-0 px-3 pl-md-5'" v-if="answer_company.company_name != undefined">
        <b-button @click="returnToSelect" class="mr-2" variant="dark-border">다른 기업 선택</b-button>
        <b-button-group v-if="is_admin>=3" class="">
            <!-- <b-button disabled :pressed="answer_audit_mode=='comment'" @click="changeMode('comment')" variant="mode" class="f-110">코멘트</b-button> -->
            <b-button :pressed="answer_audit_mode=='evaluation'" @click="changeMode('evaluation')" variant="mode" class="f-110">{{$t(`상세평가`)}}</b-button>
            <b-button :pressed="answer_audit_mode=='conclusion'" @click="changeMode('conclusion')" variant="mode" class="f-110">{{$t(`종합의견`)}}</b-button>
        </b-button-group>
        <div v-if="answer_audit_mode=='conclusion'" class="blue f-90 fw-500"><fa icon="exclamation-circle"></fa> {{$t(`종합의견은 결과보고서에 반영됩니다.`)}}
        </div>

        <h3 class="text-center pt-4 pb-5 gray-600" :style="answer_audit_mode=='conclusion'?'padding-right:2rem;':''"> {{answer_company.company_name}} {{$t(`답변`)}} </h3>
        <b-row class="d-flex align-items-stretch">
            <b-col :class="[answer_audit_mode=='conclusion'?'col-2':'col-3 col-xl-2','bgray-100 py-3']">
                <h5 class="pb-2 mb-3 border-bottom-gray-bold gray-500"> Questions</h5>
                <audit-question-list></audit-question-list>
                <hr class="border-0 bottom-space">
            </b-col>
            <b-col :class="[answer_audit_mode=='evaluation'? 'col-9 col-xl-10 position-relative':'narrowed bgray-100','evaluation-panel-1 position-relative py-3']" :style="is_admin<3?'background-color:#F8F8F8;':''">                
                <h5 class="pb-2 mb-3 border-bottom-gray-bold gray-500" :style="answer_audit_mode!='comment'&&is_admin>=3?'width:calc(50% - 15px)':'width:100%'"> Answers
                    <b-button variant="dark-border" size="sm" class="ml-3" v-if="$store.state.question_composition.question_code!=''&&is_admin>=3" @click="toggleAllowedEdit()" :disabled="is_admin<3">{{ is_allowed_edit_msg }}</b-button>
                </h5>
                <h5  v-if="is_admin>=3" :class="[answer_audit_mode=='conclusion'?'border-bottom-gray-bold gray-500':'border-bottom-dark-bold ', 'pb-2 mb-3 position-absolute pt-3']" style="right:15px; top:0;" :style="answer_audit_mode!='comment'?'width:calc(50% - 30px)':'display:none'"> {{$t(`상세평가`)}} </h5>
                <div v-if="subs_loaded==true" class="" :key="answer_key">
                    <div v-for="item in subs" :key="item.index" class="pb-5 d-flex justify-content-between">
                        <!-- 기업 답변 영역 -->
                        <div class="border-0 mb-3 p-3" :class="is_admin>=3?'w-50':'w-100'" >
                            <sub-component
                            :ref="item.sub_code" 
                            :sub_type="item.sub_type"
                            :item="item"
                            :is_disabled_by_parent="is_disabled_answer_edit"
                            :audit_mode="audit_mode"
                            ></sub-component>
                        </div>

                        <!-- 상세평가 영역 -->
                        <div v-if="is_admin>=3" class="border-0 bm-3 w-50 p-3">
                            <evaluation-component 
                            :sub_code="item.sub_code"
                            :sub_description="item.sub_description"
                            :disabled="answer_audit_mode=='conclusion'"
                            >
                            </evaluation-component>
                        </div>                        
                    </div>
                </div>
                <div v-else-if="$store.state.question_composition.question_code==''" class="py-5 text-center bgray-200 rounded">{{$t(`Questions에서 질문명을 클릭하여 평가를 시작하세요`)}}</div>
                <div v-else class="text-center"><b-spinner class="my-5 gray-500"></b-spinner></div>
                <div :class="subs_loaded && subs.length > 0 ? 'bg-half-gray100':''"></div>
                <hr class="border-0 bottom-space">
            </b-col>
            <template v-if="answer_audit_mode=='conclusion'">
                <b-col :class="[answer_audit_mode!='conclusion'? '':'opening py-3 pr-0','conclusion-panel-1']">
                    <div class="position-sticky conclusion-sticky pr-3 pb-5">
                        <h5 class="pb-2 mb-3 border-bottom-dark-bold"> {{$t(`평가결론`)}} </h5>
                        <audit-conclusion></audit-conclusion> 
                        <hr class="border-0 mb-4">
                    </div>
                </b-col>
            </template>
        </b-row>   
    </b-container>

    <!-- 기업 선택 화면 -->
    <b-container v-else>
            <h4 class="pt-5 pb-5 fw-600"> {{$t(`답변 확인할 기업 선택`)}} </h4>
            <b-row class="mb-5">
                <b-col cols="0" md="2" lg="3"></b-col>
                <b-col cols="12" md="8" lg="6">
                    <b-input-group> 
                        <b-form-input v-model="search_keyword" placeholder="검색할 기업명 입력"></b-form-input>
                        <b-input-group-append><b-button variant="icon-append"><fa icon="search"></fa></b-button></b-input-group-append>
                    </b-input-group>
                </b-col>
                <b-col cols="0" md="2" lg="3"></b-col>
            </b-row>            

            <h5 class="pt-4 pb-2 border-bottom">{{$t(`참여 기업`)}} ({{ filtered_companies.length }}{{$t(`개사`)}}) </h5>
            <b-button variant="dark-border" v-for="(item, index) in filtered_companies" :key="index" class="mr-2 mb-3 py-1 px-2 " @click="selectCompany(item)"> {{ item.company_name }}
            </b-button>
            <hr class="border-0 bottom-space">
    </b-container>
    <!--  -->

</div>
</template>

<script>
import TemplateTree from '../editing_template/TemplateTree'
import AuditConclusion from './answer_review/AuditConclusion'
import EvaluationComponent from './answer_review/EvaluationComponent'
import AuditPrintButton from '@/components/print_to_pdf/AuditPrintButton'
import { mapState, mapActions, mapMutations } from 'vuex'
import * as ih from '@/util'
import AuditQuestionList from './answer_review/AuditQuestionList.vue'

export default {
    data() {
        return {
            search_keyword: '',
            answer_audit_mode: 'evaluation',
            //is_company_selected:false
            is_printing: false,
            is_allowed_edit_msg: this.$t(`수정하기`),
            is_disabled_answer_edit: true,
            audit_mode: true,
            answer_key: 11314
        }
    },
    computed:{
        ...mapState('questions',['subs', 'subs_loaded','answer_company' ] ),
        ...mapState('score',['temp_target_companies']),
        campaign() {
            return this.$store.state.campaign
        },
        filtered_companies(){
            if(this.search_keyword=='') { return this.temp_target_companies }
            else{
                let return_array = []
                return_array = this.temp_target_companies.filter(item => item.company_name.includes(this.search_keyword))
                return return_array
            }
        },
        is_admin() {
            return this.$store.getters.isAdmin
        },
    },
    methods:{
        ...mapActions('score', ['readAllEvaluateResult']),
        ...mapMutations('questions',['reset_subs','update_answer_company', 'change_edit_mode']),
        ...mapMutations(['update_question_code']),
        async selectCompany(target_company){
            // let index = this.temp_target_companies.findIndex(item => { return item.company_id == target_company.company_id } )
            //이전에 보던 기업의 답변이 있다면 subs를 초기화
            this.reset_subs()
            // Audit 영역에서 그려줄 Company의 정보를 company_id로 보냄
            this.update_answer_company(target_company)    
            await this.$store.dispatch('makeTemplateTreeByCompanyId', target_company.company_id)

            this.readAllEvaluateResult({campaign_id: this.campaign.campaign_id, company_id: target_company.company_id})
            //TemplateTree.vue에서 Tree를 noneditable하도록 store.quesitons에 state 설정
            this.change_edit_mode(false) 

            this.is_company_selected = true
        },
        returnToSelect(){
            this.update_question_code('')
            this.update_answer_company({})  
        },
        changeMode(mode){
            this.answer_audit_mode = mode
        },
        toggleAllowedEdit(){
            this.is_disabled_answer_edit = !this.is_disabled_answer_edit
            this.audit_mode = !this.audit_mode
            if(this.is_disabled_answer_edit==false)
            {
                this.is_allowed_edit_msg = this.$t(`수정완료`)
            }
            else{
                this.is_allowed_edit_msg = this.$t(`수정하기`)
            }
            this.answer_key += 1
        }
    },
    created(){

    },
    mounted(){
        // console.log(this.temp_target_companies.length);
    },
    beforeDestroy(){
        this.$store.commit('questions/update_answer_company', {})
        this.$store.commit('update_question_composition', {})
        this.is_company_selected = false
    },
    components:{
        TemplateTree, 
        AuditPrintButton,
        EvaluationComponent,
        AuditConclusion,
        AuditQuestionList
    },
}
</script>
<style>
.bg-half-gray100 {
    position:absolute;
    width:50%;
    height:100%;
    top:0;
    left:0;
    background-color: var(--gray-100);
    z-index:-1;
}
.conclusion-sticky{
    top: 70px;
    height: calc(100vh - 70px);
    overflow: auto;
}
</style>
