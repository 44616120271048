<template>

<div>
    <div class="py-3 fw-500 d-flex align-items-center">
        <fa icon="comment-alt" class="mr-2" style="height: 1.2em"></fa>
        <div class="w-100 border-bottom text-left"> {{ question_in_progress.question_name }} </div>
    </div>

    <!--기존 코멘트 순서대로 불러오기 -->
    <comment-item v-for="item in comments" :key="item.comment_code" :c_item="item">
    </comment-item>
        
    <!-- 코멘트 추가 입력박스 불러오기-->
        <!--<b-textarea v-model="new_comment_contents" rows="3" class="hj-text-smr form-control"></b-textarea>-->
    <mention-text-area :key="question_code" id="write-new-comment"></mention-text-area>
    <b-tooltip target="write-new-comment" placement="bottom"> 코멘트 및 메모 작성하기 </b-tooltip>

</div>

</template>

<script>
import MentionTextArea from './MentionTextArea'
import CommentItem from './CommentItem'
import * as ih from '../../../util'
import * as th from 'tree-helper'
//import axios from 'axios'

export default {

    data(){
        return {
            user_target: '',
            comments: [],
            is_modify_idx: '',
            is_side_collapsed: false,

            new_comment_admin_only: false,
            comment_code: 'c' + Math.floor(Math.random() * 1000000000),

            parsed_mentions: [],


        } // end of data return 
    }, // end of data
    props: {
        is_reloaded: false
    },
    computed: {
        user_name() {
            return this.$store.getters.getUserName
        },
        company_name() {
            if(this.$store.state.view_mode != 'user') //provider
                { return this.$store.state.company_name }
            else { //'user'
                return this.$store.state.questions.answer_company.company_name
            }
        },
        company_id(){
            if(this.$store.state.view_mode != 'user') //provider
                { return this.$store.state.company_id }
            else { //'user'
                return this.$store.state.questions.answer_company.company_id
            }
        },
        e_mail(){
            return this.$store.getters.getEMail
        },
        user_id(){
            return this.$store.state.user_id
        },
        isAuthenticated(){
            return this.$store.getters.isAuthenticated
        },
        isLoggedIn() {
            return this.$store.getters.isLoggedIn
        },
        is_admin(){
            return this.$store.getters.isAdmin
        },
        template_in_progress(){
            return this.$store.state.template_in_progress
        },
        question_in_progress() {
                return this.$store.state.question_in_progress
        },
        question_code(){
            return this.$store.state.question_in_progress.question_code
        },
        campaign_id(){
            if(this.$store.state.view_mode != 'user') //provider
                { return this.$store.state.campaign_in_progress.campaign_id }
            else { //'user'
                return this.$store.state.campaign.campaign_id
            }

        },
        all_space(){
            return this.$store.getters.getAllSpace
        },
        isIE() {
            if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (agent.indexOf("msie") != -1) )
                {
                    return true
                }
            else
                { 
                    return false
                }
        }
    },
    watch: {
        question_code(new_question_code, old_question_code){
            this.comments = []
            this.readComments(this.e_mail, new_question_code, this.is_admin)
            .then( () => { this.makeRead(this.comments)  } )
        }
    },
    methods: {
        saveComment(e_mail, question_code, new_comment_contents, comment_code, admin_only){
            const path = this.$store.state.backend_host + '/save_comment'

            if(comment_code == 'new') { comment_code = 'c' + Math.floor(Math.random() * 1000000000) }

            //불리언으로 변경
            if (admin_only == 'true') admin_only = true
            else admin_only = false

            //멘션 체크 & Parsing
            this.parseMention(new_comment_contents)

            //예외처리
            this.parsed_mentions.forEach( item => {
                if(item.target_department_id == undefined) item.target_department_id = 0
                if(item.target_department_name == undefined) item.target_department_name = ""
                if(item.target_user_id == undefined) item.target_user_id = 0
                if(item.target_user_name == undefined) item.target_user_name = ""
                if(item.target_company_id == undefined) item.target_company_id = 0
                if(item.target_company_name == undefined) item.target_company_name = ""
            })

            const formData = new FormData()
            formData.append('e_mail',e_mail)
            formData.append('campaign_id', this.campaign_id)
            formData.append('company_id', this.company_id)
            formData.append('question_code',question_code)
            formData.append('contents', JSON.stringify(new_comment_contents))
            formData.append('comment_code', comment_code )
            formData.append('admin_only', admin_only)
            formData.append('mentions', JSON.stringify(this.parsed_mentions))

            axios.post(path, formData)
            .then(response => {

                EventBus.$emit('toast-success', '성공', '코멘트를 성공적으로 저장하였습니다.')

                let new_comment_item = {
                    author_id: this.user_id,
                    author_e_mail: e_mail,
                    campaign_id: response.data.campaign_id,
                    company_id: response.data.company_id,
                    question_code: question_code,
                    contents: new_comment_contents, 
                    time_stamp: response.data.time_stamp,
                    comment_code: comment_code,
                    admin_only: admin_only,
                    is_admin: this.is_admin,
                    author_name: this.user_name
                }

                //오른쪽 뉴 코멘트 아이템 추가
                let idx = this.comments.findIndex(item => { return item.comment_code == comment_code })
                if( idx > -1 ) this.comments.splice(idx, 1, new_comment_item)
                else this.comments.push(new_comment_item)

                //화면 트리의 새 코멘트 개수 업데이트 하고 색깔 바꾸기
                let new_template_in_progress = { ...this.template_in_progress }
                th.breadthFirstSearch(new_template_in_progress.template_contents, node => {
                    if(node.question_code==question_code){
                        Vue.set(node, 'number_of_comment', node.number_of_comment + 1)
                        Vue.set(node, 'number_of_new_comment', node.number_of_new_comment +1)
                    }
                })
                this.$store.commit('update_template_in_progress', new_template_in_progress)
                EventBus.$emit('refresh-tree') //for tree update
            })
            .catch(error => {
                if (error.response && error.response.status == '401')
                {
                    alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                    this.dispatch('logout').then(() => { this.$router.push({name: 'Login'})} )
                }
                else console.log(error)
            })
            .finally( () => {
                //이메일 보내기 처리
                console.log(this.parsed_mentions)
            })
        },
        readComments(e_mail, question_code, is_admin){
            const path = this.$store.state.backend_host + '/read_comments'

            return axios.get(path, {
                            params: {
                                e_mail, 
                                question_code, 
                                is_admin,
                                campaign_id: this.campaign_id,
                                company_id: this.company_id
                            }
            })
            .then(response => {
                let received_comments = response.data 
                received_comments.forEach( item => {
                    return item.contents = JSON.parse(item.contents)
                })
                this.comments = received_comments
                //this.comments.push(received_comments)
                //'author_id': item.user.id,
                //'author_e_mail' : item.e_mail,
                //'question_code' : item.question_code,
                //'contents' : item.contents,
                //'time_stamp' : str(item.time_stamp),
                //'comment_code' : item.comment_code,
                //'admin_only': item.admin_only,
                //'is_admin' : item.user.is_admin,
                //'author_name' : item.user.user_name,
            })
            .catch(error => {
                if (error.response && error.response.status == '401')
                    { alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                    this.dispatch('logout').then(() => { this.$router.push({name:'Login'}) })
                }
                else console.log(error)
            })
        },
        deleteComment(comment_code){
            const path = this.$store.state.backend_host + '/delete_comment'

            const formData = new FormData()
            formData.append('comment_code',comment_code)

            return axios.post(path,formData)
            .then( response => {
                let idx = this.comments.findIndex(item => { return item.comment_code === comment_code })
                if(idx >-1) this.comments.splice(idx, 1)
                EventBus.$emit('toast-success','성공','코멘트를 성공적으로 삭제하였습니다.')

                //화면 트리의 새 코멘트 개수 업데이트 하고 색깔 바꾸기
                let new_template_in_progress = { ...this.template_in_progress }
                th.breadthFirstSearch(new_template_in_progress.template_contents, node => {
                    if(node.question_code==this.question_code){
                        Vue.set(node, 'number_of_comment', node.number_of_comment - 1 )
                        Vue.set(node, 'number_of_new_comment', 0 )
                    }
                })
                this.$store.commit('update_template_in_progress', new_template_in_progress)
                EventBus.$emit('refresh-tree') //for tree update

            })
            .catch(error => {
                if (error.response.status == '401')
                {
                    alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                    this.dispatch('logout').then(() => { this.$router.push({name: 'Login'})} )
                }
                else console.log(error.response)
            })
        },
        makeRead(comments){
            const path = this.$store.state.backend_host + '/make_read'
            let promises = []

            for(var i=0; i<comments.length; i++){
                let formData = new FormData()
                formData.append('user_id', this.user_id)
                formData.append('comment_code', comments[i].comment_code)

                let new_promise = axios.post(path, formData)               
                .catch(error => {
                    console.log(error.response)
                })

                promises.push(new_promise)
            }

            let new_template_in_progress = { ...this.template_in_progress }

            return Promise.all(promises)
            .then(
                responses => {
                    for (let j=0; j< responses.length; j++){
                        if(responses[j].data.response_message == 'newly read'){
                            this.comments[j].is_new = true        
                            //화면 트리의 새 코멘트 개수 업데이트 하고 색깔 바꾸기
                            th.breadthFirstSearch(new_template_in_progress.template_contents, node => {
                                if(node.question_code==this.question_code){
                                    Vue.set(node, 'number_of_new_comment', node.number_of_new_comment-1)
                                }
                            })
                        }
                    }
            })
            .finally( () => {
                this.$store.commit('update_template_in_progress', new_template_in_progress)
                EventBus.$emit('refresh-tree') //for tree update
            })
        },
        toggleSide(){
            this.is_side_collapsed = !this.is_side_collapsed
            EventBus.$emit('toggle-side', this.is_side_collapsed)
        },
        parseMention(comment_object){
            this.parsed_mentions = []

           for(let i=0; i<comment_object.content.length; i++)
           {   let parse_target = comment_object.content[i]

                if( parse_target.hasOwnProperty('content') ) {
                    parse_target.content.forEach( item => {
                        if(item.type === 'mention') {
                            //멘션id를 스페이스에서 찾음
                            let idx = this.all_space.findIndex(space_item => { return space_item.id == item.attrs.id })
                            if(idx > -1) {
                                let new_parsed_mention = {
                                    id: item.attrs.id,
                                    mode: this.all_space[idx].mode,
                                    target_user_id: this.all_space[idx].user_id,
                                    target_user_name: this.all_space[idx].user_name,
                                    target_department_id: this.all_space[idx].department_id,
                                    target_department_name: this.all_space[idx].department_name,
                                    target_company_id: this.all_space[idx].company_id,
                                    target_company_name: this.all_space[idx].company_name 
                                }
                                this.parsed_mentions.push(new_parsed_mention)
                                //console.log(this.parsed_mentions)
                            }
                        }
                    })
                }
            }
        }

    },
    created(){
        this.readComments(this.e_mail,this.question_code,this.is_admin)
        .then( () => {
            this.makeRead(this.comments)
        })

        EventBus.$on('read-comments', this.readComments)
        EventBus.$on('save-comment', this.saveComment)
        EventBus.$on('delete-comment', this.deleteComment)
    },
    components:{
        MentionTextArea,
        CommentItem
    }


}
</script>