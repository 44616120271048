<template>
<div>
    <section v-for="(item, i) in local_company.score_contents" :key="i" class="mb-5 ">
        <div v-if="item.question_name!='기본정보'&&item.question_name!='기본 정보'&&item.question_name!='I. 기본 정보'" class="border-bottom">
            <b-navbar class="d-flex justify-content-between align-items-center border-bottom-dark-bold py-0">
                <h4 class="mb-0">{{ $t(item.question_name) }}</h4> 
                <div :class="item.score_result != null ?'f-220':'gray-500 py-2'">{{ item.score_result != null ? Math.round(item.score_result) : $t(`해당없음`) }} <span class="f-90 gray-700"> / 100</span></div>
            </b-navbar>
            <b-row no-gutters class="d-flex align-items-stretch" v-for="(item, index) in item.children" :key="index" >
                <b-col md="4" class=" border-right border-top py-2 px-3 d-flex justify-content-between align-items-start">
                    <div class="pr-3">{{ $t(item.question_name) }}</div>
                    <div :class="[item.score_result != null?'f-180':'f-100 gray-500', 'fw-300 mb-0']"> {{ item.score_result != null ? Math.round(item.score_result) : $t(`해당없음`) }}<span class="f-85 gray-700"> / 100</span> </div>
                </b-col>
                <b-col md="8" class="p-0">
                    <div v-if="is_evaluate_loaded==true" class="px-3 py-2 border-top d-flex f-95">
                        <div class="f-90 factor-min-width"> <fa :icon="['far','plus-square']" class="mr-2"></fa>{{$t(`우수사항`)}}</div>
                        <div v-if="item.score_result == null || get_plus_factor[item.question_code].length==0" class="f-90 ml-2 ml-md-3 gray-500">{{$t(`없음`)}}</div>
                        <div v-else class="ml-2 ml-md-3">                        
                            <!-- <div v-for="x in get_plus_factor[item.question_code]" :key="x.id" :class="isIE==true?'mb-1 d-inline-block':'mb-1 d-flex align-items-start'" > 
                                <b-badge variant="basic" class="mt-1 mr-2">충족</b-badge> 
                                <span>{{ x.evaluation_name }}</span>
                            </div> -->
                            <b-table-simple class="mb-0 txt-table">
                                <b-tbody>
                                    <b-tr v-for="x in get_plus_factor[item.question_code]" :key="x.id">
                                        <b-td><b-badge variant="basic" class="mt-1 mr-2">{{$t(`충족`)}}</b-badge> </b-td>
                                        <b-td class="text-left">{{ $i18n.locale=='ko'? x.evaluation_name : x.evaluation_name_e }} <b-badge class="bg-transparent mt-1 ml-1" variant="emerging" v-if="checkLaws(x.ev_id)" v-b-popover.hover.top.html="renderLaw(x.evaluation_name)" :title="$t(`관련 법규`)">{{$t(`법규`)}}</b-badge></b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </div>
                    </div>
                    <div v-if="is_evaluate_loaded==true" class="px-3 py-2 border-top d-flex f-95">
                        <div class="f-90 factor-min-width"> <fa :icon="['far','minus-square']" class="mr-2"></fa>{{$t(`개선필요`)}}</div>
                        <div v-if="item.score_result == null || get_minus_factor[item.question_code].length==0" class="f-90 ml-2 ml-md-3 gray-500">{{$t(`없음`)}}</div>
                        <div v-else class="ml-2 ml-md-3">                        
                            <!-- <div v-for="x in get_minus_factor[item.question_code]" :key="x.id" :class="isIE==true?'mb-1 d-inline-block':'mb-1 d-flex align-items-start'"> 
                                <b-badge v-if="x.score == 0 && x.defined_score > 0" variant="red5" class="mt-1 mr-2">미충족</b-badge>
                                <b-badge v-else-if="x.score < 0" variant="red" class="mt-1 mr-2">감점</b-badge>
                                <span v-if="x.score == 0 && x.defined_score > 0">  {{ x.reverse_name }} <b-badge class="bg-transparent mt-1 ml-1" variant="emerging" v-if="x.law_info!=null" v-b-popover.hover.top.html="'<b>' + x.law_title + '</b><br>' + x.law_info" :title="$t(`관련 법규`)">법규</b-badge>
                                </span>
                                <span v-else-if="x.score < 0 && x.defined_score < 0">{{ x.evaluation_name }} <b-badge class="bg-transparent mt-1 ml-1" variant="emerging" v-if="x.law_info!=null" v-b-popover.hover.top.html="'<b>' + x.law_title + '</b><br>' + x.law_info" :title="$t(`관련 법규`)">법규</b-badge> 
                                </span>
                            </div> -->
                            <b-table-simple class="mb-0 txt-table">
                                <b-tbody>
                                    <b-tr v-for="(x, idx) in get_minus_factor[item.question_code]" :key="x.id">
                                        <template v-if="x.score == 0&&x.reverse_name!=''">
                                            <b-td class="d-flex align-items-start"><b-badge v-if="x.score == 0 && x.defined_score > 0" variant="red5" class="mt-1 mr-2">{{$t(`미충족`)}}</b-badge></b-td>
                                            <b-td class="text-left">
                                                <span v-if="x.score == 0 && x.defined_score > 0"> {{ $i18n.locale=='ko'? x.reverse_name :  x.reverse_name_e}} 
                                                    <b-badge class="bg-transparent mt-1 ml-1" variant="emerging" v-if="checkLaws(x.ev_id)" v-b-popover.hover.top.html="renderLaw(x.evaluation_name)" :title="$t(`관련 법규`)">{{$t(`법규`)}}</b-badge>
                                                </span>
                                                <span v-else-if="x.score < 0 && x.defined_score < 0">{{ $i18n.locale=='ko'? x.evaluation_name : x.evaluation_name_e }} 
                                                    <b-badge class="bg-transparent mt-1 ml-1" variant="emerging" v-if="checkLaws(x.ev_id)" v-b-popover.hover.top.html="renderLaw(x.evaluation_name)" :title="$t(`관련 법규`)">{{$t(`법규`)}}</b-badge> 
                                                </span>
                                            </b-td>
                                        </template>
                                        <template v-else-if="idx==0&&get_minus_factor[item.question_code].length==1&&x.score==0&&x.reverse_name==''"><span class="f-90 gray-500">{{$t(`없음`)}}</span></template>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </div>
                    </div>
                    <!-- <div v-if="is_comment_loaded==true" class="px-3 py-2 border-top d-flex f-95">
                        <div class="f-90 factor-min-width"><fa icon="user-tie" class="mr-2"></fa>평가자 의견</div>
                        <div class="ml-2 ml-md-3"> {{ comment_by_question[item.question_code] }}</div>
                    </div> -->
                </b-col>
            </b-row>
        </div>
    </section>

</div>
</template>

<script>
import * as th from 'tree-helper'
import { mapState } from 'vuex'

export default {
    data() {
        return {
            // plus_factor: {  
                // "Rq185285675": ['evalution_name1','evalution_name2' ],
                // "q755135911": ['evalution_name3','evalution_name4' ]
            // },
            auditor_comment_loaded: false,
            law_info: [], 
            local_company: {}
            // [ { ev_id: '', title: '', contents: '' }]                
        }
    },
    props:{
        report_company:{type:Object}
    },
    computed:{
        ...mapState('cr_scores', ['reporting_company']),
        ...mapState('score', ['is_score_loaded','is_evaluate_loaded', 'is_comment_loaded', 
        'evaluate_results', 'category_names']
        ),
        campaign_in_progress() {
            return this.$store.state.campaign_in_progress
        },
        company_name(){
            return this.$store.state.company_name
        },
        template_contents(){
            return this.$store.state.template_tree.template_contents
        },
        // score_contents(){
        //     let temp = {}      
        //     let company_id = '' 
        //     if(this.local_company!=undefined){ company_id = this.local_company.company_id } 
        //     else (company_id = this.$store.state.company_id)
        //     // console.log(company_id);

        //     let idx = this.$store.state.score.temp_target_companies.findIndex(item => {return item.company_id == company_id })
        //     if(idx > -1){
        //         temp = this.$store.state.score.temp_target_companies[idx]
        //     }
        //     console.log(temp.score_contents);
        //     return temp.score_contents
        // },
        // company의 영역별 점수
        company_category_score(){
            let temp = {} 
            let company_id = '' 
            if(this.local_company!=undefined){ company_id = this.local_company.company_id }
            else (company_id = this.$store.state.company_id)
            // console.log(company_id);

            let idx = this.$store.state.score.temp_target_companies.findIndex(item => {return item.company_id == company_id })
            if(idx > -1){
                temp = this.$store.state.score.temp_target_companies[idx].category_score
            }
            return temp
        },
        get_plus_factor(){
            if (this.local_company.score_contents==undefined){
                return []
            }
            else {
                let q_code = []
                let plus_factor = []
                th.breadthFirstSearch(this.local_company.score_contents, node => {
                    if (node.question_code != undefined) {
                        q_code.push(node.question_code)
                    }
                })
                for (let y=0; y<q_code.length; y++){
                    let evaluation_array = []
                    this.evaluate_results.forEach(item => {
                        // console.log('q_code:' + q_code[y])
                        // console.log('item:' + item.question_code)
                        // console.log(q_code[y]==item.question_code ? true : false)
                        if (item.question_code == q_code[y] && item.score >0){
                            evaluation_array.push(item)
                        }
                    })
                    plus_factor[q_code[y]] = evaluation_array
                }            
                // console.log(plus_factor)    
                return plus_factor
            }
        },
        get_minus_factor(){
            if (this.local_company.score_contents==undefined){
                return []
            }
            else {
                let q_code = []
                let minus_factor = []
                th.breadthFirstSearch(this.local_company.score_contents, node => {
                    if (node.question_code != undefined) {
                        q_code.push(node.question_code)
                    }
                })
                for (let y=0; y<q_code.length; y++){
                    let evaluation_array = []
                    this.evaluate_results.forEach(item => {
                        if (item.question_code == q_code[y]) 
                        {
                            if( item.score < 0) { //감점 요인에 의해 감점당함
                                if(item.evaluation_name != '기타' && item.evaluation_name != '그 외') { evaluation_array.push(item) }
                                // console.log(item)
                            } 
                            else if(item.score == 0 && item.defined_score > 0){ //점수를 얻지 못함
                                if(item.evaluation_name != '기타' && item.evaluation_name != '그 외') { evaluation_array.push(item) }
                            }
                        }  
                    })
                    minus_factor[q_code[y]] = evaluation_array
                }
                // console.log(minus_factor)    
                return minus_factor
            }
        },
        comment_by_question(){
            if (this.local_company.score_contents==undefined){
                return []
            }
            else {
                let all_comments = this.$store.state.score.overall_comment
                let comment_by_question = []
                let q_code = []
                th.breadthFirstSearch(this.local_company.score_contents, node => {
                    if (node.question_code != undefined) {
                        q_code.push(node.question_code)
                    }
                })
                for (let y=0; y<q_code.length; y++){
                    all_comments.forEach(item => {
                        if (item.question_code == q_code[y]){ 
                            comment_by_question[q_code[y]] = item.overall_comment 
                        }
                    })
                }
                return comment_by_question
            }
        },
        isIE() {
            if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (navigator.userAgent.toLowerCase().indexOf("msie") != -1) )
                { return true }
            else
                { return false }
        },
        campaign_id(){
            if(this.$store.state.view_mode=='provider'){
                return this.$store.state.campaign_in_progress.campaign_id
            } 
            else
            {
                return this.$store.state.campaign.campaign_id    
            }
        }
    },
    methods:{
        checkLaws(ev_id){
            let idx = this.law_info.findIndex(item => item.ev_id == ev_id)
            if(idx > -1) { 
                return true 
            }
            else {
                return false
            }
        },
        renderLaw(evaluation_name){
            let string = ''
            this.law_info.forEach(item => {
                if(item.evaluation_name == evaluation_name){
                    string += '<b>' + this.$t(item.title) + '</b><br>' 
                    string += this.$t(item.contents)+ '<br><br>'
                }
            })
            return string
        },
        initiateLawInfo(){
            const path = this.$store.state.backend_host + '/read_law_info'

            return axios.get(path, { params: {
                campaign_id : this.campaign_id
            }})
            .then( response => {
                // console.log(response.data)
                this.law_info = response.data
            })
            .catch( error => console.log(error) )
        } 
    },
    created(){
        this.initiateLawInfo()
        // console.log(this.report_company);
    
        if(this.report_company==undefined||this.report_company.company_id==undefined){
            this.local_company = this.reporting_company
        }else this.local_company = this.report_company
        // console.log(this.evaluate_results);
        //console.log(this.get_plus_factor);
    }
    
   
}
</script>

<style>
.factor-min-width{
    min-width: 80px;
}

</style>