<template>
<div>
    <div class="w-100 bgray-200 mb-5 fw-400"> <h2 class="py-5 fw-400 text-center"> LIST OF QUESTION TEMPLATES</h2> </div>

    <template v-if="is_template_loaded==true">
        <b-container class="pt-3 mb-45 d-flex align-items-center justify-content-between"> 
            <h4 class="fw-600"> 생성/사용중인 질문지 </h4> 
            <b-button class="f-105 fw-500" variant="dark-border" @click="newTemplate" size="sm" v-if="is_admin>=4"><fa icon="plus" class="mr-2"></fa>새 질문지</b-button> 
        </b-container>

        <b-container class="py-0 py-md-3" v-if="on_going_templates != undefined&&archived_templates != undefined">
            <div v-if="on_going_templates != undefined" class="mb-5">
                <b-table-simple responsive hover class="rep-table">
                    <b-thead><b-tr class="">
                        <b-td style="width:15%">최종편집일</b-td><b-td>템플릿명</b-td><b-td style="width:23%">연결된 캠페인</b-td><b-td>편집</b-td><b-td>삭제</b-td>
                    </b-tr></b-thead>
                    <b-tbody><b-tr v-for="(item, index) in on_going_templates" :key="index" class="">
                        <b-td class="py-2 f-95" > {{ item.time_stamp}} </b-td>
                        <b-td :class="[item.type == 'owner'?'fw-500':'','py-2']"> {{ item.template_name }} </b-td>
                        <b-td class="f-95 py-2"> {{ item.campaign_name }} </b-td>
                        <!-- <b-td :class="[item.type == 'owner'?'fw-500':'fw-300 gray-600','py-2']"> {{ item.type == 'owner' ? '내 템플릿' : '보기 권한' }} </b-td> -->
                        <b-td class="py-2" v-if="checkAllowed(item.type, is_admin, 4)==true"> 
                            <b-button class="py-1 px-2" variant="dark-border" @click.stop="editTemplate(item)" small v-b-tooltip.hover.left title="수정/편집"><fa icon="pen"></fa></b-button>
                            <b-button class="py-1 px-2 ml-2" variant="dark-border" @click.stop="copyTemplate(item)" small v-b-tooltip.hover.right title="복제"><fa icon="copy"></fa></b-button>
                        </b-td>
                        <b-td class="py-2" v-if="checkAllowed(item.type, is_admin, 4)==true">
                            <b-button  class="py-1 px-2" variant="dark-border" @click.stop="deleteTemplate(item)" small v-b-tooltip.hover.right title="삭제"><fa icon="trash-alt"></fa></b-button>
                        </b-td>
                        <b-td v-else colspan="2"> 권한 없음</b-td>
                    </b-tr></b-tbody>
                </b-table-simple>
            </div>

            <hr class="border-0 pb-5">

            <div v-if="archived_templates != undefined">
                <h4 class="mb-5 gray-500 fw-600"> <span v-b-tooltip.hover title="캠페인이 종료되어 사용중이지 않은 질문지 입니다.">사용 종료된 질문지</span> </h4> 
                <b-table-simple responsive hover class="rep-table">
                    <b-thead><b-tr class="border-bottom-bold gray-600">
                        <b-td style="width:15%"> 최종편집일</b-td><b-td>템플릿명</b-td><b-td style="width:23%">연결된 캠페인</b-td><b-td>편집</b-td><b-td>삭제</b-td>
                    </b-tr></b-thead>
                    <b-tbody><b-tr v-for="(jtem, index) in archived_templates" :key="index" class="gray-550">
                        <b-td class="py-2 f-95"> {{ jtem.time_stamp }} </b-td>
                        <b-td class="py-2"> {{ jtem.template_name }} </b-td>
                        <b-td class="f-95 py-2"> {{ jtem.campaign_name }} </b-td>
                        <!-- <b-td :class="[jtem.type == 'owner'?'fw-500':'fw-300 gray-600','py-2']"> {{ jtem.type == 'owner' ? '내 템플릿' : '보기 권한' }} </b-td> -->
                        <b-td class="py-2" v-if="checkAllowed(jtem.type, is_admin, 4)==true"> 
                            <b-button class="py-1 px-2" variant="dark-border" @click.stop="editTemplate(jtem)" small v-b-tooltip.hover.left title="수정/편집"><fa icon="pen"></fa></b-button>
                            <b-button class="py-1 px-2 ml-2" variant="dark-border" @click.stop="copyTemplate(jtem)" small v-b-tooltip.hover.right title="복제"><fa icon="copy"></fa></b-button>
                        </b-td>
                        <b-td class="py-2" v-if="checkAllowed(jtem.type, is_admin, 4)==true">
                            <b-button  class="py-1 px-2" variant="dark-border" @click.stop="deleteTemplate(jtem)" small v-b-tooltip.hover.right title="삭제"><fa icon="trash-alt"></fa></b-button>
                        </b-td>
                        <b-td v-else colspan="2"> 권한 없음</b-td>
                    </b-tr></b-tbody>
                </b-table-simple>
            </div>
            <hr class="border-0 bottom-space">
        </b-container>
        <b-container class="px-0 px-md-5 py-0 py-md-3" v-else>
            <div class="mt-2 py-2 text-center gray-600 bgray-200"> 저장된 질문지가 없습니다. 새 질문지를 만들어 주세요.</div>
        </b-container>
    </template>
    <template v-else class="py-5">
        <b-container class="px-0 px-md-5 py-0 py-md-3">
            <b-skeleton-table
                :rows="4"
                :columns="4"
                :table-props="{ bordered: false, striped: false }"
            ></b-skeleton-table>
        </b-container>
    </template>
</div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
    data(){
        return {
            all_templates: [],
            is_template_loaded: false,
            deletable_item: ''
        }
    },
    props: {
        initiate_list: { type: Boolean, default:true }
    },
    computed: {
        user_id() {
            return this.$store.state.user_id
        },
        is_admin() {
            return this.$store.getters.isAdmin
        },
        on_going_templates(){
            let temp_on_going = this.all_templates.filter(item => item.campaign_state!='종료')     
            return temp_on_going.sort((b,a) => new Date(a.time_stamp).getTime() - new Date(b.time_stamp).getTime())
        },
        archived_templates(){
            let temp_archived = this.all_templates.filter(item => item.campaign_state=='종료')
            return temp_archived.sort((b,a) => new Date(a.time_stamp).getTime() - new Date(b.time_stamp).getTime())
        }
    },
    methods: {
        ...mapMutations('questions', ['change_edit_mode']),
        readTemplates(user_id){
            // console.log(this.user_id);
            const path = this.$store.state.backend_host + '/read_template_list'
            return axios.get(path, {
                            params: {
                                user_id:user_id
                              }
            })
            .then(response => {
                let result = response.data.templates
                let all_templates = []
                
                for (var i=0; i<result.length; i++){
                    let parsed_template = {}
                    parsed_template.template_id = result[i].template_id
                    parsed_template.template_name = result[i].template_name
                    if(result[i].template_contents != ''){
                        parsed_template.template_contents = JSON.parse(result[i].template_contents)
                    }
                    else {
                        parsed_template.template_contents = []
                    }
                    parsed_template.user_id = result[i].user_id
                    parsed_template.template_code = result[i].template_code
                    parsed_template.type = result[i].type
                    parsed_template.time_stamp = result[i].time_stamp,
                    parsed_template.campaign_name = result[i].campaign_name
                    parsed_template.campaign_state = result[i].campaign_state

                    all_templates.push(parsed_template)
                }
                this.all_templates = all_templates.filter(item => item.user_id!=null)
                // console.log(this.on_going_templates);
                
                this.is_template_loaded = true
            })
            .catch(error => {
                console.log(error)
                if (error.response.status == '401')
                    { alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                    this.$store.dispatch('logout').then(() => { this.$router.push({name:'Login'}) })
                }
            })
        },
        editTemplate(new_template){
            this.change_edit_mode(true)
            this.$store.commit('update_template_tree', new_template)
            this.$router.push({name: 'EditingTemplate', params: { value_new: false}} )
        },
        deleteTemplate(item){
            let result = confirm( `${item.template_name} 템플릿을 삭제하시겠습니까?`);
            if(result){
                const path = this.$store.state.backend_host + '/delete_template'

                let formData = new FormData()
                formData.append('template_id', item.template_id)
                return axios.post(path, formData)            
                .then( response => {
                    EventBus.$emit('toast-success', '알림', `${item.template_name}을 성공적으로 삭제하였습니다.`)

                    const i = this.all_templates.findIndex( (x) => x.template_id == item.template_id )
                    if (i > -1) this.all_templates.splice(i, 1)
                    }
                )    
            }         
        },
        copyTemplate(item){
            const path = this.$store.state.backend_host + '/copy_template'

            let formData = new FormData()
            formData.append('template_code', item.template_code)

            return axios.post(path, formData)
            .then(response => {
                // console.log(response.data)
                this.readTemplates(this.user_id)
            })
            .catch(error => {
                console.log(error)
            })
        },
        newTemplate(){
            this.change_edit_mode(true)
            this.$router.push({ name: 'EditingTemplate', params: { value_new: true }})
        },
        checkAllowed(campaign_type, user_admin_level, target_admin_level){
            if(user_admin_level >= 4) { return true }
            if(campaign_type=='owner') { return true }
            // if(campaign_type=='auditor' && user_admin_level < target_admin_level){
            //     return false
            // }
            else { return false }
        },        
        // saveTemplates(){
        //     const path = this.$store.state.backend_host + '/save_templates'

        //     const formData = new FormData()

        //     let templates_id = []
        //     for(let i=0; i<this.on_going_templates.length; i++)
        //         templates_id.push(this.on_going_templates[i].template_id)

        //     formData.append('user_id', this.$store.state.user_id)
        //     formData.append('templates_id', JSON.stringify(templates_id))

        //     return axios.post(path, formData)
        //     .then(response => {
        //         // console.log(response.data)
        //     })
        //     .catch(error => {
        //         console.log('axios 실패')
        //         console.log(error)
        //         if (error.response.status == '401')
        //             { alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
        //              this.$store.dispatch('logout').then(() => { this.$router.push({name:'Login'}) })
        //         }
        //     })

        // },
    },
    created() {
        this.readTemplates(this.user_id)
        this.$store.commit('questions/update_answer_company', {})
    },

}
</script>

<style scoped>

</style>
