<template>
<div id="action_plan_b"> 
        <div class="d-flex justify-content-between mb-3">
            <h5> {{ findCompanyName(company_id) }} 개선과제 </h5>

            <div v-if="is_pa_loaded==true" class="float-right">
                <b-button v-if="is_confirmed!=true&&is_printing!=true" variant="blue-border" @click="confirmPlan" v-b-tooltip.hover title="클릭하여 개선과제를 승인하세요"> 개선과제 승인하기 </b-button>
                <template v-else-if="is_confirmed==true&&is_printing!=true"> 
                    <span class="f-90 red">승인된 개선과제입니다.</span> <b-button variant="red-border" class="ml-2" @click="confirmPlan"> 승인 취소</b-button>
                </template>
                <general-print-button @modal-opened="is_printing=true" @modal-closed="is_printing=false" :d_none="is_printing==true? 'd-none':''" 
                 print_target="action_plan_b" class="py-1 d-inline ml-3" :btn_variant="'dark-border'" >{{$t(`출력/다운로드`)}} <fa icon="print" class="ml-2"></fa></general-print-button>
            </div>
           
        </div>
        <div v-for="(category, index) in template_in_progress.template_contents" :key="category.id" class="pb-4">
            <template v-if="has_improvment_category(index)==true">
                <div class="bgray-300 pl-2 py-1 mb-4 f-110"> {{ category.question_name }} </div>
                <template v-if="is_pa_loaded==true">
                    <b-table-simple class="light-thead table-text-left f-95 mb-5">
                        <b-thead><b-tr>
                            <b-td style="width:20%">평가기준 미충족 문항</b-td> <b-td style="width:38%">미충족 사항 및 현장평가 확인사항</b-td> <b-td>개선계획 작성</b-td>
                        </b-tr></b-thead>
                        <b-tbody v-for="question in category.children" :key="question.id"> 
                            <b-tr v-if="has_improvment_contents(question.question_code)==true">
                                <b-td><span class="fw-500 f-100">{{ question.question_name }}</span></b-td>
                                <b-td class="py-0" colspan="2">
                                    <div v-for="sub in JSON.parse(question.question_contents)" :key="sub.id" :class="ev_results_hash[sub]?'py-3 border-bottom-dashed':''"  class="d-flex">
                                        <template v-if="ev_results_hash[sub]">   
                                            <div style="width:47.5%">
                                                <div v-for="ev in ev_results_hash[sub].evaluation_results" :key="ev.id" class="mb-3">
                                                    <p class="gray-550 mb-1"><b-badge variant="red5" class="mr-2">미충족</b-badge>{{ ev.reverse_name }}</p>                            
                                                </div>
                                                <p v-if="ev_results_hash[sub].review_comment" class="mb-0 f-90 gray-550"> 실사 확인 내용</p>
                                                <p v-if="ev_results_hash[sub].review_comment" class="bgray-200 mb-2 py-1 px-2 f-95">{{ ev_results_hash[sub].review_comment }} </p>
                                                <p v-if="ev_results_hash[sub].task_comment" class="mb-0 f-90 gray-550"> 개선 권고사항</p>
                                                <p v-if="ev_results_hash[sub].task_comment" class="bgray-200 mb-2 py-1 px-2 f-95">{{ ev_results_hash[sub].task_comment }} </p>
                                            </div>
                                            <div style="width:calc(52.5% - 15px)" class="ml-3">                                                
                                                <span v-if="action_plan_hash[sub].time_horizon">
                                                    <span v-if="action_plan_hash[sub].time_horizon=='단기'" class="mr-2 circle px-2 f-90 bgray-700 text-white" >{{ action_plan_hash[sub].time_horizon }}</span>
                                                    <span v-else class="mr-2 circle px-2 f-90" :class="action_plan_hash[sub].time_horizon=='중기'? 'bgray-550 text-white':'bgray-400 text-white'">{{ action_plan_hash[sub].time_horizon }}</span>
                                                </span>
                                                <span v-if="action_plan_hash[sub].action_plan!=''"> {{action_plan_hash[sub].action_plan}} </span>
                                                <span v-else class="gray-500">(작성된 개선과제가 없습니다.) </span>
                                            </div>
                                        </template>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </template>
                <template v-else>
                    <b-table-simple class="light-thead table-text-left f-95 mb-5">
                        <b-thead><b-tr>
                            <b-td style="width:20%">평가기준 미충족 문항</b-td> <b-td style="width:38%">미충족 사항 및 현장평가 확인사항</b-td> <b-td>개선계획 작성</b-td>
                        </b-tr></b-thead>
                        <b-tbody>
                            <b-tr><b-td><b-skeleton height="20px" class="mb-4"></b-skeleton></b-td><b-td><b-skeleton height="20px" class="mb-4"></b-skeleton></b-td><b-td><b-skeleton height="20px" class="mb-4"></b-skeleton></b-td></b-tr>
                            <b-tr><b-td><b-skeleton height="20px" class="mb-4"></b-skeleton></b-td><b-td><b-skeleton height="20px" class="mb-4"></b-skeleton></b-td><b-td><b-skeleton height="20px" class="mb-4"></b-skeleton></b-td></b-tr>
                        </b-tbody>
                    </b-table-simple>
                </template>

            </template>
        </div>

</div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
import GeneralPrintButton from '@/components/print_to_pdf/GeneralPrintButton'

export default {
    data() {
        return {
            radio_options: [{text: '단기 (~ 6개월)', value: '단기'}, {text: '중기 (~ 2년)', value: '중기'}, {text: '장기 (3년 이상)', value: '장기'}],
            is_pa_loaded: false,
            is_confirmed: false,
            is_printing: false
        }
    },
    props:{
        company_id: {default: ''}
    },
    computed:{
        ...mapState(['campaign','company_name']),
        ...mapState('score', ['temp_target_companies']),
        ...mapState('postassess', ['pa_evaluation_results','pa_action_plans','template_in_progress','pa_by_campaign']),
        ...mapGetters('postassess', ['ev_results_hash', 'action_plan_hash']),
        has_improvment_contents(){
            return(q_code) => {
                let idx = this.pa_evaluation_results.findIndex(w => w.question_code == q_code)
                if(idx>-1) return true
                else return false  
            }
        },
        has_improvment_category(){
            return(index) => {
                let question_list = this.template_in_progress.template_contents[index].children
                for (let x=0; x<question_list.length; x++){
                    let jdx = this.pa_evaluation_results.findIndex(w => w.question_code == question_list[x].question_code)
                    if(jdx>-1) return true
                    else return false  
                }
            }
        },
    },
    methods:{
        ...mapMutations('score', ['update_temp_target_companies','add_temp_target_companies']),
        ...mapActions('postassess', ['readPAEvaluationResults', 'readPAActionPlans','savePAActionPlan','readPAActionPlansSubmitted','savePAActionPlanSubmit',
        'readTemplateInProgress','saveMonitoringConfirm']),
        async confirmPlan(){
            let confirmed = true
            if(this.is_confirmed == true){
                confirmed = false
            }
            await this.savePAActionPlanSubmit({
                campaign_id: this.campaign.campaign_id, 
                company_id: this.company_id,
                submit: true
            })
            await this.saveMonitoringConfirm({
                campaign_id: this.campaign.campaign_id, 
                company_id: this.company_id,
                confirmed: confirmed
            })
            this.findConfirmed(this.company_id)
        },
        findCompanyName(company_id){
            // console.log(this.temp_target_companies);
            return this.temp_target_companies.find(x => x.company_id == company_id).company_name            
        },
        findConfirmed(){
            this.is_confirmed = this.pa_by_campaign.find(x => x.company_id == this.company_id).action_plan_confirmed
        }
    },
    async created(){
        await this.readTemplateInProgress({template_code: this.campaign.template_code})
        await this.readPAEvaluationResults({campaign_id: this.campaign.campaign_id, company_id: this.company_id})
        await this.readPAActionPlans({campaign_id: this.campaign.campaign_id, company_id: this.company_id})
        this.findConfirmed()
        this.is_pa_loaded = true
    },
    updated(){
        // console.log(this.template_in_progress.template_contents);
        // console.log(this.question_composition);
        // console.log(this.pa_evaluation_results);
    },
    components:{
        GeneralPrintButton,
    }

}
</script>

<style>

</style>