<template>
    <b-container id="question-main-layout" fluid class=" px-0 position-relative">
        
        <!-- Question & Question info 영역 -->
        <div class="px-5 py-5">
            <div class="f-90 gray-550 float-right">{{  byLang( campaign_in_progress.campaign_name, campaign_in_progress.campaign_name_e, campaign_in_progress.campaign_name_c) }}</div>
            <h4 class="fw-600">{{ byLang( question_name, question_name_e, question_name_c)}} </h4>        
            <div id="question_infobox" :class="question_infobox!=''&& question_infobox!=null? 'f-90 p-2 px-3 gray-550 bgray-100 border-gray-300 rounded':''" v-html="$i18n.locale=='ko' ? question_infobox : question_infobox_e"></div>
        </div>

        <!-- Sub-component 영역 -->
        <div v-if="subs_loaded && subs.length > 0" class="px-5 pb-5 mb-5" :key="pipeline_key">
            <sub-component v-for="item in subs" :key="item.index" 
            :sub_type="item.sub_type"
            :item.sync="item"
            :is_disabled_by_parent="is_disabled_by_parent"
            @update-pipeline="handleUpdatePipeline"
            ></sub-component>
        </div>
        <div v-else-if="!subs_loaded" class="text-center">
            <b-spinner class="mt-5 grey"></b-spinner>
        </div>
        <div v-else-if="subs.length == 0" class="px-5">
            <b-card class="bgray-200"> {{$t(`다음 질문으로 이동하여 주십시오.`)}} </b-card>
        </div>

        <!-- 완료 체크 및 문항 이동 영역 -->
        <div class="px-5 py-5 mb-5 d-flex justify-content-between align-items-center">
            <!-- <div v-if="subs.length>0&&is_completed!='true'&&is_disabled_by_parent!=true" class="f-90 mr-5 red">
                <fa icon="info-circle" class="mr-1"></fa> <b>{{ $t(question_name) }}</b> {{$t(`문항에 답변을 완료하셨다면`)}}<br> {{$t(`'제출하기' 체크 후 다음 문항으로 이동해주세요.`)}}
            </div>
            <div v-else-if="subs.length>0&&is_completing==true" >&nbsp;</div>
            <div v-else class="f-90 mr-5 blue"></div> -->
            <div class="d-flex">
                <div v-if="subs.length > 0&&is_completing==false" :class="[is_completed=='true'? 'border-blue':'border-red', 'rounded p-2 d-flex align-items-center']"
                 v-b-tooltip.hover :title="is_disabled_by_parent==true? $t(`답변 기간이 아닙니다.`) : $t(`답변기간 내에는 제출을 해제하고 답변을 수정할 수 있습니다.`)"> 
                    <b-form-checkbox :disabled="is_disabled_by_parent" v-if="$store.state.mode != 'public'" v-model="is_completed" value="true" unchecked-value="false" @change="toggleProgress()" class="d-inline">
                        <span v-if="$i18n.locale=='ko'" :class="is_completed=='true'? 'blue':'red'"><b>'{{ question_name }}'</b> {{is_completed=='true'? $t(`제출됨`): $t(`제출하기`) }}</span>
                        <span v-else :class="is_completed=='true'? 'blue':'red'"> {{is_completed=='true'? $t(`제출됨`): $t(`제출하기`) }} <b>"{{ byLang(question_name, question_name_e, question_name_c) }}"</b></span>
                    </b-form-checkbox> 
                </div>
                <div v-else-if="subs.length > 0&&is_completing==true" class="border-blue rounded p-2">
                    <b-spinner class="blue"></b-spinner>
                </div>
                <b-button class="ml-4 py-2" :disabled="is_completing==true" @click.stop.prevent="goForward" variant="dark-border">{{$t(`다음`)}}<fa icon="caret-right" class="ml-2"></fa></b-button>
            </div>
        </div>

        <!-- Question not editable -->
        <div v-if="is_writable==false" class="w-100 question-blocked">
            <b-alert show dismissible class="question-block-alert w-100 d-flex justify-content-center align-items-center">
               <div class="text-center fw-500"> <fa icon="info-circle" class='mr-1'></fa> 이 문항은 보기만 가능합니다. <br> <span class="f-90 fw-400">작성 및 편집을 원하실 경우 관리자에게 문의하세요.</span></div>
            </b-alert>
        </div>

</b-container>
</template>

<script>
import SubComponent from './sub_component/SubComponent'
import * as ih from '../../util.js'
import * as th from 'tree-helper'
//import axios from 'axios'
import { mapState } from 'vuex'

export default {
    data () {
        return {
            template_name: '',
            //is_writable: true,
            is_completing: false,
            pipeline_key: 100,
        }
    },
    props:{
        question_code: { type: String, default: '' }
    },
    computed: {
        ...mapState('questions', ['subs', 'subs_loaded', ]),
        ...mapState(['company_id', 'campaign_in_progress', 'question_in_progress', 'question_in_progress_loaded', 'user_id', 'template_in_progress']),

        question_name() {
            return this.question_in_progress.question_name
        },
        question_name_e(){
            return this.question_in_progress.question_name_e
        },
        question_name_c(){
            return this.question_in_progress.question_name_c
        },
        question_infobox() {
            return this.question_in_progress.question_infobox
        },
        question_infobox_e(){
            return this.question_in_progress.question_infobox_e
        },
        question_infobox_c(){
            return this.question_in_progress.question_infobox_c
        },
        question_contents() {
            return this.question_in_progress.question_contents
        },
        question_comment() {
            return this.question_in_progress.comment
        },
        is_completed:{
            get(){
                return this.question_in_progress.is_completed
            },
            set(newVal){
                let temp_obj = { ...this.question_in_progress }
                temp_obj.is_completed = newVal
                this.$store.commit('update_question_in_progress', temp_obj)
            }
        },
        is_writable() {
                th.breadthFirstSearch(this.$store.state.template_in_progress.template_contents, node => {
                    if(node.question_code == this.question_code) {
                        return node.writable
                    }
                })
        },
        is_disabled_by_parent(){
            if(this.campaign_in_progress.campaign_state=='답변중'){ return false
            }else if (this.campaign_in_progress.campaign_state=='생성/편집'){ return false
            }else return true
        },
        // subs_filtered(){  //아모레 s64712871 지표 삭제 요청에 의해 화면에 보이지 않도록 수정함
        //     if (this.campaign_in_progress.campaign_id == 63){
        //         let idx = this.subs.findIndex(item => item.sub_code == 's64712871')
        //         let temp_subs = ih.deepCopy(this.subs)
        //         if (idx >-1 ){
        //             temp_subs.splice(idx, 1)
        //         }
        //         return temp_subs
        //     }
        //     else return this.subs
        // }
    },
    methods: {
        //readSubsInit(){
        //    this.$store.dispatch('questions/readSubsInit', this.question_contents)
        //},
        byLang(ko, en, cn){
            if(this.$i18n.locale=='en') return en
            else if (this.$i18n.locale=='cn') return cn
            else return ko
        },
        toggleProgress() {
            Vue.nextTick( () => {
                this.saveQuestionInProgress()
                this.saveProgressLog()
            })
        },
        saveQuestionInProgress(){
                this.is_completing = true
                this.$store.dispatch('saveQuestionInProgress', this.question_in_progress)
                .then( () => {
                    EventBus.$emit('refresh-tree') //for tree update
                    this.is_completing = false
                })
        },
        saveProgressLog(){
            Vue.nextTick( () => {
                let progress_rate = this.$store.getters.getProgressRate
                const path = this.$store.state.backend_host + '/save_progress_log'

                let formData = new FormData()
                formData.append('user_id', this.user_id)
                formData.append('campaign_id', this.campaign_in_progress.campaign_id)
                formData.append('company_id', this.$store.state.company_id)
                formData.append('question_code', this.question_code)
                formData.append('is_completed', this.is_completed)
                formData.append('progress_rate', progress_rate)

                return axios.post(path,formData)
                .then( response => {
                    // console.log(response.data)
                })
                .catch(error => {
                    console.log(error)
                })
            })
        },
        goForward(){
            let question_list = []
            th.depthFirstSearch(this.template_in_progress.template_contents, node => {
                if(node.visible == true){ 
                    question_list.push(node) 
                }
            })
            let idx = question_list.findIndex(item => { return item.question_code == this.question_in_progress.question_code })
            // console.log('현재 퀘스천');            
            // console.log(this.question_in_progress)
            
            if( idx > -1 && idx < question_list.length-1){
                let new_question_code = question_list[idx+1].question_code
                // console.log('이동할 퀘스천코드');
                // console.log(question_list[idx+1].question_name);
                
                this.pageGo(new_question_code)
                EventBus.$emit('move-next-question',new_question_code) //to ProviderAside
            }
            else{
                alert(this.$t(`마지막 문항입니다. 전체 답변을 완료하신 경우 우측의 '전체 제출하기' 버튼을 클릭하여 제출해주세요.`))
                // console.log('마지막 퀘스천일 경우');
            }
        },
        makeCompleted(){
            if (this.campaign_in_progress.campaign_state!='답변중'||this.campaign_in_progress.campaign_state!='생성완료') { 
                this.is_completed='true'
            }
        },
        handleUpdatePipeline(){
            this.pipeline_key += 1
        },
        pageGo(question_code){
            //최초 이니시에이팅 완료 신호
            //this.initiating = false
            
            //자식노드가 있는 경우 클릭만으로도 접고 펴기
            //if(node.children && node.children.length) store.toggleOpen(node)

            //퀘스천 페이지 이동
            let temp_question_in_progress = { ...this.question_in_progress }
            temp_question_in_progress.question_code = question_code

            th.breadthFirstSearch(this.template_in_progress.template_contents,node => {
                if(node.question_code == question_code){
                    temp_question_in_progress.comment = node.comment
                    temp_question_in_progress.question_name = node.question_name
                    temp_question_in_progress.question_name_e = node.question_name_e
                    temp_question_in_progress.question_infobox = node.question_infobox
                    if(typeof(node.question_contents)=='string') {
                        temp_question_in_progress.question_contents = JSON.parse(node.question_contents)
                    }
                    else{
                        temp_question_in_progress.question_contents = node.question_contents
                    }
                    temp_question_in_progress.is_completed = node.is_completed
                }
            })
            this.$store.commit('update_question_in_progress', temp_question_in_progress)
            
            //console.log('퀘스천 콘텐츠')
            // console.log(temp_question_in_progress.question_contents)
            this.$store.dispatch('questions/readSubsInit', temp_question_in_progress.question_contents)
            .then( () => {
                Vue.nextTick( () => {
                    // console.log('퀘스천 코드' + question_code)
                    this.$router.push( { name: 'Questions', params: { question_code: question_code }} )
                })
            })
            .catch(error => {
                console.log(error)
            })
        }
    },
    created(){
        //console.log(this.campaign_in_progress)
        // this.makeCompleted()
    },
    components: {
        SubComponent
    },
    beforeDestroy(){
        EventBus.$emit('toggle-writable', true)
    }
}
</script>
<style>

.question-blocked{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    flex-grow: 1;
    height: 100%;
    background: rgb(255,255,255, 0.2);
}

.question-block-alert {
    top: 0px;
    height: 60px;
    background-color: rgba(0,0,0,0.5);
    color:white;
    border:0;
    border-radius: 0;
    z-index: 60;
}

#question_infobox > a {
    color: var(--blue7);
}

</style>
