<template>
<div class="editing-dfs">
        <!--제목, 컴포넌트 기본 공통 -->
        <div class="p-2 bgray-200 rounded d-flex justify-content-between align-items-center border">
            <div class="d-flex align-items-center">
                <span class="mx-3 f-110">{{ sub_code }}</span>
                <b-dropdown :text="sub_type" variant="dark-border" >
                    <b-dropdown-item-button @click="selectType(item)" v-for="item in sub_types" :key="item.id" > {{ item }} </b-dropdown-item-button>
                </b-dropdown>
            </div>
            <div class="d-flex align-items-center">             
                <b-form-checkbox v-model="sub_data_header.required">필수 응답(Public ONLY)</b-form-checkbox>
                <span class="mx-3 gray-600"> |</span>
                <b-dropdown :text="scoring" size="sm" variant="dark-border">
                    <b-dropdown-item @click="setScoring('none')" >No Scoring</b-dropdown-item>
                    <b-dropdown-item @click="setScoring('auto')">Auto Scoring</b-dropdown-item>
                </b-dropdown>
            </div>
        </div>

        <div class="mt-3 mb-1 fw-600 f-90">Title</div>
        <b-input v-model="sub_name" placeholder="Sub name"> {{ sub_name }} </b-input>

        <div class="mt-3 mb-1 fw-600 f-90">Description</div>
        <b-textarea v-model="sub_description" placeholder="A1. ㅇㅇㅇ을 관리하고 있습니까?"> {{ sub_description }} </b-textarea>

        <div class="mt-3 mb-1 fw-600 f-90">Info</div>
        <b-textarea v-model="sub_info" placeholder="용어설명, 참고사항, 평가기준 등 답변 참고정보" > {{ sub_info }} </b-textarea>

        <div class="mt-3 mb-1 fw-600 f-90" style="cursor:pointer" @click="conditionPopoverToggle()">Condition <fa :id="`sub_condition_information+${sub_code}`" icon="info-circle"></fa></div>
            <b-popover :target="`sub_condition_information+${sub_code}`" title="설명" :content="sub_condition_information" placement="left" :show="sub_condition_popover_show">
            </b-popover>
        <div  id="editing-template-condition">
            <b-form-checkbox v-model="is_condition_available" @input="manageConditionAvailability()" class="editing-dfs">
                <b-form-input placeholder="선행조건 표시내용을 입력해 주세요" v-model="sub_condition.text"> {{ sub_condition.text }} </b-form-input>
            </b-form-checkbox>      
        </div>

        <div class="mt-3 mb-1 fw-600 f-90">Not Applicable</div>
        <b-form-checkbox v-model="sub_data_header.enable_na" class="blue">선택지에 '해당없음' 추가</b-form-checkbox>

        <div class="mt-3 mb-1 fw-600 f-90" style="cursor:pointer" @click="contentsPopoverToggle()">Structure  <fa :id="`sub_data_contents_information+${sub_code}`" icon="info-circle"></fa></div>
            <b-popover :target="`sub_data_contents_information+${sub_code}`" title="정의"  :content="sub_data_contents_information" placement="left" :show="sub_data_contents_popover_show">
            </b-popover>
        <!-- DF Text Box 데이터 구조 수정 -->  
        <div class="d-flex editing-dfs">
            <b-form-input v-model="sub_data_contents.placeholder" placeholder="텍스트박스 입력 가이드를 입력해주세요" class="w-75">{{ sub_data_contents.placecholder }}</b-form-input>
            <b-form-input v-model="sub_data_header.rows" placeholder="박스크기(rows)" type="number" class="ml-2 w-25">{{ sub_data_header.rows }}</b-form-input>
        </div>                      
                      
        <!-- Tags, Child 등 기본 공통 -->
        <div class="mt-4 d-flex editing-dfs">
            <span class="fw-600 mr-5 f-90">Tags </span>
            <b-input v-model="tags" placeholder="띄어쓰기 대신 _ 사용, 쉼표로 구분"> {{ tags }}</b-input>
        </div>

        <div v-if="checkAbleChild" class="mt-3 mb-4 d-flex">
            <span class="fw-600 mr-5 f-90">Child</span>
            <div class="bgray-200 w-100 p-2 rounded "><b-form-checkbox v-model="has_child" @change="handleHasChild" >파일 및 URL 첨부</b-form-checkbox></div>
        </div>

        <div class="mt-4 d-flex editing-dfs">
            <span class="fw-600 mr-5 f-90">Priority</span>
            <b-form-select v-model="sub_priority" :options="sub_priority_option"></b-form-select>
        </div>

        <div class="float-right">
            <b-button @click="handleSaveSub()" variant="blue" class="mr-2">저장</b-button>
            <b-button @click="$emit('click-cancel')" variant="dark">취소</b-button>
        </div>

</div>
</template>

<script>
import { EditingSubComponentMixin } from './EditingSubComponentMixin'

export default {
    mixins: [EditingSubComponentMixin],
    data() {
        return {
            sub_data_contents_information: `
                placeholder: 텍스트박스 입력 폼에 표시될 가이드 텍스트입니다. 
                rows: 텍스트박스의 높이(화면에 표기될 줄 수)입니다.
            `
            //sub_data_header 데이터 구조 { "cols:4" }
            //sub_data_input 데이터 구조  { "placeholder": "텍스트를 입력해 주세요" }
        }
    },
    computed: {
    },
    methods: {
        handleSaveSub(){
            this.saveSub()
            .then(this.$emit('click-save'))
        }
    },
    watch: {

    },
    created(){
 
    },
}
</script>