// User Service 중 Managing Operation 관련 Store
// 선택된 Campaign에 대한 Owner, Auditor, Target Company 소속 유저 정보 저장

const notices = {
    namespaced: true,
    state: {
        users_in_campaign_universe: [{
            user_id: '',
            e_mail: '',
            user_name: '',
            company_id: '',
            company_name: '',
            department_id: '',
            department_name: '',
            is_admin: '',
            type: ''
        }],
        is_users_in_campaign_universe_loaded: false
    },

    mutations: {
        update_users_in_campaign_universe(state, new_user_space){
            state.users_in_campaign_universe=new_user_space
            state.is_users_in_campaign_universe_loaded = true
        }
    },

    getters: {
        owners(state) {
            let temp_owners = state.users_in_campaign_universe.filter(item => item.type=='owners')
            return temp_owners
        },
        auditors(state) {
            let auditors = state.users_in_campaign_universe.filter(item => item.type=='auditors')
            return auditors
        },
        participants(state){
            let participants = state.users_in_campaign_universe.filter(item => item.type=='participants')
            return participants
        }
    },

    actions: {
        initiateUsersInCampaignUniverse({state, commit, rootState }, campaign_id ){
            const path = rootState.backend_host + '/read_users_in_campaign_universe'
            //console.log('request: user_id:'+user_id)
            return axios.get(path, { params: {
                campaign_id: campaign_id
            }})
            .then( response => {
                let new_user_space = response.data
                console.log(new_user_space);
                commit('update_users_in_campaign_universe', new_user_space )
                return new_user_space
            })
            .catch( error => {
                console.log(error)
            })
        }
    }
}

export default notices