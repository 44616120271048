<template>
<div>
    <h2 class="pt-5 mb-4 fw-600"> {{$t(`개인정보 처리방침`)}} </h2>
    <b-card class="mb-5 border-0 shadow-sm">

        <div class="terms-of-use-text">
            
            <p class="fw-500 f-110">{{$t(`(주)퀀티파이드이에스지(이하 QESG)는 개인정보 보호법 제30조에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.`)}}</p>
            <br>
            <h4> {{$t(`1. 개인정보의 처리 목적`)}} </h4>
                <div class="terms-of-use-details">
                {{$t(`QESG는 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.`)}}<br>
                {{$t(`- 고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 물품 또는 서비스 공급에 따른 금액 결제, 물품 또는 서비스의 공급·배송 등`)}}</div>
            <br>
            <h4>{{$t(`2. 개인정보의 처리 및 보유 기간`)}}</h4>
                <div class="terms-of-use-details">
                {{$t(`QESG는 정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 보유·이용기간 내에 개인정보를 처리합니다.`)}} {{$t(`구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다.`)}}<br>
                {{$t(`- 고객 가입 및 관리: 서비스 이용계약 또는 회원가입 해지시까지`)}}</div>
            <br>
            <h4>{{$t(`3. 개인정보의 제3자 제공에 관한 사항`)}}</h4>
                <div class="terms-of-use-details">
                {{$t(`QESG는 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.`)}}<br>
                {{$t(`QESG는 다음과 같이 개인정보를 제3자에게 제공할 수 있습니다.`)}}<br>
                <div class="terms-of-use-details">
                {{$t(`1. 개인정보를 제공받는 자: 데이터 검증 및 실사 전문 파트너`)}}<br>
                {{$t(`2. 제공받는 자의 개인정보 이용목적: 데이터 검증 및 실사, 평가, 분석 및 후속 모니터링을 위한 정보 이용`)}}<br>
                {{$t(`3. 제공받는 자의 보유·이용기간: 프로젝트 종료 후 30일 이내 파기`)}}</div>
                {{$t(`QESG는 개인정보 제 3자 제공 및 개인정보 처리 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.`)}}<br>

                {{$t(`위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.`)}}</div>
            <br>
            <h4>{{$t(`4. 이용자의 권리`)}}</h4>
                <div class="terms-of-use-details">
                {{$t(`정보주체는 QESG에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.`)}}<br>
                <div class="terms-of-use-details">
                {{$t(`1. 개인정보 열람요구`)}}<br>
                {{$t(`2. 오류 등이 있을 경우 정정 요구`)}}<br>
                {{$t(`3. 삭제요구`)}}<br>
                {{$t(`4. 처리정지 요구`)}}<br>
                </div></div>
            <br>
            <h4>{{$t(`5. 처리하는 개인정보의 항목`)}}</h4>
                <div class="terms-of-use-details">
                {{$t(`QESG는 제품 및 서비스 제공, 문의사항 접수 및 관리, 마케팅 및 광고에의 활용을 위하여 다음의 개인정보 항목을 처리하고 있습니다.`)}}<br>
                {{$t(`- 필수항목: 성함, 이메일, 휴대전화번호, 회사명`)}}<br>
                {{$t(`- 선택항목: 부서, 직책, 회사전화번호, 회사주소 등`)}}</div>
            <br>
            <h4>{{$t(`6. 개인정보의 파기`)}}</h4>
                <div class="terms-of-use-details">
                {{$t(`QESG는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다.`)}} {{$t(`파기의 절차, 기한 및 방법은 다음과 같습니다.`)}}
                <div class="terms-of-use-details">
                {{$t(`1. 파기절차`)}}<br>
                {{$t(`이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져 내부 방침에 따라 일정기간 저장됩니다.`)}} {{$t(`이 때, DB로 옮겨진 개인정보는 법률에 의한 경우를 제외하고 다른 목적으로 이용되지 않습니다.`)}}</div>
                <div class="terms-of-use-details">
                {{$t(`2. 파기기한`)}}<br>
                {{$t(`이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 30일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 30일 이내에 그 개인정보를 파기합니다.`)}}</div>
                </div>
            <br>
            <h4>{{$t(`7. 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항`)}}</h4>
                <div class="terms-of-use-details">
                {{$t(`QESG는 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용할 수 있습니다.`)}} {{$t(`쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.`)}}
                <div class="terms-of-use-details">
                {{$t(`1. 쿠키의 사용 목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.`)}} <br>
                {{$t(`2. 쿠키의 설치•운영 및 거부: 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.`)}} {{$t(`단, 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.`)}}
                </div></div>
            <br>
            <h4>{{$t(`8. 개인정보 보호책임자 작성`)}}</h4>
                <div class="terms-of-use-details">
                {{$t(`QESG는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.`)}}
                <div class="terms-of-use-details">
                {{$t(`▶ 개인정보 보호책임자`)}}<br>
                {{$t(`성명: 김혜주`)}}<br>
                {{$t(`연락처: 02-6956-2410, info@qesg.co.kr`)}}<br>
                {{$t(`※ 개인정보 보호 담당부서로 연결됩니다.`)}}</div>

                {{$t(`정보주체께서는 QESG의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.`)}} {{$t(`QESG는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.`)}}</div>
            <br>
            <h4>{{$t(`9. 개인정보 처리방침 변경`)}}</h4>
                <div class="terms-of-use-details">
                {{$t(`이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항 또는 이메일을 통하여 고지할 것입니다.`)}}</div>
            <br>
            <h4>{{$t(`10. 개인정보의 안전성 확보 조치`)}}</h4>
                <div class="terms-of-use-details">
                {{$t(`QESG는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.`)}}
                <div class="terms-of-use-details">
                {{$t(`1. 정기적인 자체 감사 실시`)}}<br>
                {{$t(`개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.`)}}<br>

                {{$t(`2. 개인정보 취급 직원의 최소화 및 교육`)}}<br>
                {{$t(`개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.`)}}<br>

                {{$t(`3. 내부관리계획의 수립 및 시행`)}}<br>
                {{$t(`개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.`)}}<br>

                {{$t(`4. 개인정보의 암호화`)}}<br>
                {{$t(`이용자의 개인정보는 비밀번호는 암호화되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.`)}}<br>

                {{$t(`5. 비인가자에 대한 출입 통제`)}}<br>
                {{$t(`개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.`)}}</div></div>
                <br>            

            <p class="fw-500 f-110 text-center">{{$t(`본 방침은 2020년 4월 10일부터 시행됩니다.`)}}</p>
        </div>
    </b-card>

</div>
</template>

<script>
export default {

}
</script>

<style scoped>
.terms-of-use-text > h4 {
    font-weight: 600;
}

</style>