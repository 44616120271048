<template>
<div>
    <div :class="[previous!=true ? '':'bgray-200 p-3 gray-600 rounded', 'mb-5']">

        <div v-if="headers">
            <div :class="[ sub_name != '' ? 'fw-600 f-120 border-bottom mb-2':'', '' ]"> <span v-if="sub_data_header.required && sub_data_header.required==true">*</span> {{ sub_name }} </div>
                <div :class="[ sub_description != '' ? 'mb-3': '', 'mb-1']">
                    <div v-if="is_indicate_description" class="mr-1 fw-500 f-105 d-inline px-0"> {{ sub_description }} </div>
                    <span v-for="item in tags" :key="item.id"> <span v-if="item != ''" class="ml-2 f-80 gray-500"> {{ item }} </span></span>
                    <b-button  v-if="sub_info != ''" class="ml-1 px-1 py-0 f-80" variant="teal" size="sm" :id="`i-${ sub_code }`" @click="toggleVisible" ><fa icon="book-open"></fa> 작성 도움말</b-button>

                    <span v-if="sub_priority != ''"><b-badge :variant="badge_var" class="mx-2">{{ sub_priority }}</b-badge></span>
                    <b-button v-if="previous_sub.sub_code != undefined && previous != true" v-b-toggle="previous_sub.sub_code" size="sm" variant="red" class="py-0">{{ $t('과거 답변 보기') }}<fa icon="history" class="ml-2"></fa></b-button>

                    <!-- sub_info 정보 -->
                    <div v-if="is_visible" :id="`sub_info-${ sub_code }`" class="mt-2 mb-3 px-1 blue f-90"> <fa icon="info-circle" class="mr-1"></fa><span v-html="sub_info"></span></div>

                    <!-- sub_condition -->
                    <div v-if="is_sub_condition_activated==true" :id="`sub_condition-${sub_code}`" class="my-2 py-2">
                        <b-form-checkbox :disabled="is_locked || is_disabled_by_parent" v-model="sub_condition.value" @change="radioSave()"> {{ sub_condition.text }} </b-form-checkbox>
                    </div>
                </div>
            </div>

        <b-overlay :show="is_visible_by_pipeline==false" rounded="sm" opacity="0.6" bg-color="#c8c8c8">

            <!-- Editable Table 유형 Sub Component -->
            <b-table-simple responsive style="word-break:keep-all" hover class="f-90 border-bottom">
                <b-tr>
                    <b-th v-for="table_field in sub_data_header['table_header']" :key="table_field.index" class="bgray-200"> {{ table_field }} </b-th>
                    <b-th>관리</b-th>
                </b-tr>
                <b-tr v-for="(table_item, index) in sub_data_contents" :key="table_item.id">
                    <b-td v-for="table_field in sub_data_header['table_header']" :key="table_field.id" class="py-1 px-1">
                        <span> {{ table_item[table_field] }} </span>
                    </b-td>
                    <b-td>
                        <b-button v-b-modal="`modal-edit-${sub_code}-${index}`">수정</b-button>

                        <!-- 데이터 수정 모달 -->
                        <b-modal @ok="dbSave()" :id="`modal-edit-${sub_code}-${index}`">
                        <b-container>
                            <b-row v-for="table_field in sub_data_header['table_header']" :key="table_field.key">
                                <b-col>
                                    {{ table_field }}
                                </b-col>
                                <b-col>
                                    <b-form-input v-model="table_item[table_field]">{{ table_item[table_field] }}</b-form-input>
                                </b-col>
                            </b-row>
                        </b-container>
                        </b-modal>

                    </b-td>
                </b-tr>
                <b-button @click="addRow(sub_data_contents)">행 추가</b-button>
            </b-table-simple>
            <div v-if="previous != true" :class="validation_style" class="mt-2"><fa v-if="validation_icon !=''" :icon="validation_icon"></fa> {{ validation_comment }} </div>
            
            <!-- Child Component -->
            <sub-component v-for="(item, index) in child_subs" :key="item.id"
            :is_final_sub_component="index == child_subs.length -1 ? true : false"
            :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent" 
            :sub_type="item.sub_type" 
            :item="item" 
            :audit_mode="audit_mode" 
            :is_disabled_by_parent="is_disabled_by_parent" ></sub-component>
            
            <!-- is_visible_by_pipeline 조건에 따른 해당없음 overlay -->
            <template v-slot:overlay>
                <div class="text-center ">
                    {{$t(`해당사항 없음`)}} <b-button variant="icon" v-b-tooltip.hover :title="pipeline_title"><fa icon="info-circle"></fa></b-button>
                </div>
            </template>
        </b-overlay>   
   
    </div>

    <!-- previous component -->
    <b-collapse v-if="print_mode!=true" :id="previous_sub.sub_code">
        <sub-component :disabled="true" v-if="previous_sub.sub_code != undefined && previous != true" :is_disabled_by_parent="true" :sub_type="previous_sub.sub_type" :item="previous_sub" :previous="true"></sub-component>
    </b-collapse>

</div>
</template>
<script>
import { SubComponentMixin } from './SubComponentMixin'
import { runInThisContext } from 'vm'

export default {
    data(){
        return{
            button_title: this.$t(`평가정보`),
            pipeline_title: this.$t(`이 문항은 답변할 필요가 없습니다.`)
        }
    },
    mixins: [SubComponentMixin],
    methods: {
        showPlaceholder(table_item, table_field) {
            if(table_item.hasOwnProperty('placeholders') && table_item['placeholders'].hasOwnProperty(table_field)) {
                return table_item['placeholders'][table_field]
            }
            else return ''
        },
        async setDropdown(table_item, table_field, contents) {
            table_item[table_field] = contents
            return await this.radioSave()
        },
        addRow(table_contents){
            let table_header = this.sub_data_header['table_header']

            let new_table_contents = {}
            for (let i=0; i<table_header.length; i++)
            {
                let new_key = table_header[i]
                new_table_contents[new_key] = ''
            }
            table_contents.push(new_table_contents)
        }
    }
}
</script>

<style scoped>


</style>
