<template>
<div>
    <div v-if="headers">
    <div :class="[ sub_name != '' ? 'fw-500 f-110 border-bottom':'', '' ]"> {{ sub_name }} </div>
        <div :class="[ sub_description != '' ? 'mb-3': 'mb-0', '']">

            <!-- sub_info 정보 -->
            <div v-if="is_visible" :id="`sub_info-${ sub_code }`" class="mt-2 mb-3 px-1 blue f-90"> <fa icon="info-circle" class="mr-1"></fa><span v-html="sub_info"></span></div>

            <!-- sub_condition -->
            <div v-if="is_sub_condition_activated==true" :id="`sub_condition-${sub_code}`" class="my-2 py-2">
                <b-form-checkbox :disabled="is_locked || is_disabled_by_parent" v-model="sub_condition.value" @change="radioSave()"> {{ sub_condition.text }} </b-form-checkbox>
            </div>
        </div>
    </div>

    <!-- textbox 유형 Sub Component -->
    <div :class="sub_data_header.bordered == true ? 'bordered-guide-text' : ''"> 
        <div v-if="sub_data_header.html == true"> <span v-html="sub_data_contents.structure[0]"></span></div>
        <div v-else> {{ sub_data_contents.structure[0] }} </div>
    </div> 

    <!-- Child Component -->
    <sub-component @update-parent-contents="handleUpdateParentContents" :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent" v-for="item in child_subs" :key="item.id" :sub_type="item.sub_type" :item="item"></sub-component>

</div>
</template>
<script>
import { SubComponentMixin } from './SubComponentMixin'

export default {
    data() {
        return {
        }
    },
    mixins: [SubComponentMixin],
    computed: {
        textarea_rows: {
            get() {
                if(this.sub_data_header.hasOwnProperty('rows') && this.sub_data_header.rows != 0 ) 
                { 
                    return this.sub_data_header.rows
                }
                else 
                { 
                    return 8 
                }
            }
        }
    }
}
</script>

<style scoped>
.bordered-guide-text{
    padding: 1rem 1.75rem;
    background-color: var(--gray-200);
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}
</style>
