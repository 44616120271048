<template>
    <div class="group-inputs">
        
        <div class="gray-600 mb-1"> {{ $t(`성함`) }} *</div>
        <b-form-input class="mb-2" v-model="name" type='text'></b-form-input>

        <div class="gray-600 mb-1">{{ $t(`이메일`) }} *</div>
        <b-form-input class="mb-2" v-model="e_mail" type='text' placeholder="name@example.com"> </b-form-input>

        <div class="gray-600 mb-1">{{ $t(`소속`) }}</div>
        <b-form-input class="mb-3" v-model="company" type='text' :placeholder="modal_company" ></b-form-input>
        <div v-if="user_name==''||user_name==undefined">
            <!-- <b-form-input class="mb-3" v-model="department" type='text' :placeholder="modal_department" ></b-form-input> -->

            <div class="gray-600 mb-1"> {{$t(`관심있는 서비스 분야`)}} </div>
            <b-card class="mb-3 bgray-100 f-90">
                <b-form-group class="m-0 text-left"><b-form-checkbox-group v-model="interest" :options="options" name=""></b-form-checkbox-group></b-form-group>
            </b-card>
        </div>

        <div class="gray-600 mb-1">{{ $t(`문의사항`) }} *</div>
        <b-textarea v-model="requirements" rows="6" :placeholder="$t(`문의사항을 작성해 주세요.`)"> </b-textarea>

        <div class="f-90 text-right mb-4">{{ $t(`평균 1시간 이내에 답변 드립니다. *은 필수입력 항목입니다.`) }}</div>

        <div v-if="mail_sending==false" class="d-flex justify-content-end mb-2">
            <b-button @click="hideModal" variant="dark">{{ $t(`취소`) }}</b-button>
            <b-button @click="saveCustomerService" class="ml-2" variant="blue">{{ $t(`문의하기`) }}<fa icon="paper-plane" class="ml-1"></fa></b-button>            
        </div>
        <div v-if="mail_sending==true" class="d-flex justify-content-end mb-2">
            <b-spinner size="small"></b-spinner> {{ $t(`문의사항을 전송하는 중입니다. 잠시 기다려 주세요.`) }}
        </div>
                
    </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
    data() {
        return {
            selected: [],
            options: [
              { text: this.$t(`공급망 관리`), value: '공급망' },
              { text: this.$t(`ESG 벤치마크`), value: '벤치마크' },
              { text: this.$t(`기업 데이터 관리`), value: '데이터 관리' },              
              { text: this.$t(`지속가능경영 정보 공개`), value: '웹 리포팅' },
              { text: this.$t(`설문조사`), value: '설문조사' },
              { text: this.$t(`기타`), value: '기타' }
            ],

            name: '',
            company: '',
            department: '',
            office: '',
            mobile: '',
            e_mail: '',
            interest: [],
            requirements: '',
            is_form_filled: false,

            modal_company: this.$t(`회사`),
            modal_department: this.$t(`소속부서`),

            mail_sending: false,
        }
    },
    computed:{
        ...mapState({
            user_id: state=>state.user_id,
            store_e_mail: state=>state.e_mail, 
            user_name: state => state.user_name,
            company_name: state => state.company_name,
            campaign_in_progress: state => state.campaign_in_progress,
            template_in_progress: state => state.template_in_progress,
            question_in_progress: state => state.question_in_progress 
        })
    },
    methods:{
        saveCustomerService() {
            this.checkForm() 
            if (this.is_form_filled != false) {
                this.mail_sending = true
                const path = this.$store.state.backend_host + '/save_customer_service'

                let str_interest = JSON.stringify(this.interest)
                let lang = ''
                if (this.$i18n.locale == 'cn'){
                    lang = 'cn'
                }
                console.log(lang);

                let formData = new FormData 
                formData.append('name', this.name)
                formData.append('company', this.company)
                formData.append('department', this.department)
                formData.append('office', this.office)
                formData.append('mobile', this.mobile)
                formData.append('e_mail', this.e_mail)
                formData.append('interest', str_interest)
                formData.append('requirements', this.requirements)
                formData.append('lang', lang)

                return axios.post(path, formData)
                .then((response) => {
                    EventBus.$emit('toast-success', this.$t('알림'), this.$t(`문의사항을 접수하였습니다. 최대한 빠르게 답변드리도록 하겠습니다.`))
                    this.hideModal()

                    const mail_path = this.$store.state.backend_host + '/send_customer_service_mail'
                    axios.post(mail_path, formData)
                })
                .catch(error => {
                    console.log(error);                
                }) 
            }
            else {
                alert(this.$t(`필수 항목을 모두 입력하여 주십시오`))
            }
        },
        checkForm(){
            if ( this.name!='' && this.e_mail!='' && this.requirements!='' ) {
                this.is_form_filled = true
            } else this.is_form_filled = false
        },
        hideModal(){
            this.$emit('close-need-help')
        }
       
    },
    created(){
        this.name = this.user_name
        this.company = this.company_name
        this.e_mail = this.store_e_mail
        // console.log(this.campaign_in_progress.campaign_name);
        if(this.campaign_in_progress.campaign_name!=undefined||this.campaign_in_progress.campaign_name!=''){
            this.requirements = this.$t(`${this.campaign_in_progress.campaign_name}`) + this.$t('관련 문의')
        }
        else this.requirements = ''
    },
    mounted(){
        // console.log(this.user_name);
    }

}
</script>

<style scoped>

</style>