<template>
<div style="z-index:1002">
    <b-navbar toggleable="sm" type="dark" class="bgray-100 header-nav px-lg-4">
        <b-navbar-brand><div @click="pageGo()"><b-img class="header-logo" src="@/assets/Tier Zero_basic.svg"></b-img></div></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto d-sm-flex align-items-sm-center" >
                <!-- <b-nav-item > -->
                    <div class="d-none d-lg-block f-85 gray-600"> <span v-if="company_name !=''"> {{ company_name }}</span> <span v-if="user_name !=''">{{ user_name }}</span>{{ $t(`님 안녕하세요`) }}</div>
                <!-- </b-nav-item> -->
                <b-nav-item>
                    <b-button size="sm" variant="dark-border" class="btn-round-2 ml-2 ml-sm-4" id="my-info" @click.stop="showMyInfo"> <fa :icon="['far','user']"></fa> </b-button>
                </b-nav-item>
                <b-nav-item>
                    <b-button size="sm" :variant="number_of_new > 0 ? 'red': 'dark-border'" class="btn-round-2 ml-2" id="notice" @click.stop="showNotice"> <fa :icon="['far','bell']"></fa></b-button>
                </b-nav-item>
                <!-- <b-nav-item>
                    <b-button size="sm" variant="dark-border" class="btn-round-2 ml-2" id="received-comments" @click.stop="showComments"> <fa :icon="['far','comment-dots']"></fa> </b-button>
                </b-nav-item> -->
                <b-nav-item>
                    <b-button size="sm" variant="dark-border" class="btn-round-2 ml-2" id="faq" @click.stop="showFaq" > <fa icon="book"></fa> </b-button>
                </b-nav-item>
                <b-nav-item><span v-if="is_admin >= 4">
                    <b-button size="sm" variant="dark-border" class="btn-round-4 ml-2 f-105" @click.stop="$refs['change-login-company'].show()" v-b-tooltip title="로그인 기업 변경"> <fa icon="user-secret"></fa> </b-button>
                </span></b-nav-item>
                <b-nav-item><span v-if="is_admin >= 2">
                    <b-button size="sm" variant="blue-border" class="btn-round-4 ml-2 ml-sm-3" id="to-user-main" @click="goAdminPage()"> {{ $t(`관리자`)}} </b-button>
                </span></b-nav-item>
                <b-nav-item>
                    <b-dropdown size="sm" variant="red-border" class="btn-round-4 ml-2 ml-sm-3" >
                         <template #button-content> <fa icon="globe"></fa> {{selected_lang}} </template>
                        <b-dropdown-item  @click="$i18n.locale=lang.value" v-for="lang in langs" :key="lang.id"> {{ lang.text }} </b-dropdown-item>
                    </b-dropdown>
                </b-nav-item>
                
                <b-nav-item><b-button size="sm" variant="dark-border" class="btn-round-4 ml-2 ml-sm-3" @click="logout"> {{ $t(`로그아웃`)}} </b-button></b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>

            <b-tooltip target="my-info" triggers="hover"> {{$t(`내 정보 관리`)}}</b-tooltip>
            <b-tooltip target="notice" triggers="hover"> {{ number_of_new }} {{ $t(`개 새로운 공지사항`)}} </b-tooltip>
            <!-- <b-tooltip target="received-comments" triggers="hover"> 나에게 온 코멘트 </b-tooltip> -->
            <b-tooltip target="faq" triggers="hover"> {{$t(`FAQ & 사용 매뉴얼`)}} </b-tooltip>
            <b-tooltip target="to-user-main" triggers="hover">{{$t(`관리자 페이지로 이동`)}}</b-tooltip>

            <b-modal ref="my-info" size="lg" :title="$t(`내 정보 관리`)" id="modify-modal-top" hide-footer><user-info @user-save="hideMyInfoModal"></user-info></b-modal>
            <b-modal ref="notice" size="lg" :title="$t(`공지사항`)" id="modify-modal-top" hide-footer scrollable><notice @make-read-notice="makeReadNotice"></notice></b-modal>
            <!-- <b-modal ref="received-comments" size="lg" title="나에게 온 코멘트" id="modify-modal-top" hide-footer scrollable><received-comments></received-comments></b-modal> -->
            <b-modal ref="faq" size="lg" :title="$t(`FAQ & 사용 매뉴얼`)" id="modify-modal-top" hide-footer scrollable ><faq></faq></b-modal>
            <b-modal ref="change-login-company" size="lg" title="로그인 기업 변경"><change-login-company></change-login-company></b-modal>
    
</div>
</template>

<script>
import UserInfo from '@/components/provider_landing/UserInfo'
import Notice from './Notice'
import Faq from '@/components/provider_landing/Faq'
import ReceivedComments from '@/components/main_layout/comments/ReceivedComments'
import ChangeLoginCompany from '@/components/main_layout/admin/ChangeLoginCompany'
import { mapState, mapGetters } from 'vuex'

export default {
    data() {
        return {
            notices: [],
            langs: [{text:'한국어', value:'ko'}, {text:'English', value:'en'}, {text:'中文', value:'cn'}]
        }
    },
     computed: {
        ...mapState(['user_id', 'company_id', 'user_name', 'company_name', 'e_mail', 'is_admin', 'campaign_in_progress']),
        ...mapGetters(['selected_lang']),
        isLoggedIn() {
            return this.$store.getters.isLoggedIn
        },
        number_of_new(){
            let x = this.notices.filter((item) => {
                return item.new == true
            })            
            return x.length            
        },
        selected_lang(){
            if (this.$i18n.locale=='ko'){ return 'Korean'
            }if (this.$i18n.locale=='en'){ return 'English'
            }if (this.$i18n.locale=='cn'){ return 'Chinese' }
        },
        
    },
    methods: {
        logout(){
            this.$store.dispatch('logout').then(() => { this.$router.push({name:'Login'})  } )
        },
        showMyInfo() {
            this.$refs['my-info'].show()
        },
        showNotice() {
            this.$refs['notice'].show()
        },
        showFaq() {
            this.$refs['faq'].show()
        },
        showComments(){
            this.$refs['received-comments'].show()
        },
        pageGo(){
            this.$router.push({name: 'Main'})
        },
        readNotices() {
            const path = this.$store.state.backend_host + '/read_notices'

            return axios.get(path, { params: {company_id: this.company_id, user_id: this.user_id}})
            .then(response =>{
                //console.log(response);                
                this.notices = response.data.notices
            })
            .catch(error => {
                console.log(error);
            })
        },
        hideMyInfoModal(){
            this.$refs['my-info'].hide()
        },
        goAdminPage(){
            this.$router.push({name:'UserMainLayout'})
        },
        makeReadNotice(notice_id){
            let idx = this.notices.findIndex(item => item.notice_id == notice_id)
            if(idx > -1){
                let new_notice = { ...this.notices[idx] }
                new_notice.new = false
                this.notices.splice(idx, 1, new_notice)
            }
        },
        
    },
    created(){
        this.readNotices()
        .then( ()=> {
            if (this.number_of_new > 0){
                this.showNotice(0)
            } 
        })
        this.$store.commit('set_view_mode', 'provider')
    },
    components: {
        UserInfo,
        Notice,
        Faq,
        ReceivedComments,
        ChangeLoginCompany
    }
    
}
</script>

<style>
.header-nav > div.navbar-brand, .header-nav > div>ul>li>a.nav-link {
    padding:0!important;
}
.header-nav >.navbar-toggler {
    border-color: transparent;
}

.header-nav >.navbar-toggler >.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(100,100,100,0.7)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

@media (max-width: 574px) {
    .header-nav > div> ul.navbar-nav  {
        margin-top: 0.75rem;
        display: inline-block!important;
    }
    .header-nav > div> ul>li  {
        float:left!important;
    }

}
</style>