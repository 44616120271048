<template>

<header class="sticky-top">
    <b-navbar toggleable="lg" type="dark" class="bgray-800 header-nav py-0">
        <b-navbar-brand><div @click="pageGo('',{name:'Main'})"><b-img class="header-logo" src="@/assets/Tier Zero_white.svg"></b-img></div></b-navbar-brand>

        <b-navbar-toggle target="user-header-collapse" class="ml-auto py-3"></b-navbar-toggle>
        <b-collapse id="user-header-collapse" is-nav>
            <b-navbar-nav class="mx-auto d-lg-flex align-items-lg-center"  @mouseleave="show_sub_menu=false">
                <b-nav-item @click.stop="pageGo('camp', {name: 'UserMainLayout'})" @mouseover="show_sub_menu=false" :class="[is_active=='camp' ? 'active-head':'', 'mx-3 py-3']"> {{$t(`캠페인 관리`)}} </b-nav-item>
                <b-nav-item @click.stop="pageGo('template', {name: 'TemplateList'})" @mouseover="show_sub_menu=false" :class="[is_active=='template' ? 'active-head':'', 'mx-3 py-3']" v-if="is_admin >=4"> {{$t(`질문지 관리`)}} </b-nav-item>
                <b-nav-item @mouseover="showSubMenu('participants')" :class="[is_active=='user' ? 'active-head':'', 'mx-3 py-3 position-relative']" v-if="is_admin >=3">{{$t(`참여대상 관리`)}}
                    <div @mouseover="showSubMenu('participants')" :class="show_sub_menu==true&&is_participants_sub==true?'position-absolute sub-menu-box':'d-none'" style="width: 200px;">
                        <b-button-group vertical>
                            <b-button @click="pageGo('user',{name: 'ManagingCompany'})" class="f-90 text-left py-2 px-3 no-shadow" variant="dark5">{{$t(`기업 & 참여자 관리`)}}</b-button>
                            <!-- <b-button @click="pageGo('user',{name: 'ManagingUser', params: { target_company_name: '' }})" class="f-90 text-left py-2 px-3" variant="dark5">{{$t(`참여자 등록`)}}</b-button> -->
                            <b-button @click="pageGo('user',{name: 'PortfolioList'})" class="f-90 text-left py-2 px-3 no-shadow" variant="dark5">{{$t(`기업 리스트 관리`)}}</b-button>
                        </b-button-group>
                    </div>
                </b-nav-item>
                <b-nav-item @mouseover="showSubMenu('')" :class="[is_active=='operation' ? 'active-head':'', 'mx-3 py-3 position-relative']" v-if="is_admin >=3"> {{$t(`운영 관리`)}}
                    <div @mouseover="showSubMenu('')" :class="show_sub_menu==true&&is_participants_sub!=true? 'position-absolute sub-menu-box':'d-none'" style="width: 200px;">
                        <b-button-group vertical>
                            <b-button @click="pageGo('operation', {name: 'NoticeManager'})" class="f-90 text-left py-2 px-3 no-shadow" variant="dark5">{{$t(`공지하기`)}}</b-button>
                            <b-button @click="pageGo('operation', {name: 'CsManager'})" class="f-90 text-left py-2 px-3 no-shadow" variant="dark5">{{$t(`문의사항 관리`)}}</b-button>
                        </b-button-group>
                    </div>
                </b-nav-item>
            </b-navbar-nav>
        </b-collapse>
            <b-button size="sm" variant="dark" class="btn-round-2 ml-2 ml-lg-2" id="my-info" @click.stop="showMyInfo"> <fa :icon="['far','user']"></fa> </b-button>
            <b-button size="sm" variant="dark" class="btn-round-4 ml-2 ml-lg-2" @click="logout">{{$t(`로그아웃`)}} </b-button>
    </b-navbar>        
            <!-- 툴팁 -->
            <b-tooltip target="my-info" triggers="hover"> {{$t(`내 정보 관리`)}}</b-tooltip>
            <!-- <b-tooltip target="to-user-main" triggers="hover"> 캠페인 페이지로 이동 </b-tooltip> -->
            <!-- 모달 -->
            <b-modal ref="my-info" size="lg" :title="$t(`내 정보 관리`)" hide-footer><user-info></user-info></b-modal>
        
</header>

</template>

<script>
import UserInfo from './provider_landing/UserInfo'

export default {
    data(){
        return {
            show_sub_menu: false,
            is_participants_sub: false,
            is_active:'camp'
        }
    },
    computed: {
        user_name() {
            return this.$store.getters.getUserName
        },
        company_name() {
            return this.$store.getters.getCompanyName
        },
        e_mail(){
            return this.$store.getters.getEMail
        },
        isAuthenticated(){
            return this.$store.getters.isAuthenticated
        },
        isLoggedIn() {
            return this.$store.getters.isLoggedIn
        },
        is_admin(){
            return this.$store.getters.isAdmin
        }
    },
    methods: {
        showSubMenu(menu){
            this.show_sub_menu = true
            if (menu=='participants'){                
                this.is_participants_sub = true
            }
            else this.is_participants_sub = false
        },
        logout(){
                this.$store.dispatch('logout')
                .then(() => { this.$router.push({name:'Login'})  } )
        },
        showMyInfo() {
            this.$refs['my-info'].show()
        },
        pageGo(mode, destination){
            this.$store.commit('update_now_page', destination[name])
            this.$router.push(destination)
            this.is_active = mode
        }
                    
    },
    created(){
        this.$store.commit('set_view_mode', 'user')
    },
    components : {
        UserInfo,
    }

}
</script>
<style>

.active-head{
    position: relative;
}
.active-head > .nav-link {
    color: var(--gray-200)!important;
}
.active-head::after {
    position: absolute;
    bottom: 0;
    height: 0;
    width: 0;
    left: calc(50% - 4px);
    border: 8px solid transparent;
    border-bottom-color: var(--gray-200);
    content: "";
}

.sub-menu-box{
    margin-top:4px; 
    left:0;
    z-index: 30;
}

@media (max-width: 992px) {
    .active-head::after {
        position: absolute;
        bottom: 0;
        height: 0;
        width: 0;
        left: calc(50% - 4px);
        border: 0;
        content: "";
    }
    .sub-menu-box{
        margin-top:-25px; 
        left:100px;
        z-index: 30;
    }
}
</style>
