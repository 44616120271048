<template>
<b-container class="position-relative px-0">
    <template v-if="template_tree.template_contents.length>0">
        <div v-if="edit_mode==true&&block_list!=true" class="d-flex justify-content-between position-absolute" style="margin-top:-45px; right:0;">
            <span v-if="is_delete_mode!=true"><b-button @click="toggleDeleteMode(true)" size="sm" variant="dark-border" class="bottom-radius-0">수정/삭제</b-button></span>
            <span v-else><b-button @click="toggleDeleteMode(false)" size="sm" variant="dark-border" class="bottom-radius-0">수정완료</b-button></span>  
        </div>

        <Tree @change="saveTemplate" :data="template_contents" :draggable="edit_mode==true" :prevent-select="true" :space="1" :indent="10">
            <template v-slot="{data, store}">
                <div v-if="!data.isDragPlaceholder" @click.stop="pageGo(data.question_code)" :class="audit_mode==true?'in-audit':''">
                    <b-list-group-item  @click="activateList(data.question_code)" :class="[is_active == data.question_code ? `node-level-${data._vm.level}-active`: '', `node-level-${data._vm.level}`]">
                        <div class="d-flex align-items-center justify-content-between"> 
                            <div>
                                <fa v-if="edit_mode==true" :id="`handle-${data}`" icon="grip-vertical" class="mr-2 handle"></fa> 
                                <span v-if="modify_id==data._id &&edit_mode==true"><b-input class="f-90 d-inline" @keyup.enter="nameUpdate(data, store)" v-model="data.question_name"> {{data.question_name}} </b-input> </span>
                                <span v-else> {{ data.question_name }} </span>
                            </div>
                            <div :class="is_delete_mode==true?'d-none':'float-right'" v-if="data.children && data.children.length" @click.stop="store.toggleOpen(data)"><fa :icon="data.open?'angle-up':'angle-down'" class="mr-2"></fa> </div>

                            <div v-if="edit_mode==true&&is_delete_mode==true" class="d-flex align-items-center">
                                <span v-if="modify_id==data._id"><b-button size="sm" variant="icon" @click.stop="nameUpdate(data,store)"><fa icon="save"></fa></b-button></span>
                                <span v-else><b-button size="sm" variant="icon" @click.stop="modifyNode(data._id, store)"><fa icon="pen"></fa></b-button></span>
                                <span><b-button size="sm" variant="icon" @click.stop="handleDeleteQuestion(data._id)"><fa icon="trash-alt"></fa></b-button></span>
                                <!-- 삭제경고 Modal -->
                                <b-modal header-text-variant="danger" @ok="deleteQuestion(data._id, store)" :ref="`delete-modal-${data._id}`" title="경고">
                                    <p> 진행할 경우 선택한 {{ data.question_name }} 질문의 내용 및 세부 컴포넌트 설정이 지워집니다. 이 변경은 되돌릴 수 없습니다.</p>
                                    <p> 계속 하시겠습니까? </p>
                                </b-modal>
                            </div>
                        </div>
                    </b-list-group-item>
                </div>
            </template>
        </Tree>
    </template>
    <template v-else>
        <div class="py-5 text-center bgray-200 rounded">'새질문'으로 질문 리스트를 만드세요.</div>
    </template>
        
    <div class="text-right pt-4"><b-button v-if="edit_mode==true" variant="blue" size="sm" @click="addChild"><fa icon="plus" class="mr-2"></fa>새질문</b-button></div>

</b-container>
</template>

<script>
import {DraggableTree} from 'vue-draggable-nested-tree'
import * as th from 'tree-helper'
// import axios from 'axios'

export default {
    data(){
        return {
            modify_id: '',
            is_editing: false,
            is_delete_mode: false,
            is_collapseall: false,
            is_active: 'false',
            delete_target_id: '',
        }
    },
    props:{
        audit_mode: {type:Boolean},
        block_list: {type:Boolean}
    },
    computed: {
        template_tree: {
            get() {
                return this.$store.state.template_tree
            },
            set(new_tree) {
                return this.$store.state.template_tree = new_tree
            }
        },
        template_name: {
            get() {
                return this.template_tree.template_name
            },
            set(new_name) {
                this.template_tree.template_name = new_name
            }
        },
        template_code: {
            get() {
              return this.template_tree.template_code
            },
            set(newValue) {
              return this.template_tree.template_code = newValue
            }
        },
        template_contents: {
            get() {
                return this.template_tree.template_contents
            },
            set(new_contents) {
                this.template_tree.template_contents = new_contents
            }
        },
        edit_mode() {
            return this.$store.state.questions.edit_mode
        },
        answer_company(){
            return this.$store.state.questions.answer_company
        },
        campaign() {
            return this.$store.state.campaign
        },
    },
    watch: {
    },
    components: {
        Tree : DraggableTree
    },
    created() {
        this.readTemplate(this.template_code)
        EventBus.$on('update-tree-contents',this.updateTree)        
    },
    methods: {
        addChild() {
            const new_tree_node = {
                question_code: 'q' + Math.floor(Math.random() * 1000000000),
                question_name: '새질문',
                question_infobox: '',
                question_contents: []
            }
            this.$store.commit('reset_question_subs')
            this.template_contents.push(new_tree_node)
            this.$store.commit('update_question_composition', { 
                question_code: new_tree_node.question_code, 
                question_name: new_tree_node.question_name,
                question_infobox: new_tree_node.question_infobox,
                qeustion_contents: new_tree_node.question_contents })
            this.$store.dispatch('saveQuestionComposition')
            .then( () => { this.saveTemplate()} )
        },
        expandAll() {
          th.breadthFirstSearch(this.template_contents, node => {
            node.open = true
          })
           return this.is_collapseall = false
        },
        collapseAll() {
          th.breadthFirstSearch(this.template_contents, node => {
            node.open = false
          })
            return this.is_collapseall = true
        },
        updateTree(question_code, question_name){
            const new_template_contents = this.template_contents
            th.breadthFirstSearch(new_template_contents, node => {
                if (node.question_code == question_code) node.question_name = question_name
            })
            this.template_contents = new_template_contents
        },
        nameUpdate(x, t){
            this.modifyNode()
            const new_question_composition = {
            question_code: x.question_code,
            question_name: x.question_name,
            question_infobox: this.$store.state.question_composition.question_infobox,
            question_contents: this.$store.state.question_composition.question_contents
            }
            this.$store.commit('update_question_composition',new_question_composition)
            this.$store.dispatch('saveQuestionComposition')
            this.saveTemplate()
        },
        modifyNode(x, t) {
            this.modify_id = x
            if(x) {
                t.getNodeById(x).draggable = false
                this.is_editing = true
            } else {
                this.is_editing = false
                th.breadthFirstSearch(this.template_contents, node => {
                node.draggable = true
                })
            }
        },
        pageGo(question_code) {
            // console.log(this.$store.state.question_composition.question_code);
            this.$store.commit('update_is_question_subs_loaded',false)
            this.$store.dispatch('readQuestionComposition', question_code)
            .then( () => {
                this.$store.dispatch('readQuestionSubs')
                .then( () => {
                    this.$store.dispatch('questions/readSubsInit', this.$store.state.question_composition.question_contents)
                    this.$store.dispatch('score/readOverallComment', {campaign_id: this.campaign.campaign_id, 
                                                                        company_id: this.answer_company.company_id, 
                                                                        question_code: question_code})
                })        
            })
        },
        activateList(question_code){
            this.is_active = question_code
        },
        toggleDeleteMode(x){
            this.is_delete_mode = x 
        },
        handleDeleteQuestion(x){
            this.delete_target_id = x
            this.$refs[`delete-modal-${x}`].show()
        },
        deleteQuestion(delete_target_id, t){            
            let target_node = t.getNodeById(delete_target_id)
            t.deleteNode(target_node)
            this.$store.dispatch('saveTemplateTree')

            this.is_delete_mode = false
            
        },
        saveTemplate() {
            this.$store.dispatch('saveTemplateTree')
        },
        readTemplate(template_code) {
            this.$store.dispatch('readTemplateTree',{ template_code: template_code, is_previous: false})
        }
    }
}
</script>

<style scoped>
.in-audit .node-level-1,
.in-audit .node-level-2,
.in-audit .node-level-3 {
    color: var(--gray-600);
    background-color: rgba(0,0,0,0);
}
.in-audit .node-level-1:hover,
.in-audit .node-level-2:hover,
.in-audit .node-level-3:hover {
    color:black;
    background-color: rgba(0,0,0,0);
}
.in-audit .node-level-1-active,
.in-audit .node-level-2-active,
.in-audit .node-level-3-active{
    color: white;
    background-color: var(--blue);
}
.in-audit .node-level-1 {
    padding: 0.3rem 0;
    border-bottom: 1px solid var(--gray-500);
}
.in-audit .node-level-2 {
    padding: 0.2rem;
}


.node-level-1,
.node-level-2,
.node-level-3{
    font-weight: 500;
    width: 100%;
    padding: 0.25rem 0.5rem;
    border: 0;
    border-radius: 0;
}
.node-level-1{
    background-color: var(--gray-200);   
}
.node-level-2{
    font-size: 0.95rem;
    color: var(--gray-600);
}
.node-level-3{
    font-size: 0.9rem;
    color: var(--gray-600);
}

.node-level-1:hover,
.node-level-2:hover,
.node-level-3:hover,
.node-level-4:hover{
    color: var(--gray-500);
    cursor: pointer;
}
.node-level-1-active,
.node-level-2-active,
.node-level-3-active,
.node-level-4-active {
    background-color: var(--blue);
    color: var(--gray-100);
}

.node {
    cursor: pointer;
}


/*안쓰는 스타일
.ih-button{
    font-size: 0.9rem;
    padding: 0.2rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    margin-left: 5px;
    margin-bottom: 20px;
}

.ih-modify{
    font-size: 0.8rem;
    padding: 0.2rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    margin-left: 5px;
}

.ih-delete{
    font-size: 0.8rem;
    padding: 0.2rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    margin-left: 5px;
}*/

.tree-node-inner-back {
  padding: 5px;
  cursor: pointer;
}

.draggable-placeholder-inner{
  border: 1px dashed #0088F8;
  box-sizing: border-box;
  background: rgba(0, 136, 249, 0.09);
  color: #0088f9;
  text-align: center;
  padding: 0;
  display: flex;
  align-items: center;
}

.draggable-placeholder-inner{
    background-color: orangered;
}

</style>
