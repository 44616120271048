<template>
<div class="">
    <section class="px-2 px-md-5 px-lg-3 pt-3 mb-5">
        <div class="f-105 line-height-lg mb-5" v-html="byLang(campaign_description, campaign_description_e, campaign_description_c)"> </div>

        <b-card v-if="campaign.required_info.length != 0" header-tag="header" class="mb-5 "> 
            <template v-slot:header> <h6 class="mb-0 fw-500 gray-550">{{$t(`사전 정보 입력`)}} </h6> </template>     
            <b-table-simple class="mb-0">
                <b-tbody>
                    <b-tr v-for="(item, index) in required_info" :key="index" class="">
                        <b-td class="text-left border-0 p-1"> {{index+1}}.</b-td>
                        <b-td class="text-left border-0 p-1"> {{ item.title=='매출액' ? $t(`매출액 정보를 선택하여 주십시오`): $t(item.title) }} {{item.title=='매출액' ? '':$t(`정보를 선택하여 주십시오`)}} </b-td>
                        <b-td class="text-left border-0 p-1"> 
                            <b-dropdown :text="$t(required_info[index].result)" variant="dark-border" block :disabled="campaign.campaign_state=='완료'">
                                <b-dropdown-item v-for="value in item.structure" :key="value" @click="handleSelect(index, value)"> {{ $t(value) }} </b-dropdown-item>
                            </b-dropdown>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            
            <div v-if="validation_comment!=''" :class="validation_style" class="mt-3"><fa :icon="validation_icon" v-if="validation_icon !=''"></fa> {{ validation_comment }} </div>
        </b-card>

        <div class="pt-4 mb-5 text-center">
            <b-button variant="blue" class="f-110" style="min-width: 30%;" @click.stop.prevent="startSurvey">{{$t(`시작하기`)}}</b-button>
        </div> 
    </section>

        <b-modal size="lg" id="hj-agree" :title="$t(`정보제공 동의`)" scrollable hide-footer hide-header-close no-close-on-backdrop no-close-on-esc>
            <div class="overflow-auto" style="max-height:400px;" v-html="byLang(policy_content, policy_content_e, policy_content_c)">                   
            </div>
            <hr>
            <h6 class="mt-4 mb-4 text-center">{{ $t('위 내용에 동의하십니까?') }}</h6>
            <b-form-radio-group class="mb-4 text-center" v-model="selected" @change="handleAgree">
                <b-form-radio value="Y">{{ $t('예, 동의합니다.') }}</b-form-radio>
                <b-form-radio value="N">{{ $t('아니오, 동의하지 않습니다.') }}</b-form-radio>
            </b-form-radio-group>
            

            <div class="text-right">
                <span class="red"><fa v-if="warning_msg!=''" icon="exclamation-circle"></fa> {{warning_msg}}</span>
                <b-button @click="handleOk" :variant="is_agree==false?'red': 'blue'" class="ml-3">{{$t(`확인`)}}</b-button>
            </div>
        </b-modal>

</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import * as th from 'tree-helper'

export default {
    data () {
        return {
            required_info: [{result:'', structure:[], title: ''}],

            save_state: 'not_saved',

            validation_comment: '',
            validation_icon: '',
            validation_style: 'ih-validation-saved',
            selected:'',
            warning_msg:''
        }
    },
    computed: {
        ...mapState('policies', {
            is_agree: state => state.is_agree,
            policy_content: state => state.policy_content,
            policy_content_e: state => state.policy_content_e,
            policy_content_c: state => state.policy_content_c,
            policy_id: state => state.policy_id
        }),
        ...mapState(['company_id', 'campaign_in_progress', 'question_in_progress', 'question_in_progress_loaded', 'user_id', 'template_in_progress']),
        user_name () {
            return this.$store.getters.getUserName 
        },            
        company_name() {
            return this.$store.getters.getCompanyName
        },
        e_mail(){
            return this.$store.getters.getEMail
        },
        isAuthenticated(){
            return this.$store.getters.isAuthenticated
        },
        isLoggedIn() {
            return this.$store.getters.isLoggedIn
        },
        campaign() {
                    return this.$store.state.campaign_in_progress
        },
        campaign_description: {
            get() {
                return this.campaign.campaign_description
            }
        },
        campaign_description_e(){
            return this.campaign.campaign_description_e
        },
        campaign_description_c(){
            return this.campaign.campaign_description_c
        },
        number_of_questions: {
            get() {
                return this.$store.getters.getNumberOfQuestions
            }
        },
        number_of_completed_questions: {
            get() {
                return this.$store.getters.getNumberOfCompletedQuestions
            }
        },
        progress_rate: {
            get() {
                return this.$store.getters.getProgressRate
            }
        },
        first_question_code() {
            return this.$store.state.template_in_progress.template_contents[0].children[0].question_code
        },
    },
    methods: {
        ...mapActions('policies', ['readAgree', 'saveAgree']),
        ...mapMutations('policies', ['set_is_agree']),
        
        byLang(ko, en, cn){
            if(this.$i18n.locale=='en') return en
            else if (this.$i18n.locale=='cn') return cn
            else return ko
        },
        handleSelect(index, content){
            const path = this.$store.state.backend_host + '/save_required_info'

            let formData = new FormData()
            formData.append('campaign_id', this.campaign.campaign_id)
            formData.append('company_id', this.$store.state.company_id)
            formData.append('title', this.campaign.required_info[index].title)
            formData.append('result', content)

            return axios.post(path, formData)
            .then(response=>{
                // console.log(response)
                this.required_info.splice(index, 1, { title:this.required_info[index].title, structure: this.required_info[index].structure, result: content })
                this.$store.commit('update_temp_save_status', true)
                this.save_state ='saved'
            })
            .catch(error =>{
                console.log(error)
            })
        },
        readRequiredInfo(){
            if (this.campaign.required_info.length>0){
                const path = this.$store.state.backend_host + '/read_required_info'
                return axios.get(path, {
                            params: {
                                campaign_id: this.campaign.campaign_id,
                                company_id: this.$store.state.company_id
                            }
                })
                .then(response => {           
                    let temp_required_result = response.data
                    // console.log(temp_required_result);
                    // console.log(this.campaign.required_info);
                    if (temp_required_result.length>0){
                        for (let i=0; i<this.campaign.required_info.length; i++ ){
                            let new_info = {...this.campaign.required_info[i]}
                            
                            if(temp_required_result[i].result!=undefined&&temp_required_result[i].result!=''){
                                new_info.result = temp_required_result[i].result
                            }
                            else new_info.result = `${this.campaign.required_info[i].title}`+ this.$t(`선택`)
                            this.required_info.splice(i,1,new_info)
                        }
                    }
                    else {
                        this.required_info = [...this.campaign.required_info]
                        for (let i=0; i<this.campaign.required_info.length; i++ ){
                            this.required_info[i].result = `${this.required_info[i].title}`+ `선택`
                        }
                    }

                })
                .catch(error => {
                    //console.log('에러 찍어주세요');
                    console.log(error);
                })     
            }            
        },
        startSurvey(){
            this.pageGo(this.first_question_code)
            this.$emit('move-next-question', this.first_question_code) //to ProviderAside
        },
        showAgree(){
            // 접속한 캠페인의 campaign_id, 로그인한 기업의 company_id 보내서 동의 여부 상태 체크
            this.readAgree({company_id:this.company_id, campaign_id: this.campaign.campaign_id})
            .then(()=>{
                if ( this.policy_id!=null && this.is_agree!=true){
                    this.$bvModal.show('hj-agree')
                }   
            })                      
        },
        handleAgree(){
            Vue.nextTick(() => {
                // console.log(this.selected);
                if (this.selected=='Y'){
                    this.saveAgree({company_id:this.company_id, campaign_id: this.campaign.campaign_id, mode:true}) 
                    this.warning_msg = ''
                }
                else if (this.selected=='N'){ 
                    this.saveAgree({company_id:this.company_id, campaign_id: this.campaign.campaign_id, mode:false}) 
                    this.warning_msg = this.$t(`동의하지 않으실 경우, 본 온라인 질의에 참여하실 수 없습니다.`)
                }
            })
        },
        handleOk(){
            if (this.is_agree==true){
                this.$bvModal.hide('hj-agree')
            }
            else this.$router.push({name: 'Main'})
        },
        pageGo(question_code){
            //최초 이니시에이팅 완료 신호
            //this.initiating = false
            
            //자식노드가 있는 경우 클릭만으로도 접고 펴기
            //if(node.children && node.children.length) store.toggleOpen(node)

            //퀘스천 페이지 이동
            let temp_question_in_progress = { ...this.question_in_progress }
            temp_question_in_progress.question_code = question_code

            th.breadthFirstSearch(this.template_in_progress.template_contents,node => {
                if(node.question_code == question_code){
                    temp_question_in_progress.comment = node.comment
                    temp_question_in_progress.question_name = node.question_name
                    temp_question_in_progress.question_infobox = node.question_infobox
                    if(typeof(node.question_contents)=='string') {
                        temp_question_in_progress.question_contents = JSON.parse(node.question_contents)
                    }
                    else{
                        temp_question_in_progress.question_contents = node.question_contents
                    }
                    temp_question_in_progress.is_completed = node.is_completed
                }
            })
            this.$store.commit('update_question_in_progress', temp_question_in_progress)
            
            //console.log('퀘스천 콘텐츠')
            //console.log(temp_question_in_progress.question_contents)
            this.$store.dispatch('questions/readSubsInit', temp_question_in_progress.question_contents)
            .then( () => {
                Vue.nextTick( () => {
                    // console.log('퀘스천 코드' + question_code)
                    this.$router.push( { name: 'Questions', params: { question_code: question_code }} )
                })
            })
            .catch(error => {
                console.log(error)
            })
        }
    },
    updated(){
      //this.progress_bar_key += 1
    },
    created(){
        this.readRequiredInfo()
    },
    mounted(){
        // this.showAgree()
        // console.log(this.$store.state.template_in_progress.template_contents);
    },
    watch: {
        save_state: function() {
            if (this.save_state == 'saving') {
                this.validation_comment = this.$t(`저장 중 입니다.`)
                this.validation_icon = 'hourglass'
                this.validation_style = 'ih-validation-saving'
            }
            else if (this.save_state == 'saved') {
                this.validation_comment = this.$t(`저장 되어있습니다`)
                this.validation_icon = 'check'
                this.validation_style = 'ih-validation-saved'
            }
            else if (this.save_state == 'resaved') {
                this.validation_comment = this.$t(`변경 저장 되었습니다`)
                this.validation_icon = 'check'
                this.validation_style = 'ih-validation-resaved'
            }
        },
    },
    components:{
    }
  }
</script>

<style scoped>
.preface-required > header.card-header {
    background-color: var(--gray-600);
    padding: 0.5rem 1rem;
}
.preface-required >div.card-body{
    padding: 0.75rem 1rem;
}
</style>
