<template>
	<div>
		<b-button @click.stop="printStart()" :variant="btn_variant">
            <slot>
            </slot>
        </b-button>
        <div v-if="print_started==true" style="margin-left: -9999px; margin-top:-9999px; width: 1100px; position:absolute;" :key="start_key">
			<div id="printout">
            <div v-if="print_mode=='only_question'" :class="is_printing==true? 'printing px-5' : 'not_printing'">
				<div>
					<b-table-simple class="my-5 f-110" bordered>
						<b-thead>
							<b-tr>
								<b-td class="fw-600 bgray-200">구분</b-td>
								<b-td class="fw-600 bgray-200">내용</b-td>
							</b-tr>
						</b-thead>
						<b-tbody class="text-left">
							<b-tr>
								<b-td class="fw-500">평가명</b-td> <b-td> {{ target_campaign.campaign_name  }} </b-td>
							</b-tr>
							<b-tr>
								<b-td class="fw-500">평가기간</b-td> <b-td> {{ target_campaign.start_date }} ~ {{ target_campaign.end_date }} </b-td>
							</b-tr>
							<b-tr>
								<b-td class="fw-500">주최사</b-td> <b-td> {{ target_campaign.operator_name }} </b-td>
							</b-tr>
							<b-tr>
								<b-td class="fw-500">참여 기업명</b-td> <b-td> {{ target_company.company_name }} </b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>				
				</div>
				<div v-for="(question_code_item, index) in print_target_questions" :key="question_code_item.id">
					<questions-for-print 
					class="text-left"
					@load-started="handleLoadStarted" 
					@load-finished="handleLoadFinished"  
					:question_code="question_code_item" 
					:is_final_question_code="index==print_target_questions.length-1 ? true : false"
					></questions-for-print>				
				</div>
            </div>

            <div v-else-if="print_mode=='question_and_audit'" :class="is_printing==true? 'printing px-5' : 'not_printing'"> 
				<div>
					<b-table-simple class="my-5 f-110" bordered>
						<b-thead>
							<b-tr>
								<b-td class="fw-600 bgray-200">구분</b-td>
								<b-td class="fw-600 bgray-200">내용</b-td>
							</b-tr>
						</b-thead>
						<b-tbody class="text-left">
							<b-tr>
								<b-td class="fw-500">명칭</b-td> <b-td> {{ target_campaign.campaign_name  }} </b-td>
							</b-tr>
							<b-tr>
								<b-td class="fw-500">기간</b-td> <b-td> {{ target_campaign.start_date }} ~ {{ target_campaign.end_date }} </b-td>
							</b-tr>
							<b-tr>
								<b-td class="fw-500">주최사</b-td> <b-td> {{ target_campaign.operator_name }} </b-td>
							</b-tr>
							<b-tr>
								<b-td class="fw-500">참여 기업명</b-td> <b-td> {{ target_company.company_name }} </b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>				
				</div>
				<div v-for="(question_code_item, index) in print_target_questions" :key="question_code_item.id">
					<answers-for-print 
					class="text-left"
					@load-started="handleLoadStarted" 
					@load-finished="handleLoadFinished"  
					:question_code="question_code_item" 
					:is_final_question_code="index==print_target_questions.length-1 ? true : false"
					></answers-for-print>				
				</div>
			</div>
            </div> <!-- print_out div-->
        </div>

		<b-modal id="pdf_down_modal" size="lg" @show="is_printing=true" @hide="is_printing=false" hide-footer>
            <template v-slot:modal-title>
                <span>출력하기</span>
            </template>
            <div v-if="print_mode=='not_determined'">
               <div class="mt-2 mb-4 text-center fw-500">출력물에 상세평가(Audit) 내용을 포함하시겠습니까?</div>
               <div class="text-center mb-3"> 
                    <b-button @click="print_mode='question_and_audit'" class="mr-3" variant="dark-border" v-if="is_admin==4">예, 포함합니다.</b-button>
                    <b-button @click="print_mode='only_question'" variant="dark-border">아니오, 질문과 답변만 필요합니다.</b-button>
                </div>               
            </div>
            <div v-else>
                <div class="d-block text-center">
                    <div class="pb-3"><b-progress :value="num_of_print_target_questions_started + num_of_print_target_questions_loaded" :max="num_of_print_target_questions" animated></b-progress> </div>

                    <div v-if="print_ready!=true">
                        <div class="d-flex align-items-center justify-content-center"> <b-spinner small class="mr-2" type="grow"></b-spinner>  {{ msg }}</div>
                    </div>
                    <div v-else class="mt-3">
                        <b-button v-if="isIE!=true" v-print="'#printout'" variant="blue">PDF 인쇄 / 다운로드</b-button>
					    <b-button v-else @click="pdf.save('company_report.pdf')" variant="blue">PDF 다운로드</b-button>
                    </div>
                </div>                
            </div>
            <b-button class="float-right" @click="cancel()" variant="dark">취소</b-button>
		</b-modal>
	</div>
</template>
<script>
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import QuestionsForPrint from '@/components/print_to_pdf/QuestionsForPrint'
import AnswersForPrint from '@/components/print_to_pdf/AnswersForPrint'
import * as th from 'tree-helper'
import * as ih from '@/util'
import { mapState } from 'vuex'

export default {
    components: {
        QuestionsForPrint,
        AnswersForPrint
    },
	name: 'pdf',
	data () {
		return {
			print_target_questions: [],
			start_key: Math.random()*1000,
			propTitle: 'mypdf',
			print_started: false,
			print_ready: false,
			pdf: '',
			print_target_questions_started: [],
			print_target_questions_loaded: [],
			msg: 'PDF 파일 준비중... 최대 1분 정도 소요될 수 있습니다.',
            is_printing: false,

            print_mode: 'not_determined',
        
		}
    },
    props:{
        btn_variant:{type:String, default:'dark-border'}
    },
	computed:{
        ...mapState(['is_admin']),
        target_campaign(){
            return this.$store.state.campaign
        },
        target_template_contents(){
            return this.$store.state.template_tree.template_contents
        },
        target_company(){
            return this.$store.state.questions.answer_company
        },    
		num_of_print_target_questions(){
			return this.print_target_questions.length * 2
		},
		num_of_print_target_questions_started(){
			return this.print_target_questions_started.length
		},
		num_of_print_target_questions_loaded(){
			return this.print_target_questions_loaded.length
		},
		progress_rate(){
			return Math.floor( (this.num_of_print_target_questions_started + this.num_of_print_target_questions_loaded) / this.num_of_print_target_questions )
        },
		isIE() {
            if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (navigator.userAgent.toLowerCase().indexOf("msie") != -1) )
                { return true }
            else
                { return false }
        }		
	},
	methods: {
		handleLoadStarted(question_code){
			this.print_target_questions_started.push(question_code)
		},  
		handleLoadFinished(question_code){
			this.print_target_questions_loaded.push(question_code)
			if(question_code == this.print_target_questions[this.print_target_questions.length - 1]){
				while(this.progress_rate < 1)
				{	
					this.print_target_questions_loaded.push('dummy')
				}
			}
		},
		makePDF(sel) {
			window.html2canvas = html2canvas //Vue.js 특성상 window 객체에 직접 할당해야한다.
			let that = this
			let pdf = new jsPDF('p', 'mm', 'a4')
			let canvas = pdf.canvas
			const pageWidth = 210 //캔버스 너비 mm
			const pageHeight = 295 //캔버스 높이 mm
			canvas.width = pageWidth

            let ele = document.querySelector(sel)
			let width = ele.offsetWidth // 셀렉트한 요소의 px 너비
			let height = ele.offsetHeight // 셀렉트한 요소의 px 높이
			let imgHeight = pageWidth * height/width // 이미지 높이값 px to mm 변환

			if(!ele){
				console.warn(selector + ' is not exist.')
				return false
			}

            return html2canvas(ele)
            .then( function(canvas) {
					let position = 0
					const imgData = canvas.toDataURL('image/png')
					pdf.addImage(imgData, 'png', 0, position, pageWidth, imgHeight, undefined, 'slow')

					//Paging 처리
					let heightLeft = imgHeight //페이징 처리를 위해 남은 페이지 높이 세팅.
					heightLeft -= pageHeight
					while (heightLeft >= 0) {
						position = heightLeft - imgHeight
						pdf.addPage();
						pdf.addImage(imgData, 'png', 0, position, pageWidth, imgHeight)
						heightLeft -= pageHeight
					}
					
					return pdf
			})			
		},
		printStart(){
			this.$bvModal.show('pdf_down_modal')
			//this.is_printing = true //for debug
			//this.print_mode = 'question_and_audit' //for debug
			if(this.print_started != true){				
				this.initiatePrintTargetQuestions()
				this.print_target_questions_started = []
				this.print_target_questions_loaded = []
				this.print_started=true
			}	
		},
		cancel(){
			this.print_started = false				
			this.print_target_questions_started = []
			this.print_target_questions_loaded = []
	        this.print_mode='not_determined'
			this.$bvModal.hide('pdf_down_modal')
		},
		async initiatePrintTargetQuestions(){

            await this.$store.dispatch('makeTemplateTreeByCompanyId', this.target_company.company_id)

			this.print_target_questions = []
			let template_contents = this.target_template_contents
			let temp_print_target = []
			th.breadthFirstSearch(template_contents, node => {
				if(node.children == undefined || node.children.length == 0){
					temp_print_target.push(node.question_code)
				}
			})
			this.print_target_questions = temp_print_target
		}
    },
    created(){
        if(this.$store.state.is_evaluate_loaded==false)
        {
            this.$store.dispatch('score/readAllEvaluateResult', {campaign_id: this.campaign.campaign_id, company_id: this.answer_company.company_id})
        }
    },
	watch:{
		async progress_rate(new_value){
			if(new_value >= 1){
				if(this.isIE==true) { this.pdf = await this.makePDF('#printout') }
				this.print_ready = true
				this.msg = '출력 준비가 완료되었습니다.'
			}
		}
	}
}
</script>
<style scoped>
.printing{
	display: block;
}

.not_printing{
	display: none;
}
</style>