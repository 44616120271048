<template>
  <div>
    <h2 class="pt-5 mb-4 fw-600"> {{$t(`이용약관`)}} </h2>

    <b-card class="mb-5 border-0 shadow-sm">
        <div class="terms-of-use-text">
            <h3>{{$t(`제1장 [총칙]`)}}</h3>
                <br>
                <h5>{{$t(`제1조 [목적]`)}}</h5>
                    {{$t(`본 이용약관은 ㈜퀀티파이드이에스지(이하 “QESG”)가 운영하는 온라인 플랫폼 TierZero(이하 “당 사이트”)에서 제공하는 서비스 이용을 위한 회원의 권리와 의무, 책임사항, 그 밖에 필요한 기본적인 사항을 규정함을 목적으로 합니다.`)}}<br>
                    <br>
                <h5>{{$t(`제2조 [용어의 정의]`)}}</h5>
                    {{$t(`본 약관에서 정의하는 용어는 다음과 같으며 정의되지 않은 약관상 용어의 의미는 관련 법령 및 일반적인 관행에 의하기로 합니다.`)}}<br>
                    <div class="terms-of-use-details">
                    1.	"{{$t(`이용자`)}}"{{$t(`라 함은 당 사이트에 접속하여 이 약관에 따라 당 사이트가 제공하는 서비스를 받는 회원을 말합니다.`)}}<br>
                    2.	"{{$t(`회원`)}}"{{$t(`이라 함은 서비스를 이용하기 위하여 당 사이트에 개인정보를 제공하여 아이디(ID)와 비밀번호를 부여 받은 자를 말합니다.`)}}<br>
                    3.	"{{$t(`계정`)}}"{{$t(`이란 당 사이트에 가입된 회원 별 권한을 의미합니다.`)}}<br>
                    4.	"{{$t(`회원 아이디(ID)`)}}"{{$t(`라 함은 회원의 식별 및 서비스 이용을 위하여 자신이 선정한 문자 및 숫자의 조합을 말합니다.`)}}<br>
                    5.	"{{$t(`비밀번호`)}}"{{$t(`라 함은 회원이 자신의 개인정보 및 직접 작성한 콘텐츠의 보호를 위하여 선정한 문자, 숫자 및 특수문자의 조합을 말합니다.`)}}<br>
                    6.	"{{$t(`가입`)}}"{{$t(`이라 함은 회원가입 시 제공하는 신청서 양식에 해당 정보를 기입하고, 본 약관에 동의하여 서비스 이용계약을 완료하는 행위를 말합니다.`)}}<br>
                    7.	"{{$t(`소유권`)}}"{{$t(`이란 데이터 상품의 판매를 통해 수익을 발생시킬 수 있는 권리를 의미하며 ‘소유권자’란 해당 권리를 가진 자를 의미합니다.`)}}</div>
                    <br>
                    <h5>{{$t(`제3조 [약관의 명시 및 개정]`)}}</h5>
                    <div class="terms-of-use-details">
                    {{$t(`1. 당 사이트는 본 약관의 내용을 회원이 알 수 있도록 당 사이트의 초기 화면 또는 연결된 페이지를 통해 공지합니다.`)}}<br>
                    {{$t(`2. 당 사이트가 약관을 개정할 경우에는 적용일자 및 개정 사유를 명시하여 현행 약관과 함께 당 사이트의 초기화면 또는 초기화면과의 연결화면에 적용일자 7일 이전부터 적용일자 전일까지 공지합니다.`)}} {{$t(`다만, 회원에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.`)}} {{$t(`이 경우 당 사이트는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.`)}}<br>
                    {{$t(`3. 당 사이트가 전항에 따라 개정약관을 공지하면서 “개정일자 적용 이전까지 회원이 명시적으로 거부 의사 표시를 하지 않는 경우 회원이 개정약관에 동의한 것으로 봅니다.”라는 취지를 명확하게 공지하였음에도 회원이 명시적으로 거부의 의사 표시를 하지 않는 경우 개정약관에 동의한 것으로 봅니다.`)}} {{$t(`회원이 개정약관에 동의하지 않는 경우 당 사이트 이용계약을 해지할 수 있습니다.`)}}<br>
                    {{$t(`4. 본 약관에 명시되지 않은 사항은 「약관의 규제에 관한 법률」 등 관계법령의 규정이 적용됩니다.`)}}</div>
                    <br>
                    <br>
            <h3>{{$t(`제2장 [이용계약의 체결]`)}}</h3>
                <br>
                <h5>{{$t(`제4조 [이용계약의 성립]`)}}</h5>
                    {{$t(`이용계약은 고객이 당 사이트가 제공하는 양식에 따라 회원가입 신청을 한 뒤, 당 사이트가 정한 약관에 「동의합니다」를 선택하고, 당 사이트가 이를 승낙함으로써 성립합니다.`)}}<br>
                    {{$t(`당 사이트는 고객의 회원가입을 위하여 이메일, 이름, 비밀번호 정보 외에도 회원에게 다양하고 유익한 정보를 제공하기 위하여 이용신청자에게 메일수신여부를 요청할 수 있습니다.`)}} {{$t(`이는 당 사이트의 개인정보 처리방침에 따라 사용됩니다.`)}} {{$t(`다만, 신청자는 그러한 정보의 제공을 거절할 수 있습니다.`)}}<br>
                    <br>
                <h5>{{$t(`제5조 [회원가입]`)}}</h5>
                    {{$t(`당 사이트가 제공하는 서비스를 이용하고자 하는 고객은 당 사이트가 제공하는 가입신청양식에 따라 가입신청을 해야 합니다.`)}} {{$t(`가입신청 시 필요한 정보는 사실대로 기재하여야 합니다.`)}}<br>
                    <br>    
                <h5>{{$t(`제6조 [개인정보의 보호 및 사용]`)}}</h5>
                    {{$t(`당 사이트는 관계법령이 정하는 바에 따라 회원 등록정보를 포함한 회원의 개인정보를 보호하기 위해 노력합니다.`)}}<br>
                    {{$t(`회원의 개인정보는 개인정보보호법에 의해 보호되며, 당 사이트의 개인정보 처리방침이 적용됩니다.`)}} {{$t(`다만, 당 사이트 이외에 링크된 사이트에 대해서는 당 사이트의 개인정보 보호정책이 적용되지 않습니다.`)}}<br>
                    <br>
                <h5>{{$t(`제7조 [이용 신청의 승낙과 제한]`)}}</h5>
                    {{$t(`당 사이트는 제5조의 규정에 의한 화원가입 고객에 대하여 약관에 정하는 바에 따라 서비스 이용을 승낙합니다.`)}}<br>
                    {{$t(`당 사이트는 아래사항에 해당하는 경우에 대해서 회원가입을 승낙하지 아니하거나 이후 사전 통보 없이 취소할 수 있습니다.`)}}<br>
                    <div class="terms-of-use-details">
                    1.	{{$t(`실명이 아니거나 타인의 명의를 이용한 경우`)}}<br>
                    2.	{{$t(`허위의 정보를 기재하거나, 당 사이트가 제시하는 내용을 기재하지 않은 경우`)}}<br>
                    3.	{{$t(`가입신청자의 귀책사유로 인하여 승인이 불가능한 경우`)}}<br>
                    4.	{{$t(`당 사이트로부터 자격정지 조치 등을 받은 회원이 그 조치 기간 중에 이용계약을 임의 해지하고 재이용 신청을 하는 경우`)}}<br>
                    5.	{{$t(`기타 본 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 당 사이트의 합리적인 판단에 의하여 필요하다고 인정하는 경우`)}}<br>
                    6.	{{$t(`다른 사람의 당 사이트 이용을 방해하거나 그 정보를 도용하는 등의 행위를 하였을 경우`)}}<br>
                    7.	{{$t(`당 사이트를 이용하여 법령과 본 약관이 금지하는 행위를 하는 경우`)}}</div>
                <br>
                <br>        
            <h3>{{$t(`제3장 [서비스 이용]`)}}</h3>
                <br>
                <h5>{{$t(`제8조 [서비스 이용 시간]`)}}</h5>
                    <div class="terms-of-use-details">
                    {{$t(`1. 회원의 이용신청을 승낙한 때부터 서비스를 개시합니다.`)}} {{$t(`단, 일부 서비스의 경우에는 지정된 일자부터 서비스를 개시합니다.`)}}<br>
                    {{$t(`2. 업무상 또는 기술상의 장애로 인하여 서비스를 개시하지 못하는 경우에는 사이트에 공시하거나 회원에게 이를 통지합니다.`)}}<br>
                    {{$t(`3. 서비스의 이용은 연중무휴, 1일 24시간을 원칙으로 하며, 서비스 응대 및 처리 시간은 법정 근무일 근무시간(09:00~18:00, 법정공휴일 및 주말 제외)으로 합니다.`)}} {{$t(`다만, 당 사이트의 업무상 또는 기술상의 이유로 서비스가 일시 중지될 수 있습니다.`)}} {{$t(`이러한 경우 당 사이트는 사전 또는 사후에 이를 공지합니다.`)}}<br>
                    {{$t(`4. 회원으로 가입한 이후라도 일부 서비스 이용 시 특정회원에게만 서비스를 제공할 수 있습니다.`)}}</div>
                    <br>
                <h5>{{$t(`제9조 [서비스 저작권]`)}}</h5>
                    <div class="terms-of-use-details">
                    {{$t(`1. 당 사이트가 게시한 모든 콘텐츠에 대한 저작권은 당 사이트에 있습니다.`)}} {{$t(`다만, 게시물의 원저작자가 별도로 있는 경우 그 출처를 명시하며 해당 게시물의 저작권은 원저작자에게 있습니다.`)}}<br>
                    {{$t(`2. 회원이 직접 게시한 저작물의 저작권은 회원에게 있습니다.`)}} {{$t(`다만, 회원은 당 사이트에 무료로 이용할 수 있는 권리를 허락한 것으로 봅니다.`)}}<br>
                    {{$t(`3. 당 사이트 소유의 콘텐츠에 대하여 제3자가 허락 없이 다른 홈페이지에 사용 또는 인용하는 것을 금지합니다.`)}}</div>
                    <br>
                <h5>{{$t(`제10조 [서비스의 중지 및 정보의 저장과 사용]`)}}</h5>
                    <div class="terms-of-use-details">
                    {{$t(`1. 당 사이트는 기술상•운영상의 필요에 의해 제공하는 서비스의 일부 또는 전부를 변경하거나 중단할 수 있습니다.`)}} {{$t(`당 사이트의 서비스를 일시적으로 중단하는 경우에는 7일 전에 당 사이트 또는 이메일을 통해 이를 공지하되, 다만 사전에 통지할 수 없는 부득이한 사정이 있는 경우는 사후에 통지를 할 수 있습니다.`)}}<br>
                    {{$t(`2. 제1항의 경우에 당 사이트가 제공하는 서비스의 이용과 관련하여, 당 사이트는 이용자에게 발생한 어떠한 손해에 대해서도 책임을 지지 않습니다.`)}} {{$t(`다만 당 사이트의 고의 또는 중대한 과실로 인하여 발생한 손해의 경우는 제외합니다.`)}}<br>
                    {{$t(`3. 당 사이트에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 국가의 비상사태, 정전, 당 사이트의 관리 범위 외의 서비스 설비 장애 및 기타 불가항력에 의하여 보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실이 있을 경우에 당 사이트는 관련 책임을 부담하지 아니합니다.`)}}<br>
                    {{$t(`4. 당 사이트가 정상적인 서비스 제공의 어려움으로 인하여 서비스 영구히 중지하여야 할 경우에는 서비스 중지 30일 전의 고지 후 서비스를 중지할 수 있으며, 이 기간 동안 이용자가 고지내용을 인지하지 못한 데 대하여 당 사이트는 책임을 부담하지 아니합니다.`)}} {{$t(`부득이한 사정이 있을 경우 위 사전 고지기간은 감축되거나 생략될 수 있습니다.`)}} 
                    {{$t(`또한 위 서비스 중지에 의하여 본 서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 보관되지 못하였거나 삭제, 전송되지 못한 경우 및 기타 통신 데이터의 손실이 있을 경우에 대하여도 당 사이트는 책임을 부담하지 아니합니다.`)}}<br>
                    {{$t(`5. 당 사이트는 사전 고지 후 서비스를 일시적으로 수정, 변경 및 중단할 수 있으며, 이에 대하여 이용자 또는 제3자에게 어떠한 책임도 부담하지 아니합니다.`)}}<br>
                    {{$t(`6. 장기간 휴면 이용자인 경우 안내 메일 또는 공지사항 발표 후 1주일간의 통지 기간을 거쳐 서비스 사용을 중지할 수 있습니다.`)}}</div>
                    <br>
                <h5>{{$t(`제11조 [정보 제공 및 홍보물 게재]`)}}</h5>
                    <div class="terms-of-use-details">
                    {{$t(`1. 당 사이트는 서비스를 운영함에 있어서 각종 정보를 당 사이트에 게재하는 방법 등을 통해 회원에게 제공할 수 있습니다.`)}}<br>
                    {{$t(`2. 당 사이트는 서비스에 적절하다고 판단하거나 활용 가능성 있는 홍보물을 게재할 수 있습니다.`)}}</div>
                    <br>
                <h5>{{$t(`제12조 [서비스 이용계약의 종료]`)}}</h5>
                    <div class="terms-of-use-details">
                    {{$t(`1. 회원은 언제든지 내정보관리 메뉴 등을 통하여 이용계약 해지 신청을 할 수 있으며, 당 사이트는 최대한 빠른 시일 내에 이를 처리하여야 합니다.`)}}<br>
                    {{$t(`2. 회원이 계약을 해지할 경우, 관련법령 및 개인정보 처리방침에 따라 당 사이트가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 모든 데이터가 소멸됩니다.`)}} <br>
                    {{$t(`3. 회원이 계약을 해지하는 경우, 회원이 작성한 게시물 등은 삭제되지 않습니다.`)}} </div>
                    <br>
                    <br>
            <h3>{{$t(`제4장 [의무 및 책임]`)}}</h3>
                <br>
                <h5>{{$t(`제13조 [당 사이트의 의무]`)}}</h5>
                    <div class="terms-of-use-details">
                    {{$t(`1. 당 사이트는 지속적이고 안정적으로 서비스를 제공하기 위해 노력할 의무가 있습니다.`)}}<br>
                    {{$t(`2. 당 사이트는 회원의 개인 신상 정보를 본인의 승낙 없이 타인에게 누설, 배포하지 않습니다.`)}} {{$t(`다만, 전기통신관련법령 등 관계법령에 의하여 관계 국가기관 등의 요구가 있는 경우에는 그러하지 아니합니다.`)}}<br>
                    {{$t(`3. 당 사이트는 회원으로부터 제기되는 의견이 합당하다고 판단될 경우에는, 적절한 조치를 취하여야 합니다.`)}}<br>
                    {{$t(`4. 당 사이트는 이용자의 귀책사유로 인한 서비스 이용 장애에 대하여 책임을 지지 않습니다.`)}}</div>
                    <br>
                <h5>{{$t(`제14조 [이용자의 의무]`)}}</h5>
                    {{$t(`회원은 당 사이트의 서비스를 이용하여 얻은 정보를 각 사이트의 사전승낙 없이 복사, 복제, 변경, 번역, 출 판·방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.`)}} {{$t(`단, 개별사이트에서 별도로 정하는 경우에는 예외로 합니다.`)}}<br>
                    {{$t(`이용자는 당 사이트 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안 됩니다.`)}}<br>
                    <div class="terms-of-use-details">
                    1.	{{$t(`다른 회원의 ID를 부정 사용하는 행위`)}}<br>
                    2.	{{$t(`범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위`)}}<br>
                    3.	{{$t(`타인의 명예를 훼손하거나 모욕하는 행위`)}}<br>
                    4.	{{$t(`타인의 지적재산권 등의 권리를 침해하는 행위`)}}<br>
                    5.	{{$t(`해킹행위 또는 컴퓨터바이러스의 유포행위`)}}<br>
                    6.	{{$t(`서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위`)}}<br>
                    7.	{{$t(`기타 전기통신법 제53조와 전기통신사업법 시행령 16조(불온통신), 통신사업법 제53조 3항에 위배되는 행위`)}}</div>
                    <br>
                    <br>

            <h3>{{$t(`제5장 [기타]`)}}</h3>
                <br>
                <h5>{{$t(`제15조 [당 사이트의 소유권]`)}}</h5>
                    {{$t(`당 사이트가 제공하는 서비스, 그에 필요한 소프트웨어, 이미지, 마크, 로고, 디자인, 서비스 명칭, 정보 및 상표 등과 관련된 지적재산권 및 기타 권리는 당 사이트에 소유권이 있습니다.`)}}<br>
                    {{$t(`이용자는 명시적으로 승인받거나 개별 사이트에서 별도로 정하는 경우를 제외하고는 제1항의 소정의 각 재산에 대한 전부 또는 일부의 수정, 대여, 대출, 판매, 배포, 제작, 양도, 재라이센스, 담보권 설정 행위, 상업적 이용 행위를 할 수 없으며, 제3자로 하여금 이와 같은 행위를 하도록 허락할 수 없습니다.`)}}<br>
                    <br>
                <h5>{{$t(`제16조 [손해배상]`)}}</h5>
                    {{$t(`당 사이트는 무료로 제공되는 서비스와 관련하여 이용자에게 어떠한 손해가 발생하더라도 당 사이트가 고의로 행한 범죄행위를 제외하고 이에 대하여 책임을 부담하지 아니합니다.`)}}<br>
                    <br>
                <h5>{{$t(`제17조 [관할법원]`)}}</h5>
                    {{$t(`서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 민사 소송법상의 관할 법원에 제기합니다.`)}}<br>
                    <br>
                <h5>{{$t(`부칙`)}}</h5>
                    {{$t(`[시행일] 본 약관은 2020년 4월 10일부터 시행됩니다.`)}}<br>
                    {{$t(`개정된 약관의 적용일자 이전 이용자 또는 회원은 개정된 이용약관의 적용을 받습니다.`)}}<br>

        </div>
    </b-card>

  </div>
</template>

<script>
export default {

}
</script>

<style>
.terms-of-use-text{
    padding: 2rem;
    line-height: 2;
}
.terms-of-use-text >h3 {
    font-weight: 600;
}
.terms-of-use-details {
    padding: 0.75rem 0 0.75rem 1.5rem;
}
</style>