<template>
<div :class="audit_mode!=true&&previous!=true ? 'mb-5':'gray-600 font-scale-sm'">
    <div v-show="is_visible_by_pipeline==true" :class="previous==true ? 'p-3 gray-500 rounded border bgray-100':''">

        <div v-if="headers">
            <!-- <template v-if="$i18n.locale=='ko'"> -->
                <div :class="[ sub_name != '' ? 'fw-500 f-110 border-bottom mb-2':'', '' ]"> <span v-if="sub_data_header.required && sub_data_header.required==true">*</span>{{ byLang(sub_name,sub_name_e, sub_name_c) }} </div>
                <div :class="[ sub_description != '' ? 'mb-3': '', 'mb-1']">
                    <div v-if="is_indicate_description" class="mr-1 fw-500 d-inline px-0">
                        <span v-if="sub_name === '' && sub_data_header.required && sub_data_header.required === true">*</span>
                        {{ byLang(sub_description,sub_description_e, sub_description_c) }}
                    </div>
                    <span v-for="item in tags" :key="item.id"> <span v-if="item != ''" class="ml-2 f-80 gray-500"> {{ item }} </span></span>
                    <!-- <b-button  v-if="sub_info != ''" class="ml-1 px-1 py-0 f-80" variant="teal" size="sm" :id="`i-${ sub_code }`" @click="toggleVisible" ><fa icon="book-open"></fa> {{byLang('작성 도움말', 'Info', '信息')}}</b-button> -->

                    <span v-if="sub_priority != ''"><b-badge :variant="badge_var" class="mx-2">{{ sub_priority }}</b-badge></span>
                    <b-button v-if="previous_sub.sub_code!=undefined && previous!=true" v-b-toggle="previous_sub.sub_code" size="sm" variant="red" class="py-0">{{ $t('과거 답변 보기') }}<fa icon="history" class="ml-2"></fa></b-button>

                    <!-- sub_info 정보 -->
                    <!-- <div v-if="is_visible" :id="`sub_info-${ sub_code }`" class="mt-2 mb-3 px-1 blue f-90"> <fa icon="info-circle" class="mr-1"></fa><span v-html="sub_info"></span></div> -->
                    <b-card v-if="sub_info != ''" :id="`sub_info-${ sub_code }`" class="mt-2 mb-3 border-0 teal8 bg-secondary2 f-90"> <fa icon="info-circle" class="mr-1"></fa><span v-html="sub_info"></span></b-card>

                    <!-- sub_condition -->
                    <div v-if="is_sub_condition_activated==true" :id="`sub_condition-${sub_code}`" class="my-2 py-2">
                        <b-form-checkbox :disabled="is_locked || is_disabled_by_parent" v-model="sub_condition.value" @change="radioSave()"> {{ byLang(sub_condition.text, sub_condition.text_e, sub_condition.text_c) }} </b-form-checkbox>
                    </div>
                </div>
            <!-- </template> -->            
        </div>


        <!-- previous component -->
        <b-collapse v-if="print_mode!=true" :id="previous_sub.sub_code" class="mb-5">
            <sub-component :disabled="true" v-if="previous_sub.sub_code != undefined && previous != true" :is_disabled_by_parent="true" :sub_type="previous_sub.sub_type" :item="previous_sub" :previous="true"></sub-component>
        </b-collapse>

        <b-overlay :show="is_visible_by_pipeline==false" rounded="sm" opacity="0.6" bg-color="#c8c8c8">

            <!-- file_URL2 유형 Sub Component -->
            <div :class="[audit_mode!=true ? 'border-0' : 'border-0 bg-transparent','d-md-flex align-items-start']">
                <b-button :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent" v-b-modal="`file_URL2-${sub_code}`" class="mr-0 mr-md-4 my-2 f-95" 
                :size="audit_mode!=true ?'lg':''" :variant="audit_mode!=true ? 'dark-border':''" style="min-width: 170px;"> {{$t(`파일 및 URL 첨부`)}} 
                <fa icon="cloud-upload-alt" class="ml-1"></fa>
                </b-button>
                <div v-if="sub_data_contents.result&&sub_data_contents.result.length>0" class="w-100">
                    <p class="py-2 mb-0">  {{$t(`업로드된 파일/URL`)}}</p>
                    <b-table-simple  hover class="txt-table d-block-helper w-100" style=" overflow-x:auto;">
                        <b-tbody>
                            <b-tr v-for="(row, index) in sub_data_contents.result" :key="row.id" class="bgray-100">
                                <b-td class="text-left px-2" style="width:50px;">{{ index+1 }}. 
                                    <!-- <b-badge variant="basic" class="mx-2"> {{ row.type }}</b-badge> -->
                                </b-td>
                                <b-td class="text-left px-3 f-90 d-flex align-items-center justify-content-between"> 
                                    <div v-if="isIE==true">
                                        <a v-if="row.type=='file'" href="#" @click="handleIEDownload(row)"> {{ row.filename }} </a>
                                        <a v-else :href="row.file_link" target="blink"> {{ row.filename }}</a>
                                    </div>
                                    <div v-else>
                                        <a v-if="row.type=='file'" :href="row.file_link" :download="row.filename" :ref="`file_${sub_code}_${index}`"> {{ row.filename }} </a>
                                        <a v-else :href="row.file_link" target="blink"> {{ row.filename }}</a>
                                    </div>
                                    <b-button :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent" variant="dark-border" size="sm" class="ml-3" @click="deleteFileOrURL(row, index)"><fa icon="trash-alt"></fa></b-button>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </div>
            </div>
            
            <!-- is_visible_by_pipeline 조건에 따른 해당없음 overlay -->
            <template v-slot:overlay>
                <div class="text-center ">
                    {{$t(`해당사항 없음`)}} <b-button variant="icon" v-b-tooltip.hover :title="pipeline_title"><fa icon="info-circle"></fa></b-button>
                </div>
            </template>
        </b-overlay>
    </div>

    
        <b-modal @hide="handleHide" size="lg" :ref="`file_URL2-${sub_code}`" :id="`file_URL2-${sub_code}`" :title="file_modal_title" hide-footer hide-header-close>
            <b-container v-if="saving_files==false" class="px-0 f-90">
                <b-row class="pb-3">                    
                    <b-col class="col-6">
                        <fa icon="paperclip" class="mr-1"></fa> File
                        <b-form-file
                            @input="addFileToSubDataContents(file_temp)"
                            v-model="file_temp"
                            :placeholder="form_placeholder"
                            :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent"
                            class="text-left"
                        ></b-form-file>
                    </b-col>
                    <b-col class="col-6">
                        <fa icon="link" class="mr-1"></fa>URL
                        <b-input-group>
                            <b-form-input :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent" @keyup.enter="addURLToSubDataContents(url_temp)" v-model="url_temp"></b-form-input>
                            <b-input-group-append><b-button @click="addURLToSubDataContents(url_temp)" variant="icon-append"><fa icon="save"></fa></b-button></b-input-group-append>               
                        </b-input-group>
                    </b-col>
                </b-row>
                
                <table v-if="sub_data_contents.result != []" hover class="w-100 border-top">
                    <tr v-for="(row, index) in sub_data_contents.result" :key="row.id" class="w-100 border-bottom">
                        <td class="bgray-200" style="width:40px;">{{ row.type }}</td>
                        <td class="bg-white text-left"> 
                            <div v-if="isIE==true">
                                <span> {{ row.filename }} </span>
                                <!--<a v-if="row.type=='file'" href="#" @click="handleIEDownload(row)"> {{ row.filename }} </a>
                                <a v-else :href="row.file_link" target="blink"> {{ row.filename }}</a>-->
                            </div>
                            <div v-else>
                                <span> {{ row.filename }} </span>
                                <!--<a v-if="row.type=='file'" :href="row.file_link" :download="row.filename" :ref="`file_${sub_code}_${index}`"> {{ row.filename }} </a>
                                <a v-else :href="row.file_link" target="blink"> {{ row.filename }}</a>-->
                            </div>
                        </td>
                        <td class="bg-white px-0" style="width:30px;"><b-button variant="icon" @click="deleteFileOrURL(row, index)"><fa icon="trash-alt"></fa></b-button></td>
                    </tr>
                </table>
        
                <b-button @click="handle_file_url2_ok(`file_URL2-${sub_code}`)" class="float-right mt-3" variant="blue">{{$t(`완료`)}}</b-button>
                <b-button @click="hideModal(`file_URL2-${sub_code}`)" class="float-right mt-3 mx-2" variant="dark">취소</b-button>

            </b-container>
            <b-container v-else>
                <div class="py-5">
                    <span> {{$t(`파일을 서버에 업로드 중입니다. 잠시 기다려 주세요...`)}} </span> <b-spinner small></b-spinner>
                </div>

                <b-button @click="hideModal(`file_URL2-${sub_code}`)" class="float-right mt-3" variant="blue">{{$t(`취소`)}}</b-button>
            </b-container>
        </b-modal>
</div>
</template>
<script>
import { SubComponentMixin } from './SubComponentMixin'

export default {
    mixins: [SubComponentMixin],
    data(){
        return{
            saving_files: false,
            file_modal_title: this.$t(`파일 업로드 및 URL`),
            form_placeholder: this.$t(`여기로 끌어오거나, 클릭하여 파일을 찾으세요.`),
            button_title: this.$t(`평가정보`),
            pipeline_title: this.$t(`이 문항은 답변할 필요가 없습니다.`)
        }
    },
    computed:{
        isIE() {
            if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (navigator.userAgent.toLowerCase().indexOf("msie") != -1) )
                {
                    return true
                }
            else
                { 
                    return false
                }
        }
    },
    methods: {
        byLang(ko, en, cn){
            if(this.$i18n.locale=='en') return en
            else if (this.$i18n.locale=='cn') return cn
            else return ko
        },
        handleHide(bvModalEvent){
            if(bvModalEvent.trigger == 'esc' || bvModalEvent.trigger == 'backdrop' || bvModalEvent.trigger == 'headerclose') bvModalEvent.preventDefault()
        },
        hideModal(ref){
            this.$refs[ref].hide()
        },
        handle_file_url2_ok(ref){
             this.saving_files = true
             this.fileURL2DbSave()
            .then( () => {
                if (this.files != []) {
                    this.saving_files = true
                    this.getFilesFromServer() //현재 폼에 입력된 파일이 존재하는 경우, 서버에 파일을 저장하고 이를 다시 읽어 옴
                    .then( () => {
                        this.saving_files = false
                        this.hideModal(ref) 
                    })
                    .catch(error => {
                        this.saving_files = false
                        console.log(error)
                        window.alert(this.$t(`파일이 저장되지 않았습니다. 삭제 후 다시 시도해 주세요:`) + error)
                        this.hideModal(ref)
                    })
                }
                else{
                    console.log('그냥 닫혔음!!!')
                    this.saving_files = false
                    this.hideModal(ref)
                }
            })
        },
        fileURL2DbSave(){
                const path = this.$store.state.backend_host + '/save_sub_file_url2'

                const formData = new FormData()
                formData.append('response_code', this.$store.state.response_code)
                formData.append('mode', this.$store.state.mode)
                formData.append('company_id', this.$store.state.company_id)
                formData.append('campaign_id', this.campaign_in_progress.campaign_id)
                formData.append('question_code', this.question_code)
                formData.append('sub_code',this.sub_code)
                formData.append('sub_name',this.sub_name)
                formData.append('sub_description', this.sub_description)
                formData.append('sub_info', this.sub_info)
                formData.append('sub_condition', JSON.stringify(this.sub_condition))
                formData.append('sub_type',this.sub_type)
                formData.append('sub_data_header',JSON.stringify(this.sub_data_header))
                formData.append('sub_data_contents',JSON.stringify(this.sub_data_contents))
                formData.append('sub_priority',this.sub_priority)
                formData.append('sub_parent', this.sub_parent)
                formData.append('sub_child', JSON.stringify(this.sub_child))
                formData.append('score_result', this.score_result)

                //파일이 존재하는 경우 전처리
                if(this.files != []) {
                    for(var i=0; i<this.files.length; i++)
                    {
                        formData.append('files',this.files[i])
                    }
                }

                return axios.post(path, formData, {
                    headers: {
                    'Content-Type' : 'multipart/form-data'
                    }
                })
                .then(response => {
                    this.result = response.data
                    this.save_state = 'resaved'
                    this.files = []
                })
                .catch(error => {
                    console.log(error)
                    if (error.response.status == '401')
                    {
                        alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                        this.$store.dispatch('logout').then(() => { this.$router.push({name:'Login'})} )
                    }
                })
        },
        async deleteFileOrURL(item, index){
            //1. DB 및 서버에서 지운다. 
            const path = this.$store.state.backend_host + '/delete_file'

            let company_name = ''
            if (this.view_mode=='provider') {
                company_name = this.$store.getters.getCompanyName 
            } else company_name = this.$store.state.questions.answer_company.company_name

            let campaign_id = ''
            if (this.view_mode=='provider'){
                campaign_id = this.campaign_in_progress.campaign_id
            } else {campaign_id = this.$store.state.campaign.campaign_id }

            let formData = new FormData()
            formData.append('requested_file', item.filename)
            formData.append('mode', this.$store.state.mode)
            formData.append('company_id', this.$store.state.company_id)
            formData.append('response_code', this.$store.state.response_code)
            formData.append('campaign_id', this.campaign_in_progress.campaign_id)
            formData.append('sub_code', this.sub_code)

            console.log('requested_file', item.filename)

            //DB 및 서버로 신호 보냄
            let response = await axios.post(path, formData)
            console.log('파일 지움')
            console.log(response)

            let new_sub_data_contents = { ...this.sub_data_contents }
            new_sub_data_contents.result.splice(index, 1)

            let idx = this.question_subs.findIndex(x => { return x.sub_code == this.sub_code }) //provider_questions에 소속된 sub임
            if(idx > -1)
            {   
                this.updateStore(new_sub_data_contents, this.sub_condition)
            }
            else{
                //sub-child에서 온 아이임 
                this.$emit('update-parent-contents', new_sub_data_contents, this.sub_code)
            }

            let idx2 = this.files.findIndex(y => { return y.filename == item.filename })
            if(idx2 > -1){
                this.files.splice(idx2, 1)
            }

            //2. 지웠음을 저장
            this.fileURL2DbSave()

        },
        handleIEDownload(file){
            window.navigator.msSaveOrOpenBlob(file.file_link, file.filename);
            //console.log(file)
            //console.log('눌렀다')
        }
    }
}
</script>

<style scoped>

</style>
