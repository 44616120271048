<template>
<div>
    <b-container fluid class="px-0 mx-0">
    <b-row>
        <b-col class="mt-3">
            <!--<editor-menu-bar :editor="editor" v-slot="{ commands }">
            <div class="menubar">
                <b-button size="sm" class="menubar_button" @click="commands.mention({ id: 99, label: '퀀티파이드이에스지' })">
                <span>Insert Mention</span>
                </b-button>
            </div>
            </editor-menu-bar>-->
            <div v-if="!is_IE" class="px-2 py-1 box-sm-shadow border-blue" style="min-height:60px;">    
                <editor-content :id="`comment_editor_test`" ref="editor_content" class="comment_editor comment_editor_content text-left" :editor="comment_editor" placeholder="@로 수신인 지정"></editor-content>
            </div>
            <!-- for IE -->
            <div v-else>
                <b-textarea class="box-sm-shadow" v-model="IE_comment_contents" placeholder="@로 받는사람을 지정하여 메모를 남길 수 있습니다."></b-textarea>
            </div>
        </b-col>
    </b-row>
    <b-row>
          <b-col>
            <b-button v-if="!is_IE" @keyup.enter.stop.prevent="saveComment(e_mail, question_code, new_comment_contents, 'new', new_comment_admin_only)" @click.stop.prevent="saveComment(e_mail, question_code, new_comment_contents, 'new', new_comment_admin_only)" class="float-right" variant="blue" size="sm">등록</b-button>
            <b-button v-else-if="is_IE" @keyup.enter.stop.prevent="IESaveComment(e_mail, question_code, IE_comment_contents, 'new', new_comment_admin_only)" @click.stop.prevent="IESaveComment(e_mail, question_code, IE_comment_contents, 'new', new_comment_admin_only)" class="float-right" variant="blue" size="sm">등록</b-button> 
          </b-col>
    </b-row>
    <div v-show="validate_message" id="validate-message" class="text-left red f-90"><fa icon="info-circle" class="mr-1"></fa> {{ validate_message }} </div>
    </b-container>


    <div class="suggestion-list" v-show="showSuggestions" ref="suggestions">
      <template v-if="hasResults">
        <div
          v-for="(user, index) in filteredUsers"
          :key="user.id"
          class="suggestion-list_item"
          :class="{ 'is-selected': navigatedUserIndex === index }"
          @click="selectUser(user)"
        >
          <span>{{ user.face }}</span><span v-if="user.e_mail != undefined">({{user.e_mail}})</span>
        </div>
      </template>
      <div v-else class="suggestion-list_item is-empty">
        유저가 없습니다
      </div>
    </div>

</div>
</template>
<script>
import Fuse from 'fuse.js'
import tippy, { roundArrow }  from 'tippy.js'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import 'tippy.js/animations/shift-away.css'
import '../../../assets/tiptap_editor.scss'
import * as hangul from 'hangul-js'

import {
  HardBreak,
  Heading,
  Mention,
  Code,
  Bold,
  Italic,
} from 'tiptap-extensions'

export default {
    data(){
        return {
            new_comment_admin_only: false,
            comment_code: '',
            validate_message: '',

            tiptap_mention: '',
            comment_editor: '',

            query: null, 
            suggestionRange: null,
            filteredUsers: [],
            navigatedUserIndex: 0,
            insertMention: () => {},
            observer: null,
            tippy_popup: null,

            //for IE
            IE_comment_contents: ''
        }
    },
    computed: {
        user_name() {
            return this.$store.getters.getUserName
        },
        company_name() {
            return this.$store.getters.getCompanyName
        },
        e_mail(){
            return this.$store.getters.getEMail
        },
        user_id(){
            return this.$store.state.user_id
        },
        isAuthenticated(){
            return this.$store.getters.isAuthenticated
        },
        isLoggedIn() {
            return this.$store.getters.isLoggedIn
        },
        is_admin(){
            return this.$store.getters.isAdmin
        },
        question_in_progress() {
            return this.$store.state.question_in_progress
        },
        question_code(){
            return this.$store.state.question_in_progress.question_code
        },
        user_space() {
            return this.$store.getters.getUserSpace
        },
        all_space() {
            return this.$store.getters.getAllSpace
        },
        hasResults() {
            return this.filteredUsers.length
        },
        showSuggestions() {
            return this.query || this.hasResults
        },
        new_comment_contents() {
            return this.comment_editor.getJSON()
        },
        is_IE() {
            let agent = navigator.userAgent.toLowerCase();
            if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (agent.indexOf("msie") != -1) )
                {
                    return true
                }
            else
                { 
                    return false
                }
        }
    },
    methods: {
        //윗 유저로 이동
        upHandler() {
            this.navigatedUserIndex = ((this.navigatedUserIndex + this.filteredUsers.length) - 1) % this.filteredUsers.length
        },

        //다음 유저로 이동. 마지막 유저일 경우 처음으로 이동
        downHandler() {
            this.navigatedUserIndex = (this.navigatedUserIndex + 1) % this.filteredUsers.length
        },

        //엔터 키 쳤을 경우
        enterHandler() {
            const user = this.filteredUsers[this.navigatedUserIndex]
            if (user) {
                this.selectUser(user)
            }
        }, 

        //우리가 추천한 것을 멘션을 포함한 텍스트로 변동시켜야 함
        selectUser(user){
            this.insertMention({
                range: this.suggestionRange,
                attrs: {
                    id: user.id,
                    label: user.face
                }
            })
            this.comment_editor.focus()
        },

        //suggestion popup
        renderPopup(node){
            if(this.tippy_popup) {
                return
            }

            //console.log(node)

            this.tippy_popup = tippy('#comment_editor_test', {                
                lazy:false, 
                onTrigger(instance) {
                    instance.popperInstance.reference = node
                },
                content: this.$refs.suggestions,
                trigger: 'enter',
                interactive: true,
                theme: 'dark',
                placement: 'bottom',
                inertia: true,
                duration: 500,
                showOnCreate: true,
                arrow: true,
                animation: 'shift-away',
                popperOptions: {
                    positionFixed: true,
                },
            })

            //console.log(this.tippy_popup)

            //DOM 변경될 때마다 tippy를 업데이트
            if(MutationObserver) {
                this.observer = new MutationObserver( ()=> {
                    this.tippy_popup[0].popperInstance.scheduleUpdate()
                })
                this.observer.observe(this.$refs.suggestions, {
                    childList: true,
                    subtree: true,
                    characterData: true
                })
            }
        },
        destroyPopup(){
            if(this.tippy_popup){
                this.tippy_popup[0].destroy()
                this.tippy_popup = null
            }

            if (this.observer){
                this.observer.disconnect()
            }
        },
        prepareSuggestions(){
            //console.log(this.all_space)
            return this.all_space
        },
        saveComment(e_mail, question_code, new_comment_contents, comment_code, admin_only){

            if(comment_code == 'new') comment_code = 'c' + Math.floor(Math.random() * 1000000000)      
            let comment_html = this.comment_editor.getHTML()
            let treated_comment = comment_html.replace(/(<([^>]+)>)/g,"").replace(/ /g,"")
            if(treated_comment == ''){
                this.validate_message = '내용을 입력해주세요'
                return false
            }
            else {
                //부모로 이벤트 전달
                this.validate_message = ''
                EventBus.$emit('save-comment', e_mail, question_code, new_comment_contents, comment_code, admin_only)
                this.comment_editor.setContent('')
            }
        },
        IESaveComment(e_mail, question_code, IE_new_comment_contents, comment_code, admin_only){
            //'@'으로 시작하는 문구가 있는지 조사. 정규표현식의 의미는, @으로 시작하고 단어가 끝나는 곳 까지 매치되는 문자열을 배열로 저장
            let mentions_list =  IE_new_comment_contents.match(/(@[a-z가-힣0-9]*)/gim) 
            let matched_list = []

            //null 에러방지
            if(mentions_list == null) mentions_list=[]
            
            this.all_space.forEach(space_item => {
                mentions_list.forEach(mention_item => {
                    if(mention_item.match(space_item.face)){
                        matched_list.push(space_item)
                    }
                })
            })

            //이제 matched_list는 all_space의 하위타입 배열로서, comment에 삽입되어야 할 mention 아이템을 의미함. 
            //contents에서 mention 아이템을 교체해서 심어줌. 
            matched_list.forEach(item => {
                let leg_exp = new RegExp('(?!>)@' + item.face + '(?!<)')
                IE_new_comment_contents = IE_new_comment_contents.replace(leg_exp, `<span class="mention" data-mention-id='${item.id}' mode='${item.mode}'>@${item.face}</span>`)
            })

            // 줄바꿈 문자를 <br>로 치환하고, 앞 뒤로 <p> 태그 붙임
            IE_new_comment_contents = IE_new_comment_contents.replace(/\r\n/g,'<br>')
            IE_new_comment_contents = '<p>'+IE_new_comment_contents + '</p>'

            this.comment_editor.setContent(IE_new_comment_contents)
            this.saveComment(e_mail, question_code, this.comment_editor.getJSON(), comment_code, admin_only)
            this.IE_comment_contents=''
        }
    },
    created() {
        this.tiptap_mention = new Mention({
                        //a list of all suggested items
                        items: () => {
                            return this.prepareSuggestions()
                        },
                        //is called when a suggestion starts

                        onEnter: ({
                            items, query, range, command, virtualNode, 
                        }) => {
                            this.query = query
                            this.filteredUsers = items
                            this.suggestionRange = range
                            this.renderPopup(virtualNode)
                            // 선택한 맨션을 추가하는 명령을 저장
                            // 키보드, 마우스 액션을 통해 커스텀 팝업에서 불러옴
                            this.insertMention = command
                        },
                        // suggestion이 바뀌었을 때 불러오기
                        onChange: ({
                            items, query, range, virtualNode, text
                        }) => {
                          
                            this.query = query
                            this.filteredUsers = items
                            this.suggestionRange = range
                            this.navigatedUserIndex = 0
                            this.renderPopup(virtualNode)
                        },
                        // suggestion이 캔슬되었을 때
                        onExit: () => {
                            // 모든 저장된 값을 리셋함
                            this.query = null
                            this.filteredUsers = []
                            this.suggestionRange = null
                            this.navaigatedUserIndex = 0
                            this.destroyPopup()
                        },
                        // 키를 누를 때
                        onKeyDown: ({ event }) => {
                            //화살표 윗키
                            if(event.keyCode === 38) {
                                this.upHandler()
                                return true
                            }
                            //화살표 아래키
                            if(event.keyCode === 40) {
                                this.downHandler()
                                return true
                            }
                            //엔터키
                            if(event.keyCode === 13) {
                                this.enterHandler()
                                return true
                            }
                            return false
                        },
                        //suggestion이 변화가 생겼을 때 불러오는 함수
                        //fuse.js를 통해 fuzzy 서치를 진행
                        onFilter: (items, query) => {
                            if(!query) {
                                return items
                            }

                            const fuse = new Fuse(items, {
                                shouldSort: true,
                                threshold: 0.2,
                                location: 0,
                                distance: 100,
                                maxPatternLength: 32,
                                minMatchCharLength: 1,
                                keys: [
                                    "face", "e_mail"
                                ]
                            })

                            return fuse.search(query)
                        }
                    })
                    
        let extensions = [
                        new HardBreak(),
                        new Heading({ levels: [1, 2, 3]}),
                        new Code(),
                        new Bold(),
                        new Italic(),
                        this.tiptap_mention,
                        ]

        //let agent = navigator.userAgent.toLowerCase();
        //if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (agent.indexOf("msie") != -1) ) {
        //IE일 경우 mention 기능을 제공하지 않음
        //    extensions.pop()
        //}
        this.comment_editor = new Editor({
                    extensions: extensions,
                    content: '',
                    //onUpdate: ({ state, getHTML, getJSON, transaction }) => {
                        //console.log(transaction)
                        //for IE Fix
                        //let agent = navigator.userAgent.toLowerCase();
                        //if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (agent.indexOf("msie") != -1) ) {
                        //    if(new_string.content && new_string.content.length != 0)
                        //    {
                        //        for(let i=0; i<new_string.content.length; i++)
                        //        {
                        //            if(new_string.content[i].content && new_string.content[i].content.length !=0)
                        //            {
                        //               if(new_string.content[i].content[0].type=='text')
                        //                {
                        //                    if(new_string.content[i].content[0].text.length < 5)
                        //                    {
                        //                        new_string.content[i].content[0].text = hangul.a(hangul.d(new_string.content[i].content[0].text))
                        //                        this.comment_editor.setContent(new_string)    
                        //                    }
                        //                }
                        //            }
                        //        }
                        //    }
                        //}
                    //}
            })
    },
    beforeDestroy(){
        this.comment_editor.destroy();
    },
    components:{
        EditorContent,
        EditorMenuBar,
    }

}
</script>
