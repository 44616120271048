<template>
<div>
    <b-container class="pt-5">
        <b-row>
            <b-col class="col-3"></b-col> 
            <b-col> <hr class="border-top-0 border-bottom-dark p-0 m-0"></b-col>
        </b-row>
        <b-row>
            <b-col class="col-3 d-flex flex-column"> 
                <h4 class="mb-4 camp-position-title">기업 검색</h4><div :class="selected_company.company_name!=''||searched_keyword!=''?'camp-position-line':''"></div>
            </b-col>
            <b-col class="pt-4">
                <company-finder @company-not-found="handleNotFound" @company-search-keyword="searchedKeyword" :no_portfolio="no_portfolio" :newly_created="newly_created"
                :target_company_name="target_company_name" :before_search_keyword="$route.params.search_keyword"></company-finder>
                <hr :class="selected_company.company_name!=''||searched_keyword!=''?'border-top-0 border-bottom-dark pb-0 mb-0 pt-5':'border-0'">
            </b-col>
        </b-row>
        <b-row v-if="selected_company.company_name!=''||searched_keyword!=''">
            <b-col class="col-3 d-flex flex-column"> 
                <h4 class="mb-4 camp-position-title">등록된 정보</h4><div :class="is_editing_user!=true?'':'camp-position-line'"></div>
            </b-col>
            <b-col class="pt-4">                
                <b-row> 
                    <b-col :class="selected_company.company_name!=''&&selected_company.company_name!=searched_keyword?'col-6':''">
                        <h6 class="f-110 mb-3">기업 정보</h6>
                        <b-table-simple hover class="input-table f-90 overflow-auto">
                            <b-tbody>
                                <b-tr><b-td style="width:30%">회사명*</b-td><b-td> 
                                    <div v-if="is_editing_company!=true">{{ selected_company.company_name }}</div>
                                    <b-input v-else class="narrow-input-form f-90" type="text" v-model="selected_company.company_name" placeholder="(주),(유),(사)등을 제외한 회사명"></b-input> 
                                </b-td></b-tr>
                                <b-tr><b-td>사업자등록번호</b-td><b-td>
                                    <div v-if="is_editing_company!=true">{{ selected_company.company_code }}</div>
                                    <b-input v-else class="narrow-input-form f-90" type="text" v-model="selected_company.company_code" placeholder="000-00-00000"></b-input>
                                </b-td></b-tr>
                                <b-tr><b-td>전화번호</b-td><b-td>
                                    <div v-if="is_editing_company!=true">{{ selected_company.company_tel }}</div>
                                    <b-input v-else class="narrow-input-form f-90" type="text" v-model="selected_company.company_tel" placeholder="전화번호"></b-input>
                                </b-td></b-tr>
                                <b-tr><b-td>주소</b-td><b-td>
                                    <div v-if="is_editing_company!=true">{{ selected_company.company_address }}</div>
                                    <b-input v-else class="narrow-input-form f-90" type="text" v-model="selected_company.company_address" placeholder="본사 주소"></b-input>
                                </b-td></b-tr>
                                <b-tr><b-td>참여 캠페인</b-td><b-td>{{ temp_campaign_name }}</b-td></b-tr>
                            </b-tbody>
                        </b-table-simple>
                        <div class="text-right border-top" v-if="is_editing_company!=true">
                            <b-button size="sm" variant="dark-border" @click="editCompany"><fa icon="pen"></fa> </b-button>
                        </div>
                        <div class="text-right mr-2" v-else-if="is_editing_company==true&&is_editing_user!=true">
                            <b-button size="sm" variant="blue" @click="saveCompany()"><fa icon="check" class="mr-1"></fa> 등록하기</b-button>
                            <b-button v-if="selected_company.company_name!=''" size="sm" variant="dark" @click="is_editing_company=false" class="ml-2"> 취소 </b-button>
                        </div>
                    </b-col>
                    <b-col v-if="selected_company.company_name!=''&&company_not_found!=true" class="">
                        <h6 class="f-110 mb-3">사용자 정보</h6>
                        <b-card v-if="selected_company.all_users.length>0" class="bgray-200 border-0" style="min-height: calc(100% - 60px);">
                            <div class="f-90 mb-2 gray-600" v-for="(item, index) in selected_company.all_users" :key="item.id">
                                {{index +1}}. {{item.user_name}} {{item.e_mail}} 
                                <b-badge v-if="item.is_admin>=1" variant="basic" class="ml-1"> 
                                    <span v-if="item.is_admin>=3">{{ item.is_admin==4? '최고 관리자' :'캠페인 평가자' }}</span>
                                    <span v-else>{{ item.is_admin==2? '외부 평가자' :'캠페인 리더' }}</span>
                                </b-badge>
                                <template v-if="item.excluded_campaign_name.length>0" >
                                    <span v-for="excl_name in item.excluded_campaign_name" :key="excl_name.id" >
                                        <span v-b-tooltip.hover :title="`'${excl_name}' 참여 제한됨. 설정을 바꾸려면 시스템 관리자에 문의하세요.`" class="red ml-2 cursor">
                                            <fa icon="exclamation-triangle"></fa>
                                        </span>
                                    </span>
                                </template>
                            </div>
                            <!-- <div class="f-90 mt-3 pt-1 border-top"><fa icon="exclamation-triangle" class="red"></fa> : 참여 제한된 캠페인이 있습니다. 설정을 바꾸려면 시스템 관리자에 문의하세요. </div> -->
                        </b-card>
                        <b-card v-else class="bgray-200 border-0 text-center py-2 f-90 gray-550" style="min-height: calc(100% - 60px);">
                            등록된 사용자가 없습니다. <br> 신규 사용자를 등록해 주세요.
                        </b-card>
                        <div class="text-right" v-if="is_editing_company!=true&&is_editing_user!=true">
                            <b-button size="sm" variant="dark-border" @click="is_editing_user=true"> 사용자 등록/수정 </b-button>
                        </div>
                    </b-col> 
                </b-row>
                <hr :class="is_editing_user!=true?'border-0':'border-top-0 border-bottom-dark pb-0 mb-0 pt-5'">
            </b-col>
        </b-row>
        <b-row v-if="is_editing_user==true">
            <b-col class="col-3 d-flex flex-column"> 
                <h4 class="mb-4 camp-position-title">사용자 정보 관리</h4>
            </b-col>
            <b-col class="pt-4"> 
                <user-registration-form></user-registration-form>
                <hr class="border-0 pb-0 mb-0 pt-5">
            </b-col>
        </b-row>

        <hr class="border-0 bottom-space my-5">
    </b-container>
</div>
</template>

<script>
import CompanyFinder from '../managing_user/CompanyFinder'
// import CompanyRegistrationForm from './CompanyRegistrationForm'
import UserRegistrationForm from '../managing_user/UserRegistrationForm'
import * as ih from '@/util'

export default {
    data() {
        return {
            is_company_selected: false,
            company_not_found: false, 
            // is_user_form_asked: false,
            // is_user_form_denied: false,

            // company_selected: {},
            searched_keyword: '',

            is_editing_company: false,
            is_editing_user: false,
            temp_campaign_name: '-',
            no_portfolio: true,
            newly_created: false
         }
    },
    props:{
        target_company_name: { default: ''}
    },
    computed:{
        selected_company(){
            let temp_company = ih.deepCopy(this.$store.state.selected_company)
            return temp_company
        },
        isAdminDefined(){
            if(this.selected_company.hasOwnProperty('all_users')){
                let idx = this.selected_company.all_users.findIndex(item => item.is_admin >= 1)
                if(idx > -1) return true
            }
            else return false
        },
    },
    methods: {
        checkForm(){
            if (this.company_name == '') return false
            else return true
        },
        saveCompany(){
            if (this.checkForm() != true) return window.alert('* 표시는 필수 입력란입니다.')

            const path = this.$store.state.backend_host + '/save_company'

            const formData = new FormData()
            formData.append('company_name', this.selected_company.company_name)
            formData.append('company_code', this.selected_company.company_code)
            formData.append('company_address', this.selected_company.company_address)
            formData.append('company_tel', this.selected_company.company_tel)
            // formData.append('company_tel', this.company_tel)
            // formData.append('other_info', this.other_info)

            return axios.post(path, formData)
            .then(response => {
                this.is_editing_company=false
                this.$store.commit('update_selected_company', {
                    company_name: response.data.company_name,
                    company_id: response.data.company_id,
                    company_other_info: '',
                    company_tel: '',
                    company_code: response.data.company_code,
                    company_address: response.data.company_address,
                    admin_users: [],
                    all_users: []
                })
                EventBus.$emit('toast-success','저장',`${response.data.company_name} 정보를 저장하였습니다.`)
                this.company_not_found = false
                this.newly_created= true
            })
            .catch(error => {
                if (error.response.status == '401')
                { 
                alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`)) 
                this.$store.dispatch('logout').then(() => { window.location.href = $store.state.frontend_host + '/login'} )
                }
            })    
        },
        // handleSelect(is_company_selected, item) { 
        //     this.is_company_selected = is_company_selected
        //     if (this.is_company_selected==true){
        //         this.is_editing_company = false
        //     }
            // this.company_selected = item
            // console.log('컴퍼니 파인더로부터 선택된 회사 정보를 받았습니다.')
        // },
        handleNotFound(mode){
            this.company_not_found = mode
        },
        searchedKeyword(word) {
            this.searched_keyword = word
            if(this.searched_keyword != '') {                
                this.selected_company.company_name = word,
                this.selected_company.company_code = '',
                this.selected_company.company_address = '',
                this.company_not_found = true
            }            
            this.is_editing_company = true
        },
        resetSelectedCompany(){
            this.$store.commit('update_selected_company', {
                company_name: '',
                company_id: '',
                company_other_info: '',
                company_tel: '',
                company_code: '',
                company_address: '',
                admin_users: [],
                all_users: []
            })
        },
        editCompany(){
            this.is_editing_company = true
            this.is_editing_user = false
        }
        //ManagingUser로 보냄
        // handleManagingUser(){
        //     this.$store.commit('update_now_page', 'ManagingUser')
        //     this.$router.push({ name: 'ManagingUser', params:{ target_company_name: this.company_selected.company_name }}) 
        // }
        // handleNotFound(){                
        //     console.log('컴퍼니 낫 파운드 작동합니다');
        //     if(this.searched_keyword != '') {                
        //         this.selected_company.company_name = this.searched_keyword,
        //         this.selected_company.company_code = '',
        //         this.selected_company.company_address = '',
        //         this.company_not_found = true
        //     }  
        // },
    },
    created(){
        this.resetSelectedCompany()
        // if(this.$route.params.search_keyword != undefined) { 
        //     this.target_company_name = this.$route.params.search_keyword 
        // } //ManagingUser에서 검색한 기업이 company-not-found일 경우 검색어(search_keyword)를 CompanyFinder로 보냄
    },
    watch:{
        selected_company:{
            deep: true,
            // immediate:true,
            handler(newVal, oldVal) {
                if (newVal.company_name != oldVal.company_name) {
                    this.is_editing_company=false
                    this.is_editing_user=false
                }
            }
        },
        searched_keyword:{
            immediate: true,
            handler(newVal){
                if(newVal){
                    console.log(newVal);
                    this.is_editing_user=false
                }
            }
        }
    },
    beforeDestroy(){
        this.resetSelectedCompany()
    },
    components: {
        CompanyFinder,
        // CompanyRegistrationForm,
        UserRegistrationForm
    }

}
</script>

<style scoped>

</style>