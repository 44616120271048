//Provider Service 중 Question에 나타나는 Sub들 전역 Vuex
import axios from 'axios'
import Vue from 'vue';
import * as ih from '@/util'

const questions = {
    namespaced: true,
    state: {
        subs: [],
        subs_loaded: false,
        answer_company: {},
        edit_mode: true,
    },
    mutations: {
        update_subs(state, new_subs){
            state.subs = new_subs
        },
        add_sub(state, new_sub){
            state.subs.push(new_sub)
        },
        update_sub(state, new_sub){
            let idx = state.subs.findIndex(item => { return item.sub_code == new_sub.sub_code })
            if(idx > -1){
                // state.subs.splice(idx, 1, new_sub)
                Vue.set(state.subs, idx, { ...state.subs[idx], ...new_sub })
            }
        },
        update_subs_loaded(state, new_status){
            state.subs_loaded = new_status
        },
        update_answer_company(state, new_company){
            state.answer_company = new_company
        },
        change_edit_mode(state, mode){
            state.edit_mode = mode
        },
        reset_subs(state){
            state.subs = [],
            state.subs_loaded = false
        }
    },
    getters: {
    },
    actions: {
        readSubsInit({commit, state, rootState}, question_contents){
            // console.log(question_contents);
            
            commit('update_subs', [])
            commit('update_subs_loaded', false)
            var promises = []
            const path = rootState.backend_host + '/read_sub_init'

            question_contents.forEach(function (sub_code) {
                promises.push(
                    axios.get(path, { params: {sub_code: sub_code}})
                    .catch( error => { console.log(error)})
                )}                
            )

            let temp_result = []
            return Promise.all(promises)
            .then( results => {
                    // console.log(results);
                    results.forEach(
                        response => {                            
                            if (response != undefined){
                                let result = response.data
                                let new_sub = {}
                                new_sub.sub_code = result.sub_code
                                new_sub.sub_name = result.sub_name
                                new_sub.sub_name_e = result.sub_name_e
                                new_sub.sub_name_c = result.sub_name_c
                                new_sub.sub_description = result.sub_description
                                new_sub.sub_description_e = result.sub_description_e
                                new_sub.sub_description_c = result.sub_description_c
                                new_sub.sub_condition = result.sub_condition
                                new_sub.sub_info = result.sub_info
                                new_sub.sub_info_e = result.sub_info_e
                                new_sub.sub_info_c = result.sub_info_c
                                new_sub.sub_type = result.sub_type
                                new_sub.sub_data_header = result.sub_data_header
                                new_sub.sub_data_contents = result.sub_data_contents
                                new_sub.sub_priority = result.sub_priority
                                new_sub.sub_parent = result.sub_parent
                                new_sub.sub_child = result.sub_child
                                new_sub.tags = result.tags
                                new_sub.has_pipeline = result.has_pipeline
                            
                                new_sub.question_code=this.question_code
                                temp_result.push(new_sub)
                            }
                    })
                    commit('update_subs', temp_result)    
                    commit('update_subs_loaded', true)
            })
            .finally( () => {
                
            })
        },
        readSubsInitSaq({commit, state, rootState}, question_contents){
            // console.log(question_contents);
            
            var promises = []
            const path = rootState.backend_host + '/read_sub_init'

            question_contents.forEach(function (sub_code) {
                promises.push(
                    axios.get(path, { params: {sub_code: sub_code}})
                    .catch( error => { console.log(error)})
                )}                
            )

            let temp_result = []
            return Promise.all(promises)
            .then( results => {
                    // console.log(results);
                    results.forEach(
                        response => {                            
                            if (response != undefined){
                                let result = response.data
                                let new_sub = {}
                                new_sub.sub_code = result.sub_code
                                new_sub.sub_name = result.sub_name
                                new_sub.sub_name_e = result.sub_name_e
                                new_sub.sub_name_c = result.sub_name_c
                                new_sub.sub_description = result.sub_description
                                new_sub.sub_description_e = result.sub_description_e
                                new_sub.sub_description_c = result.sub_description_c
                                new_sub.sub_condition = result.sub_condition
                                new_sub.sub_info = result.sub_info
                                new_sub.sub_info_e = result.sub_info_e
                                new_sub.sub_info_c = result.sub_info_c
                                new_sub.sub_type = result.sub_type
                                new_sub.sub_data_header = result.sub_data_header
                                new_sub.sub_data_contents = result.sub_data_contents
                                new_sub.sub_priority = result.sub_priority
                                new_sub.sub_parent = result.sub_parent
                                new_sub.sub_child = result.sub_child
                                new_sub.tags = result.tags
                                new_sub.has_pipeline = result.has_pipeline
                            
                                new_sub.question_code=this.question_code
                                temp_result.push(new_sub)
                            }
                        })    
            })
            .finally( () => {
                commit('update_subs', temp_result)
                commit('update_subs_loaded', true)
            })
        },
        // 1.Auditor 용 subs_adjust 읽어오기
            // subs_adjust는 기존 sub의 복제본으로 가져옴 (read_sub 대신하는 역할)
            // subs 통 복사가 아니라, foriegn_key(campaign_id, sub_code, company_name) + sub_data_contents, + author_name, author_comment, time_stamp 저장
        
        // 2. SubComponentMixin.js에서 initiateData 말고 audit_mode 일 때 initiate할 함수 하나 더 불러서 그려주기

        // 3. 각 subcomponent에서 값을 수정/편집한 다음 저장 
            // 기존 save_sub과 다르게 DB의 subs_adjust 테이블로 저장해야 함
            // v-model을 분리해서, audit_mode일때 save_sub_adjust 로 저장하도록 조정
            // 기존과 다르게 저장하려면 sub_data_conents를 다르게 받아야 함


        
    }
}

export default questions