<template>
<div>
        <!-- 질문지 명칭 및 점수산정 모드 설정 -->
        <editing-template-attr :value_new="value_new"></editing-template-attr>
        
        <!-- 질문지 컨텐츠 생성 -->
        <b-container fluid class="m-0 px-2 px-md-5 py-2 overflow-hidden">
            <b-row no-gutters class="d-md-flex">
                <b-col :class="[show_sub_finder==true?'col-2':'col-3','pr-3']">
                    <div class="mb-3 border-bottom-dark"> 
                        <h5>1. 질문 리스트 생성 </h5>    
                    </div>
                    <template-tree :block_list="show_sub_finder"></template-tree>
                </b-col>
                <b-col :class="[show_sub_finder==true?'col-6':'col-9','px-3']">
                    <div class="mb-3 border-bottom-dark d-flex justify-content-between"> 
                        <h5>2. 문항별 질문 생성/편집 </h5>
                        <b-button v-if="show_sub_finder!=true" variant="dark-border" class="bottom-radius-0" size="sm" @click="show_sub_finder=true">질문 가져오기</b-button>
                    </div>
                    <question-master></question-master>
                </b-col>
                <b-col :class="[show_sub_finder==true?'opening':'closed','question-search-panel pl-3']" >
                <template v-if="show_sub_finder==true">
                    <div v-if="show_sub_finder==true" class="mb-3 border-bottom-dark d-flex justify-content-between"> 
                        <h5>검색해서 넣기</h5>
                        <b-button v-if="show_sub_finder==true" variant="dark-border" class="bottom-radius-0" size="sm" @click="show_sub_finder=false">닫기</b-button>
                    </div>
                    <sub-finder></sub-finder> 
                </template>
                </b-col>
            </b-row>
            <hr class="border-0 bottom-space">
        </b-container>
</div>
</template>

<script>
import EditingTemplateAttr from './EditingTemplateAttr'
import TemplateTree from './TemplateTree'
import QuestionMaster from './QuestionMaster'
import SubFinder from './SubFinder'

export default {
    components: {
        TemplateTree,
        QuestionMaster,
        SubFinder,
        EditingTemplateAttr 
    },
    data() {
        return {
          page_destination: '',
          is_side_collapsed: false,
          add_component: '',
          show_sub_finder: false
        }
    },
    props:{
      value_new: { type: Boolean, default: false }
    },
    computed: {
        user_name() {
            return this.$store.getters.getUserName
        },
        user_id() {
            return this.$store.state.user_id
        },
        company_name() {
            return this.$store.getters.getCompanyName
        },
        e_mail(){
            return this.$store.getters.getEMail
        },
        isAuthenticated(){
            return this.$store.getters.isAuthenticated
        },
        isLoggedIn() {
            return this.$store.getters.isLoggedIn
        },
    },
    methods: {

    },
    created() {
        if(this.value_new == true){
            const new_template_tree = {
                template_id: 0,
                user_id: this.user_id, 
                template_code: 't' + Math.floor(Math.random() * 1000000000),
                template_name: '',
                template_contents: []
            }
            this.$store.commit('update_template_tree', new_template_tree)
        }
    },
    mounted(){
    //   EventBus.$emit('toggle-sidebar', true)
    }
}
</script>

<style scoped>
/* #right-side2 {
  position: relative;
  top: 0;
  right: 0;
  min-height: 100vh;
  max-height: auto;
  transition: all 0.3s;
  max-width: 300px;
  min-width: 300px;
  overflow-x: hidden;
}

#right-side2.collapsed {
  margin-right: -300px!important;
} */
#main2 {
  min-width: 1000px;
  width: 100vw;
  height: auto;
}
/* @media (max-width:1280px){
  #t-component-1{
      min-width: 200px;
  }
  #t-component-2 {
      min-width: 380px;
  }
  #right-side2 {
    min-width: 260px;
    width: auto;
  }
}
@media (max-width: 768px) {
  #right-side2 {
    min-width: 490px;
    width: auto;
  }
}
@media (max-width:736px) {
  #right-side2 {
    min-width: 97.4vw;
  }
}
@media (max-width:367px) {
  #right-side2 {
    min-width: 96vw!important;
  }
} */

</style>
