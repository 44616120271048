<template>
    <div>
        <b-table-simple>
            <b-thead>
            </b-thead>
            <b-tbody>
                <b-tr>
                    <b-td>파이프라인 사용 여부</b-td>
                        <b-td>
                            <b-dropdown :text="pipeline_enabled? '사용' : '사용하지 않음'">
                                <b-dropdown-item @click="enablePipeline(true)">사용</b-dropdown-item>
                                <b-dropdown-item @click="enablePipeline(false)">사용하지 않음</b-dropdown-item>
                            </b-dropdown>
                        </b-td>
                </b-tr>
                <b-tr v-if="pipeline_enabled==true">
                    <b-td>대상 SubCode</b-td><b-td><b-form-input v-model="pipeline_sub_code"></b-form-input></b-td>
                </b-tr>
                <b-tr v-if="pipeline_enabled==true">
                    <b-td>상세조건</b-td><b-td><b-form-input v-model="condition"></b-form-input></b-td>
                </b-tr>
                <b-tr v-if="pipeline_enabled==true">
                    <b-td col-span="2">조건이 참일 경우 해당 질문을 
                        <b-dropdown :text="satisfied_visible? '표시함' : '표시하지 않음'">
                            <b-dropdown-item @click="satisfied_visible=true">표시함</b-dropdown-item>
                            <b-dropdown-item @click="satisfied_visible=false">표시하지 않음</b-dropdown-item>
                        </b-dropdown>
                    </b-td>
                </b-tr>
                <b-tr v-if="pipeline_enabled==true">
                    <b-td col-span="2">질문이 표시되지 않는 경우 해당 질문을 
                        <b-dropdown :text="invisible_not_applicable? '해당없음 처리' : '0점 처리'">
                            <b-dropdown-item @click="invisible_not_applicable=true">해당없음 처리</b-dropdown-item>
                            <b-dropdown-item @click="invisible_not_applicable=false">0점 처리</b-dropdown-item>
                        </b-dropdown>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <div class="text-right">
            <b-button variant="blue" class="mr-2" @click="handleSave">저장</b-button>
            <b-button variant="dark" @click="$emit('click-cancel')">취소</b-button>
        </div> 
    </div>
</template>

<script>
import * as ih from '@/util'

export default {
    data() {
        return {
            pipeline_enabled: false,
            pipeline_sub_code: '',
            condition: '',
            satisfied_visible: false,
            invisible_not_applicable: true
        }
    },
    props:{
        sub_code: { type: String, default: '' },
        sub_type: { type: String, default: '' }
    },
    methods:{
        handleSave(){
            if(this.pipeline_enabled == false) { this.deletePipeline() }
            else{
                this.savePipeline()
            }
        },
        savePipeline(){
            const path = this.$store.state.backend_host + '/save_pipeline'
                   
            const formData = new FormData()
            formData.append('sub_code', this.sub_code)
            formData.append('pipeline_sub_code', this.pipeline_sub_code)
            formData.append('condition', this.condition)
            formData.append('satisfied_visible', this.satisfied_visible )
            formData.append('invisible_not_applicable', this.invisible_not_applicable)

            return axios.post(path, formData)
            .then(response => {
                console.log(response)
                EventBus.$emit('toast-success','성공','질문 숨김/표시 조건을 저장하였습니다.')               
            })
            .catch(error => {
                console.log(error)
            })
        },
        readPipeline(){
            const path = this.$store.state.backend_host + '/read_pipeline'

            return axios.get(path, { params: {
                sub_code: this.sub_code
            }})
            .then(response => {
                console.log(response)
                if(response.data) { this.pipeline_enabled = true }
                this.pipeline_sub_code = response.data.pipeline_sub_code
                this.condition = response.data.condition
                this.satisfied_visible = response.data.satisfied_visible
                this.invisible_not_applicable = response.data.invisible_not_applicable
            })
            
        },
        deletePipeline(){
            const path = this.$store.state.backend_host + '/delete_pipeline'

            const formData = new FormData()
            formData.append('sub_code', this.sub_code)

            return axios.post(path, formData)
        },
        enablePipeline(target){
            if(target==true){
                this.pipeline_enabled = true
            }
            else{
                this.pipeline_enabled = false
                this.pipeline_sub_code = ''
                this.condition = ''
                this.satisfied_visible = false,
                this.invisible_not_applicable = true
            }
        }

    },
    created(){
        this.readPipeline()
    }

}
</script>

<style>
#structure-preview > .card-header {
    padding: 0.25rem 1.25rem;
}
#structure-preview > .card-body > .table td{
    text-align: left;
}
</style>