<template>
<div class="editing-dfs">
        <!--제목, 컴포넌트 기본 공통 -->
        <div class="p-2 bgray-200 rounded d-flex justify-content-between align-items-center border">
            <div class="d-flex align-items-center">
                <span class="mx-3 f-110">{{ sub_code }}</span>
                <b-dropdown :text="sub_type" variant="dark-border" >
                    <b-dropdown-item-button @click="selectType(item)" v-for="item in sub_types" :key="item.id" > {{ item }} </b-dropdown-item-button>
                </b-dropdown>
            </div>
            <div class="d-flex align-items-center">             
                <b-form-checkbox v-model="sub_data_header.required">필수 응답(Public ONLY)</b-form-checkbox>
                <span class="mx-3 gray-600"> |</span>
                <b-dropdown :text="scoring" size="sm" variant="dark-border">
                    <b-dropdown-item @click="setScoring('none')" >No Scoring</b-dropdown-item>
                    <b-dropdown-item @click="setScoring('auto')">Auto Scoring</b-dropdown-item>
                </b-dropdown>
            </div>
        </div>

        <div class="mt-3 mb-1 fw-600 f-90">Title</div>
        <b-input v-model="sub_name" placeholder="Sub name"> {{ sub_name }} </b-input>

        <div class="mt-3 mb-1 fw-600 f-90">Description</div>
        <b-textarea v-model="sub_description" placeholder="A1. ㅇㅇㅇ을 관리하고 있습니까?"> {{ sub_description }} </b-textarea>

        <div class="mt-3 mb-1 fw-600 f-90">Info</div>
        <b-textarea v-model="sub_info" placeholder="용어설명, 참고사항, 평가기준 등 답변 참고정보"> {{ sub_info }} </b-textarea>

        <div class="mt-3 mb-1 fw-600 f-90" style="cursor:pointer" @click="conditionPopoverToggle()">Condition <fa :id="`sub_condition_information+${sub_code}`" icon="info-circle"></fa></div>
            <b-popover :target="`sub_condition_information+${sub_code}`" title="설명" :content="sub_condition_information" placement="left" :show="sub_condition_popover_show">
            </b-popover>
        <div  id="editing-template-condition">
            <b-form-checkbox v-model="is_condition_available" @input="manageConditionAvailability()" class="editing-dfs">
                <b-form-input placeholder="선행조건 표시내용을 입력해 주세요" v-model="sub_condition.text"> {{ sub_condition.text }} </b-form-input>
            </b-form-checkbox>      
        </div>

        <div class="mt-3 mb-1 fw-600 f-90">Not Applicable</div>
        <b-form-checkbox v-model="sub_data_header.enable_na" class="blue">선택지에 '해당없음' 추가</b-form-checkbox>

        <div class="mt-3 mb-1 fw-600 f-90" style="cursor:pointer" @click="contentsPopoverToggle()">Structure  <fa :id="`sub_data_contents_information+${sub_code}`" icon="info-circle"></fa></div>
            <b-popover :target="`sub_data_contents_information+${sub_code}`" title="정의"  :content="sub_data_contents_information" placement="left" :show="sub_data_contents_popover_show">
            </b-popover>

        <!-- DF Checkbox 데이터 구조 수정 --> 
        <b-table-simple>
            <tr>
                <td> text </td><td> value </td> <td> description </td> <td v-if="sub_data_header.scoring=='auto'"> 배점(총 10점) </td> <td> 삭제 </td>
            </tr>
            <tr v-for="(item, index) in sub_data_contents.structure" :key="item.id">
                <td><b-form-input v-model.trim="item.text">{{ item.text }}</b-form-input></td>
                <td><b-form-input v-model.trim="item.value">{{ item.value }}</b-form-input></td>
                <td><b-form-checkbox v-model="is_description_available[index]" @input="manageDescriptionAvailability(item, index)" value="true" unchecked-value="false"> 
                        <b-form-input :disabled="!is_description_available[index]" placeholder="상세 내용 폼에 표시될 가이드텍스트를 입력해 주세요" v-model="item.placeholder">
                            {{ item.placeholder }}
                        </b-form-input>
                    </b-form-checkbox>
                </td>
                <td v-if="sub_data_header.scoring=='auto'">
                    <b-form-input type='number' v-model="item.score"></b-form-input>
                </td>
                <td>
                    <b-button @click="sub_data_contents.structure.splice(index,1)" class="hj-icon-only-btn" size="sm"><fa icon="trash-alt"></fa></b-button>
                </td>
            </tr>
        </b-table-simple>
        <div class="text-right">
            <b-button @click="addCheckboxItem()" variant="blue" size="sm">체크박스 선택지 추가</b-button>
        </div>

        <!-- Tags, Child 등 기본 공통 -->
        <div class="mt-4 d-flex editing-dfs">
            <span class="fw-600 mr-5 f-90">Tags </span>
            <b-input v-model="tags" placeholder="띄어쓰기 대신 _ 사용, 쉼표로 구분"></b-input>
        </div>

        <div v-if="checkAbleChild" class="mt-3 mb-4 d-flex">
            <span class="fw-600 mr-5 f-90">Child</span>
            <div class="bgray-200 w-100 p-2 rounded "><b-form-checkbox v-model="has_child" @change="handleHasChild" >파일 및 URL 첨부</b-form-checkbox></div>
        </div>

        <div class="mt-4 d-flex editing-dfs">
            <span class="fw-600 mr-5 f-90">Priority</span>
            <b-form-select v-model="sub_priority" :options="sub_priority_option"></b-form-select>
        </div>

        <div class="float-right">
            <b-button @click="handleSaveSub()" variant="blue" class="mr-2">저장</b-button>
            <b-button @click="$emit('click-cancel')" variant="dark">취소</b-button>
        </div>
</div>
</template>

<script>
import { EditingSubComponentMixin } from './EditingSubComponentMixin'

export default {
    mixins: [EditingSubComponentMixin],
    data() {
        return {
            is_description_available: [],
            sub_data_contents_information: `
                text: 체크박스 옆에 표시될 텍스트입니다. 
                value: 체크박스 선택시 저장될 값입니다. text와 같을 수 있습니다. 
                description: 체크박스 옆에 부가 설명을 제공할 수 있는 텍스트입력 폼을 제공할지 결정합니다. 
                placeholder: 텍스트입력 폼에 폼에 표시될 가이드 텍스트입니다. 
            `
            //sub_data_header 데이터 구조 { }
            //sub_data_contents 데이터 구조  [ {"text": "예", "value": "예", "description": "", "placeholder": ""},
            //                                {"text": "아니오", "value": "아니오", "description": "", "placeholder": ""}]`,
        }
    },
    computed: {
    },
    methods: {
        handleSaveSub(){
            this.saveSub()
            .then(this.$emit('click-save'))
        },
        manageDescriptionAvailability(item, index){

            Vue.nextTick( () => {
                //해당 checkbox 아이템에 대해 description을 쓴다고 체크한 것이면
                if(this.is_description_available[index] == 'true'){
                    Vue.set(item, 'description', "")
                    Vue.set(item, 'placeholder', "")
                }
                //description을 쓰지 않는다고 체크한 것이면
                else {
                    delete item.description
                    delete item.placeholder
                }

            })
        },
        addCheckboxItem(){
            let new_checkbox_item = {
                text: '',
                value: ''
            }
            if(!this.sub_data_contents.structure) {
                Vue.set(this.sub_data_contents, 'structure', [])
            }
            this.sub_data_contents.structure.push(new_checkbox_item)
        },
        initiateDescriptionAvailability(){
            for (let i=0; i<this.sub_data_contents.structure.length; i++)
            {
                if( this.sub_data_contents.structure[i].hasOwnProperty('description')) this.is_description_available[i] = true
                else this.is_description_available[i] = false
            }
        }
    },
    created(){
        this.initiateDescriptionAvailability()
        console.log(this.sub_data_contents)
    },
}
</script>