<template>
<div>
    <div :class="[previous!=true ? '':'bgray-200 p-3 gray-600 rounded', 'mb-5']">

        <div v-if="headers">
            <!-- <template v-if="$i18n.locale=='ko'"> -->
                <div :class="[ sub_name != '' ? 'fw-500 f-110 border-bottom mb-2':'', '' ]"> <span v-if="sub_data_header.required && sub_data_header.required==true">*</span>{{ byLang(sub_name,sub_name_e, sub_name_c) }} </div>
                <div :class="[ sub_description != '' ? 'mb-3': '', 'mb-1']">
                    <div v-if="is_indicate_description" class="mr-1 fw-500 d-inline px-0"> {{  byLang(sub_description,sub_description_e, sub_description_c)}} </div>
                    <span v-for="item in tags" :key="item.id"> <span v-if="item != ''" class="ml-2 f-80 gray-500"> {{ item }} </span></span>
                    <b-button  v-if="sub_info != ''" class="ml-1 px-1 py-0 f-80" variant="teal" size="sm" :id="`i-${ sub_code }`" @click="toggleVisible" ><fa icon="book-open"></fa>{{byLang('작성 도움말', 'Info', '信息')}}</b-button>

                    <span v-if="sub_priority != ''"><b-badge :variant="badge_var" class="mx-2">{{ sub_priority }}</b-badge></span>
                    <b-button v-if="previous_sub.sub_code!=undefined && previous!=true" v-b-toggle="previous_sub.sub_code" size="sm" variant="red" class="ml-2" v-b-tooltip.hover :title="$t('과거 답변 보기')"><fa icon="history"></fa></b-button>

                    <!-- sub_info 정보 -->
                    <div v-if="is_visible" :id="`sub_info-${ sub_code }`" class="mt-2 mb-3 px-1 blue f-90"> <fa icon="info-circle" class="mr-1"></fa><span v-html="sub_info"></span></div>

                    <!-- sub_condition -->
                    <div v-if="is_sub_condition_activated==true" :id="`sub_condition-${sub_code}`" class="my-2 py-2">
                        <b-form-checkbox :disabled="is_locked || is_disabled_by_parent" v-model="sub_condition.value" @change="radioSave()"> {{ byLang(sub_condition.text, sub_condition.text_e, sub_condition.text_c) }} </b-form-checkbox>
                    </div>
                </div>
            <!-- </template> -->
           
        </div>


        <b-overlay :show="is_visible_by_pipeline==false" rounded="sm" opacity="0.6" bg-color="#c8c8c8">
            <!-- {{sub_data_contents.structure}} -->
            <!-- Priority 유형 Sub Component-->
            <div v-if="sub_data_contents.structure && sub_data_contents.structure.length !=0" class="mb-4">
                <div v-for="(item, idx) in sub_data_contents.structure" :key="item.id">
                <b-row no-gutters v-if="!item.is_seperator || item.is_sperator != true" class="w-100 py-2 border-bottom d-flex align-items-center">
                    <b-col class="col-8 col-sm-9 col-md-8 mr-4"> {{ item.text }} 
                    </b-col>
                    <b-col>
                        순위: <b-dropdown variant="blue-border" :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent" :text="sub_data_contents.result[idx]">
                            <b-dropdown-item v-for="(number_item, index) in sub_data_contents.structure" :key="number_item.id" @click="prioritySet(idx, index+1)">{{ index + 1 }}</b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                </b-row>
                </div>
            </div>
            <div v-else>
                <b-card v-if="sub_data_header.mode=='dynamic'">이전 질문에 답변을 하여야 항목이 표시됩니다. </b-card>
            </div>

            <div v-if="previous != true" :class="validation_style" class="mt-2"><fa v-if="validation_icon !=''" :icon="validation_icon"></fa> {{ validation_comment }} </div>

            <b-button v-if="save_state!='saved' && save_state!='resaved'" @click="handleSave()" variant="red">우선순위 저장</b-button>
            <b-button v-else @click="handleSave()" variant="blue">변경사항 저장</b-button>

            <!-- Child Component -->
            <sub-component v-for="(item, index) in child_subs" :key="item.id"
            :is_final_sub_component="index == child_subs.length -1 ? true : false"
            :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent" 
            :sub_type="item.sub_type" 
            :item="item" 
            :audit_mode="audit_mode" 
            :is_disabled_by_parent="is_disabled_by_parent" ></sub-component>  
                
            <!-- is_visible_by_pipeline 조건에 따른 해당없음 overlay -->
            <template v-slot:overlay>
                <div class="text-center ">
                    {{$t(`해당사항 없음`)}} <b-button variant="icon" v-b-tooltip.hover :title="pipeline_title"><fa icon="info-circle"></fa></b-button>
                </div>
            </template>
        </b-overlay>
    </div>

    <!-- previous component -->
    <b-collapse v-if="print_mode!=true" :id="previous_sub.sub_code">
        <sub-component :disabled="true" v-if="previous_sub.sub_code != undefined && previous != true" :is_disabled_by_parent="true" :sub_type="previous_sub.sub_type" :item="previous_sub" :previous="true"></sub-component>
    </b-collapse>
    

</div>
</template>


<script>
import { SubComponentMixin } from './SubComponentMixin'

export default {
    mixins: [SubComponentMixin],

    data(){
        return {
            button_title: this.$t(`평가정보`),
            pipeline_title: this.$t(`이 문항은 답변할 필요가 없습니다.`)
            //sub_data_header: {
            //    mode: 'dynamic', // dynamic 이면 다른 sub init structure 또는 result에서 text/value를 가지고 옴
                               // 가져올 수 있는 type: CheckBox 또는 Materiality
                               // mode 이면 아래에서 structure를 지정함
            //    answer_only: true, // true이면 result에서 가져옴. 
                                   // false이면 structure에서 가져옴. 
            //    sub_code: '', // 어떤 옵션에서 가지고 올 것인지
            //    duplicated: false // 중복 순위 허용여부
            //},
            //sub_data_contents: {
            //    structure: [ 
            //                { 
            //                    text: '전략 일반 - M&A 기반 시너지 창출 전략',
            //                    value: '전략 일반 - M&A 기반 시너지 창출 전략', 
            //                },
            //                {
            //                    text: '판매관행 및 제품 라벨링 - 브랜드 신뢰도 강화',
            //                    value: '판매관행 및 제품 라벨링 - 브랜드 신뢰도 강화',                             
            //                } 
            //            ],
            //    result: [] // 순서대로 정렬
            //}             
        }
    },
    computed:{
        target_contents(){
            if(this.sub_data_header.mode=='dynamic') {
                if(this.$store.state.questions.subs_loaded==true){
                    let idx = this.$store.state.questions.subs.findIndex( sub => { return sub.sub_code == this.sub_data_header.sub_code }) 
                    if(idx >-1){
                        console.log(this.$store.state.questions.subs[idx])
                        if(this.$store.state.questions.subs[idx].sub_data_contents_initial.result){
                            return this.$store.state.questions.subs[idx].sub_data_contents_initial
                        }
                    }
                }
            }
            else return null
        },

    },
    created(){
        
    },
    methods:{
        byLang(ko, en, cn){
            if(this.$i18n.locale=='en') return en
            else if (this.$i18n.locale=='cn') return cn
            else return ko
        },
        async handleSave () {
            if (this.sub_data_header.duplicated === false) {
                // console.log(this.sub_data_contents.result);
                for (let i = 0; i < this.sub_data_contents.result.length ; i++) {
                    for (let j = i + 1; j < this.sub_data_contents.result.length; j++) {
                        if (this.sub_data_contents.result[i] === this.sub_data_contents.result[j]) {
                            this.validation_style = 'ih-validation-saving'
                            this.validation_comment = '순위 중복이 존재하여 저장할 수 없습니다.'
                            return false
                        } else {
                            this.validation_style ='ih-validation-saved'
                            this.validation_comment = this.$t(`저장 되었습니다`)
                        }
                    }
                }
            }
            
            await this.dbSave()
        },
        prioritySet(idx, number){
            //idx:
            Vue.set(this.sub_data_contents.result, idx, number.toString())
            this.handleSave()
            // console.log(this.sub_data_contents.result);
        },
        initiateStructure(){
            // console.log(this.sub_data_header.duplicated)    
            if(this.sub_data_header.mode=='dynamic'){
                //sub_data_header 읽어오는 로직

                let path = ''
                this.sub_data_contents.structure = []
                    
                //1) 만약 answer_only 가 아니라 전체 항목 순위일 경우   
                if(this.sub_data_header.answer_only!=true){
                    path = this.$store.state.backend_host + '/read_sub_init'
                }
                else { //2) 만약 answer_only인 경우 
                    if(this.$store.state.mode != 'public') { path = this.$store.state.backend_host + '/read_sub' }
                    else { path = this.$store.state.backend_host + '/read_sub_public' }
                }

                return axios.get(path, {params: {
                    company_name: this.$store.getters.getCompanyName,
                    campaign_id: this.$store.state.campaign_in_progress.campaign_id,
                    response_code: this.$store.state.response_code,
                    question_code: this.question_code,
                    sub_code: this.sub_data_header.sub_code
                }})
                .then(response => {
                    let new_contents = response.data.sub_data_contents

                    if(this.sub_data_header.answer_only == true)
                    {
                        let temp_structure = []
                        new_contents.result.forEach( item => {
                            let idx = new_contents.structure.findIndex( x => {return x.value == item})
                            if(idx > -1){
                                temp_structure.push(new_contents.structure[idx])
                            }
                        })
                        Vue.set(this.sub_data_contents, 'structure',temp_structure)
                        this.$forceUpdate()
                    }
                    else{
                        let temp_structure = []
                        new_contents.structure.forEach( item => {
                            if(item.is_sperator && item.is_sperator == true) { }
                            else{
                                this.temp_structure.push(item)
                            }
                        })
                        Vue.set(this.sub_data_contents, 'structure', temp_structure)

                        this.$forceUpdate()
                    }   
                })
                .catch(error=>{
                    console.log(error)
                })
            }
        }
    },
    watch: {
        is_loaded:{
            immediate: true, 
            handler(newVal){
                if(newVal==true){    
                    this.initiateStructure()
                }
            }
        },
        target_contents: {
            handler(new_contents){
                //서버를 거쳐서 오면 동기화 문제 생기기 때문에, 같은 화면에 있을 경우에는 직접 Store를 거쳐서 갱신토록 함
                if(this.sub_data_header.answer_only == true && new_contents.result)
                    {
                        let temp_structure = []
                        new_contents.result.forEach( item => {
                            let idx = new_contents.structure.findIndex( x => {return x.value == item})
                            if(idx > -1){
                                temp_structure.push(new_contents.structure[idx])
                            }
                        })

                        this.sub_data_contents.structure = temp_structure
                    }
                    else{
                        let temp_structure = []
                        new_contents.structure.forEach( item => {
                            if(item.is_sperator && item.is_sperator == true) { }
                            else{
                                this.temp_structure.push(item)
                            }
                        })
                        Vue.set(this.sub_data_contents, 'structure', temp_structure)
                    }   
            }
        }
    }
}


</script>

<style scoped>

</style>