<template>
<div>
    <general-print-button print_target="improvement-b-container" :btn_variant="$i18n.locale!='cn'?'subtop-gn':'subtop-gn-cn'" 
     class="text-right"
    @modal-opened="is_printing=true" @modal-closed="is_printing=false" :d_none="is_printing==true? 'd-none':''"><fa icon="print"></fa></general-print-button>

        <div id="improvement-b-container" class="pt-4 mb-3">
            <div v-if="is_printing==true" class="gray-500 f-95 mb-4 d-flex justify-content-between"><p class="tail-line w-100">{{ campaign_in_progress.campaign_name }}</p> <p class="ml-3">{{ company_name }}</p> </div>

            <h4 class="mb-3" >개선 계획 작성 </h4>
            <template v-if="is_printing==true">  평가 결과 도출된 개선과제에 대해 시정 조치 및 개선 계획을 작성하세요. </template>
                
            <hr class="border-0 pb-3">
            <!-- <improvements-mode-a></improvements-mode-a> -->
            <improvements-mode-b></improvements-mode-b>

            <hr class="border-0 pb-5">
        </div>
        <!-- <div v-else class="py-5">
            <b-card class="border-0 blue text-center"><b-spinner class="mx-2 p-1 my-1" small type="grow"></b-spinner> 개선과제를 가져오고 있습니다.</b-card>
        </div> -->

</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import ImprovementsModeA from './ImprovementsModeA'
import ImprovementsModeB from './ImprovementsModeB'
import GeneralPrintButton from '@/components/print_to_pdf/GeneralPrintButton'

export default {
    data() {
        return {
           is_printing: false,
        }
    },
    computed:{
        ...mapState(['campaign_in_progress','company_name','company_id','user_id']),
    },
    methods:{
        
    },
    created(){
        
    },
    components:{
        ImprovementsModeA,
        ImprovementsModeB,
        GeneralPrintButton,
    },
    mounted(){
    }
}
</script>

<style>
</style>