<template>
<div>
    <h2 v-if="local_campaign.campaign_id==124||local_campaign.campaign_id==177" class="pt-5 mb-5 tail-line-dark"> {{$t(`ESG 자가진단 결과 요약`)}} </h2>
    <h1 v-else class="pt-5 mb-5 tail-line-dark"> {{ reporting_company.company_name }} ESG 자가진단 결과 </h1>

    <div class="d-flex justify-content-between align-items-center mb-4">
        <div class="width-calc50">
            <div class="mb-5">
                <h5 class="pb-2 mb-2 border-bottom-dark-bold f-140">{{local_campaign.campaign_id==124||local_campaign.campaign_id==177? $t('진단정보'):$t('평가정보')}}</h5>
                <b-row no-gutters class="pb-2"><b-col cols="3" class="gray-700">{{local_campaign.campaign_id==124||local_campaign.campaign_id==177? $t('진단명'):$t('평가명')}}</b-col><b-col>{{$t(local_campaign.campaign_name)}}</b-col></b-row>
                <b-row no-gutters class="pb-2"><b-col cols="3" class="gray-700">{{$t(`기간`)}}</b-col><b-col>{{camgaign_start_date_string}} ~ {{camgaign_end_date_string}}</b-col></b-row>
                <b-row no-gutters class="pb-2 border-bottom-dark"><b-col cols="3" class="gray-700">{{$t(`주최사`)}}</b-col><b-col>{{$t(local_campaign.operator_name)}}</b-col></b-row>
            </div>
            <div class="">
                <h5 class="pb-2 mb-2 border-bottom-dark-bold f-140">{{$t(`기업정보`)}}</h5>
                <b-row no-gutters class="pb-2"><b-col cols="3" class="gray-700">{{$t(`회사명`)}}</b-col><b-col>{{$t(reporting_company.company_name)}}</b-col></b-row>
                <b-row no-gutters class="pb-2" v-if="$i18n.locale=='ko'"><b-col cols="3" class="gray-700">{{$t(`사업자번호`)}}</b-col><b-col>{{company_info.company_code}}</b-col></b-row>
                <b-row no-gutters class="pb-2 border-bottom-dark"><b-col cols="3" class="gray-700">{{$t(`주소`)}}</b-col><b-col>{{company_info.company_address}}</b-col></b-row>
            </div>
        </div>
        <div class="py-2 width-calc50" style="min-height: 380px;"> 
            <div class="d-flex justify-content-center">
                <div class="position-relative" style="width: 400px;">
                    <radial-progress-bar v-for="(cat, idx) in category_names" :key="idx" :diameter="diameter[idx]" :completed-steps="reporting_company.category_score[cat]==null?0:reporting_company.category_score[cat]" :total-steps="total_steps" 
                    :inner-stroke-color="'#DBDBCC'" :start-color="'#8fa0ff'" :stop-color="'#677EFF'" class="position-absolute" :style="radial_position[idx]"></radial-progress-bar>
                    <div class="position-absolute text-right text-black f-95" style="top: -3px; right: calc(50% + 10px);">
                        <div v-for="cat in category_names" :key="cat.id" class="fw-600 mb-1"><span class="mr-2">{{$t(cat)}}</span> {{ Math.round(reporting_company.category_score[cat]*10)/10 }} </div>
                    </div>
                    <div class="position-absolute w-100 text-center" style="top:150px; left: 0;"> 
                        <div class="fw-500"><span class="fw-600" style="font-size: 3.3rem">{{ Math.round(reporting_company.total_score*10)/10 }}</span> / 100</div> 
                        <h6 class="mb-0" style="margin-top: -10px;">{{$t(`총점`)}}</h6>
                    </div>
                </div>
            </div>
        </div>  
    </div>

    <h5 class="pb-2 mb-2 border-bottom-dark-bold f-140">{{$t(`부문별 결과`)}}</h5>
    <b-row>
        <b-col v-for="item in category_names" :key="item.id" class="d-flex flex-column">
            <h6 class="py-2 fw-600 border-bottom-gray-550 d-flex justify-content-between align-items-center">{{ $t(item) }}</h6>
        </b-col>
    </b-row>
    <b-row class="mb-5">
        <b-col v-for="item in category_names" :key="item.id" class="d-flex flex-column">
            <div class="border-bottom-dark text-right f-95 pb-2 mb-2"> <span class="f-160 fw-600">{{ isNaN(reporting_company.category_score[item])==true||reporting_company.category_score[item]==null? '-': Math.round(reporting_company.category_score[item]*10)/10}}</span> / 100</div>
            <template v-if="cat2_score_hashed[item]!=undefined">
                <div v-for="ele in cat2_score_hashed[item].children" :key="ele.id" class="d-flex justify-content-between py-1 f-100 line-height-low">
                    <span>{{ $t(ele.question_name) }}</span> <span class="ml-2">{{ isNaN(ele.score_result)==true||ele.score_result==null? '-': ele.score_result }}</span>
                </div>
            </template>
            <div class="mt-auto border-bottom-dark"></div>
        </b-col>                        
    </b-row>
    <hr class="border-0">

    <h5 class="pt-2 pb-2 mb-3 border-bottom-dark-bold f-140"> {{$t(`벤치마크 기업 대비`)}} {{ $t(reporting_company.company_name) }} {{$t(`현황`)}}</h5>
    <div class="mb-4 f-95 d-flex flex-wrap align-items-center justify-content-end px-3"> 
        <div class="color-box mr-1 bg-blue"></div> {{ $t(reporting_company.company_name) }} 
        <div class="color-box ml-3 mr-1 bgray-300 border-gray-600"></div> {{$t(`평균점수`)}}
        <div class="color-box ml-3 mr-1 bgray-400 border-gray-600"></div>{{$t(`최고점수`)}} 
        <!-- <div class="color-box ml-3 mr-1 bgray-100 border-gray-400"></div>{{$t(`전체 최저점수`)}} -->
    </div>

    <div v-show="is_printing==false"><bar-chart ref="category_result_chart" v-if="is_chart_loaded==true" :chartData="category_score_chart_data" :options="category_score_chart_option" class="category-result-chart2"></bar-chart></div>
    <div class="overflow-hidden text-center mb-3" v-show="is_printing==true"><img style="width:860px; height: 150px;" :src="category_result_chart_url" /></div>     

    <b-table-simple v-if="local_campaign.campaign_id!=''&&(local_campaign.campaign_id==124||local_campaign.campaign_id==177)" hover responsive class="rep-table pt-5 mb-0">
        <b-thead class="fw-500">
            <b-tr>
                <b-td></b-td><b-td class="">{{ camgaign_start_date_string.slice(0,4) }} {{ $t(reporting_company.company_name) }}</b-td>
                <b-td class="">{{$t(`평균점수`)}}</b-td>
                <b-td class="">{{$t(`최고점수`)}}</b-td>
                <b-td class="">{{$t(`가중치`)}}(%)**</b-td>
            </b-tr>
        </b-thead>
        <b-tbody v-if="is_chart_loaded==true">
            <b-tr class="">
                <b-td class="border-bottom-dark text-left">{{$t(`총점`)}}*</b-td>
                <b-td class="border-bottom-dark"> {{ Math.round(reporting_company.total_score*10)/10 }} </b-td>
                <b-td class="border-bottom-dark"> {{ all_average }} </b-td>
                <b-td class="border-bottom-dark"> {{ all_max }} </b-td>
                <b-td class="border-bottom-dark">100</b-td>
            </b-tr>
            <b-tr v-for="item in category_names" :key="item.id" class="">
                <b-td class="text-left"> {{$t(item)}} </b-td>
                <b-td> {{ Math.round(reporting_company.category_score[item]*10)/10}} </b-td>
                <b-td> {{ all_category_average[item] }} </b-td>
                <b-td> {{ all_category_max[item] }} </b-td>
                <b-td> {{ get_weighting(item) }}  </b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>

    <b-table-simple v-else hover responsive class="rep-table pt-5 mb-0">
        <b-thead class="fw-500">
            <b-tr>
                <b-td></b-td><b-td class="">{{ camgaign_start_date_string.slice(0,4) }} {{ reporting_company.company_name }}</b-td>
                <b-td class="">{{$t(`전체 평균`)}}</b-td>
                <b-td class="">{{$t(`전체 최고점수`)}}</b-td>
                <b-td class="">{{$t(`전체 최저점수`)}}</b-td>
            </b-tr>
        </b-thead>
        <b-tbody v-if="is_chart_loaded==true">
            <b-tr class="">
                <b-td class="border-bottom-dark text-left">{{$t(`총점`)}}</b-td>
                <b-td class="border-bottom-dark"> {{ Math.round(reporting_company.total_score*10)/10 }} </b-td>
                <b-td class="border-bottom-dark"> {{ all_average }} </b-td>
                <b-td class="border-bottom-dark"> {{ all_max }} </b-td>
                <b-td class="border-bottom-dark"> {{ all_min }} </b-td>
            </b-tr>
            <b-tr v-for="item in category_names" :key="item.id" class="">
                <b-td class="text-left"> {{$t(item)}} </b-td>
                <b-td> {{ reporting_company.category_score[item] ? Math.round(reporting_company.category_score[item]*10)/10 : '-' }} </b-td>
                <b-td> {{ all_category_average[item] }} </b-td>
                <b-td> {{ all_category_max[item] }} </b-td>
                <b-td> {{ all_category_min[item] }}  </b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>

    <div v-if="local_campaign.campaign_id==124||local_campaign.campaign_id==177" class="f-90 mt-3">
        {{$t(`* 총점: 총점은 부문별 점수에 가중치를 반영한 점수입니다.`)}} <br>
        {{$t(`** 가중치: 가중치는 각 평가 부문의 중요도를 나타냅니다. 예를 들어, 'B. 안전한 작업환경' 부분의 가중치가 25%라는 것은, 해당 영역의 점수가 총점에 반영될 때 전체(100%) 중 25%의 중요성이 적용된다는 뜻으로, 총점을 산출하는 과정에서 'A. 근로자의 인권 존중'에 이어 두번째로 중요하게 반영된 평가 부문이라는 것을 의미합니다.`)}}
    </div> 

</div>
</template>

<script>
import * as ih from '@/util'
import RadialProgressBar from 'vue-radial-progress'
import BarChart from '@/components/charts/BarChart'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    components:{
        RadialProgressBar,
        BarChart,
    },
    data() {
        return {
            total_steps: 100,
            category_score_chart_data: {
                labels: [] ,
                datasets: [
                    {
                        label: '',
                        data: [],
                        barPercentage: 0.8,
                        categoryPercentage: 0.4,
                        backgroundColor: ['rgba(31, 64, 255, 1)','rgba(31, 64, 255, 0.7)','rgba(31, 64, 255, 0.7)','rgba(31, 64, 255, 0.7)','rgba(31, 64, 255, 0.7)','rgba(31, 64, 255, 0.7)','rgba(31, 64, 255, 0.7)','rgba(31, 64, 255, 0.7)'],
                        borderColor: ['rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.2)'],
                        borderWidth: 1,
                        order: 1
                    },
                    {
                        label: '',
                        data: [],
                        barPercentage: 0.8,
                        categoryPercentage: 0.4,
                        backgroundColor: ['rgba(0,0,0, 0.2)','rgba(0,0,0, 0.2)','rgba(0,0,0, 0.2)','rgba(0,0,0, 0.2)','rgba(0,0,0, 0.2)','rgba(0,0,0, 0.2)','rgba(0,0,0, 0.2)','rgba(0,0,0, 0.2)'],
                        borderColor: ['rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.3)'],
                        borderWidth: 1,
                        order: 1
                    },
                    {
                        label: '',
                        data: [],
                        barPercentage: 0.8,
                        categoryPercentage: 0.4,
                        backgroundColor: ['rgba(0,0,0, 0.35)','rgba(0,0,0, 0.35)','rgba(0,0,0, 0.35)','rgba(0,0,0, 0.35)','rgba(0,0,0, 0.35)','rgba(0,0,0, 0.35)','rgba(0,0,0, 0.35)','rgba(0,0,0, 0.35)'],
                        borderColor: ['rgba(0,0,0, 0.5)', 'rgba(0,0,0, 0.5)', 'rgba(0,0,0, 0.5)', 'rgba(0,0,0, 0.5)', 'rgba(0,0,0, 0.5)', 'rgba(0,0,0, 0.5)', 'rgba(0,0,0, 0.5)', 'rgba(0,0,0, 0.5)'],
                        borderWidth: 1,
                        order: 1
                    },
                    // {
                    //     label: '',
                    //     data: [],
                    //     barPercentage: 0.8,
                    //     categoryPercentage: 0.7,
                    //     backgroundColor: ['rgba(0,0,0, 0.05)','rgba(0,0,0, 0.05)','rgba(0,0,0, 0.05)','rgba(0,0,0, 0.05)','rgba(0,0,0, 0.05)','rgba(0,0,0, 0.05)','rgba(0,0,0, 0.05)','rgba(0,0,0, 0.05)'],
                    //     borderColor: ['rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.3)', 'rgba(0,0,0, 0.3)'],
                    //     borderWidth: 1,
                    //     order: 1
                    // },
                ]
            },
            category_score_chart_option: {
                maintainAspectRatio: false,
                responsive: true,
                legend: {
                    display:false
                },
                scales: {
                    xAxes: [{
                        // gridLines: {
                        //     color:"rgba(0, 0, 0, 0)"
                        // },
                        ticks: {
                            beginAtZero:true,
                            max: 100,
                            stepSize: 20
                        }                                        
                    }],
                    yAxes: [{
                        gridLines: {
                            color:"rgba(0, 0, 0, 0)"
                        },
                        ticks: {
                            beginAtZero:true,
                            max: 100,
                            stepSize: 20
                        },  
                    }]
                }
            },
            is_chart_loaded: false,
            category_result_chart_url: '',
            local_campaign: {}
        }
    },
    props:{
        is_printing: { type: Boolean, default: false }
    },
    computed:{
        ...mapState('cr_scores', ['reporting_company', 'company_info']),
        ...mapState('user_campaigns_list',['campaigns']
        ),
        ...mapState('score', ['is_score_loaded','is_evaluate_loaded','is_previous_score_loaded', 
        'is_comment_loaded', 'category_names', 'temp_target_companies','previous_temp_target_companies']
        ),
        ...mapState('reviews', ['reviews', 'is_conclusion_loaded', 'is_recommendations_loaded']),
        ...mapState(['campaign_in_progress', 'campaign']),
        template_contents(){
            return this.$store.state.template_tree.template_contents
        },
        previous_template_tree(){
            return this.$store.state.previous_template_tree
        },
        diameter(){
            return [380, 334, 288, 242, 196, 150]
        },
        radial_position(){
            return ['top: 0; left: 0', 'top:23px; left: 23px;', 'top:46px; left:46px;', 'top:69px; left:69px;', 'top:92px; left:92px;']         
        },
        cat2_score_hashed(){
            let temp_obj = {}
            this.reporting_company.score_contents.forEach(x => {
                if(x.question_name!='기본정보'&&x.question_name!='기본 정보'&&x.question_name!='基本信息'){
                    Vue.set(temp_obj, x.question_name, x)
                }
            })    
            return temp_obj    
        },
        camgaign_start_date_string(){
            if(this.view_mode=='provider'){
                return ih.dateToString(this.$store.state.campaign_in_progress.start_date)
            }
            else return ih.dateToString(this.$store.state.campaign.start_date)
        },
        camgaign_end_date_string(){
            if(this.view_mode=='provider'){
                return ih.dateToString(this.$store.state.campaign_in_progress.end_date)
            }
            else return ih.dateToString(this.$store.state.campaign.end_date)
        },
        all_category_average(){
            return this.$store.getters['score/getCategoryAverage']
        },
        all_category_max(){
            return this.$store.getters['score/getCategoryMax']
        },
        all_category_min(){
            return this.$store.getters['score/getCategoryMin']
        },
        all_average(){
            return this.$store.getters['score/getAllAverage']
        },
        all_max(){
            return this.$store.getters['score/getAllMax']
        },
        all_min(){
            return this.$store.getters['score/getAllMin']
        },
        view_mode() {
            return this.$store.state.view_mode
        },
    },
    methods:{
        ...mapActions('cr_scores', ['readCompanyInfo']),
        updateChartData(){
            // console.log('업데이트 차트 데이터 까지 옴');
            
            if(this.is_score_loaded!=false){
                //category 차트데이터 업데이트
                this.category_score_chart_data.labels = []
                this.category_score_chart_data.labels.push(this.$t('총점')) 
                this.category_names.forEach(item=>{
                    this.category_score_chart_data.labels.push((this.$t(item))) 
                })

                // 내기업 점수
                this.category_score_chart_data.datasets[0].label = this.reporting_company.company_name
                this.category_score_chart_data.datasets[0].data = []
                this.category_score_chart_data.datasets[0].data.push(Math.round(this.reporting_company.total_score*10/10))
                this.category_names.forEach(item => {
                    let company = this.temp_target_companies.find(item => { return item.company_id == this.reporting_company.company_id})
                    this.category_score_chart_data.datasets[0].data.push(Math.round(company.category_score[item]*10)/10)
                })    
                //전체 평균
                this.category_score_chart_data.datasets[1].label = this.$t('평균점수')
                this.category_score_chart_data.datasets[1].data = []
                this.category_score_chart_data.datasets[1].data.push(this.all_average)    
                this.category_names.forEach(item => {
                    this.category_score_chart_data.datasets[1].data.push(Math.round(this.all_category_average[item]*10)/10)
                })
                // 전체 최고점
                this.category_score_chart_data.datasets[2].label = this.$t('최고점수')
                this.category_score_chart_data.datasets[2].data = []
                this.category_score_chart_data.datasets[2].data.push(this.all_max)    
                this.category_names.forEach(item => {
                    this.category_score_chart_data.datasets[2].data.push(Math.round(this.all_category_max[item]*10)/10)
                })
                // 전체 최저점
                // this.category_score_chart_data.datasets[3].label = this.$t('전체 최저점수')
                // this.category_score_chart_data.datasets[3].data = []
                // this.category_score_chart_data.datasets[3].data.push(this.all_min)    
                // this.category_names.forEach(item => {
                //     this.category_score_chart_data.datasets[3].data.push(Math.round(this.all_category_min[item]*10)/10)
                // })
                this.is_chart_loaded = true
                // this.updatePreviousScore()
            }
            else this.is_chart_loaded =false
        },
        initiateAuditConclusion(){
            let temp_audit_conclusion = this.findAuditConclusion(this.reporting_company.company_id) 
            //console.log(temp_rev)
            let conclusion_obj = {}
            if(temp_audit_conclusion != undefined){
                    conclusion_obj = temp_audit_conclusion
                }
            else {
                conclusion_obj = {
                    conclusion_id: null,
                    auditor_name: '',
                    conclusion: '',
                    time_stamp: ''
                }
                this.is_editing = true
            }
            this.audit_conclusion = conclusion_obj
        },
        initiateAuditRecommendations(){
            let temp_audit_recommendations = this.findAuditRecommendations(this.reporting_company.company_id) 

            let temp_rec = []
            if(temp_audit_recommendations == undefined || temp_audit_recommendations.length == 0){
                temp_rec = [{
                rec_code: 'rc'+ Math.floor(Math.random()*10000000000),
                auditor_name: '',
                title: '',
                recommendation: '',
                time_stamp: ''
                },
                {
                rec_code: 'rc'+ Math.floor(Math.random()*10000000000),
                auditor_name: '',
                title: '',
                recommendation: '',
                time_stamp: ''
                }]
                this.is_editing_recommendation = []
                for(let i=0; i<temp_rec.length; i++){
                    this.is_editing_recommendation.push(true)
                }
            }
            else{
                temp_rec = temp_audit_recommendations
                this.is_editing_recommendation = []
                for(let i=0; i<temp_rec.length; i++){
                    this.is_editing_recommendation.push(false)
                }                
            }
            this.audit_recommendations = temp_rec
        },
        findAuditRecommendations(company_id){
            let idx = this.reviews.findIndex(item => item.company_id == company_id)
            if (idx > -1){
                return ih.deepCopy(this.reviews[idx].audit_recommendations)
            }
            else return undefined
        },
        findAuditConclusion(company_id){
            let idx = this.reviews.findIndex(item => item.company_id == company_id)
            if (idx > -1){
                return ih.deepCopy(this.reviews[idx].audit_conclusion)
            }
            else return undefined
        },
        get_weighting(category_name){
            if(this.local_campaign.campaign_id==124){
                if(category_name == 'A. 근로자의 인권 존중') {
                    return '40'
                }
                else if(category_name == 'B. 안전한 작업환경') {
                    return '25'
                }
                else if(category_name == 'C. 환경 친화적 사업장 관리') {
                    return '19'
                }
                else if(category_name == 'D. 기업윤리 준수') {
                    return '16'
                }
            }
            else if(this.local_campaign.campaign_id==177) {
                if(category_name == 'A. 근로자의 인권 존중') {
                    return '52'
                }
                else if(category_name == 'B. 안전한 작업환경') {
                    return '25'
                }
                else if(category_name == 'C. 환경 친화적 사업장 관리') {
                    return '3'
                }
                else if(category_name == 'D. 기업윤리 준수') {
                    return '20'
                }
            }
        },
        get_percentile(my_score){

        }
    },
    created(){
        if (this.view_mode=='provider'){
            this.local_campaign = this.campaign_in_progress
        } else {
            this.local_campaign = this.campaign
        }

        if (this.local_campaign.campaign_id != '' && this.reporting_company !=undefined) {
            this.$store.dispatch('makeTemplateTreeByCompanyId', this.reporting_company.company_id)
            this.$store.dispatch('score/readAllEvaluateResult', { campaign_id: this.local_campaign.campaign_id, company_id: this.reporting_company.company_id})  
            .then(()=>{      
                // console.log(this.category_names);
                // console.log(this.template_contents);
                this.$store.commit('score/update_category_names', Array.from(this.category_names.map(x => {
                if (!x.includes('기본정보')&&!x.includes('기본 정보')&&!x.includes('基本信息')){
                    // console.log(x);
                    return x
                }}).filter(x=>x!=undefined)                    
                ))
                this.updateChartData() 
                this.initiateAuditConclusion()
                this.initiateAuditRecommendations()        
                this.readCompanyInfo(this.reporting_company.company_id)           
            })
        }
        else console.log('캠페인 못받음')    
    },
    updated(){
        // console.log(this.category_names);
    },
    beforeDestroy(){
        this.local_campaign = {}
    },
    watch:{
        is_printing:{
            immediate: true,
            handler(newV, oldV){
                // console.log(newV);
                if(newV==true){
                    this.category_result_chart_url = this.$refs['category_result_chart'].$data._chart.toBase64Image()
                    // console.log(this.category_result_chart_url);
                }
            }
        }
    }
   
}
</script>

<style>
.hj-radar-chart{
    height: 440px;
    padding-left: 100px;
}

.hj-radar-chart canvas {
    height: 440px!important;
    width: 440px!important;
}

.v-bar-chart {
    max-height: 200px;
    width: 90%;
}

/* 이하 고정요소 -> 수정 주의 */
.xy-block{ 
    width: calc(100% - 30px);
    height: 140px;
    border-left: 1.2px solid var(--gray-500);
    border-bottom: 1.2px solid var(--gray-500);
}
.x-label-position{
    bottom: -30px;
}
.yearly-trend-circle{
    margin-bottom: auto;
    width: 40px;
    height: 40px;
    background-color:rgba(61, 90, 254, 0.5);
    border-radius: 50%;
    text-align: center;
    padding-top: 8px;
    color:white;
    font-weight: 500;
}
.yearly-trend-circle.this-year{
    background-color:  var(--report1);
}
.distribution-bar{
    width: calc(20% - 12px);
    margin-left: 6px;
    margin-right: 6px;
    bottom: 0;
    background-color: var(--gray-400);
    /* border: 1px solid var(--gray-700) */
}
.distribution-bar > .distribution-section {
    width: 100%;
    bottom: -31px;
}
.distribution-bar.blue-bar{
    background-color: rgba(47, 132, 86, 0.5);
    border: 2px solid var(--report1);
}

.width-calc50{
    width: calc(50% - 15px)
}
.width-calc50-sm{
    width: calc(50% - 0.5rem)

}
.color-box{
    width: 12px;
    height: 12px;
}
.esg-rating-block-simple{
    width: 14.2857%;
    padding: 0.1rem;
    border: 1px solid var(--report1);
    border-left: 0px;
}
.esg-rating-block-simple:last-child{
    border-right: 0px;
}
.category-result-chart2 {
    max-height: 150px;
 }
</style>