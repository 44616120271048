<template>
<div>  
        <div v-for="(category, index) in template_contents" :key="category.id" class="pb-4">
            <template v-if="is_pa_loaded==true">
                <template v-if="has_improvment_category(index)==true">
                    <div class="bgray-300 pl-2 py-1 mb-4 f-110"> {{ category.question_name }} </div>
                    <b-table-simple class="light-thead table-text-left f-95 mb-5">
                        <b-thead><b-tr>
                            <b-td style="width:20%">평가기준 미충족 문항</b-td> <b-td style="width:38%">미충족 사항 및 현장평가 확인사항</b-td> <b-td>개선계획 작성</b-td>
                        </b-tr></b-thead>
                        <b-tbody v-for="question in category.children" :key="question.id">
                            <b-tr v-if="has_improvment_contents(question.question_code)==true">
                                <b-td><span class="fw-500 f-100">{{ question.question_name }}</span></b-td>
                                <b-td class="py-0" colspan="2">
                                    <div v-for="sub in JSON.parse(question.question_contents)" :key="sub.id" :class="ev_results_hash[sub]?'py-3 border-bottom-dashed':''"  class="d-flex">
                                        <template v-if="ev_results_hash[sub]">   
                                            <div style="width:47.5%">
                                                <div v-for="ev in ev_results_hash[sub].evaluation_results" :key="ev.id" class="mb-3"> 
                                                    <template v-if="ev.satisfied==false">
                                                        <p class="gray-550 mb-1"><b-badge variant="red5" class="mr-2">미충족</b-badge>{{ ev.reverse_name }}</p>  
                                                    </template>
                                                </div>
                                                <p v-if="ev_results_hash[sub].review_comment" class="mb-0 f-90 gray-550"> 실사 확인 내용</p>
                                                <p v-if="ev_results_hash[sub].review_comment" class="bgray-200 mb-2 py-1 px-2 f-95">{{ ev_results_hash[sub].review_comment }} </p>
                                                <p v-if="ev_results_hash[sub].task_comment" class="mb-0 f-90 gray-550"> 개선 권고사항</p>
                                                <p v-if="ev_results_hash[sub].task_comment" class="bgray-200 mb-2 py-1 px-2 f-95">{{ ev_results_hash[sub].task_comment }} </p>

                                            </div>
                                            <div style="width:calc(52.5% - 15px)" class="ml-3">
                                                <b-textarea v-model="action_plan_hash[sub].action_plan" class="mb-3" placeholder="개선을 위한 조치 및 향후 관리 계획을 작성하여 주십시오"
                                                @update="textInputSave(sub)" :disabled="company_action_plan_submitted==true" ></b-textarea>
                                                <div class="d-xl-flex justify-content-xl-between f-90 mb-2">
                                                    <div class="gray-550">개선 목표 기간</div>
                                                    <b-form-radio-group v-model="action_plan_hash[sub].time_horizon" @change="textInputSave(sub)" :disabled="company_action_plan_submitted==true">
                                                        <b-form-radio value="단기">단기 (~ 6개월)</b-form-radio>
                                                        <b-form-radio value="중기">중기 (~ 2년)</b-form-radio>
                                                        <b-form-radio value="장기">장기 (3년 이상)</b-form-radio>
                                                    </b-form-radio-group>
                                                </div>
                                                <p class="mb-0 f-90" :class="save_state[sub]=='저장 중...'?'red':'green'"> <fa v-if="save_state[sub]=='저장 되었습니다.'" icon="check" class="mr-1"></fa> {{ save_state[sub] }}</p> 
                                            </div>
                                        </template>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>  
                </template>                 
            </template>
            <template v-else>
                <b-skeleton height="35px" class="mb-4 "></b-skeleton>
                <b-table-simple class="light-thead table-text-left f-95 mb-5">
                    <b-thead><b-tr>
                        <b-td style="width:20%">평가기준 미충족 문항</b-td> <b-td style="width:38%">미충족 사항 및 현장평가 확인사항</b-td> <b-td>개선계획 작성</b-td>
                    </b-tr></b-thead>
                    <b-tbody>
                        <b-tr><b-td><b-skeleton height="20px" class="mb-4"></b-skeleton></b-td><b-td><b-skeleton height="20px" class="mb-4"></b-skeleton></b-td><b-td><b-skeleton height="20px" class="mb-4"></b-skeleton></b-td></b-tr>
                        <b-tr><b-td><b-skeleton height="20px" class="mb-4"></b-skeleton></b-td><b-td><b-skeleton height="20px" class="mb-4"></b-skeleton></b-td><b-td><b-skeleton height="20px" class="mb-4"></b-skeleton></b-td></b-tr>
                    </b-tbody>
                </b-table-simple>
            </template>
        </div>

        <div class="position-fixed w-100 text-center" style="bottom:30px; left:0; z-index: 1" v-if="isVisible">
            <p class="mb-2 f-90"> <fa icon="info-circle" class="mr-1" ></fa>{{company_action_plan_submitted==true ? '개선계획이 제출되었습니다. 제출 취소시 개선계획을 수정할 수 있습니다.':'개선계획 작성 완료 후 제출하기를 클릭해주세요'}}</p>
            <b-button :variant="company_action_plan_submitted==true?'green':'blue'" class="px-5 f-110" @click="handleSaveSubmit" >{{company_action_plan_submitted==true ? '제출 취소하기':'개선계획 제출하기'}}</b-button>
        </div>
      
</div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
import * as th from 'tree-helper'
import * as ih from '@/util'

export default {
    data() {
        return {
            radio_options: [{text: '단기 (~ 6개월)', value: '단기'}, {text: '중기 (~ 2년)', value: '중기'}, {text: '장기 (3년 이상)', value: '장기'}],
            save_state:{},
            is_pa_loaded: false,
            position: {x:0, y:0},
            is_improvment_category_checked: false
        }
    },
    computed:{
        ...mapState(['campaign_in_progress','company_name','company_id','user_id']),
        ...mapState({
            template_contents: state => state.template_in_progress.template_contents
        }),
        ...mapState('postassess', ['pa_evaluation_results','pa_action_plans','company_action_plan_submitted']),
        ...mapGetters('postassess', ['ev_results_arr', 'ev_results_hash', 'action_plan_hash']),
        isVisible() {
            // console.log(this.position.y/document.body.clientHeight);
            if(this.position.y/document.body.clientHeight <= 0.2) return false
            else return true
        },
    },
    methods:{
        ...mapMutations('score', ['update_temp_target_companies','add_temp_target_companies']),
        ...mapActions('postassess', ['readPAEvaluationResults', 'readPAActionPlans','savePAActionPlan','readPAActionPlansSubmitted','savePAActionPlanSubmit']),
        textInputSave(sub) {
            Vue.set(this.save_state, sub, '저장 중...')
            this.debouncedSave(sub)            
        },
        debouncedSave: debounce(function(sub) {  
            Vue.nextTick(() => {
                    this.savePAActionPlan({
                        campaign_id: this.campaign_in_progress.campaign_id,
                        company_id: this.company_id,
                        sub_code: sub,
                        action_plan: this.action_plan_hash[sub].action_plan,
                        time_horizon: this.action_plan_hash[sub].time_horizon
                    })
                    // console.log(this.save_state[sub]);
                    Vue.set(this.save_state, sub, '저장 되었습니다.')
                })
            },
            //사용자 입력을 기다리는 시간
            800
        ),
        initiateSaveState(){
            for (let i=0; i<this.pa_evaluation_results.length; i++){
                if (this.action_plan_hash[this.pa_evaluation_results[i].sub_code].action_plan!=''){
                    Vue.set(this.save_state, this.pa_evaluation_results[i].sub_code, '저장 되었습니다.')
                }
                else Vue.set(this.save_state, this.pa_evaluation_results[i].sub_code, '')
            }
        },
        handleSaveSubmit(){     
            let temp_submit = true
            if(this.company_action_plan_submitted==true){
                temp_submit = false
            }      
            this.savePAActionPlanSubmit({
                campaign_id: this.campaign_in_progress.campaign_id,
                company_id: this.company_id,
                submit: temp_submit
            })
        },
        has_improvment_contents(q_code){
            let idx = this.ev_results_arr.findIndex(w => w.question_code == q_code)
            if(idx > -1) return true
            else return false
        },
        has_improvment_category(index){
            let question_list = this.template_contents[index].children
            for (let x=0; x<question_list.length; x++){
                let jdx = this.pa_evaluation_results.findIndex(w => w.question_code == question_list[x].question_code) 
                this.is_improvment_category_checked = true
                if(jdx>-1) return true
                else return false
            }
        },
    },
    async created(){
        await this.readPAEvaluationResults({campaign_id: this.campaign_in_progress.campaign_id, company_id: this.company_id})
        await this.readPAActionPlans({campaign_id: this.campaign_in_progress.campaign_id, company_id: this.company_id})
        await this.readPAActionPlansSubmitted({campaign_id: this.campaign_in_progress.campaign_id, company_id: this.company_id})
        this.initiateSaveState()
        this.is_pa_loaded = true

        if (!this.$isServer) { // 제출하기 버튼 위치 파악을 위한 scroll listener 장치
            this._scrollListener = () => {
                this.position = {
                    x: Math.round(window.pageXOffset),
                    y: Math.round(window.pageYOffset)
                }
            }
            this._scrollListener()
            window.addEventListener('scroll', this._scrollListener)
        }
    },
    beforeDestroy() {
        // Detach the listener when the component is gone
        window.removeEventListener('scroll', this._scrollListener)
    }


}
</script>

<style>

</style>