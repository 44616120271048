<script>
import { HorizontalBar, mixins } from 'vue-chartjs'

export default {
    extends: HorizontalBar,
    mixins: [mixins.reactiveProp],
    props: {
        chartData: {
            type: Object,
            default: () => ({})
        },
        options: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
        }
    },
    mehtods:{
    },
    mounted() {
        this.renderChart(this.chartData, this.options)
    }
}
</script>