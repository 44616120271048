<template>
<div>
<b-container fluid class="px-0">
    <b-row class="mx-0 px-0">
        <b-col id="header" class="px-0">
            <router-view name="provider_header"></router-view>
        </b-col>
    </b-row>
    <b-row class="mx-0 align-items-stretch w-100">
        <b-col class="mx-0 px-0 text-left">
            <router-view :question_code="question_code"></router-view>
        </b-col>
    </b-row>
    <b-row class="m-0">
        <b-col id="footer" class="px-0">
            <router-view name="provider_footer"></router-view>
        </b-col>
    </b-row>
</b-container>
</div>
</template>

<script>

export default {
    data () {
        return {
            is_sidebar_collapsed: false,
            is_side_collapsed: false
        }
    },
    props: {
        question_code: {type: String, default: ''}
    },
    methods: {
        toggleSidebar(new_value){
            this.is_sidebar_collapsed = new_value
        },
        toggleSide(new_value) {
            this.is_side_collapsed = new_value
        },
        makeToast(title, contents){
            this.$bvToast.toast(contents, {
                title: title,
                toaster: 'b-toaster-bottom-center',
                autoHideDelay: 1000,
                solid: true,
                appendToast: true,
                variant: 'success'
            })
        },
    },
    created () {
        EventBus.$on('toggle-sidebar',this.toggleSidebar)
        EventBus.$on('toggle-side', this.toggleSide)
    },
    computed: {
        user_name() {
            return this.$store.getters.getUserName
        },
        company_name() {
            return this.$store.getters.getCompanyName
        },
        e_mail(){
            return this.$store.getters.getEMail
        },
        isAuthenticated(){
            return this.$store.getters.isAuthenticated
        },
        isLoggedIn() {
            return this.$store.getters.isLoggedIn
        },
        campaign_in_progress() {
            return this.$store.state.campaign_in_progress
        },
        template_in_progress() {
            return this.$store.state.template_in_progress
        },
        template_code() {
            return this.campaign_in_progress.template_code
        },
    },
    watch: {
        e_mail: function () {
            if (this.e_mail=='undefined') {
                alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                this.$store.dispatch('logout').then(() => { window.location.href = this.$store.state.frontend_host + '/login' })
                return
            }
        },
        //template_code: {
        //    immediate: true,
        //    handler(value, old_value) {
        //        if(this.campaign_in_progress.template_code == '' || this.campaign_in_progress.campaign_id == '')
        //        { this.$router.push({name:'Main'}) }
        //        else{
        //            this.$store.dispatch('readTemplateInProgress', this.template_code)
        //        }
        //    }
        //}
    },
    components: {

    }
}
</script>

<style scoped>
.ih-tree{
    text-align: left;
}

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}


</style>
