<template> 
<div>   
        <div class="mt-3 mb-5 border-0 text-left">
            <b-row class="">
                <b-col class="col-12 col-lg-3"><h4 class="gray-700"> 참여자 관리 </h4></b-col>
                <b-col class="col-12 col-lg-9 border-left">
                    <span class="f-105">{{$t(`문항별 참여자 리스트입니다. 캠페인 참여자 추가 및 삭제, 제한 설정은 각 기업의 캠페인 리더 계정으로 설정 가능합니다. 캠페인 참여자 신규 등록은`)}}
                    '<span class="cursor hover-bottomline" @click="openUserRegistrationModal">{{$t(`'신규 참여자 등록하기'`)}}</span>{{$t(`에서 가능합니다.`)}}</span>

                    <div v-show="selected_company.admin_users!=undefined" class="mt-2 f-95">
                        <div v-if="isAdminDefined()" class=" gray-600">
                            * {{ company_name }} {{$t(`캠페인 리더`)}}:
                            <span v-for="item in selected_company.admin_users" :key="item.user_id" class="mb-1"> <b> {{ item.admin_name }} </b> <span class="fw-300"> ({{ item.e_mail }}) </span></span>
                            <div class="mt-2"> * {{$t(`캠페인 리더 계정 변경이 필요하신 경우 시스템 관리자에게 문의하여 주십시오.`)}} <b-badge variant="btn" class="ml-1" @click="showNeepHelp">{{$t(`도움이 필요하신가요?`)}}</b-badge> </div>
                        </div> 
                        <div v-else class="mb-1 bgray-300 rounded">
                            <fa icon="info-circle" class="mr-1"></fa> {{ company_name }} 의 캠페인 리더 계정이 등록되어 있지 않습니다. 시스템 관리자에 문의하여 주십시오. <b-badge variant="btn" class="ml-1" @click="showNeepHelp">도움이 필요하신가요?</b-badge>
                        </div>                
                    </div>
                </b-col>
            </b-row>
        </div>
        
        <div v-show="auth_loaded">
            <!-- 권한 모두 부여/제거 기능은 1개 기업당 참여자 10인 이상의 경우에만 적용 -->
            <div v-if="is_admin >= 1" class="mb-3 mx-0 d-flex justify-content-between align-items-center"> 
                    <b-button @click="openUserRegistrationModal" variant="dark-border" size="sm"><fa icon="user-plus" class="mr-1"></fa>{{$t(`신규 참여자 등록하기`)}}</b-button>
                    <div v-if="is_saving_auth==false">   
                        <b-button @click="addAuthAll" variant="blue-border" class="mr-2" size="sm"><fa icon="plus" class="mr-1"></fa> {{$t(`모든 문항에 모든 사용자 추가`)}} </b-button>
                        <b-button @click="removeAuthAll" variant="blue-border" class="mr-2" size="sm"><fa icon="minus" class="mr-1"></fa> {{$t(`모든 사용자 제거`)}}</b-button>
                    </div>
                    <div v-else>
                        <span class="gray-500 f-90">모든 권한 조정은 시간이 소요됩니다. 잠시 기다려 주십시오..</span><b-spinner small class="gray-500 ml-2" label="저장 중입니다."></b-spinner> 
                    </div>
            </div>
        </div>
        <b-modal ref="user-registration" size="lg" title="참여자 등록하기" hide-footer> <user-registration-form :company_users="company_users"></user-registration-form> </b-modal>
        
    <b-container fluid class="px-0 participants-container">
        <b-row class="mx-0 bgray-700 gray-300" style="min-width:400px;">
            <b-col class="col-6 col-sm-5 py-1 text-center"> 문항 </b-col>
            <b-col class="col-5 py-1 text-center"> 참여자 </b-col>
            <b-col class="col-1 py-1 px-0 text-center" v-if="is_admin >= 1 "> 관리 </b-col>
            <!-- <b-col class="col col-sm-2 py-1 text-center"> 진행현황 </b-col> -->
        </b-row>

        <div>
            <Tree :data="question_auth_list" :prevent-select="false" :draggable="false" :space="1" :indent="0" class="pb-2 clearfix">
            <template v-slot="{data}">
                <b-row v-if="auth_loaded"
                :class="[`node-level-${data._vm.level}`, 'mx-0 text-left border-bottom d-flex align-items-stretch']" style="min-width:400px;">
                    <!--질문명-->
                    <b-col :class="[`node-level-${data._vm.level}-sub`, 'col-6 col-sm-5 py-1 pr-0 d-flex align-items-center']"> {{ data.question_name }} </b-col> 
                    <!--보기/작성권한-->
                    <b-col class="col-5 py-1 d-flex justify-content-center align-items-center f-90"> 
                        <div v-if="!data.children || !data.children.length">
                            <span v-for="(item, index) in data.writable_users" :key="item.id">{{ item.user_name }}<span v-if="data.writable_users.length > 1 && data.writable_users.length-2>=index||data.visible_only.length>1">, </span></span>
                            <span v-for="(item, index) in data.visible_only" :key="item.id"> {{ item.user_name }}(작성제한)<span v-if="data.visible_only.length > 1 && data.visible_only.length-2>=index">, </span></span>
                        </div> 
                    </b-col>
                    <!--관리-->
                    <b-col class="col-1 d-flex justify-content-center align-items-center px-0" v-if="is_admin >= 1" > 
                        <div v-if="!data.children || !data.children.length">
                            <b-button @click="openAuthModal(data)" variant="icon" :id="data.question_name"><fa icon="user-check"></fa></b-button>
                        <b-tooltip :target="data.question_name" triggers="hover">문항별 참여자 관리</b-tooltip>
                        </div>
                        
                        <!--참여자 수정 모달-->
                        <b-modal :id="`auth-modal-${data._id}`" size="lg" :title="`'${data.question_name}' 문항 참여자 관리`" hide-footer>
                            <div class="modal-table">
                            <table class="f-90 table table-hover">
                                <thead><tr class="border-top-bottom-bold">
                                    <td>이름</td><td>이메일</td><td>소속</td><td>직접 참여</td><td>보기만 가능<br>(답변작성 불가)</td>
                                </tr></thead>
                                <tbody><tr v-for="item in company_users" :key="item.id" class="border-bottom">
                                    <td> {{ item.user_name }}</td>
                                    <td> {{ item.e_mail }} </td>
                                    <td> {{ item.company_name }}</td>                                    
                                    <td> <b-form-checkbox v-model="item[`${data._id}-writable`]" :value="true" :unchecked-value="false" @change="makeChangeAuthWritable(data, item, !item[`${data._id}-writable`])"></b-form-checkbox> </td>
                                    <td> <b-form-checkbox v-model="item[`${data._id}-visible`]" :value="true" :unchecked-value="false" @change="makeChangeAuthVisible(data, item, !item[`${data._id}-visible`])"> </b-form-checkbox> </td>
                                </tr></tbody>
                            </table>
                            </div>
                            <div class="mt-3">
                                <b-button @click="$bvModal.hide(`auth-modal-${data._id}`)" variant="dark" class="float-right ml-2 f-90">닫기</b-button>                                
                                <b-button @click="handleOK(data, `auth-modal-${data._id}`)" variant="blue" class="float-right f-90">저장</b-button>
                            </div>
                        </b-modal>
                    </b-col>
                    <!--진행률-->
                    <!-- <b-col class="col-2 d-flex justify-content-center align-items-center">
                   
                    <b-badge v-if="checkCompleted(data)!=''" :class ="[ checkCompleted(data) != '완료' ? 'on-completed':'on-track','fw-500']"> {{ checkCompleted(data) }} </b-badge>
                    <b-badge v-else style="display: none;"></b-badge>
                    
                    </b-col> -->
                </b-row>
                <b-row v-else>
                    <b-col class="text-center">
                        <b-spinner class="my-5" label="user loading"></b-spinner>
                    </b-col>
                </b-row>

            </template>
            </Tree> 
        </div>
    </b-container>
</div>
</template>
<script>
import {DraggableTree} from 'vue-draggable-nested-tree'
import * as th from 'tree-helper'
import * as ih from '../../util'
//import axios from 'axios'
import UserRegistrationForm from '../managing_user/UserRegistrationForm'

export default {
    data() {
        return {
            //question_auth_list: [],
            // question_auth : { 
            //   question_code,
            //   question_name,
            //   visible_users: []
            //   writable_users: []
            // }
            company_users: [],

            auth_loaded: false,
            is_saving_auth: false,
            target_company_id: ''
            // is_editable: false,
        }
    },
    computed: {
        user_id() {
            return this.$store.state.user_id
        },
        campaign_id() {
            return this.$store.state.campaign_in_progress.campaign_id
        },
        company_id() {
            return this.$store.state.company_id
        },
        company_name() {
            return this.$store.state.company_name
        },        
        template_code() {
            return this.$store.state.template_in_progress.template_code
        },
        template_contents() {
            return this.$store.state.template_in_progress.template_contents
        },
        admin_level() {
            return this.$store.getters.getAdminLevel
        },
        is_admin() {
            return this.$store.getters.isAdmin
        },
        selected_company(){
            return this.$store.state.selected_company
        },
        question_auth_list: {
            get(){
                return this.$store.state.template_in_progress.template_contents
            },
            set(newValue){
                let new_template = {
                    template_name: this.$store.state.template_name,
                    template_code: this.$store.state.template_code,
                    template_contents: newValue
                }
                this.$store.commit('update_template_in_progress', new_template)
            }
        }
    },
    methods: {
        readQuestionAuthUsersByCompany(){
            let question_list = []
            th.breadthFirstSearch(this.question_auth_list, node => {
                if(node.children && node.children.length) {
                    //자식이 있는 노드인 경우 아무것도 하지 않음
                }
                else {
                    question_list.push(node.question_code)
                }
            })

            const path = this.$store.state.backend_host + '/read_question_auth_users_by_company_name'

            return axios.get(path, { params: {
                campaign_id: this.campaign_id,
                company_name: this.company_name, 
                question_code_array: JSON.stringify(question_list)
            }})
            .then(response=>{
                let result = response.data
                th.breadthFirstSearch(this.question_auth_list, node => {
                    if(node.children && node.children.length) {
                    //자식이 있는 노드인 경우 아무것도 하지 않음
                    }
                    else {
                        let idx = result.findIndex(item => item.question_code == node.question_code)
                        if(idx >-1){
                            node.question_name = result[idx].question_name
                            node.visible_users = result[idx].visible_users.sort((a, b) => a.e_mail.localeCompare(b.e_mail))
                            node.writable_users = result[idx].writable_users.sort((a, b) => a.e_mail.localeCompare(b.e_mail))
                            let visible_only = result[idx].visible_users.filter(val => {return !result[idx].writable_users.includes(result[idx].writable_users.find(item => item.e_mail == val.e_mail))})           
                            Vue.set(node, 'visible_only', visible_only)
                            this.treatVisibility(node)
                        }
                    }
                })
            })
            .catch(error=> {
                console.log(error)
            })
            .finally( () => {
                this.auth_loaded = true             
            })
        },
        saveQuestionAuths(){
            let promises = []
            const path = this.$store.state.backend_host + '/save_question_auth'

            this.is_saving_auth = true
            this.$refs['save_button'].blur()

            //question_auth_list의 내용을 읽어서 visible_users와 writable_users_list를 promise 배열에 추가
            th.breadthFirstSearch(this.question_auth_list, node => {
                if(node.children && node.children.length){
                    //자식이 있는 노드는 스킵
                }
                else {
                    this.company_users.forEach( item => {
                        let formData = new FormData()
                        formData.append('campaign_id',this.campaign_id)
                        formData.append('question_code', node.question_code)
                        formData.append('user_id', item.user_id) 

                        //노드의 visible_users에 일치하는 user id가 있는지 검사
                        let idx = node.visible_users.findIndex( user1 => { return user1.user_id == item.user_id})
                        if(idx > -1) formData.append('visible',true)
                        else formData.append('visible',false)
                    
                        //마찬가지로 writable_users 검사
                        let index = node.writable_users.findIndex( user1 => { return user1.user_id == item.user_id })
                        if(index > -1) formData.append('writable', true)
                        else formData.append('writable', false)
                
                        promises.push(
                            axios.post(path, formData)
                            .catch(error => {
                                console.log(error)
                            })
                        )
                    })
                }   
            })
            
            return Promise.all(promises)
            .finally( () => {
                EventBus.$emit('toast-success','성공','질문별 유저 접근권한을 저장하였습니다.')
                this.is_saving_auth = false
                this.$emit('edit-complete', false)
                this.readQuestionAuthUsersByCompany()
            })
        },
        readCompanyUsers() {
            const path = this.$store.state.backend_host + '/read_users_by_company'

            axios.get(path, { params: {
                                company_id:this.company_id
                                }
            })
            .then(response => {
                // console.log(response.data.users);
                this.company_users = response.data.users
            })
            .catch(error => {
                console.log(error)
            })
        },
        checkUserQuestionVisible(node,user_id){
            if(node.visible_users) {
               let idx = node.visible_users.findIndex( item => { return item.user_id == user_id } )
               if(idx > -1) return true
            }
            else return false
        },
        checkUserQuestionWritable(node,user_id){
            if(node.writable_users) {
               let idx = node.writable_users.findIndex( item => { return item.user_id == user_id } )
               if(idx > -1) return true
            }
            else return false
        },
        openAuthModal(node){        
            if(this.company_users){
                this.company_users.forEach(item => {
                    if(this.checkUserQuestionVisible(node,item.user_id) == true) {
                        Vue.set(item, `${node._id}-visible`, true)
                    }
                    else{
                        Vue.set(item, `${node._id}-visible`, false)
                    }
                })

                this.company_users.forEach(item => {
                    if(this.checkUserQuestionWritable(node,item.user_id) == true) {
                        Vue.set(item, `${node._id}-writable`, true)
                    }
                    else{
                        Vue.set(item, `${node._id}-writable`, false)
                    }
                })

            }
            this.$bvModal.show(`auth-modal-${node._id}`)
        },
        makeChangeAuthVisible(node, user, auth){
            Vue.nextTick( () => {
                //auth는 체크박스 선택에 따라 true 또는 false값을 전달 받는데,
                //이를 토대로 1. node 의 visible 에 있는 유저를 찾아서 삭제하거나, 추가해야함(동기화) 
                if(auth == true){
                    //visible 유저에 추가해야 함
                    if(node.visible_users) {
                        //노드의 Visible User 목록에 해당 유저가 있는지 확인하고, 없으면 추가. 
                        let idx = node.visible_users.findIndex( item => { return item.user_id == user.user_id })
                        if(idx > -1) {
                            console.log('추가실패: visible_users목록내에 해당 유저가 이미 있습니다.')
                            return
                        }
                        else {
                            node.visible_users.push(user)
                            console.log('추가했습니다')
                        }
                    }
                    else {
                        console.log('추가실패: 질문노드에 visible_users가 정의되어 있지 않습니다.')
                    }
                }
                else{
                    //visible 유저에서 제거해야 함
                    if(node.visible_users) {
                        //노드의 visible User 목록에 해당 유저가 있는지 확인하고, 있으면 제거. 이때 Writable 권한도 함께 제거해야함
                        let idx = node.visible_users.findIndex( item => { return item.user_id == user.user_id })
                        if(idx > -1) {
                            node.visible_users.splice(idx, 1)
                            let index = this.company_users.findIndex ( item => { return item.user_id == user.user_id })
                            this.company_users[index][`${node._id}-writable`] = false
                            this.makeChangeAuthWritable(node, user, false)
                            console.log('제거했습니다')
                        }
                        else {
                            console.log('제거실패: visible_users 목록내에 해당 유저가 없었습니다.')
                            return
                        }
                    }
                    else {
                        console.log('제거실패: 질문노드에 visible_users가 정의되어 있지 않습니다. ')

                    }
                }
            })
        },
        makeChangeAuthWritable(node, user, auth){
            Vue.nextTick( () => {
                //auth는 체크박스 선택에 따라 true 또는 false값을 전달 받는데,
                //이를 토대로 1. node 의 writable 에 있는 유저를 찾아서 삭제하거나, 추가해야함(동기화) 
                if(auth == true){
                    //writable 유저에 추가해야 함. 단, 이때 visible에도 함께 추가해야함.
                    if(node.writable_users) {
                        //노드의 writable User 목록에 해당 유저가 있는지 확인하고, 없으면 추가. 
                        let idx = node.writable_users.findIndex( item => { return item.user_id == user.user_id })
                        if(idx > -1) {
                            console.log('추가실패: writable_users목록내에 해당 유저가 이미 있습니다.')
                            return
                        }
                        else {
                            node.writable_users.push(user)
                            let index = this.company_users.findIndex ( item => { return item.user_id == user.user_id })
                            this.company_users[index][`${node._id}-visible`] = true 
                            this.makeChangeAuthVisible(node, user, true)
                            console.log('추가했습니다')
                        }
                    }
                    else {
                        console.log('추가실패: 질문노드에 visible_users가 정의되어 있지 않습니다.')
                    }
                }
                else{
                    //wirtable 유저에서 제거해야 함
                    if(node.writable_users) {
                        //노드의 Writable User 목록에 해당 유저가 있는지 확인하고, 있으면 제거.
                        let idx = node.writable_users.findIndex( item => { return item.user_id == user.user_id })
                        if(idx > -1) {
                            node.writable_users.splice(idx, 1)
                            console.log('제거했습니다')
                        }
                        else {
                            console.log('제거실패: writable_users 목록내에 해당 유저가 없었습니다.')
                            return
                        }
                    }
                    else {
                        console.log('제거실패: 질문노드에 writable_users가 정의되어 있지 않습니다. ')
                    }
                }
            })
        },
        addAuthAll(){
            this.is_saving_auth=true
            const path = this.$store.state.backend_host + '/add_all_auth'

            let formData = new FormData()
            formData.append('campaign_id', this.campaign_id)
            formData.append('company_id', this.company_id)
            
            return axios.post(path,formData)
            .then(response=>{
                this.is_saving_auth = false
                this.$emit('edit-complete', false)
                this.readQuestionAuthUsersByCompany()
                .then(()=>{
                    EventBus.$emit('toast-success','성공','질문별 유저 접근권한을 저장하였습니다.')
                    this.is_saving_auth = false
                })
            })
            .catch(error=>{
                console.log(error)
            })

        },
        removeAuthAll(){
            this.is_saving_auth = true
            const path = this.$store.state.backend_host + '/remove_all_auth'

            let formData = new FormData()
            formData.append('campaign_id', this.campaign_id)
            formData.append('company_id', this.company_id)
            
            return axios.post(path,formData)
            .then(response=>{
                EventBus.$emit('toast-success','성공','질문별 유저 접근권한을 삭제하였습니다.')
                this.is_saving_auth = false
                this.$emit('edit-complete', false)
                this.readQuestionAuthUsersByCompany()
                .then(()=>{
                    EventBus.$emit('toast-success','성공','질문별 유저 접근권한을 저장하였습니다.')
                    this.is_saving_auth = false
                })
            })
            .catch(error=>{
                console.log(error)
            })
        },
        saveQuestionAuth(node){
            //단일 노드만 저장 
            const path = this.$store.state.backend_host + '/save_question_auth'
            let promises = []

            console.log('node') 
            console.log(node)

            //company_users에 속한 user의 user_id별로 전체 배열 순회하면서, 
            //node에서 visible_user와 writable_user 배열안에 있는지를 보고 user_id별 formDataset 구성. 
            //이러면 false일 경우 false라고 정확히 남길 수 있음 

            this.company_users.forEach( item => {
                let formData = new FormData()
                formData.append('campaign_id',this.campaign_id)
                formData.append('question_code', node.question_code)
                formData.append('user_id', item.user_id) 

                //노드의 visible_users에 일치하는 user id가 있는지 검사
                let idx = node.visible_users.findIndex( user1 => { return user1.user_id == item.user_id})
                if(idx > -1) formData.append('visible',true)
                else formData.append('visible',false)

                //마찬가지로 writable_users 검사
                let index = node.writable_users.findIndex( user1 => { return user1.user_id == item.user_id })
                if(index > -1) formData.append('writable', true)
                else formData.append('writable', false)

                promises.push(
                    axios.post(path, formData)
                    .catch(error => {
                        console.log(error)
                    })
                )                        
            })
            return Promise.all(promises)
            .then( results => {
                results.forEach( response => {
                    let x = response.data 
                    th.breadthFirstSearch(this.question_auth_list, node=>{
                        if(node.question_code == x.question_code){
                            let i = node.visible_users.findIndex( user1 => { return user1.user_id == x.user_id })
                            if(i > -1){
                                if(x.visible == false) node.visible_users.splice(i, 1)
                            }
                            else{
                                if(x.visible == true) node.visible_users.push(x.user_id)
                            }
                            let j = node.writable_users.findIndex( user1 => { return user1.user_id == x.user_id})
                            if(j > -1){
                                if(x.writable == false) node.writable_users.splice(j, 1)
                            }
                            else{
                                if(x.writable == true) node.writable_users.push(x.user_id)
                            }

                            let visible_only = node.visible_users.filter(val => {return !node.writable_users.includes(node.writable_users.find(item => item.user_id == val.user_id))})
                            node.visible_only=visible_only

                            if(x.user_id == this.user_id){
                                node.visible = x.visible
                                node.writable = x.writable
                                
                                let y = node
                                while((y.visible == true || y.writable == true) && y.parent){
                                    y.parent.visible = y.visible
                                    y.parent.writable = y.writable
                                    y=y.parent
                                }
                            }
                        }
                    })
                })
            })
            .finally( () => {
                //Template 권한 갱신
                //this.$store.dispatch('readTemplateInProgress',this.template_code)
                //.then( () => {
                //    this.initiateData()
                //})
            })
        },
        checkCompleted(node){
            if(node.children && node.children.length) return ''

            if(node.is_completed == 'true') return '완료'
            else if(node.is_completed == 'false') return '진행중'
            else return ''
        },
        handleOK(node, id){
            this.saveQuestionAuth(node)
            .then( ()=> {
                this.readQuestionAuthUsersByCompany()
                this.$bvModal.hide(id)
            })
        },
        initiateData(){
            this.readQuestionAuthUsersByCompany()
            this.readCompanyUsers()
            //console.log(this.question_auth_list);
        },
        openUserRegistrationModal(){    
            this.$refs['user-registration'].show()
        },
        isAdminDefined(){
            if(this.selected_company.hasOwnProperty('admin_users'))
                if(this.selected_company.admin_users.length != 0) return true
            else return false
        },
        showNeepHelp() {
            this.$emit('show-need-help')
        },
        treatVisibility(node){
            //node를 받아서, node visible/writable user에 내가 속해있는지 본 뒤 있을 경우 tree 위쪽으로 전파

            //변화된 유저가 나일 경우 처리!
            let vidx = node.visible_users.findIndex(item => {return item.user_id == this.user_id})
            if(vidx > -1) {
                Vue.set(node, 'visible', true)
            }
            else{
                Vue.set(node, 'visible', false)
            }

            let widx = node.visible_users.findIndex(item => {return item.user_id == this.user_id})
            if(widx > -1){
                Vue.set(node, 'writable', true)
            }
            else{
                Vue.set(node, 'writable', false)
            }

            if(node.parent != undefined && (node.visible == true || node.writable == true)) this.transperParent(node.parent, true, node.writable)
        },
        transperParent(node, visible, writable){
            Vue.set(node, 'visible', visible)
            Vue.set(node, 'writable', writable)

            if(node.parent != undefined && (node.visible == true || node.writable == true)) this.transperParent(node.parent, true, node.writable)
        },
        readCompanyUsers() {
            const path = this.$store.state.backend_host + '/read_users_by_company'

            axios.get(path, { params: {
                                company_id:this.company_id
                                }
            })
            .then(response => {
                // console.log(response.data);
                this.company_users = response.data.all_users
                this.$store.commit('update_selected_company', response.data)
            })
            .catch(error => {
                console.log(error)
            })
        },
    },
    created() {
        this.initiateData()
        
        // if(this.company_name!=undefined){
        //     const path = this.$store.state.backend_host + '/read_users_by_company'
        //     let result = {}
        //     return axios.get(path, { params: {
        //         company_id: this.company_id
        //     }})
        //     .then( response => {
        //         console.log(response.data);
        //             result = response.data
        //             let all_users = []
        //             let admin_users = []
        //             result.users.forEach(item => {
        //                 let new_user = {
        //                     e_mail : item.e_mail,
        //                     name : item.user_name,
        //                     user_id: item.id,
        //                     is_admin: item.is_admin
        //                     }
        //                 all_users.push(new_user)

        //                 if(item.is_admin >= 1) { 
        //                     let new_admin = {
        //                         admin_id: item.user_id,
        //                         e_mail: item.e_mail,
        //                         admin_name: item.user_name
        //                         }
        //                     admin_users.push(new_admin) 
        //                 }
        //             })

        //             let new_selected_company = {
        //                 company_id: result.company_id,
        //                 company_name: result.company_name, 
        //                 company_code: result.company_code,
        //                 company_address: result.company_address,
        //                 company_tel: result.company_tel,
        //                 other_info: result.other_info,
        //                 admin_users: admin_users,
        //                 all_users: all_users
        //             }
        //             this.$store.commit('update_selected_company', new_selected_company)
        //     })
        // }
    },
    components: {
        Tree: DraggableTree,
        UserRegistrationForm,
    }

    
}
</script>
<style scoped>

.participants-container{
    overflow-x: scroll;
}

.on-track{
    background-color: var(--gray-600);
    color: #ffffff;
}
.on-completed {
    background-color: var(--blue);
    color: #ffffff;   
}
.node-level-1 {
    background-color: var(--gray-300);
    font-weight: 600;
}
.node-level-2 {
    background-color: var(--gray-200);
    font-weight: 500;
}
.node-level-1-sub {
    padding-left: 1rem!important;
}
.node-level-2-sub {
    padding-left: 1.75rem!important;
}

</style>
