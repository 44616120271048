<template>
<div class="bgray-100 text-left min-vh-100">
    <b-navbar toggleable="sm" type="dark" class="bgray-100 header-nav d-flex justify-content-between align-items-center">
        <b-navbar-brand><router-link to="/"><b-img class="header-logo" src="@/assets/Tier Zero_basic.svg"></b-img></router-link></b-navbar-brand>
        <b-dropdown size="sm" variant="dark-border" class="" >
            <template #button-content> <fa icon="globe"></fa> {{selected_lang}} </template>
            <b-dropdown-item  @click="$i18n.locale=lang.value" v-for="lang in langs" :key="lang.id"> {{ lang.text }} </b-dropdown-item>
        </b-dropdown>
        <!-- <b-button variant="dark-border" @click="$router.push({name: 'Login'})" class="ml-2" ><fa icon="caret-left" class="mr-2"></fa>{{ $t(`첫 화면으로`)}}</b-button>  -->
    </b-navbar>

    <b-container class="d-flex flex-column align-items-center justify-content-center h-80vh" :class="isIE==true?'pt-5':''">
        <h2 class="mb-5">{{$t(`가입 및 신청`)}}</h2>
        <b-row no-gutters class="w-100 mb-5 align-items-end">
            <b-col cols="0" md="2" xl="3"></b-col>
            <b-col cols="4" md="3" xl="2" :class="register_step==0?'border-bottom-dark-bold fw-500 f-120 gray-900':'border-bottom-dark f-105'" class="mr-3"> <span class="f-90">Step 1.</span><br> {{$t(`기업 정보`)}} </b-col>
            <b-col cols="4" md="3" xl="2" :class="register_step==1?'border-bottom-dark-bold fw-500 f-120 gray-900':'border-bottom-dark f-105'" class="mr-3"> <span class="f-90">Step 2.</span><br>{{$t(`담당자 정보`)}} </b-col>
            <b-col cols="4" md="3" xl="2" :class="register_step==2?'border-bottom-dark-bold fw-500 f-120 gray-900':'border-bottom-dark f-105'" class="mr-3"> <span class="f-90">Step 3.</span><br> {{$t(`신청완료`)}} </b-col>
            <b-col cols="0"  md="2" xl="3"></b-col>
        </b-row>        

        <b-row no-gutters class="w-100 mb-5 align-items-end">
            <b-col cols="0" md="2" xl="3"></b-col>
            <b-col cols="12" md="8" xl="6" class="ml-3">
                <b-card class="px-lg-3 px-lg-4 py-3">
                    <template v-if="register_step==0">
                        <div class="pb-1">{{$t(`기업명`)}}</div>
                        <b-form-input v-model.trim="company_name" :placeholder="$t(`기업명`)" autofocus></b-form-input>
                        <!-- <template v-if="$i18n.locale=='ko'"> -->
                            <div class="pt-3 pb-1">{{project_code=='hyundai22saq'?byLang('사업자등록번호/현대차 협력사 코드','Hyundai Vendor Code','供应商代码(Vendor Code)'):$t('사업자등록번호')}} 
                                <!-- <b-button v-if="$i18n.locale=='en'" v-b-modal.duns-number-info class="py-0 px-1 ml-1" variant="dark" size="sm"><fa icon="info-circle"></fa></b-button> 
                                <b-modal id="duns-number-info" title="Vendor Code?">
                                    <div class="pb-3 fw-500"> D‑U‑N‑S Number is a unique nine-digit identifier for businesses issued by The Dun & Bradstreet.</div>
                                    <p> Please enter the DUNS number based on the head office. </p>
                                    If you don't have any DUNS number, please contact us. <a href="mailto:info@qesg.co.kr">info@qesg.co.kr</a>
                                </b-modal> -->
                            </div>
                            <b-form-input v-model.trim="company_code" 
                            :placeholder="project_code=='hyundai22saq'?byLang('[중요]해외법인의 경우, 현대차 협력사 코드(vendor code) 입력','Vendor code granted by Hyundai Motor Company','现代汽车公司授予的供应商代码'):$t('사업자등록번호')"></b-form-input>
                        <!-- </template> -->
                        <div class="pt-3 pb-1">{{$t(`프로젝트 코드`)}}</div>
                        <b-form-input v-model.trim="project_code" :placeholder="$t(`안내받은 코드 입력`)" :state="project_code_state" aria-describedby="project-code-validation" @keyup.enter="handleNext"></b-form-input>
                        <b-form-invalid-feedback id="project-code-validation">
                        {{$t(`프로젝트 코드를 정확히 입력하여 주십시오. 안내받으신 프로젝트 코드가 유효하지 않을 경우`)}} <a href="mailto: info@qesg.co.kr">{{$t(`문의하여`)}}</a> {{$t(`주십시오`)}}.
                        </b-form-invalid-feedback>
                    </template>
                    <template v-else-if="register_step==1">
                        <template v-if="company_already_registered==true">
                            <div class="py-2">
                                <h4 class="mb-45">'{{ company_name }}' {{$t(`기업은`)}} <br>'{{byLang(campaign_name, campaign_name_e, campaign_name_c)}}'( {{ project_code_duplicated!=''? project_code_duplicated : project_code }}) {{$t(`평가에 이미 신청이 되어있습니다.`)}} </h4>
                                <div class="mb-2">{{$t(`신청된 정보를 확인하여 주십시오.`)}} </div>   
                                <b-card class="bgray-200 mb-5">
                                    {{$t(`신청인`)}}: <b>{{requested_e_mail}}</b><br> {{$t(`신청일시`)}}:  <b>{{ requested_time_stamp }}</b>
                                </b-card>                       
                                <h6 v-if="project_code_duplicated==''"> {{ $i18n.locale=='ko'?company_name :'' }} {{$t(`소속으로 회원가입을 원하실 경우, 이메일 주소를 입력해주세요.`)}}</h6>
                                <div v-else>
                                    <h6 class="blue fw-400"> '{{ project_code }}' 코드로 ESG 평가를 신청하려면 <a href="mailto: info@qesg.co.kr" class="text-underline fw-600">{{$t(`문의하여`)}}</a> {{$t(`주십시오`)}}.</h6>
                                    <a href="mailto: info@qesg.co.kr"> 문의처: info@qesg.co.kr </a>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="mb-4 "> {{$t(`기업명`)}}: <span class="teal7">{{ company_name }}</span> <br> {{$t(`사업자등록번호`)}}: <span class="teal7">{{company_code}}</span> <br> {{$t(`참여 평가`)}}: <span class="teal7">{{byLang(campaign_name, campaign_name_e, campaign_name_c)}}</span> </div>
                        </template>
                        <div class="pb-1" autofocus ><span v-if="user_checked==true">*</span> {{$t(`이메일 주소`)}}</div>
                        <b-form-input v-model.trim="e_mail" placeholder="email@email.com"></b-form-input>
                        <template v-if="user_checked==true">
                            <div class="pt-3 pb-1">* {{$t(`성함`)}}</div>
                            <b-form-input v-model.trim="user_name" :placeholder="$t(`성함`)"></b-form-input>
                            <div class="pt-3 pb-1">{{$t(`연락처`)}}</div>
                            <b-form-input v-model.trim="tel_office" placeholder="+82)000-0000-0000"></b-form-input>
                            <div class="pt-3 pb-1">* {{$t(`비밀번호`)}}</div>
                            <b-form-input v-model.trim="password" :placeholder="$t(`4자리 이상`)" type="password"></b-form-input>
                            <div class="pt-3 pb-1">* {{$t(`비밀번호 확인`)}}</div>
                            <b-form-input v-model.trim="password_2" :placeholder="$t(`비밀번호 한번 더 입력`)" type="password" aria-describedby="password_check_feedback" :state="password==password_2?true:false"></b-form-input>
                            <b-form-invalid-feedback id="password_check_feedback">{{$t(`비밀번호를 동일하게 입력해 주세요`)}} </b-form-invalid-feedback>
                        </template>
                    </template>
                    <template v-else-if="register_step==2&&company_already_registered==false">
                        <div v-if="request_success==true" class="py-5">
                            <h3 class="mb-3">{{$t(`신청이 완료되었습니다.`)}}</h3>
                            <h5 class="mb-5 fw-400">{{$t(`이메일을 확인하여 주십시오.`)}}</h5>                          
                            <router-link :to="{name: 'Login'}">{{$t(`로그인 하기`)}}</router-link>
                        </div>
                        <div v-else class="py-5">
                            <h3 class="mb-1">{{$t(`신청 중입니다`)}} ... <b-spinner type="grow" small></b-spinner><b-spinner type="grow" small class="mx-1"></b-spinner><b-spinner type="grow" small></b-spinner></h3>
                        </div>
                    </template>
                    <template v-else-if="register_step==2&&company_already_registered==true">
                        <div v-if="user_add_success==true" class="py-5">
                            <h4 class="mb-45">{{$t(`회원 가입이 완료되었습니다.`)}} </h4>
                            <div class="mb-4">{{$t(`로그인 후 이용하여 주십시오.`)}} </div>   
                           <router-link :to="{name: 'Login'}">{{$t(`로그인 하기`)}}</router-link>
                        </div>
                        <div v-else-if="user_add_success==false&&user_already_registered==false" class="py-5">
                            <h3 class="mb-1">{{$t(`회원정보를 등록 중입니다`)}} ... <b-spinner type="grow" small></b-spinner><b-spinner type="grow" small class="mx-1"></b-spinner><b-spinner type="grow" small></b-spinner></h3>
                        </div>
                        <div v-else class="py-5">
                            <h3 class="mb-3">{{$t(`이미 등록된 유저입니다.`)}}</h3>
                            <h5 class="mb-5 fw-400">{{$t(`로그인 후 이용하여 주십시오.`)}}</h5>                          
                            <router-link :to="{name: 'Login'}">{{$t(`로그인 하기`)}}</router-link>
                        </div>
                    </template>
                    
                    <div class="text-right pt-4" v-if="register_step!=2"><b-button variant="blue" @click="handleNext">{{$t(`다음`)}}</b-button></div>

                </b-card>
            </b-col>
            <b-col cols="0" md="2" xl="3"></b-col>
        </b-row>
    </b-container>

    <b-modal id="register-privacy-agree" :title="$t(`개인정보 제공 동의`)" size="lg" hide-footer>
            <div class="pt-3 mb-4 gray-900">{{$t(`(주)퀀티파이드이에스지는 ESG 진단 또는 평가(이하 '평가'), 평가 점수 산출, 평가 결과보고서 발급, 평가 참여 이력 관리 등 서비스 제공을 위해 아래와 같이 개인정보를 수집 및 이용하고자 합니다.`)}} </div>
            <b-table-simple class="regular-table">
                <b-thead><b-tr><b-td>{{$t(`항목`)}}</b-td><b-td>{{$t(`수집목적`)}}</b-td><b-td>{{$t(`보유기간`)}}</b-td></b-tr></b-thead>
                <b-tbody><b-tr><b-td>{{$t(`성함, 이메일주소, 연락처`)}}</b-td><b-td>{{$t(`ESG 진단 또는 평가(이하 '평가'), 평가 결과보고서 발급, 평가 참여 이력 관리 등 서비스 제공`)}}</b-td><b-td>{{$t(`3년`)}}</b-td></b-tr></b-tbody>
            </b-table-simple>
            <a :href="$i18n.locale=='ko'?`http://tierzero.kr/privacy_policy.html`:`http://tierzero.kr/privacy_policy_en.html`" target="_blank"> {{$t(`(주)퀀티파이드이에스지 개인정보처리방침 전문 보기`)}}</a>
            <div class="pt-5 mb-4 f-95">※ {{$t(`위의 개인정보 수집‧이용에 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 ESG 진단 또는 평가 참여 제한 등의 불이익이 발생하게 됩니다.`)}}</div>
            <b-form-checkbox v-model="privacy_agree" class="blue-check text-right"> {{$t(`위와 같이 개인정보를 수집·이용하는데 동의합니다.`)}} </b-form-checkbox>

            <div class="text-right pt-4"><b-button variant="blue" @click="agreeAndNext">{{$t(`확인`)}}</b-button></div>
    </b-modal>

</div>
</template>

<script>

export default {
    components:{
    },
    data() {
        return {
            register_step: 0,
            company_confirmed: false,
            user_checked: false,
            company_name:'',
            company_code: null,
            project_code: '',
            privacy_agree: false,
            e_mail:'',
            user_name: '',
            tel_office: '',
            password: '',    
            password_2: '',
            project_code_state: null,
            company_already_registered: false,
            requested_e_mail: '',
            requested_time_stamp: '',
            campaign_name: '',
            campaign_name_e:'',
            campaign_name_c:'',
            request_success: false,
            user_add_success: false,
            user_already_registered: false,
            project_code_duplicated: '',
            request_status: '',
            langs: [{text:'한국어', value:'ko'}, {text:'English', value:'en'}, {text:'中文', value:'cn'}]
        }
    },
    computed:{
        isIE() {
            if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (navigator.userAgent.toLowerCase().indexOf("msie") != -1) )
                { return true }
            else
                { return false }
        },   
        selected_lang(){
            if (this.$i18n.locale=='ko'){ return 'Korean'
            }if (this.$i18n.locale=='en'){ return 'English'
            }if (this.$i18n.locale=='cn'){ return 'Chinese' }
        },
    },
    methods:{
        byLang(ko, en, cn){
            if(this.$i18n.locale=='en') return en
            else if (this.$i18n.locale=='cn') return cn
            else return ko
        },
        handleNext(){
            if(this.register_step==0){
                this.project_code = this.project_code.toLowerCase()
                if(this.formCheck()==true){
                    // console.log('form check 성공');
                    this.checkCompanyRegistered()
                }
            }else if (this.register_step==1){        
                if(this.e_mail!=''&&this.e_mail.length>4){
                    if(this.user_checked==false){
                        // 일단 유저 등록되어있는지 확인
                        this.checkUserRegistered()               
                    }else { // 유저정보 입력하고 다시 다음 누른 경우임
                        if(this.user_name!=''&&this.e_mail!=''&&this.password!=''&&this.password_2!=''){
                            this.$bvModal.show('register-privacy-agree')
                        }else alert(this.$t(`* 표시된 항목은 필수 입력 항목입니다.`))
                    }
                }        
                else alert(this.$t(`이메일을 정확히 입력하여 주십시오.`))
            }
        },
        agreeAndNext(){
            if(this.privacy_agree!=true){
                alert(this.$t(`개인정보 제공에 동의하지 않으실 경우, 평가 신청이 불가능합니다.`))
            }
            else {
                this.$bvModal.hide('register-privacy-agree')
                if (this.company_already_registered==false){
                    this.geRequestAssessment()
                }else {
                    this.addUser()
                }
            }
        },
        checkCompanyRegistered(){
            const path = this.$store.state.backend_host + '/register/company'
            return axios.get(path, {params: {
                company_name : this.company_name,
                company_code: this.company_code,
                project_code: this.project_code,
                period: 'tierzero'
            }})
            .then(response => {
                // console.log(response.data);
                let result = response.data
                if (result.code=='company_found'||result.code=='company_not_found'){
                    this.register_step = 1
                    if(result.code=='company_found'){
                        this.company_name = result.company_name
                    }
                    this.campaign_name = result.campaign_name
                    this.campaign_name_e = result.campaign_name_e
                    this.campaign_name_c = result.campaign_name_c
                }
                else if(result.code.includes('request')){
                    alert(this.$t(result.msg))
                    this.company_already_registered = true
                    this.company_name = result.company_name
                    this.campaign_name = result.campaign_name
                    this.campaign_name_e = result.campaign_name_e
                    this.campaign_name_c = result.campaign_name_c
                    this.requested_e_mail = result.requested_e_mail
                    this.requested_time_stamp = result.requested_ts
                    this.register_step = 1
                    if(result.code=='request_blocked'||result.code=='request_reassign'){
                        this.project_code_duplicated = result.project_code
                        this.request_status = result.code
                    }               
                }else if(result.code=='project_code_error'){
                    alert(this.$t(result.msg))
                    this.project_code_state = false
                }else alert(this.$t(result.msg))
            })
        },
        checkUserRegistered(){
            const path = this.$store.state.backend_host + '/register/user'
            return axios.get(path, {params: {
                e_mail : this.e_mail
            }})
            .then(response => {
                // console.log(response.data);
                if (response.data.code =='user_found'){ // 기존에 등록된 유저인 경우, 신청하기로 바로 넘기기
                    if(this.project_code=='hyundai22saq'){
                        if(this.$i18n.locale=='en') {
                            alert( this.e_mail + ' is already registered as a member of '+ response.data.company_name+'. Please enter another email address.')
                        }
                        else if(this.$i18n.locale=='cn') {
                            alert( this.e_mail + ' 帐户已注册为 '+response.data.company_name+' 公司。请输入其他电子邮件地址。')
                            
                        }else alert(response.data.company_name + ' 기업으로 이미 가입되어 있습니다. 다른 이메일 주소로 가입해주세요.')
                    }else {
                        this.user_already_registered = true
                        this.geRequestAssessment()
                    }
                }else {
                    // alert(this.$t(`등록되어 있지 않은 계정입니다. 회원가입을 위해 추가 정보를 입력해 주세요.`))
                    this.user_checked=true
                }
            })
        },
        geRequestAssessment(){
            this.register_step=2
            const path = this.$store.state.tierzero_host + '/register/request'

            let formData = new FormData
            formData.append('company_name', this.company_name)
            formData.append('company_code', this.company_code)
            formData.append('project_code', this.project_code)
            formData.append('e_mail', this.e_mail)
            formData.append('user_name', this.user_name)
            formData.append('tel_office', this.tel_office)
            formData.append('password', this.password)
            formData.append('lang',this.$i18n.locale)
            return axios.post(path, formData,{ withCredentials: true })
            .then( response =>{
                console.log(response.data);
                if(response.data.code=='success'){                    
                    this.request_success = true
                    EventBus.$emit('toast-success', this.$t('신청완료'), this.$t(response.data.msg))
                }
            })
        },
        addUser(){
            this.register_step=2
            const path = this.$store.state.backend_host + '/add_user'

            let formData = new FormData
            formData.append('company_code', this.company_code)
            formData.append('project_code', this.project_code)
            formData.append('e_mail', this.e_mail)
            formData.append('user_name', this.user_name)
            formData.append('tel_office', this.tel_office)
            formData.append('password', this.password)
            formData.append('lang',this.$i18n.locale)

            return axios.post(path, formData)
            .then( response =>{
                // console.log(response.data);
                if(response.data.code=='success'){                    
                    this.user_add_success = true
                    EventBus.$emit('toast-success', this.$t('회원가입 완료'), this.$t(response.data.msg))
                }
            })
        },
        formCheck(){
            if(this.company_name==''||this.company_name.length<2){ 
                alert(this.$t(`기업명을 정확히 입력하여 주십시오.`))
                return false
            }
            if(this.company_code==null || this.company_code==undefined || this.company_code.length < 3){ 
                alert(this.$t(`사업자등록번호를 정확히 입력하여 주십시오.`))
                return false
            }
            // if(this.$i18n.locale=='ko'&&checkCorporateRegiNumber(this.company_code)!=true){ 
            //     alert(this.$t(`유효하지 않은 사업자번호입니다. 사업자등록번호를 정확히 입력하여 주십시오.`))
            //     return false
            // }
            // if(this.$i18n.locale!='ko' && ( this.company_code==null || this.company_code==undefined || this.company_code.length < 6 )){
            //     this.company_code = 'ran-' + Math.floor(Math.random()*1000).toString(36) + '-' + Math.floor(Math.random() *10000000).toString(36)
            // }
            if(this.project_code==''){ 
                alert(this.$t(`프로젝트 코드를 정확히 입력하여 주십시오.`))
                return false
            }else return true
        },
        initiateAllData(){
            this.register_step=0,
            this.company_confirmed=false,
            this.user_checked=false,
            this.company_name='',
            this.company_code='',
            this.project_code='',
            this.privacy_agree=false,
            this.e_mail='',
            this.user_name='',
            this.tel_office='',
            this.password='',    
            this.project_code_state=null       
        },
        getQueryStringObject() {
            let a = window.location.search.substr(1).split('&');
            if (a == "") return {};
            let b = {};
            for (let i = 0; i < a.length; ++i) {
                let p = a[i].split('=', 2);
                if (p.length == 1)
                    b[p[0]] = "";
                else
                    b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
            }
            return b;
        },
        
    },
    created(){
        this.qs = this.getQueryStringObject()
        // console.log(this.qs);
        if(this.qs.code){
            this.project_code = this.qs.code
        }
        if(this.qs.l && this.qs.l=='ENG'){
            this.$i18n.locale='en'
        }
        if(this.qs.l && this.qs.l=='CN'){
            this.$i18n.locale='cn'
        }
        // console.log(this.$route);
    },
    beforeDestroy(){
        this.initiateAllData()
    }
    
}
function validateEmail(email){
    var emailReg = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    return emailReg.test(email);
}
function checkCorporateRegiNumber(number){
	var numberMap = number.replace(/-/gi, '').split('').map(function (d){
		return parseInt(d, 10);
	});
	
	if(numberMap.length == 10){
		var keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5]; //사업자번호 유효성 확인하는 일반 코드임
		var chk = 0;
		
		keyArr.forEach(function(d, i){
			chk += d * numberMap[i];
		});
		
		chk += parseInt((keyArr[8] * numberMap[8])/ 10, 10);
		// console.log(Math.floor(numberMap[9]) === ( (10 - (chk % 10) ) % 10));
		return Math.floor(numberMap[9]) === ( (10 - (chk % 10) ) % 10);
	}	
	else return false;
}

</script>

<style>

</style>