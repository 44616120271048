<template>
<div>

    <div class="bgray-200 mb-5 py-4">
        <b-container class="my-2"><b-row>
            <b-col class="col-0 col-md-2">
                <div @click.stop="pageGo({path: '/campaign_list'})" class="a-link-gray cursor f-140 d-none d-md-flex">&lang;<div class="pl-2 mt-1">목록으로</div></div>
            </b-col>
            <b-col class="col-12 col-md-10 col-lg-8">
                <div v-if="is_editing_title==true" class="d-flex justify-content-center ml-4">
                    <b-form-input type="text" v-model="temp_campaign.campaign_name" placeholder="캠페인 명을 입력해주세요." class="title-input-form" @keyup.enter="handleSaveCampaign"></b-form-input>
                    <b-button variant="blue-border" class="ml-2 px-3" @click="handleSaveCampaign"><fa icon="save"></fa></b-button>
                </div>
                <div v-else class="d-flex justify-content-center ml-4">
                    <h3 class="mb-0 pr-4">{{temp_campaign.campaign_name}}</h3><b-button variant="dark-border" class="mb-2" size="sm" @click="is_editing_title=true"><fa icon="pen"></fa></b-button>
                </div>
            </b-col>
        </b-row></b-container>
    </div>

    <b-container class="pt-4">
    <!-- 저장중일때 overlay 뜨도록 장치  -->
    <b-overlay :show="is_saving_campaign" rounded="sm">
        <b-row>
            <b-col class="col-3"></b-col> 
            <b-col class="position-relative">
                <div v-if="temp_campaign.is_public==true" class="f-90 position-absolute gray-600 fw-300" style="right:15px; margin-top:-30px;">{{ `오픈 URL: http://tierzero.kr/public_engagement?campaign_id=${temp_campaign.campaign_id}` }}</div>
                <div class="d-flex justify-content-end mb-2">Private<span class="pl-1 f-90">(공급망)</span> <b-form-checkbox v-model="temp_campaign.is_public" switch class="even-switch ml-2"></b-form-checkbox> Public <span class="f-90 pl-1">(대외오픈)</span> </div>
                <hr class="border-top-0 border-bottom-dark p-0 m-0">
            </b-col>
        </b-row>
        <!-- 필수 정보 입력란 -->
        <!-- 필수 정보 입력란 -->
        <b-row>
            <b-col class="col-3 d-flex flex-column"> 
                <h4 class="mb-4 camp-position-title">필수요소</h4><div class="camp-position-line"></div>
            </b-col>
            <b-col class="pt-4">
                <b-row class="mb-3">
                    <b-col class="col-3 fw-500 f-110">캠페인 주최사</b-col>
                    <b-col> <b-form-input type="text" v-model="temp_campaign.operator_name" placeholder="캠페인 주최사 입력"></b-form-input> </b-col>
                </b-row>
                <b-row class="mb-3">
                    <b-col class="col-3 fw-500 f-110">평가 프로세스 선택</b-col>
                    <b-col class="py-1"> 
                        <b-form-checkbox-group v-model="temp_campaign.process_options">
                            <b-form-checkbox class="black-check" value="온라인실사">온라인 실사</b-form-checkbox> 
                            <b-form-checkbox class="black-check" value="현장실사">현장 실사</b-form-checkbox> 
                            <b-form-checkbox class="black-check" value="후속모니터링">후속 모니터링(개선계획 수립)</b-form-checkbox> 
                        </b-form-checkbox-group>
                    </b-col>
                </b-row>
                <b-row class="mb-5">
                    <b-col class="col-3 fw-500 f-110">캠페인 평가자</b-col>
                    <b-col> 
                        <searchable-input v-if="is_admin_space_loaded==true" v-model="target_auditor" :search_universe="admin_user_space" :search_keys="['e_mail', 'user_name']" :auditor_mode="true"
                        placeholder="이름 또는 이메일로 검색" @auditor-add="auditorAdd(target_auditor)" class="mb-3"></searchable-input> 
                        <b-card class="border-0 bgray-200" v-if="selected_auditors.length>0">
                            <b-table-simple responsive hover class="f-90 mb-0">
                                <b-thead><b-tr>
                                    <b-td>평가자 리스트</b-td><b-td>이름</b-td><b-td>기업명</b-td><b-td>선택 제외</b-td>
                                </b-tr></b-thead>
                                <b-tbody><b-tr v-for="(item, index) in selected_auditors" :key="item.id">
                                    <b-td> {{ index+1 }} </b-td><b-td> {{ item.user_name }} </b-td><b-td> {{ item.company_name }} </b-td>
                                    <b-td><b-button variant="icon-dark" @click="deleteSelectedAuditor(item.e_mail)" class="p-0 f-120"><fa icon="minus-square"></fa></b-button></b-td>
                                </b-tr></b-tbody>
                            </b-table-simple>
                        </b-card>
                    </b-col>
                </b-row>
                <div class="text-right"><b-button variant="blue-border" class="mb-2" size="sm" @click="handleSaveCampaign"><fa icon="save" class="mr-1"></fa> 임시저장 </b-button></div>
                <hr class="border-top-0 border-bottom-dark pt-5 m-0">
            </b-col>
        </b-row>
        <!-- 참여대상 설정 -->
        <!-- 참여대상 설정 -->
        <b-row>
            <b-col class="col-3 d-flex flex-column"> 
                <h4 class="mb-4 camp-position-title">참여대상</h4><div class="camp-position-line"></div>
            </b-col>
            <b-col class="pt-4">
                <b-row class="mb-2">
                    <b-col class="col-3 fw-500 f-110">기업명 검색</b-col>
                    <b-col> <company-finder :campaign_id="value_new!=true? temp_campaign.campaign_id:''"></company-finder> </b-col>
                </b-row>
                <b-row class="mb-3">
                    <b-col class="col-3 fw-500 f-110">리스트 가져오기</b-col>
                    <b-col class="py-1"> 
                        <b-dropdown v-if="is_loading_portfolio == true" variant="dark-border" class="mb-4 w-100 text-left" :text="selected_portfolio.portfolio_name">
                            <b-dropdown-item-button class="f-90" v-for="item in portfolios" :key="item.index" @click="selectPortfolio(item)">{{ item.portfolio_name }}</b-dropdown-item-button>
                        </b-dropdown>                                 
                    </b-col>
                    <b-col cols="12">
                        <b-card class="border-0 bgray-200" v-if="selected_company.company_id!='' || selected_portfolio.portfolio_target_companies.length>0">
                            <editing-portfolio :exclude_save_mode="true" :save_excludes="save_excludes" @save-campaign="handleSaveCampaign" @initiate_save_excludes="save_excludes=false"></editing-portfolio>
                        </b-card>
                    </b-col>
                </b-row>
                <hr class="border-top-0 border-bottom-dark pt-5 m-0">
            </b-col>
        </b-row>
        <!-- 캠페인 기본정보 설정 -->
        <!-- 캠페인 기본정보 설정 -->
        <b-row>
            <b-col class="col-3 d-flex flex-column"> 
                <h4 class="mb-4 camp-position-title">캠페인 기본정보</h4><div class="camp-position-line"></div>
            </b-col>
            <b-col class="pt-4">
                <div class="fw-500 f-110 mb-3">답변기간 설정</div>
                <div class="d-flex mb-4">
                    <b-form-group label="답변 시작일" class="mr-2"><b-form-datepicker v-model="temp_campaign.start_date" ></b-form-datepicker></b-form-group>
                    ~ <b-form-group label="답변 종료일" class="ml-3"><b-form-datepicker v-model="temp_campaign.end_date"></b-form-datepicker></b-form-group>
                </div>

                <div class="fw-500 f-110 mb-3">전체 진행일정 설정</div>
                <b-table-simple responsive hover class="edi-table f-90 mb-3">
                    <b-thead><b-tr>
                        <b-td> 추진단계 </b-td> <b-td> 시작날짜 </b-td> <b-td> 종료날짜 </b-td><b-td>삭제</b-td>
                    </b-tr></b-thead>
                    <b-tbody><b-tr v-for="(item, index) in temp_campaign.steps" :key="item.id">
                        <b-td> <b-input v-model="item.step_name" placeholder="추진단계명"></b-input> </b-td>
                        <b-td> <b-input type="date" v-model="item.start_date"></b-input></b-td>
                        <b-td> <b-input type="date" v-model="item.end_date"></b-input></b-td>
                        <b-td> <b-button v-if="index!=0" variant="icon-dark" @click="deleteStep(index)" class="p-0 f-140"><fa icon="minus-square"></fa></b-button></b-td>
                        <!-- <b-td></b-td> -->
                    </b-tr></b-tbody>                  
                </b-table-simple>
                <div class="text-right mb-5"><b-button variant="dark-border" @click="newStep()" size="sm"><fa icon="plus" class="mr-2"></fa>추진단계 추가</b-button></div>

                <div class="fw-500 f-110 mb-2">캠페인 소개 문구</div>
                <b-textarea v-if="description_preview!=true" v-model="temp_campaign.campaign_description" rows="8" class="mb-2" placeholder="캠페인 소개 문구를 작성해 주세요. html 태그를 사용할 수 있습니다."></b-textarea>
                <div v-if="description_preview==true" class="mb-2 py-3 px-3 bgray-200 rounded" v-html="temp_campaign.campaign_description"></div>
                <b-button v-if="temp_campaign.campaign_description!=''" variant="dark-border" size="sm" class="float-right" @click="description_preview=!description_preview">{{description_preview!=true?'미리보기':'수정' }}</b-button>
                
                <div class="fw-500 f-110 pt-5 mb-2">사전 동의 사항</div>
                <b-textarea v-model="temp_campaign.policy_content" rows="8" class="mb-2" placeholder="정보 수집 및 제3자 정보 제공 등과 관련하여 사전 동의가 필요한 사항에 대해 작성하여 주십시오. 작성 후 우측 하단의 미리보기를 통해 확인 가능합니다."></b-textarea>
                <b-button v-show="temp_campaign.policy_content!=''" variant="dark-border" size="sm" class="float-right" @click="showPolicyPreview">미리보기</b-button>

                <div class="fw-500 f-110 pt-5 mb-3">사전 확인 정보</div>                   

                <b-row no-gutters class="mb-2 f-90 pb-2 pt-1 border-bottom-dashed" v-for="(item, index) in temp_campaign.required_info" :key="index">
                    <b-col class="col-3">사전 확인 정보 {{ index+1 }}. </b-col>
                    <b-col class="d-flex justify-content-between"> 
                        <div class="" style="width: calc(100% - 55px);">
                            <b-form-input placeholder="사전 확인 정보명" v-model="item.title" class="mb-2 f-95"></b-form-input>
                            <div class="d-flex align-items-center" v-for="(structure_item, id) in item.structure" :key="id" >
                                <div class="mr-3"> 선택지 {{id+1}} </div> <b-form-input placeholder="선택지 입력" v-model="item.structure[id]" style="width: calc(100% - 140px);" class="narrow-input-form f-90"></b-form-input>
                                <b-button @click="item.structure.push('')" variant="icon-dark" class="p-0 f-140 mx-2" v-b-tooltip.hover title="선택지 추가"><fa :icon="['fas','plus-square']"></fa></b-button>
                                <b-button v-if="id!=0" @click="item.structure.splice(id, 1)" variant="icon-dark" class="p-0 f-140 ml-1" v-b-tooltip.hover title="선택지 삭제"><fa icon="minus-square"></fa></b-button>
                            </div>
                        </div>
                        <b-button @click="temp_campaign.required_info.splice(index, 1)" class="f-140" variant="dark-border" v-b-tooltip.hover :title="'사전 확인 정보'+(index+1)+'. 삭제'"><fa icon="minus-square"></fa></b-button>
                    </b-col>
                </b-row>
                <div class="text-right mb-5"><b-button @click="temp_campaign.required_info.push({ title: '', structure: [''] })" variant="dark-border" size="sm"><fa icon="plus" class="mr-2"></fa>사전확인정보 추가</b-button></div>
                <div class="text-right"><b-button variant="blue-border" class="mb-2" size="sm" @click="handleSaveCampaign"><fa icon="save" class="mr-1"></fa> 임시저장 </b-button></div>
                <hr class="border-top-0 border-bottom-dark pt-5 m-0">
            </b-col>
        </b-row>
        <!-- 캠페인 컨텐츠 설정 -->
        <!-- 캠페인 컨텐츠 설정 -->
        <b-row>
            <b-col class="col-3 d-flex flex-column"> 
                <h4 class="mb-4 camp-position-title">캠페인 컨텐츠</h4><div class="camp-position-line"></div>
            </b-col>
            <b-col class="pt-4">
                <b-row class="mb-3">
                    <b-col class="col-3 fw-500 f-110">질문지</b-col>
                    <b-col> <b-dropdown boundary="viewport" variant="dark-border" class="w-100" :text="selected_template.template_name">
                            <div style="max-height:60vh; overflow: auto">
                            <b-dropdown-item-button v-for="item in templates" :key="item.index" @click="selectTemplate(item)">{{item.template_id }}. {{ item.template_name }}</b-dropdown-item-button>
                            </div>
                    </b-dropdown> </b-col>                    
                </b-row>
                <b-row class="mb-3">
                    <b-col class="col-3 fw-500 f-110">이전평가 답변 연결</b-col>
                    <b-col class="d-flex align-items-center justify-content-between"> 
                        <b-form-checkbox v-model="historic_mode">이전평가 답변 보기 기능 사용</b-form-checkbox>
                        <b-dropdown :disabled="historic_mode==false" variant="dark-border" class="w-50" :text="selected_campaign.campaign_name">
                            <b-dropdown-item-button v-for="item in all_campaigns" :key="item.index" @click="selectPreviousCampaign(item)">{{ item.campaign_name }}</b-dropdown-item-button>
                        </b-dropdown> 
                    </b-col>                    
                </b-row>
                <hr class="border-top-0 border-bottom-dark pt-5 m-0">
            </b-col>   
        </b-row>
    </b-overlay>
    <!-- end of 'is_saving_campaign' overlay -->
    <!-- end of 'is_saving_campaign' overlay -->

        <!-- 저장하기 -->
        <!-- 저장하기 -->
        <b-row :class="is_saving_campaign==true?'pt-3':''">
            <b-col class="col-3 d-flex flex-column"> 
                <h4 class="mb-4 camp-position-title">저장</h4>
            </b-col>
            <b-col class="pt-4">
                <p v-if="is_saving_campaign==true" class="green f-90"> <b-spinner class="mx-2 p-1 my-1" small type="grow"></b-spinner>저장 중입니다. </p>
                <b-button :disabled="is_saving_campaign" block size="lg" @click="handleSaveCampaign" :variant="is_saving_campaign==true?'green':'blue'">캠페인 저장하기 <fa icon="save" class="ml-2"></fa></b-button>
            </b-col>
        </b-row>


        <b-modal size="lg" id="hj-agree" title="사전 동의 사항 미리보기" scrollable hide-footer hide-header-close no-close-on-backdrop no-close-on-esc>
            <div class="overflow-auto" style="max-height:400px;" v-html="temp_campaign.policy_content">                   
            </div>
            <hr>
            <h6 class="mt-4 mb-4 text-center">위 내용에 동의하십니까?</h6>
            <b-form-radio-group class="mb-4 text-center" v-model="fake_policy_agree">
                <b-form-radio value="Y">예, 동의합니다.</b-form-radio>
                <b-form-radio value="N">아니오, 동의하지 않습니다.</b-form-radio>
            </b-form-radio-group>
            <div class="text-right blue">확인을 누르면 미리보기가 종료됩니다. <b-button @click="hidePolicyPreview" variant="blue" class="ml-3">확인</b-button> </div>
        </b-modal>

        <hr class="border-0 bottom-space my-5">
    </b-container>
</div>
</template>


<script>
import CompanyFinder from '../managing_user/CompanyFinder'
import EditingPortfolio from '../managing_portfolio/EditingPortfolio'
import EditingCampaignUsers from './campaign_participants/EditingCampaignUsers'
import SearchableInput from '../utility/SearchableInput'
import * as ih from '@/util'
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            temp_campaign: {},
            is_loading_portfolio: false,
            templates: [],
            selected_template: {
                template_name: '질문지 선택',
                template_code: '',
                template_contents: [],
                template_id: ''
            },
            template_code:'',
            portfolios: [],
            selected_portfolio: {
                portfolio_name: '참여대상 리스트 선택',
                portfolio_id: '',
                portfolio_description: '',
                portfolio_target_companies: []
            },
            target_companies:[],

            historic_mode: false, 
            all_campaigns: [],
            selected_campaign: {
                campaign_id: null,
                campaign_name: '지난 캠페인 선택'
            },
            target_auditor: '',
            selected_auditors: [],
            description_preview:false,
            policy_preview: false,
            is_editing_title: false,

            campaign_updated:false,
            
            fake_policy_agree: '',
            is_saving_campaign: false,
            save_excludes: false //신규 캠페인 저장 후 excluded users 저장
        }
    },
    props: {
        value_new: { type: Boolean, default: false },
    },
    computed: {
        ...mapState('auditors', {
            admin_user_space: state => state.admin_user_space,
            is_admin_space_loaded: state => state.is_admin_space_loaded
        }),
        ...mapState(['user_name', 'company_name','campaign_id', 'e_mail', 'user_id',
        'portfolio', 'campaign', 'selected_company' ]),
        
        isAuthenticated(){
            return this.$store.getters.isAuthenticated
        },
        isLoggedIn() {
            return this.$store.getters.isLoggedIn
        },
        is_admin() {
            return this.$store.getters.isAdmin
        },
        // start_date() {
        //     let temp = []
        //     this.temp_campaign.steps.forEach(item => {
        //         temp.push(new Date(item.start_date))
        //     })
        //     temp.sort( (a, b) => { return a - b })
        //     return ih.dateToString(temp[0])
        // },
        // end_date() {
        //     let temp = []
        //     this.temp_campaign.steps.forEach(item => {
        //         temp.push(new Date(item.end_date))
        //     })
        //     temp.sort( (a, b) => { return b - a })
        //     return ih.dateToString(temp[0])
        // },
    },
    methods: {
        ...mapActions('auditors', ['initiateSpace']),
        handleSaveCampaign() {
            if (this.checkForm()!=false){
                this.is_editing_title = false
                this.is_saving_campaign = true
                let new_campaign = {
                    campaign_id: this.temp_campaign.campaign_id,
                    campaign_name: this.temp_campaign.campaign_name,
                    user_id: this.temp_campaign.user_id,
                    campaign_description: this.temp_campaign.campaign_description,
                    template_code: this.temp_campaign.template_code,
                    steps: this.temp_campaign.steps,
                    start_date: this.temp_campaign.start_date,
                    end_date: this.temp_campaign.end_date,
                    target_year: this.temp_campaign.target_year,
                    campaign_state: this.temp_campaign.campaign_state,
                    campaign_target_companies: this.selected_portfolio.portfolio_target_companies,
                    process_options: this.temp_campaign.process_options,
                    operator_name: this.temp_campaign.operator_name,
                    is_public: this.temp_campaign.is_public,
                    required_info: this.temp_campaign.required_info,
                    previous_campaign_id: this.selected_campaign.campaign_id,
                    auditors: this.selected_auditors.map(x => x.id),
                    policy_content: this.temp_campaign.policy_content
                }
                // console.log(new_campaign);

                if(this.historic_mode==false){
                    new_campaign.previous_campaign_id=null
                }
                
                this.$store.dispatch('saveCampaign', new_campaign)
                .then( response => {
                    // console.log(response);
                    this.$store.commit('update_campaign', response)
                    let result = response

                    let parsed_steps = [{ step_name: '', start_date:'', end_date:''} ]
                    if(result.steps != ''){
                        parsed_steps = JSON.parse(result.steps)
                    }
                    result.steps = parsed_steps
                    this.temp_campaign = ih.deepCopy(result) 

                    this.save_excludes = true
                    this.is_saving_campaign = false
                    EventBus.$emit('toast-success', '알림', '캠페인을 저장하였습니다.')

                    // this.$router.push( { name: 'UserMainLayout', params: { is_reloaded: true} })
                })
                .finally(()=>{
                    
                })
            }
        },
        checkForm(){
            if (this.temp_campaign.campaign_name==''){
                alert('캠페인명을 입력해 주세요')
                return false
            }                
            let i_s = this.temp_campaign.steps.findIndex(item => item.start_date=='')
            if (i_s > -1){
                alert('추진단계의 시작 날짜를 입력해 주세요')
                return false
            }
            let i_e = this.temp_campaign.steps.findIndex(item => item.end_date=='')
            if (i_e > -1){
                alert('추진단계의 종료 날짜를 입력해 주세요')
                return false
            }                
        },
        readTemplateList(user_id) {
            const path = this.$store.state.backend_host + '/read_template_list'
            return axios.get(path, {
                            params: {
                                user_id:user_id
                            }
            })
            .then(response => {
                let temp_templates = response.data.templates
                // console.log(temp_templates);
                let result = temp_templates
                // let result = temp_templates.filter((item, i) =>{
                //     return temp_templates.findIndex((template_item, j)=>{
                //         return item.template_code == template_item.template_code
                //     }) == i
                // }) 
                this.templates = []

                for (var i=0; i<result.length; i++){
                    let parsed_template = {}
                    parsed_template.template_id = result[i].template_id
                    parsed_template.template_name = result[i].template_name
                    if(result[i].template_contents != ''){
                        parsed_template.template_contents = JSON.parse(result[i].template_contents)
                    }
                    else {
                        parsed_template.template_contents = []
                    }
                    parsed_template.user_id = result[i].user_id
                    parsed_template.template_code = result[i].template_code
                    parsed_template.type = result[i].type
                    parsed_template.time_stamp = result[i].time_stamp,
                    parsed_template.campaign_name = result[i].campaign_name
                    parsed_template.campaign_state = result[i].campaign_state

                    this.templates.push(parsed_template)
                }
            })
            .catch(error => {
                if (error.status == '401')
                    { alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                    this.$store.dispatch('logout').then(() => { this.$router.push({name:'Login'}) })
                }
            })
        },
        selectTemplate(template){
            this.selected_template = template
            this.temp_campaign.template_code = template.template_code
        },
        showTemplate(template){
            window.alert(template.template_name+'이 선택되었습니다.')
        },
        addTargetCompany(){
            this.$store.commit('add_portfolio_target_company', selected_company)
        },
        readPortfolios(user_id){
            this.is_loading_portfolio = true
            const path = this.$store.state.backend_host + '/read_portfolio'

            return axios.get(path, {
                            params: {
                                user_id: user_id
                            }
            })
            .then(response => {
                // console.log(response.data.portfolios)
                this.portfolios = response.data.portfolios
                // console.log('this.portfolios')
                // console.log(this.portfolios)
            })
            .catch(error => {
                console.log('axios 실패')
                console.log(error)
                if (error.response.status == '401')
                    { alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                     this.$store.dispatch('logout').then(() => { this.$router.push({name:'Login'}) })
                }
            })
            this.is_loading_portfolio = false
        },
        selectPortfolio(new_portfolio){
            this.selected_portfolio = new_portfolio
            this.$store.commit('update_portfolio', new_portfolio)
        },
        // applyPortfolio(new_portfolio){
        //     this.$store.commit('update_portfolio', new_portfolio)
        // },
        pageGo(destination){
            this.$store.commit('update_now_page', destination.name)
            this.$router.push(destination)
        },
        newStep(){
            this.temp_campaign.steps.push({  // 단계별 기본 날짜를 '작성일 ~ 작성일로부터 1달 후' 로 설정
                step_name: '',
                start_date: '',
                end_date: ''
            })
        },
        deleteStep(index){
            if(this.temp_campaign.steps.length <= 1) return false
            else{
                this.temp_campaign.steps.splice(index, 1)
            }
        },
        readCampaigns(user_id){
            const path = this.$store.state.backend_host + '/read_campaigns'

            return axios.get(path, {
                            params: {
                                user_id: user_id
                            }
            }) 
            .then(response => {
                // console.log(response.data.campaigns)
                this.all_campaigns = response.data.campaigns
                //campaign_id, user_id, campaign_name, campaign_description, template_code, steps, start_date, end_date, campaing_state, user_name, e_mail, campaign_target_companies, is_public, required_info

                if(this.campaign.previous_campaign_id != null && this.campaign.previous_campaign_id != 0){
                    let idx = this.all_campaigns.findIndex(item => { return item.campaign_id == this.campaign.previous_campaign_id })
                    if(idx >-1){
                        this.historic_mode = true
                        this.selectPreviousCampaign(this.all_campaigns[idx])
                    }
                }
            })
            .catch(error => {
                console.log('axios 실패')
                console.log(error)
                if (error.response.status == '401')
                    { alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                     this.$store.dispatch('logout').then(() => { this.$router.push({name:'Login'}) })
                }
            })
        },
        selectPreviousCampaign(campaign){
            this.selected_campaign = campaign
        },
        auditorAdd(target_auditor){
            let target_e_mail = target_auditor.split(' ', 1)[0] //e-mail
            if(target_e_mail == this.e_mail){
                return false
            }
            let idx = this.selected_auditors.findIndex(item => item.e_mail == target_e_mail)
            if (idx > -1) { return false }
            else {
                let auditor_obj = this.admin_user_space.findIndex(item => item.e_mail == target_e_mail)
                if( auditor_obj > -1 ){
                    this.selected_auditors.push(this.admin_user_space[auditor_obj])
                }
            }
        },
        deleteSelectedAuditor(target_e_mail){
            let idx = this.selected_auditors.findIndex(item=> item.e_mail == target_e_mail )
            if( idx > -1){
                this.selected_auditors.splice(idx,1)
            }
        },
        showPolicyPreview(){
            this.policy_preview =true
            this.$bvModal.show('hj-agree')
        },
        hidePolicyPreview(){
            this.policy_preview =false
            this.$bvModal.hide('hj-agree')
        }
    },
    created() {
        let today = new Date()
        const new_campaign = {
                campaign_id: 0,
                campaign_name: '',
                process_options: [],
                campaign_description: '',
                user_id: this.user_id,
                template_code: 't' + Math.floor(Math.random() * 1000000000),
                steps: [
                    { step_name: '', start_date: ih.dateToString(today), end_date: ih.dateToString(today.setMonth(today.getMonth() + 1))} 
                    
                ],
                start_date: '',
                end_date: '',
                campaign_state: '생성/편집',
                campaign_target_companies: [],
                required_info: [{                 
                    title: this.$t(`매출액`),
                    structure: ['100억 이하', '100억~500억 원', '500억 원 이상'] //array of string
                }],
                is_public: false,
                previous_campaign_id: null,
                policy_content: '',
                target_year:'FY2020'
        }

        if(this.value_new == true){
            this.$store.commit('update_campaign', new_campaign)
            this.$store.commit('update_portfolio', this.selected_portfolio)
            this.readTemplateList(this.$store.state.user_id)
            this.selected_auditors = []
        }
        else {
            this.selected_portfolio.portfolio_target_companies = ih.deepCopy(this.campaign.campaign_target_companies)
            // console.log(this.selected_portfolio.portfolio_target_companies);
            this.$store.commit('update_portfolio', this.selected_portfolio)
            this.readTemplateList(this.$store.state.user_id)
            .then(() => {
                // console.log(this.templates); 
                let idx = this.templates.findIndex(item => item.template_code == this.campaign.template_code)
                if( idx > -1 ){ 
                    this.selected_template = this.templates[idx] 
                }
            })

            this.campaign.auditors.forEach(id => {
                let idx = this.admin_user_space.findIndex(item => item.id == id)
                if(idx > -1){
                    let auditor_obj = this.admin_user_space[idx]
                    this.selected_auditors.push(auditor_obj)
                }
            })
        }
        this.temp_campaign = ih.deepCopy(this.campaign)   

        this.readPortfolios(this.user_id)
        this.readCampaigns(this.user_id)

    },
    mounted(){
        if (this.temp_campaign.campaign_name==''){
            this.is_editing_title = true
        }
    },
    watch:{
        campaign:{
            deep: true,
            immediate: true,
            handler(newVal, oldVal){
                if (newVal != oldVal){
                    this.campaign_updated =true
                }
            }
        },
        
    },
    components:{
        CompanyFinder,
        EditingPortfolio,
        EditingCampaignUsers,
        SearchableInput
    }

}
</script>
<style>

/* .camp-making-steps{
    padding: 10px 0px;
    display: inline-block;
    position: relative;
    margin-bottom: calc(100% - 46.4px);
}
.camp-making-steps::after {
    content: '';
    width: 1px;
    height: calc(100%);
    background: var(--gray-300);
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -1px;
} */

</style>