<template>
    <!-- <div class="pt-5 px-8">
        <div class="bgray-700 gray-50 f-90 px-3 py-1 d-flex justify-content-between">
            <div>{{ campaign.campaign_name }}</div> 
            <div >{{report_company.company_name}}</div> 
        </div>
    </div> -->
    <div class="py-45 px-8 bgray-200 gray-600 fw-500 d-flex align-item-center justify-content-between">
       {{ $t(local_campaign.campaign_name) }} {{$t(`결과 보고서`)}}
    </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    data() {
        return {
            local_campaign: {}
        }
    },
    computed:{
        ...mapState('cr_scores', ['report_company']),
        ...mapState(['company_id','company_name', 'campaign', 'campaign_in_progress']),
        view_mode() {
            return this.$store.state.view_mode
        },
        // ...mapState('industry_scores', [ 'company_info', 'industry', 'assessment_info',]),

    },
    created(){
        if (this.view_mode=='provider'){
            this.local_campaign = this.campaign_in_progress
        } else {
            this.local_campaign = this.campaign
        }
    }

}
</script>

<style>
.py-45{
    padding-top: 2.1rem!important;
    padding-bottom: 2.1rem!important;
}
</style>