<template>
<div>
    <b-container class="pt-5">
        <h4 class="pb-2 fw-600"> 새로 공지하기 </h4>
        
        <section class="p-3 mb-5 bgray-100">
            <editing-notice-attr ref="editing-notice-attr" @update-wrote-notice="updateWroteNotice" :notice="notice"></editing-notice-attr>
        </section>

        <hr class="border-0 pb-5">
        <div class="pb-2 d-flex justify-content-between align-items-center">
            <h4 class="pb-2 fw-600"> {{ view_all!=true? `내가 보낸 최신 공지`:'내가 보낸 공지 전체 리스트' }} </h4> 
            <b-button variant="dark-border" class="ml-auto" @click="view_all=!view_all"><fa :icon="view_all!=true?'sort-amount-down-alt':'sort-amount-up-alt'" class="mr-1"></fa> {{ view_all!=true? `전체 공지 보기`:'최신 공지 모아보기' }} </b-button>
        </div>
        <section class="mb-5" v-if="notices.length!=0">
            <template v-if="view_all!=true">
                <b-row class="">
                    <b-col v-for="item in recent_notices" :key="item.id" class="col-6 mb-3">
                        <b-card class="shadow-sm">
                            <div class="notice-card-title d-flex align-items-center"> {{ item.notice_title }} </div> 
                            <div class="notice-card-sub"> {{ item.time_stamp }} </div>
                            <b-card-text class="border-top f-95"> <span v-html="item.notice_content"></span> </b-card-text>
                        </b-card>
                    </b-col>
                </b-row>
            </template>

            <template v-else>
                <b-table-simple hover responsive class="">
                    <b-thead><b-tr class="bgray-700 gray-400">
                        <b-td>번호</b-td><b-td>제목 / 내용</b-td><b-td style="width:12%;">작성일자</b-td><b-td style="width:15%;">편집</b-td>
                    </b-tr></b-thead>
                    <b-tbody><b-tr v-for="(item, index) in notices" :key="index" class="border-bottom">
                        <b-td>{{ index+1 }}</b-td>
                        <b-td class="text-left py-3">
                            <h6 class="mb-3 f-105">{{ item.notice_title }}</h6>
                            <div v-if="is_preview==item.notice_id" v-html="item.notice_content" class="f-90"></div> <div v-else class="f-90">{{ item.notice_content }}</div></b-td>
                        <b-td> <span class="f-90">{{ item.time_stamp }}</span></b-td>
                        <b-td>
                            <b-button v-if="is_preview==item.notice_id" class="py-1 px-2 mb-2 ml-1" variant="dark-border" size="sm" @click="is_preview=0">html보기</b-button> 
                            <b-button v-else class="py-1 px-2 mb-2 ml-1" variant="dark-border" size="sm" @click="is_preview=item.notice_id">미리보기</b-button> 
                            <b-button class="py-1 px-2 mb-2 ml-1" variant="dark-border" size="sm" @click="editNotice(item)"><fa icon="pen"></fa></b-button> 
                            <b-button class="py-1 px-2 mb-2 ml-1" variant="dark-border" size="sm" @click="deleteNotice(item)"><fa icon="trash-alt"></fa></b-button>
                        </b-td>
                    </b-tr></b-tbody>
                </b-table-simple>
            </template>
        </section>
        <section class="mb-5" v-else>
            <div class="mt-2 py-2 text-center gray-600 bgray-200"> {{ user_name }}님이 보낸 공지가 없습니다.</div>
        </section>

        <b-modal id="edit-notice" size="lg" title="공지 수정하기" hide-footer @hidden="resetModal">
            <editing-notice-attr @update-wrote-notice="updateWroteNotice" :notice="notice" :is_rewrite="true"></editing-notice-attr>
        </b-modal>  

        <hr class="border-0 bottom-space mb-5">
    </b-container>
</div>
</template>

<script>
import EditingNoticeAttr from '../managing_operation/EditingNoticeAttr'
import * as ih from '@/util'

export default {
    data(){
        return {
            notices: [],
            // notice_id: '',
            // notice_title: '',
            // notice_content: '', ]
            notice: {},
            is_preview: 0,
            is_rewrite: false,
            view_all: false
        }
    },
    computed: {
        e_mail() {
            return this.$store.state.e_mail
        },
        user_name() {
            return this.$store.state.user_name
        },
        company_name() {
            return this.$store.getters.getCompanyName
        },
        target_companies(){
            return this.$store.state.portfolio.portfolio_target_companies
        },
        company_selected(){
            return this.$store.state.selected_company 
        },
        recent_notices(){
            let temp_notices = ih.deepCopy(this.notices)
            return temp_notices.splice(0, 4)
        }
    },
    methods: {
        readNotices() {
            const path = this.$store.state.backend_host + '/read_wrote_notices'

            return axios.get(path, { params: {e_mail: this.e_mail}})
            .then(response =>{
                // console.log(response.data.notices);                
                let temp_notices = response.data.notices 
                this.notices = temp_notices.sort((b,a) => new Date(a.time_stamp).getTime() - new Date(b.time_stamp).getTime())
                // console.log(this.notices);
            })
            .catch(error => {
                console.log(error);
            })
        },
        updateWroteNotice(){
            this.readNotices()
        },
        editNotice(item) {
            this.notice = item
            this.$bvModal.show('edit-notice')
        },
        previewNotice(id){
            this.is_preview = id
        },
        deleteNotice(notice) {
            let result = confirm( `'${notice.notice_title}' 공지를 삭제하시겠습니까?`);
            if(result){
                const path = this.$store.state.backend_host + '/delete_notice'

                let formData = new FormData()
                formData.append('notice_id', notice.notice_id)

                return axios.post(path, formData) 
                .then (response => {
                    // console.log(response);
                    
                    let idx = this.notices.findIndex( item => { return item.notice_id == notice.notice_id })
                    if(idx >-1){
                        this.notices.splice(idx, 1)
                    }
                    EventBus.$emit('toast-success','공지사항 삭제', '공지사항을 삭제하였습니다. 주의: 받은 사람이 공지사항을 더 이상 확인할 수 없습니다.')
                })
                .catch (error => {
                    console.log(error);                
                })
            }

        },
        resetModal(){
            this.$refs['editing-notice-attr'].initiateForm()
            this.is_rewrite = false
        }       
    },
    created(){
        this.readNotices()
    },
    components:{
        EditingNoticeAttr,
    }
    
}
</script>

<style scoped>
.notice-card-title {
    font-size: 1rem;
    font-weight: 500;
    padding: 0.3rem 1rem 0rem;
}
.notice-card-sub {
    font-size: 0.8rem;
    padding: 0.3rem 1rem;
    color: var(--gray-550);
}
</style>