<template>
<div>
    <!-- input text 유형 -->
    <div class="pl-4 mb-2 d-flex align-items-center" v-if="type=='input_text'">
        <div class="pr-2 ">
            {{ byLang(structure.pre_text, structure.pre_text_ea, structure.pre_text_ca) }} 
        </div>

        <template>
            <b-input-group class="primary-input" style="">
                <b-form-input :disabled="disabled" v-model="result" @update="handleUpdate" :placeholder="byLang(structure.placeholder, structure.placeholder_ea, structure.placeholder_ca  )" ></b-form-input>
                <b-input-group-append v-if="custom_style==''"><b-button :disabled="disabled" class="f-80" variant="primary" v-b-modal="`input-text-${code}`"><fa icon="pen"></fa></b-button></b-input-group-append>
            </b-input-group>
        </template>

        <div class="pl-2"> 
            <span >
                {{ byLang(structure.after_text, structure.after_text_ea, structure.after_text_ca )  }} 
            </span>

        </div>

        <b-modal @ok="handleOK" size="lg" :id="`input-text-${code}`" :title="structure.placeholder" >
            <p class="mb-2 f-90 gray-600"> 상세설명을 작성하여 주십시오.</p>
                <b-textarea :disabled="disabled" v-model="result" rows="5"></b-textarea>
        </b-modal>
    </div>

    <!-- input number 유형  -->
    <div class="pl-4 mb-2 d-flex align-items-center" v-else-if="type=='input_number'">
        <div class="pr-2"> {{ byLang(structure.pre_text, structure.pre_text_ea, structure.pre_text_ca)}} 
        </div>
        <b-form-input class="primary-input" style="max-width:50%;" :disabled="disabled" v-model="result" @update="handleUpdate" :placeholder="byLang(structure.placeholder, structure.placeholder_ea, structure.placeholder_ca )" type='number'></b-form-input>

        <div class="pl-2"> {{ byLang(structure.after_text,structure.after_text_ea, structure.after_text_ca )  }} 
        </div>
    </div>

    <!-- checkbox 유형 -->
    <div class="pl-4 mb-2" v-else-if="type=='checkbox'">
        <b-form-checkbox-group v-model="result" @change="handleUpdate" stacked>
            <div v-for="(checkbox_option, index) in structure" :key="checkbox_option.id" class="ml-2">
                <!-- {{checkbox_option}} -->
                <b-form-checkbox
                :value="checkbox_option.value" :class="isIE==true?'element-check':''"
                :disabled="disabled" class="mb-1 primary-check"
                > 
                {{ byLang(checkbox_option.text, checkbox_option.text_ea, checkbox_option.text_ca) }}

                </b-form-checkbox>
                <div class="mb-2 w-100">
                    <b-input-group v-if="checkbox_option.description != undefined" class="mb-2 ml-4" style="width: calc(100% - 3rem);">
                        <b-form-input
                        v-model="checkbox_option.description"
                        :disabled="!result.includes(checkbox_option.value) || disabled"
                        :placeholder="checkbox_option.placeholder? byLang(checkbox_option.placeholder, checkbox_option.placeholder_ea, checkbox_option.placeholder_ca) : $t('상세설명')"
                        class="f-90 "
                        @change="handleUpdate"
                        ></b-form-input>

                        <b-input-group-append><b-button :disabled="!result.includes(checkbox_option.value) || disabled" v-b-modal="`checkbox-text-${code}-${index}`" size="sm" variant="icon-append"><fa icon="pen"></fa></b-button></b-input-group-append>
                        <b-modal @ok="handleOK" :id="`checkbox-text-${code}-${index}`" :title="checkbox_option.value ? checkbox_option.value : $t(`텍스트 상세입력`)" size="lg">
                            <div>
                                <p class="mb-2 pl-1 f-90"> {{$t(`상세설명을 작성하여 주십시오.`)}}</p>
                                <b-textarea rows="5" v-model="checkbox_option.description"></b-textarea>
                            </div>
                        </b-modal>
                    </b-input-group>
                </div>
            </div>
        </b-form-checkbox-group>
    </div>

    <!-- Table 유형 -->
    <div class="pl-4 mb-3" v-else-if="type=='table'">
        <!-- {{structure}} -->
        <!-- <hr> -->
        <!-- {{table_header}} <br> -->
        <!-- {{table_header_ea}} <br> -->
        <!-- {{table_header_ca}} -->
        <b-table-simple class="bor-table" hover>
            <b-thead><b-tr>
                <b-td v-for="table_field in byLang(table_header, table_header_ea, table_header_ca)" :key="table_field.key" class="f-90"> {{ byLang(table_field, table_field.label, table_field.label) }} </b-td>
            </b-tr></b-thead>
            <b-tbody>

                <b-tr v-for="(table_item, index) in structure" :key="table_item.id">
                    <b-td v-for="(table_field, no) in byLang(table_header, table_header_ea, table_header_ca)" :key="table_field.key" class="f-90" :style="isIE?'max-width:100px;':''">
                        <div v-if="table_item.hasOwnProperty('noteditables') && (table_item['noteditables'].includes(table_field) || table_item['noteditables'].includes(table_field.key))">
                            <span v-if="$i18n.locale=='en'" v-html="table_item[table_field.key+'_ea']"></span>
                            <span v-else-if="$i18n.locale=='cn'" v-html="table_item[table_field.key+'_ca']"></span>
                            <span v-else v-html="table_item[table_field]"></span>
                        </div>
                        <div v-else>
                            <div v-if="table_item.hasOwnProperty('dropdown') && (table_item['dropdown'].hasOwnProperty(table_field) || table_item['dropdown'].hasOwnProperty(table_field.key))">
                                <b-dropdown v-if="$i18n.locale=='en'" :disabled="disabled" :id="`table-dropdown-${index}-${no}`" size="sm" variant="dark-border"
                                :text="table_item[table_field.key+'_ea']!=''&&table_item[table_field.key+'_ea']!=null ? table_item[table_field.key+'_ea'] : table_item['dropdown'][table_field.key+'_ea'][0]" >
                                    <b-dropdown-item-button v-for="dropdown_item in table_item['dropdown'][table_field.key+'_ea']" :key="dropdown_item.id" @click="setDropdown(table_item, table_field.key+'_ea', dropdown_item)" class="f-90">{{ dropdown_item }}</b-dropdown-item-button>
                                </b-dropdown>
                                <b-dropdown v-else-if="$i18n.locale=='cn'" :disabled="disabled" :id="`table-dropdown-${index}-${no}`" size="sm" variant="dark-border"
                                :text="table_item[table_field.key+'_ca']!=''&&table_item[table_field.key+'_ca']!=null ? table_item[table_field.key+'_ca'] : table_item['dropdown'][table_field.key+'_ca'][0]" >
                                    <b-dropdown-item-button v-for="dropdown_item in table_item['dropdown'][table_field.key+'_ca']" :key="dropdown_item.id" @click="setDropdown(table_item, table_field.key+'_ca', dropdown_item)" class="f-90">{{ dropdown_item }}</b-dropdown-item-button>
                                </b-dropdown>
                                <b-dropdown v-else :disabled="disabled" :id="`table-dropdown-${index}-${no}`" :text="table_item[table_field]" size="sm" variant="dark-border">
                                    <b-dropdown-item-button v-for="dropdown_item in table_item['dropdown'][table_field]" :key="dropdown_item.id" @click="setDropdown(table_item, table_field, dropdown_item)" class="f-90">{{ dropdown_item }}</b-dropdown-item-button>
                                </b-dropdown>
                            </div>
                            <div v-else>
                                <div v-if="table_item.hasOwnProperty('calculation') && table_item['calculation'].hasOwnProperty(table_field)">
                                    {{ cellCalculate(table_item.calculation[table_field]) }}
                                </div>
                                <div v-else>
                                    <b-input-group>
                                        <b-form-input v-if="$i18n.locale=='en'" :disabled="disabled" v-model.trim="table_item[table_field.key+'_ea']" @update="handleUpdate" :placeholder="showPlaceholder(table_item, table_field.key+'_ea')" class="pr-0 f-90"></b-form-input>
                                        <b-form-input v-else-if="$i18n.locale=='cn'" :disabled="disabled" v-model.trim="table_item[table_field.key+'_ca']" @update="handleUpdate" :placeholder="showPlaceholder(table_item, table_field.key+'_ca')" class="pr-0 f-90"></b-form-input>
                                        <b-form-input v-else :disabled="disabled" v-model.trim="table_item[table_field]" @update="handleUpdate" :placeholder="showPlaceholder(table_item, table_field)" class="pr-0 f-90"></b-form-input>
                                        <b-input-group-append><b-button :disabled="disabled" class="f-80" variant="icon-append" v-b-modal="`${table_item[table_field]}-text-${index*7}-${no}`"><fa icon="pen"></fa></b-button></b-input-group-append>

                                        <b-modal @ok="handleOK" :id="`${table_item[table_field]}-text-${index*7}-${no}`" :title="table_field ? byLang(table_field, table_field.label, table_field.label) : $t(`텍스트 상세입력`)">
                                            <b-textarea rows="4" :disabled="disabled" v-model="table_item[table_field]"></b-textarea>
                                        </b-modal>
                                    </b-input-group>
                                </div>
                            </div>
                        </div>
                    </b-td>
                </b-tr>

            
            </b-tbody>
        </b-table-simple>
    </div>

</div>
</template>
<script>
import * as ih from '@/util'
export default {
    data(){
        return {
            type: '',
            result: '',
            structure: null,
            table_header: [],
            table_header_ea: [],
            table_header_ca: [],
            code: Math.random() * 100000,

            //type: 'input_text', // input_number, input_text
            //result: '',
            //structure: [{
                //pre_text: '시험삼아',
                //after_text: '해보았습니다',
                //placeholder: '입력'
            //}]

            //type: 'checkbox', // checkbox
            //result: [],
            //structure: [
            //     {
            ///         "text": "이사회 의장이 독립이사임", 
            //          "value": "이사회 의장이 독립이사", 
            //          "description": ""
            //      }, 
            //      {
            //          "text": "이사회 의장이 전 CEO 또는 전 의장임", 
            //          "value": "이사회 의장이 전 CEO 또는 전 의장", 
            //          "description": ""
            //      }, 
            //      {
            //          "text": "이사회 의장이 기타비상무이사임", 
            //          "value": "이사회 의장이 기타비상무이사", 
            //          "description": ""
            //      }, 
            //      {
            //          "text": "이사회 의장이 CEO 또는 사내이사임", 
            //          "value": "이사회 의장이 CEO 또는 사내이사", 
            //          "description": ""
            //      }
            //  ]
        }

    },
    props:{
        item_idx: { type: Number},
        element_idx: { type: Number},
        element_item: { type: Object },
        disabled: { type: Boolean },
        audit_mode: { type: Boolean }
    },
    computed:{
        isIE() {
            let agent = navigator.userAgent.toLowerCase()
            if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (agent.indexOf("msie") != -1) )
                {
                    return true
                }
            else
                { 
                    return false
                }
        }
    },
    methods:{
        byLang(ko, en, cn){
            if(this.$i18n.locale=='en') return en
            else if (this.$i18n.locale=='cn') return cn
            else return ko
        },
        handleUpdate(){
            Vue.nextTick( () => {
                let new_element_item = {
                    type: this.type, 
                    result: this.result,
                    structure: this.structure,
                    table_header: this.table_header,
                    table_header_ea: this.table_header_ea,
                    table_header_ca: this.table_header_ca
                }
                this.$emit('change', this.item_idx, this.element_idx, new_element_item)
            })
        },
        handleOK(bvModalEvent){
            if(this.result.length==0) bvModalEvent.preventDefault()
            this.handleUpdate()()
            // this.$bvModal.hide(id)
        },
        cellCalculate(str){
            let var_item = []
            let calculation_item = []

            var_item = str.match(/([A-Z0-9\(\)]+)/gi)
            calculation_item = str.match(/([+-/*])/g)

            let result = ''

            //오류처리들
            if(!var_item[0])
            {
                //console.log('셀 아이템이 없습니다')
                return false
            }
            if(var_item.length!=calculation_item.length+1)
            {
                //console.log('셀 아이템과 연산자 개수가 맞지 않습니다.')
                return false 
            }

            //만약 셀 참조일 경우, cellValue로 변환
            if(var_item[0].match(/[A-Z]/i)) result = this.cellValue(var_item[0])
            else result = String(var_item[0])

            for(let i=0; i<calculation_item.length; i++)
            {
                if(var_item[i+1].match(/[A-Z]/i)) { var_item[i+1] = this.cellValue(var_item[i+1]) }
                else var_item[i+1] = String(var_item[i+1])
                result = result + calculation_item[i] + var_item[i+1]
            }

            //console.log(result)
            result = eval(result)
            return result.toLocaleString(undefined, {maximumFractionDigits:0})

        },
        showPlaceholder(table_item, table_field) {
            if(table_item.hasOwnProperty('placeholder') && table_item['placeholder'].hasOwnProperty(table_field)) {
                return table_item['placeholder'][table_field]
            }
            else return ''
        },
        setDropdown(table_item, table_field, contents) {
            table_item[table_field] = contents
            return this.handleUpdate()
        },
    },
    mounted(){
        // console.log(this.element_item);
        this.type = this.element_item.type
        this.result = this.element_item.result
        this.structure = this.element_item.structure
        if(this.element_item.table_header != undefined || this.element_item.table_header != null){
            this.table_header = this.element_item.table_header
            this.table_header_ea = this.element_item.table_header_ea
            this.table_header_ca = this.element_item.table_header_ca        
        }
        // console.log(this.isIE)
    }
}
</script>
<style>
.element-check > label,
.element-check > label > span{
    min-width: 450px;
}
</style>