s<template>   

        <div class="w-100 py-2 bgray-700 gray-400 f-90 d-flex justify-content-around">
                
                <div class="text-left">
                       <span class="pr-2"> Copyright <fa icon="copyright" class="mr-2"></fa>2022 </span> <span class="pr-2">QuantifiedESG, Inc.</span><span v-if="$i18n.locale=='ko'">(주)퀀티파이드이에스지</span>
                </div>
                <div class="text-right">
                        <a class="mr-3 footer-link" @click="goPrivacyPolicy"> {{$t(`개인정보 처리방침`)}}</a>|
                        <a class="mr-3 pl-3 footer-link" @click="goTermsOfUse"> {{$t(`이용약관`)}}</a>|
                        <a href="mailto:info@qesg.co.kr" class="mr-3 pl-3 text-reset"> {{$t(`시스템 문의`)}}</a>
                </div>
        </div>

</template>
<script>
export default {
    methods:{
            goTermsOfUse(){
                this.$router.push({name: 'TermsOfUse'})
                this.$store.commit('update_service_policy_mode', '이용약관')
            },
            goPrivacyPolicy(){
                this.$router.push({name: 'PrivacyPolicy'})
                this.$store.commit('update_service_policy_mode','개인정보 처리방침')
            }
        }
}
</script>
