<template>
<div>
    <h4 class="mt-3 f-140"> {{$t(`전체일정`)}} </h4>
        <!-- <div> {{ this.campaign_name }} 캠페인의 전체 진행 일정입니다. 각 Step별 추진 기간 및 종료 일정을 참고해 주십시오. </div> -->
        <!-- <b-row class="w-100">
            <b-col class="col-12 col-lg-3"><h4 class=""> 전체일정 </h4></b-col>
            <b-col class="col-12 col-lg-9 border-left f-105"> {{ this.campaign_name }} 캠페인의 전체 진행 일정입니다. 각 Step별 추진 기간 및 종료 일정을 참고해 주십시오.
                <div class="mt-2 f-95 gray-600"> * 일정 관련 문의사항이 있으실 경우 <b-badge variant="btn" @click="showNeedHelp">도움이 필요하신가요?</b-badge>를 통해 메세지를 전달주십시오. </div>
            </b-col>
        </b-row> -->

    <div class="mb-1 text-right f-90 fw-300 gray-600"> 
        Start. {{ camgaign_start_date_string }} / End. {{ camgaign_end_date_string }} 
    </div>

    <b-container fluid ref="schedule-container" class="px-0 w-100 position-relative border-top">

        <b-row no-gutters id="steps-generator" v-for="(item, index) in steps" :key="item.id" class="border-bottom-dashed align-items-center">
            <b-col class="col-4 col-lg-3 text-left">
                <div class="py-2 py-lg-3"> <span class="f-85"> {{ 'Step'+ (index+1) }}.</span> {{ $t(item.step_name) }} </div> 
            </b-col>
            <b-col ref="bar-canvas" class="col-8 col-lg-9 position-relatve">
                <div class="bar-steps" :style="calculatePosition(item)"> </div>
                <div class="bar-progress" :style="calculateProgress(item)"> </div>
                <div class="f-80 gray-600 bar-date" :style="calculateMargin(item)"> {{ item.end_date.toISOString().slice(0,10) }}</div>
            </b-col>
        </b-row>

        <b-row no-gutters id="fake-row" class="position-absolute ">
            <b-col class="col-4 col-lg-3 pl-2"><div class="py-3"></div> </b-col>
            <b-col class="col-8 col-lg-9 position-relative overflow-hidden">
                <div id="today-indicator" :style="calculateToday()"></div>
                <div id="virtual-indicator" :style="calculateTodayV()"></div>
            </b-col>
            <b-tooltip show target="virtual-indicator" variant="blue">Today</b-tooltip>
        </b-row>

    </b-container>

</div>
</template>

<script>
import * as ih from '@/util'

export default {
    data() {
        return {
            bar_canvas_width: 100,
            start_date: '',
            //steps: [
                // new Date 정의 시 month는 0~11로 정해야함 ex) 1월 = 0월
            //    { step_name: '평가오픈', start_date: new Date(2020, 0, 20), end_date: new Date(2020, 0, 27) },
            //    { step_name: '온라인 답변 작성', start_date: new Date(2020, 0, 28), end_date: new Date(2020, 1, 28)},
            //    { step_name: '현장실사', start_date: new Date(2020, 1, 29), end_date: new Date(2020, 2, 31)}
            //],
        }
    },
    computed: {        
        camgaign_start_date(){
            return this.$store.state.campaign_in_progress.start_date
        },
        camgaign_end_date(){
            return this.$store.state.campaign_in_progress.end_date
        },
        camgaign_start_date_string(){
            return ih.dateToString(this.$store.state.campaign_in_progress.start_date)
        },
        camgaign_end_date_string(){
            return ih.dateToString(this.$store.state.campaign_in_progress.end_date)
        },
        campaign_name() {
            return this.$store.state.campaign_in_progress.campaign_name
        },
        project_duration() {
            // 전체 day 수 반환
            return this.camgaign_end_date - this.camgaign_start_date
        },
        steps() {
            return this.$store.state.campaign_in_progress.steps
        }
    },
    methods:{
        calculatePosition(step){
            let width
            width =  (step.end_date - step.start_date) / this.project_duration * this.bar_canvas_width

            let margin_left
            margin_left = (step.start_date - this.camgaign_start_date) / this.project_duration * this.bar_canvas_width

            return { 'width': width + '%', 'margin-left': margin_left + '%' }
        },
        calculateProgress(step) {
            let width
            var today = ih.stringToDate(ih.getTimeStamp().substring(0,10))
            
            if (today - step.end_date >= 0) {
                width = (step.end_date - step.start_date) / this.project_duration * this.bar_canvas_width
            }
            else if (today - step.start_date > 0){
                width = (today - step.start_date) /this.project_duration *this.bar_canvas_width
            }
            else if (today - step.start_date <= 0) {
                width = 0
            }

            let margin_left
            margin_left = (step.start_date - this.camgaign_start_date) / this.project_duration * this.bar_canvas_width

            return { 'width': width + '%', 'margin-left': margin_left + '%' }
        },
        calculateToday(){
            let width
            var today = ih.stringToDate(ih.getTimeStamp().substring(0,10))
            width = (today - this.camgaign_start_date) / this.project_duration *this.bar_canvas_width

            // console.log( 'calculate_today')
            // console.log( 'today, camgaign_start_date, proejct_duration, bar_canvas_width', today + ' ' + this.camgaign_start_date + ' ' + this.project_duration + ' ' + this.bar_canvas_width )

            if (width >=100) {
                return { 'width': 100 + '%' } 
            }
            else return { 'width': width + '%' }            
        },
        calculateTodayV(){
            let width
            var today = ih.stringToDate(ih.getTimeStamp().substring(0,10))
            width = (today - this.camgaign_start_date) / this.project_duration *this.bar_canvas_width *2

            if (width >=200) {
                return { 'width': 200 + '%' } 
            }
            else return { 'width': width + '%' }             
        },
        calculateMargin(step){
            let margin_left 
            let margin_top          
            margin_left = ((step.end_date - this.camgaign_start_date) / this.project_duration )*this.bar_canvas_width + 1
            if (margin_left > 90 ) {
                margin_left = 90
                margin_top = 16
            }
            else margin_top = 0
            return { 'margin-left' : margin_left + '%' , 'margin-top' : margin_top +'px'}
        },
        showNeedHelp(){
            this.$emit('show-need-help')
        }
    }
    
}
</script>

<style scoped>

#fake-row {
    z-index: 9;
    top: 0;
    width: 100%;
    height: 100%;
}

.bar-steps{
    z-index: 5;
    background-color: var(--gray-400);
    height: 15px;
}

.bar-progress{
    z-index: 7;
    position: absolute;
    top: 0rem;
    background-color: var(--gray-700);
    height: 15px;
}
.bar-date {
    z-index: 7;
    position: absolute;
    top: 0rem;
}
#today-indicator{
    position: absolute;
    border-right: 2px dotted var(--blue);
    height: 100%;
    box-sizing: border-box;
    z-index: 30;
}
#virtual-indicator{
    position: absolute;
    overflow: hidden;
    top: 0;
    height: 100%;
}
.hover-bg-gray:hover{
    background-color: var(--gray-200);
}
</style>