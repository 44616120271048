const sh_saq = {
    namespaced: true,
    state: {
        company_code : null,
        e_mail : null
    },
    mutations: {
        set_company_code(state, new_company_code){
            state.company_code = new_company_code
        },
        set_e_mail(state, new_e_mail){
            state.e_mail = new_e_mail
        }

    },
    getters: {
    },
    actions: {

    }
}
export default sh_saq