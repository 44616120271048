<template>
<b-container fluid>
    <b-row class="mb-5">
        <b-col :class="is_rewrite==true?'col-2 px-0':'col-2'"><h5 :class="is_rewrite==true?'f-110':''"> 내용 작성 </h5></b-col>
        <b-col class="">
            <b-form-input v-model="notice_title" placeholder="제목을 입력해 주세요" class="mb-3"></b-form-input>
            <b-form-textarea v-model="notice_content" placeholder="내용을 입력해 주세요" rows="8" max-rows="8" class="mb-3"></b-form-textarea>
        </b-col>
    </b-row>
    <b-row>
        <b-col :class="is_rewrite==true?'col-2 px-0':'col-2'"><h5 :class="is_rewrite==true?'f-110':''"> 공지 대상 선택 </h5></b-col>
        <b-col class="">
            <div v-if="notice.notice_id == undefined || notice.notice_id == ''">
                <b-button-group class="mb-4">
                    <b-button variant="mode" :pressed="notice_target_mode=='campaign'" @click="notice_target_mode='campaign'"> 캠페인 대상 </b-button>
                    <b-button variant="mode" :pressed="notice_target_mode=='portfolio'" @click="notice_target_mode='portfolio'"> 개별 기업 대상 </b-button>
                </b-button-group>

                <!-- 공지대상이 개별기업 / 기업 리스트 기반일 때 -->
                <div v-if="notice_target_mode=='portfolio'" class="mb-5">
                    <div class="w-50 mb-2"><company-finder :key="company_finder_key"></company-finder></div>
                    <b-dropdown v-if="is_loading_portfolio == true" variant="dark-border" class="w-50 mb-5" text="참여대상 리스트 선택">
                        <b-dropdown-item-button class="f-90" v-for="item in portfolios" :key="item.index" @click="selectPortfolio(item)">{{ item.portfolio_name }}</b-dropdown-item-button>
                    </b-dropdown>
                    
                    <div v-show="target_companies && target_companies.length !=0"><editing-portfolio :notice="notice"></editing-portfolio></div>
                </div>
                <!-- 공지대상이 캠페인 기반일 때 -->
                <div v-else> 
                    <b-form-select v-model="selected_campaign_id" :options="campaigns_list_option_obj" @change="selectTargetCampaign(selected_campaign_id)" class="w-50 mb-5"></b-form-select> 

                    <div v-if="selected_campaign_id!=0" class="mb-3">
                        <div class="mb-5">
                            <div class="pb-2 d-flex align-items-end">
                                <div class="mr-auto f-110 fw-500 gray-600">캠페인 운영기업</div> 
                                <b-button variant="dark-border" class="f-90" size="sm" @click="addAll(owners, selected_owners)"><fa icon="plus" class="mr-2"></fa>모두추가</b-button>
                                <b-button variant="dark-border" class="f-90 ml-2" size="sm" @click="deleteAll(selected_owners)"><fa icon="minus" class="mr-2"></fa>모두제거</b-button>
                            </div>
                            <b-form-checkbox-group v-model="selected_owners">
                            <b-table-simple responsive hover class="f-90">
                                <b-thead><b-tr class="bgray-300">
                                        <b-td>선택</b-td><b-td>이름</b-td><b-td>이메일</b-td><b-td>기업명</b-td><b-td>부서</b-td><b-td>구분</b-td>
                                </b-tr></b-thead>
                                <b-tbody>   
                                    <b-tr v-for="item in owners" :key="'owner'+item.user_id">
                                        <b-td><b-form-checkbox v-model="selected_owners" :value="item.e_mail" class="mr-0"></b-form-checkbox></b-td>
                                        <b-td> {{ item.user_name }} </b-td>
                                        <b-td> {{ item.e_mail }} </b-td>
                                        <b-td> {{ item.company_name }} </b-td>
                                        <b-td> {{ item.department_name }}</b-td>
                                        <b-td> {{ parseAdmin(item.is_admin) }}</b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            </b-form-checkbox-group>
                        </div>
                        <div v-if="auditors.length > 0" class="mb-5">
                            <div class="pb-2 d-flex align-items-end">
                                <div class="mr-auto f-110 fw-500 gray-600">평가자(Auditor)</div> 
                                <b-button variant="dark-border" class="f-90" size="sm" @click="addAll(auditors, selected_auditors)"><fa icon="plus" class="mr-2"></fa>모두추가</b-button>
                                <b-button variant="dark-border" class="f-90 ml-2" size="sm" @click="deleteAll(selected_auditors)"><fa icon="minus" class="mr-2"></fa>모두제거</b-button>
                            </div>
                            <b-form-checkbox-group v-model="selected_auditors">
                            <b-table-simple responsive hover class="f-90">
                                <b-thead><b-tr class="bgray-300">
                                        <b-td>선택</b-td><b-td>이름</b-td><b-td>이메일</b-td><b-td>기업명</b-td><b-td>부서</b-td><b-td>구분</b-td>
                                </b-tr></b-thead>
                                <b-tbody>
                                    <b-tr v-for="item in auditors" :key="'auditors'+item.user_id">
                                        <b-td><b-form-checkbox v-model="selected_auditors" :value="item.e_mail" class="mr-0"></b-form-checkbox></b-td>
                                        <b-td> {{ item.user_name }} </b-td>
                                        <b-td> {{ item.e_mail }} </b-td>
                                        <b-td> {{ item.company_name }} </b-td>
                                        <b-td> {{ item.department_name }}</b-td>
                                        <b-td> {{ parseAdmin(item.is_admin) }}</b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            </b-form-checkbox-group>
                        </div>
                        <div v-if="participants.length > 0" class="mb-5">
                            <div class="pb-2 d-flex align-items-end">
                                <div class="mr-auto f-110 fw-500 gray-600">참여기업</div> 
                                <b-button variant="dark-border" class="f-90" size="sm" @click="addAll(participants, selected_participants)"><fa icon="plus" class="mr-2"></fa>모두추가</b-button>
                                <b-button variant="dark-border" class="f-90 ml-2" size="sm" @click="deleteAll(selected_participants)"><fa icon="minus" class="mr-2"></fa>모두제거</b-button>
                            </div>
                            <b-form-checkbox-group v-model="selected_participants">
                            <b-table-simple responsive hover class="f-90">
                                <b-thead><b-tr class="bgray-300">
                                        <b-td>선택</b-td><b-td>이름</b-td><b-td>이메일</b-td><b-td>기업명</b-td><b-td>부서</b-td><b-td>구분</b-td>
                                </b-tr></b-thead>
                                <b-tbody>
                                    <b-tr v-for="item in participants" :key="'participants'+item.user_id">
                                        <b-td><b-form-checkbox v-model="selected_participants" :value="item.e_mail" class="mr-0"></b-form-checkbox></b-td>
                                        <b-td> {{ item.user_name }} </b-td>
                                        <b-td> {{ item.e_mail }} </b-td>
                                        <b-td> {{ item.company_name }} </b-td>
                                        <b-td> {{ item.department_name }}</b-td>
                                        <b-td> {{ parseAdmin(item.is_admin) }}</b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            </b-form-checkbox-group>
                        </div>
                    </div>

                </div>
            </div>

            <!--수정 모드일 경우-->
            <!--수정 모드일 경우-->
            <div v-else class="mb-4">
                <span v-if="notice.target_users">
                    {{ notice.target_users }}
                    <!-- {{ notice.target_users[0].e_mail }}  -->
                    <span v-if="notice.target_users.length >= 2">외 {{ notice.target_users.length -1 }} 명</span>에게 공지되었습니다. 공지대상은 수정할 수 없습니다. 
                </span>
            </div>

        </b-col>
    </b-row>
    <b-row class="mb-45">
        <b-col :class="is_rewrite==true?'col-2 px-0':'col-2'"><h5 :class="is_rewrite==true?'f-110':''"> 공지하기 </h5></b-col>
        <b-col class="">
            <div class="border-top-dark d-flex justify-content-end align-items-center pt-4 mt-2">
            <b-form-checkbox v-model="using_e_mail" class="f-105">공지와 함께 이메일 전송</b-form-checkbox> 
            <b-button variant="blue" @click="handleOK" class="ml-4 px-4"> <fa icon="paper-plane" class="mr-2"></fa>공지하기 </b-button>
            </div>
        </b-col>
    </b-row>

        <b-modal id="notice-preview" ref="notice-preview" size="lg" hide-footer title="공지 미리보기">
            <!-- <div class="mb-3"> 공지대상 </div> -->
            <div v-if="notice.notice_id == undefined || notice.notice_id == ''">
                <div class="mb-3 fw-500" v-if="notice_target_mode == 'portfolio'">
                    <span class="fw-400">공지대상 : </span> 총 {{ target_companies.length }} 개 기업
                </div>
                <div class="mb-3 fw-500" v-else> <!--캠페인-->
                    <span class="fw-400">공지대상 : </span>{{ notice_target_array[0] }} <span v-if="notice_target_array.length >= 2">외 {{ notice_target_array.length - 1 }} 명</span>
                </div>
            </div>
            <div v-else><!--공지 수정모드일 경우-->
                <span v-if="notice.target_users">
                    <!-- {{ notice.target_users[0].e_mail }}  -->
                    <span v-if="notice.target_users.length >= 2">외 {{ notice.target_users.length -1 }} 명</span>에게 공지되었습니다.
                </span>
            </div>

            <b-card class="mb-5 py-2">
                <h5 class="mb-3"> {{ notice_title }} </h5>
                <div v-html="notice_content"></div>
            </b-card>

            <div class="text-right">
                <span class="blue mr-3" v-if="using_e_mail == true"> <fa icon="check" class="mr-1"></fa>본 공지는 이메일로도 전달됩니다. </span>  
                <span class="blue mr-3" v-else> <fa icon="exclamation-circle" class="mr-1"></fa> 본 공지는 이메일로는 전달되지 않습니다. </span>
                <b-button @click="saveNotice" variant="blue"><fa icon="paper-plane" class="mr-1"></fa> 보내기</b-button>   
            </div>
        </b-modal>

</b-container>
</template>

<script>
import CompanyFinder from '../managing_user/CompanyFinder'
import EditingPortfolio from '../managing_portfolio/EditingPortfolio'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
    data(){
        return {
            notice_id: '',
            notice_title: '',
            notice_content: '',
            // is_company_selected: false, 
            company_finder_key: 0,

            is_loading_portfolio: false,
            portfolios: [],
            selected_portfolio: {},

            notice_target_mode: 'campaign',
            selected_campaign_id: 0,

            selected_owners: [],
            selected_auditors: [],
            selected_participants: [],

            using_e_mail: false
        }
    }, 
    props:{
        notice: { type:Object, default: ()=>({}) },
        is_rewrite: { type:Boolean, default: false }
    },
    computed: {
        ...mapState('notices', {
            users_in_campaign_universe : state => state.users_in_campaign_universe
        }),
        ...mapGetters('notices', ['owners', 'auditors', 'participants']),
        ...mapState('user_campaigns_list',{
            campaigns: state => state.campaigns
        }),
        ...mapState(['e_mail', 'user_id', 'user_name', 'company_name']),
        target_companies(){
            if(this.$store.state.portfolio.portfolio_target_companies) 
            {
                return this.$store.state.portfolio.portfolio_target_companies
            }
            else{
                return []
            }
        },
        company_selected(){
            return this.$store.state.selected_company 
        },
        campaigns_list_option_obj() {
            let temp_options = [{text: '캠페인을 선택해 주세요', value: 0}]
            this.campaigns.forEach(item => {
                let new_obj = { text: item.campaign_name, value: item.campaign_id }
                temp_options.push(new_obj)
            })
            return temp_options
        },
        notice_target_array(){
            return this.selected_owners.concat(this.selected_auditors, this.selected_participants)
        }
    },
    methods: {
        ...mapActions('notices', ['initiateUsersInCampaignUniverse']),
        checkForm(){
            // console.log(this.target_companies);
            // console.log(this.notice_target_array);
            if (this.notice_title=='') { alert('공지 제목을 입력해 주세요')
                return false
            }
            if (this.notice_content=='') { alert('공지 내용을 입력해 주세요')
                return false
            }
            if ( this.target_companies.length==0 && this.notice_target_array.length==0 ){
                alert('공지 대상을 선택해 주세요') 
                return false
            }
            else { return true }
        },
        handleOK(){
            if (this.checkForm()!=false){
                this.$bvModal.show('notice-preview')
            }
        },
        saveNotice(){
            const path = this.$store.state.backend_host + '/save_notice'
            let notice_target_companies_id = []

            if (this.target_companies != undefined && this.target_companies.length){
                for(var i=0; i<this.target_companies.length; i++) {
                    notice_target_companies_id.push(this.target_companies[i].company_id)
                }
            } //portfolio 모드가 아니면 this.target_companies 자체가 없는 배열임

            let notice_target_array_local = this.notice_target_array
            if (this.notice.notice_id != undefined && this.notice.notice_id !=''){
                notice_target_array_local = []
                this.notice.target_users.forEach(item =>{
                    notice_target_array_local.push(item.e_mail)
                })
            }
            // console.log(notice_target_companies_id);
            // console.log(this.notice_target_mode)

            const formData = new FormData()
            formData.append('notice_target_mode', this.notice_target_mode) //'portfoilo' or 'campaign'
            formData.append('notice_id', this.notice_id)
            formData.append('notice_title', this.notice_title)
            formData.append('notice_content', this.notice_content)
            formData.append('user_name', this.user_name ) 
            formData.append('e_mail', this.e_mail) 
            formData.append('target_companies_id', JSON.stringify(notice_target_companies_id)) // for 'portfolio' mode
            formData.append('target_e_mails', JSON.stringify(notice_target_array_local)) // for 'campaign' mode
            formData.append('using_e_mail', this.using_e_mail)

            axios.post(path, formData)
            .then(response => {
                // console.log(response)
                EventBus.$emit('toast-success', '알림', '공지하였습니다.')
                this.$emit('update-wrote-notice')
                this.initiateForm()
            })
            .catch(error => {
                console.log(error);                
            })
            .finally(
                this.$bvModal.hide('notice-preview')
            )
        },
        initiateForm(){
            this.notice_title = ''
            this.notice_content = ''
            this.$store.commit('update_selected_company', {})
            this.$store.commit('update_portfolio', { portfolio_id: '', portfolio_name: '', portfolio_description: '', portfolio_target_companies: [] })
            this.company_finder_key += 1
            this.selected_campaign_id = 0
        },
        // 포트폴리오 선택 
        readPortfolios(){
            this.is_loading_portfolio = true
            const path = this.$store.state.backend_host + '/read_portfolio'

            return axios.get(path, {
                            params: {
                                user_id: this.user_id
                            }
            })
            .then(response => {
                this.portfolios = response.data.portfolios
            })
            .catch(error => {
                console.log('axios 실패')
                console.log(error)
                if (error.response.status == '401')
                    { alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                     this.$store.dispatch('logout').then(() => { this.$router.push({name:'Login'}) })
                }
            })
        },
        selectPortfolio(new_portfolio){
            this.selected_portfolio = new_portfolio
            this.$store.commit('update_portfolio', new_portfolio)
        },
        applyPortfolio(new_portfolio){
            this.$store.commit('update_portfolio', new_portfolio)
        },
        async selectTargetCampaign(campaign_id){
            await this.initiateUsersInCampaignUniverse(campaign_id)
            // console.log(this.users_in_campaign_universe)
        },
        addAll(origin_array, target_array){
            origin_array.forEach(item => {
                if(target_array.indexOf(item.e_mail) == -1){
                    target_array.push(item.e_mail)
                }
            })
        },
        deleteAll(target_array){
            target_array.splice(0, target_array.length)
        },
        parseAdmin(admin_level){
            if(admin_level == 0){
                return '참여자'
            }
            else if(admin_level == 1)
            {
                return '캠페인 리더'
            }
            else if(admin_level == 2)
            {
                return '실사 전문가'
            }
            else if(admin_level == 3)
            {
                return '캠페인 매니저'
            }
            else if(admin_level == 4)
            {
                return '시스템 관리자'
            }
            else return '기타'
        }
    },
    watch: {
        company_selected: {
            deep: true,
            handler(newVal){
                if (newVal.company_id != undefined && newVal.company_id != '') {
                    //this.is_company_selected = true
                }
                else if (newVal.company_id == undefined || newVal.company_id == ''){
                    //this.is_company_selected = false
                }
            }
        }, 
    },
    created(){
        this.readPortfolios()
    },
    mounted(){
        if (this.notice.notice_id != undefined && this.notice.notice_id != '') {
            this.notice_id = this.notice.notice_id
            this.notice_title = this.notice.notice_title
            this.notice_content = this.notice.notice_content 
        }  
    },
    components:{
        CompanyFinder,
        EditingPortfolio,
    },
    beforeDestroy(){
        this.$store.commit('update_portfolio', {})
    }
    
}
</script>