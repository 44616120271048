<template>
<div>
    <b-row no-gutters class="mb-5">
        <b-col :class="is_editing==true?'col-4 pr-2':'col-6'">
            <h6 class="f-110 mb-3">{{ $t(`등록된 사용자`)}} </h6>
            <div v-if="company_users.length>0">
                <b-button v-for="(item, index) in company_users" :key="item.id" :class="[is_editing==true?'f-90 px-1':'','w-100 mb-1 f-95 text-left']" 
                :variant="e_mail==item.e_mail&&is_editing==true?'blue-border':'white-dark'" @click="editUser(item)">
                    {{ index+1 }}. {{ item.user_name }} 
                    <div :class="is_editing==true?'pl-3':'d-inline pl-2'">{{ item.e_mail }}
                        <b-badge v-if="item.is_admin>=1" :variant="e_mail==item.e_mail&&is_editing==true?'blue-basic':'basic'" class="ml-1"> 
                            <span v-if="item.is_admin>=3">{{ item.is_admin==4? $t(`최고 관리자`) : $t(`캠페인 평가자`) }}</span>
                            <span v-else>{{ item.is_admin==2? $t(`외부 평가자`) : $t(`캠페인 리더`) }}</span>
                        </b-badge>
                        <template v-if="item.excluded_campaigns" >
                            <span v-for="excl_name in item.excluded_campaigns" :key="excl_name.id" >
                                <span v-b-tooltip.hover :title="excl_name + $t(`참여 제한됨. 설정을 바꾸려면 시스템 관리자에 문의하세요.`)" class="red ml-2 cursor">
                                    <fa icon="exclamation-triangle"></fa>
                                </span>
                            </span>
                        </template>
                    </div>
                </b-button>
            </div>
            <div v-else-if="company_users.length==0&&is_editing!=true" class="text-center"> <b-card class="bgray-100 border-0 text-center py-5 mt-2 mb-4 f-90 gray-550"> {{ $t(`등록된 사용자가 없습니다. 신규 사용자를 등록해 주세요.`)}}</b-card></div>
            <div v-if="is_editing_new!=true" class="pt-4 mb-5">
                <b-button variant="dark-border" size="sm" @click="setNewUser"><fa icon="plus" class="mr-1"></fa>{{ $t(`신규 사용자 추가`)}}</b-button>
            </div>

            
        </b-col>
        <b-col v-if="is_editing==true" class="pl-3">
            <h6 class="f-110 mb-3 pl-3"> {{ is_editing_new==true? $t(`신규 사용자 등록`) : $t(`사용자 정보 수정`) }} </h6>
            <div class="border-left pl-3">
                <b-table-simple responsive hover class="input-table f-90 overflow-auto" >
                    <b-tbody>
                        <b-tr><b-td style="width:30%">{{ $t(`성함`)}}*</b-td><b-td style="max-width: 400px;"> 
                            <b-form-input class="narrow-input-form f-90" type="text" v-model.trim="user_name" :placeholder="$t(`성함`)"></b-form-input>
                        </b-td></b-tr>
                        <!-- <b-tr><b-td style="width:30%">{{ $t(`소속 회사 / 부서`)}}</b-td><b-td class="" style="max-width: 400px;"> 
                            {{selected_company.company_name}} / <b-form-input class="d-inline narrow-input-form f-90 w-50 ml-3" type="text" v-model.trim="department_name" :placeholder="$t(`소속 부서`)"></b-form-input>
                        </b-td></b-tr> -->
                        <b-tr><b-td style="width:30%">{{ $t(`이메일 (로그인 ID)`)}}*</b-td><b-td style="max-width: 400px;">  
                            <b-form-input class="narrow-input-form f-90" type="text" v-model.trim="e_mail" :placeholder="$t(`로그인에 사용할 이메일`)"></b-form-input>
                        </b-td></b-tr>
                        <b-tr v-if="is_editing_new!=true"><b-td style="width:30%"> {{is_password_change!=true? $t(`비밀번호 설정`):$t(`기존 비밀번호`)}}</b-td><b-td style="max-width: 400px;">
                            <b-button v-if="is_editing_new!=true&&is_password_change!=true" variant="dark-border" block class="py-1 f-90" @click="toggleChangePassword">{{ $t(`비밀번호 변경하기`)}}</b-button> 
                            <template v-else>
                                <b-form-input class="narrow-input-form f-90" type="password" v-model.trim="password_old" :class="[password_old? 'ih-password':'', '']" :placeholder="$t(`기존 비밀번호 입력`)"></b-form-input>
                            </template>
                        </b-td></b-tr>
                        <b-tr v-if="is_editing_new==true||is_password_change==true"><b-td style="width:30%"> {{ $t(`비밀번호`)}}*</b-td><b-td style="max-width: 400px;">                           
                            <b-form-input class="narrow-input-form f-90" type="password" v-model.trim="password_new1" :class="[password_new1? 'ih-password':'', '']" :placeholder="$t(`4자리 이상`)"></b-form-input>
                            <small class="text-left red">{{ passwordLengthCheck }}</small>
                        </b-td></b-tr>
                        <b-tr v-if="is_editing_new==true||is_password_change==true"><b-td style="width:30%">{{ $t(`비밀번호 확인`)}}*</b-td><b-td style="max-width: 400px;"> 
                            <b-form-input type="password" v-model.trim="password_new2" :class="[password_new2? 'ih-password':'', '']" :placeholder="$t(`비밀번호 한번 더 입력`)"></b-form-input>
                            <small class="text-left red"> {{ passwordLengthAlign }} </small>
                        </b-td></b-tr>
                        <b-tr><b-td> {{ $t(`전화번호`)}} </b-td><b-td class="d-flex align-items-center" style="max-width: 400px;"> 
                            <b-form-input type="text" v-model.trim="tel_office" class="narrow-input-form mr-2 w-50" placeholder="office."></b-form-input> <b-form-input type="text" v-model.trim="tel_mobile" class="narrow-input-form w-50" placeholder="mobile."></b-form-input> 
                        </b-td></b-tr>
                        <b-tr><b-td> {{ $t(`주소`)}} </b-td><b-td style="max-width: 400px;"> 
                            <b-form-input class="narrow-input-form f-90" type="text" v-model.trim="address" :placeholder="$t(`근무지 주소`)"></b-form-input> 
                        </b-td></b-tr>
                        <b-tr v-if="admin_level != 'NOT_DEFINED'"><b-td> {{ $t(`권한 레벨`)}} </b-td><b-td class="text-left" style="max-width: 400px;"> 
                                <b-form-group class="mb-0">
                                    <b-form-radio v-if="admin_level == 'CAMPAIGN_ADMIN' || admin_level == 'CAMPAIGN_MANAGER' || admin_level == 'SUPER_ADMINISTRATOR'" v-model="is_admin" value="0" class="f-90">{{ $t(`일반 참여자`)}} (lv.0)</b-form-radio>
                                    <b-form-radio v-if="admin_level == 'CAMPAIGN_ADMIN' || admin_level == 'CAMPAIGN_MANAGER' || admin_level == 'SUPER_ADMINISTRATOR'" v-model="is_admin" value="1" class="f-90">{{ $t(`캠페인 리더`)}} (lv.1)</b-form-radio>
                                    <b-form-radio v-if="admin_level == 'CAMPAIGN_MANAGER' || admin_level == 'SUPER_ADMINISTRATOR'" v-model="is_admin" value="2" class="f-90">{{ $t(`외부 평가자`)}} (lv.2)</b-form-radio>
                                    <b-form-radio v-if="admin_level == 'SUPER_ADMINISTRATOR'" v-model="is_admin" value="3" class="f-90">{{ $t(`캠페인 평가자`)}} (lv.3)</b-form-radio>
                                    <b-form-radio v-if="admin_level == 'SUPER_ADMINISTRATOR'" v-model="is_admin" value="4" class="f-90">{{ $t(`최고관리자`)}} (lv.4)</b-form-radio>
                                </b-form-group>
                        </b-td></b-tr>
                    </b-tbody>
                </b-table-simple>

                <div class="mt-3 text-right">
                    <b-button @click="saveUser" class="" size="sm" variant="blue"><fa icon="check" class="mr-1"></fa> {{ $t(`등록하기`)}} </b-button>
                    <b-button v-if="is_editing_new!=true" @click="deleteUser" class="ml-2" size="sm" variant="red"> {{ $t(`삭제하기`)}} </b-button>
                    <b-button @click="handleCancel" class="ml-2" size="sm" variant="dark">{{ $t(`취소`)}} </b-button>
                </div>

            </div>
        </b-col>
        <b-col v-else-if="is_editing!=true&&company_users.length>0" class="pl-3 pt-4">
            <b-card :class="[company_users.length>0?'bgray-300':'bgray-100','border-0 text-center py-5 mt-2 f-90 gray-550']"> {{ $t(`수정/삭제할 사용자를 선택하거나, 신규 사용자를 추가해 주세요`)}}</b-card> 
        </b-col>
        <b-col class="col-12">    
            
        </b-col>
    </b-row>

    <b-card v-if="isAdminDefined==true" :class="[company_users.length>0?'':'border-0','f-90 bgray-100 text-black-50 ']">
        <!-- <p class="mt-1 mb-2"> <fa icon="exclamation-triangle" class="red"></fa> : 캠페인 참여 대상이 아닙니다. 참여자는 해당 캠페인에 접속할 수 없습니다. </p> -->
        <b-badge variant="basic f-80 mt-1 mr-1"> {{ $t(`캠페인 리더`)}} </b-badge>{{ $t(`는 신규 참여자를 추가하거나 문항 작성 권한을 조정할 수 있는 관리자입니다.`)}}
        <p class="mt-1 mb-0" v-if="$i18n.locale!='cn' && $i18n.locale!='en'"> 캠페인 리더 계정 변경이 필요한 경우 <a href="mailto:info@qesg.co.kr" class=""> info@qesg.co.kr </a>로 변경 원하시는 정보를 보내주세요.</p>
        <p class="mt-1 mb-0" v-else>{{ $t(`캠페인 리더 계정의 변경이 필요한 경우 info@qesg.co.kr로 변경 원하시는 정보를 보내주세요.`)}} </p>
    </b-card>
    <b-card v-else class="f-90 bgray-100 text-black-50 border-0">   
        <fa icon="exclamation-circle" class="mr-1"></fa>
        <b>{{ selected_company.company_name }}의 캠페인 리더가 등록되어 있지 않습니다. 캠페인 리더를 등록하여 주십시오.</b>
        <div class="mt-1">{{ $t(`캠페인 리더는 신규 참여자를 추가하거나 문항 작성 권한을 조정할 수 있는 관리자입니다.`)}} </div>
    </b-card> 

    <hr v-if="company_users.length==0" class="border-0 py-5 my-5">
    
</div>
</template>

<script>
import * as ih from '@/util'
import { mapState, mapMutations } from 'vuex'

export default {
    data() {
        return {
            user_name: '',
            e_mail: '',
            company_id: '',
            department_name: '',
            tel_office:'',
            tel_mobile:'',
            address: '',
            is_admin: 0,
            password_old: '',
            password_new1: '',
            password_new2: '',

            is_editing: false,
            is_editing_new: false,
            is_password_change: false,
            company_users:[]
        }
    },
    props: {
        // company_users: { type:Array, default:()=>[] },
        //target_company_id: { default: '' }
    },
    computed: {
        ...mapState(['view_mode', 'selected_company']),
        ...mapState('campaign_users', ['users_in_company']),
        is_company_selected() { //관리자 화면에서 사용
            if (this.$store.state.selected_company.company_id != '' ) return true
            else return false
        },
        all_users(){ //관리자 화면에서 사용
            return [...this.$store.state.selected_company.all_users]
        },
        admin_level(){
            return this.$store.getters.getAdminLevel
        },
        passwordLengthCheck() {
            if(this.password_new1 != '') {
                return this.password_new1.length > 3 ? '' : this.$t('비밀번호는 4자리 이상 입력해주세요')
            }
        },
        passwordLengthAlign() {
            if(this.password_new2 != '')
            return this.password_new1.length == this.password_new2.length ? '' : this.$t('패스워드가 일치하는지 확인해 주세요')
        },
        isAdminDefined(){
            if(this.view_mode=='provider'){ // provider 화면일때
                let idx = this.company_users.findIndex(item => item.is_admin >= 1) 
                if (idx > -1) return true
                else return false
            }
            else { // 관리자 화면일 경우 
                if(this.selected_company.hasOwnProperty('all_users')){ 
                    let idx = this.selected_company.all_users.findIndex(item => item.is_admin >= 1)
                    if(idx > -1) return true
                }
                else return false
            }
        },             
    },
    methods: {
        ...mapMutations('campaign_users',['update_users_in_company']),
        editUser(user){
            this.is_editing = true
            this.is_editing_new = false
            
            this.user_name = user.user_name,
            this.e_mail = user.e_mail,
            this.company_id = user.company_id,
            this.department_name = user.department_name,
            this.tel_office = user.tel_office,
            this.tel_mobile = user.tel_mobile,
            this.address = user.address,
            this.is_admin = user.is_admin
        },
        setNewUser(){
            this.is_editing = true
            this.is_editing_new = true
            this.initiateForm()
        },
        saveUser(){
            this.checkForm()
            if(this.checkForm2() !=true) return window.alert(this.$t('* 표시는 필수 입력란 입니다.'))

            const path = this.$store.state.backend_host + '/save_user'

            let target_company_id = 0
            if(this.view_mode=='provider'){
                target_company_id = this.$store.state.company_id
            }
            else target_company_id = this.selected_company.company_id

            const formData = new FormData()
            formData.append('is_password_change', this.is_password_change)
            formData.append('password_old', this.password_old)
            formData.append('password_new', this.password_new1)
            formData.append('user_name', this.user_name)
            formData.append('e_mail', this.e_mail)
            formData.append('company_id', target_company_id)
            formData.append('department_name', this.department_name)
            formData.append('tel_office', this.tel_office)
            formData.append('tel_mobile', this.tel_mobile)
            formData.append('address', this.address)
            formData.append('is_admin',this.is_admin)

            axios.post(path, formData)
            .then(response => {
                let result = response.data
                // console.log(response.data);
                if(this.view_mode=='provider'){ 
                    this.update_users_in_company(result.all_users)                                 
                    EventBus.$emit('toast-success',this.$t('사용자 등록'), this.$t('신규 사용자를 등록하였습니다.'))
                    //`${this.$store.state.company_name}에 ${this.user_name}를 사용자로 등록하였습니다`)
                }
                else { //관리자 화면일 경우 update selected company  
                    this.$store.commit('update_selected_company', result)
                    EventBus.$emit('toast-success',this.$t('사용자 등록'), this.$t('신규 사용자를 등록하였습니다.'))
                    //`${this.$store.state.company_name}에 ${this.user_name}를 사용자로 등록하였습니다`)
                    
                }
                let user_exist_idx = this.company_users.findIndex(user => user.e_mail == this.e_mail) //업데이트인 경우 all_users의 마지막값을 교체함
                // console.log(user_exist_idx);
                if (user_exist_idx > -1){
                    this.company_users.splice(user_exist_idx, 1, result.all_users[user_exist_idx])
                }
                else this.company_users.push(result.all_users.slice(-1)[0]) //신규 저장인 경우 all_users의 마지막값을 push함
                this.initiateForm()
            })
            .catch(error => {
                if (error.response && error.response.status == '401') { 
                    alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`)) 
                    this.$store.dispatch('logout').then(() => { window.location.href = $store.state.frontend_host + '/login'} )
                }
            })
        },
        deleteUser(){
            if (this.e_mail!=''&&this.e_mail!= this.$store.state.e_mail){
                let confirm_delete_user = confirm(this.$t('삭제하면 사용자 설정이 모두 지워지며, 되돌릴 수 없습니다. 삭제하시겠습니까?'))
                if(confirm_delete_user==true){
                    const path = this.$store.state.backend_host + '/delete_user'

                    let formData = new FormData()
                    formData.append('e_mail', this.e_mail)

                    axios.post(path, formData)
                    .then(response => {
                        // console.log(response.data);

                        if(this.view_mode=='provider'){                        
                            let idx = this.company_users.findIndex(item => item.e_mail == this.e_mail)
                            if (idx >-1){
                                this.company_users.splice(idx, 1)
                            }
                            this.update_users_in_company(this.company_users)  
                        }
                        else { //관리자 화면일 경우  
                             let temp_company = ih.deepCopy(this.selected_company)
                            let idx = temp_company.all_users.findIndex(item => item.e_mail == this.e_mail)
                            if(idx > -1){
                                temp_company.all_users.splice(idx, 1)
                                this.company_users.splice(idx, 1)
                            }
                            this.$store.commit('update_selected_company', temp_company)
                            
                        }

                        EventBus.$emit('toast-success', this.$t('삭제'), this.$t(`사용자를 삭제하였습니다.`))

                        this.initiateForm()
                    })
                }                
            }
            else {
                alert(this.$t('삭제 가능한 유저 정보가 없습니다.'))
                this.initiateForm()
            }
        },
        checkForm(){
            if( this.is_password_change == true ) {
                //1) 비밀번호 체크: 기존 비밀번호 입력했는지? 
                if(this.password_old == '') {
                    window.alert(this.$t('기존 비밀번호를 입력해 주세요')) 
                    return false
                }
                //2) 비밀번호 신규 2개가 일치하는지? 
                if(this.password_new1 == '' || this.password_new1 != this.password_new2) {
                    window.alert(this.$t('변경할 비밀번호를 다시 확인해 주세요'))
                    return false
                }
            }
            else return true
        },
        checkForm2(){
            if (this.user_name == '') return false
            else if (this.e_mail == '') return false
            // else if (this.password == '') return false
            // else if (this.tel_office == '') return false
            // else if (this.tel_mobile == '') return false
            else return true
        },
        // isThisUserAdmin(e_mail){
        //     if(this.selected_company.hasOwnProperty('admin_users')){
        //         let idx = this.selected_company.admin_users.findIndex(item => item.e_mail == this.e_mail)
        //         if (idx >-1 ){
        //             return true
        //         }else return false
        //     }
        // },
        initiateForm(){
            this.user_name =''
            this.e_mail = ''
            this.department_name = ''
            this.password_new1 = ''
            this.password_new2 = ''
            this.tel_office = ''
            this.tel_mobile = ''
            this.address = ''
            this.is_admin = 0
        },
        handleCancel(){
            this.is_editing = false
            this.is_editing_new = false
            this.is_password_change = false
            this.initiateForm()
        },
        toggleChangePassword(){
            this.is_password_change = !this.is_password_change
        }
        
    },
    created(){
        if(this.view_mode=='provider'){
            this.company_users = this.users_in_company
        }
        else {
            this.company_users = this.all_users
        }        
        // console.log(this.company_users);
    },
    watch:{
       
    },

}
</script>

<style scoped>

</style>