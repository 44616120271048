
//캠페인-타겟 조직에 대해서 evaluation_result, review_result 를 뿌려주고, 
//post_assessment를 작성할 수 있게 함
import * as th from 'tree-helper'
import * as ih from '@/util'

import { padStart } from "core-js/fn/string"

const postassess = {
    namespaced: true,
    state: {
        pa_evaluation_results: [
            // {   
            //     campaign_id: '',
            //     template_code: '',
            //     company_id: '',
            //     company_name: '',
            //     question_code: '',
            //     question_name: '',
            //     sub_code: '',
            //     evaluation_results: [
            //         {
            //             formula: '', 
            //             evaluation_name: '', 
            //             reverse_name: '',
            //             satisfied: '', 
            //             not_applicable: ''
            //             defined_score: '',
            //             score: '', 
            //             law_info: '', 
            //             author_id: '', 
            //             author_name: '', 
            //             audit_comment: '', 
            //             time_stamp: '',
            //         }
            //     ],
            //     auditor_name: '',
            //     review_comment: '',
            //     task_comment: '',
            //     time_stamp: '',
            // }
        ],
        pa_action_plans: [
            // {
            //     "campaign_id": 63,
            //     "company_id": 94,
            //     "sub_code": "Rs576512089",
            //     "action_plan": "법적 기준을 충족하는 근로계약서를 작성하겠습니다.",
            //     "time_horizon": "단기",
            //     "time_stamp": "2020-11-13 17:36:46"
            // }
        ],
        pa_by_campaign: [ 
            // {
            //     company_id: '',
            //     action_plan_submitted: false,
            //     action_plan_submit_time_stamp: ''
            //     action_plan_confirmed: false,  // 평가자의 confirm 여부
            //     action_plan_confirmed_time_stamp: ''
            //     pa_action_plans:[{
            //         campaign_id: '',
            //         company_id: '',
            //         sub_code: '',
            //         action_plan: '',
            //         time_horizon: '',
            //         time_stamp: ''
            //     }]
            // }
        ],
        company_action_plan_submitted: false,  // 협력사 화면에서 제출 여부 확인,
        template_in_progress:[]
    },
    mutations:{
        update_pa_evaluation_results(state, new_pa_evaluation_results){
            state.pa_evaluation_results = new_pa_evaluation_results
        },
        update_pa_action_plans(state, new_pa_action_plans){
            state.pa_action_plans = new_pa_action_plans
        },
        empty_pa_by_campaign_list(state){
            state.pa_by_campaign = []
        },
        update_pa_by_campaign_list(state, {company_id}){
            let new_obj = {
                company_id: company_id,
                action_plan_submitted: false,
                action_plan_submit_time_stamp: '',
                action_plan_confirmed: false,
                action_plan_confirmed_time_stamp: '',
                pa_action_plans: []
            }
            state.pa_by_campaign.push(new_obj)
        },
        add_or_update_pa_submitted_by_campaign(state, new_arr){
            let temp_pas = [...state.pa_by_campaign]
            for(let r=0; r<new_arr.length; r++){
                let idx = temp_pas.findIndex(x => x.company_id == new_arr[r].company_id)
                if (idx >-1){
                    temp_pas[idx].action_plan_submitted = new_arr[r].submit
                    temp_pas[idx].action_plan_submit_time_stamp = new_arr[r].time_stamp
                }else {
                    console.log('여기로 오면 에러');
                    // let new_obj = {
                    //     company_id: new_arr[r].company_id,
                    //     action_plan_submitted: new_arr[r].submit,
                    //     action_plan_confirmed: false,
                    //     pa_action_plans: []
                    // }
                    // temp_pas.push(new_obj)
                }
            }
            console.log('제출여부 업데이트');
            state.pa_by_campaign = temp_pas
        },
        add_or_update_pa_plans_by_campaign(state, new_arr){
            let temp_pas = [...state.pa_by_campaign]
            for(let z=0; z<new_arr.length; z++){
                let idx = temp_pas.findIndex(x => x.company_id == new_arr[z].company_id)
                if(idx >-1){
                    let jdx = temp_pas[idx].pa_action_plans.findIndex(y => y.action_id == new_arr[z].action_id)
                    if (jdx >-1){
                        temp_pas[idx].pa_action_plans.splice(jdx, 1, new_arr[z])
                    }
                    else temp_pas[idx].pa_action_plans.push(new_arr[z])
                }else {
                    console.log('여기로 오면 에러');
                    // let temp_arr = []
                    // temp_arr.push(new_arr[z])
                    // let new_obj = {
                    //     company_id: new_arr[z].company_id,
                    //     action_plan_submitted: false,
                    //     action_plan_confirmed: false,
                    //     pa_action_plans: temp_arr
                    // }
                    // temp_pas.push(new_obj)
                }
                // console.log(temp_pas);                
            }
            console.log('액션플랜 업데이트');
            state.pa_by_campaign = temp_pas
        },
        add_or_update_pa_plan_confirmed(state, new_company_confirmed){ 
            // console.log(new_company_confirmed);
            let temp_pas = [...state.pa_by_campaign]
            let idx = temp_pas.findIndex(x => x.company_id == new_company_confirmed.company_id)
            if(idx >-1){
                temp_pas[idx].action_plan_confirmed = new_company_confirmed.confirmed
                temp_pas[idx].action_plan_confirmed_time_stamp = new_company_confirmed.time_stamp
            }else {
                // console.log(new_company_confirmed.company_id);
            }
            console.log('컨펌여부 업데이트');
            // console.log(temp_pas);
            state.pa_by_campaign = temp_pas
        },
        add_or_update_pa_action_plan(state, {campaign_id, company_id, sub_code, action_plan, time_horizon, time_stamp} ){
            let temp_pa_action_plans = [...state.pa_action_plans]

            let idx = temp_pa_action_plans.findIndex( item => item.campaign_id==campaign_id && item.company_id == company_id && item.sub_code == sub_code )
            if (idx > -1){
                temp_pa_action_plans[idx].action_plan = action_plan
                temp_pa_action_plans[idx].time_horzion = time_horizon
                temp_pa_action_plans[idx].time_stamp = time_stamp
            }
            else{
                temp_pa_action_plans.push({ 
                    campaign_id: campaign_id,
                    company_id: company_id, 
                    sub_code: sub_code, 
                    action_plan: action_plan, 
                    time_horzion: time_horizon, 
                    time_stamp: time_stamp
                })
            }
            //store 인식
            state.pa_action_plans = temp_pa_action_plans
        },
        update_company_action_plan_submitted(state, new_company_submitted_info){ // 협력사 화면에서 제출 여부 확인
            if (new_company_submitted_info.submit == true || new_company_submitted_info.submit == 1 ) {
                state.company_action_plan_submitted = true
            }
            else state.company_action_plan_submitted = false
        },
        update_template_in_progress(state, new_template){
            state.template_in_progress = new_template
        },
        make_template_tree(state, question_array){
            th.breadthFirstSearch(state.template_in_progress.template_contents, node => {
              let idx = question_array.findIndex( item => { return item.question_code == node.question_code })
              if(idx >-1){
                node.question_name = question_array[idx].question_name
                node.question_infobox = question_array[idx].question_infobox
                node.question_contents = question_array[idx].question_contents
                node.visible = question_array[idx].visible
                node.writable = question_array[idx].writable
                node.number_of_comment = question_array[idx].number_of_comment
                node.number_of_new_comment = question_array[idx].number_of_new_comment
                node.is_completed = question_array[idx].is_completed
                node.comment = question_array[idx].comment
    
                let x = node
                while((x.visible == true || x.writable == true) && x.parent){
                  x.parent.visible = x.visible
                  x.parent.writable = x.writable
                  x = x.parent
                }
    
              }
            })
        }

    },
    getters: {
        ev_results_arr(state){
            let temp_arr = ih.deepCopy(state.pa_evaluation_results)
            let temp_list = []
            for (let i=0; i<temp_arr.length; i++){      
                let j = temp_arr[i].evaluation_results.filter( x => x.score != null && x.satisfied==false && x.evaluation_name!= '기타')
                if ( j.length> 0){
                    temp_arr[i].evaluation_results = j
                    temp_list.push(temp_arr[i])
                }
            }
            return temp_list
        },
        ev_results_hash(state){
            let temp_arr = ih.deepCopy(state.pa_evaluation_results)
            let temp_hash = {}
            for (let i=0; i<temp_arr.length; i++){      
                let j = temp_arr[i].evaluation_results.filter( x => x.score != null && x.satisfied==false && x.evaluation_name!= '기타')
                if ( j.length> 0){
                    temp_arr[i].evaluation_results = j
                    temp_hash[temp_arr[i].sub_code] = temp_arr[i]
                }
            }
            return temp_hash
        },
        ev_results_hash(state){
            let temp_arr = ih.deepCopy(state.pa_evaluation_results)
            let temp_hash = {}
            for (let i=0; i<temp_arr.length; i++){      
                let j = temp_arr[i].evaluation_results.filter( x => x.score != null && x.satisfied==false && x.evaluation_name!= '기타')
                if ( j.length> 0){
                    temp_arr[i].evaluation_results = j
                    temp_hash[temp_arr[i].sub_code] = temp_arr[i]
                }
            }
            return temp_hash
        },
        action_plan_hash(state){
            let temp_ev_results = [...state.pa_evaluation_results]
            let temp_action_plans = [...state.pa_action_plans]
            let temp_hash = {}
            for (let i=0; i<temp_ev_results.length; i++){
                temp_hash[temp_ev_results[i].sub_code] = {
                    campaign_id: '',
                    company_id: '',
                    sub_code: '',
                    action_plan: '',
                    time_horizon: '',
                    time_stamp: ''
                }
            }
            for (let j=0; j<temp_action_plans.length; j++){
                if (temp_hash.hasOwnProperty(temp_action_plans[j].sub_code)){
                    temp_hash[temp_action_plans[j].sub_code] = temp_action_plans[j]
                }
            }
            return temp_hash
        }
    },
    actions: {
        readPAEvaluationResults({commit, rootState}, { campaign_id, company_id }){
            const path = rootState.backend_host + '/read_pa_evaluation_results'

            return axios.get( path, { params: {
                campaign_id: campaign_id,
                company_id: company_id
            }})
            .then( response => {
                // console.log(response.data);
                commit('update_pa_evaluation_results', response.data)
            })
        },
        readPAActionPlans({commit, rootState}, {campaign_id, company_id}){
            const path = rootState.backend_host + '/read_pa_action_plans'

            return axios.get( path, { params: {
                campaign_id: campaign_id, 
                company_id: company_id
            }})
            .then( response => {
                // console.log(response.data);
                commit('update_pa_action_plans', response.data)
            })
        },
        readPAActionPlansByCampaign({commit, rootState}, {campaign_id}){
            const path = rootState.backend_host + '/read_pa_action_plans_by_campaign'

            return axios.get( path, { params: {
                campaign_id: campaign_id
            }})
            .then( response => {
                // console.log(response.data);
                commit('add_or_update_pa_plans_by_campaign', response.data)
            })
        },
        readPAActionPlansSubmitted({commit, rootState}, {campaign_id, company_id}){ // 협력사 화면에서 읽을 때는 company_id도 보냄
            const path = rootState.backend_host + '/read_action_submit'

            if(company_id==''||company_id==undefined||company_id==null){
                company_id == null
            }

            return axios.get( path, { params: {
                campaign_id: campaign_id,
                company_id : company_id
            }})
            .then( response => {
                console.log(response.data);
                if(company_id!=null&& response.data.length>0){
                    commit('update_company_action_plan_submitted', response.data[0])
                }
                else commit('add_or_update_pa_submitted_by_campaign', response.data)
            })
        },
        readMonitoringConfirm({commit, rootState}, {campaign_id}){
            const path = rootState.backend_host + '/read_monitoring_confirm'

            return axios.get( path, { params: {
                campaign_id: campaign_id
            }})
            .then( response => {
                // console.log(response.data);
                if (response.data[0]){
                    commit('add_or_update_pa_plan_confirmed', response.data[0])
                }
            })
        },
        savePAActionPlan({commit, rootState}, {campaign_id, company_id, sub_code, action_plan, time_horizon}){
            const path = rootState.backend_host + '/save_pa_action_plan'

            if(action_plan==''||action_plan==undefined||action_plan==null){
                action_plan == null
            }
            if(time_horizon==''||time_horizon==undefined||time_horizon==null){
                time_horizon == null
            }

            let formData = new FormData()
            formData.append('campaign_id', campaign_id)
            formData.append('company_id', company_id)
            formData.append('sub_code', sub_code)
            formData.append('action_plan', action_plan)
            formData.append('time_horizon', time_horizon)

            return axios.post( path, formData )
            .then( response => {
                // console.log(response.data);
                commit('add_or_update_pa_action_plan', response.data)
                
            })
        },
        savePAActionPlanSubmit({commit, rootState}, {campaign_id, company_id, submit}){
            const path = rootState.backend_host + '/save_action_submit'

            let formData = new FormData()
            formData.append('campaign_id', campaign_id)
            formData.append('company_id', company_id)
            formData.append('submit', submit)
            return axios.post( path, formData )
            .then( response => {
                // console.log(response.data);
                commit('update_company_action_plan_submitted', response.data)
            })            
        },
        saveMonitoringConfirm({commit,  rootState}, {campaign_id, company_id, confirmed}){
            const path = rootState.backend_host + '/save_monitoring_confirm'

            let formData = new FormData()
            formData.append('campaign_id', campaign_id)
            formData.append('company_id', company_id)
            formData.append('confirmed', confirmed)
            return axios.post( path, formData )
            .then( response => {
                // console.log(response.data);
                commit('add_or_update_pa_plan_confirmed', response.data)
            })    
        },
        readTemplateInProgress({commit,  rootState}, {template_code} ){
            const path = rootState.backend_host + '/read_template'
            const new_template_tree = {
                template_name: '',
                template_code: '',
                template_scoring: '',
                template_contents: []
            }
            return axios.get(path, {
                            params: {
                                template_code: template_code
                            }
            })
            .then(response => {
                this.result = response.data
                new_template_tree.template_name = this.result.template_name
                new_template_tree.template_code = this.result.template_code
                new_template_tree.template_scoring = this.result.template_scoring
                new_template_tree.template_contents = ih.JSONParse(this.result['template_contents'])

                commit('update_template_in_progress', new_template_tree)
                // this.dispatch('makeTemplateTree')


                const path = rootState.backend_host + '/make_template_tree'
      
                return axios.get(path, { params: {
                    campaign_id: rootState.campaign.campaign_id,
                    e_mail: rootState.e_mail
                }})
                .then(response => {
                    // console.log(response.data);
                    commit('make_template_tree', response.data)
                })
                .catch( error => {
                    console.log(error)
                })
            })
            .catch(error => {
                console.log(error)
            })
        },
        // makeTemplateTree({commit, rootState}) {
        //     console.log('여기까지 오기는 하나요?');
        //     const path = rootState.backend_host + '/make_template_tree'
  
        //     return axios.get(path, { params: {
        //         campaign_id: rootState.campaign.campaign_id,
        //         e_mail: rootState.e_mail
        //     }})
        //     .then(response => {
        //         console.log(response.data);
        //         commit('make_template_tree', response.data)
        //     })
        //     .catch( error => {
        //         console.log(error)
        //     })
        //   },
    }
}

export default postassess