<template>
<div>
    <template v-if="is_monitoring_confirmed_loaded==true">
        <div class="d-md-flex justify-content-between mb-5" style="margin-left:-1rem; margin-right:-1rem;">
            <div class="w-100 mx-3 mb-3 mb-md-0">
                <h6 class="f-120 mb-2"> 개선계획 제출률 </h6>
                <div class="monitoring-block bgray-200 w-100 d-flex align-items-center justify-content-center position-relative">
                    <div class="f-260 mb-2">{{ Math.round((count_submitted/temp_target_companies.length)*100) }} <span class="f-100">%</span></div>
                    <div class="text-right f-80 px-3 pb-2 w-100 position-absolute" style="bottom:0;"> * 전체 {{temp_target_companies.length}}개 기업 중 {{count_submitted}}개사 제출</div>                 
                </div>
            </div>
            <div class="w-100 mx-3 mb-3 mb-md-0">
                <h6 class="f-120 mb-2"> 전체 개선계획 및 개선과제 수 </h6>
                <div class="monitoring-block bgray-200 w-100 d-flex align-items-center justify-content-center position-relative"> 
                    <div class="mb-2"><span class="f-260">{{ count_actions }}</span>개 계획</div> <span class="px-3">/</span>
                    <div class="f-260 mb-2">{{ count_recommendations }}<span class="f-100">개 과제</span></div>
                </div>
            </div>
            <div class="w-100 mx-3 mb-3 mb-md-0">
                <h6 class="f-120 mb-2"> 전문가 컨펌 현황 </h6>
                <div class="monitoring-block bgray-200 w-100 d-flex align-items-center justify-content-center position-relative">
                    <div class="mb-2"><span class="f-260">{{ Math.round((confirmed_companies/temp_target_companies.length)*100) }}</span>%</div>
                    <div class="text-right f-80 px-3 pb-2 w-100 position-absolute" style="bottom:0;"> * 전체 {{temp_target_companies.length}}개 기업 중 {{confirmed_companies}}개사 컨펌완료</div>                 
                </div>
            </div>
        </div>
        <!-- <h4 class="pt-4 pb-2 fw-600"> 개선과제 대시보드 </h4>
        <div class="bgray-100 py-5 mb-5 w-100"></div> -->

        <hr class="border-0 pb-4">

        <h4 class="mb-5 fw-600">기업별 후속 모니터링</h4>

        <b-table-simple hover responsive class="rep-table">
            <b-thead class="">
                <b-tr class="">
                    <b-td>기업명</b-td>
                    <b-td>개선계획 제출 현황</b-td>
                    <b-td>개선계획/개선과제 수</b-td>
                    <b-td>개선과제 및 계획 보고서</b-td>
                    <b-td>전문가 컨펌</b-td>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="item in reviews" :key="item.id">
                    <b-td> {{ findCompanyName(item.company_id) }} </b-td>
                    <b-td>
                        <span v-if="item.action_submit!=undefined"> {{ item.action_submit.submit==true?'제출함':'미제출' }} 
                            <span class="ml-1 f-80 fw-300"> {{ item.action_submit.submit==true? item.action_submit.time_stamp.slice(0,10):'' }}</span>
                        </span>
                    </b-td>
                    <b-td>
                        <span v-if="item.corrective_actions!=undefined"> {{ item.corrective_actions.length }}</span> / <span v-if="item.audit_recommendations!=undefined">{{ item.audit_recommendations.length }}</span> 
                    </b-td>
                    <b-td> 
                        <b-button @click="showMonitoringReport(item.company_id)" variant="dark-border" size="sm"> {{ item.action_submit.submit==true? '보기':'(미제출) 현황보기' }} </b-button> 
                    </b-td>
                    <b-td>
                        <span v-if="item.action_submit!=undefined">
                            <span v-if="item.action_submit.submit==true&&findMonitoringConfirm(item.company_id)==true" class="green"> <fa icon="check" class="mr-2"></fa>완료</span>
                            <span v-else-if="item.action_submit.submit==true&&findMonitoringConfirm(item.company_id)!=true" class="red" v-b-tooltip.hover title="개선과제 및 계획 보고서 내 '컨펌하기'를 통해 컨펌할 수 있습니다."> 컨펌필요 </span>
                            <span v-else class="gray-500">(미제출)</span>
                        </span>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>


        <b-modal id="action-report" size="xl" hide-footer :title="campaign.campaign_name">
            <corrective-action-report :monitoring_company_id="monitoring_company_id"></corrective-action-report>
        </b-modal>

    </template>
    <template v-else >
        <b-card class="py-5 border-0 blue text-center"><b-spinner class="mx-2 p-1 my-1" small type="grow"></b-spinner> 모니터링 현황을 가져오고 있습니다.</b-card>
    </template>

</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import CorrectiveActionReport from '@/components/managing_campaigns/improve_corrective/CorrectiveActionReport'

export default {
    data() {
        return {
            monitoring_company_id:''            
        }
    },
    computed:{
        ...mapState('score', ['temp_target_companies']),
        ...mapState('reviews', ['reviews', 'is_corrective_actions_loaded', 'is_monitoring_confirmed_loaded']),
        campaign() {
            return this.$store.state.campaign
        },
        count_submitted(){
            let submitted = []
            this.reviews.forEach(x => { if (x.action_submit.submit == true){
                submitted.push(x)
            }})
            return submitted.length
        },
        count_actions(){
            let temp_count_actions = []
            this.reviews.forEach(x => {
                temp_count_actions.push( x.corrective_actions.length)
            })
            return temp_count_actions.reduce((a, b) => a + b)
        },
        count_recommendations(){
            let temp_count_recommendations = []
            this.reviews.forEach(x => {
                temp_count_recommendations.push( x.audit_recommendations.length)
            })
            return temp_count_recommendations.reduce((a, b) => a + b)
        },
        confirmed_companies(){
            let temp_confirmed = []
            this.reviews.forEach(x => { if (x.monitoring_confirm.confirmed == true){
                temp_confirmed.push(x)
            }})
            return temp_confirmed.length
        }
        
    },
    methods:{
        ...mapMutations('score', ['update_temp_target_companies', 'add_temp_target_companies']),
        findCompanyName(company_id){
            // console.log(this.temp_target_companies);
            return this.temp_target_companies.find(x => x.company_id == company_id).company_name            
        },
        findMonitoringConfirm(company_id){
            let idx = this.reviews.findIndex(item => item.company_id == company_id)
            if (idx > -1){
                return this.reviews[idx].monitoring_confirm.confirmed
            }
            else return {}
        },  
        showMonitoringReport(company_id){
            this.monitoring_company_id = company_id
            this.$bvModal.show('action-report')
        }

    },
    created(){
       
    },
    components:{
        CorrectiveActionReport
    }

}
</script>

<style scoped>
.monitoring-block{
    height: 8rem;
}

</style>