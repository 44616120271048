<template>
  <div class="bgray-100 text-left">
    <b-navbar toggleable="sm" type="dark" class="bgray-100 header-nav">
        <b-navbar-brand><router-link to="/"><b-img class="header-logo" src="@/assets/Tier Zero_basic.svg"></b-img></router-link></b-navbar-brand>
    </b-navbar>

    <b-container class="py-5">
        <router-view></router-view>
        <!-- <h2 class="pt-5 mb-4 fw-600"> {{ mode }} </h2>
        
        <b-card v-if="mode=='이용약관'" class="mb-5 border-0 shadow-sm">
            <terms-of-use></terms-of-use>
        </b-card>
        <b-card v-else-if="mode=='개인정보 처리방침'" class="mb-5 border-0 shadow-sm"> 
            <privacy-policy></privacy-policy>
        </b-card>     -->
    </b-container>

    <div class="w-100 py-5 bgray-100 text-center f-90">
        <span class="pl-5"> Copyright 2021. </span> <span class="pr-2">QuantifiedESG, Inc.</span> <span v-if="$i18n.locale=='ko'">(주)퀀티파이드이에스지</span>
    </div>
  </div>
</template>

<script>
import TermsOfUse from '@/components/provider_landing/service_policies/TermsOfUse'
import PrivacyPolicy from '@/components/provider_landing/service_policies/PrivacyPolicy'

export default {
    data() {
        return {
           
        }
    },
    computed:{
        mode(){
            return this.$store.state.service_policy_mode
        }
    },
    methods:{

    },
    components: {
        TermsOfUse,
        PrivacyPolicy
    },
    created(){
        console.log(this.$router);
    }

}
</script>

<style>

</style>