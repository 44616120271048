
const hmc_total_score_obj =
            [
                {
                "company_id": 5294,
                "total_score": 87
                },
                {
                "company_id": 4552,
                "total_score": 78
                },
                {
                "company_id": 3705,
                "total_score": 78
                },
                {
                "company_id": 4496,
                "total_score": 69
                },
                {
                "company_id": 1651,
                "total_score": 87
                },
                {
                "company_id": 3351,
                "total_score": 90
                },
                {
                "company_id": 4445,
                "total_score": 66
                },
                {
                "company_id": 3125,
                "total_score": 93
                },
                {
                "company_id": 4529,
                "total_score": 83
                },
                {
                "company_id": 3497,
                "total_score": 91
                },
                {
                "company_id": 3142,
                "total_score": 89
                },
                {
                "company_id": 2043,
                "total_score": 89
                },
                {
                "company_id": 4435,
                "total_score": 80
                },
                {
                "company_id": 3291,
                "total_score": 91
                },
                {
                "company_id": 3253,
                "total_score": 96
                },
                {
                "company_id": 3133,
                "total_score": 79
                },
                {
                "company_id": 3064,
                "total_score": 95
                },
                {
                "company_id": 1902,
                "total_score": 83
                },
                {
                "company_id": 3594,
                "total_score": 93
                },
                {
                "company_id": 3507,
                "total_score": 90
                },
                {
                "company_id": 3255,
                "total_score": 82
                },
                {
                "company_id": 3237,
                "total_score": 75
                },
                {
                "company_id": 4235,
                "total_score": 97
                },
                {
                "company_id": 4535,
                "total_score": 76
                },
                {
                "company_id": 2209,
                "total_score": 95
                },
                {
                "company_id": 3094,
                "total_score": 84
                },
                {
                "company_id": 3499,
                "total_score": 83
                },
                {
                "company_id": 4440,
                "total_score": 72
                },
                {
                "company_id": 3135,
                "total_score": 66
                },
                {
                "company_id": 3484,
                "total_score": 82
                },
                {
                "company_id": 3436,
                "total_score": 89
                },
                {
                "company_id": 3405,
                "total_score": 79
                },
                {
                "company_id": 1942,
                "total_score": 82
                },
                {
                "company_id": 3641,
                "total_score": 87
                },
                {
                "company_id": 3355,
                "total_score": 65
                },
                {
                "company_id": 3145,
                "total_score": 86
                },
                {
                "company_id": 4421,
                "total_score": 97
                },
                {
                "company_id": 3496,
                "total_score": 73
                },
                {
                "company_id": 4351,
                "total_score": 85
                },
                {
                "company_id": 4422,
                "total_score": 78
                },
                {
                "company_id": 3549,
                "total_score": 76
                },
                {
                "company_id": 3721,
                "total_score": 85
                },
                {
                "company_id": 4557,
                "total_score": 85
                },
                {
                "company_id": 3163,
                "total_score": 76
                },
                {
                "company_id": 3117,
                "total_score": 71
                },
                {
                "company_id": 3282,
                "total_score": 84
                },
                {
                "company_id": 3065,
                "total_score": 80
                },
                {
                "company_id": 3264,
                "total_score": 87
                },
                {
                "company_id": 3498,
                "total_score": 87
                },
                {
                "company_id": 4173,
                "total_score": 84
                },
                {
                "company_id": 4509,
                "total_score": 67
                },
                {
                "company_id": 3542,
                "total_score": 68
                },
                {
                "company_id": 3569,
                "total_score": 87
                },
                {
                "company_id": 3659,
                "total_score": 93
                },
                {
                "company_id": 4350,
                "total_score": 91
                },
                {
                "company_id": 1961,
                "total_score": 83
                },
                {
                "company_id": 2968,
                "total_score": 83
                },
                {
                "company_id": 3302,
                "total_score": 80
                },
                {
                "company_id": 4174,
                "total_score": 82
                },
                {
                "company_id": 3105,
                "total_score": 74
                },
                {
                "company_id": 4506,
                "total_score": 73
                },
                {
                "company_id": 3072,
                "total_score": 80
                },
                {
                "company_id": 2047,
                "total_score": 84
                },
                {
                "company_id": 3587,
                "total_score": 74
                },
                {
                "company_id": 1901,
                "total_score": 70
                },
                {
                "company_id": 3512,
                "total_score": 84
                },
                {
                "company_id": 3216,
                "total_score": 54
                },
                {
                "company_id": 3092,
                "total_score": 90
                },
                {
                "company_id": 3557,
                "total_score": 92
                },
                {
                "company_id": 2050,
                "total_score": 56
                },
                {
                "company_id": 4305,
                "total_score": 78
                },
                {
                "company_id": 3181,
                "total_score": 85
                },
                {
                "company_id": 4491,
                "total_score": 72
                },
                {
                "company_id": 3635,
                "total_score": 83
                },
                {
                "company_id": 4300,
                "total_score": 62
                },
                {
                "company_id": 3190,
                "total_score": 87
                },
                {
                "company_id": 1975,
                "total_score": 91
                },
                {
                "company_id": 4303,
                "total_score": 82
                },
                {
                "company_id": 3251,
                "total_score": 97
                },
                {
                "company_id": 3408,
                "total_score": 97
                },
                {
                "company_id": 3647,
                "total_score": 89
                },
                {
                "company_id": 4143,
                "total_score": 86
                },
                {
                "company_id": 3683,
                "total_score": 72
                },
                {
                "company_id": 3576,
                "total_score": 70
                },
                {
                "company_id": 4149,
                "total_score": 65
                },
                {
                "company_id": 3277,
                "total_score": 57
                },
                {
                "company_id": 3100,
                "total_score": 87
                },
                {
                "company_id": 3234,
                "total_score": 58
                },
                {
                "company_id": 3234,
                "total_score": 58
                },
                {
                "company_id": 3244,
                "total_score": 89
                },
                {
                "company_id": 3201,
                "total_score": 94
                },
                {
                "company_id": 5129,
                "total_score": 85
                },
                {
                "company_id": 4002,
                "total_score": 77
                },
                {
                "company_id": 3582,
                "total_score": 84
                },
                {
                "company_id": 5369,
                "total_score": 95
                },
                {
                "company_id": 5627,
                "total_score": 93
                },
                {
                "company_id": 4211,
                "total_score": 85
                },
                {
                "company_id": 4034,
                "total_score": 68
                },
                {
                "company_id": 4034,
                "total_score": 68
                },
                {
                "company_id": 4230,
                "total_score": 88
                },
                {
                "company_id": 3473,
                "total_score": 87
                },
                {
                "company_id": 5454,
                "total_score": 91
                },
                {
                "company_id": 4998,
                "total_score": 91
                },
                {
                "company_id": 3445,
                "total_score": 83
                },
                {
                "company_id": 4423,
                "total_score": 91
                },
                {
                "company_id": 5429,
                "total_score": 91
                },
                {
                "company_id": 4128,
                "total_score": 78
                },
                {
                "company_id": 5603,
                "total_score": 78
                },
                {
                "company_id": 4127,
                "total_score": 93
                },
                {
                "company_id": 4103,
                "total_score": 85
                },
                {
                "company_id": 4556,
                "total_score": 95
                },
                {
                "company_id": 5121,
                "total_score": 74
                },
                {
                "company_id": 4159,
                "total_score": 93
                },
                {
                "company_id": 5036,
                "total_score": 77
                },
                {
                "company_id": 5009,
                "total_score": 87
                },
                {
                "company_id": 3701,
                "total_score": 36
                },
                {
                "company_id": 5044,
                "total_score": 95
                },
                {
                "company_id": 5092,
                "total_score": 89
                },
                {
                "company_id": 5041,
                "total_score": 91
                },
                {
                "company_id": 5565,
                "total_score": 88
                },
                {
                "company_id": 4279,
                "total_score": 91
                },
                {
                "company_id": 5401,
                "total_score": 87
                },
                {
                "company_id": 3235,
                "total_score": 95
                },
                {
                "company_id": 5428,
                "total_score": 91
                },
                {
                "company_id": 5653,
                "total_score": 90
                },
                {
                "company_id": 5653,
                "total_score": 90
                },
                {
                "company_id": 5403,
                "total_score": 95
                },
                {
                "company_id": 4264,
                "total_score": 89
                },
                {
                "company_id": 5531,
                "total_score": 88
                },
                {
                "company_id": 5076,
                "total_score": 91
                },
                {
                "company_id": 3425,
                "total_score": 85
                },
                {
                "company_id": 5424,
                "total_score": 84
                },
                {
                "company_id": 4314,
                "total_score": 90
                },
                {
                "company_id": 5493,
                "total_score": 59
                },
                {
                "company_id": 4392,
                "total_score": 55
                },
                {
                "company_id": 5561,
                "total_score": 90
                },
                {
                "company_id": 4083,
                "total_score": 97
                },
                {
                "company_id": 5611,
                "total_score": 91
                },
                {
                "company_id": 3998,
                "total_score": 69
                },
                {
                "company_id": 3214,
                "total_score": 83
                },
                {
                "company_id": 3366,
                "total_score": 89
                },
                {
                "company_id": 4488,
                "total_score": 79
                },
                {
                "company_id": 4281,
                "total_score": 54
                },
                {
                "company_id": 5669,
                "total_score": 93
                },
                {
                "company_id": 3232,
                "total_score": 86
                },
                {
                "company_id": 4327,
                "total_score": 88
                },
                {
                "company_id": 5654,
                "total_score": 91
                },
                {
                "company_id": 4109,
                "total_score": 89
                },
                {
                "company_id": 3309,
                "total_score": 81
                },
                {
                "company_id": 5671,
                "total_score": 89
                },
                {
                "company_id": 5319,
                "total_score": 95
                },
                {
                "company_id": 5670,
                "total_score": 93
                },
                {
                "company_id": 4441,
                "total_score": 86
                },
                {
                "company_id": 3621,
                "total_score": 91
                },
                {
                "company_id": 3621,
                "total_score": 91
                },
                {
                "company_id": 5754,
                "total_score": 90
                },
                {
                "company_id": 5731,
                "total_score": 83
                },
                {
                "company_id": 3114,
                "total_score": 91
                },
                {
                "company_id": 4199,
                "total_score": 86
                },
                {
                "company_id": 4028,
                "total_score": 89
                },
                {
                "company_id": 4308,
                "total_score": 93
                },
                {
                "company_id": 4558,
                "total_score": 70
                },
                {
                "company_id": 5402,
                "total_score": 91
                },
                {
                "company_id": 5811,
                "total_score": 79
                },
                {
                "company_id": 4038,
                "total_score": 86
                },
                {
                "company_id": 4573,
                "total_score": 84
                },
                {
                "company_id": 3529,
                "total_score": 88
                },
                {
                "company_id": 4967,
                "total_score": 84
                },
                {
                "company_id": 5321,
                "total_score": 90
                },
                {
                "company_id": 4253,
                "total_score": 93
                },
                {
                "company_id": 5728,
                "total_score": 85
                },
                {
                "company_id": 5325,
                "total_score": 95
                },
                {
                "company_id": 3902,
                "total_score": 71
                },
                {
                "company_id": 4319,
                "total_score": 87
                },
                {
                "company_id": 5716,
                "total_score": 79
                },
                {
                "company_id": 4553,
                "total_score": 89
                },
                {
                "company_id": 5400,
                "total_score": 91
                },
                {
                "company_id": 4094,
                "total_score": 87
                },
                {
                "company_id": 4105,
                "total_score": 94
                },
                {
                "company_id": 5683,
                "total_score": 87
                },
                {
                "company_id": 4424,
                "total_score": 93
                },
                {
                "company_id": 4404,
                "total_score": 24
                },
                {
                "company_id": 5684,
                "total_score": 87
                },
                {
                "company_id": 4227,
                "total_score": 86
                },
                {
                "company_id": 3746,
                "total_score": 87
                },
                {
                "company_id": 3531,
                "total_score": 85
                },
                {
                "company_id": 5685,
                "total_score": 87
                },
                {
                "company_id": 5687,
                "total_score": 87
                },
                {
                "company_id": 5688,
                "total_score": 85
                },
                {
                "company_id": 3196,
                "total_score": 88
                },
                {
                "company_id": 5689,
                "total_score": 85
                },
                {
                "company_id": 5675,
                "total_score": 87
                },
                {
                "company_id": 5682,
                "total_score": 87
                },
                {
                "company_id": 5775,
                "total_score": 94
                },
                {
                "company_id": 5775,
                "total_score": 94
                },
                {
                "company_id": 4433,
                "total_score": 86
                },
                {
                "company_id": 5804,
                "total_score": 85
                },
                {
                "company_id": 5003,
                "total_score": 95
                },
                {
                "company_id": 5065,
                "total_score": 88
                },
                {
                "company_id": 5295,
                "total_score": 77
                },
                {
                "company_id": 4463,
                "total_score": 90
                },
                {
                "company_id": 3906,
                "total_score": 77
                },
                {
                "company_id": 4331,
                "total_score": 75
                },
                {
                "company_id": 3989,
                "total_score": 76
                },
                {
                "company_id": 4332,
                "total_score": 87
                },
                {
                "company_id": 5365,
                "total_score": 87
                },
                {
                "company_id": 4021,
                "total_score": 78
                },
                {
                "company_id": 5290,
                "total_score": 80
                },
                {
                "company_id": 3192,
                "total_score": 66
                },
                {
                "company_id": 5347,
                "total_score": 71
                },
                {
                "company_id": 5442,
                "total_score": 52
                },
                {
                "company_id": 4071,
                "total_score": 89
                },
                {
                "company_id": 3419,
                "total_score": 66
                },
                {
                "company_id": 5744,
                "total_score": 92
                },
                {
                "company_id": 4106,
                "total_score": 82
                },
                {
                "company_id": 5758,
                "total_score": 52
                },
                {
                "company_id": 3676,
                "total_score": 89
                },
                {
                "company_id": 4116,
                "total_score": 91
                },
                {
                "company_id": 3765,
                "total_score": 76
                },
                {
                "company_id": 3616,
                "total_score": 74
                },
                {
                "company_id": 3633,
                "total_score": 90
                },
                {
                "company_id": 3602,
                "total_score": 86
                },
                {
                "company_id": 3807,
                "total_score": 95
                },
                {
                "company_id": 3284,
                "total_score": 97
                },
                {
                "company_id": 3913,
                "total_score": 81
                },
                {
                "company_id": 3708,
                "total_score": 87
                },
                {
                "company_id": 3787,
                "total_score": 71
                },
                {
                "company_id": 3535,
                "total_score": 60
                },
                {
                "company_id": 3788,
                "total_score": 71
                },
                {
                "company_id": 4080,
                "total_score": 90
                },
                {
                "company_id": 5763,
                "total_score": 74
                },
                {
                "company_id": 4048,
                "total_score": 87
                },
                {
                "company_id": 3451,
                "total_score": 72
                },
                {
                "company_id": 3068,
                "total_score": 95
                },
                {
                "company_id": 5560,
                "total_score": 85
                },
                {
                "company_id": 3191,
                "total_score": 83
                },
                {
                "company_id": 4056,
                "total_score": 88
                },
                {
                "company_id": 3111,
                "total_score": 95
                },
                {
                "company_id": 4005,
                "total_score": 75
                },
                {
                "company_id": 4022,
                "total_score": 60
                },
                {
                "company_id": 4000,
                "total_score": 83
                },
                {
                "company_id": 5663,
                "total_score": 91
                },
                {
                "company_id": 4477,
                "total_score": 90
                },
                {
                "company_id": 3523,
                "total_score": 91
                },
                {
                "company_id": 4450,
                "total_score": 87
                },
                {
                "company_id": 4215,
                "total_score": 94
                },
                {
                "company_id": 4290,
                "total_score": 80
                },
                {
                "company_id": 5590,
                "total_score": 83
                },
                {
                "company_id": 5061,
                "total_score": 87
                },
                {
                "company_id": 3904,
                "total_score": 68
                },
                {
                "company_id": 5330,
                "total_score": 74
                },
                {
                "company_id": 4984,
                "total_score": 92
                },
                {
                "company_id": 3358,
                "total_score": 74
                },
                {
                "company_id": 3409,
                "total_score": 95
                },
                {
                "company_id": 5051,
                "total_score": 94
                },
                {
                "company_id": 3534,
                "total_score": 92
                },
                {
                "company_id": 5604,
                "total_score": 79
                },
                {
                "company_id": 5615,
                "total_score": 84
                },
                {
                "company_id": 3667,
                "total_score": 91
                },
                {
                "company_id": 4100,
                "total_score": 87
                },
                {
                "company_id": 4008,
                "total_score": 95
                },
                {
                "company_id": 4141,
                "total_score": 88
                },
                {
                "company_id": 5014,
                "total_score": 73
                },
                {
                "company_id": 4200,
                "total_score": 97
                },
                {
                "company_id": 4206,
                "total_score": 93
                },
                {
                "company_id": 5124,
                "total_score": 89
                },
                {
                "company_id": 4091,
                "total_score": 85
                },
                {
                "company_id": 5058,
                "total_score": 75
                },
                {
                "company_id": 3194,
                "total_score": 84
                },
                {
                "company_id": 4464,
                "total_score": 91
                },
                {
                "company_id": 5646,
                "total_score": 82
                },
                {
                "company_id": 5605,
                "total_score": 82
                },
                {
                "company_id": 4505,
                "total_score": 82
                },
                {
                "company_id": 3707,
                "total_score": 83
                },
                {
                "company_id": 5608,
                "total_score": 85
                },
                {
                "company_id": 4142,
                "total_score": 85
                },
                {
                "company_id": 5587,
                "total_score": 59
                },
                {
                "company_id": 3449,
                "total_score": 92
                },
                {
                "company_id": 3763,
                "total_score": 96
                },
                {
                "company_id": 3472,
                "total_score": 85
                },
                {
                "company_id": 5426,
                "total_score": 91
                },
                {
                "company_id": 4015,
                "total_score": 88
                },
                {
                "company_id": 4472,
                "total_score": 88
                },
                {
                "company_id": 3464,
                "total_score": 95
                },
                {
                "company_id": 4058,
                "total_score": 71
                },
                {
                "company_id": 5094,
                "total_score": 74
                },
                {
                "company_id": 5706,
                "total_score": 90
                },
                {
                "company_id": 4377,
                "total_score": 93
                },
                {
                "company_id": 3411,
                "total_score": 97
                },
                {
                "company_id": 5118,
                "total_score": 85
                },
                {
                "company_id": 4150,
                "total_score": 97
                },
                {
                "company_id": 4181,
                "total_score": 82
                },
                {
                "company_id": 4879,
                "total_score": 64
                },
                {
                "company_id": 3997,
                "total_score": 95
                },
                {
                "company_id": 4335,
                "total_score": 85
                },
                {
                "company_id": 3910,
                "total_score": 76
                },
                {
                "company_id": 4086,
                "total_score": 91
                },
                {
                "company_id": 3729,
                "total_score": 89
                },
                {
                "company_id": 5358,
                "total_score": 96
                },
                {
                "company_id": 5645,
                "total_score": 94
                },
                {
                "company_id": 4400,
                "total_score": 93
                },
                {
                "company_id": 4006,
                "total_score": 57
                },
                {
                "company_id": 5298,
                "total_score": 77
                },
                {
                "company_id": 4425,
                "total_score": 93
                },
                {
                "company_id": 4165,
                "total_score": 85
                },
                {
                "company_id": 5570,
                "total_score": 91
                },
                {
                "company_id": 4131,
                "total_score": 97
                },
                {
                "company_id": 3753,
                "total_score": 92
                },
                {
                "company_id": 4326,
                "total_score": 2
                },
                {
                "company_id": 5135,
                "total_score": 89
                },
                {
                "company_id": 5091,
                "total_score": 95
                },
                {
                "company_id": 3907,
                "total_score": 85
                },
                {
                "company_id": 5574,
                "total_score": 91
                },
                {
                "company_id": 3167,
                "total_score": 93
                },
                {
                "company_id": 5363,
                "total_score": 84
                },
                {
                "company_id": 5291,
                "total_score": 78
                },
                {
                "company_id": 4228,
                "total_score": 92
                },
                {
                "company_id": 5567,
                "total_score": 82
                },
                {
                "company_id": 3241,
                "total_score": 93
                },
                {
                "company_id": 3948,
                "total_score": 85
                },
                {
                "company_id": 4077,
                "total_score": 81
                },
                {
                "company_id": 5119,
                "total_score": 91
                },
                {
                "company_id": 5315,
                "total_score": 85
                },
                {
                "company_id": 3834,
                "total_score": 91
                },
                {
                "company_id": 3706,
                "total_score": 89
                },
                {
                "company_id": 5710,
                "total_score": 60
                },
                {
                "company_id": 5059,
                "total_score": 90
                },
                {
                "company_id": 3698,
                "total_score": 85
                },
                {
                "company_id": 4047,
                "total_score": 90
                },
                {
                "company_id": 4203,
                "total_score": 95
                },
                {
                "company_id": 5601,
                "total_score": 81
                },
                {
                "company_id": 3888,
                "total_score": 73
                },
                {
                "company_id": 5545,
                "total_score": 91
                },
                {
                "company_id": 3952,
                "total_score": 76
                },
                {
                "company_id": 3979,
                "total_score": 54
                },
                {
                "company_id": 5805,
                "total_score": 58
                },
                {
                "company_id": 4969,
                "total_score": 67
                },
                {
                "company_id": 5366,
                "total_score": 81
                },
                {
                "company_id": 4079,
                "total_score": 95
                },
                {
                "company_id": 3947,
                "total_score": 80
                },
                {
                "company_id": 5578,
                "total_score": 87
                },
                {
                "company_id": 4146,
                "total_score": 84
                },
                {
                "company_id": 3427,
                "total_score": 87
                },
                {
                "company_id": 3330,
                "total_score": 65
                },
                {
                "company_id": 3296,
                "total_score": 89
                },
                {
                "company_id": 3745,
                "total_score": 95
                },
                {
                "company_id": 5045,
                "total_score": 85
                },
                {
                "company_id": 4561,
                "total_score": 82
                },
                {
                "company_id": 4612,
                "total_score": 94
                },
                {
                "company_id": 4119,
                "total_score": 88
                },
                {
                "company_id": 5060,
                "total_score": 78
                },
                {
                "company_id": 5146,
                "total_score": 90
                },
                {
                "company_id": 4426,
                "total_score": 59
                },
                {
                "company_id": 5360,
                "total_score": 92
                },
                {
                "company_id": 5834,
                "total_score": 86
                },
                {
                "company_id": 3367,
                "total_score": 82
                },
                {
                "company_id": 3367,
                "total_score": 82
                },
                {
                "company_id": 4017,
                "total_score": 87
                },
                {
                "company_id": 3254,
                "total_score": 60
                },
                {
                "company_id": 4007,
                "total_score": 71
                },
                {
                "company_id": 3113,
                "total_score": 95
                },
                {
                "company_id": 4208,
                "total_score": 84
                },
                {
                "company_id": 3465,
                "total_score": 93
                },
                {
                "company_id": 5568,
                "total_score": 91
                },
                {
                "company_id": 3571,
                "total_score": 87
                },
                {
                "company_id": 3261,
                "total_score": 77
                },
                {
                "company_id": 4219,
                "total_score": 95
                },
                {
                "company_id": 4216,
                "total_score": 92
                },
                {
                "company_id": 3832,
                "total_score": 91
                },
                {
                "company_id": 3423,
                "total_score": 93
                },
                {
                "company_id": 4032,
                "total_score": 87
                },
                {
                "company_id": 5093,
                "total_score": 78
                },
                {
                "company_id": 4139,
                "total_score": 97
                },
                {
                "company_id": 3424,
                "total_score": 83
                },
                {
                "company_id": 5746,
                "total_score": 86
                },
                {
                "company_id": 3402,
                "total_score": 86
                },
                {
                "company_id": 5336,
                "total_score": 93
                },
                {
                "company_id": 4415,
                "total_score": 78
                },
                {
                "company_id": 5791,
                "total_score": 93
                },
                {
                "company_id": 4111,
                "total_score": 57
                },
                {
                "company_id": 3286,
                "total_score": 79
                },
                {
                "company_id": 3471,
                "total_score": 93
                },
                {
                "company_id": 4064,
                "total_score": 82
                },
                {
                "company_id": 4204,
                "total_score": 64
                },
                {
                "company_id": 4044,
                "total_score": 76
                },
                {
                "company_id": 3689,
                "total_score": 95
                },
                {
                "company_id": 5416,
                "total_score": 91
                },
                {
                "company_id": 4229,
                "total_score": 87
                },
                {
                "company_id": 3591,
                "total_score": 84
                },
                {
                "company_id": 3944,
                "total_score": 72
                },
                {
                "company_id": 5714,
                "total_score": 76
                },
                {
                "company_id": 5494,
                "total_score": 90
                },
                {
                "company_id": 5836,
                "total_score": 86
                },
                {
                "company_id": 5425,
                "total_score": 72
                },
                {
                "company_id": 5616,
                "total_score": 83
                },
                {
                "company_id": 5056,
                "total_score": 79
                },
                {
                "company_id": 5322,
                "total_score": 74
                },
                {
                "company_id": 3766,
                "total_score": 90
                },
                {
                "company_id": 4639,
                "total_score": 93
                },
                {
                "company_id": 4065,
                "total_score": 93
                },
                {
                "company_id": 4029,
                "total_score": 93
                },
                {
                "company_id": 3224,
                "total_score": 91
                },
                {
                "company_id": 4020,
                "total_score": 80
                },
                {
                "company_id": 927,
                "total_score": 91
                },
                {
                "company_id": 5498,
                "total_score": 91
                },
                {
                "company_id": 5802,
                "total_score": 85
                },
                {
                "company_id": 5630,
                "total_score": 92
                },
                {
                "company_id": 4025,
                "total_score": 93
                },
                {
                "company_id": 3673,
                "total_score": 87
                },
                {
                "company_id": 5089,
                "total_score": 99
                },
                {
                "company_id": 4479,
                "total_score": 93
                },
                {
                "company_id": 3945,
                "total_score": 95
                },
                {
                "company_id": 4202,
                "total_score": 93
                },
                {
                "company_id": 4090,
                "total_score": 90
                },
                {
                "company_id": 5617,
                "total_score": 91
                },
                {
                "company_id": 3539,
                "total_score": 76
                },
                {
                "company_id": 4968,
                "total_score": 89
                },
                {
                "company_id": 5214,
                "total_score": 83
                },
                {
                "company_id": 5764,
                "total_score": 95
                },
                {
                "company_id": 5767,
                "total_score": 93
                },
                {
                "company_id": 5067,
                "total_score": 85
                },
                {
                "company_id": 3990,
                "total_score": 86
                },
                {
                "company_id": 5348,
                "total_score": 83
                },
                {
                "company_id": 4358,
                "total_score": 95
                },
                {
                "company_id": 4108,
                "total_score": 82
                },
                {
                "company_id": 4207,
                "total_score": 95
                },
                {
                "company_id": 4362,
                "total_score": 95
                },
                {
                "company_id": 3384,
                "total_score": 95
                },
                {
                "company_id": 5753,
                "total_score": 85
                },
                {
                "company_id": 4996,
                "total_score": 89
                },
                {
                "company_id": 3112,
                "total_score": 93
                },
                {
                "company_id": 3597,
                "total_score": 87
                },
                {
                "company_id": 4302,
                "total_score": 87
                },
                {
                "company_id": 4904,
                "total_score": 42
                },
                {
                "company_id": 3759,
                "total_score": 88
                },
                {
                "company_id": 5155,
                "total_score": 88
                },
                {
                "company_id": 5571,
                "total_score": 91
                },
                {
                "company_id": 3661,
                "total_score": 89
                },
                {
                "company_id": 4088,
                "total_score": 91
                },
                {
                "company_id": 3786,
                "total_score": 80
                },
                {
                "company_id": 3533,
                "total_score": 56
                },
                {
                "company_id": 3208,
                "total_score": 84
                },
                {
                "company_id": 5368,
                "total_score": 93
                },
                {
                "company_id": 5759,
                "total_score": 79
                },
                {
                "company_id": 3440,
                "total_score": 85
                },
                {
                "company_id": 4455,
                "total_score": 87
                },
                {
                "company_id": 4122,
                "total_score": 88
                },
                {
                "company_id": 5142,
                "total_score": 88
                },
                {
                "company_id": 4182,
                "total_score": 87
                },
                {
                "company_id": 4542,
                "total_score": 50
                },
                {
                "company_id": 3903,
                "total_score": 76
                },
                {
                "company_id": 4014,
                "total_score": 65
                },
                {
                "company_id": 3318,
                "total_score": 91
                },
                {
                "company_id": 3946,
                "total_score": 78
                },
                {
                "company_id": 3841,
                "total_score": 86
                },
                {
                "company_id": 4263,
                "total_score": 68
                },
                {
                "company_id": 4282,
                "total_score": 95
                },
                {
                "company_id": 5140,
                "total_score": 80
                },
                {
                "company_id": 4089,
                "total_score": 77
                },
                {
                "company_id": 5628,
                "total_score": 69
                },
                {
                "company_id": 4452,
                "total_score": 94
                },
                {
                "company_id": 5651,
                "total_score": 81
                },
                {
                "company_id": 5651,
                "total_score": 81
                },
                {
                "company_id": 3840,
                "total_score": 95
                },
                {
                "company_id": 5097,
                "total_score": 85
                },
                {
                "company_id": 3230,
                "total_score": 90
                },
                {
                "company_id": 5680,
                "total_score": 82
                },
                {
                "company_id": 4033,
                "total_score": 93
                },
                {
                "company_id": 3646,
                "total_score": 80
                },
                {
                "company_id": 5349,
                "total_score": 89
                },
                {
                "company_id": 3900,
                "total_score": 86
                },
                {
                "company_id": 4082,
                "total_score": 77
                },
                {
                "company_id": 4153,
                "total_score": 85
                },
                {
                "company_id": 3555,
                "total_score": 88
                },
                {
                "company_id": 5727,
                "total_score": 93
                },
                {
                "company_id": 5564,
                "total_score": 77
                },
                {
                "company_id": 3448,
                "total_score": 57
                },
                {
                "company_id": 5070,
                "total_score": 97
                },
                {
                "company_id": 5761,
                "total_score": 79
                },
                {
                "company_id": 3978,
                "total_score": 91
                },
                {
                "company_id": 4260,
                "total_score": 86
                },
                {
                "company_id": 5039,
                "total_score": 79
                },
                {
                "company_id": 3624,
                "total_score": 87
                },
                {
                "company_id": 3722,
                "total_score": 82
                },
                {
                "company_id": 5380,
                "total_score": 82
                },
                {
                "company_id": 5379,
                "total_score": 80
                },
                {
                "company_id": 4476,
                "total_score": 93
                },
                {
                "company_id": 4267,
                "total_score": 84
                },
                {
                "company_id": 3403,
                "total_score": 77
                },
                {
                "company_id": 4053,
                "total_score": 91
                },
                {
                "company_id": 5779,
                "total_score": 95
                },
                {
                "company_id": 5333,
                "total_score": 73
                },
                {
                "company_id": 3107,
                "total_score": 91
                },
                {
                "company_id": 4062,
                "total_score": 97
                },
                {
                "company_id": 4222,
                "total_score": 70
                },
                {
                "company_id": 3399,
                "total_score": 92
                },
                {
                "company_id": 5012,
                "total_score": 97
                },
                {
                "company_id": 3694,
                "total_score": 72
                },
                {
                "company_id": 3703,
                "total_score": 42
                },
                {
                "company_id": 5309,
                "total_score": 49
                },
                {
                "company_id": 3760,
                "total_score": 93
                },
                {
                "company_id": 3299,
                "total_score": 95
                },
                {
                "company_id": 5125,
                "total_score": 71
                },
                {
                "company_id": 3996,
                "total_score": 92
                },
                {
                "company_id": 4226,
                "total_score": 77
                },
                {
                "company_id": 4115,
                "total_score": 62
                },
                {
                "company_id": 4003,
                "total_score": 83
                },
                {
                "company_id": 3609,
                "total_score": 86
                },
                {
                "company_id": 3141,
                "total_score": 86
                },
                {
                "company_id": 5362,
                "total_score": 82
                },
                {
                "company_id": 4019,
                "total_score": 90
                },
                {
                "company_id": 5147,
                "total_score": 95
                },
                {
                "company_id": 5661,
                "total_score": 53
                },
                {
                "company_id": 3818,
                "total_score": 81
                },
                {
                "company_id": 5740,
                "total_score": 67
                },
                {
                "company_id": 5367,
                "total_score": 85
                },
                {
                "company_id": 3626,
                "total_score": 76
                },
                {
                "company_id": 5778,
                "total_score": 73
                },
                {
                "company_id": 4130,
                "total_score": 90
                },
                {
                "company_id": 3169,
                "total_score": 94
                },
                {
                "company_id": 5270,
                "total_score": 76
                },
                {
                "company_id": 5048,
                "total_score": 95
                },
                {
                "company_id": 3278,
                "total_score": 60
                },
                {
                "company_id": 5655,
                "total_score": 77
                },
                {
                "company_id": 4054,
                "total_score": 93
                },
                {
                "company_id": 4154,
                "total_score": 90
                },
                {
                "company_id": 4322,
                "total_score": 79
                },
                {
                "company_id": 3750,
                "total_score": 95
                },
                {
                "company_id": 4169,
                "total_score": 95
                },
                {
                "company_id": 4288,
                "total_score": 91
                },
                {
                "company_id": 4101,
                "total_score": 75
                },
                {
                "company_id": 4371,
                "total_score": 83
                },
                {
                "company_id": 4163,
                "total_score": 57
                },
                {
                "company_id": 4164,
                "total_score": 40
                },
                {
                "company_id": 4224,
                "total_score": 60
                },
                {
                "company_id": 4157,
                "total_score": 86
                },
                {
                "company_id": 4095,
                "total_score": 63
                },
                {
                "company_id": 4172,
                "total_score": 80
                },
                {
                "company_id": 4155,
                "total_score": 70
                },
                {
                "company_id": 3211,
                "total_score": 76
                },
                {
                "company_id": 3995,
                "total_score": 83
                },
                {
                "company_id": 4148,
                "total_score": 68
                },
                {
                "company_id": 4102,
                "total_score": 91
                },
                {
                "company_id": 4366,
                "total_score": 92
                },
                {
                "company_id": 4195,
                "total_score": 81
                },
                {
                "company_id": 4459,
                "total_score": 78
                },
                {
                "company_id": 3357,
                "total_score": 89
                },
                {
                "company_id": 4133,
                "total_score": 70
                },
                {
                "company_id": 4162,
                "total_score": 64
                },
                {
                "company_id": 4361,
                "total_score": 80
                },
                {
                "company_id": 4043,
                "total_score": 67
                },
                {
                "company_id": 4176,
                "total_score": 87
                },
                {
                "company_id": 4050,
                "total_score": 85
                },
                {
                "company_id": 4324,
                "total_score": 48
                },
                {
                "company_id": 3749,
                "total_score": 86
                },
                {
                "company_id": 4171,
                "total_score": 81
                },
                {
                "company_id": 4201,
                "total_score": 91
                },
                {
                "company_id": 4158,
                "total_score": 78
                },
                {
                "company_id": 4187,
                "total_score": 84
                },
                {
                "company_id": 3347,
                "total_score": 58
                },
                {
                "company_id": 4129,
                "total_score": 77
                },
                {
                "company_id": 4161,
                "total_score": 75
                },
                {
                "company_id": 4045,
                "total_score": 59
                },
                {
                "company_id": 4126,
                "total_score": 68
                },
                {
                "company_id": 4241,
                "total_score": 85
                },
                {
                "company_id": 4099,
                "total_score": 79
                },
                {
                "company_id": 4084,
                "total_score": 81
                },
                {
                "company_id": 4179,
                "total_score": 80
                },
                {
                "company_id": 4166,
                "total_score": 76
                },
                {
                "company_id": 4066,
                "total_score": 68
                },
                {
                "company_id": 4125,
                "total_score": 48
                },
                {
                "company_id": 3618,
                "total_score": 66
                },
                {
                "company_id": 4057,
                "total_score": 86
                },
                {
                "company_id": 4168,
                "total_score": 72
                },
                {
                "company_id": 4120,
                "total_score": 60
                },
                {
                "company_id": 4061,
                "total_score": 81
                },
                {
                "company_id": 4145,
                "total_score": 78
                },
                {
                "company_id": 5043,
                "total_score": 76
                },
                {
                "company_id": 4104,
                "total_score": 72
                },
                {
                "company_id": 3363,
                "total_score": 74
                },
                {
                "company_id": 5381,
                "total_score": 82
                },
                {
                "company_id": 4132,
                "total_score": 78
                },
                {
                "company_id": 4132,
                "total_score": 78
                },
                {
                "company_id": 5807,
                "total_score": 91
                },
                {
                "company_id": 5563,
                "total_score": 91
                },
                {
                "company_id": 5717,
                "total_score": 36
                },
                {
                "company_id": 5405,
                "total_score": 97
                },
                {
                "company_id": 3622,
                "total_score": 75
                },
                {
                "company_id": 5667,
                "total_score": 81
                },
                {
                "company_id": 5153,
                "total_score": 78
                },
                {
                "company_id": 3808,
                "total_score": 88
                },
                {
                "company_id": 5765,
                "total_score": 74
                },
                {
                "company_id": 5342,
                "total_score": 93
                },
                {
                "company_id": 4328,
                "total_score": 81
                },
                {
                "company_id": 5572,
                "total_score": 72
                },
                {
                "company_id": 5662,
                "total_score": 50
                },
                {
                "company_id": 4016,
                "total_score": 97
                },
                {
                "company_id": 3364,
                "total_score": 95
                },
                {
                "company_id": 3909,
                "total_score": 62
                },
                {
                "company_id": 3891,
                "total_score": 73
                },
                {
                "company_id": 4233,
                "total_score": 74
                },
                {
                "company_id": 5631,
                "total_score": 88
                },
                {
                "company_id": 5361,
                "total_score": 97
                },
                {
                "company_id": 4397,
                "total_score": 89
                },
                {
                "company_id": 3182,
                "total_score": 87
                },
                {
                "company_id": 4060,
                "total_score": 83
                },
                {
                "company_id": 3668,
                "total_score": 73
                },
                {
                "company_id": 5747,
                "total_score": 89
                },
                {
                "company_id": 5633,
                "total_score": 79
                },
                {
                "company_id": 4474,
                "total_score": 87
                },
                {
                "company_id": 4655,
                "total_score": 69
                },
                {
                "company_id": 4152,
                "total_score": 86
                },
                {
                "company_id": 3426,
                "total_score": 89
                },
                {
                "company_id": 4160,
                "total_score": 62
                },
                {
                "company_id": 3238,
                "total_score": 95
                },
                {
                "company_id": 3742,
                "total_score": 91
                },
                {
                "company_id": 3475,
                "total_score": 79
                },
                {
                "company_id": 5318,
                "total_score": 90
                },
                {
                "company_id": 3991,
                "total_score": 93
                },
                {
                "company_id": 5614,
                "total_score": 71
                },
                {
                "company_id": 3999,
                "total_score": 84
                },
                {
                "company_id": 5625,
                "total_score": 89
                },
                {
                "company_id": 3530,
                "total_score": 92
                },
                {
                "company_id": 3470,
                "total_score": 93
                },
                {
                "company_id": 5166,
                "total_score": 85
                },
                {
                "company_id": 4482,
                "total_score": 87
                },
                {
                "company_id": 5136,
                "total_score": 82
                },
                {
                "company_id": 5723,
                "total_score": 94
                },
                {
                "company_id": 5768,
                "total_score": 81
                },
                {
                "company_id": 3695,
                "total_score": 95
                },
                {
                "company_id": 4223,
                "total_score": 73
                },
                {
                "company_id": 4316,
                "total_score": 97
                },
                {
                "company_id": 3636,
                "total_score": 71
                },
                {
                "company_id": 3319,
                "total_score": 69
                },
                {
                "company_id": 3502,
                "total_score": 93
                },
                {
                "company_id": 3193,
                "total_score": 76
                },
                {
                "company_id": 3822,
                "total_score": 90
                },
                {
                "company_id": 3500,
                "total_score": 97
                },
                {
                "company_id": 3665,
                "total_score": 89
                },
                {
                "company_id": 5404,
                "total_score": 90
                },
                {
                "company_id": 3260,
                "total_score": 88
                },
                {
                "company_id": 4026,
                "total_score": 92
                },
                {
                "company_id": 5068,
                "total_score": 90
                },
                {
                "company_id": 4382,
                "total_score": 90
                },
                {
                "company_id": 1958,
                "total_score": 89
                },
                {
                "company_id": 4485,
                "total_score": 87
                },
                {
                "company_id": 3666,
                "total_score": 92
                },
                {
                "company_id": 5612,
                "total_score": 88
                },
                {
                "company_id": 5799,
                "total_score": 58
                },
                {
                "company_id": 3326,
                "total_score": 93
                },
                {
                "company_id": 3327,
                "total_score": 71
                },
                {
                "company_id": 3630,
                "total_score": 69
                },
                {
                "company_id": 4069,
                "total_score": 64
                },
                {
                "company_id": 5356,
                "total_score": 68
                },
                {
                "company_id": 5693,
                "total_score": 95
                },
                {
                "company_id": 5752,
                "total_score": 87
                },
                {
                "company_id": 3951,
                "total_score": 86
                },
                {
                "company_id": 3733,
                "total_score": 85
                },
                {
                "company_id": 5606,
                "total_score": 80
                },
                {
                "company_id": 5609,
                "total_score": 76
                },
                {
                "company_id": 3265,
                "total_score": 91
                },
                {
                "company_id": 4170,
                "total_score": 85
                },
                {
                "company_id": 4543,
                "total_score": 81
                },
                {
                "company_id": 3776,
                "total_score": 90
                },
                {
                "company_id": 5772,
                "total_score": 91
                },
                {
                "company_id": 4063,
                "total_score": 93
                },
                {
                "company_id": 5781,
                "total_score": 81
                },
                {
                "company_id": 5032,
                "total_score": 87
                },
                {
                "company_id": 5117,
                "total_score": 93
                },
                {
                "company_id": 5715,
                "total_score": 64
                },
                {
                "company_id": 4087,
                "total_score": 85
                },
                {
                "company_id": 5613,
                "total_score": 84
                },
                {
                "company_id": 5600,
                "total_score": 61
                },
                {
                "company_id": 3276,
                "total_score": 89
                },
                {
                "company_id": 4051,
                "total_score": 81
                },
                {
                "company_id": 5158,
                "total_score": 91
                },
                {
                "company_id": 5692,
                "total_score": 81
                },
                {
                "company_id": 5123,
                "total_score": 89
                },
                {
                "company_id": 3881,
                "total_score": 88
                },
                {
                "company_id": 3417,
                "total_score": 86
                },
                {
                "company_id": 3628,
                "total_score": 74
                },
                {
                "company_id": 4081,
                "total_score": 93
                },
                {
                "company_id": 4027,
                "total_score": 86
                },
                {
                "company_id": 4349,
                "total_score": 88
                },
                {
                "company_id": 4767,
                "total_score": 49
                },
                {
                "company_id": 4001,
                "total_score": 82
                },
                {
                "company_id": 5673,
                "total_score": 90
                },
                {
                "company_id": 5312,
                "total_score": 88
                },
                {
                "company_id": 5632,
                "total_score": 79
                },
                {
                "company_id": 5111,
                "total_score": 91
                },
                {
                "company_id": 5064,
                "total_score": 92
                },
                {
                "company_id": 3233,
                "total_score": 75
                },
                {
                "company_id": 4746,
                "total_score": 82
                },
                {
                "company_id": 5052,
                "total_score": 82
                },
                {
                "company_id": 4009,
                "total_score": 95
                },
                {
                "company_id": 5602,
                "total_score": 86
                },
                {
                "company_id": 4269,
                "total_score": 94
                },
                {
                "company_id": 5345,
                "total_score": 89
                },
                {
                "company_id": 4151,
                "total_score": 89
                },
                {
                "company_id": 5344,
                "total_score": 84
                },
                {
                "company_id": 4137,
                "total_score": 98
                },
                {
                "company_id": 5806,
                "total_score": 93
                },
                {
                "company_id": 3740,
                "total_score": 89
                },
                {
                "company_id": 4024,
                "total_score": 86
                },
                {
                "company_id": 5610,
                "total_score": 48
                },
                {
                "company_id": 5178,
                "total_score": 73
                },
                {
                "company_id": 4136,
                "total_score": 93
                },
                {
                "company_id": 3620,
                "total_score": 82
                },
                {
                "company_id": 5176,
                "total_score": 85
                },
                {
                "company_id": 3911,
                "total_score": 69
                },
                {
                "company_id": 4004,
                "total_score": 92
                },
                {
                "company_id": 4273,
                "total_score": 87
                },
                {
                "company_id": 5096,
                "total_score": 85
                },
                {
                "company_id": 4549,
                "total_score": 93
                },
                {
                "company_id": 5648,
                "total_score": 93
                },
                {
                "company_id": 5576,
                "total_score": 91
                },
                {
                "company_id": 5644,
                "total_score": 95
                },
                {
                "company_id": 5607,
                "total_score": 89
                },
                {
                "company_id": 4259,
                "total_score": 83
                },
                {
                "company_id": 4262,
                "total_score": 87
                },
                {
                "company_id": 3482,
                "total_score": 89
                },
                {
                "company_id": 4470,
                "total_score": 63
                },
                {
                "company_id": 3243,
                "total_score": 91
                },
                {
                "company_id": 3572,
                "total_score": 87
                },
                {
                "company_id": 5082,
                "total_score": 92
                },
                {
                "company_id": 5751,
                "total_score": 91
                },
                {
                "company_id": 3862,
                "total_score": 85
                },
                {
                "company_id": 5809,
                "total_score": 93
                },
                {
                "company_id": 5748,
                "total_score": 91
                },
                {
                "company_id": 4046,
                "total_score": 86
                },
                {
                "company_id": 3623,
                "total_score": 87
                },
                {
                "company_id": 5832,
                "total_score": 90
                },
                {
                "company_id": 4124,
                "total_score": 75
                },
                {
                "company_id": 3898,
                "total_score": 96
                },
                {
                "company_id": 5695,
                "total_score": 89
                },
                {
                "company_id": 4401,
                "total_score": 85
                },
                {
                "company_id": 4329,
                "total_score": 85
                },
                {
                "company_id": 4134,
                "total_score": 91
                },
                {
                "company_id": 4478,
                "total_score": 30
                },
                {
                "company_id": 5594,
                "total_score": 69
                },
                {
                "company_id": 5115,
                "total_score": 83
                },
                {
                "company_id": 5677,
                "total_score": 36
                },
                {
                "company_id": 3973,
                "total_score": 70
                },
                {
                "company_id": 5282,
                "total_score": 69
                },
                {
                "company_id": 4076,
                "total_score": 68
                },
                {
                "company_id": 5391,
                "total_score": 93
                },
                {
                "company_id": 4110,
                "total_score": 88
                },
                {
                "company_id": 4410,
                "total_score": 92
                },
                {
                "company_id": 5364,
                "total_score": 74
                },
                {
                "company_id": 4107,
                "total_score": 85
                },
                {
                "company_id": 5095,
                "total_score": 81
                },
                {
                "company_id": 3656,
                "total_score": 93
                },
                {
                "company_id": 5569,
                "total_score": 92
                },
                {
                "company_id": 3663,
                "total_score": 81
                },
                {
                "company_id": 5063,
                "total_score": 91
                },
                {
                "company_id": 4381,
                "total_score": 93
                },
                {
                "company_id": 5543,
                "total_score": 95
                },
                {
                "company_id": 5393,
                "total_score": 88
                },
                {
                "company_id": 5335,
                "total_score": 89
                },
                {
                "company_id": 4760,
                "total_score": 86
                },
                {
                "company_id": 5386,
                "total_score": 84
                },
                {
                "company_id": 5394,
                "total_score": 83
                },
                {
                "company_id": 5040,
                "total_score": 90
                },
                {
                "company_id": 1951,
                "total_score": 67
                },
                {
                "company_id": 3554,
                "total_score": 91
                },
                {
                "company_id": 4833,
                "total_score": 46
                },
                {
                "company_id": 4438,
                "total_score": 65
                },
                {
                "company_id": 3447,
                "total_score": 52
                },
                {
                "company_id": 4147,
                "total_score": 64
                },
                {
                "company_id": 3612,
                "total_score": 80
                },
                {
                "company_id": 3481,
                "total_score": 86
                },
                {
                "company_id": 4895,
                "total_score": 76
                },
                {
                "company_id": 3222,
                "total_score": 89
                },
                {
                "company_id": 4012,
                "total_score": 90
                },
                {
                "company_id": 3838,
                "total_score": 63
                },
                {
                "company_id": 5288,
                "total_score": 70
                },
                {
                "company_id": 3378,
                "total_score": 79
                },
                {
                "company_id": 4917,
                "total_score": 92
                },
                {
                "company_id": 4334,
                "total_score": 78
                },
                {
                "company_id": 3229,
                "total_score": 74
                },
                {
                "company_id": 3714,
                "total_score": 76
                },
                {
                "company_id": 3304,
                "total_score": 77
                },
                {
                "company_id": 3596,
                "total_score": 82
                },
                {
                "company_id": 3864,
                "total_score": 83
                },
                {
                "company_id": 3559,
                "total_score": 94
                },
                {
                "company_id": 3354,
                "total_score": 74
                },
                {
                "company_id": 4826,
                "total_score": 55
                },
                {
                "company_id": 3514,
                "total_score": 91
                },
                {
                "company_id": 4877,
                "total_score": 85
                },
                {
                "company_id": 3090,
                "total_score": 92
                },
                {
                "company_id": 3200,
                "total_score": 59
                },
                {
                "company_id": 4953,
                "total_score": 69
                },
                {
                "company_id": 3119,
                "total_score": 79
                },
                {
                "company_id": 5099,
                "total_score": 74
                },
                {
                "company_id": 1979,
                "total_score": 91
                },
                {
                "company_id": 3634,
                "total_score": 91
                },
                {
                "company_id": 5205,
                "total_score": 75
                },
                {
                "company_id": 3122,
                "total_score": 82
                },
                {
                "company_id": 4286,
                "total_score": 75
                },
                {
                "company_id": 3159,
                "total_score": 60
                },
                {
                "company_id": 3334,
                "total_score": 85
                },
                {
                "company_id": 3379,
                "total_score": 87
                },
                {
                "company_id": 3604,
                "total_score": 88
                },
                {
                "company_id": 4346,
                "total_score": 83
                },
                {
                "company_id": 4486,
                "total_score": 91
                },
                {
                "company_id": 4884,
                "total_score": 12
                },
                {
                "company_id": 4068,
                "total_score": 90
                },
                {
                "company_id": 3227,
                "total_score": 92
                },
                {
                "company_id": 3669,
                "total_score": 80
                },
                {
                "company_id": 5240,
                "total_score": 89
                },
                {
                "company_id": 3899,
                "total_score": 85
                },
                {
                "company_id": 4299,
                "total_score": 91
                },
                {
                "company_id": 5302,
                "total_score": 81
                },
                {
                "company_id": 4431,
                "total_score": 89
                },
                {
                "company_id": 3275,
                "total_score": 89
                },
                {
                "company_id": 3121,
                "total_score": 87
                },
                {
                "company_id": 3279,
                "total_score": 97
                },
                {
                "company_id": 3885,
                "total_score": 92
                },
                {
                "company_id": 3839,
                "total_score": 65
                },
                {
                "company_id": 4544,
                "total_score": 81
                },
                {
                "company_id": 4378,
                "total_score": 95
                },
                {
                "company_id": 3180,
                "total_score": 82
                },
                {
                "company_id": 2942,
                "total_score": 97
                },
                {
                "company_id": 3274,
                "total_score": 81
                },
                {
                "company_id": 356,
                "total_score": 91
                },
                {
                "company_id": 2944,
                "total_score": 93
                },
                {
                "company_id": 5707,
                "total_score": 75
                },
                {
                "company_id": 4837,
                "total_score": 91
                },
                {
                "company_id": 3281,
                "total_score": 95
                },
                {
                "company_id": 4437,
                "total_score": 80
                },
                {
                "company_id": 4881,
                "total_score": 74
                },
                {
                "company_id": 4217,
                "total_score": 82
                },
                {
                "company_id": 3269,
                "total_score": 77
                },
                {
                "company_id": 1885,
                "total_score": 95
                },
                {
                "company_id": 3106,
                "total_score": 68
                },
                {
                "company_id": 3773,
                "total_score": 93
                },
                {
                "company_id": 4533,
                "total_score": 90
                },
                {
                "company_id": 3522,
                "total_score": 87
                },
                {
                "company_id": 4453,
                "total_score": 84
                },
                {
                "company_id": 3541,
                "total_score": 70
                },
                {
                "company_id": 3845,
                "total_score": 88
                },
                {
                "company_id": 3543,
                "total_score": 79
                },
                {
                "company_id": 3259,
                "total_score": 76
                },
                {
                "company_id": 4393,
                "total_score": 79
                },
                {
                "company_id": 3653,
                "total_score": 80
                },
                {
                "company_id": 3650,
                "total_score": 77
                },
                {
                "company_id": 402,
                "total_score": 52
                },
                {
                "company_id": 4536,
                "total_score": 65
                },
                {
                "company_id": 3126,
                "total_score": 84
                },
                {
                "company_id": 3450,
                "total_score": 82
                },
                {
                "company_id": 3607,
                "total_score": 71
                },
                {
                "company_id": 3688,
                "total_score": 87
                },
                {
                "company_id": 357,
                "total_score": 86
                },
                {
                "company_id": 3551,
                "total_score": 85
                },
                {
                "company_id": 4429,
                "total_score": 94
                },
                {
                "company_id": 4530,
                "total_score": 89
                },
                {
                "company_id": 4396,
                "total_score": 66
                },
                {
                "company_id": 3086,
                "total_score": 59
                },
                {
                "company_id": 4388,
                "total_score": 74
                },
                {
                "company_id": 3188,
                "total_score": 81
                },
                {
                "company_id": 3343,
                "total_score": 91
                },
                {
                "company_id": 3088,
                "total_score": 81
                },
                {
                "company_id": 3462,
                "total_score": 78
                },
                {
                "company_id": 4412,
                "total_score": 69
                },
                {
                "company_id": 358,
                "total_score": 93
                },
                {
                "company_id": 4411,
                "total_score": 82
                },
                {
                "company_id": 3679,
                "total_score": 53
                },
                {
                "company_id": 3144,
                "total_score": 89
                },
                {
                "company_id": 5090,
                "total_score": 71
                },
                {
                "company_id": 5398,
                "total_score": 77
                },
                {
                "company_id": 3837,
                "total_score": 70
                },
                {
                "company_id": 3205,
                "total_score": 93
                },
                {
                "company_id": 3321,
                "total_score": 93
                },
                {
                "company_id": 4307,
                "total_score": 80
                },
                {
                "company_id": 4278,
                "total_score": 86
                },
                {
                "company_id": 3617,
                "total_score": 81
                },
                {
                "company_id": 4347,
                "total_score": 62
                },
                {
                "company_id": 3711,
                "total_score": 63
                },
                {
                "company_id": 5657,
                "total_score": 72
                },
                {
                "company_id": 3652,
                "total_score": 86
                },
                {
                "company_id": 3311,
                "total_score": 87
                },
                {
                "company_id": 3570,
                "total_score": 75
                },
                {
                "company_id": 5134,
                "total_score": 91
                },
                {
                "company_id": 3521,
                "total_score": 84
                },
                {
                "company_id": 4532,
                "total_score": 62
                },
                {
                "company_id": 4297,
                "total_score": 62
                },
                {
                "company_id": 3136,
                "total_score": 71
                },
                {
                "company_id": 3748,
                "total_score": 70
                },
                {
                "company_id": 3615,
                "total_score": 88
                },
                {
                "company_id": 3237,
                "total_score": 75
                },
                {
                "company_id": 3638,
                "total_score": 78
                },
                {
                "company_id": 3209,
                "total_score": 56
                },
                {
                "company_id": 3375,
                "total_score": 65
                },
                {
                "company_id": 4294,
                "total_score": 87
                },
                {
                "company_id": 3854,
                "total_score": 89
                },
                {
                "company_id": 5575,
                "total_score": 87
                },
                {
                "company_id": 4359,
                "total_score": 64
                },
                {
                "company_id": 3290,
                "total_score": 69
                },
                {
                "company_id": 3075,
                "total_score": 81
                },
                {
                "company_id": 4337,
                "total_score": 84
                },
                {
                "company_id": 4306,
                "total_score": 90
                },
                {
                "company_id": 4277,
                "total_score": 95
                },
                {
                "company_id": 4277,
                "total_score": 95
                },
                {
                "company_id": 4413,
                "total_score": 95
                },
                {
                "company_id": 3684,
                "total_score": 87
                },
                {
                "company_id": 3715,
                "total_score": 97
                },
                {
                "company_id": 4353,
                "total_score": 91
                },
                {
                "company_id": 3312,
                "total_score": 81
                },
                {
                "company_id": 3178,
                "total_score": 91
                },
                {
                "company_id": 3562,
                "total_score": 88
                },
                {
                "company_id": 3508,
                "total_score": 72
                },
                {
                "company_id": 4458,
                "total_score": 75
                },
                {
                "company_id": 3631,
                "total_score": 56
                },
                {
                "company_id": 3400,
                "total_score": 85
                },
                {
                "company_id": 4342,
                "total_score": 95
                },
                {
                "company_id": 3863,
                "total_score": 95
                },
                {
                "company_id": 3325,
                "total_score": 97
                },
                {
                "company_id": 4519,
                "total_score": 85
                },
                {
                "company_id": 4889,
                "total_score": 82
                },
                {
                "company_id": 4185,
                "total_score": 89
                },
                {
                "company_id": 3536,
                "total_score": 50
                },
                {
                "company_id": 3550,
                "total_score": 67
                },
                {
                "company_id": 3670,
                "total_score": 85
                },
                {
                "company_id": 3248,
                "total_score": 81
                },
                {
                "company_id": 4828,
                "total_score": 80
                },
                {
                "company_id": 4309,
                "total_score": 92
                },
                {
                "company_id": 3812,
                "total_score": 90
                },
                {
                "company_id": 4900,
                "total_score": 93
                },
                {
                "company_id": 4287,
                "total_score": 88
                },
                {
                "company_id": 3513,
                "total_score": 91
                },
                {
                "company_id": 4489,
                "total_score": 84
                },
                {
                "company_id": 5212,
                "total_score": 87
                },
                {
                "company_id": 4210,
                "total_score": 91
                },
                {
                "company_id": 5281,
                "total_score": 64
                },
                {
                "company_id": 4240,
                "total_score": 74
                },
                {
                "company_id": 3655,
                "total_score": 88
                },
                {
                "company_id": 3453,
                "total_score": 83
                },
                {
                "company_id": 3077,
                "total_score": 91
                },
                {
                "company_id": 3137,
                "total_score": 97
                },
                {
                "company_id": 3157,
                "total_score": 94
                },
                {
                "company_id": 4236,
                "total_score": 95
                },
                {
                "company_id": 3648,
                "total_score": 94
                },
                {
                "company_id": 3109,
                "total_score": 90
                },
                {
                "company_id": 3755,
                "total_score": 91
                },
                {
                "company_id": 3637,
                "total_score": 89
                },
                {
                "company_id": 5833,
                "total_score": 93
                },
                {
                "company_id": 3627,
                "total_score": 70
                },
                {
                "company_id": 3108,
                "total_score": 72
                },
                {
                "company_id": 3165,
                "total_score": 78
                },
                {
                "company_id": 5171,
                "total_score": 74
                },
                {
                "company_id": 5308,
                "total_score": 91
                },
                {
                "company_id": 3610,
                "total_score": 77
                },
                {
                "company_id": 5286,
                "total_score": 71
                },
                {
                "company_id": 3162,
                "total_score": 79
                },
                {
                "company_id": 3893,
                "total_score": 74
                },
                {
                "company_id": 4852,
                "total_score": 71
                },
                {
                "company_id": 5101,
                "total_score": 70
                },
                {
                "company_id": 5195,
                "total_score": 83
                },
                {
                "company_id": 4256,
                "total_score": 87
                },
                {
                "company_id": 4626,
                "total_score": 66
                },
                {
                "company_id": 3323,
                "total_score": 84
                },
                {
                "company_id": 3486,
                "total_score": 82
                },
                {
                "company_id": 3202,
                "total_score": 81
                },
                {
                "company_id": 5054,
                "total_score": 85
                },
                {
                "company_id": 5317,
                "total_score": 83
                },
                {
                "company_id": 3589,
                "total_score": 78
                },
                {
                "company_id": 5084,
                "total_score": 71
                },
                {
                "company_id": 5102,
                "total_score": 79
                },
                {
                "company_id": 5075,
                "total_score": 90
                },
                {
                "company_id": 3756,
                "total_score": 90
                },
                {
                "company_id": 5303,
                "total_score": 58
                },
                {
                "company_id": 3160,
                "total_score": 78
                },
                {
                "company_id": 5311,
                "total_score": 87
                },
                {
                "company_id": 4551,
                "total_score": 89
                },
                {
                "company_id": 5103,
                "total_score": 89
                },
                {
                "company_id": 4271,
                "total_score": 84
                },
                {
                "company_id": 3186,
                "total_score": 93
                },
                {
                "company_id": 4221,
                "total_score": 92
                },
                {
                "company_id": 3189,
                "total_score": 87
                },
                {
                "company_id": 3151,
                "total_score": 91
                },
                {
                "company_id": 3895,
                "total_score": 80
                },
                {
                "company_id": 5157,
                "total_score": 69
                },
                {
                "company_id": 3850,
                "total_score": 92
                },
                {
                "company_id": 4248,
                "total_score": 79
                },
                {
                "company_id": 4870,
                "total_score": 76
                },
                {
                "company_id": 3386,
                "total_score": 83
                },
                {
                "company_id": 5072,
                "total_score": 86
                },
                {
                "company_id": 4492,
                "total_score": 89
                },
                {
                "company_id": 5690,
                "total_score": 78
                },
                {
                "company_id": 3270,
                "total_score": 93
                },
                {
                "company_id": 3339,
                "total_score": 88
                },
                {
                "company_id": 4957,
                "total_score": 64
                },
                {
                "company_id": 4830,
                "total_score": 81
                },
                {
                "company_id": 3852,
                "total_score": 78
                },
                {
                "company_id": 3070,
                "total_score": 89
                },
                {
                "company_id": 3545,
                "total_score": 95
                },
                {
                "company_id": 3590,
                "total_score": 91
                },
                {
                "company_id": 4312,
                "total_score": 89
                },
                {
                "company_id": 4430,
                "total_score": 74
                },
                {
                "company_id": 3501,
                "total_score": 80
                },
                {
                "company_id": 3383,
                "total_score": 93
                },
                {
                "company_id": 3671,
                "total_score": 89
                },
                {
                "company_id": 3369,
                "total_score": 78
                },
                {
                "company_id": 3718,
                "total_score": 78
                },
                {
                "company_id": 3289,
                "total_score": 70
                },
                {
                "company_id": 2963,
                "total_score": 83
                },
                {
                "company_id": 5200,
                "total_score": 4
                },
                {
                "company_id": 3643,
                "total_score": 91
                },
                {
                "company_id": 4197,
                "total_score": 92
                },
                {
                "company_id": 3002,
                "total_score": 91
                },
                {
                "company_id": 5110,
                "total_score": 76
                },
                {
                "company_id": 3338,
                "total_score": 75
                },
                {
                "company_id": 4406,
                "total_score": 90
                },
                {
                "company_id": 3335,
                "total_score": 78
                },
                {
                "company_id": 4635,
                "total_score": 87
                },
                {
                "company_id": 3288,
                "total_score": 76
                },
                {
                "company_id": 3865,
                "total_score": 86
                },
                {
                "company_id": 5709,
                "total_score": 93
                },
                {
                "company_id": 3212,
                "total_score": 77
                },
                {
                "company_id": 3775,
                "total_score": 72
                },
                {
                "company_id": 3488,
                "total_score": 93
                },
                {
                "company_id": 3134,
                "total_score": 67
                },
                {
                "company_id": 3250,
                "total_score": 87
                },
                {
                "company_id": 1959,
                "total_score": 97
                },
                {
                "company_id": 4856,
                "total_score": 80
                },
                {
                "company_id": 3820,
                "total_score": 90
                },
                {
                "company_id": 3081,
                "total_score": 95
                },
                {
                "company_id": 3629,
                "total_score": 82
                },
                {
                "company_id": 3183,
                "total_score": 93
                },
                {
                "company_id": 4394,
                "total_score": 90
                },
                {
                "company_id": 1955,
                "total_score": 79
                },
                {
                "company_id": 3720,
                "total_score": 84
                },
                {
                "company_id": 3272,
                "total_score": 86
                },
                {
                "company_id": 3487,
                "total_score": 64
                },
                {
                "company_id": 3078,
                "total_score": 76
                },
                {
                "company_id": 3219,
                "total_score": 40
                },
                {
                "company_id": 4274,
                "total_score": 83
                },
                {
                "company_id": 3158,
                "total_score": 93
                },
                {
                "company_id": 5310,
                "total_score": 85
                },
                {
                "company_id": 3174,
                "total_score": 85
                },
                {
                "company_id": 3632,
                "total_score": 72
                },
                {
                "company_id": 3356,
                "total_score": 92
                },
                {
                "company_id": 4114,
                "total_score": 90
                },
                {
                "company_id": 5130,
                "total_score": 81
                },
                {
                "company_id": 3739,
                "total_score": 95
                },
                {
                "company_id": 5105,
                "total_score": 79
                },
                {
                "company_id": 4887,
                "total_score": 73
                },
                {
                "company_id": 5265,
                "total_score": 87
                },
                {
                "company_id": 3308,
                "total_score": 91
                },
                {
                "company_id": 3132,
                "total_score": 86
                },
                {
                "company_id": 4389,
                "total_score": 87
                },
                {
                "company_id": 3073,
                "total_score": 92
                },
                {
                "company_id": 5144,
                "total_score": 60
                },
                {
                "company_id": 3526,
                "total_score": 92
                },
                {
                "company_id": 3177,
                "total_score": 91
                },
                {
                "company_id": 3897,
                "total_score": 87
                },
                {
                "company_id": 3329,
                "total_score": 82
                },
                {
                "company_id": 3953,
                "total_score": 88
                },
                {
                "company_id": 3681,
                "total_score": 72
                },
                {
                "company_id": 3712,
                "total_score": 71
                },
                {
                "company_id": 4368,
                "total_score": 91
                },
                {
                "company_id": 3735,
                "total_score": 67
                },
                {
                "company_id": 3245,
                "total_score": 91
                },
                {
                "company_id": 3242,
                "total_score": 83
                },
                {
                "company_id": 5073,
                "total_score": 91
                },
                {
                "company_id": 3131,
                "total_score": 71
                },
                {
                "company_id": 3305,
                "total_score": 78
                },
                {
                "company_id": 3076,
                "total_score": 89
                },
                {
                "company_id": 4249,
                "total_score": 92
                },
                {
                "company_id": 3744,
                "total_score": 92
                },
                {
                "company_id": 4193,
                "total_score": 81
                },
                {
                "company_id": 3483,
                "total_score": 93
                },
                {
                "company_id": 3491,
                "total_score": 93
                },
                {
                "company_id": 3890,
                "total_score": 87
                },
                {
                "company_id": 3727,
                "total_score": 82
                },
                {
                "company_id": 359,
                "total_score": 97
                },
                {
                "company_id": 3184,
                "total_score": 97
                },
                {
                "company_id": 3518,
                "total_score": 58
                },
                {
                "company_id": 3228,
                "total_score": 88
                },
                {
                "company_id": 3519,
                "total_score": 71
                },
                {
                "company_id": 3846,
                "total_score": 83
                },
                {
                "company_id": 4417,
                "total_score": 53
                },
                {
                "company_id": 4092,
                "total_score": 94
                },
                {
                "company_id": 360,
                "total_score": 95
                },
                {
                "company_id": 3600,
                "total_score": 84
                },
                {
                "company_id": 3920,
                "total_score": 91
                },
                {
                "company_id": 3658,
                "total_score": 73
                },
                {
                "company_id": 4899,
                "total_score": 58
                },
                {
                "company_id": 4194,
                "total_score": 80
                },
                {
                "company_id": 3879,
                "total_score": 86
                },
                {
                "company_id": 3314,
                "total_score": 75
                },
                {
                "company_id": 3150,
                "total_score": 61
                },
                {
                "company_id": 3152,
                "total_score": 75
                },
                {
                "company_id": 5253,
                "total_score": 82
                },
                {
                "company_id": 4901,
                "total_score": 91
                },
                {
                "company_id": 4912,
                "total_score": 93
                },
                {
                "company_id": 4280,
                "total_score": 33
                },
                {
                "company_id": 4118,
                "total_score": 72
                },
                {
                "company_id": 3478,
                "total_score": 89
                },
                {
                "company_id": 3332,
                "total_score": 85
                },
                {
                "company_id": 4892,
                "total_score": 70
                },
                {
                "company_id": 4886,
                "total_score": 62
                },
                {
                "company_id": 3595,
                "total_score": 65
                },
                {
                "company_id": 5300,
                "total_score": 65
                },
                {
                "company_id": 4059,
                "total_score": 95
                },
                {
                "company_id": 3691,
                "total_score": 83
                },
                {
                "company_id": 5266,
                "total_score": 73
                },
                {
                "company_id": 1887,
                "total_score": 78
                },
                {
                "company_id": 3352,
                "total_score": 89
                },
                {
                "company_id": 3548,
                "total_score": 87
                },
                {
                "company_id": 3645,
                "total_score": 95
                },
                {
                "company_id": 3457,
                "total_score": 89
                },
                {
                "company_id": 3493,
                "total_score": 75
                },
                {
                "company_id": 3580,
                "total_score": 64
                },
                {
                "company_id": 3099,
                "total_score": 83
                },
                {
                "company_id": 4311,
                "total_score": 97
                },
                {
                "company_id": 4363,
                "total_score": 77
                },
                {
                "company_id": 3097,
                "total_score": 93
                },
                {
                "company_id": 3333,
                "total_score": 89
                },
                {
                "company_id": 1956,
                "total_score": 81
                },
                {
                "company_id": 3129,
                "total_score": 87
                },
                {
                "company_id": 3080,
                "total_score": 79
                },
                {
                "company_id": 3280,
                "total_score": 77
                },
                {
                "company_id": 3516,
                "total_score": 72
                },
                {
                "company_id": 3198,
                "total_score": 78
                },
                {
                "company_id": 3116,
                "total_score": 71
                },
                {
                "company_id": 3556,
                "total_score": 82
                },
                {
                "company_id": 4386,
                "total_score": 77
                },
                {
                "company_id": 3249,
                "total_score": 81
                },
                {
                "company_id": 4515,
                "total_score": 88
                },
                {
                "company_id": 3583,
                "total_score": 84
                },
                {
                "company_id": 4317,
                "total_score": 91
                },
                {
                "company_id": 3455,
                "total_score": 93
                },
                {
                "company_id": 361,
                "total_score": 93
                },
                {
                "company_id": 4292,
                "total_score": 83
                },
                {
                "company_id": 4178,
                "total_score": 97
                },
                {
                "company_id": 4742,
                "total_score": 64
                },
                {
                "company_id": 3716,
                "total_score": 70
                },
                {
                "company_id": 3642,
                "total_score": 71
                },
                {
                "company_id": 3215,
                "total_score": 95
                },
                {
                "company_id": 3439,
                "total_score": 96
                },
                {
                "company_id": 3835,
                "total_score": 79
                },
                {
                "company_id": 3147,
                "total_score": 89
                },
                {
                "company_id": 3532,
                "total_score": 71
                },
                {
                "company_id": 3980,
                "total_score": 81
                },
                {
                "company_id": 4289,
                "total_score": 72
                },
                {
                "company_id": 4446,
                "total_score": 76
                },
                {
                "company_id": 3083,
                "total_score": 95
                },
                {
                "company_id": 3069,
                "total_score": 88
                },
                {
                "company_id": 5283,
                "total_score": 83
                },
                {
                "company_id": 1672,
                "total_score": 85
                },
                {
                "company_id": 4356,
                "total_score": 70
                },
                {
                "company_id": 3123,
                "total_score": 86
                },
                {
                "company_id": 4398,
                "total_score": 75
                },
                {
                "company_id": 3348,
                "total_score": 84
                },
                {
                "company_id": 3573,
                "total_score": 79
                },
                {
                "company_id": 3573,
                "total_score": 79
                },
                {
                "company_id": 3916,
                "total_score": 81
                },
                {
                "company_id": 3130,
                "total_score": 79
                },
                {
                "company_id": 3087,
                "total_score": 87
                },
                {
                "company_id": 3657,
                "total_score": 84
                },
                {
                "company_id": 2838,
                "total_score": 84
                },
                {
                "company_id": 5327,
                "total_score": 45
                },
                {
                "company_id": 1954,
                "total_score": 95
                },
                {
                "company_id": 3153,
                "total_score": 55
                },
                {
                "company_id": 3168,
                "total_score": 89
                },
                {
                "company_id": 3179,
                "total_score": 87
                },
                {
                "company_id": 5331,
                "total_score": 71
                },
                {
                "company_id": 5107,
                "total_score": 85
                },
                {
                "company_id": 3149,
                "total_score": 91
                },
                {
                "company_id": 3651,
                "total_score": 89
                },
                {
                "company_id": 3223,
                "total_score": 86
                },
                {
                "company_id": 3613,
                "total_score": 91
                },
                {
                "company_id": 3747,
                "total_score": 79
                },
                {
                "company_id": 3474,
                "total_score": 89
                },
                {
                "company_id": 5409,
                "total_score": 89
                },
                {
                "company_id": 4013,
                "total_score": 89
                },
                {
                "company_id": 3161,
                "total_score": 87
                },
                {
                "company_id": 3528,
                "total_score": 93
                },
                {
                "company_id": 3398,
                "total_score": 93
                },
                {
                "company_id": 3138,
                "total_score": 77
                },
                {
                "company_id": 3146,
                "total_score": 91
                },
                {
                "company_id": 3345,
                "total_score": 91
                },
                {
                "company_id": 4490,
                "total_score": 64
                },
                {
                "company_id": 4385,
                "total_score": 69
                },
                {
                "company_id": 4055,
                "total_score": 71
                },
                {
                "company_id": 3495,
                "total_score": 62
                },
                {
                "company_id": 3066,
                "total_score": 71
                },
                {
                "company_id": 4448,
                "total_score": 48
                },
                {
                "company_id": 4177,
                "total_score": 78
                },
                {
                "company_id": 4313,
                "total_score": 80
                },
                {
                "company_id": 3503,
                "total_score": 75
                },
                {
                "company_id": 3368,
                "total_score": 68
                },
                {
                "company_id": 4420,
                "total_score": 84
                },
                {
                "company_id": 3085,
                "total_score": 91
                },
                {
                "company_id": 3611,
                "total_score": 93
                },
                {
                "company_id": 3273,
                "total_score": 91
                },
                {
                "company_id": 5165,
                "total_score": 49
                },
                {
                "company_id": 3479,
                "total_score": 72
                },
                {
                "company_id": 4428,
                "total_score": 55
                },
                {
                "company_id": 3268,
                "total_score": 97
                },
                {
                "company_id": 4283,
                "total_score": 95
                },
                {
                "company_id": 3297,
                "total_score": 95
                },
                {
                "company_id": 3172,
                "total_score": 74
                },
                {
                "company_id": 3185,
                "total_score": 91
                },
                {
                "company_id": 4276,
                "total_score": 87
                },
                {
                "company_id": 3084,
                "total_score": 75
                },
                {
                "company_id": 362,
                "total_score": 93
                },
                {
                "company_id": 3605,
                "total_score": 77
                },
                {
                "company_id": 5086,
                "total_score": 56
                },
                {
                "company_id": 5306,
                "total_score": 69
                },
                {
                "company_id": 5027,
                "total_score": 85
                },
                {
                "company_id": 4730,
                "total_score": 77
                },
                {
                "company_id": 3306,
                "total_score": 73
                },
                {
                "company_id": 3894,
                "total_score": 83
                },
                {
                "company_id": 4212,
                "total_score": 91
                },
                {
                "company_id": 3155,
                "total_score": 93
                },
                {
                "company_id": 5252,
                "total_score": 91
                },
                {
                "company_id": 3584,
                "total_score": 81
                },
                {
                "company_id": 3316,
                "total_score": 91
                },
                {
                "company_id": 4218,
                "total_score": 89
                },
                {
                "company_id": 5329,
                "total_score": 56
                },
                {
                "company_id": 5031,
                "total_score": 81
                },
                {
                "company_id": 5116,
                "total_score": 70
                },
                {
                "company_id": 3678,
                "total_score": 93
                },
                {
                "company_id": 4831,
                "total_score": 83
                },
                {
                "company_id": 4754,
                "total_score": 96
                },
                {
                "company_id": 4832,
                "total_score": 82
                },
                {
                "company_id": 3640,
                "total_score": 97
                },
                {
                "company_id": 4321,
                "total_score": 91
                },
                {
                "company_id": 3574,
                "total_score": 68
                },
                {
                "company_id": 4242,
                "total_score": 60
                },
                {
                "company_id": 4838,
                "total_score": 76
                },
                {
                "company_id": 5658,
                "total_score": 83
                },
                {
                "company_id": 3662,
                "total_score": 86
                },
                {
                "company_id": 3385,
                "total_score": 70
                },
                {
                "company_id": 4888,
                "total_score": 80
                },
                {
                "company_id": 3598,
                "total_score": 85
                },
                {
                "company_id": 3220,
                "total_score": 81
                },
                {
                "company_id": 3328,
                "total_score": 91
                },
                {
                "company_id": 3093,
                "total_score": 82
                },
                {
                "company_id": 3071,
                "total_score": 97
                },
                {
                "company_id": 3082,
                "total_score": 89
                },
                {
                "company_id": 3344,
                "total_score": 65
                },
                {
                "company_id": 3118,
                "total_score": 81
                },
                {
                "company_id": 4380,
                "total_score": 70
                },
                {
                "company_id": 3565,
                "total_score": 85
                },
                {
                "company_id": 3649,
                "total_score": 77
                },
                {
                "company_id": 363,
                "total_score": 95
                },
                {
                "company_id": 4339,
                "total_score": 84
                },
                {
                "company_id": 3156,
                "total_score": 82
                },
                {
                "company_id": 3936,
                "total_score": 81
                },
                {
                "company_id": 3936,
                "total_score": 81
                },
                {
                "company_id": 3578,
                "total_score": 83
                },
                {
                "company_id": 3176,
                "total_score": 94
                },
                {
                "company_id": 3110,
                "total_score": 95
                },
                {
                "company_id": 4444,
                "total_score": 74
                },
                {
                "company_id": 3696,
                "total_score": 93
                },
                {
                "company_id": 4183,
                "total_score": 74
                },
                {
                "company_id": 3730,
                "total_score": 95
                },
                {
                "company_id": 3804,
                "total_score": 97
                },
                {
                "company_id": 3148,
                "total_score": 91
                },
                {
                "company_id": 3728,
                "total_score": 87
                },
                {
                "company_id": 4487,
                "total_score": 61
                },
                {
                "company_id": 3225,
                "total_score": 75
                },
                {
                "company_id": 5676,
                "total_score": 89
                },
                {
                "company_id": 3608,
                "total_score": 85
                },
                {
                "company_id": 3674,
                "total_score": 81
                },
                {
                "company_id": 3247,
                "total_score": 90
                },
                {
                "company_id": 3143,
                "total_score": 81
                },
                {
                "company_id": 5370,
                "total_score": 85
                },
                {
                "company_id": 4906,
                "total_score": 89
                },
                {
                "company_id": 3692,
                "total_score": 95
                },
                {
                "company_id": 3221,
                "total_score": 93
                },
                {
                "company_id": 2998,
                "total_score": 89
                },
                {
                "company_id": 3856,
                "total_score": 88
                },
                {
                "company_id": 3210,
                "total_score": 39
                },
                {
                "company_id": 4460,
                "total_score": 80
                },
                {
                "company_id": 3164,
                "total_score": 70
                },
                {
                "company_id": 3717,
                "total_score": 82
                },
                {
                "company_id": 3547,
                "total_score": 95
                },
                {
                "company_id": 3374,
                "total_score": 87
                },
                {
                "company_id": 3702,
                "total_score": 71
                },
                {
                "company_id": 3724,
                "total_score": 91
                },
                {
                "company_id": 4414,
                "total_score": 91
                },
                {
                "company_id": 4320,
                "total_score": 91
                },
                {
                "company_id": 4909,
                "total_score": 79
                },
                {
                "company_id": 3685,
                "total_score": 79
                },
                {
                "company_id": 3207,
                "total_score": 90
                },
                {
                "company_id": 5701,
                "total_score": 67
                },
                {
                "company_id": 4466,
                "total_score": 89
                },
                {
                "company_id": 4449,
                "total_score": 97
                },
                {
                "company_id": 3860,
                "total_score": 97
                },
                {
                "company_id": 3553,
                "total_score": 75
                },
                {
                "company_id": 4484,
                "total_score": 89
                },
                {
                "company_id": 3074,
                "total_score": 49
                },
                {
                "company_id": 1731,
                "total_score": 93
                },
                {
                "company_id": 3524,
                "total_score": 96
                },
                {
                "company_id": 3381,
                "total_score": 92
                },
                {
                "company_id": 4402,
                "total_score": 85
                },
                {
                "company_id": 4405,
                "total_score": 84
                },
                {
                "company_id": 3544,
                "total_score": 83
                },
                {
                "company_id": 3124,
                "total_score": 93
                },
                {
                "company_id": 3124,
                "total_score": 93
                },
                {
                "company_id": 3091,
                "total_score": 93
                },
                {
                "company_id": 3700,
                "total_score": 91
                },
                {
                "company_id": 5284,
                "total_score": 89
                },
                {
                "company_id": 3089,
                "total_score": 93
                },
                {
                "company_id": 3079,
                "total_score": 93
                },
                {
                "company_id": 3639,
                "total_score": 90
                },
                {
                "company_id": 4563,
                "total_score": 91
                },
                {
                "company_id": 2109,
                "total_score": 92
                },
                {
                "company_id": 3128,
                "total_score": 73
                },
                {
                "company_id": 3732,
                "total_score": 87
                },
                {
                "company_id": 3340,
                "total_score": 88
                },
                {
                "company_id": 3758,
                "total_score": 84
                },
                {
                "company_id": 3546,
                "total_score": 88
                },
                {
                "company_id": 3170,
                "total_score": 64
                },
                {
                "company_id": 3460,
                "total_score": 65
                },
                {
                "company_id": 4534,
                "total_score": 76
                },
                {
                "company_id": 5033,
                "total_score": 83
                },
                {
                "company_id": 3725,
                "total_score": 67
                },
                {
                "company_id": 3882,
                "total_score": 83
                },
                {
                "company_id": 3734,
                "total_score": 85
                },
                {
                "company_id": 5087,
                "total_score": 87
                },
                {
                "company_id": 3258,
                "total_score": 95
                },
                {
                "company_id": 3396,
                "total_score": 93
                },
                {
                "company_id": 3166,
                "total_score": 86
                },
                {
                "company_id": 3490,
                "total_score": 85
                },
                {
                "company_id": 3789,
                "total_score": 97
                },
                {
                "company_id": 354,
                "total_score": 86
                },
                {
                "company_id": 3789,
                "total_score": 97
                },
                {
                "company_id": 3127,
                "total_score": 84
                },
                {
                "company_id": 4501,
                "total_score": 82
                },
                {
                "company_id": 3204,
                "total_score": 91
                },
                {
                "company_id": 5596,
                "total_score": 66
                },
                {
                "company_id": 4451,
                "total_score": 79
                },
                {
                "company_id": 5245,
                "total_score": 70
                },
                {
                "company_id": 4041,
                "total_score": 95
                },
                {
                "company_id": 3972,
                "total_score": 95
                },
                {
                "company_id": 4627,
                "total_score": 39
                },
                {
                "company_id": 3067,
                "total_score": 97
                },
                {
                "company_id": 4298,
                "total_score": 71
                },
                {
                "company_id": 5712,
                "total_score": 92
                },
                {
                "company_id": 4471,
                "total_score": 77
                },
                {
                "company_id": 3120,
                "total_score": 89
                },
                {
                "company_id": 3115,
                "total_score": 75
                },
                {
                "company_id": 3886,
                "total_score": 79
                },
                {
                "company_id": 3844,
                "total_score": 83
                },
                {
                "company_id": 3456,
                "total_score": 86
                },
                {
                "company_id": 3738,
                "total_score": 76
                },
                {
                "company_id": 3331,
                "total_score": 75
                },
                {
                "company_id": 4894,
                "total_score": 92
                },
                {
                "company_id": 4285,
                "total_score": 73
                },
                {
                "company_id": 4372,
                "total_score": 95
                },
                {
                "company_id": 4213,
                "total_score": 70
                },
                {
                "company_id": 3855,
                "total_score": 72
                },
                {
                "company_id": 3217,
                "total_score": 95
                },
                {
                "company_id": 5120,
                "total_score": 88
                },
                {
                "company_id": 5721,
                "total_score": 65
                }
            ]

export default hmc_total_score_obj