<template>
<div :class="audit_mode!=true&&previous!=true ? 'mb-5':'gray-600 font-scale-sm'">
    <div v-show="is_visible_by_pipeline==true" :class="previous==true ? 'p-3 gray-500 rounded border bgray-100':''">

        <div v-if="headers">
            <template>
                <div :class="[ sub_name != '' ? 'fw-500 f-110 border-bottom mb-2':'', '' ]"> <span v-if="sub_data_header.required && sub_data_header.required==true">*</span>{{ byLang(sub_name,sub_name_e, sub_name_c) }} </div>
                <div :class="[ sub_description != '' ? 'mb-3': '', 'mb-1']">
                    <div v-if="is_indicate_description" class="mr-1 fw-500 d-inline px-0">
                        <span v-if="sub_name === '' && sub_data_header.required && sub_data_header.required === true">*</span>
                        {{ byLang(sub_description,sub_description_e, sub_description_c) }}
                    </div>
                    <span v-for="item in tags" :key="item.id"> <span v-if="item != ''" class="ml-2 f-80 gray-500"> {{ item }} </span></span>
                    <!-- <b-button  v-if="sub_info != ''" class="ml-1 px-1 py-0 f-80" variant="teal" size="sm" :id="`i-${ sub_code }`" @click="toggleVisible" ><fa icon="book-open"></fa> 작성 도움말</b-button> -->

                    <span v-if="sub_priority != ''"><b-badge :variant="badge_var" class="mx-2">{{  sub_priority }}</b-badge></span>
                    <b-button v-if="previous_sub.sub_code!=undefined && previous!=true" v-b-toggle="previous_sub.sub_code" size="sm" variant="red" class="py-0">{{ $t('과거 답변 보기') }}<fa icon="history" class="ml-2"></fa></b-button>

                    <!-- sub_info 정보 -->
                    <!-- <div v-if="is_visible" :id="`sub_info-${ sub_code }`" class="mt-2 mb-3 px-1 blue f-90"> <fa icon="info-circle" class="mr-1"></fa><span v-html="sub_info"></span></div> -->
                    <b-card v-if="sub_info != ''" :id="`sub_info-${ sub_code }`" class="mt-2 mb-3 border-0 teal8 bg-secondary2 f-90"> <fa icon="info-circle" class="mr-1"></fa><span v-html="byLang(sub_info, sub_info_e, sub_info_c)"></span></b-card>

                    <!-- sub_condition -->
                    <div v-if="is_sub_condition_activated==true" :id="`sub_condition-${sub_code}`" class="my-2 py-2">
                        <b-form-checkbox :disabled="is_locked || is_disabled_by_parent" v-model="sub_condition.value" @change="radioSave()"> {{byLang(sub_condition.text, sub_condition.text_e, sub_condition.text_c) }} </b-form-checkbox>
                    </div>
                </div>
            </template>
        </div>
        
        <!-- previous component -->
        <b-collapse v-if="print_mode!=true" :id="previous_sub.sub_code" class="mb-5">
            <sub-component :disabled="true" v-if="previous_sub.sub_code != undefined && previous != true" :is_disabled_by_parent="true" :sub_type="previous_sub.sub_type" :item="previous_sub" :previous="true"></sub-component>
        </b-collapse>

        <b-overlay :show="is_visible_by_pipeline==false" rounded="sm" opacity="0.6" bg-color="#c8c8c8">
            <!-- CheckBox 유형 Sub Component-->
            <!-- <b-form-radio-group v-model="not_applicable" @change="toggleNA"> -->
            <!-- <b-form-radio :disabled="is_locked || is_disabled_by_condition ||is_disabled_by_parent" v-if="sub_data_header.enable_na==true" :value="false"></b-form-radio> -->

            <b-form-checkbox-group v-model="sub_data_contents.result" @change="handleChange" stacked >
                <b-row v-for="(checkbox_option, index) in sub_data_contents.structure" :key="checkbox_option.id" class="ml-2 w-100">
                    <b-col cols="12" class="mb-1" >
                        <b-form-checkbox
                        :value="checkbox_option.value"
                        :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent || is_disabled_by_na"
                        >
                        <span v-html="byLang(checkbox_option.text, checkbox_option.text_ea, checkbox_option.text_ca)"></span> </b-form-checkbox>
                    </b-col>
                    <b-col cols="11" class="mb-2 ml-4">
                        <!-- {{checkbox_option}} -->
                        <b-input-group v-if="checkbox_option.description != undefined">
                            <b-form-input
                                v-model="checkbox_option.description"
                                :disabled="!sub_data_contents.result.includes(checkbox_option.value) || is_locked || is_disabled_by_condition || is_disabled_by_parent || is_disabled_by_na"
                                :placeholder="checkbox_option.placeholder ? byLang(checkbox_option.placeholder, checkbox_option.placeholder_e, checkbox_option.placeholder_c) : $t(`상세설명`)"
                                class="f-90"
                                @update="textInputSave"
                                ></b-form-input>
                            <b-input-group-append><b-button :disabled="!sub_data_contents.result.includes(checkbox_option.value)  || is_locked || is_disabled_by_condition || is_disabled_by_parent || is_disabled_by_na" v-b-modal="`checkbox-text-${sub_code}-${index}`" size="sm" variant="icon-append"><fa icon="pen"></fa></b-button></b-input-group-append>
                            <b-modal @ok="dbSave" :id="`checkbox-text-${sub_code}-${index}`" :title="checkbox_option.value ? checkbox_option.value : $t(`텍스트 상세입력`)" size="lg">
                                <div>
                                    <p class="mb-2 pl-1 f-90"> {{$t(`상세설명을 작성하여 주십시오.`)}}</p>
                                    <b-textarea rows="5" v-model="checkbox_option.description"></b-textarea>
                                </div>
                            </b-modal>
                        </b-input-group>
                    </b-col>
                </b-row>               
            </b-form-checkbox-group>

            <!--해당없음-->
            <b-form-radio class="ml-4" :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent" v-if="sub_data_header.enable_na==true" :value="true">{{$t(`해당없음`)}}</b-form-radio>
            <!-- </b-form-radio-group> -->
            <div v-if="not_applicable==true"><b-form-input v-model="sub_data_contents.na_reason" placeholder="본 질문이 귀사에 해당되지 않는 사유를 간략히 입력해 주세요." @update="textInputSave"></b-form-input></div>

            <!--validation-->
            <div v-if="previous != true && audit_mode!=true &&custom_style==''" :class="validation_style" class="mt-2"><fa v-if="validation_icon !=''" :icon="validation_icon"></fa> {{ validation_comment }} </div>

            <!-- Child Component -->
            <sub-component v-for="(item, index) in child_subs" :key="item.id"
            :is_final_sub_component="index == child_subs.length -1 ? true : false"
            :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent" 
            :sub_type="item.sub_type" 
            :item="item" 
            :audit_mode="audit_mode" 
            :is_disabled_by_parent="is_disabled_by_parent" ></sub-component>

            
            <!-- is_visible_by_pipeline 조건에 따른 해당없음 overlay -->
            <template v-slot:overlay>
                <div class="text-center ">
                    {{$t(`해당사항 없음`)}} <b-button variant="icon" v-b-tooltip.hover :title="pipeline_title"><fa icon="info-circle"></fa></b-button>
                </div>
            </template>
        </b-overlay>

    </div>



</div>
</template>


<script>
import { SubComponentMixin } from './SubComponentMixin'
import * as ih from '@/util'

export default {
    data(){
        return{
            button_title: this.$t(`평가정보`),
            pipeline_title: this.$t(`이 문항은 답변할 필요가 없습니다.`)
        }
    },
    mixins: [SubComponentMixin],
    computed:{
        // not_applicable:{
        //     get(){
        //         if(this.sub_data_contents.result == '해당없음'){
        //             return true
        //         }
        //         else{
        //             return false
        //         }
        //     },
        //     set(newVal){
        //         if(newVal==true){
        //             this.sub_data_contents.result = '해당없음'
        //         }
        //         else{
        //             this.sub_data_contents.result = []
        //         }
        //     }
        // }
    },
    methods:{
        byLang(ko, en, cn){
            if(this.$i18n.locale=='en') return en
            else if (this.$i18n.locale=='cn') return cn
            else return ko
        },
        async handleChange () {
            this.$nextTick(async () =>  {
                //calculate score
                this.not_applicable = false
                if(this.sub_data_header.scoring && this.sub_data_header.scoring=='auto'){
                    let temp_score = 0

                    this.sub_data_contents.result.forEach(item => {
                        let idx = this.sub_data_contents.structure.findIndex(x => { return x.value == item })
                        if(idx > -1){
                            if(this.sub_data_contents.structure[idx].score){
                                temp_score += Number(this.sub_data_contents.structure[idx].score) 
                            }
                        }
                    })
                    this.score_result = temp_score
                }
            })
            await this.radioSave()
        },
        toggleNA(){
            this.$nextTick(async () => {
                if(this.not_applicable == false){
                    this.sub_data_contents.result = []
                    delete this.sub_data_contents.na_reason
                    //this.is_disabled_by_na = false
                }
                else {
                    this.sub_data_contents.result = $t(`해당없음`)
                    //this.is_disabled_by_na = true
                    await this.radioSave()
                }
            })
        },
    }
}


</script>

<style scoped>

</style>
