<template>
<div>
                  
    <b-row no-gutters class='mb-45'>
        <b-col class="pr-4"><h5 class="tail-line gray-550">전문가 제안 개선과제</h5></b-col>
        <b-col><h5 class="tail-line-dark">{{company_name}} 개선계획</h5></b-col>
    </b-row>

    <b-row no-gutters v-for="(item, index) in recommendations_by_company" :key="index" class="mb-5 py-2 hover-dark">
        <b-col class="d-flex align-items-start text-left pr-3 pl-2" variant="icon">  
            <b-badge variant="advance" class="mb-2 mr-3">개선과제 {{ index+1 }}.</b-badge>
            <div><h6 class="f-110"> {{item.title}}</h6>
            <p> {{ item.recommendation }} </p></div>
        </b-col>

        <b-col v-if="is_recommendations_by_company_loaded==true">  
            <b-textarea v-model="corrective_action_item[item.rec_code].action_plan" rows="4" placeholder="개선을 위한 조치 및 향후 관리 계획을 작성하여 주십시오." :disabled="submit_disabled"
                @update="textInputSave(item.rec_code, corrective_action_item[item.rec_code].action_plan, corrective_action_item[item.rec_code].timeframe, index)"></b-textarea>
            <b-form-select v-model="corrective_action_item[item.rec_code].timeframe" :disabled="submit_disabled" aria-placeholder="개선 목표 기간을 선택하여 주십시오."
                @change="textInputSave(item.rec_code, corrective_action_item[item.rec_code].action_plan, corrective_action_item[item.rec_code].timeframe, index)">
                <template v-slot:first>
                    <b-form-select-option :value="null" disabled> -- 단기/중기/장기/계획없음 -- </b-form-select-option>
                </template>
                <b-form-select-option value="단기">단기(1년 이내)</b-form-select-option>
                <b-form-select-option value="중기">중기(2년 이내)</b-form-select-option>
                <b-form-select-option value="장기">장기(5년 이내)</b-form-select-option>
                <b-form-select-option value="계획 없음">계획 없음</b-form-select-option>
            </b-form-select>
            
            <div v-if="save_state[index]!=''" :class="save_state[index]=='saving'?'ih-validation-saving':'ih-validation-saved'" class="mt-2"><fa :icon="save_state[index]=='saving'?'hourglass':'check'"></fa> {{ save_state[index]=='saving' ? this.$t(`저장 중 입니다.`) : this.$t(`저장 되었습니다.`) }} </div>
        </b-col>
    </b-row>


    <div class="text-right">
        <b-button :variant="submit_disabled==false?'blue':'green'" @change="handleSubmit">
            <fa :icon="submit_disabled==false?'paper-plane':'check'" class="mr-1"></fa>
            {{ submit_disabled==false?'제출하기':'제출됨'}}
        </b-button>
    </div>

</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import debounce from 'lodash.debounce'

export default {
    data() {
        return {
            corrective_actions:[{
                rec_code: '',
                action_plan: '',
                timeframe: '',
                user_name: 0,
                time_stamp: '',
                },
                {
                rec_code: '',
                action_plan: '',
                timeframe: '',
                user_name: 0,
                time_stamp: '',
                }
            ],
            save_state:['','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','',''],
            submit_disabled: false
        }
    },
    props:{
        // released:{ type: Boolean }
    },
    computed:{
        ...mapState(['campaign_in_progress','company_name','company_id','user_id']),
        ...mapState('reviews', ['conclusion_by_company', 'is_conclusion_by_company_loaded', 'recommendations_by_company', 'is_recommendations_by_company_loaded']),
        corrective_action_item(){
            let obj = {}
            this.recommendations_by_company.forEach(item => {
                let idx = this.corrective_actions.findIndex(cor_item => cor_item.rec_code == item.rec_code)
                if (idx >-1){
                    let temp = { 
                    campaign_id: this.corrective_actions[idx].campaign_id,
                    action_plan: this.corrective_actions[idx].action_plan,
                    timeframe: this.corrective_actions[idx].timeframe,
                    user_name: this.corrective_actions[idx].user_name,
                    time_stamp: this.corrective_actions[idx].time_stamp,
                    }
                    obj[item.rec_code] = temp
                }
                else {
                    let temp = {
                    campaign_id: '',
                    action_plan: '',
                    timeframe: '',
                    user_name: '', 
                    time_stamp: '',
                    }
                    obj[item.rec_code] = temp
                }
            
            })
            return obj
        }
    },
    methods:{
        ...mapMutations('reviews', ['update_is_conclusion_by_company_loaded', 'update_is_recommendations_by_company_loaded']),
        // ...mapActions('reviews', ['readConclusionbyCompany', 'readRecommendationsbyCompany']),
        temp_actions(rec_code){
            // let temp_actions = []
            let idx = this.corrective_actions.findIndex(item => item.rec_code == rec_code ) 
            return this.corrective_actions[idx]
        },
        saveCorrectiveAction(rec_code, action_plan, timeframe, index){
            const path = this.$store.state.backend_host + '/save_corrective_action'

            let formData = new FormData()
            formData.append('company_id', this.company_id)
            formData.append('rec_code', rec_code)
            formData.append('action_plan', action_plan )
            formData.append('timeframe', timeframe)
            formData.append('user_id', this.user_id)

            return axios.post(path, formData)
            .then( ()=> {
                // console.log(response.data)
                Vue.set(this.save_state, index, 'saved')
                }
            )
        },
        textInputSave(rec_code, action_plan, timeframe, index) {
            Vue.set(this.save_state, index, 'saving')
            this.debouncedSave(rec_code, action_plan, timeframe, index)
        },
        debouncedSave: debounce( function (rec_code, action_plan, timeframe, index) {  
            Vue.nextTick(() =>
                {
                    this.saveCorrectiveAction(rec_code, action_plan, timeframe, index)
                })
            },
            //사용자 입력을 기다리는 시간
            800
        ),
        readCorrectiveAction(){
            const path = this.$store.state.backend_host + '/read_corrective_actions'
            return axios.get(path, {params:{
                campaign_id : this.campaign_in_progress.campaign_id,
                company_id : this.company_id
            }})
            .then ( response => {
                this.corrective_actions = response.data
                console.log(this.corrective_actions);
            })
        },
        handleSubmit(){
            const path = this.$store.state.backend_host + '/save_action_submit'

            let temp_status = false
            if(this.submit_disabled==false){
                temp_status = true
            }

            let formData = new FormData()
            formData.append('company_id', this.company_id)
            formData.append('campaign_id', this.campaign_in_progress.campaign_id)
            formData.append('submit', temp_status)

            return axios.post(path, formData)
            .then(response =>{
                let result = response.data
                console.log(result.submit);

                if (result.submit==true){
                    this.submit_disabled = true
                }
                else this.submit_disabled = false
            })
        },
        readSubmit(){
            const path = this.$store.state.backend_host + '/read_action_submit'

            return axios.get(path, {params:{
                company_id: this.company_id,
                campaign_id: this.campaign_in_progress.campaign_id,
            }})
            .then (response =>{
                let result = response.data
                console.log(result);
                // if (result[0].submit==true){
                //     this.submit_disabled = true
                // }else this.submit_disabled = false
            })
        }

    },
    created(){
        this.readCorrectiveAction()
        this.readSubmit()
    },
    mounted(){
        console.log(this.is_recommendations_by_company_loaded);
    },
    watch:{
        campaign_in_progress:{
            deep:true,
            // immediate: true,
            handler(newVal, oldVal){
                // console.log(oldVal.campaign_id);
                // console.log(newVal.campaign_id);
                if (newVal.campaign_id != oldVal.campaign_id){

                    this.$emit('show-dashboard')
                }
            }
        }
    }

}
</script>

<style>
.hover-dark{
    color: var(--gray-550);
    background-color: var(--gray-100);
}
.hover-dark:hover{
    color: var(--gray-700);
    background-color: var(--gray-200);
}
</style>