import * as ih from '../../../util'

export const EditingSubComponentMixin = {
    data() {
        return {
            is_condition_available: false,

            sub_types: ['checkbox','table', 'radio', 'input_text', 'textbox', 'input_number', 'file_URL2', 'materiality', 'priority', 'guide_text'],

            sub_condition_information: '선행조건을 둘 경우, 해당 체크박스를 클릭해야만 구체적인 답변을 입력할 수 있도록 제한합니다',
            sub_data_header_information: {
                checkbox: `{ "value": [] `,
                table: `{ "table_header": ["No", "이름", "독립성", "직업"]}`,
                radio: `{
                    "value": ""
                }`,
                radio_group: `{}`,
                input_text: `{ "placeholder": "텍스트를 입력해 주세요" }`,
                textbox: `{ "placeholder": "텍스트를 입력해 주세요" }`,
                input_number: `{ "placeholder": "수치를 입력해 주세요" }`,
                file: ``,
                URL: ``,
                file_URL: ``,
                file_URL2: ``,
                editable_table: `{ "table_header": ["No", "이름", "독립성", "직업"] }`,

            },
            sub_data_contents_information: {
                checkbox: `[ {"text": "예", "value": "예", "description": ""},
                    {"text": "아니오", "value": "아니오", "description": ""}]`,
                table: `[{ "No": "1", "이름": "홍길동", "독립성": "", "직업": "모름",
                    "noteditables": ["No", "이름"],
                    "dropdown": { "독립성": ["독립","비독립"] },
                    "placeholders": { "독립성": "독립성 여부를 입력해 주세요" }
                    },
                    { "No": "2", "이름": "누구", "독립성": "비독립", "직업": "모름" }]`,
                radio: `{{ "text": "예", "value": "예" },
                    { "text": "아니오", "value": "아니오"}}`,
                radio_group: `[{ "label": "대기배출량 종류", "option": [ "1층", "2층", "3층" ], "selected": "" },
                    { "label": "폐수처리", "option": [ "예", "아니오" ], "selected": "" }]`,
                input_text: `[]`,
                textbox: `[]`,
                input_number: `[]`,
                file: ``,
                URL: ``,
                file_URL: `[]`,
                file_URL2: `[]`,
                editable_table: `[{ "No": "1", "이름": "홍길동", "독립성": "", "직업": "모름",
                    "noteditables": ["No", "이름"],
                    "dropdown": { "독립성": ["독립","비독립"] },
                    "placeholders": { "독립성": "독립성 여부를 입력해 주세요" }
                    },
                    { "No": "2", "이름": "누구", "독립성": "비독립", "직업": "모름" }]`
            },
            sub_data_header_popover_show: false,
            sub_data_contents_popover_show: false,
            sub_condition_popover_show: false,

            has_child: false,
            disable_child: ['file', 'file_URL', 'file_URL2'],

            dismissSecs: 2,
            dismissCountDown: false,
            alert_msg: '',
            alert_variant: 'danger',
            
            sub_name: '',
            sub_description: '',
            sub_info: '',
            sub_condition: '',
            sub_data_header: {},
            sub_data_contents: {},
            sub_priority: '',

            sub_priority_option: [
                { value: '', text: ''},
                { value: '기초', text: '기초'},
                { value: '심화', text: '심화'},
                { value: '잠재', text: '잠재'}
            ],

            sub_child: '',
            sub_parent: '',
            tags: '',

            scoring: 'No Scoring',

            idx: -1,

        }
    },
    props: {
        sub_code: { type: String, default: '' }
    },
    computed: {
        sub_type:{
            get() {
                return this.$store.state.question_subs[this.idx].sub_type
            },
            set(newValue) {
                let new_sub = {
                    sub_code: this.sub_code,
                    sub_name: this.sub_name,
                    sub_type: newValue,
                    sub_description: this.sub_description,
                    sub_info: this.sub_info,
                    sub_condition: this.sub_condition,
                    sub_data_header: this.sub_data_header,
                    sub_data_contents: this.sub_data_contents,
                    sub_priority: this.sub_priority,
                    sub_child: this.sub_child,
                    sub_parent: this.sub_parent,
                    tags: this.tags
                }
                
                if (!Array.isArray(new_sub.tags) && new_sub.tags === '') {
                    new_sub.tags = []
                }
                
                this.$store.commit('update_question_sub', new_sub)
            }
        }
    },
    created(){
        if (this.sub_condition.text && this.sub_condition.text != '') 
            { this.is_condition_available = true }
    },
    watch: {
        sub_child: {
            immediate: true,
            handler(newValue, oldValue){
                if (this.sub_child.length != undefined && this.sub_child.length !=0)
                {
                    this.has_child = true
                }
                else {
                    this.has_child = false
                }
            }
        }
    },
    methods: {
        saveSub(){
            const new_sub = {
                sub_code: this.sub_code,
                sub_name: this.sub_name,
                sub_type: this.sub_type,
                sub_description: this.sub_description,
                sub_condition: this.sub_condition,
                sub_info: this.sub_info,
                sub_data_header: this.sub_data_header,
                sub_data_contents: this.sub_data_contents,
                sub_priority: this.sub_priority,
                sub_child: this.sub_child,
                sub_parent: '',
                tags: this.tags
            }
            return this.$store.dispatch('saveQuestionSub', new_sub)
        },
        selectType(item){
            this.sub_type = item
            //this.$emit('change-type', this.sub_type)
            this.$forceUpdate()
        },
        conditionPopoverToggle(x){
            if (x === true || x === false){
                this.sub_condition_popover_show = x
            }
            else {
                this.sub_condition_popover_show = !this.sub_condition_popover_show
            }
        },
        headerPopoverToggle(x){
            if (x === true || x === false){
                this.sub_data_header_popover_show = x
            }
            else {
                this.sub_data_header_popover_show = !this.sub_data_header_popover_show
            }
        },
        contentsPopoverToggle(x){
            if (x === true || x === false){
                this.sub_data_contents_popover_show = x
            }
            else {
                this.sub_data_contents_popover_show = !this.sub_data_contents_popover_show
            }
        },
        isValid(JSONString){
            return ih.isJSON(JSONString)
        },
        handleHasChild(){
            this.$nextTick( () => {
                if(this.has_child == true){
                    //Case I. 기존자식이 없고, 새로운 자식이 추가 = 새로운 차일드 컴포넌트 생성하여 붙이기
                    let new_sub = {
                    sub_code: 'c' + Math.floor(Math.random() * 1000000000),
                    sub_name: '',
                    sub_type: 'file_URL2',
                    sub_condition: {},
                    sub_description: '',
                    sub_info: '',
                    sub_priority: '',
                    sub_data_header: {},
                    sub_data_contents: {},
                    tags: '',
                    sub_parent: this.sub_code,
                    sub_child: []
                    }
                    this.$store.dispatch('saveQuestionSub', new_sub)
                    .then(() => {
                        //이전에 만들어 놓은 질문일 경우 오류예외처리
                        if(this.sub_child.length == undefined) { this.sub_child = [] }
                        
                        this.sub_child.push(new_sub.sub_code)
                        this.saveSub()
                    })
                }
                else if(this.has_child == false){
                    //Case II. 기존자식이 있고, 새로운 자식을 없앰 = 기존 차일드 컴포넌트 삭제하기
                    this.sub_child = []
                }

            })
        },
        checkAbleChild(){
            if(this.disable_child.includes(this.sub_type)) return false
            else return true
        },
        manageConditionAvailability(){
            Vue.nextTick( () => {
                //Condition 체크박스를 해제하였으면
                if(this.is_condition_available != true) {
                    delete this.sub_condition.text
                    delete this.sub_condition.value
                }
                //condition 체크박스를 체크한 것이면
                else {
                    //기본 컨디션 상태를 false로 초기화
                    this.sub_condition.value = false 
                }
            })
        },
        showAlert(alert_variant, msg) {
            this.alert_variant = alert_variant
            this.alert_msg = msg
            this.dismissCountDown = this.dismissSecs
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        setScoring(mode){
            if(mode=='auto') { 
                this.scoring='Auto Scoring'
                Vue.set(this.sub_data_header, 'scoring', 'auto')
            }
            // else if(mode=='manual'){
            //     this.scoring='수동(평가자가 평가)'
            //     Vue.set(this.sub_data_header, 'scoring', 'manual')
            // }
            else{
                this.scoring='No Scoring'
                Vue.set(this.sub_data_header, 'scoring', 'none')
            }

        }
    },
    created(){
        this.idx = this.$store.state.question_subs.findIndex( item => { return item.sub_code == this.sub_code })
        if (this.idx > -1){
            this.sub_name = this.$store.state.question_subs[this.idx].sub_name
            this.sub_description = this.$store.state.question_subs[this.idx].sub_description
            this.sub_info = this.$store.state.question_subs[this.idx].sub_info
            this.sub_condition = this.$store.state.question_subs[this.idx].sub_condition
            this.sub_data_header = this.$store.state.question_subs[this.idx].sub_data_header
            this.sub_data_contents = this.$store.state.question_subs[this.idx].sub_data_contents
            this.sub_priority = this.$store.state.question_subs[this.idx].sub_priority
            this.sub_child = this.$store.state.question_subs[this.idx].sub_child
            this.sub_parent = this.$store.state.question_subs[this.idx].sub_parent
            if (typeof(this.$store.state.question_subs[this.idx].tags) == 'object') {
                this.tags = this.$store.state.question_subs[this.idx].tags.toString()
            }
            else {
                this.tags = this.$store.state.question_subs[this.idx].tags
            }
        }
    }
}