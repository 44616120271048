<template>
<div>
    <div id="campaign-container" v-if="register_mode==false&&operation_mode==false">
        <div class="w-100 bgray-200 text-center pt-3 position-relative"> 
            <h3 class="f-160 py-5"> {{ campaign.campaign_name }} </h3> 
            <b-nav tabs align="center" class="">
                <b-nav-item :active="sub_menu[0]" @click.stop="pageGo({path: '/campaign_progress'})" class="sub-nav"> {{$t(`진행현황`)}} </b-nav-item>
                <b-nav-item :disabled="checkAllowed(campaign.campaign_state, campaign.type, is_admin, 2)==false" :active="sub_menu[1]" @click.stop="pageGo({path: '/answers'})" class="sub-nav"> {{$t(`기업별 답변`)}} </b-nav-item>
                <b-nav-item :disabled="checkAllowed(campaign.campaign_state, campaign.type, is_admin, 2)==false" :active="sub_menu[2]" @click.stop="pageGo({path: '/campaign_report'})" class="sub-nav"> {{$t(`평가결과`)}} </b-nav-item>
                <b-nav-item v-if="checkOptions(campaign.campaign_state, campaign.process_options)==true" :active="sub_menu[3]" @click.stop="pageGo({path: '/improvement_monitoring'})" class="sub-nav"> {{$t(`후속 모니터링`)}} </b-nav-item>
            </b-nav>
            <div @click.stop="pageGo({path: '/campaign_list'})" class="d-none d-md-block a-link-gray cursor position-absolute f-140" style="top: calc(50% - 25px); left:calc(10vw - 45px)"><span class="pr-2" style="font-size: 1.8rem;">&lang;</span> {{$t(`목록으로`)}}</div>
        </div>

        <b-container class="text-right position-relative" v-if="sub_menu[2]!=true"> 
            <!-- <audit-print-button v-if="sub_menu[1]==true" :btn_variant="'subtop'">답변 전체 인쇄/다운로드<fa icon="print" class="ml-2"></fa></audit-print-button> -->
            <general-print-button v-if="sub_menu[1]!=true" print_target="campaign-container" :btn_variant="$i18n.locale!='cn'?'subtop-gn':'subtop-gn-cn'" @modal-opened="is_printing=true" @modal-closed="is_printing=false" :d_none="is_printing==true? 'd-none':''"><fa icon="print"></fa></general-print-button>
        </b-container> 
        
        <router-view :value_new="value_new" :target_company_name="target_company_name" :class="[is_printing==true?'px-5':'','min-vh-100']"></router-view>
    </div>
    
    <!-- 기업 및 참여자 관리 모드일 경우 -->
    <div v-else-if="register_mode==true&&operation_mode==false">
        <div class="w-100 bgray-200 text-center pt-3 position-relative"> 
            <h3 class="py-5 fw-400"> COMPANIES & PARTICIPANTS </h3> 
            <b-nav tabs align="center" class="">
                <b-nav-item :active="register_menu[0]" @click.stop="pageGo({path: '/managing_company'})" class="sub-nav"> {{$t(`기업 & 참여자 관리`)}} </b-nav-item>
                <b-nav-item :active="register_menu[1]" @click.stop="pageGo({path: '/portfolio_list'})" class="sub-nav">{{$t(`기업 리스트 관리`)}} </b-nav-item>
            </b-nav>
        </div>
        <router-view></router-view>
    </div>

    <!-- 운영 관리 모드일 경우 -->
    <div v-else>
        <div class="w-100 bgray-200 text-center pt-3 position-relative"> 
            <h3 class="py-5 fw-400"> NOTICE & CUSTOMER SERVICE </h3> 
            <b-nav tabs align="center" class="">
                <b-nav-item :active="operation_menu[0]" @click.stop="pageGo({path: '/notice_manager'})" class="sub-nav"> {{$t(`공지하기`)}} </b-nav-item>
                <b-nav-item :active="operation_menu[1]" @click.stop="pageGo({path: '/cs_manager'})" class="sub-nav"> {{$t(`고객문의 관리`)}} </b-nav-item>
            </b-nav>
        </div>
        <router-view></router-view>
    </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import GeneralPrintButton from '@/components/print_to_pdf/GeneralPrintButton'
import AuditPrintButton from '@/components/print_to_pdf/AuditPrintButton'


export default {
    data() {
        return {
            sub_menu: [true, false, false, false],
            is_printing: false,
            register_mode: false,
            register_menu: [true, false],
            operation_mode: false,
            operation_menu: [true, false]
        }
    },
    props: {
        value_new : { default: false },
        target_company_name: { default: '' },
    },
    computed:{
        ...mapState('user_campaigns_list', {
            campaigns: state => state.campaigns
        }),
        campaign(){
            return this.$store.state.campaign
        },
        is_admin() {
            return this.$store.getters.isAdmin
        },
        isIE() {
            if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (navigator.userAgent.toLowerCase().indexOf("msie") != -1) )
                { return true }
            else
                { return false }
        }        
    },
    methods:{
        pageGo(destination){
            // this.$store.commit('score/update_temp_target_companies', [])
            // this.campaign.scoring_target_companies.forEach(item=>{
            //     this.$store.commit('score/add_temp_target_companies', { company_name: item.company_name, company_id: item.company_id, required_info: item.required_info })
            // })
            this.$router.push(destination)
        },
        checkAllowed(campaign_state, campaign_type, user_admin_level, target_admin_level){
            if(campaign_state == '평가중'||campaign_state == '결과배포' ||campaign_state == '종료') { return true }
            if(user_admin_level >= 4) { return true }
            if(campaign_type=='owner') { return true }
            if(campaign_type=='auditor' && user_admin_level < target_admin_level){
                return false
            }
            else 
            {
                return true
            }
        },
        checkOptions(campaign_state, process_options_array){
            if (process_options_array != null){
                let idx = process_options_array.indexOf('후속모니터링')
                if (idx>-1 && (campaign_state=='결과배포' || campaign_state=='종료')){ return true }
            }
            else {return false}            
        }
    },
    watch:{
        '$route':{
            immediate: true,
            handler(to, from){
                if(to!=from){
                    if (to.name=='CampaignProgress'){
                        this.register_mode = false
                        this.operation_mode = false
                        this.sub_menu = [true, false, false, false]
                    }
                    else if (to.name=='Answers'){
                        this.register_mode = false
                        this.operation_mode = false
                        this.sub_menu = [false, true, false, false]
                    }
                    else if (to.name=='CampaignReport'){
                        this.register_mode = false
                        this.operation_mode = false
                        this.sub_menu = [false, false, true, false]
                    }
                    else if (to.name=='ImprovementMonitoring'){
                        this.register_mode = false
                        this.operation_mode = false
                        this.sub_menu = [false, false, false, true]
                    }
                    // 기업 및 참여자 등록 페이지일 경우
                    else if (to.name=='ManagingCompany'){
                        this.register_mode = true
                        this.operation_mode = false
                        this.register_menu = [true, false]
                    }
                    else if (to.name=='PortfolioList'){
                        this.register_mode = true
                        this.operation_mode = false
                        this.register_menu = [false, true]
                    }
                    // else if (to.name=='EditingPortfolioAttr'){
                    //     this.register_mode = true
                    //     this.operation_mode = false
                    //     this.register_menu = [false, true]
                    // }
                    // 운영관리 페이지일 경우
                    else if (to.name=='NoticeManager'){
                        this.register_mode = false
                        this.operation_mode = true
                        this.operation_menu = [true, false]
                    }
                    else if (to.name=='CsManager'){
                        this.register_mode = false
                        this.operation_mode = true
                        this.operation_menu = [false, true]
                    }
                }
            }
        }
    },
    components:{
        GeneralPrintButton,
        AuditPrintButton
    }

}
</script>

<style>
.sub-nav > .nav-link.active{
    color: var(--gray-800)
}
.sub-nav > .nav-link,
.sub-nav > .nav-link:hover {
    border-color:rgba(0,0,0,0);
}
.sub-nav > a {
    color: var(--gray-600);
}
.sub-nav > a:hover {
    color: var(--gray-800);
}
.arrow-line-up{
    border-top:1px solid #ff00ff; height:100px; margin-left:-140px;
    transform: rotate(45deg); 
}
.arrow-line-down{
    border-top:1px solid #ff00ff; height:100px; margin-left:-140px;
    transform: rotate(135deg); 
}

.arrow-line{
    position: relative;
    height: 100px;
}
.arrow-line::before{
  content: '';
  position: absolute;
  left: 100%;
  z-index:-1;
  width: 28px;
  height: 28px;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  transform: rotate(-45deg) translate(-14px,-7px);
}
</style>