import Vue from 'vue'
import Vuex from 'vuex'
//import axios from 'axios'
import * as th from 'tree-helper'
import * as ih from './util'
import comments from './store/comments'
import questions from './store/provider_questions'
import score from './store/score'
import auditors from './store/auditors'
import notices from './store/notices'
import user_campaigns_list from './store/user_campaigns_list'
import policies from './store/policies'
import reviews from './store/reviews'
import campaign_users from './store/campaign_users'
import postassess from './store/postassess'
import cr_scores from './store/cr_scores'
import sh_saq from './store/sh_saq'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
      comments: comments,
      questions: questions,
      score: score,
      auditors: auditors,
      notices: notices,
      user_campaigns_list: user_campaigns_list,
      policies: policies,
      reviews: reviews,
      campaign_users: campaign_users, 
      postassess: postassess,
      cr_scores: cr_scores,
      sh_saq: sh_saq
    },
    state: {
      //로그인
      accessToken: localStorage.getItem('accessToken') || '',
      user_name: localStorage.getItem('user_name') || '',
      company_name: localStorage.getItem('company_name') || '',
      company_id: localStorage.getItem('company_id') || '',
      user_id: localStorage.getItem('user_id') || '',
      e_mail: localStorage.getItem('e_mail') || '',
      isAuthenticated: '',
      is_admin: localStorage.getItem('isAdmin') || 0,

      //public or login_required
      mode: '',
      response_code: localStorage.getItem('response_code') || null,

      //서버정보
      //frontend_host: 'http://home.francis.kr:8080',
      //backend_host: 'http://home.francis.kr:5000/api',
      frontend_host: 'https://tierzero.kr',
      //backend_host: 'http://backend.dataflare.net/api',
      //backend_host: 'http://dataflare.net:5000/api',
      
      backend_host: process.env.VUE_APP_BACKENDHOST,
      
      tierzero_host: 'https://dataflare.net:5000/tierzero/api',
      //tierzero_host: 'http://localhost:5000/tierzero/api',

      view_mode: 'provider',
      campaigns: [],

      campaign_in_progress: {
        campaign_name: '',
        campaign_owner_id: '',
        campaign_id: '',
        template_code: '',
        campaign_description: '',
        steps: [],
        start_date: '',
        end_date: '',
        campaign_state: '',
        target_company_id: '',
        target_company_name: '',
        process_options: [], //'온라인실사', '현장실사', '후속모니터링'
        operator_name: '',
        is_public: false,
        target_companies_list: [],
        previous_campaign_id: null,
        auditors: []
      },

      template_in_progress: {
        template_name: '',
        template_code: '',
        template_contents: []
      },

      template_in_progress_loaded: false,

      question_in_progress: {
        question_code: '',
        question_name: '',
        question_infobox: '',
        question_contents: [],

        is_completed: 'false',
        visible: 'true',
        writable: 'true',
        comment: ''
      },

      question_in_progress_loaded: false,

      temp_save_status: false,

      login_alert: false,

      service_policy_mode: '이용약관',

      /////////////////////// User /////////////////////////////////////
      //user_aside
        now_page: '',

      //Managing_User
      selected_company: {},

      //Managing_Portfolio
      portfolio: {
        portfolio_id: '',
        portfolio_name: '',
        portfolio_description: '',
        portfolio_target_companies: []
      },

      //Managing_Companies
      campaign: {
        campaign_id: '',
        campaign_name: '',
        campaign_description: '',
        template_code: '',
        steps: [
            {
                step_name: '',
                start_date: '',
                end_date: ''
            }
        ],
        start_date: '',
        end_date: '',
        campaign_state: '',
        campaign_target_companies: [],
        process_options: [], //'온라인실사', '현장실사', '후속모니터링'
        operator_name: '',
        is_public: false,
        previous_campaign_id: null,
        auditors: [],
        type: '', //owner or auditor
        policy_content: ''
      },

      //editing_template
      template_tree: {
        user_id: '',
        template_name: '',
        template_code: '',
        template_scoring: '',
        template_contents: []
      },
      previous_template_tree:{
        user_id: '',
        template_name: '',
        template_code: '',
        template_scoring: '',
        template_contents: []
      },
      question_composition: {
        question_name: '',
        question_code: '',
        question_infobox: '',
        question_contents: [],
      },

      question_subs: [],
      is_question_subs_loaded: false,
      searched_subs: [],
      editing_question_name: false,

    },

    mutations: {
      ///////////////////////////////////Provider////////////////////////////////////////
      change_login_company(state, {company_name, company_id}){ //슈퍼관리자용
        if(state.is_admin < 4){
          return false
        }
        else{
          state.company_name = company_name
          state.company_id = company_id
        }
      },
      set_view_mode(state, view_mode){
        state.view_mode = view_mode
      },
      reset_store(state){
        state.campaigns = []

        state.campaign_in_progress = {
          campaign_name: '',
          campaign_owner_id: '',
          campaign_id: '',
          template_code: '',
          process_options: [],
          campaign_description: '',
          steps: [],
          start_date: '',
          end_date: '',
          campaign_state: '',
          target_company_id: '',
          target_company_name: '',
          operator_name: '',
          is_public: false,
          target_companies_list: [],
          previous_campaign_id: null,
          auditors: []
        }

        state.template_in_progress = {
          template_name: '',
          template_code: '',
          template_contents: []
        }

        state.template_in_progress_loaded = false

        state.question_in_progress = {
          question_code: '',
          question_name: '',
          question_infobox: '',
          question_contents: [],

          is_completed: 'false',
          visible: 'true',
          writable: 'true',
          comment: ''
        },

        state.question_in_progress_loaded = false
        state.temp_save_status = false
        state.login_alert = false
        state.service_policy_mode = ''

        state.now_page = ''
        state.selected_company = {}
        state.portfolio = {
          portfolio_id: '',
          portfolio_name: '',
          portfolio_description: '',
          portfolio_target_companies: []
        }

        state.campaign = {
          campaign_id: '',
          campaign_name: '',
          campaign_description: '',
          template_code: '',
          steps: [
              {
                  step_name: '',
                  start_date: '',
                  end_date: ''
              }
          ],
          start_date: '',
          end_date: '',
          campaign_state: '',
          campaign_target_companies: [],
          operator_name: '',
          is_public: false,
          previous_campaign_id: null,
          auditors: [],
          type: '' //owner or auditor
        }

        //editing_template
        state.template_tree = {
          user_id: '',
          template_name: '',
          template_code: '',
          template_scoring: '',
          template_contents: []
        },
        state.previous_template_tree ={
          user_id: '',
          template_name: '',
          template_code: '',
          template_scoring: '',
          template_contents: []
        }
        state.question_composition = {
          question_name: '',
          question_code: '',
          question_infobox: '',
          question_contents: [],
        }

        state.question_subs = []
        state.is_question_subs_loaded = false
        state.searched_subs = []
        state.editing_question_name = false
      },
      update_login_alert(state, flag){
        state.login_alert = flag
      },
      update_service_policy_mode(state, mode){
        state.service_policy_mode = mode
      },
      update_campaign_in_progress(state, new_campaign){
        state.campaign_in_progress = new_campaign
        state.template_in_progress.template_code = new_campaign.template_code
      },
      update_campaigns(state, new_campaigns){
        state.campaigns = new_campaigns
      },
      update_template_in_progress(state, new_template){
        state.template_in_progress = new_template
      },
      make_template_tree(state, question_array){
        th.breadthFirstSearch(state.template_in_progress.template_contents, node => {
          let idx = question_array.findIndex( item => { return item.question_code == node.question_code })
          if(idx >-1){
            node.question_name = question_array[idx].question_name
            node.question_name_e = question_array[idx].question_name_e
            node.question_name_c = question_array[idx].question_name_c
            node.question_infobox = question_array[idx].question_infobox
            node.question_infobox_e = question_array[idx].question_infobox_e
            node.question_infobox_c = question_array[idx].question_infobox_c
            node.question_contents = question_array[idx].question_contents
            node.visible = question_array[idx].visible
            node.writable = question_array[idx].writable
            node.number_of_comment = question_array[idx].number_of_comment
            node.number_of_new_comment = question_array[idx].number_of_new_comment
            node.is_completed = question_array[idx].is_completed
            node.comment = question_array[idx].comment

            let x = node
            while((x.visible == true || x.writable == true) && x.parent){
              x.parent.visible = x.visible
              x.parent.writable = x.writable
              x = x.parent
            }

          }
        })

        if(state.is_admin >=1 ){ //관리자 이상일 경우이며, 이 경우 visible, writable 무시
          th.breadthFirstSearch(state.template_in_progress.template_contents, node => {
            node.visible = true,
            node.writable = true
          })
        }
        state.template_in_progress_loaded = true
      },
      update_question_in_progress(state, new_question_in_progress){
        state.question_in_progress = new_question_in_progress

        let temp_obj = { ...state.template_in_progress }
        th.breadthFirstSearch(temp_obj.template_contents, node => {
          if(node.question_code == new_question_in_progress.question_code) {
            node.is_completed = new_question_in_progress.is_completed
            node.comment = new_question_in_progress.comment
          }
        })
        state.template_in_progress = temp_obj

      },
      update_template_in_progress_loaded(state, { loading_status }){
        state.template_in_progress_loaded = loading_status
      },
      update_question_in_progress_loaded(state, {loading_status }){
        state.question_in_progress_loaded = loading_status
      },

      update_temp_save_status(state, flag){
        state.temp_save_status = flag
      },
      ////////////////////////////////////Public_Engagement//////////////////////////////
      make_mode_public(state, response_code){
        state.mode = 'public'
        if(response_code != null){
          state.response_code = response_code
          localStorage.setItem('response_code', state.response_code)
        }
        if(state.response_code == null){
          state.response_code = 'R'+ Math.floor((Math.random() * 999999))+1
          localStorage.setItem('response_code', state.response_code)
        }
      },
      make_mode_public_single(state){
        state.mode = 'public'
      },
      ////////////////////////////////////User///////////////////////////////////////////
      make_template_tree_for_user_view(state, question_array){
        th.breadthFirstSearch(state.template_tree.template_contents, node => {
          let idx = question_array.findIndex( item => { return item.question_code == node.question_code })
          if(idx >-1){
            node.question_name = question_array[idx].question_name
            node.question_name_e = question_array[idx].question_name_e
            node.question_name_c = question_array[idx].question_name_c
            node.question_infobox = question_array[idx].question_infobox
            node.question_infobox_e = question_array[idx].question_infobox_e
            node.question_infobox_c = question_array[idx].question_infobox_c

            if(typeof(question_array[idx].question_contents)=='string'){
              node.question_contents = JSON.parse(question_array[idx].question_contents)
            }
            else{
              node.question_contents = question_array[idx].question_contents
            }

            node.visible = question_array[idx].visible
            node.writable = question_array[idx].writable
            node.num_of_comment = question_array[idx].num_of_comment
            node.number_of_new_comment = question_array[idx].number_of_new_comment 
            node.is_completed = question_array[idx].is_completed
            node.comment = question_array[idx].comment

            //let x = node
            //while((x.visible == true || x.writable == true) && x.parent){
            //  x.parent.visible = x.visible
            //  x.parent.writable = x.writable
            //  x = x.parent
            }

          }
        )
        //if(state.is_admin >=1 ){ //관리자 이상일 경우이며, 이 경우 visible, writable 무시
          th.breadthFirstSearch(state.template_in_progress.template_contents, node => {
            node.visible = true,
            node.writable = true
          })
        //}
        //state.template_in_progress_loaded = true
      },
      update_now_page(state, new_page){
        state.now_page = new_page
      },
      update_template_tree(state, template_tree) {
        state.template_tree = template_tree
        state.question_subs = []
        state.question_composition = {
          question_name: '',
          question_name_e: '',
          question_name_c: '',
          question_code: '',
          question_infobox: '',
          question_infobox_e: '',
          question_infobox_c: '',
          question_contents: [],
        }
      },
      update_previous_template_tree(state, template_tree){
        state.previous_template_tree = template_tree
      },
      update_question_code(state, question_code){
        state.question_composition.question_code = question_code
      },
      update_question_name(state, {'question_name': question_name }){
        state.question_composition.question_name = question_name
      },
      update_question_composition (state, { 'question_code': question_code, 'question_name': question_name, 'question_infobox' : question_infobox, 'question_contents': question_contents, 'question_name_e': question_name_e, 'question_infobox_e': question_infobox_e, 'question_name_c': question_name_c, 'question_infobox_c': question_infobox_c }) {
        state.question_composition.question_code = question_code
        state.question_composition.question_name = question_name
        state.question_composition.question_name_e = question_name_e
        state.question_composition.question_name_c = question_name_c
        state.question_composition.question_infobox = question_infobox
        state.question_composition.question_infobox_e = question_infobox_e
        state.question_composition.question_infobox_c = question_infobox_c
        try {
          state.question_composition.question_contents = ih.JSONParse(question_contents)
        } catch {
          state.question_composition.question_contents = question_contents
        }
        //console.log('question composition 이렇게 업데이트 했습니다')
        // console.log(state.question_composition)
      },
      align_question_contents (state){
        state.question_composition.question_contents = state.question_subs.map(x => x.sub_code);
      },
      read_question_composition_failure (state) {
        state.question_composition.question_contents = []
        state.question_subs = []
      },
      reset_question_subs (state) {
        state.question_subs = []
      },
      update_question_subs (state, new_subs) {
        state.question_subs = new_subs
        state.question_composition.question_contents = state.question_subs.map(x => x.sub_code)
      },
      add_question_sub (state, new_sub) {
        state.question_subs.push(new_sub)
        state.question_composition.question_contents = state.question_subs.map(x => x.sub_code);
      },
      update_is_question_subs_loaded(state, new_state){
        state.is_question_subs_loaded = new_state
      },
      delete_question_sub (state, sub_code) {
        const idx = state.question_subs.findIndex(function(item) {return item.sub_code === sub_code})
        if(idx > -1) {
          state.question_subs.splice(idx,1)
          state.question_composition.question_contents = state.question_subs.map(x => x.sub_code);
        }
      },
      update_question_sub (state, new_sub) {
        const idx = state.question_subs.findIndex(function(item) {return item.sub_code === new_sub.sub_code})

        if (idx > -1) {
          if (!Array.isArray(new_sub.tags)) {
              if (new_sub.tags === '') {
                new_sub.tags = []
              } else {
                if (new_sub.tags.includes(',')) {
                  new_sub.tags.split(',')
                } else {
                  new_sub.tags.split(' ')
                }
              }
            }
            state.question_subs.splice(idx, 1, new_sub)
            state.question_composition.question_contents = state.question_subs.map(x => x.sub_code)
        }
      },
      reset_searched_subs (state) {
        state.searched_subs = []
      },
      add_searched_sub(state,new_sub){
        state.searched_subs.push(new_sub)
      },
      update_searched_subs (state, new_subs) {
        //for (var i=0; i<new_subs.length; i++)
        //{
        //    new_subs[i].sub_condition = ih.JSONParse(new_subs[i].sub_condition)
        //    new_subs[i].sub_data_header = ih.JSONParse(new_subs[i].sub_data_header)
        //    new_subs[i].sub_data_contents = ih.JSONParse(new_subs[i].sub_data_contents)
        //}
        state.searched_subs = new_subs
      },
      delete_searched_sub(state, sub_code){
        const idx = state.searched_subs.findIndex(function(item) {return item.sub_code === sub_code})
        if(idx > -1) {
          state.searched_subs.splice(idx,1)
        }
      },
      update_selected_company(state, new_company) {
        state.selected_company = new_company
      },
      add_portfolio_target_company(state, new_company) {
        if (new_company.company_id != '' && state.portfolio.portfolio_target_companies.length>-1) {
            let idx = state.portfolio.portfolio_target_companies.findIndex((item) => {return item.company_id == new_company.company_id})
            // console.log(idx)
            if( idx > -1) { console.log('이미 목록에 존재하는 기업입니다.')}
            else state.portfolio.portfolio_target_companies.push(new_company)
        }
        else {
            state.portfolio.portfolio_target_companies.push(new_company)
            // window.alert('목록에 추가할 기업을 선택하여 주십시오')
            console.log(new_company);
            console.log(state.portfolio)
        }

      },
      delete_portfolio_target_company(state, deletable_company) {
        const idx = state.portfolio.portfolio_target_companies.findIndex(function(item) { return item.company_id == deletable_company.company_id})
        if(idx > -1) state.portfolio.portfolio_target_companies.splice(idx, 1)
        else window.alert('삭제할 기업이 존재하지 않습니다.')
      },
      reset_portfolio_target_companies(state){
        state.portfolio_target_companies = []
      },
      update_portfolio(state, new_portfolio){
        state.portfolio = new_portfolio
      },
      update_campaign(state, new_campaign){
        state.campaign = new_campaign
      },
      logout (state) {
        state.accessToken = null
        state.isAuthenticated = ''
        state.user_name = ''
        state.company_name = ''
        state.e_mail = ''
        delete localStorage.accessToken
        delete localStorage.user_name
        delete localStorage.company_name
        delete localStorage.e_mail
        delete localStorage.isAuthenticated
        delete localStorage.isAdmin
        axios.defaults.headers.common['Authorization'] = '';
        state = {}
      },
      auth_request(state) {
          state.isAuthenticated = 'loading'
          localStorage.setItem('isAuthenticated', 'loading')
      },
      auth_success(state, { 'accessToken' : accessToken, 'user_name': user_name, 'company_name' : company_name, 'company_id': company_id, 'e_mail':e_mail, 'is_admin': is_admin, 'user_id': user_id }) {
          state.isAuthenticated = 'success'
          localStorage.setItem('isAuthenticated', 'success')

          state.accessToken = accessToken
          state.user_name = user_name
          state.company_name = company_name
          state.company_id = company_id
          state.e_mail = e_mail
          state.is_admin = is_admin
          state.user_id = user_id

          localStorage.setItem('accessToken', accessToken)
          localStorage.setItem('user_name',user_name)
          localStorage.setItem('company_name', company_name)
          localStorage.setItem('company_id',company_id)
          localStorage.setItem('e_mail',e_mail)
          localStorage.setItem('isAdmin', is_admin)
          localStorage.setItem('user_id', user_id)

          axios.defaults.headers.common['Authorization']= `Bearer ${accessToken}`;
      },
      auth_error(state){
          state.isAuthenticated = 'error'
          localStorage.setItem('isAuthenticated', 'error')
      },

    },
    getters: {
      getNumberOfQuestions(state){
        var number_of_questions = 0
        th.breadthFirstSearch(state.template_in_progress.template_contents, node => {
          if(node.children.length==0) { number_of_questions += 1 }
        })

        return number_of_questions
      },
      getNumberOfCompletedQuestions(state){
        var number_of_completed_questions = 0
        th.breadthFirstSearch(state.template_in_progress.template_contents, node => {
          if(node.hasOwnProperty('is_completed')) {
            if(node.is_completed == 'true')
              { number_of_completed_questions += 1 }
          }
        })
        return number_of_completed_questions
      },
      getNumberOfCategoryQuestions(state){
        //카테고리별로 하위 카테고리에 있는 계산 가능한 질문들의 총 수를 계산하여 Object로 리턴
        // { '0. 기업정보': 1, '1. 경제': 3, '2. 환경: 2', ... }
        // root_node: state.template_in_progress.template_contents
        let categories = state.template_in_progress.template_contents
        let return_object = {}
        categories.forEach( item => {
          item.number_of_questions = 0
          th.breadthFirstSearch(item, node => {
            if(node.children.length == 0) { item.number_of_questions += 1 }
          })
          return_object[item.question_name] = item.number_of_questions
        })
        return return_object
      },
      getNumberOfCompletedCategoryQuestions(state){
        //카테고리별로 하위 카테고리에 있는 완료된 질문들의 총 수를 계산하여 Object로 리턴
        // { '0. 기업정보': 1, '1. 경제': 0, '2. 환경: 1' ...}
        let categories = state.template_in_progress.template_contents
        let return_object = {}
        categories.forEach( item => {
          item.number_of_completed_questions = 0
          th.breadthFirstSearch(item, node => {
            if(node.hasOwnProperty('is_completed') && node.is_completed == 'true'){
              item.number_of_completed_questions += 1
            }
          })
          return_object[item.question_name] = item.number_of_completed_questions
        })
        return return_object
      },
      getProgressRate(state, getters) {
        let number_of_questions = getters.getNumberOfQuestions
        let number_of_completed_questions = getters.getNumberOfCompletedQuestions

        if(number_of_questions != 0) { return Math.round( number_of_completed_questions / number_of_questions * 100)  }
        else return 0
      },
      getCategoryProgressRate(state, getters){
        // 카테고리별로 완료율을 계산하여 [ ] array로 반환
        // [ { category: '0. 기업정보', progress_rate: 10 }, { category: '1.경제', prgoress_rate: 15}, ... ]
        let number_of_category_questions = getters.getNumberOfCategoryQuestions
        let number_of_completed_category_questions = getters.getNumberOfCompletedCategoryQuestions

        let return_array = []

        let keys=Object.keys(number_of_category_questions)

        keys.forEach(item => {
          let category_name = item
          let denominator = number_of_category_questions[item]
          let value = number_of_completed_category_questions[item]
          return_array.push({
            category: category_name,
            progress_rate: Math.round(value / denominator * 100)
          })
        })
        return return_array
      },
      getTemplateTree: function(state){
        return state.template_tree
      },
      getTemplateContents: function(state){
        // console.log('template_contents')
        // console.log(state.template_tree.template_contents)
        if (state.template_tree.template_contents == '' || state.template_tree.template_contents.length == 0) return []
        else if(state.template_tree.template_contents[0]._vm == undefined) return state.template_tree.template_contents
        else return state.template_tree.template_contents[0]._vm.store.getPureData()
      },
      getQuestionComposition: function(state) {
        return state.question_composition
      },
      getSearchedSubs: function(state){
        return state.searched_subs
      },
      isEmptySearchedSubs: function(state){
         if (state.searched_subs.length == 0) return true
         else return false
      },
      getQuestionSubs: function(state){
        return state.question_subs
      },
      getQuestionSubBySubcode: function(state, sub_code){
        const idx = state.question_subs.findIndex( item => {return item.sub_code === sub_code} )
        return state.question_subs[idx]
      },
      isAuthenticated: function(state) {
        return state.isAuthenticated
      },
      isAdmin: function(state){
        return state.is_admin
      },
      getAdminLevel: function(state){
        //유저의 권한 레벨을 정의
        // 0: CAMPAIGN_USER 캠페인에 참여하는 유저(현업부서)
        // 1: CAMPAIGN_ADMIN 기업 COMPANY_USER를 추가하고, Question별 읽기/편집 권한 부여 가능
        // 2: CAMPAIGN_AUDITOR 캠페인 실사/평가에 참여. 접근이 허용된 캠페인의 정보를 확인하고, 점수를 매길 수 있음
        // 3: CAMPAIGN_MANAGER. 캠페인/포트폴리오/템플릿 생성/Auditor 계정생성, 캠페인/포트폴리오/템플릿 매핑 가능.
        //    CAMPAIGN_MANAGER는 자신이 생성한 CAMPAIGN에 한해서 모든 권한 사용 가능
        // 4: SUPER_ADMINISTRATOR. 데이터플레어 캠페인/포트폴리오/유저 생성/삭제/매핑 차원에서 모든 권한을 가짐
        if(state.is_admin == 0) return 'CAMPAIGN_USER' // 참여자
        else if(state.is_admin == 1) return 'CAMPAIGN_ADMIN' // 캠페인 리더
        else if(state.is_admin == 2) return 'CAMPAIGN_AUDITOR' // 실사 전문가
        else if(state.is_admin == 3) return 'CAMPAIGN_MANAGER' // 캠페인 매니저
        else if(state.is_admin == 4) return 'SUPER_ADMINISTRATOR' // 시스템 관리자
        else if(state.is_admin == 5) return 'MASTER'
        else return 'NOT_DEFINED'
      },
      getUserName: function(state) {
        return state.user_name
      },
      getCompanyName: function(state) {
        return state.company_name
      },
      getEMail: function(state) {
        return state.e_mail
      },
      getAccessToken: function(state) {
        return state.accessToken
      },
      getQuestionInfoBox: function(state){
        return state.question_composition.question_infobox
      },
      isLoggedIn: state => !!state.accessToken,
      

    },
    actions: {
        /////////////////////////// Provider ////////////////////////////////
        read_recieved_campaigns({ dispatch, state, commit }, user_id){
          commit('update_campaigns', [])
          const path = state.backend_host + '/read_received_campaigns'

          return axios.get(path, {
                params: {
                    user_id: user_id,
                }
          })
          .then(response => {
              let campaigns = response.data.campaigns
              campaigns.forEach( item => {
                  item.steps.forEach( x => {
                      x.start_date = ih.stringToDate(x.start_date)
                  })
                  item.steps.forEach( y => {
                      y.end_date = ih.stringToDate(y.end_date)
                      let zerodate  = y.end_date
                      zerodate.setHours(zerodate.getHours()+23)
                      zerodate.setMinutes(zerodate.getMinutes()+59)
                  })
                  item.start_date = ih.stringToDate(item.start_date)
                  item.end_date = ih.stringToDate(item.end_date)
                      let end_zero = item.end_date
                      end_zero.setHours(end_zero.getHours()+23)
                      end_zero.setMinutes(end_zero.getMinutes()+59)
              })
            //   console.log(campaigns);

              if (state.campaigns.length==0){
                  commit('update_campaigns', campaigns)
              }
          })
          .catch(error => {
              console.log(error)
              return error
          })
        },
        readTemplateInProgress({commit, state}, template_code ){

          commit('update_template_in_progress_loaded', { loading_status: false })
          const path = state.backend_host + '/read_template'
          const new_template_tree = {
            template_name: '',
            template_code: '',
            template_scoring: '',
            template_contents: []
          }
          return axios.get(path, {
                          params: {
                              template_code: template_code
                          }
          })
          .then(response => {
              this.result = response.data
              new_template_tree.template_name = this.result.template_name
              new_template_tree.template_code = this.result.template_code
              new_template_tree.template_scoring = this.result.template_scoring
              new_template_tree.template_contents = ih.JSONParse(this.result['template_contents'])

              commit('update_template_in_progress',new_template_tree)
              //console.log(state.template_in_progress)

              if(state.mode != 'public') {
                this.dispatch('makeTemplateTree')
              }
              else {
                this.dispatch('makePublicTemplateTree')
              }
          })
          .catch(error => {
              if (error.response && error.response.status == '401')
                  { alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                  this.dispatch('logout').then(() => { this.$router.push({name:'Login'}) })
              }
              else
              {
                console.log(error)
              }
          })

        },
        makeTemplateTree({commit, state}) {
          const path = state.backend_host + '/make_template_tree'

          return axios.get(path, { params: {
            campaign_id: state.campaign_in_progress.campaign_id,
            e_mail: state.e_mail
          }})
          .then(response => {
            commit('make_template_tree', response.data)
          })
          .catch( error => {
            console.log(error)
          })
        },
        makeTemplateTreeByCompanyId({commit, state}, company_id) {
          const path = state.backend_host + '/make_template_tree_by_company_id'

          return axios.get(path, { params: {
            campaign_id: state.campaign.campaign_id,
            company_id: company_id
          }})
          .then(response => {
            commit('make_template_tree_for_user_view', response.data)
            //console.log('데이터 찍습니다')
            //console.log(response.data)
          })
          .catch( error => {
            console.log(error)
          })
        },
        readQuestionInProgress({commit, state}, question_code){
          const path = state.backend_host + '/read_question_answer'

          let new_question_in_progress = {
            question_code: question_code,
            is_completed: '',
            comment: '',

            question_name: '',
            question_name_e: '',
            question_name_c: '',
            question_infobox: '',
            question_infobox_e: '',
            question_infobox_c: '',
            question_contents: []
          }

          commit('update_question_in_progress_loaded', { loading_status: false })

          return axios.get(path, {
            params: {
              question_code: question_code,
              company_id: state.company_id,
              campaign_id: state.campaign_in_progress.campaign_id
            }
          })
          .then( response => {
              new_question_in_progress.is_completed = response.data.is_completed
              new_question_in_progress.comment = response.data.comment
              new_question_in_progress.question_name = response.data.question_name
              new_question_in_progress.question_name_e = response.data.question_name_e
              new_question_in_progress.question_name_c = response.data.question_name_c
              new_question_in_progress.question_infobox = response.data.question_infobox
              new_question_in_progress.question_infobox_e = response.data.question_infobox_e
              new_question_in_progress.question_infobox_c = response.data.question_infobox_c
              new_question_in_progress.question_contents = JSON.parse(response.data.question_contents)
              commit('update_question_in_progress', new_question_in_progress)
              commit('update_question_in_progress_loaded', {loading_status: true })
          })
          .catch(error => {
              console.log(error)
        })
        },
        saveQuestionInProgress({dispatch, commit, state}, new_question_in_progress ) {
          const path = state.backend_host + '/save_question_answer'

          const formData = new FormData()
          formData.append('question_code', new_question_in_progress.question_code )
          formData.append('campaign_id', state.campaign_in_progress.campaign_id )
          formData.append('company_id', state.company_id)
          formData.append('is_completed', new_question_in_progress.is_completed)
          formData.append('comment', new_question_in_progress.comment)

          return axios.post(path, formData)
          .then( response => {
            //console.log(response.data)
            //commit('update_question_in_progress', new_question_in_progress)
            //화면 갱신
            let temp_template_in_progress = { ...state.template_in_progress }
            th.breadthFirstSearch(temp_template_in_progress.template_contents, node => {
              if(node.question_code == new_question_in_progress.question_code ){
                node.is_completed = new_question_in_progress.is_completed
                node.comment = new_question_in_progress.comment
              }
            })
            commit('update_template_in_progress', temp_template_in_progress)

          })
          .catch(error => {
            console.log('axios 실패')
            console.log(error)
            if (error.response.status == '401')
                { alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                 dispatch('logout').then(() => { this.$router.push({name:'Login'}) })
            }
          })
        },
    ///////////////////////// Public_Engagement /////////////////////////
      readPublicCampaign({commit, state}, campaign_id) {
        const path = state.backend_host + '/read_public_campaign'

        return axios.get(path, { params: {
                                  campaign_id: campaign_id
                                }
        })
        .then( response => {
            // console.log(response.data);
            if(response.data.is_public != true){
              window.alert('캠페인 접근 권한이 없습니다(참여제한 캠페인)')
              history.go('http://tierzero.kr')
            }
            commit('update_campaign_in_progress', response.data)
            this.dispatch('readTemplateInProgress',response.data.template_code)
        })
        .catch(error => {
            console.log(error)
        })

      },
      makePublicTemplateTree({commit,state}) {
        const path = state.backend_host + '/make_public_template_tree'

        return axios.get(path, { params: {
          campaign_id: state.campaign_in_progress.campaign_id,
        }})
        .then(response => {
          commit('make_template_tree', response.data)
        })
        .catch( error => {
          console.log(error)
        })
      },
    /////////////////////////// Users ///////////////////////////////////
      readTemplateTree({commit, state, dispatch}, {template_code, is_previous}) {
        const path = state.backend_host + '/read_template'
        const new_template_tree = {
          user_id: '',
          template_name: '',
          template_code: '',
          template_scoring: '',
          template_contents: []
        }
        return axios.get(path, {
                        params: {
                            template_code: template_code
                        }
        })
        .then(response => {
            this.result = response.data
            new_template_tree.user_id = this.result.user_id
            new_template_tree.template_name = this.result.template_name
            new_template_tree.template_code = this.result.template_code
            new_template_tree.template_scoring = this.result.template_scoring
            new_template_tree.template_contents = ih.JSONParse(this.result['template_contents'])
            ih.completeTree(new_template_tree.template_contents)
            //console.log(this.result);


            if(is_previous!=true){
              commit('update_template_tree', new_template_tree)
            } else commit('update_previous_template_tree', new_template_tree)

        })
        .catch(error => {
            console.log(error)

            if (error.response.status == '401')
                { alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                 dispatch('logout').then(() => { this.$router.push({name:'Login'}) })
            }
            else if(error.response.status == '404')
            {
              console.log('404: 존재하는 템플릿 없음')
            }
        })
      },
      saveTemplateTree({commit, state, getters, dispatch}){
        const path = this.state.backend_host + '/save_template'

        // console.log(getters.getTemplateContents)
        const formData = new FormData()
        formData.append('user_id', state.template_tree.user_id)
        formData.append('template_name', state.template_tree.template_name)
        formData.append('template_code', state.template_tree.template_code)
        formData.append('template_scoring', 'manual')
        formData.append('template_contents', JSON.stringify(getters.getTemplateContents))

        return axios.post(path, formData)
        .then(response => {
            console.log(response)
        })
        .catch(error => {
            if (error.response.status == '401')
            {
                alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                dispatch('logout').then(() => { this.$router.push({name:'Login'})} )
            }
        })
      },
      async saveQuestionComposition({commit, state, dispatch}){
        try {
          const path = this.state.backend_host + '/save_question'

          commit('align_question_contents')
          const formData = new FormData()

          formData.append('question_name', state.question_composition.question_name)
          formData.append('question_code', state.question_composition.question_code)
          formData.append('question_infobox', state.question_composition.question_infobox)
          formData.append('question_contents', JSON.stringify(state.question_composition.question_contents))
          formData.append('template_code', state.template_tree.template_code)
          
          await axios.post(path, formData)
        } catch (error) {
          if (error.response) {
            if (error.response.status == '401') {
              alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
              await dispatch('logout')
              this.$router.push({name: 'Login'})
            } 
          }
        }
      },
      readQuestionComposition({commit, state, dispatch}, question_code){
        const path = state.backend_host + '/read_question'

        const new_question_composition = {
          question_name:'',
          question_name_e: '',
          question_name_c: '',
          question_code: '',
          question_infobox: '',
          question_infobox_e: '',
          question_infobox_c: '',
          question_contents: [],
          template_code: ''
        }
        return axios.get(path, {
                        params: {
                            question_code: question_code
                        }
        })
        .then(response => {
            //   console.log(response.data)
              new_question_composition.question_name = response.data.question_name
              new_question_composition.question_name_e = response.data.question_name_e
              new_question_composition.question_name_c = response.data.question_name_c
              new_question_composition.question_code = response.data.question_code
              new_question_composition.question_infobox = response.data.question_infobox
              new_question_composition.question_infobox_e = response.data.question_infobx_e
              new_question_composition.question_infobox_c = response.data.question_infobx_c
              new_question_composition.question_contents = response.data.question_contents
              new_question_composition.template_code = response.data.template_code

              commit('update_question_composition', new_question_composition)
        })
        .catch(error => {
            if (error.response.status == '404')
            {
                this.commit('read_question_composition_failure')
            }
            else if (error.response.status == '401')
                { alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                 dispatch('logout').then(() => { this.$router.push({name:'Login'}) })
            }
            console.log(error.response)
        })
      },
      deleteQuestionComposition({commit, state}, question_code ){
        const path = state.backend_host + '/delete_question'

        const formData = new FormData()
        formData.append('question_code', question_code)

        return axios.post(path,formData)
        .then( response => {
          //console.log(response)
        })
      },
      readQuestionSubs ({commit, state, dispatch}){
        commit('reset_question_subs')
        commit('update_is_question_subs_loaded', false)
        var promises = []
        const path = this.state.backend_host + '/read_sub_init'

        state.question_composition.question_contents.forEach(
          function (sub_code) {
            promises.push(
              axios.get(path, { params: {sub_code: sub_code}})
              .catch( error => {
                console.log(error)
              })
            )
          }
        )

        let new_subs = []
        return Promise.all(promises)
          .then( results => {
            //console.log(results)
            results.forEach(
              response => {
                if (response != undefined){
                  let result = response.data
                  let new_sub = {}
                  new_sub.sub_code = result.sub_code
                  new_sub.sub_name = result.sub_name
                  new_sub.sub_name_e = result.sub_name_e
                  new_sub.sub_name_c = result.sub_name_c
                  new_sub.sub_description = result.sub_description
                  new_sub.sub_description_e = result.sub_description_e
                  new_sub.sub_description_c = result.sub_description_c
                  new_sub.sub_info = result.sub_info
                  new_sub.sub_info_e = result.sub_info_e
                  new_sub.sub_info_c = result.sub_info_c
                  new_sub.sub_condition = result.sub_condition
                  new_sub.sub_type = result.sub_type
                  new_sub.sub_data_header = result.sub_data_header
                  new_sub.sub_data_contents = result.sub_data_contents
                  new_sub.sub_priority = result.sub_priority
                  new_sub.sub_child = result.sub_child
                  new_sub.sub_parent = result.sub_parent
                  new_sub.tags = result.tags
                  new_subs.push(new_sub)
                }
            })
          })
          .finally( () => {
            commit('update_question_subs', new_subs)
            commit('update_is_question_subs_loaded', true)
            //console.log(state.question_subs)
          })
      },
      async saveQuestionSubs ({commit, state, dispatch}) {
          for (var i = 0; i < state.question_subs.length; i++) {
            await dispatch('saveQuestionSub', state.question_subs[i])
          }
      },
      async saveQuestionSub ({commit, state, dispatch}, new_sub) {
        try {
          const path = this.state.backend_host + '/save_sub_init'
          const formData = new FormData()

          formData.append('sub_code', new_sub.sub_code)
          formData.append('sub_name', new_sub.sub_name)
          formData.append('sub_description', new_sub.sub_description)
          formData.append('sub_condition', JSON.stringify(new_sub.sub_condition))
          formData.append('sub_info', new_sub.sub_info)
          formData.append('sub_type', new_sub.sub_type)
          formData.append('sub_data_header', JSON.stringify(new_sub.sub_data_header))
          formData.append('sub_data_contents', JSON.stringify(new_sub.sub_data_contents))
          formData.append('sub_priority', new_sub.sub_priority)
          formData.append('sub_child', JSON.stringify(new_sub.sub_child))
          formData.append('sub_parent', new_sub.sub_parent)
          formData.append('question_code', state.question_composition.question_code)
          formData.append('tags', new_sub.tags)

          const response = await axios.post(path, formData)
          commit('update_question_sub', new_sub)
        } catch (error) {
          if (error.response) {
            if (error.response.status == '401') {
              alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
              await dispatch('logout')
              this.$router.push({name: 'Login'})
            } else {
              console.log(error.response)
            }  
          }
        }
      },
      readSearchedSubs({commit, state, dispatch}, search_keyword){
        commit('reset_searched_subs')

        const path = state.backend_host + '/search_subs'
        return axios.get(path, {
            params: {
                search_keyword: search_keyword
            }
        })
        .then(response => {
            const result = response.data
            // console.log(result.searched_subs);
            commit('update_searched_subs', result.searched_subs)
            //console.log('debug: searched_sub 리절트')
            //console.log(state.searched_subs)
        })
        .catch(error => {
            if (error_code == '401')
                { alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                 dispatch('logout').then(() => { this.$router.push({name:'Login'}) })
            }
            else {
                console.log(error)
            }
        })
      },
      async deleteSubfromDB({commit, state}, sub_code) {
        try {
          const path = state.backend_host + '/delete_sub_init'
            const response = await axios.get(path, {
                params: { sub_code }
            })
            
            commit('delete_question_sub', sub_code)
            commit('delete_searched_sub', sub_code)
        } catch (error) {
          console.log(error) 
        }
      },
        savePortfolio({commit,state, dispatch}, new_portfolio){
            const path = this.state.backend_host + '/save_portfolio'
            let portfolio_target_companies_id = []

            for(var i=0; i<new_portfolio.portfolio_target_companies.length; i++) {
            portfolio_target_companies_id.push(new_portfolio.portfolio_target_companies[i].company_id)
            }

            const formData = new FormData()
            formData.append('e_mail', state.e_mail)
            formData.append('portfolio_id', new_portfolio.portfolio_id)
            formData.append('portfolio_name', new_portfolio.portfolio_name)
            formData.append('portfolio_description', new_portfolio.portfolio_description)
            formData.append('portfolio_target_companies_id', JSON.stringify(portfolio_target_companies_id))

            return axios.post(path, formData)
            .then(response => {
                // console.log(response)
                commit('update_portfolio', new_portfolio)
                //window.alert('포트폴리오 상세내용을 저장하였습니다.')
            })
            .catch(error => {
                if (error.response.status == '401')
                {
                    alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                    dispatch('logout').then(() => { this.$router.push({name: 'Login'}) } )
                }
                else console.log(error.response)
            })
        },
        saveCampaign({commit, state, dispatch }, new_campaign){
            const path = this.state.backend_host + '/save_campaign'
            let campaign_target_companies_id = []

            for(var i=0; i<new_campaign.campaign_target_companies.length; i++) {
            campaign_target_companies_id.push(new_campaign.campaign_target_companies[i].company_id)
            }

            const formData = new FormData()
            formData.append('user_id', new_campaign.user_id)
            formData.append('campaign_id', new_campaign.campaign_id)
            formData.append('campaign_name', new_campaign.campaign_name)
            formData.append('campaign_description', new_campaign.campaign_description)
            formData.append('template_code', new_campaign.template_code)
            formData.append('steps', JSON.stringify(new_campaign.steps) )
            formData.append('start_date', new_campaign.start_date)
            formData.append('end_date', new_campaign.end_date)
            formData.append('campaign_state', new_campaign.campaign_state)
            formData.append('campaign_target_companies_id', JSON.stringify(campaign_target_companies_id))
            formData.append('process_options', JSON.stringify(new_campaign.process_options))
            formData.append('operator_name', new_campaign.operator_name)
            formData.append('is_public', new_campaign.is_public),
            formData.append('required_info', JSON.stringify(new_campaign.required_info))
            formData.append('previous_campaign_id', new_campaign.previous_campaign_id)
            formData.append('auditors', JSON.stringify(new_campaign.auditors) )
            formData.append('policy_content', new_campaign.policy_content)

            // console.log(new_campaign)

            return axios.post(path, formData)
            .then(response => {
                return response.data
                // console.log(response.data);
                //window.alert('포트폴리오 상세내용을 저장하였습니다.')
            })
            .catch(error => {
                if (error.response.status == '401')
                {
                    alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                    dispatch('logout').then(() => { this.$router.push({name: 'Login'}) } )
                }
                else console.log(error.response)
            })
        },
        login({commit, dispatch}, user){
            return axios.post(this.state.backend_host+'/login', { params: { user: user }}
            )
            .then( response => {
                let accessToken = response.data['accessToken']
                let user_name = response.data['user_name']
                let company_name = response.data['company_name']
                let company_id = response.data['company_id']
                let e_mail = response.data['e_mail']
                let is_admin = response.data['is_admin']
                let user_id = response.data['user_id']
                commit('auth_success', { accessToken, user_name, company_name, company_id, e_mail, is_admin, user_id })
            })
            .catch(error => {
                console.log(error)
                dispatch('logout')
                .then(() => { this.$router.push({name: 'Login'}) })
            })
        },
        logout({commit}) {
            return axios.post(this.state.backend_host+'/logout')
            .then(response => {
            commit('logout')
            commit('reset_store')
            }).catch(error => {
            console.log(error)
            })
        },
    }
  })

export default store
