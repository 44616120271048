<template>
<b-container class="px-0">
        <b-input-group>
            <b-form-input placeholder="검색어를 입력해 주세요" @keyup.enter="searchSubs(search_keyword)" v-model="search_keyword"></b-form-input>
            <b-input-group-append>
                <b-button class="py-1 px-2" variant="dark-border" @click="searchSubs(search_keyword)"><fa icon="search"></fa></b-button>
            </b-input-group-append>
        </b-input-group>

        <div v-if="is_empty_searched_subs==true" class="mt-2 ml-2 f-90"> {{ message }} </div>
        <div v-else class="mt-2">
            <draggable :list="searched_subs" group="subs">
                <editing-searched-sub-component></editing-searched-sub-component>
                <!-- <editing-searched-sub-component v-for="sub in searched_subs" :key="sub.index" :sub_code="sub.sub_code"></editing-searched-sub-component> -->
            </draggable>
        </div>
</b-container>
</template>

<script>
import draggable from 'vuedraggable'
import editingSearchedSubComponent from './EditingSearchedSubComponent'

export default {

    data() {
        return {
            search_keyword : '',
            message: '',
            is_side_collapsed: false
        }
    },
    computed: {
        searched_subs() {
            return this.$store.state.searched_subs
        },
        is_empty_searched_subs(){
            return this.$store.getters.isEmptySearchedSubs
        }
    },
    methods: {
        searchSubs(search_keyword){
            this.$store.dispatch('readSearchedSubs',search_keyword)
            if(this.is_empty_searched_subs==true) {
                this.message = this.search_keyword + '에 대한 검색결과가 없습니다. 다시 검색해주세요.'
            }
        },
        toggleSide(){
            this.is_side_collapsed = !this.is_side_collapsed
            EventBus.$emit('toggle-side', this.is_side_collapsed)
        },
    },
    created(){
        this.$store.commit('reset_searched_subs')
    },
    mounted(){
        console.log(this.searched_subs);
        console.log(this.is_empty_searched_subs);
    },
    components: {
        editingSearchedSubComponent,
        draggable
    }
}
</script>

<style scoped>

.ih-table-head {
    background-color:#333333;
    color: white;
}

.btn-right-collapse {
    height: 100vh;
    background-color: rgba(0,0,0,0);
    color: var(--gray-600);
    position: fixed;
    z-index: 50;
    top:0;
    right:0;
}
.btn-right-collapse:hover, .btn-right-collapse:focus {
    background-color: rgba(0,0,0,0.2);
    color: var(--gray-700);
}

</style>
