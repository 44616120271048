<template>
<div>
    <b-card class="mb-3 bgray-100 text-left">
        {{ $t(`캠페인 및 시스템 관련 공지사항 입니다. 새소식을 확인해 주세요.`) }}
        <b-card-text class="bgray-300 f-90 blue rounded">
            <b-badge variant="blue">New</b-badge> : {{ $t(`현재`) }} <b>{{ number_of_new }}</b>{{ $t(`개의 새소식이 있습니다.`) }}
        </b-card-text>
    </b-card>

    <div v-if="is_notices_loaded==true">
        <div v-for="(item, index) in notices" :key="index">
            <b-card id="notice-card" :class="[item.new==true ? 'border-blue':'', 'my-2']" >
                <div block  v-b-toggle="'n'+`${item.notice_id}`"  class="d-flex justify-content-between align-items-center">
                <!-- <div block :aria-expanded="visible ? 'false' : 'true'" :aria-controls="'n'+item.notice_id"  @click="toggleGroup(index, item.notice_id)" class="d-flex justify-content-between align-items-center"> -->
                    <div @click="toggleGroup(index, item.notice_id)" class="w-100">
                        <div class="notice-card-title d-flex align-items-center"> <b-badge v-show="item.new==true" class="mr-2 new">New</b-badge> {{ item.notice_title }} </div> 
                        <div class="notice-card-sub"> {{ item.time_stamp }} </div>
                    </div> 
                    <div class="pr-3">
                        <fa :icon="opened_notice==index ? 'angle-up':'angle-down'"></fa>
                    </div> 
                </div>
                <b-collapse :id="'n'+`${item.notice_id}`">
                    <b-card-text class="border-top"> <span v-html="item.notice_content" class="vhtml-setting"></span> </b-card-text>
                </b-collapse>
            </b-card>
        </div>
    </div> 
    <div v-else class="text-center">
        <b-spinner class="my-5" label="Loading..."></b-spinner>
    </div>
</div>
</template>

<script>
import * as ih from '@/util'

export default {
    data(){
        return{
            // visible: false,
            notices: [],
            // notice_title: '',
            // notice_content: ''
            // user_name: '',
            // time_stamp: '', 
            is_notices_loaded: false,  
            opened_notice: -1,
            new_notices: []        
        }
    },
    computed: {
        user_id(){
            return this.$store.state.user_id
        },
        company_id() {
            return this.$store.state.company_id
        },
        number_of_new(){
            let x = this.notices.filter((item) => {
                return item.new == true
            })            
            // console.log(x.length);
            return x.length            
        },
        // notices(){
        //     let new_notices = this.notices
        //      new_notices.sort( (a, b) => {
        //         return ih.stringToDate(b.time_stamp) - ih.stringToDate(a.time_stamp)
        //     })
        //     return new_notices           
        // }
    },
    methods:{
        readNotices() {
            const path = this.$store.state.backend_host + '/read_notices'

            return axios.get(path, { params: {company_id: this.company_id, user_id: this.user_id}})
            .then(response =>{
                // console.log(response);                
                this.notices = response.data.notices
                this.is_notices_loaded = true
            })
            .catch(error => {
                console.log(error);
            })
        },
        toggleGroup(index, notice_id) {
            // console.log('toggle group 까지 옴');
            // this.opened_notice = index
            this.makeRead(notice_id)
        },
        makeRead(notice_id){
            // console.log('make read 까지 옴');

            const path = this.$store.state.backend_host + '/make_notice_read'
            
            let formData = new FormData()
            formData.append('notice_id', notice_id)
            formData.append('user_id', this.user_id)

            return axios.post(path, formData)
            .then(response => {
                this.$emit('make-read-notice', notice_id)
            })
            .catch(error => {
                console.log(error);  
            })
            
        }
    },
    created(){
        this.readNotices()
    }    
}
</script>
<style>
.vhtml-setting div img,
.vhtml-setting > div > img {
    max-width: 100%;
}
</style>
<style scoped>
.vhtml-setting img,
.vhtml-setting > div > img {
    max-width: 600px;
}
.notice-card-title {
    font-size: 1.1rem;
    font-weight: 600;
    padding: 0.3rem 1rem 0rem;
}
.notice-card-sub {
    font-size: 0.9rem;
    padding: 0.3rem 1rem;
    color: var(--gray-600);
}

</style>
