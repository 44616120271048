//import axios from 'axios'
import debounce from 'lodash.debounce'
import * as ih from '@/util'
import { mapMutations } from 'vuex'
// import DFAttachedFileURL from '@/components/main_layout/sub_component/elements/DFAttachedFileURL.vue'

export const SubComponentMixin = {

    data () {
        return {
            file: null,
            files: [],
            file_names: [],

            uploaded_file: {
                url: '',
                name: ''
            },

            is_visible_by_pipeline: true,

            file_placeholder:'',
            save_state: 'not_saved',

            validation_comment: '',
            validation_icon: '',
            validation_style: 'ih-validation-saved',

            mode: '파일 또는 URL 유형선택',

            is_visible: false,

            url_temp: 'http://',
            file_temp: null,

            child_subs: [],
            score_result: 0,

            is_loaded: false,
            previous_sub: {},

            not_applicable: '',
            original_sub_data_contents: {},
        }
    },
    computed: {
        question_composition: {
            get(){
                return this.$store.state.question_composition
            }
        },
        campaign_in_progress: {
            get() {
                return this.$store.state.campaign_in_progress
            }
        },
        question_in_progress: {
            get() {
                return this.$store.state.question_in_progress
            }
        },
        is_locked: {
            get() {
                if( this.question_in_progress.is_completed == 'true') return true
                else return false
            }
        },
        view_mode() {
            return this.$store.state.view_mode
        },
        question_subs() {
            return this.$store.state.questions.subs
        },
        company_name() {
            return this.$store.state.company_name
        },
        response_code() {
            return this.$store.state.response_code
        },
        is_indicate_description() {
            var indicate_temp = this.sub_description
            indicate_temp.replace(/(\s*)/g,"")
            if (indicate_temp=='') return false
            else return true
        },
        is_disabled_by_na(){
            if(this.sub_data_contents.result == '해당없음') return true
            else return false
        },
        is_disabled_by_condition() {
            if(!this.is_sub_condition_activated) return false
            else {
                var x = this.sub_condition.value
                if( x === 'false' ||
                    x === false ||
                    x === '' ||
                    x === undefined ||
                    x === 'undefined' ||
                    x === '아니오' ||
                    x === 0 ||
                    x === '0' ||
                    x === null )
                { return  true }

                else return false
            }
        },
        is_sub_condition_activated(){
            if( this.sub_condition.hasOwnProperty('text'))
            {
                if (this.sub_condition.text != '') {
                    if(!this.sub_condition.hasOwnProperty('value')) { Vue.set(this.sub_condition, 'value', false) }
                    return true
                }
                else return false
            }
            else {
                Vue.set(this.sub_condition, 'text', '')
                return false
            }
        },
        isIE() {
            let agent = navigator.userAgent.toLowerCase();
            if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (agent.indexOf("msie") != -1) )
                {
                    return true
                }
            else
                { 
                    return false
                }
        },
        badge_var(){          
            if (this.sub_priority=='기초'){
                return 'basic'
            }
            else if (this.sub_priority=='심화'){
                return 'advance'
            }
            else if (this.sub_priority=='잠재'){
                return 'emerging'
            }            
        }
    },
    props: {
        question_code: { type: String, reuqired: true, default: 'GHG' },
        sub_code: { type: String, required: true, default: 'ghg_policy'},
        sub_name: { type: String, default: '' },
        sub_name_e: { type: String, default: '' },
        sub_name_c: { type: String, default: '' },
        sub_type: { type: String, required: true, default: 'text_input' },
        sub_description: { type: String },
        sub_description_e: { type: String },
        sub_description_c: { type: String },
        sub_info: { type: String, default: '' },
        sub_info_e: { type: String, default: '' },
        sub_info_c: { type: String, default: '' }, 
        sub_condition: { type: Object, default: () => ({
            value: '',
            text: '',
        }) },
        sub_data_header: {
            default: () => ({}) 
        },
        sub_data_contents: { default: () => ({
            structure: [],
            result: []
        }) },
        sub_priority: { type: [String, Number], default: '' },
        sub_parent: { type: String, default: ''},
        sub_child: { type: Array, default: () => ([]) },
        tags: { type: Array, default: () => ([]) },
        has_pipeline: { type: Boolean, default: false },

        headers: { type: Boolean, default: true },
        is_disabled_by_parent: { type: Boolean, default: false },
        preview: { type: Boolean },
        previous: { type: Boolean },
        audit_mode: { type: Boolean, default: false },
        is_final_sub_component: { type: Boolean, default: false },
        print_mode: { type: Boolean, default: false },
        custom_style: { type: String, default: ''}
    },
    watch: {
        save_state: function() {
            if (this.save_state == 'saving') {
                this.validation_comment = this.$t(`저장 중 입니다.`)
                this.validation_icon = 'hourglass'
                this.validation_style = 'ih-validation-saving'
            }
            else if (this.save_state == 'saved') {
                this.validation_comment = this.$t(`저장 되었습니다`)
                this.validation_icon = 'check'
                this.validation_style = 'ih-validation-saved'
            }
            else if (this.save_state == 'resaved') {
                this.validation_comment = this.$t(`변경 저장 되었습니다`)
                this.validation_icon = 'check'
                this.validation_style = 'ih-validation-resaved'
            }
        }
    },
    async created() {
        if(this.has_pipeline == true){
            await this.checkPipelineVisibility()
        }
        this.original_sub_data_contents = ih.deepCopy(this.sub_data_contents)
        await this.initiateData(this.sub_code)
        if (this.is_final_sub_component === true) {
            this.$emit('load-finished') 
        }

    },
    methods: {
        ...mapMutations('questions',['update_subs_loaded']),
        async dbSave () {
            try {
                console.log(`dbSave ~ `);
                
                let path = this.$store.state.backend_host + '/save_sub' 
                let formData = new FormData()
                

                let company_name = ''
                if (this.view_mode=='provider') {
                    company_name = this.$store.getters.getCompanyName
                } else company_name = this.$store.state.questions.answer_company.company_name

                let campaign_id = ''
                if (this.view_mode=='provider'){
                    campaign_id = this.campaign_in_progress.campaign_id
                } else {campaign_id = this.$store.state.campaign.campaign_id }
                
                formData.append('company_name', company_name)
                formData.append('response_code', this.$store.state.response_code)
                formData.append('mode', this.$store.state.mode)
                formData.append('campaign_id', campaign_id)
                formData.append('question_code', this.question_code)
                formData.append('sub_code',this.sub_code)
                formData.append('sub_name',this.sub_name)
                formData.append('sub_description',this.sub_description)
                formData.append('sub_info', this.sub_info)
                formData.append('sub_condition', JSON.stringify(this.sub_condition))
                formData.append('sub_type',this.sub_type)
                formData.append('sub_data_header',JSON.stringify(this.sub_data_header))
                formData.append('sub_data_contents',JSON.stringify(this.sub_data_contents))
                formData.append('sub_priority',this.sub_priority)
                formData.append('sub_parent', this.sub_parent)
                formData.append('sub_child', JSON.stringify(this.sub_child))
                formData.append('score_result', this.score_result)

                //파일이 존재하는 경우
                if (this.sub_type == 'file' && this.file != '' && this.file != null) formData.append('file', this.file)
                
                const response = await axios.post(path, formData, {
                    headers: { 'Content-Type' : 'multipart/form-data' }
                })

                // console.log(response.data)
                this.save_state = 'resaved'

                console.log(` ~ response => `, response);
                
                if (response.data.pipeline_updated === true) { 
                    this.$emit('update-pipeline')
                } // 파이프라인 변경 되었을 경우 Question 전체를 다시 그림
            } catch (error) {
                if (error.response.status == '401') {
                    alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                    await this.$store.dispatch('logout')
                    this.$router.push({ name: 'Login' })
                }   
            }
        },
        async radioSave () {
            this.save_state = 'saving'
            this.$nextTick(async () => {
                await this.dbSave()
            })

            // if (this.has_pipeline == true) {
            //     await this.checkPipelineVisibility()
            // }
            await this.checkPipelineVisibility()
        },
        textInputSave() {
            this.save_state = 'saving',
            this.debouncedSave()
        },
        debouncedSave: debounce (
            function() {
                this.$nextTick(async () => {
                    await this.dbSave()

                    if (this.has_pipeline == true) {
                        await this.checkPipelineVisibility()
                    }
                })
            },
            //사용자 입력을 기다리는 시간
            800
        ),
        async initiateData(sub_code) {
            try {
                let path = ''
                let company_name = ''

                if (this.view_mode=='provider') {
                    company_name = this.$store.getters.getCompanyName
                } else company_name = this.$store.state.questions.answer_company.company_name

                let campaign_id = ''
                if (this.view_mode=='provider'){
                    campaign_id = this.campaign_in_progress.campaign_id
                } else {campaign_id = this.$store.state.campaign.campaign_id }

                if(this.$store.state.mode != 'public') { 
                    path = this.$store.state.backend_host + '/read_sub' 
                } else {
                    path = this.$store.state.backend_host + '/read_sub_public'
                }
            
                const response = await axios.get(path, {
                    params: {
                        company_name: company_name,
                        campaign_id: campaign_id, 
                        response_code: this.$store.state.response_code,
                        question_code: this.question_code,
                        sub_code: sub_code
                    }
                })


                let result = response.data
                
                // this.sub_condition = result['sub_condition']
                // this.sub_data_contents = result['sub_data_contents']
                // this.score_result = result['score_result']

                this.$store.commit('questions/update_sub', {
                    sub_code: this.sub_code,
                    sub_condition: result['sub_condition'],
                    sub_data_contents: result['sub_data_contents'],
                    sub_data_header: result['sub_data_header'],
                    score_result: result['score_result'],
                })
                
                this.save_state = 'saved'
                
                //차일드 컴포넌트가 존재하는 경우 정보 가져옴
                if (this.sub_child.length != undefined && this.sub_child.length != 0) {
                    this.readChildSubs()
                }

                //지난해 정보가 존재하는 경우 정보 가져옴
                let previous_campaign_id = ''
                if (this.view_mode=='provider'){
                    previous_campaign_id = this.campaign_in_progress.previous_campaign_id
                } else {previous_campaign_id = this.$store.state.campaign.previous_campaign_id }
                
                if (previous_campaign_id != null && previous_campaign_id != ''){   
                    let comparable_code = this.sub_description.match(/^[A-Z.0-9]+/g)
                    if(comparable_code != null){
                        comparable_code = comparable_code[0]

                        let company_name = ''
                        if (this.view_mode=='provider') {
                            company_name = this.$store.getters.getCompanyName
                        }
                        else company_name = this.$store.state.questions.answer_company.company_name 

                        if(this.print_mode!=true){
                            this.readPreviousSub(previous_campaign_id, company_name, comparable_code)
                        }
                    }
                }  

                if(this.sub_type=='file_URL2') { this.getFilesFromServer() }
                                
                this.is_loaded = true

                //만약 '해당없음'이면 not_applicable = true 처리함
                if (this.sub_data_header.enable_na === true) {
                    if (this.sub_data_contents.result === '해당없음') {
                        this.not_applicable = true
                    } else if((this.sub_data_contents.result == [] || this.sub_data_contents.result == '') && this.save_state !== 'saved') {
                        this.not_applicable = ''
                    } else {
                        this.not_applicable = false
                    }
                }    
            } catch (error) {
                // console.log(error.response.status)
                this.save_state = 'not_saved'

                if (error.response.status==404){ 
                    console.log('DB에 저장된 내용이 없음 --> 신규 작성하는게 정상') 
                }
                else if (error.response.status==401)
                    { alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                     this.$store.dispatch('logout').then(() => { this.$router.push({name:'Login'}) })
                }

                //차일드 컴포넌트가 존재하는 경우 정보 가져옴
                if (this.sub_child.length != undefined && this.sub_child.length != 0) {
                    this.readChildSubs()
                }
                
                
                //지난해 정보가 존재하는 경우 정보 가져옴
                let previous_campaign_id = ''
                if (this.view_mode=='provider'){
                    previous_campaign_id = this.campaign_in_progress.previous_campaign_id
                } else {previous_campaign_id = this.$store.state.campaign.previous_campaign_id }
                
                if (previous_campaign_id != null && previous_campaign_id != ''){   
                    let comparable_code = this.sub_description.match(/^[A-Z.0-9]+/g)
                    if(comparable_code != null){
                        comparable_code = comparable_code[0]

                        let company_name = ''
                        if (this.view_mode=='provider') {
                            company_name = this.$store.getters.getCompanyName
                        }
                        else company_name = this.$store.state.questions.answer_company.company_name 

                        if(this.print_mode!=true){
                            this.readPreviousSub(previous_campaign_id, company_name, comparable_code)
                        }
                    }
                }  

                if(this.sub_type=='file_URL2') { this.getFilesFromServer() }

                //만약 '해당없음'이면 not_applicable = true 처리함
                if(this.sub_data_header.enable_na==true){
                    if(this.sub_data_contents.result=='해당없음'){
                        this.not_applicable = true
                    }
                    else if( (this.sub_data_contents.result==[] || this.sub_data_contents.result =='') && this.save_state != 'saved'){
                        this.not_applicable = ''
                    }
                    else{
                        this.not_applicable = false
                    }
                }
                                                
                this.is_loaded = true   
            }
        },
        get_file() {
            var got_file = {
                url: '',
                name: ''
            }

            axios({
                url: this.$store.state.backend_host + '/get_file',
                params: {
                    requested_file: this.sub_data_contents.file_name,
                    company_name: this.$store.getters.getCompanyName,
                    sub_code: this.sub_code
                    },
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    got_file['url'] = window.URL.createObjectURL(response.data);
                    got_file['name'] = this.sub_data_contents.file_name
                }).catch(error => {
                    console.log(error)
                    if (error.response.status == '401')
                    { alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                     this.$store.dispatch('logout').then(() => { this.$router.push({name:'Login'}) })
                    }
                })
            return got_file
        },
        getFilesFromServer() {
            if(!this.sub_data_contents.result) {
                console.log('저장될 파일이 제대로 지정되지 않았음')
                return Promise.resolve()
            }

            let promises = []
            const path = this.$store.state.backend_host + '/get_file_from_server'
            //console.log('Get FIle Form Server!')
            //console.log('view_mode:' + this.$store.state.view_mode)

            let target_company_id = 0
            let target_campaign_id = 0
            if(this.$store.state.view_mode=='provider'){
                target_company_id = this.$store.state.company_id
                if(this.previous == true){
                    target_campaign_id = this.$store.state.campaign_in_progress.previous_campaign_id 
                }
                else{
                    target_campaign_id = this.$store.state.campaign_in_progress.campaign_id
                }
            }
            else{
                target_company_id = this.$store.state.questions.answer_company.company_id
                if(this.previous == true){
                    target_campaign_id = this.$store.state.campaign.previous_campaign_id
                }
                else{
                    target_campaign_id = this.$store.state.campaign.campaign_id
                }
            }

            //console.log('company_id:' + target_company_id)

            //Previous Status에 따라서 campaign_id switching
            let i = 0
            this.sub_data_contents.result.forEach(
                file => {
                    let filename = file.filename
                    promises.push(
                        axios.get(
                            path, 
                            { 
                                params: {
                                    requested_file: filename,
                                    mode: this.$store.state.mode,
                                    response_code: this.$store.state.response_code, 
                                    company_id: target_company_id,
                                    campaign_id: target_campaign_id,
                                    sub_code: this.sub_code
                                },
                                method: 'GET',
                                responseType: 'blob', // important 
                        })
                        .catch(error => {
                            console.log(error)
            
                            if (error.response && error.response.status == '401')
                            { alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                             this.$store.dispatch('logout').then(() => { this.$router.push({name:'Login'}) })
                            }
                        })
                    )
                }
            )
              
            return Promise.all(promises)
            .then( responses => {
                for(let i=0; i<responses.length; i++){
                    if(responses[i] != undefined && responses[i] != 'File Not Found'){
                        let got_file = []
                        if(this.isIE==true){
                            got_file['file_link'] = responses[i].data
                        }
                        else{
                            got_file['file_link'] = window.URL.createObjectURL(responses[i].data);
                        }
                        got_file['filename'] = responses[i].config.params.requested_file
                        this.sub_data_contents.result[i].file_link = got_file['file_link']
                    }
                    else if(responses[i] == 'File Not Found'){
                        this.sub_data_contents.result[i].filename = this.sub_data_contents.result[i].filename + '(오류)'
                        this.sub_data_contents.result[i].file_link = 'File Not Found'
                    }
                    else continue
                }
            })
            .finally( () =>{
                // console.log(this.sub_data_contents)
            })

        },
        makeMode(new_mode){
            this.mode = new_mode
        },
        async handleOK () {
            if (this.sub_data_contents.length === 0) {
                this.$bvModalEvt.preventDefault()
            }
            await this.dbSave()
        },
        handleSubmit(){
            return true
        },
        toggleVisible(){
            this.is_visible = !this.is_visible
        },
        addURLToSubDataContents(item){
            if(this.isValidURL(item)==false) {
                window.alert('올바른 URL 형식으로 입력해 주십시오 (http)')
                return false
            }
            else {
                let new_URL_item = {
                    type: '',
                    filename: '',
                    file_link: '',
                    file_url_key: 0
                }
                new_URL_item.type = 'URL'
                new_URL_item.filename = item
                new_URL_item.file_link = item
                new_URL_item.file_url_key = this.files.length+1

                if (this.sub_data_contents.result==undefined){
                    this.sub_data_contents.result=[]
                } 
                this.sub_data_contents.result.push(new_URL_item)
                this.url_temp = 'http://'
            }
        },
        addFileToSubDataContents(item){          
            if(ih.fileCheck(item)==true) {
                let new_file_item = {
                    type: '',
                    filename: '',
                    file_link: '',
                    file_url_key: 0
                }
                new_file_item.type = 'file'
                new_file_item.filename = item.name
                new_file_item.file_link = item.name
                new_file_item.file_url_key = this.files.length+1

                this.files.push(item)
                if (this.sub_data_contents.result==undefined){
                    this.sub_data_contents.result=[]
                }        
                this.sub_data_contents.result.push(new_file_item)
                this.file_temp = null
            }else {
                this.file_temp = null
            }
        },
        isValidURL(str) {
            try {
                str = str.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
                var regex = new RegExp("[\\?&]" + str + "=([^&#]*)"),
                    results = regex.exec(location.search);
                    results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
                return true
                // ie에서 new URL을 사용할 수 없어 위 코드로 변경함
                // new URL(str);
                // return true;
            } catch (_) {
                return false;
            }
        },
        readChildSubs(){
            this.child_subs = []
            var promises = []
            const path = this.$store.state.backend_host + '/read_sub_init'

            this.sub_child.forEach(
                function (sub_code) {
                promises.push(axios.get(path, { params: {sub_code: sub_code}}))
            })

            return Promise.all(promises)
            .then( results => {
                    let temp_subs = []
                    results.forEach(
                        response => {
                            let result = response.data
                            let new_sub = {}
                            new_sub.sub_code = result.sub_code
                            new_sub.sub_name = result.sub_name
                            new_sub.sub_description = result.sub_description
                            new_sub.sub_condition = result.sub_condition
                            new_sub.sub_info = result.sub_info
                            new_sub.sub_type = result.sub_type
                            new_sub.sub_data_header = result.sub_data_header
                            new_sub.sub_data_contents = result.sub_data_contents
                            new_sub.sub_priority = result.sub_priority
                            new_sub.sub_parent = result.sub_parent
                            new_sub.sub_child = result.sub_child
                            new_sub.tags = result.tags

                            new_sub.question_code=this.question_code
                            temp_subs.push(new_sub)
                    })
                    this.child_subs = temp_subs
            })
        },
        updateStore(sub_data_contents, sub_condition){
            let new_sub = {
                sub_code: this.sub_code, 
                sub_name: this.sub_name, 
                sub_description: this.sub_description,
                sub_info: this.sub_info, 
                sub_condition: sub_condition, 
                sub_type: this.sub_type, 
                sub_data_header: this.sub_data_header,
                sub_data_contents: sub_data_contents,
                sub_priority: this.sub_priority,
                sub_parent: this.sub_parent, 
                sub_child: this.sub_chid,
                save_state: this.save_state,
                score_result: this.score_result
            }
            this.$store.commit('questions/update_sub',new_sub)
        },
        handleUpdateParentContents(sub_data_contents, sub_code){
            let idx = this.child_subs.findIndex(item => { return item.sub_code == sub_code })
            if(idx >-1){
                let new_sub = { ...this.child_subs[idx] }
                new_sub.sub_data_contents = sub_data_contents
                // console.log('handle Update')
                // console.log(new_sub)
                this.child_subs.splice(idx, 1, new_sub)
                //Vue.set(this.child_subs[idx],'sub_data_contents',sub_data_contents)
            }
            else{
                console.log('차일드 서브에서 못찾음')
            }
        },
        readPreviousSub(campaign_id, company_name, comparable_code){    
            const path = this.$store.state.backend_host + '/read_previous_sub'

            return axios.get(path, { params: {
                campaign_id,
                company_name,
                comparable_code
            }})
            .then( response => {
                this.previous_sub = response.data    
            })
            .catch( error => {
                console.log(error)
            })

        },
        async checkPipelineVisibility () {
            const path = this.$store.state.backend_host + '/check_pipeline_visibility'

            let company_name = ''
            if (this.view_mode=='provider') {
                company_name = this.$store.getters.getCompanyName 
            } else {
                company_name = this.$store.state.questions.answer_company.company_name
            }

            let campaign_id = ''
            if (this.view_mode === 'provider') {
                campaign_id = this.campaign_in_progress.campaign_id
            } else {
                campaign_id = this.$store.state.campaign.campaign_id
            }

            const response = await this.axios.get(path, { params: {
                sub_code: this.sub_code,
                campaign_id: campaign_id,
                company_name: company_name,
                mode: this.$store.state.mode,
                response_code: this.$store.state.response_code
            }})

            this.is_visible_by_pipeline = response.data.visibility
        }
    }
}