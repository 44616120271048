<template>
    <div class="py-4 px-8 bgray-200 d-flex align-item-center justify-content-between">
        <!-- <div class="d-flex align-item-center justify-content-between"> -->
            <b-img v-if="local_campaign.campaign_id==124||local_campaign.campaign_id==177" src="@/assets/gsc_logo.svg" style="width: 110px;"></b-img>
            <b-img src="@/assets/QuantifiedESG_Logo_v3_Basic.svg" style="width: 110px;"></b-img>
        <!-- </div> -->
        <a v-if="local_campaign.campaign_id!=124&&local_campaign.campaign_id!=177" class="gray-600 f-90" href="http://tierzero.kr/" target="_blank"> tierzero.kr </a>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    data() {
        return {
            local_campaign:''
        }
    },
    computed:{
        ...mapState('cr_scores', ['reporting_company', 'company_info']),
        ...mapState(['view_mode', 'campaign_in_progress', 'campaign']),
        
    },
    created(){
        if (this.view_mode=='provider'){
            this.local_campaign = this.campaign_in_progress
        } else {
            this.local_campaign = this.campaign
        }
    }
}
</script>

<style>
.py-45{
    padding-top: 2.1rem!important;
    padding-bottom: 2.1rem!important;
}
</style>