import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'

import Print from './print_plugin/print'
Print.install = function(Vue) {
    Vue.directive('print', Print);
};

Vue.use(Print)

Vue.use(BootstrapVue)
