<template>
    <div>
        <b-table-simple class=" text-left" small outlined>
            <b-tbody>
                <b-tr>
                    <b-td>Sub Code</b-td>
                    <b-td>{{ sub_code }}</b-td>
                </b-tr>
                <b-tr>
                    <b-td>Type</b-td>
                    <b-td>
                        <b-dropdown :text="sub_type != '' ? sub_type : '질문 유형(type)을 선택해 주세요'" variant="custom"
                            :class="sub_type !== '' ? 'no-validated' : ''"
                            >
                            <b-dropdown-item-button v-for="item in sub_types"
                                :key="item.id"
                                @click="selectType(item)"
                                >
                                {{ item }}
                            </b-dropdown-item-button>
                        </b-dropdown>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <!--저장 등-->

        <b-alert class="py-2" :show="dismissCountDown" :variant="alert_variant" @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged">
            {{ alert_msg }}
        </b-alert>
        <div class="float-right">
            <b-button @click="handleSaveSub()">저장</b-button>
            <b-button @click="$emit('click-cancel')" class="ml-2">취소</b-button>
        </div>
    </div>
</template>

<script>
import { EditingSubComponentMixin } from './EditingSubComponentMixin'

export default {
    mixins: [EditingSubComponentMixin],
    methods: {
        handleSaveSub() {
            if (this.sub_type == '') {
                this.showAlert('danger', "질문 유형(type)을 선택해야 합니다")
                return false
            }
            this.saveSub()
                .then(this.$emit('click-save'))
        }
    }
}
</script>
<style scoped>
.no-validated {
    border-color: var(--dpink)
}
</style>