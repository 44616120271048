import * as th from 'tree-helper'
import i18n from '@/i18n'

function isJSON(jsonString) {
    try {
        var o = JSON.parse(jsonString);
  
        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object", 
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return true
        }
    }
    catch (e) {
        return false
     }
    return false
}

function JSONParse(jsonString) {
    if(isJSON(jsonString)) return JSON.parse(jsonString)
    else return {}
}

function ArrayParse(jsonString) {
    if(isJSON(jsonString)) return JSON.parse(jsonString)
    else return []
}

function stringToArray(string){
    let new_array = []

    if(typeof(string)=='string') 
        { new_array = string.split(',')
          for(let i=0; i<new_array.length; i++)
          {
              new_array[i] = new_array[i].replace(/^\s+|\s+$/g,"")
          }
          return new_array
        }
    else return false

}

function arrayToString(array){
    if(Array.isArray(array)) {
        return array.toString(array)
    }
    else return false
}

function deepCopy(obj) {
    if (obj === null || typeof(obj) !== 'object')
    return obj;
  
    var copy = obj.constructor();
  
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) {
        copy[attr] = deepCopy(obj[attr])
      }
    }
  
    return copy
}

function stringToDate(string) {
    //"2019-11-13" 형식의 데이터를 Javascript Date로 변환하여 반환

    let new_date = new Date(string)
    new_date.setHours(0)
    new_date.setMinutes(0)
    new_date.setSeconds(0)
    
    return new_date 
}

function dateToString(date) {
    //날짜를 "2019-11-13"로 변환하여 반환
    
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

function getTimeStamp() {

    let d = new Date();

    let s =
        leadingZeros(d.getFullYear(), 4) + '-' +
        leadingZeros(d.getMonth() + 1, 2) + '-' +
        leadingZeros(d.getDate(), 2);

    return s;
}

function leadingZeros(n, digits) {

    var zero = '';
    n = n.toString();

    if (n.length < digits) {
        for (let i = 0; i < digits - n.length; i++)
            zero += '0';
    }

    return zero + n;
}

function completeTree(data){
    const completedData = {
        children: []
    }

    th.breadthFirstSearch(data, (node, parent) => {
        for (let key in completedData) {
            if (!node.hasOwnProperty(key)) {
              node[key]=completedData[key]
            }
        }
        if(parent != null) {
            node.parent = parent
        }
        else{
            node.parent = null
        }    
    })
}

function byLang(ko, en, cn){
    if($i18n.locale=='en') return en
    else if ($i18n.locale=='cn') return cn
    else return ko
}

function fileCheck(file) {
    // 사이즈체크
    var maxSize  = 20 * 1024 * 1024    //20MB
    var fileSize = 0;

    // 브라우저 확인
    var browser=navigator.appName;
    
    // 익스플로러일 경우
    if (browser=="Microsoft Internet Explorer"){
        var oas = new ActiveXObject("Scripting.FileSystemObject");
        fileSize = oas.getFile( file.value ).size;
    }
    // 익스플로러가 아닐경우
    else{
        fileSize = file.size;
        console.log(fileSize);
    }

    if(fileSize > maxSize){
        alert("파일사이즈 : "+ Math.round(fileSize/100000)/10 + "MB, 최대파일사이즈 : 20MB \n첨부파일 사이즈는 20MB 이하로 업로드 가능합니다.");
        return false;
    }
    else return true

    // document.fileForm.submit();

}
export { isJSON, JSONParse, ArrayParse, stringToArray, arrayToString, deepCopy, stringToDate, dateToString, getTimeStamp, completeTree, byLang, fileCheck}