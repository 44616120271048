<template>
<div :class="audit_mode!=true&&previous!=true ? 'mb-5':'gray-600 font-scale-sm'">
    <div v-show="is_visible_by_pipeline==true" :class="previous==true ? 'p-3 gray-500 rounded border bgray-100':''">
        <div v-if="headers">
            <!-- <template v-if="$i18n.locale=='ko'" > -->
                <div v-if="sub_name !== ''" :class="[custom_style === '' ? 'fw-500 f-110 border-bottom mb-2':'custom-sub-name', '' ]">
                    <span v-if="sub_data_header.required && sub_data_header.required === true">*</span>
                    {{ byLang(sub_name, sub_name_e, sub_name_c) }}
                </div>
                <div :class="[ sub_description != '' ? 'mb-4': '', 'mb-1']">
                    <div v-if="is_indicate_description" class="fw-500" :class="custom_style==''?'':'custom-sub-description'">
                        <span v-if="sub_name === '' && sub_data_header.required && sub_data_header.required === true">*</span>
                        {{ byLang(sub_description, sub_description_e, sub_description_c)  }}
                    </div>
                    <span v-for="item in tags" :key="item.id"> <span v-if="item != ''" class="ml-2 f-80 gray-500"> {{ item }} </span></span>
                    <!-- <b-button  v-if="sub_info != ''" class="ml-1 px-1 py-0 f-80" variant="teal" size="sm" :id="`i-${ sub_code }`" @click="toggleVisible" ><fa icon="book-open"></fa> 작성 도움말</b-button> -->

                    <span v-if="sub_priority != ''"><b-badge :variant="badge_var" class="mx-2">{{ sub_priority }}</b-badge></span>
                    <b-button v-if="previous_sub.sub_code!=undefined && previous!=true" v-b-toggle="previous_sub.sub_code" size="sm" variant="red" class="py-0">{{ $t('과거 답변 보기') }}<fa icon="history" class="ml-2"></fa></b-button>

                    <!-- sub_info 정보 -->
                    <!-- <div v-if="is_visible" :id="`sub_info-${ sub_code }`" class="mt-2 mb-3 px-1 blue f-90"> <fa icon="info-circle" class="mr-1"></fa><span v-html="sub_info"></span></div> -->
                    <b-card v-if="sub_info != ''" :id="`sub_info-${ sub_code }`" class="mt-2 mb-3 border-0 teal8 bg-secondary2 f-90"> <fa icon="info-circle" v-if="custom_style==''" class="mr-1"></fa><span v-html="byLang(sub_info, sub_info_e, sub_info_c)"></span></b-card>

                    <!-- sub_condition -->
                    <div v-if="is_sub_condition_activated==true" :id="`sub_condition-${sub_code}`" class="my-2 py-2">
                        <b-form-checkbox :disabled="is_locked || is_disabled_by_parent" v-model="sub_condition.value" @change="radioSave()"> {{ byLang(sub_condition.text, sub_condition.text_e, sub_condition.text_c) }} </b-form-checkbox>
                    </div>
                </div>
            <!-- </template> -->
        </div>

        <!-- previous component -->
        <b-collapse v-if="print_mode!=true" :id="previous_sub.sub_code" class="mb-5">
            <sub-component :disabled="true" v-if="previous_sub.sub_code != undefined && previous != true" :is_disabled_by_parent="true" :sub_type="previous_sub.sub_type" :item="previous_sub" :previous="true"></sub-component>
        </b-collapse>


        <b-overlay :show="is_visible_by_pipeline==false" rounded="sm" opacity="0.6" bg-color="#c8c8c8">
            <!-- Table 유형 Sub Component -->
            <!--해당없음 START -->
            <b-form-radio-group v-model="not_applicable" @change="toggleNA">
            <b-form-radio :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent" v-if="sub_data_header.enable_na==true" :value="false"></b-form-radio>

            <div :class="sub_data_header.enable_na==true? 'ml-4 mb-3': ''" >
                <!-- {{sub_data_contents}} -->
            <b-table-simple hover class=" f-95 pt-3 bor-table" style="word-break:keep-all; width:100%;">
                <b-thead>
                    <b-tr class="">
                        <b-td v-for="table_field in byLang(sub_data_contents['table_header'], sub_data_contents['table_header_ea'], sub_data_contents['table_header_ca'])" :key="table_field.index" class=""> {{  byLang(table_field, table_field.label, table_field.label) }} </b-td>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(table_item, index) in sub_data_contents.structure" :key="table_item.id">
                        <b-td v-for="(table_field, no) in  byLang(sub_data_contents['table_header'], sub_data_contents['table_header_ea'], sub_data_contents['table_header_ca'])" :key="table_field.id" class="" :style="isIE?'max-width:100px;':''">
                            <div v-if="table_item.hasOwnProperty('noteditables') && (table_item['noteditables'].includes(table_field) || table_item['noteditables'].includes(table_field.key))">
                                <span v-if="$i18n.locale=='en'" v-html="table_item[table_field.key+'_ea']"></span>
                                <span v-else-if="$i18n.locale=='cn'" v-html="table_item[table_field.key+'_ca']"></span>
                                <span v-else v-html="table_item[table_field]"></span>
                            </div>
                            <div v-else>
                                <div v-if="table_item.hasOwnProperty('dropdown') && (table_item['dropdown'].hasOwnProperty(table_field) || table_item['dropdown'].hasOwnProperty(table_field.key))">
                                    <b-dropdown v-if="$i18n.locale=='en'" :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent || is_disabled_by_na"
                                    :id="`table-dropdown-${sub_code}-${index}-${no}`"
                                    :text="table_item[table_field.key+'_ea']!=''&&table_item[table_field.key+'_ea']!=null ? table_item[table_field.key+'_ea'] : table_item['dropdown'][table_field.key+'_ea'][0]"
                                    size="sm" variant="dark-border">
                                        <b-dropdown-item-button v-for="dropdown_item in table_item['dropdown'][table_field.key+'_ea']" :key="dropdown_item.id" @click="setDropdown(table_item, table_field.key+'_ea', dropdown_item)" class="f-90">{{ dropdown_item }}</b-dropdown-item-button>
                                    </b-dropdown>
                                    <b-dropdown v-else-if="$i18n.locale=='cn'" :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent || is_disabled_by_na" 
                                    :id="`table-dropdown-${sub_code}-${index}-${no}`" 
                                    :text="table_item[table_field.key+'_ca']!=''&&table_item[table_field.key+'_ca']!=null ? table_item[table_field.key+'_ca'] : table_item['dropdown'][table_field.key+'_ca'][0]" 
                                    size="sm" variant="dark-border">
                                        <b-dropdown-item-button v-for="dropdown_item in table_item['dropdown'][table_field.key+'_ca']" :key="dropdown_item.id" @click="setDropdown(table_item, table_field.key+'_ca', dropdown_item)" class="f-90">{{ dropdown_item }}</b-dropdown-item-button>
                                    </b-dropdown>
                                    <b-dropdown v-else :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent || is_disabled_by_na" :id="`table-dropdown-${sub_code}-${index}-${no}`" :text="table_item[table_field]" size="sm" variant="dark-border">
                                        <b-dropdown-item-button v-for="dropdown_item in table_item['dropdown'][table_field]" :key="dropdown_item.id" @click="setDropdown(table_item, table_field, dropdown_item)" @input="not_applicable=false"  class="f-90">{{ dropdown_item }}</b-dropdown-item-button>
                                    </b-dropdown>
                                </div>
                                <div v-else>
                                    <div v-if="table_item.hasOwnProperty('calculation') && table_item['calculation'].hasOwnProperty(table_field)">
                                        {{ cellCalculate(table_item.calculation[table_field]) }}
                                    </div>
                                    <div v-else>
                                        <b-input-group class="primary-input">
                                            <b-form-input v-if="$i18n.locale=='en'" :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent || is_disabled_by_na" v-model.trim="table_item[table_field.label]" @update="textInputSave" @input="not_applicable=false" :placeholder="showPlaceholder(table_item, table_field.key+'_ea')" class="pr-0 f-90"></b-form-input>
                                            <b-form-input v-else-if="$i18n.locale=='cn'" :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent || is_disabled_by_na" v-model.trim="table_item[table_field.label]" @update="textInputSave" @input="not_applicable=false" :placeholder="showPlaceholder(table_item, table_field.key+'_ca')" class="pr-0 f-90"></b-form-input>
                                            <b-form-input v-else :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent || is_disabled_by_na" v-model.trim="table_item[table_field]" @update="textInputSave" @input="not_applicable=false" :placeholder="showPlaceholder(table_item, table_field)" class="pr-0 f-90"></b-form-input>
                                            <b-input-group-append v-if="custom_style=''"><b-button :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent || is_disabled_by_na" class="f-80" variant="icon-append" v-b-modal="`table-text-${sub_code}-${index}-${no}`"><fa icon="pen"></fa></b-button></b-input-group-append>

                                        <b-modal @ok="dbSave" :id="`table-text-${sub_code}-${index}-${no}`" :title="table_field ? byLang(table_field, table_field.label, table_field.label) : $t(`텍스트 상세입력`)">
                                            <b-textarea rows="4" :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent || is_disabled_by_na" v-model="table_item[table_field]" @input="not_applicable=false"></b-textarea>
                                        </b-modal>
                                        </b-input-group>
                                    </div>
                                </div>
                            </div>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            </div>

            <!--해당없음 END-->
            <b-form-radio :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent" v-if="sub_data_header.enable_na==true" :value="true" class="primary-check ">해당없음</b-form-radio>
            </b-form-radio-group>
            <div v-if="not_applicable==true" class="ml-4 mt-2"><b-form-input v-model="sub_data_contents.na_reason" placeholder="본 질문이 귀사에 해당되지 않는 사유를 간략히 입력해 주세요." @update="textInputSave"></b-form-input></div>

            <!-- 저장 validation -->
            <div v-if="previous != true && audit_mode!=true &&custom_style==''" :class="validation_style" class="mt-2"><fa v-if="validation_icon !=''" :icon="validation_icon"></fa> {{ validation_comment }} </div>
            
            <!-- Child Component -->
                <sub-component v-for="(item, index) in child_subs" :key="item.id"
                :is_final_sub_component="index == child_subs.length -1 ? true : false"
                :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent" 
                :sub_type="item.sub_type" 
                :item="item" 
                :audit_mode="audit_mode" 
                :is_disabled_by_parent="is_disabled_by_parent" ></sub-component>              

            <!-- is_visible_by_pipeline 조건에 따른 해당없음 overlay -->
            <template v-slot:overlay>
                <div class="text-center ">
                    {{$t(`해당사항 없음`)}} <b-button variant="icon" v-b-tooltip.hover :title="pipeline_title"><fa icon="info-circle"></fa></b-button>
                </div>
            </template>
        </b-overlay>

    </div>


</div>
</template>
<script>
import { SubComponentMixin } from './SubComponentMixin'

export default {
    mixins: [SubComponentMixin],
    data(){
        return{
            button_title: this.$t(`평가정보`),
            pipeline_title: this.$t(`이 문항은 답변할 필요가 없습니다.`)
        }
    },
    computed:{
        isIE() {
            if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (navigator.userAgent.toLowerCase().indexOf("msie") != -1) )
                {return true }
            else return false
        }
    },
    methods: {
        byLang(ko, en, cn){
            if(this.$i18n.locale=='en') return en
            else if (this.$i18n.locale=='cn') return cn
            else return ko
        },
        showPlaceholder(table_item, table_field) {
            if(table_item.hasOwnProperty('placeholder') && table_item['placeholder'].hasOwnProperty(table_field)) {
                return table_item['placeholder'][table_field]
            }
            else return ''
        },
        async setDropdown(table_item, table_field, contents) {
            table_item[table_field] = contents
            return await this.radioSave()
        },
        cellCalculate(str){
            let var_item = []
            let calculation_item = []

            var_item = str.match(/([A-Z0-9\(\)]+)/gi)
            calculation_item = str.match(/([+-/*])/g)

            let result = ''

            //오류처리들
            if(!var_item[0])
            {
                //console.log('셀 아이템이 없습니다')
                return false
            }
            if(var_item.length!=calculation_item.length+1)
            {
                //console.log('셀 아이템과 연산자 개수가 맞지 않습니다.')
                return false 
            }

            //만약 셀 참조일 경우, cellValue로 변환
            if(var_item[0].match(/[A-Z]/i)) result = this.cellValue(var_item[0])
            else result = String(var_item[0])

            for(let i=0; i<calculation_item.length; i++)
            {
                if(var_item[i+1].match(/[A-Z]/i)) { var_item[i+1] = this.cellValue(var_item[i+1]) }
                else var_item[i+1] = String(var_item[i+1])
                result = result + calculation_item[i] + var_item[i+1]
            }

            //console.log(result)
            result = eval(result)
            return result.toLocaleString(undefined, {maximumFractionDigits:0})

        },
        cellValue(var_item){
            //열번호, 행번호 순으로 읽어옴
            let col_index_array = var_item.match(/([A-Z])/gi)
            let row_index_array = var_item.match(/(\d+)/g)

            let row_parser = {
                'A':0, 'B':1, 'C':2, 'D':3, 'E':4, 'F':5, 'G':6, 'H':7, 'I':8, 'J':9, 'K':10, 'L':11, 'M':12, 'N':13, 
                'O':14, 'P':15, 'Q':16, 'R':17, 'S':18, 'T':19, 'U':20, 'V':21, 'W':22, 'X':23, 'Y':24, 'Z':25
            }

            let col_index = ''
            let row_index = ''

            // col_index 처리함. col_index는 A = 0 , B = 1, C = 2, D = 3, E = 4의 순서로 
            // 숫자로 바꾼 뒤 sub_data_header의 table_header에서 해당 아이템을 읽어 옴 

            // 먼저 테이블의 전체 크기를 구해 둠
            let max_col_index = this.sub_data_contents.table_header.length
            let max_row_index = this.sub_data_contents.structure.length

            // let table_header_ca = this.sub_data_contents.table_header_ca

            if(!col_index_array[0] && !row_parser[col_index_array[0]]) 
            {
                //console.log('열 번호가 없습니다. 읽어온 값:'+col_index_array)
                return 0
            }
            else {
                //열 번호 검증
                if(row_parser[col_index_array[0]] > max_col_index) 
                {
                    //console.log('수식오류(열 크기 벗어남 max_col_index:'+max_col_index+'col_index'+row_parser[col_index_array[0]])
                    return 0
                } 
                col_index = this.sub_data_contents.table_header[row_parser[col_index_array[0]]]
            }

            // row index의 경우에는 1부터 시작했는데 실제로 0부터 내부적으로 시작했기 떄문에 1을 뺴줌 
            if(!row_index_array[0]) {
                //console.log('행 번호가 없습니다. 읽어온 값:'+row_index_array)
                return 0
            }
            else row_index = row_index_array[0] - 1 

            //행 번호 검증
            if (row_index > max_row_index){
                //console.log('수식오류(행 크기 벗어남 max_row_index:'+max_row_index+'row_index'+row_index)
                return 0
            }

            let cell_value = this.sub_data_contents.structure[row_index][col_index]
            //console.log('cell_value'+row_index+'행'+col_index+'열:'+cell_value)

            if(!cell_value) {
                //console.log('셀 값이 정의되지 않았습니다. 0으로 간주합니다.')
                cell_value = '0'
            }

            if(typeof(cell_value) != 'string') cell_value = String(cell_value)
            
            //원래 스트링이 (로 시작하거나, )로 끝났다면, 덧붙여줌
            if(var_item.match(/^\(+/i)) cell_value = var_item.match(/^\(+/i)+cell_value
            if(var_item.match(/\)+$/i)) cell_value = cell_value + var_item.match(/\)+$/i)
            
            //먼저 점 앞에 오는 숫자만 남기고(정수화) => 특수문자를 제거하고 => 괄호와 숫자부분만 남겨서 스트링인채로 리턴
            //console.log(var_item)
            //console.log(cell_value.replace(/\.\S*/gi,'').replace(/[\{\}\[\]\/?.,;:|*~`!^\-_+<>@\#$%&\\\=\'\"]/gi,'').match(/^[\(0-9\)]+/))
            return cell_value.replace(/\.\S*/gi,'').replace(/[\{\}\[\]\/?.,;:|*~`!^\-_+<>@\#$%&\\\=\'\"]/gi,'').match(/^[\(0-9\)]+/)
        },
        toggleNA() {
            this.$nextTick(async () => {
                if(this.not_applicable == false){
                    //Trick - 원래 저장된 내용을 sub_init 과 바꿔치기함
                    let temp = { ...this.sub_data_contents }
                    this.sub_data_contents = { ...this.original_sub_data_contents }
                    this.original_sub_data_contents = { ...temp }

                    this.sub_data_contents.result = ''
                    delete this.sub_data_contents.na_reason
                }
                else {
                    let temp = { ...this.sub_data_contents }
                    this.sub_data_contents = { ...this.original_sub_data_contents }
                    this.original_sub_data_contents = { ...temp }

                    this.sub_data_contents.result = '해당없음'
                    await this.radioSave()
                }
            })
        },
    }
}
</script>

<style>
@media(max-width:540px) {
    .d-block-helper{
        display: block;
    }
}

.ie-table-input {
    width: 50%;
}


</style>
