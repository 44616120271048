<template>
<div>
    <b-container v-if="question_code!=''" class="px-0">
        <div class="pt-2 mb-2 border-bottom f-120 fw-800 d-flex justify-content-between align-items-center">
            {{ question_name }} {{ question_code }}
        </div>
       
        <b-card no-body class="border-dotted mb-4 p-1" @click="openModifyModal('info_box')">
            <b-card-text v-if="question_infobox!=undefined && question_infobox!=''" v-html="question_infobox" class="small"></b-card-text>
            <b-card-text v-else>클릭하여 질문의 배경 정보를 입력해 주세요(Click)</b-card-text>
        </b-card>
    
     <!-- {{ question_subs }} -->
        <draggable v-if="is_question_subs_loaded==true" v-model="question_subs" sortable group="subs" :disabled="is_disabled">
            <div v-for="(sub, index) in question_subs" :key="sub.index" class="d-flex mb-5">
                <div class="p-2 border-dotted position-relative" @click="openModifyModal('sub_component', index)" :style="score_not_defined==false?'width: 60%; min-width: 60%;':'width: 100%;'" >
                    <div v-if="sub.sub_type!=''">
                        {{ sub.sub_code }}
                        <sub-component 
                        :preview="true"
                        :sub_type="sub.sub_type" 
                        :item="sub"
                        :is_disabled_by_parent="true"
                        @update-contents="handleUpdateContents(index)"
                        >
                        </sub-component>
                    </div>
                    <div v-else class="pl-2">
                        클릭하여 세부 질문을 정의해 주세요(Click)
                    </div>
                    <div class="delete-button">
                        <b-button @click="openDeleteModal(sub.sub_code)"
                            variant="red"
                            size="sm"
                            >
                            삭제
                        </b-button>
                    </div> 
                </div>
                <div v-if="sub.sub_type!=''" class="pl-3 d-none d-lg-block" style="width: 40%; min-width: 40%;">
                    <!-- <b-button v-b-toggle="'method'+sub.sub_code" variant="dark-border">Score Toggle</b-button> -->
                    <b-collapse :id="'method'+sub.sub_code" visible class="bgray-100">
                        <editing-scoring-method
                            :sub_code="sub.sub_code"
                            :sub_type="sub.sub_type"
                            :read_only="true"
                            @score_not_defined="handleScoreNotDefined"
                        >                   
                        </editing-scoring-method>
                    </b-collapse>
                </div>               
            </div>
        </draggable>
    </b-container>
    
    <b-container v-else class="p-0">
        <div class="py-5 text-center bgray-200 rounded">질문 리스트에서 편집할 질문을 선택해주세요.</div>
    </b-container>

    <div class="text-right pt-5" v-if="question_code!=''"> 
        <b-button variant="blue" @click="addSub()" size="sm"> <fa icon="plus" class="mr-1"></fa> 새문항 </b-button>
    </div>



    <!-- sub component 생성/수정하기 모달 -->
    <b-modal id="modify-modal" ref="modify-modal" title="수정하기" hide-footer scrollable>
        <div v-if="modal_mode=='info_box'">
            <div class="fw-400 p-2 text-left"> Infobox 수정 </div>
            <b-form-textarea class="p-2" rows="6" v-model="question_infobox"></b-form-textarea>
            <div class="mt-3 mb-3 mr-3 text-right">
                <b-button @click="hideModal()" variant="dark">취소</b-button>
                <b-button class="ml-2" @click="handleOK()" variant="blue">저장</b-button>
            </div>
        </div>
        <div v-else-if="idx > -1 && modal_mode=='sub_component'">
            <div :class="[show_sub_component==true ? 'bottom-blue':'bottom-red','mb-3']">
                <b-button @click="showSubComponent" :variant="show_sub_component==true? 'blue':'dark'" size="sm" class="mr-2"> Editing </b-button>
                <b-button @click="showScoringMethod" :variant="show_scoring_method==true? 'red':'dark'" size="sm" class="mr-2"> Scoring </b-button>
                <b-button @click="showPipeline" :variant="show_pipeline==true? 'red': 'dark'" size="sm" class="mr-2"> Pipeline </b-button>
            </div>
            <div v-if="show_sub_component==true">
                <editing-sub-component 
                    :sub_code="question_subs[idx].sub_code"
                    :sub_type="question_subs[idx].sub_type"
                    @click-save="handleOK()"
                    @click-cancel="hideModal()"
                ></editing-sub-component>
            </div>
            <div v-if="show_scoring_method==true">
                <editing-scoring-method
                    :sub_code="question_subs[idx].sub_code"
                    :sub_type="question_subs[idx].sub_type"
                    @scoring_method_saved="handleOK()"
                    @click-cancel="hideModal()"
                >                   
                </editing-scoring-method>
            </div>
            <div v-if="show_pipeline==true">
                <editing-pipeline
                    :sub_code="question_subs[idx].sub_code"
                    :sub_type="question_subs[idx].sub_type"
                    @click-cancel="hideModal()"
                >
                </editing-pipeline>
            </div>
        </div>
    </b-modal>

    <!-- 삭제경고 Modal -->
    <b-modal
        header-text-variant="danger"
        ref="delete-modal"
        title="경고"
        @ok="$store.dispatch('deleteSubfromDB', delete_target_code)"
        @close="hideModal"
        >
        <p> 진행할 경우 선택한 질문의 내용이 지워집니다. 이 변경은 되돌릴 수 없습니다.</p>
        <p> 계속 하시겠습니까? </p>
    </b-modal>

</div>
</template>

<script>
import draggable from 'vuedraggable'
import editingSubComponent from './editing_sub_component/EditingSubComponent'
import editingScoringMethod from './EditingScoringMethod'
import editingPipeline from './EditingPipeline'
import * as ih from '@/util'

export default {
    data(){
        return {
            is_disabled: false,

            modal_mode: false,
            idx: -1,

            delete_target_code: '',
            show_sub_component: true,
            show_scoring_method: false,
            show_pipeline: false,
            score_not_defined: false
        }
    },
    components: {
        draggable,
        editingSubComponent,
        editingScoringMethod,
        editingPipeline,
    },
    props: {
    },
    computed: {
        question_composition: {
          get() {
              return this.$store.getters.getQuestionComposition
          },
          set(newValue) {
              return this.$store.question_composition = newValue
          }
        },
        question_code: {
            get() {
                return this.question_composition.question_code
            },
            set(newValue){
                this.question_composition.question_code = newValue
            }
        },
        question_name: {
            get() {
                return this.question_composition.question_name
            },
            set(newValue) {
                this.question_composition.question_name = newValue
            }
        },
        question_infobox: {
            get() {
                return this.question_composition.question_infobox
            },
            set(newValue) {
                this.question_composition.question_infobox = newValue
            }
        },
        question_contents() {
                return this.question_composition.question_contents
        },
        question_subs: {
            get() {
                return this.$store.getters.getQuestionSubs
            },
            set(new_subs) {
                return this.$store.commit('update_question_subs', new_subs)
            }
        },
        is_question_subs_loaded(){
            return this.$store.state.is_question_subs_loaded
        }
    },
    methods: {
        togglePreviewing(){
            this.is_previewing = !this.is_previewing
            // console.log('is_previewing:'+this.is_previewing)
        },
        async saveQuestionComposition() {
            await this.$store.dispatch('saveQuestionComposition')
            this.$emit('toast-success','성공','질문 내용 변경사항을 저장하였습니다.')
        },
        addSub(){
            if (this.question_code==''){
                return alert('질문 리스트를 먼저 만들어 주세요.')
            }
            else {
                var new_sub = {
                    question_code: this.question_code,
                    sub_code: 's' + Math.floor(Math.random() * 1000000000),
                    sub_name: '',
                    sub_type: '',
                    sub_condition: {},
                    sub_description: '',
                    sub_info: '',
                    sub_priority: '',
                    sub_data_header: {},
                    sub_data_contents: {
                        structure:[
                            {text: '예', value: '예'},
                            {text: '아니오', value: '아니오'}
                        ],
                        result: []
                    },
                    sub_parent: '',
                    sub_child: [],
                    tags: ''
                }
                this.$store.commit('add_question_sub', new_sub)
            }
        },
        toggleDisabled(x){
            this.is_disabled = x
        },
        refreshQuestionMaster(question_code){
            this.$store.dispatch('readQuestionSubs')
        },
        openModifyModal(mode, index){
            this.modal_mode=mode
            this.idx = index
            this.show_sub_component = true
            this.$refs['modify-modal'].show()
        },
        resetModal(){
            this.idx = - 1
            this.modal_mode = false
            this.$refs['modify-modal'].hide()
        },
        handleOK(){
            this.saveQuestionComposition()
            this.idx = -1
            this.modal_mode = false
            this.show_scoring_method = false
            this.show_sub_component = true
            this.$refs['modify-modal'].hide()          
        },
        hideModal(){
            this.idx = -1
            this.modal_mode = false
            this.show_scoring_method = false
            this.show_sub_component = false
            this.$refs['modify-modal'].hide()
            this.$forceUpdate()
        },
        openDeleteModal(delete_target_code){
            const _delete_tartget_idx = this.question_subs.findIndex(({ sub_code }) => {
                return sub_code === delete_target_code
            })

            if (_delete_tartget_idx !== -1) {
                this.idx = _delete_tartget_idx

                this.delete_target_code = delete_target_code
                this.$refs['delete-modal'].show()
            }

            this.show_sub_component = false
            this.$forceUpdate()
        },
        showSubComponent(){
            this.show_scoring_method = false
            this.show_sub_component = true
            this.show_pipeline = false
        },
        showScoringMethod(){
            this.show_sub_component = false
            this.show_scoring_method = true
            this.show_pipeline = false
        },
        showPipeline(){
            this.show_sub_component = false
            this.show_scoring_method = false
            this.show_pipeline = true
        },
        handleScoreNotDefined(){
            this.score_not_defined = true
        }
        
    },
    watch:{
        is_question_subs_loaded(newVal){
            if(newVal==false){
                this.idx = -1 // 에러방지
            }
        }
    },

}
</script>

<style scoped>
.delete-button{
    position:absolute;
    bottom: 0.5rem;
    right: 0.5rem;
}
.bottom-blue {
    border-bottom: 1px solid var(--blue);
}
.bottom-red {
    border-bottom: 1px solid var(--red);
}

</style>
