<template>
<div class="py-2">
        <div class="fw-600 border-bottom-dark pb-1 mb-2">{{$t(`답변 작성/수정 관련`)}}</div>
        <section class="mb-5">          
            <div class="faq-group shadow-sm">
                <b-button block v-b-toggle.collapse-2 @click="toggleGroup(2)" :pressed.sync="faqToggle[2]" variant="light" class="d-flex justify-content-between"> 
                    <span class="text-left">{{$t(`Q1. '제출하기'에 체크하면 답변을 다시 수정할 수 없나요?`)}}</span>
                    <fa v-if="is_group_active[2]" icon="angle-down" class="mx-2 float-right"></fa><fa v-else icon="angle-up" class="mx-2 float-right"></fa>
                </b-button>
                <b-collapse id="collapse-2">
                    <div class="faq-answer f-95 gray-600 mb-1"> {{ $t(`'제출하기'는 진행 현황을 확인하는 데 사용됩니다.`)}} {{ $t(`답변 기간 내에는 '제출됨'을 풀고 수정 가능합니다.`)}}</div>
                </b-collapse>
            </div>

            <div class="faq-group shadow-sm">
                <b-button block v-b-toggle.collapse-1 @click="toggleGroup(1)" :pressed.sync="faqToggle[1]" variant="light" class="d-flex justify-content-between"> 
                    <span class="text-left"> {{$t(`Q2. 작성한 답변을 문서로 다운로드 받을 수 있나요?`)}} </span>
                    <fa v-if="is_group_active[1]" icon="angle-down" class="mx-2 float-right"></fa><fa v-else icon="angle-up" class="mx-2 float-right"></fa>
                </b-button>
                <b-collapse id="collapse-1">
                    <div class="faq-answer f-95 gray-600 mb-1"> {{ $t(`캠페인 페이지의 오른쪽에 '인쇄/다운로드'를 통해 pdf 출력 및 파일 다운로드가 가능합니다.`)}} <br> 
                    {{ $t(`다만, 인터넷 익스플로러를 사용하실 경우 해당 기능을 이용하실 수 없으므로 반드시 크롬(Chrome) 또는 엣지(Edge) 브라우저 등을 이용해 주세요.`)}}<br>
                    {{ $t(`자세한 사용 방법은 아래 '사용 매뉴얼'을 참고해주세요.`)}} </div>
                </b-collapse>
            </div>
        </section>

        <div class="fw-600 border-bottom-dark pb-1 mb-2"> {{ $t(`결과 보고서 관련`)}}</div>
        <section class="mb-5">
            <div class="faq-group shadow-sm">
                <b-button block v-b-toggle.collapse-4 @click="toggleGroup(4)" :pressed.sync="faqToggle[4]" variant="light" class="d-flex justify-content-between"> 
                    <span class="text-left">{{$t(`Q1. 결과보고서는 언제 확인할 수 있나요?`)}}</span>
                    <fa v-if="is_group_active[4]" icon="angle-down" class="mx-2 float-right"></fa><fa v-else icon="angle-up" class="mx-2 float-right"></fa>
                </b-button>
                <b-collapse id="collapse-4">
                    <div class="faq-answer f-95 gray-600 mb-1"> {{ $t(`결과보고서는 평가 완료 후 배포되며, 결과보고서 배포 시 이메일 및 공지사항으로 안내가 될 예정입니다.`)}}</div>
                </b-collapse>
            </div>
        </section>

        <!-- <div class="fw-600 border-bottom mb-2">코멘트 기능 관련</div>
        <section class="mb-5">
            <div class="faq-group shadow-sm">
                <b-button block v-b-toggle.collapse-4 @click="toggleGroup(4)" :pressed.sync="faqToggle[4]" variant="light" class="text-left"> <span>Q1. 캠페인 내 우측의 '코멘트' 패널은 어떻게 활용할 수 있나요?</span>
                    <fa v-if="is_group_active[4]" icon="angle-down" class="mx-2 float-right"></fa><fa v-else icon="angle-up" class="mx-2 float-right"></fa>
                </b-button>
                <b-collapse id="collapse-4">
                    <div class="faq-answer f-95 gray-600 mb-1"> 코멘트 패널은 메모를 남기거나 다른 참여자와 대화를 주고받기 위한 기능입니다. 
                    작성 중 확인 필요한 사항을 간단히 메모해 두시거나, '@'로 참여자를 검색하여 다른 참여자에 코멘트를 남길 수 있습니다. 특히, 데이터 및 정보의 출처를 작성해 두시면 추후 검토 및 실사에도 효율적으로 대응하실 수 있습니다. </div>
                </b-collapse>
            </div>
            <div class="mb-0 faq-group shadow-sm">
                <b-button block v-b-toggle.collapse-5 @click="toggleGroup(5)" :pressed.sync="faqToggle[5]"  variant="light" class="text-left"> <span>Q2. 코멘트 작성 시 누구한테 보여지는 건가요?</span>
                    <fa v-if="is_group_active[5]" icon="angle-down" class="mx-2 float-right"></fa><fa v-else icon="angle-up" class="mx-2 float-right"></fa>
                </b-button>
                <b-collapse id="collapse-5">
                    <div class="faq-answer f-95 gray-600 mb-1"> 해당 질문의 '보기권한'이 있는 작성자도 코멘트를 확인 및 답변 작성 가능합니다. 코멘트 작성 시 '@'로 수신자를 태그하실 경우 수신자에게 알람이 전송됩니다. </div>
                </b-collapse>
            </div>
        </section> -->

        <div class="fw-600 border-bottom-dark pb-1 mb-2">{{$t(`시스템 사용 방법`)}}</div>
        <div class="text-center mb-3">
            <b-button :href="byLang('/document/[2022 QESG] 티어제로_시스템 사용 매뉴얼_v1.pdf','/document/[2022 QESG] TierZero_user manual_en_v1.pdf','/document/[2022 QESG] TierZero_user manual_cn_v1.pdf')"
            variant="blue-border" block target="_blank"
            > {{$t(`시스템 사용 매뉴얼`)}} <fa icon="book" class="ml-1"></fa></b-button>
        </div>

</div>
</template>

<script>
export default {
    data (){
        return {
            // publicPath: process.env.BASE_URL,
            visible: true,
            is_group_active: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
            faqToggle: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
        }
    },
    methods: {
        toggleGroup(x){
            Vue.set(this.is_group_active,x,!this.is_group_active[x])
        },
        byLang(ko, en, cn){
            if(this.$i18n.locale=='en') return en
            else if (this.$i18n.locale=='cn') return cn
            else return ko
        },
    }
}


</script>

<style scoped>
.faq-group {
  text-align: left;
  margin-bottom: 0.5rem;
  
}
.faq-answer{
    padding: 0.75rem 1rem;
    line-height: 1.8;
}

</style>
