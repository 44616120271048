<template>
    <div class="overflow-auto" style="">
        <b-table-simple responsive class="f-90">
            <b-thead>
                <b-tr class="gray-600">
                    <b-td style="max-width:60px">번호</b-td><b-td>회사명</b-td><b-td>선택 제외</b-td><b-td v-if="exclude_save_mode==true">담당자 선택</b-td>
                </b-tr>
            </b-thead>
            <b-tbody v-for="(item, index) in portfolio_companies" :key="item.index" class="py-1">
                <b-tr >
                    <b-td> {{ index + 1}} </b-td>
                    <b-td> {{ item.company_name }} </b-td>
                    <b-td> <b-button variant="icon-dark" :disabled="is_saving_exclude==true||is_admin!=4" @click="deleteCompany(item)" class="p-0 f-120"><fa icon="minus-square"></fa></b-button></b-td>
                    <b-td class="pr-0" v-if="exclude_save_mode==true"> 
                        <div v-for="x in item.all_users" :key="x.id" class="text-left pl-1 py-1 d-flex justify-content-between hover-gray">
                            <span>{{ x.user_name }} {{ x.e_mail }}
                                <b-badge v-if="x.is_admin>=1" variant="basic ml-1"> 
                                    <span v-if="x.is_admin>=3">{{ x.is_admin==4? '최고 관리자' :'캠페인 평가자' }}</span>
                                    <span v-else>{{ x.is_admin==2? '외부 평가자' :'캠페인 리더' }}</span>
                                </b-badge>
                            </span>
                            <b-form-checkbox v-if="is_admin==4" v-model="x.excluded" :value="false" :unchecked-value="true" :disabled="is_saving_exclude==true||item.all_users.length<=1||x.e_mail==e_mail" class="black-check"></b-form-checkbox> 
                        </div>
                    </b-td>         
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <div class="pt-3 pb-1 pr-1 text-right" v-if="exclude_save_mode==true"><b-button @click="handleSaveExcludedUsers" :variant="is_saving_exclude==true?'green':'blue-border'" size="sm"><fa icon="save" class="mr-1"></fa> 참여대상 저장</b-button></div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
export default { 
    data(){
        return {
            is_saving_exclude:false
        }
    },
    props:{
        notice: { type:Object, default: ()=>({}) },
        exclude_save_mode: {type: Boolean, default: false},
        save_excludes: {type: Boolean, default: false}
        // company_only: {type: Boolean, default: false}
    },
    computed: {
        campaign_id(){
            return this.$store.state.campaign.campaign_id
        },
        e_mail(){
            return this.$store.state.e_mail
        },
        campaign(){
            return this.$store.state.campaign
        },
        portfolio_companies() {
            return this.$store.state.portfolio.portfolio_target_companies
        },
        selected_company(){
            return this.$store.state.selected_company
        },
        is_admin() {
            return this.$store.getters.isAdmin
        },
    },
    methods: { 
        deleteCompany(deletable_company){
            this.$store.commit('delete_portfolio_target_company', deletable_company)
        },
        setNewPortfolio() {
            // let temp_target_comp = {}   
            // for (var i=0; i<this.notice.target_company.length; i++) { 
            //     temp_target_comp.company_id = this.notice.target_company[i].company_id
            //     temp_target_comp.company_name = this.notice.target_company[i].company_name,
            //     temp_target_comp.company_code = this.notice.target_company[i].company_code          
            // }
            // this.$store.commit('add_portfolio_target_company', temp_target_comp)
            // console.log('뉴 포폴 set' + this.portfolio_companies);
            
           
            // let temp_target_comp = {}   
            // for (var i=0; i<this.notice.target_company.length; i++) { 
            //     temp_target_comp.company_name = this.notice.target_company[i].company_name,
            //     temp_target_comp.company_code = this.notice.target_company[i].company_code          
            // }
            // console.log('객체 생성' + temp_target_comp);
            

            // let temp_portfolio_target_comp = []
            // for(var x=0; x<this.notice.target_company.length; x++) {       
            //     temp_portfolio_target_comp.push(temp_target_comp)
            // }
            // console.log('배열 생성' + temp_portfolio_target_comp);
            
            // -------------
            let temp_portfolio_target_comp = []

            if (this.notice.notice_id != undefined && this.notice.notice_id != '') {            
                for(var i=0; i<this.notice.target_company.length; i++) {
                    temp_portfolio_target_comp.push( {
                        company_id : this.notice.target_company[i].company_id,
                        company_name : this.notice.target_company[i].company_name,
                        company_code : this.notice.target_company[i].company_code
                    })
                }
                let temp_portfolio = {
                    portfolio_name : '',
                    portfolio_description : '',
                    portfolio_target_companies : temp_portfolio_target_comp
                }

                this.$store.commit('update_portfolio', temp_portfolio)
            }   
        },
        handleSaveExcludedUsers(){
            this.$emit('save-campaign')
        },
        saveExcludedUsers(){
            this.is_saving_exclude = true
            const path = this.$store.state.backend_host + '/save_excluded_user'
            
            let temp_user_list = []
            
            for(let i in this.portfolio_companies) {
                this.portfolio_companies[i].all_users.forEach(item => { 
                    if (item.excluded == true) {
                        temp_user_list.push(item.user_id) 
                    }
                })
            }
            // console.log(temp_user_list);

            let formData = new FormData()
            formData.append('campaign_id', this.campaign.campaign_id)
            formData.append('user_id_list', JSON.stringify(temp_user_list))

            return axios.post(path, formData)
            .then(response =>{
                // console.log(response);
                this.is_saving_exclude = false
                this.$emit('initiate_save_excludes')
                EventBus.$emit('toast-success', '알림', '참여대상을 저장하였습니다.')
            })
        },
        // disableCheck(){
        //     if(is_saving_exclude==true||item.all_users.length<=1){
        //         return true
        //     }
        // }
    },
    created(){
        // this.$store.commit('update_portfolio', { portfolio_name: '', portfolio_description: '', portfolio_target_companies: [] })
        this.setNewPortfolio()
    },
    watch:{
        save_excludes:{
            immediate: true,
            handler(newVal){
                // console.log(newVal);
                if (newVal==true){
                    this.saveExcludedUsers()
                }
            }
        }
       
    }
}
</script>

<style >
.hover-gray:hover{
    background-color: var(--gray-300);
}

</style>
