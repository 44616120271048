//import axios from 'axios'

const cr_scores = {
    namespaced: true,
    state: {
        all_factors: [],
        minus_factors: [],
        e_factors: [],
        s_factors: [],
        g_factors: [],
        r_factors: [],
        is_qe_factors_loaded: false,
        reporting_company: {
            category_score: {},
            company_id: 1,
            company_name: "QESG",
            release: false,
            required_info: {},
            score_contents:[],
            total_score: 0
        },
        company_info:{}
    },
    mutations: {
        update_qe_factors(state, new_array){            
            let temp_e = [...state.e_factors]
            temp_e = new_array.filter(x => x.category1_name=='환경')
            let temp_s = [...state.s_factors]
            temp_s = new_array.filter(x => x.category1_name=='인권 및 노동')
            let temp_g = [...state.g_factors]
            temp_g = new_array.filter(x => x.category1_name=='윤리 및 지배구조')
            let temp_r = [...state.r_factors]
            temp_r = new_array.filter(x => x.category1_name=='제품 및 서비스 책임')
            let temp_minus = [...state.minus_factors]
            temp_minus = new_array.filter(x => x.icon=='minus_factor')

            state.e_factors = temp_e
            state.s_factors = temp_s
            state.g_factors = temp_g
            state.r_factors = temp_r
            state.minus_factors = temp_minus
            state.all_factors = new_array
            state.is_qe_factors_loaded = true
        },
        update_report_company(state, new_company){
            state.reporting_company = new_company
        },
        update_company_info(state,new_info){
            state.company_info = new_info
        }

    },
    getters: {
        
    },
    actions: {
        readQeCrFactors({commit, rootState},{company_id, campaign_id}){
            const path = rootState.backend_host + '/read_qe_cr_factors'
            return axios.get(path, {params: { company_id: company_id, campaign_id: campaign_id}})
            .then(response => {
                commit('update_qe_factors', response.data)
            })
            .catch(error => { console.log(error)
            })
        },
        readCompanyInfo({commit, rootState}, company_id){
            const path = rootState.backend_host + '/read_company_info_tierzero'
            return axios.get(path, {params: {company_id: company_id}})
            .then(response => {
                commit('update_company_info', response.data)
            })
            .catch(error => { console.log(error)
            })
        }
    }
}
export default cr_scores
