<template>
<div>
    <b-row class="mb-4">
        <b-col class="col-2"> <h6 class="pb-1"> 리스트 이름 </h6></b-col>
        <b-col> <b-form-input :disabled="is_saving_portfolio==true||is_admin<4" type="text" class="" v-model="temp_portfolio.portfolio_name" placeholder="참여대상 그룹 리스트 명"></b-form-input> </b-col>
    </b-row>
    <b-row class="mb-4">
        <b-col class="col-2"> <h6 class="pb-1"> 리스트 설명 </h6></b-col>
        <b-col> <b-textarea  :disabled="is_saving_portfolio==true||is_admin<4" class="" v-model="temp_portfolio.portfolio_description" rows="3" placeholder="참여대상 그룹 구성 기준, 목적 등"></b-textarea> </b-col>
    </b-row>
    <b-row class="mb-3">
        <b-col class="col-2"> <h6 class="pb-1"> 참여대상 검색 </h6></b-col>
        <b-col> <company-finder :disabled="is_saving_portfolio==true||is_admin<4"></company-finder></b-col>
    </b-row>
    <!-- store의 portfolio에 있는 portfolio_target_companies의 길이를 가져와야 함 -->
    <div v-if="portfolio.portfolio_target_companies.length>0" class="">
        <b-card class="border-0 bgray-100">
            <div class="pb-2 text-center">총 <span class="f-120">{{ portfolio.portfolio_target_companies.length }}</span> 개사</div>
            <editing-portfolio :company_only="true"></editing-portfolio>
        </b-card>
    </div>

    <div class="text-right my-5">
        <span v-if="is_saving_portfolio==true" class="green f-80"> <b-spinner class="mx-2 p-1 my-1" small type="grow"></b-spinner>저장 중입니다. </span>
        <b-button :disabled="is_saving_portfolio==true||is_admin!=4" class="ml-3" @click="savePortfolio()" :variant="is_saving_portfolio==true?'green':'blue'"><fa icon="save" class="mr-1"></fa> 저장하기 </b-button>
        <!-- <b-button :disabled="is_saving_portfolio==true" class="ml-3" @click="deletePortfolio()" variant="red">삭제하기</b-button> -->
        <b-button :disabled="is_saving_portfolio==true||is_admin!=4" class="ml-3" @click="cancelEdit" variant="dark"> 취소 </b-button>
    </div>

    <hr class="border-0 bottom-space">
</div>
</template>

<script>
import CompanyFinder from '../managing_user/CompanyFinder'
import EditingPortfolio from './EditingPortfolio'
import * as ih from '@/util'

export default {
    data() {
        return {
            is_portfolio_determined : false,
            is_saving_portfolio: false,
            temp_portfolio : {
                portfolio_id: 0,
                portfolio_name: '',
                portfolio_description: '',
                portfolio_target_companies: []
            }
        }
    },
    props: {
        value_new : { type: Boolean, default: false }
    },
    computed: {
        portfolio() {
            return this.$store.state.portfolio
        },
        selected_company(){
            return this.$store.state.selected_company
        },
        is_admin() {
            return this.$store.getters.isAdmin
        },
    },
    methods:{
        cancelEdit() {
            this.$emit('stop-edit')
            this.initiatePortfolio()
        },      
        savePortfolio(){
            if (this.checkForm()!=false){
                this.is_saving_portfolio = true
                const new_portfolio = {
                    portfolio_id: this.temp_portfolio.portfolio_id,
                    portfolio_name: this.temp_portfolio.portfolio_name,
                    portfolio_description: this.portfolio.portfolio_description,
                    portfolio_target_companies: this.portfolio.portfolio_target_companies
                }
                // console.log(new_portfolio)
                this.$store.dispatch('savePortfolio', new_portfolio)
                .then( () => {
                    this.is_saving_portfolio = false
                    this.$emit('read-again')
                    EventBus.$emit('toast-success', '알림', '포트폴리오 저장에 성공하였습니다.')
                })
            }
        },
        // deletePortfolio(){

        // },
        initiatePortfolio(){
            const new_portfolio = {
                portfolio_id: 0,
                portfolio_name: '',
                portfolio_description: '',
                portfolio_target_companies: []
            }
            this.$store.commit('update_portfolio', new_portfolio)
            this.temp_portfolio = ih.deepCopy(this.portfolio)
        },
        checkForm(){
            if (this.temp_portfolio.portfolio_name == ''){
                alert('리스트 이름을 입력하여 주십시오.')
                return false
            }
            else if (this.portfolio.portfolio_target_companies.length<1){
                alert('참여대상을 검색 후 선택하여 주십시오.')
                return false
            }
            else return true
        }
    },
    created() {
        if(this.value_new == true){
            this.initiatePortfolio()
        }
        else {
            this.temp_portfolio = ih.deepCopy(this.portfolio)
        }
    },
    beforeDestroy(){
        this.initiatePortfolio()
    },
    watch: {
        portfolio:{
            immediate:true,
            handler(newVal){
                this.temp_portfolio = ih.deepCopy(this.portfolio)
            }
        },
        value_new:{
            immediate:true,
            handler(newVal){
                if(newVal==true){
                    this.initiatePortfolio()
                }
            }

        }
    },
    components: {
        CompanyFinder,
        EditingPortfolio
    }

}
</script>

<style scoped>

</style>
