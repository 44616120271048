<template>
<div class="editing-dfs">
        <!--제목, 컴포넌트 기본 공통 -->
        <div class="p-2 bgray-200 rounded d-flex justify-content-between align-items-center border">
            <div class="d-flex align-items-center">
                <span class="mx-3 f-110">{{ sub_code }}</span>
                <b-dropdown :text="sub_type" variant="dark-border" >
                    <b-dropdown-item-button @click="selectType(item)" v-for="item in sub_types" :key="item.id" > {{ item }} </b-dropdown-item-button>
                </b-dropdown>
            </div>
            <div class="d-flex align-items-center">             
                <b-form-checkbox v-model="sub_data_header.required">필수 응답(Public ONLY)</b-form-checkbox>
                <span class="mx-3 gray-600"> |</span>
                <b-dropdown :text="scoring" size="sm" variant="dark-border">
                    <b-dropdown-item @click="setScoring('none')" >No Scoring</b-dropdown-item>
                    <b-dropdown-item @click="setScoring('auto')">Auto Scoring</b-dropdown-item>
                </b-dropdown>
            </div>
        </div>

        <div class="mt-3 mb-1 fw-600 f-90">Title</div>
        <b-input v-model="sub_name" placeholder="Sub name"> {{ sub_name }} </b-input>

        <div class="mt-3 mb-1 fw-600 f-90">Description</div>
        <b-textarea v-model="sub_description" placeholder="A1. ㅇㅇㅇ을 관리하고 있습니까?"> {{ sub_description }} </b-textarea>

        <div class="mt-3 mb-1 fw-600 f-90">Info</div>
        <b-textarea v-model="sub_info" placeholder="용어설명, 참고사항, 평가기준 등 답변 참고정보" > {{ sub_info }} </b-textarea>

        <div class="mt-3 mb-1 fw-600 f-90" style="cursor:pointer" @click="conditionPopoverToggle()">Condition <fa :id="`sub_condition_information+${sub_code}`" icon="info-circle"></fa></div>
            <b-popover :target="`sub_condition_information+${sub_code}`" title="설명" :content="sub_condition_information" placement="left" :show="sub_condition_popover_show">
            </b-popover>
        <div  id="editing-template-condition">
            <b-form-checkbox v-model="is_condition_available" @input="manageConditionAvailability()" class="editing-dfs">
                <b-form-input placeholder="선행조건 표시내용을 입력해 주세요" v-model="sub_condition.text"> {{ sub_condition.text }} </b-form-input>
            </b-form-checkbox>      
        </div>

        <div class="mt-3 mb-1 fw-600 f-90">Not Applicable</div>
        <b-form-checkbox v-model="sub_data_header.enable_na" class="blue">선택지에 '해당없음' 추가</b-form-checkbox>


        <div class="mt-3 mb-1 fw-600 f-90" style="cursor:pointer" @click="contentsPopoverToggle()">Structure  <fa :id="`sub_data_contents_information+${sub_code}`" icon="info-circle"></fa></div>
            <b-popover :target="`sub_data_contents_information+${sub_code}`" title="정의"  :content="sub_data_contents_information" placement="left" :show="sub_data_contents_popover_show">
            </b-popover>
        <!-- Radio Structure -->
        <b-table-simple class="bor-table">
            <b-thead>
            <b-tr>
                <b-td></b-td> <b-td> text </b-td><b-td> value </b-td> <b-td> 세부요소 </b-td><b-td v-if="sub_data_header.scoring=='auto'">배점(총 10점)</b-td> <b-td> 삭제 </b-td>
            </b-tr>
            </b-thead>
            <b-tbody v-for="(item, index) in sub_data_contents.structure" :key="item.id">
                <b-tr>
                    <b-td> <b-form-radio :disabled="true"></b-form-radio></b-td>
                    <b-td> <b-form-input v-model="item.text">{{ item.text }}</b-form-input></b-td>
                    <b-td> <b-form-input v-model="item.value">{{ item.value }}</b-form-input></b-td>
                    <b-td> <b-button @click="addElement(item)" variant="teal-border" size="sm"><fa icon="plus"></fa></b-button> </b-td>
                    <b-td v-if="sub_data_header.scoring=='auto'"> <b-form-input type='number' v-model="item.score"></b-form-input></b-td>
                    <b-td> <b-button @click="sub_data_contents.structure.splice(index,1)" variant="dark-border" size="sm"><fa icon="trash-alt"></fa></b-button></b-td>
                </b-tr>
                <b-tr v-for="(element_item, element_idx) in item.element_contents" :key="element_item.id">
                    <b-td></b-td>
                    <b-td colspan="3">
                        <editing-df-element :item_idx="index" :element_idx="element_idx" :element_item="element_item" @change="updateElementItem"></editing-df-element> 
                    </b-td>
                    <b-td><b-button @click="deleteElement(index, element_idx)" variant="dark-border" size="sm"><fa icon="trash-alt"></fa></b-button></b-td>
                    <!-- <b-td><b-button @click="item.element_contents.splice(element_idx, 1)" variant="dark-border" size="sm"><fa icon="trash-alt"></fa></b-button></b-td> -->
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <div class="d-flex justify-content-between">
            <b-form-checkbox v-model="sub_data_header.horizontal" class="blue">선택지를 가로방향으로 표시</b-form-checkbox>
            <b-button @click="addRadio()" variant="blue" size="sm">라디오 선택지 추가</b-button>
        </div>

        <!-- Tags, Child 등 기본 공통 -->
        <div class="mt-4 d-flex editing-dfs">
            <span class="fw-600 mr-5 f-90">Tags </span>
            <b-input v-model="tags" placeholder="띄어쓰기 대신 _ 사용, 쉼표로 구분"> {{ tags }}</b-input>
        </div>

        <div v-if="checkAbleChild" class="mt-3 mb-4 d-flex">
            <span class="fw-600 mr-5 f-90">Child</span>
            <div class="bgray-200 w-100 p-2 rounded "><b-form-checkbox v-model="has_child" @change="handleHasChild" >파일 및 URL 첨부</b-form-checkbox></div>
        </div>

        <div class="mt-4 d-flex editing-dfs">
            <span class="fw-600 mr-5 f-90">Priority</span>
            <b-form-select v-model="sub_priority" :options="sub_priority_option"></b-form-select>
        </div>

        <div class="float-right">
            <b-button @click="handleSaveSub()" variant="blue" class="mr-2">저장</b-button>
            <b-button @click="$emit('click-cancel')" variant="dark">취소</b-button>
        </div>


</div>
</template>

<script>
import { EditingSubComponentMixin } from './EditingSubComponentMixin'
import EditingDFElement from './element/EditingDFElement'
import * as ih from '@/util'

export default {
    mixins: [EditingSubComponentMixin],
    data() {
        return {
            sub_data_contents_information: `text: 라디오 선택지 옆에 표시될 텍스트를 의미합니다. 
                value: 라디오 선택지를 선택했을 경우 저장될 값을 의미합니다. 'text'와 같을 수 있습니다. `
        }
    },
    computed: {
    },
    methods: {
        addRadio(){
            let new_radio_item = {
                text: '',
                value: ''
            }
            if(!this.sub_data_contents.structure) {
                Vue.set(this.sub_data_contents, 'structure', [])
            }
            this.sub_data_contents.structure.push(new_radio_item)
        },
        handleSaveSub(){
            this.saveSub()
            .then(this.$emit('click-save'))
        },
        updateElementItem(item_index, element_idx, new_item){
            console.log('event-catch' + item_index + element_idx + new_item )
            this.sub_data_contents.structure[item_index].element_contents[element_idx] = new_item
            console.log('저장형태결론')
            console.log(this.sub_data_contents)
        },
        addElement(item){
            let new_item = {
                type: '',
                result: '',
                structure: []
            }
            if(item.hasOwnProperty('element_contents')==false){
                Vue.set(item, 'element_contents', [])
            }
            item.element_contents.push(new_item)
        },
        deleteElement(index, element_idx){
            let temp_element = {...this.sub_data_contents.structure[index]}
            temp_element.element_contents.splice(element_idx, 1)
            // console.log(temp_element);
            this.sub_data_contents.structure[index] = ih.deepCopy(temp_element)
        }
    },
    watch: {

    },
    created(){
 
    },
    components:{
        'editing-df-element': EditingDFElement
    }
}
</script>