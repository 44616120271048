<template>
<div>
    <b-container fluid class="sm text-left">
    <b-row>
        <b-col class="col-12 px-0 mb-1 d-flex align-items-end justify-content-between">
            <div>
                <name-plate
                :user_id="c_item.author_id"
                :user_name="c_item.author_name"
                class="px-1"
                >
                </name-plate>
                <span class="ml-1 f-80 text-black-50"> {{ c_item.time_stamp }}</span><span v-if="c_item.is_new" class="red">New</span>
             </div>
            <!-- 일반 저장로직 -->
            <div v-if="!is_IE" class="px-0">
                <b-button variant="icon" v-if="e_mail==c_item.author_e_mail && is_modify!=true" @click.stop="modifyComment()" size="sm"><fa icon="pen"></fa></b-button>
                <b-button variant="icon" v-else-if="e_mail==c_item.author_e_mail && is_modify==true" @keyup.enter.stop.prevent="saveComment(c_item.author_e_mail, c_item.question_code, new_comment_contents, c_item.comment_code)" @click.stop="saveComment(c_item.author_e_mail, c_item.question_code, new_comment_contents, c_item.comment_code)" size="sm"><fa icon="save"></fa></b-button>
                <b-button variant="icon" v-if="e_mail==c_item.author_e_mail" @click.stop.prevent="deleteComment(c_item.comment_code)" size="sm"><fa icon="trash-alt"></fa></b-button>
            </div>
            <!-- IE일 경우 저장로직 -->
            <div v-else>
                <b-button variant="icon" v-if="e_mail==c_item.author_e_mail && is_modify!=true" @click.stop="IEModifyComment()" size="sm"><fa icon="pen"></fa></b-button>
                <b-button variant="icon" v-else-if="e_mail==c_item.author_e_mail && is_modify==true" @keyup.enter.stop.prevent="IESaveComment(c_item.author_e_mail, c_item.question_code, IE_new_comment_contents, c_item.comment_code)" @click.stop="IESaveComment(c_item.author_e_mail, c_item.question_code, IE_new_comment_contents, c_item.comment_code)" size="sm"><fa icon="save"></fa></b-button>
                <b-button variant="icon" v-if="e_mail==c_item.author_e_mail" @click.stop.prevent="deleteComment(c_item.comment_code)" size="sm"><fa icon="trash-alt"></fa></b-button>
            </div>
        </b-col>
    </b-row>
    <b-row class="mb-3">
        <b-col class="p-0 col-12">
            <div v-if="is_IE && is_modify">
                <b-textarea ref="ie_comment_textarea" v-model="IE_new_comment_contents"></b-textarea>
            </div>
            <!-- IE일 경우 text editing -->
            <div v-else>
                <editor-content :id="`comment_editor_${c_item.comment_code}`" :ref="`editor_content_${c_item.comment_code}`" class="f-90 box-sm-shadow comment_editor_content" :editor="comment_editor"></editor-content>
            </div>
        </b-col>
        <!-- <b-col v-if="!is_IE" class="px-0">
            <b-button class="float-right" variant="icon" v-if="e_mail==c_item.author_e_mail" @click.stop="deleteComment(c_item.comment_code)" size="sm"><fa icon="trash-alt"></fa></b-button>
            <b-button class="float-right" variant="icon" v-if="e_mail==c_item.author_e_mail && is_modify!=true" @click.stop="modifyComment()" size="sm"><fa icon="pen"></fa></b-button>
            <b-button class="float-right" variant="icon" v-else-if="e_mail==c_item.author_e_mail && is_modify==true" @keyup.enter="saveComment(c_item.author_e_mail, c_item.question_code, new_comment_contents, c_item.comment_code)" @click.stop="saveComment(c_item.author_e_mail, c_item.question_code, new_comment_contents, c_item.comment_code)" size="sm"><fa icon="save"></fa></b-button>
        </b-col> -->

        <!-- IE일 경우 저장로직 추가 -->
        <!-- <b-col v-else class="px-0">
            <b-button class="float-right" variant="icon" v-if="e_mail==c_item.author_e_mail" @click.stop="deleteComment(c_item.comment_code)" size="sm"><fa icon="trash-alt"></fa></b-button>
            <b-button class="float-right" variant="icon" v-if="e_mail==c_item.author_e_mail && is_modify!=true" @click.stop="IEModifyComment()" size="sm"><fa icon="pen"></fa></b-button>
            <b-button class="float-right" variant="icon" v-else-if="e_mail==c_item.author_e_mail && is_modify==true" @keyup.enter="IESaveComment(c_item.author_e_mail, c_item.question_code, IE_new_comment_contents, c_item.comment_code)" @click.stop="IESaveComment(c_item.author_e_mail, c_item.question_code, IE_new_comment_contents, c_item.comment_code)" size="sm"><fa icon="save"></fa></b-button>
        </b-col> -->
    </b-row>
    </b-container>

    <div class="suggestion-list" v-show="showSuggestions" ref="suggestions">
      <template v-if="hasResults">
        <div
          v-for="(user, index) in filteredUsers"
          :key="index"
          class="suggestion-list_item"
          :class="{ 'is-selected': navigatedUserIndex === index }"
          @click="selectUser(user)"
        >
          <span>{{ user.face }}</span><span v-if="user.e_mail != undefined">({{user.e_mail}})</span>
        </div>
      </template>
      <div v-else class="suggestion-list_item is-empty">
        유저가 없습니다
      </div>
    </div>

</div>
</template>
<script>
import Fuse from 'fuse.js'
import tippy, { roundArrow }  from 'tippy.js'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import 'tippy.js/animations/shift-away.css'
import '../../../assets/tiptap_editor.scss'
import * as hangul from 'hangul-js'

import {
  HardBreak,
  Heading,
  Mention,
  Code,
  Bold,
  Italic,
} from 'tiptap-extensions'

export default {
    data(){
        return {
            is_modify: false, 
            IE_new_comment_contents: '',

            comment_editor: new Editor({
                content: '테스트코멘트 입니다.',
                editable: false,
                extensions: [
                    new HardBreak(),
                    new Heading({ levels: [1, 2, 3]}),
                    new Code(),
                    new Mention({
                        //a list of all suggested items
                        items: () => {
                            return this.prepareSuggestions()
                        },
                        //is called when a suggestion starts

                        onEnter: ({
                            items, query, range, command, virtualNode,
                        }) => {
                            this.query = query
                            this.filteredUsers = items
                            this.suggestionRange = range
                            this.renderPopup(virtualNode)
                            // 선택한 맨션을 추가하는 명령을 저장
                            // 키보드, 마우스 액션을 통해 커스텀 팝업에서 불러옴
                            this.insertMention = command
                        },
                        // suggestion이 바뀌었을 때 불러오기
                        onChange: ({
                            items, query, range, virtualNode,
                        }) => {
                            this.query = query
                            this.filteredUsers = items
                            this.suggestionRange = range
                            this.navigatedUserIndex = 0
                            this.renderPopup(virtualNode)
                        },
                        // suggestion이 캔슬되었을 때
                        onExit: () => {
                            // 모든 저장된 값을 리셋함
                            this.query = null
                            this.filteredUsers = []
                            this.suggestionRange = null
                            this.navaigatedUserIndex = 0
                            this.destroyPopup()
                        },
                        // 키를 누를 때
                        onKeyDown: ({ event }) => {
                            //화살표 윗키
                            if(event.keyCode === 38) {
                                this.upHandler()
                                return true
                            }
                            //화살표 아래키
                            if(event.keyCode === 40) {
                                this.downHandler()
                                return true
                            }
                            //엔터키
                            if(event.keyCode === 13) {
                                this.enterHandler()
                                return true
                            }
                            return false
                        },
                        //suggestion이 변화가 생겼을 때 불러오는 함수
                        //fuse.js를 통해 fuzzy 서치를 진행
                        onFilter: (items, query) => {
                            if(!query) {
                                return items
                            }

                            const fuse = new Fuse(items, {
                                shouldSort: true,
                                threshold: 0.2,
                                location: 0,
                                distance: 100,
                                maxPatternLength: 32,
                                minMatchCharLength: 1,
                                keys: [
                                    "face", "e_mail"
                                ]
                            })

                            return fuse.search(query)
                        }
                    }),
                    new Bold(),
                    new Italic()
                ],
            }),
            query: null, 
            suggestionRange: null,
            filteredUsers: [],
            navigatedUserIndex: 0,
            insertMention: () => {},
            observer: null,
            tippy_popup: null,
        }
    },
    props: {
        c_item: {
            type: Object
        }
    },
    computed: {
        all_space() {
            return this.$store.getters.getAllSpace
        },
        user_name() {
            return this.$store.getters.getUserName
        },
        company_name() {
            return this.$store.getters.getCompanyName
        },
        e_mail(){
            return this.$store.getters.getEMail
        },
        user_id(){
            return this.$store.state.user_id
        },
        isAuthenticated(){
            return this.$store.getters.isAuthenticated
        },
        isLoggedIn() {
            return this.$store.getters.isLoggedIn
        },
        is_admin(){
            return this.$store.getters.isAdmin
        },
        question_in_progress() {
            return this.$store.state.question_in_progress
        },
        question_code(){
            return this.$store.state.question_in_progress.question_code
        },
        hasResults() {
            return this.filteredUsers.length
        },
        showSuggestions() {
            return this.query || this.hasResults
        },
        new_comment_contents() {
            return this.comment_editor.getJSON()
        },
        is_IE() {
            let agent = navigator.userAgent.toLowerCase()
            if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (agent.indexOf("msie") != -1) )
                {
                    return true
                }
            else
                { 
                    return false
                }
        }
    },
    methods: {
        modifyComment(){
            this.is_modify = true
            this.comment_editor.setOptions({ editable : true })
            this.comment_editor.focus()
        },
        IEModifyComment(){
            this.is_modify = true
            this.IE_new_comment_contents = this.comment_editor.getHTML().replace(/(<([^>]+)>)/gi,'')
            this.$nextTick( this.$refs['ie_comment_textarea'].focus() )
        },
        saveComment(e_mail, question_code, contents, comment_code){
            this.c_item.contents = contents
            EventBus.$emit('save-comment', e_mail, question_code, contents, comment_code)
            this.is_modify = false
            
            this.comment_editor.setOptions({ editable : false })
            this.comment_editor.blur()
        },
        IESaveComment(e_mail, question_code, IE_new_comment_contents, comment_code){
            //'@'으로 시작하는 문구가 있는지 조사. 정규표현식의 의미는, @으로 시작하고 단어가 끝나는 곳 까지 매치되는 문자열을 배열로 저장
            let mentions_list =  IE_new_comment_contents.match(/(@[a-z가-힣0-9]*)/gim) 
            let matched_list = []

            //null 에러방지
            if(mentions_list == null) mentions_list=[]
            
            this.all_space.forEach(space_item => {
                mentions_list.forEach(mention_item => {
                    if(mention_item.match(space_item.face)){
                        matched_list.push(space_item)
                    }
                })
            })

            //이제 matched_list는 all_space의 하위타입 배열로서, comment에 삽입되어야 할 mention 아이템을 의미함. 
            //contents에서 mention 아이템을 교체해서 심어줌. 
            matched_list.forEach(item => {
                let leg_exp = new RegExp('(?!>)@' + item.face + '(?!<)')
                IE_new_comment_contents = IE_new_comment_contents.replace(leg_exp, `<span class="mention" data-mention-id='${item.id}' mode='${item.mode}'>@${item.face}</span>`)
            })

            // 줄바꿈 문자를 <br>로 치환하고, 앞 뒤로 <p> 태그 붙임
            IE_new_comment_contents = IE_new_comment_contents.replace(/\r\n/g,'<br>')
            IE_new_comment_contents = '<p>'+IE_new_comment_contents + '</p>'

            this.comment_editor.setContent(IE_new_comment_contents)
            this.saveComment(e_mail, question_code, this.comment_editor.getJSON(), comment_code)
            this.IE_new_comment_contents=''
        },
        deleteComment(comment_code){
            EventBus.$emit('delete-comment',comment_code)
        },
        //윗 유저로 이동
        upHandler() {
            this.navigatedUserIndex = ((this.navigatedUserIndex + this.filteredUsers.length) - 1) % this.filteredUsers.length
        },

        //다음 유저로 이동. 마지막 유저일 경우 처음으로 이동
        downHandler() {
            this.navigatedUserIndex = (this.navigatedUserIndex + 1) % this.filteredUsers.length
        },

        //엔터 키 쳤을 경우
        enterHandler() {
            const user = this.filteredUsers[this.navigatedUserIndex]
            if (user) {
                this.selectUser(user)
            }
        }, 

        //우리가 추천한 것을 멘션을 포함한 텍스트로 변동시켜야 함
        selectUser(user){
            this.insertMention({
                range: this.suggestionRange,
                attrs: {
                    id: user.id,
                    label: user.face
                }
            })
            this.comment_editor.focus()
        },

        //suggestion popup
        renderPopup(node){
            if(this.tippy_popup) {
                return
            }

            console.log(node)

            this.tippy_popup = tippy(`#comment_editor_${this.c_item.comment_code}`, {                
                lazy:false, 
                onTrigger(instance) {
                    instance.popperInstance.reference = node
                },
                content: this.$refs.suggestions,
                trigger: 'mouseenter',
                interactive: true,
                theme: 'dark',
                placement: 'bottom',
                inertia: true,
                duration: [400, 200],
                showOnCreate: true,
                arrow: true,
                animation: 'shift-away',
                popperOptions: {
                    positionFixed: true,
                },
            })

            console.log(this.tippy_popup)

            //DOM 변경될 때마다 tippy를 업데이트
            if(MutationObserver) {
                this.observer = new MutationObserver( ()=> {
                    this.tippy_popup[0].popperInstance.scheduleUpdate()
                })
                this.observer.observe(this.$refs.suggestions, {
                    childList: true,
                    subtree: true,
                    characterData: true
                })
            }
        },
        destroyPopup(){
            if(this.tippy_popup){
                this.tippy_popup[0].destroy()
                this.tippy_popup = null
            }

            if (this.observer){
                this.observer.disconnect()
            }
        },
        prepareSuggestions(){
            console.log(this.all_space)
            return this.all_space
        },
    },
    created() {
        this.comment_editor.setContent(this.c_item.contents)
    },
    beforeDestroy(){
        this.comment_editor.destroy()
    },
    components: {
        Editor, 
        EditorContent,
        EditorMenuBar
    }
}
</script>