<template>
<div>
    <b-container v-if="progress_load_indicator == true" class="text-left">
        <b-button-group class="mb-5">
            <b-button :pressed="display_filled_rate==false" @click="changeProgressMode(false)" variant="mode" class="f-110">{{$t(`제출률`)}}</b-button>
            <b-button :pressed="display_filled_rate==true" @click="changeProgressMode(true)" variant="mode" class="f-110">{{$t(`진행률`)}}</b-button>
        </b-button-group>        

        <h5 class="mb-3 fw-600"> {{$t(`전체 기업`)}} {{ display_filled_rate ? $t(`진행률`) : $t(`제출률`) }} </h5>

        <section class="mb-5">
            <template v-if="display_filled_rate==false">
                <b-progress class="thin-progress" height="1rem"><b-progress-bar :value="total_avg_progress" ></b-progress-bar></b-progress>
                <div class="top-tri blue" :style="{marginLeft: total_avg_progress - 0.5 + '%'}"><span class="f-160 fw-500" style="margin-left:-8px;">{{Math.floor(total_avg_progress)}}</span>%</div>
            </template>
            <template v-else>
                <b-progress class="thin-progress" height="1rem"><b-progress-bar :value="total_avg_filled_rate"></b-progress-bar></b-progress>
                <div class="top-tri blue" :style="{marginLeft: total_avg_filled_rate -0.5 + '%'}"><span class="f-160 fw-500" style="margin-left:-8px;">{{Math.floor(total_avg_filled_rate)}}</span>%</div>
            </template>
        </section>

        <div class="d-flex justify-content-between align-items-center mb-4">
            <h5 class="mb-0 fw-600">  {{$t(`기업별`)}} {{ display_filled_rate? $t(`진행률`) : $t(`제출률`)}} </h5>
            <b-button-group class="">
                <b-button :pressed="is_summary_mode_overall==true" @click="is_summary_mode_overall=true" variant="mode" class=""> {{$t(`종합`)}} </b-button>
                <b-button :pressed="is_summary_mode_overall==false" @click="is_summary_mode_overall=false" variant="mode" class=""> {{$t(`상세보기`)}} </b-button>
            </b-button-group>      
        </div>

        <div v-if="is_summary_mode_overall==true">
            <section class="d-flex align-items-stretch">
                <b-table-simple responsive hover class="rep-table">
                    <b-thead>
                        <b-tr>
                            <b-td class="border-top-dark-bold" style="width: 15%;" rowspan="2">{{$t(`기업명`)}} </b-td>
                            <b-td class="border-top-dark-bold" style="width: 30%;" rowspan="2"> {{ display_filled_rate? $t(`진행률`) : $t(`제출률`)}}
                                <fa @click="toggleSorted(display_filled_rate, 'sort-down')" v-if="sorted=='sort'" icon="sort" class="gray-500" style="cursor:pointer;"></fa>
                                <fa @click="toggleSorted(display_filled_rate, 'sort')" v-else-if="sorted=='sort-up'" icon="sort-up" class="gray-500" style="cursor:pointer;"></fa>
                                <fa @click="toggleSorted(display_filled_rate, 'sort-up')" v-else-if="sorted=='sort-down'" icon="sort-down" class="gray-500" style="cursor:pointer;"></fa></b-td>
                            <b-td class="border-top-dark-bold" :colspan="category_names.length">{{$t(`문항 부문별`)}} {{ display_filled_rate? $t(`진행률`) : $t(`제출률`) }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td :style="'width:'+55 / category_names.length+'%'" v-for="item in category_names" :key="item.id" class="f-95">{{ item }}</b-td>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <!-- 제출률 -->
                        <template v-if="display_filled_rate==false">  
                            <b-tr v-for="item in temp_target_companies" :key="item.id">
                                <b-td class="f-90 gray-800">  {{ item.company_name }} </b-td>
                                <b-td> 
                                    <b-progress :class="item.total_progress==100? 'thin-progress': 'category-progress'" height="0.9rem"><b-progress-bar :value="item.total_progress" :label="`${Math.floor(item.total_progress)}%`"></b-progress-bar><span v-if="item.hasOwnProperty('has_started') && item.has_started == false" v-b-tooltip.hover.right="'현재까지 한번도 질문지에 접속하지 않은 기업입니다.'" class="pl-1 f-80 cursor"><fa icon="exclamation-circle"></fa> {{$t(`시작안함`)}}</span></b-progress>
                                </b-td>
                                <b-td v-for="(category_item, index) in item.category_progress" :key="category_item + index">
                                    <span class="f-90">{{ Math.round(category_item).toFixed(0)}} %</span>
                                </b-td>                        
                            </b-tr>                            
                        </template>
                        <!-- 진행률 -->
                        <template v-else>
                            <b-tr v-for="item in temp_target_companies" :key="item.id">
                                <b-td class="f-90 gray-800"> {{ item.company_name }} </b-td>
                                <b-td>
                                    <b-progress class="thin-progress" height="0.9rem">
                                        <b-progress-bar :value="item.total_filled_rate" :label="`${Math.floor(item.total_filled_rate)}%`"></b-progress-bar>
                                        <span v-if="item.hasOwnProperty('has_started') && item.has_started == false" v-b-tooltip.hover.right="$t(`현재까지 한번도 질문지에 접속하지 않은 기업입니다.`)" class="pl-1 f-80 cursor"><fa icon="exclamation-circle"></fa> {{$t(`시작안함`)}}</span>
                                    </b-progress>
                                </b-td>
                                <b-td v-for="(category_item, index) in item.category_filled_rate" :key="item.category_item + index">
                                    <span class="f-90">{{ Math.round(category_item).toFixed(0)}} %</span>
                                </b-td>                      
                            </b-tr>                            
                        </template>
                    </b-tbody>           
                </b-table-simple>
                
            </section>
        </div> 
        <!-- 종합 끝 상세보기 시작 end of is_summary_mode_overall == true -->
        <!-- 종합 끝 상세보기 시작 end of is_summary_mode_overall == true -->
        <!-- 종합 끝 상세보기 시작 end of is_summary_mode_overall == true -->
        <!-- 종합 끝 상세보기 시작 end of is_summary_mode_overall == true -->
        
        <div v-else> 
            <section class="py-4 mb-5 bgray-100 d-flex align-items-stretch">
                <b-col class="col-3 col-xl-2 px-3 border-right">
                    <b-input-group> 
                        <b-form-input v-model="search_keyword" :placeholder="$t(`기업 검색`)"></b-form-input>
                        <b-input-group-append><b-button variant="icon-append"><fa icon="search"></fa></b-button></b-input-group-append>
                    </b-input-group>

                    <h6 class="mt-5 pb-1 border-bottom f-90 fw-500 gray-500">{{$t(`전체 기업`)}} ({{ temp_target_companies.length }}{{$t(`개사`)}}) </h6>
                    <div class="overflow-auto" style="max-height: 800px;">
                        <b-button block :variant="item.company_name == temp_target_companies[idx].company_name ?'icon-blue':'icon'" v-for="(item, index) in filtered_companies" :key="index" 
                        class="f-90 m-0 py-1 px-2 text-left overflow-hidden no-shadow" @click="selectCompany(item)"
                        > {{ item.company_name }}
                        </b-button>
                    </div>
                </b-col>
                <b-col class="col-9 col-xl-10 px-md-4">
                    <div class="f-110 fw-600 pb-2 border-bottom"> {{ temp_target_companies[idx].company_name }} {{ display_filled_rate? $t(`진행률`) : $t(`제출률`)}}</div>

                    <div v-if="display_filled_rate==true" class="d-flex flex-wrap justify-content-around align-items-center my-4">
                        <div class="total-progress-charter">
                            <progress-chart :category_name="`전체진행률`" :progress_rate="temp_target_companies[idx].total_filled_rate" :background_color="company_total_dounut" :key="re_total" class="total-progress-charter"></progress-chart>
                            <div :class="[temp_target_companies[idx].total_filled_rate > 0 ? 'blue':'gray-600','total-progress-dounut-inner']"> 
                                <span class="f-160 fw-600">{{ Math.round(temp_target_companies[idx].total_filled_rate)}}</span>% 
                            </div>                       
                        </div>
                        <div class="progress-charter" v-for="(item, index) in temp_target_companies[idx].category_filled_rate" :key="index">
                            <progress-chart :category_name="index" :progress_rate="item" :background_color="company_category_dounut" :key="re_category" class="progress-charter"></progress-chart>
                            <div :class="[ item > 0 ? 'blue5':'gray-600', 'progress-dounut-inner']"> 
                                <span class="f-120 fw-600">{{ Math.round(item) }}</span>%
                            </div>        
                        </div>
                        
                    </div>
                    <div v-else class="d-flex flex-wrap justify-content-around align-items-center my-4">
                        <div class="total-progress-charter">
                            <progress-chart :category_name="`전체제출률`" :progress_rate="temp_target_companies[idx].total_progress" :background_color="company_total_dounut" :key="re_total" class="total-progress-charter"></progress-chart>
                            <div :class="[temp_target_companies[idx].total_progress > 0 ? 'blue':'gray-600','total-progress-dounut-inner']"> 
                                <span class="f-160 fw-600">{{ Math.round(temp_target_companies[idx].total_progress) }}</span>% 
                            </div>                       
                        </div>
                        <div class="progress-charter" v-for="(item, index) in temp_target_companies[idx].category_progress" :key="index">
                            <progress-chart :category_name="index" :progress_rate="item" :background_color="company_category_dounut" :key="re_category" class="progress-charter"></progress-chart>
                            <div :class="[ item > 0 ? 'blue5':'gray-600', 'progress-dounut-inner']"> 
                                <span class="f-120 fw-600">{{ Math.round(item) }}</span>%
                            </div>        
                        </div>
                    </div>
                    <tree :data="temp_target_companies[idx].progress_contents" :prevent-select="false" :draggable="false" :space="1" :indent="0" class="px-2 px-lg-4 pb-4 clearfix overflow-auto">
                    <template v-slot="{data}">
                        <b-row
                        :class="[`node-level-${data._vm.level}`, 'mx-0 text-left border-bottom d-flex align-items-stretch']" style="min-width:400px;">
                            <b-col :class="[`node-level-${data._vm.level}-sub`, 'col-7 col-md-8 py-1 pr-0 d-flex align-items-center']"> {{ data.question_name }} </b-col>
                            <b-col class="col-auto d-flex align-items-center text-center">
                                <!-- 진행률 -->
                                <div v-if="display_filled_rate==true">
                                    <span>{{ data.num_of_filled }} / {{data.num_of_subs_init }}</span>
                                </div>
                                <!-- 제출률 -->
                                <div v-else>
                                    <span v-if="checkCompleted(data)!=''" :class ="[ checkCompleted(data) != '완료' ? 'on-completed':'on-track','fw-600']"> {{ checkCompleted(data) }} </span>
                                    <fa v-if="checkCompleted(data)!='' && checkCompleted(data)== '완료'" :icon="['far','check-circle']" class="ml-1 gray-600"></fa>
                                    <span v-else style="display: none;"></span>
                                </div>
                            </b-col>
                        </b-row>
                    </template>
                    </tree>
                </b-col>
            </section>
        </div> <!-- end of is_summary_mode_overall == false -->

    </b-container>
    <b-container v-else>
        <b-card class="my-5 border-0 blue text-center align-items-center"> <b-spinner class="mx-2 p-1 my-1" small type="grow"></b-spinner>{{$t(`진행률을 가져오고 있습니다`)}}... </b-card>
    </b-container>
    <hr class="border-0 bottom-space">

</div>
</template>

<script>
import CompanyFinder from '../managing_user/CompanyFinder'
import ProgressChart from '@/components/charts/ProgressChart'
import {DraggableTree} from 'vue-draggable-nested-tree'
import * as ih from '@/util'
import * as th from 'tree-helper'
import GeneralPrintButton from '@/components/print_to_pdf/GeneralPrintButton'
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            category_names: [],
            local_template_tree_contents_original: [],

            // temp_target_companies: [{ company_code: 0, company_name: "Company a", total_progress: 30, has_started: true/false
                                        //category_progress: { category_A: 10, category_B: 20, category_C: 30, cateogry_D: 40 }, 
                                        //category_filled_rate: { category_A: 10, ... }, 
                                        //category_num_of_subs_init: { category_A: 10... }, 
                                        //category_num_of_filled: { category_A: 10...}, 
                                        //progress_contents: [ {category_a: 70 }, {category_b: 60}, {category_c: 55}, {category_d: 20} ]},
            //                { company_code: 1, company_name: "Company b", progress_a: 80, prgress_b: 55, progress_c: 45, progress_d: 80 },
            //                { company_code: 2, company_name: "Company c", progress_a: 40, prgress_b: 74, progress_c: 35, progress_d: 40 },
            //                { company_code: 3, company_name: "Company d", progress_a: 96, prgress_b: 50, progress_c: 52, progress_d: 10 }]

            progress_load_indicator: false,
            idx: 0,

            is_progress_loaded: false,
            search_keyword: '',
            company_total_dounut: ['rgba(61, 90, 254, 1)'],
            company_category_dounut: ['rgba(173, 185, 255, 1)'],
            re_total: 0,
            re_category: 10,
            display_filled_rate: false,
            is_summary_mode_overall: true, // 'companies' or 'overall'
            sorted: 'sort'
        }
    },
    computed:{
        ...mapState('score', ['temp_target_companies']),
        campaign() {
            return this.$store.state.campaign
        },
        template_contents(){
            return this.$store.state.template_tree.template_contents
        },
        total_avg_progress(){
            if(this.is_progress_loaded==true){
                return this.average(Array.from(this.temp_target_companies.map(x => x.total_progress)))
            }
            else return 0
        },
        total_avg_filled_rate(){
            if(this.is_progress_loaded==true){
                return this.average(Array.from(this.temp_target_companies.map(x => x.total_filled_rate)))
            }
            else return 0
        },
        category_avg_progress(){
            if(this.is_progress_loaded==true){
                let return_array = []
                this.category_names.forEach(item=>{
                    let new_obj = {}
                    new_obj.category_name = item
                    new_obj.progress = this.average(Array.from(this.temp_target_companies.map(x=> x.category_progress[item])))
                    return_array.push(new_obj)
                })

                return return_array
            }
            else return 0
        },
        category_avg_filled_rate(){
            if(this.is_progress_loaded==true){
                let return_array = []
                this.category_names.forEach(item=>{
                    let new_obj = {}
                    new_obj.category_name = item
                    new_obj.filled_rate = this.average(Array.from(this.temp_target_companies.map(x=> x.category_filled_rate[item])))
                    return_array.push(new_obj)
                })

                return return_array
            }
            else return 0
        },
        filtered_companies(){
            if(this.search_keyword=='') { return this.temp_target_companies }
            else{
                let return_array = []
                for(let i=0; i<this.temp_target_companies.length; i++){
                    if(this.temp_target_companies[i].company_name.search(this.search_keyword) >= 0){
                        return_array.push(this.temp_target_companies[i])
                    }
                }
                return return_array
            }
        }
    },
    methods:{
        // ...mapActions('score', ['checkNotStartedCompanies']),
        readAllProgressesByCampaign(){
            const path = this.$store.state.backend_host + '/read_filled_rate_by_campaign'

            return axios.get(path, {params: {
                campaign_id: this.campaign.campaign_id
            }})
            .then(response=>{
                // [{ company_id, company_name, question_code, question_name, is_completed }, {...} ]
                // Question 별로 is_completed는 string 'true' 또는 'false'인 상태로 넘어옴

                let result = response.data

                this.temp_target_companies.forEach(company_item => {

                    let new_template_contents = ih.deepCopy(this.local_template_tree_contents_original)

                    //DB에서 읽어온 문항별 점수 값을 new_template_contents 각 노드에 넣어주고, node(question)별 progress를 계산함
                    th.breadthFirstSearch(new_template_contents, node => {
                        for(let i=0; i<result.length; i++)
                        {
                            if(node.question_code == result[i].question_code && company_item.company_id == result[i].company_id){
                                node.is_completed = result[i].is_completed
                                node.num_of_subs_init = result[i].num_of_subs_init
                                node.num_of_filled = result[i].num_of_filled
                                node.filled_rate = result[i].filled_rate
                            }
                        }

                        if(node.is_completed && node.is_completed=='true') { node.progress = 100 }
                        else { node.progress = 0}
                    })

                    //모든 노드의 Progress 산정
                    this.calculateAllProgress(new_template_contents)
                    Vue.set(company_item,'progress_contents', new_template_contents)

                    //총 Progress Rate 산정
                    Vue.set(company_item, 'total_progress', this.calculateTotalProgress(new_template_contents))
                    Vue.set(company_item, 'total_filled_rate', this.calculateTotalFilledRate(new_template_contents))

                    //카테고리별 Progress, filled_rate
                    new_template_contents.forEach(item => {
                        if(!company_item.category_progress) company_item.category_progress = {}
                        if(!company_item.category_filled_rate) company_item.category_filled_rate = {}
                        if(!company_item.category_num_of_subs_init) company_item.category_num_of_subs_init = {}
                        if(!company_item.category_num_of_filled) company_item.category_num_of_filled = {}
                        company_item.category_progress[item.question_name] = item.progress
                        company_item.category_filled_rate[item.question_name] = item.filled_rate
                        company_item.category_num_of_subs_init[item.question_name] = item.num_of_subs_init
                        company_item.category_num_of_filled[item.question_name] = item.num_of_filled
                    })
                })
                // console.log(this.temp_target_companies)
                this.is_progress_loaded= true
            })
        },
        calculateAllProgress(tree){
            th.breadthFirstSearch(tree, (node, parent) =>{
                if(node.children && node.children.length !=0){
                    let temp_progress = 0
                    let temp_num_of_subs_init = 0
                    let temp_num_of_filled = 0
                    let num_of_q1 = 0
                    node.children.map(x => {
                        temp_progress = temp_progress + x.progress
                        num_of_q1 += 1
                        temp_num_of_subs_init = temp_num_of_subs_init + x.num_of_subs_init
                        temp_num_of_filled = temp_num_of_filled + x.num_of_filled
                    })

                    node.num_of_subs_init = temp_num_of_subs_init
                    node.num_of_filled = temp_num_of_filled
                    node.filled_rate = temp_num_of_filled / temp_num_of_subs_init * 100

                    if(num_of_q1 > 0 && node.progress != temp_progress / num_of_q1) {
                        node.progress = temp_progress / num_of_q1
                        if(parent!=undefined){
                            this.calculateAllProgress(parent)
                        }
                    
                    }
                }
            })
        },
        calculateTotalProgress(progress_contents){
            let temp_total_progress = 0
            let number_of_q = 0
            th.breadthFirstSearch(progress_contents, node => {
                if(node.children && node.children.length ==0){
                    temp_total_progress += node.progress
                    number_of_q += 1
                }
            })

            return temp_total_progress / number_of_q
        },
        calculateTotalFilledRate(progress_contents){
            let temp_total_filled_rate = 0
            let number_of_q = 0
            th.breadthFirstSearch(progress_contents, node => {
                if(node.children && node.children.length ==0){
                    temp_total_filled_rate += node.filled_rate
                    number_of_q += 1
                }
            })

            return temp_total_filled_rate / number_of_q * 100
        },
        average(target_progresses) {
            //Progress 재료 배열을 받아서 평균점 반환
            let sum = 0
            let num_of_q = 0
            for (var i=0; i<target_progresses.length; i++) {
                if(Number.isNaN(target_progresses[i])==false)
                {
                    sum += target_progresses[i]
                    num_of_q += 1
                }
            }
            return Math.round(sum/num_of_q * 10)/10
        },
        selectCompany(company_item){
            let index = this.temp_target_companies.findIndex(item => { return item.company_id == company_item.company_id } )
            if (index > -1){
                this.idx = index
            }
            this.re_total += 1
            this.re_category += 11
        },
        checkCompleted(node){
            if(node.children && node.children.length) return ''

            if(node.is_completed == 'true') return this.$t(`완료`)
            else return this.$t(`진행중`)
        },
        changeProgressMode(mode){
            Vue.nextTick(()=>{
                this.re_total += 2
                this.re_category += 10
            })
            this.display_filled_rate = mode
        },
        toggleSorted(is_target_filled_rate, mode){
            this.sorted = mode
            if(mode == 'sort'){
                this.temp_target_companies.sort( function(a, b) {
                    return a.company_id - b.company_id
                })
            }
            else if(mode == 'sort-down'){
                if(is_target_filled_rate == true){
                    this.temp_target_companies.sort( function (a, b) {
                        return b.total_filled_rate - a.total_filled_rate
                    })
                }
                else{
                    this.temp_target_companies.sort( function (a, b){
                        return b.total_progress - a.total_progress
                    })
                }
            }
            else if(mode == 'sort-up'){
                if(is_target_filled_rate == true){
                    this.temp_target_companies.sort( function (a, b) {
                        return a.total_filled_rate - b.total_filled_rate
                    })
                }
                else{
                    this.temp_target_companies.sort( function (a, b){
                        return a.total_progress - b.total_progress
                    })
                }
            }
        },
        checkNotStartedCompanies(){
            const path = this.$store.state.backend_host + '/read_not_started_company_ids'

            return axios.get(path, { params: {
                campaign_id: this.campaign.campaign_id
            }})
            .then( response => {
                let not_started_company_ids = response.data

                for(let i=0; i<this.temp_target_companies.length; i++)
                {
                    let idx = not_started_company_ids.findIndex(element => element == this.temp_target_companies[i].company_id)
                    if(idx >-1 && this.temp_target_companies[i].total_filled_rate ==0 ){
                        Vue.set(this.temp_target_companies[i], 'has_started', false) 
                    }
                    else{
                        Vue.set(this.temp_target_companies[i], 'has_started', true)
                    }
                }

            })
        }
    },
    created() {
        // console.log(this.temp_target_companies);
        this.$store.dispatch('readTemplateTree',{template_code:this.campaign.template_code, is_previous:false})
        .then( ()=>{
            this.category_names = Array.from(this.template_contents.map(x => x.question_name))
            this.local_template_tree_contents_original = this.template_contents
            this.readAllProgressesByCampaign()
            .then( () => {
                if(this.temp_target_companies.length > 0) { this.progress_load_indicator=true }
                this.checkNotStartedCompanies()
            })

        })
    },
    mounted(){
        // console.log(this.temp_target_companies.length);
    },
    components:{
        CompanyFinder,
        ProgressChart,
        'tree': DraggableTree,
        GeneralPrintButton
    }
}
</script>

<style scoped>
.total-progress-charter {
    max-width: 150px;
}
.total-progress-dounut-inner{
    margin-top: -77px;
    margin-bottom: 60px;
    text-align: center;
}
.progress-charter {
    max-width: 130px;
}
.progress-dounut-inner{
    margin-top: -63px;
    margin-bottom: 60px;
    text-align: center;
}
.thin-progress, .category-progress {
    background-color: rgba(150, 150, 150, 0.2)!important;
}

.thin-progress > .progress-bar {
    background-color: var(--blue);
}

.category-progress > .progress-bar {
    background-color: var(--blue4);
}

.overall-company-progress > .progress-bar{
    background-color: var(--blue);
}

.on-track{
    color: var(--gray-600)!important;
    font-size: 0.9rem;
    line-height: 1.2;
}
.on-completed {
    color: var(--blue)!important;
    font-size: 0.9rem;
    line-height: 1.2;
}
.node-level-1 {
    background-color: var(--gray-300);
    font-weight: 600;
}
.node-level-2 {
    background-color: var(--gray-200);
    font-weight: 500;
}
.node-level-1-sub {
    padding-left: 1.5rem!important;
}
.node-level-2-sub {
    padding-left: 2.75rem!important;
}
.node-level-3-sub {
    padding-left: 2.75rem!important;
}
/* .active {
    border:1px solid var(--blue)!important;
    color: var(--blue)!important;
} */

.top-tri{
    position: relative;
    margin-top: 17px;
}
.top-tri::before{
    position: absolute;
    top: -17px;
    height: 0;
    width: 0;
    left: 0;
    border: 7px solid transparent;
    border-bottom-color: var(--blue);
    content: "";
}
</style>
