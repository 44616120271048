<template>
<div class="editing-dfs">
        <!--제목, 컴포넌트 기본 공통 -->
        <div class="p-2 bgray-200 rounded d-flex justify-content-between align-items-center border">
            <div class="d-flex align-items-center">
                <span class="mx-3 f-110">{{ sub_code }}</span>
                <b-dropdown :text="sub_type" variant="dark-border" >
                    <b-dropdown-item-button @click="selectType(item)" v-for="item in sub_types" :key="item.id" > {{ item }} </b-dropdown-item-button>
                </b-dropdown>
            </div>
            <div class="d-flex align-items-center">             
                <b-form-checkbox v-model="sub_data_header.required">필수 응답(Public ONLY)</b-form-checkbox>
                <span class="mx-3 gray-600"> |</span>
                <b-dropdown :text="scoring" size="sm" variant="dark-border">
                    <b-dropdown-item @click="setScoring('none')" >No Scoring</b-dropdown-item>
                    <b-dropdown-item @click="setScoring('auto')">Auto Scoring</b-dropdown-item>
                </b-dropdown>
            </div>
        </div>

        <div class="mt-3 mb-1 fw-600 f-90">Title</div>
        <b-input v-model="sub_name" placeholder="Sub name"> {{ sub_name }} </b-input>

        <div class="mt-3 mb-1 fw-600 f-90">Description</div>
        <b-textarea v-model="sub_description" placeholder="A1. ㅇㅇㅇ을 관리하고 있습니까?"> {{ sub_description }} </b-textarea>

        <div class="mt-3 mb-1 fw-600 f-90">Info</div>
        <b-textarea v-model="sub_info" placeholder="용어설명, 참고사항, 평가기준 등 답변 참고정보" > {{ sub_info }} </b-textarea>

        <div class="mt-3 mb-1 fw-600 f-90" style="cursor:pointer" @click="conditionPopoverToggle()">Condition <fa :id="`sub_condition_information+${sub_code}`" icon="info-circle"></fa>
        </div>
            <b-popover :target="`sub_condition_information+${sub_code}`" title="설명" :content="sub_condition_information" placement="left" :show="sub_condition_popover_show">
            </b-popover>
        <div id="editing-template-condition">
            <b-form-checkbox v-model="is_condition_available" @input="manageConditionAvailability()" class="editing-dfs">
                <b-form-input placeholder="선행조건 표시내용을 입력해 주세요" v-model="sub_condition.text"> {{ sub_condition.text }} </b-form-input>
            </b-form-checkbox>      
        </div>

        <div class="mt-3 mb-1 fw-600 f-90">Not Applicable</div>
        <b-form-checkbox v-model="sub_data_header.enable_na" class="blue">선택지에 '해당없음' 추가</b-form-checkbox>
    
        <!-- DF Table 데이터 구조 수정 -->        
        <div class="mt-3 mb-1 fw-600 f-90" style="cursor:pointer" @click="headerPopoverToggle()">Table Header <fa :id="`sub_data_header_information+${sub_code}`" icon="info-circle"></fa>
        </div>
            <b-popover :target="`sub_data_header_information+${sub_code}`" title="정의" :content="sub_data_header_information" placement="left" :show="sub_data_header_popover_show"
            ></b-popover>        
        <b-form-input v-model="table_header" placeholder="ex. 구분, FY2017, FY2018, FY2019" class="">{{table_header}}</b-form-input>
               
        <div class="mt-3 mb-1 fw-600 f-90" style="cursor:pointer" @click="contentsPopoverToggle()"> Table Contents <fa :id="`sub_data_contents_information+${sub_code}`" icon="info-circle"></fa>
        </div>
            <b-popover :target="`sub_data_contents_information+${sub_code}`" title="정의" :content="sub_data_contents_information" placement="left" :show="sub_data_contents_popover_show"
            ></b-popover>
         
        <b-table-simple> 
            <tr>
                <td></td>
                <td v-for="(col_item, index) in table_header_col" :key="col_item.id"> {{ alphabet[index] }} </td>
                <td>관리</td>
            </tr>
            <tr>
                <td></td>
                <td v-for="col_item in table_header_col" :key="col_item.id">{{ col_item }}</td>
                <td></td>
            </tr>
            <tr v-for="(row_item, row_index) in sub_data_contents.structure" :key="row_item.id">
                <td> {{ row_index + 1 }} </td>
                <td v-for="(col_item, col_index) in table_header_col" :key="col_item.id">
                    <div @click="editCell(row_item, col_item, row_index, col_index)"> 
                        <div v-if="row_item.noteditables && row_item.noteditables.includes(col_item)" v-html="row_item[col_item]"></div>
                        <div v-else-if="row_item.dropdown[col_item]">
                            <b-dropdown :id="`dropdown-${sub_code}-${row_index}-${col_index}`" :text="row_item[col_item]">
                                <b-dropdown-item v-for="drop_item in row_item.dropdown[col_item]" :key="drop_item.id">{{ drop_item }}</b-dropdown-item>
                                <b-dropdown-item-button style="font-size:0.8em; color:#FFFFFF; background-color:#0033FF" @click="editCell(row_item, col_item, row_index, col_index)">수정</b-dropdown-item-button>
                            </b-dropdown>
                        </div>
                        <div v-else-if="row_item.calculation[col_item]">
                            ( {{ row_item.calculation[col_item] }} )
                        </div>
                        <div v-else> 
                            <b-form-input v-model="row_item[col_item]" :disabled="true">{{row_item[col_item]}}</b-form-input> 
                        </div>
                    
                        <!-- 셀 에디터 모달 --> 
                        <b-modal @ok="handleCellEditorOK($event, row_item, col_item, row_index, col_index)" title="테이블 셀 설정" :ref="`table-cell-editor-${sub_code}-${row_index}-${col_index}`" :id="`table-cell-editor-${sub_code}-${row_index}-${col_index}`">
                       
                            <b-form-select v-model="option_cell_selected" class="mb-3">
                                <option value="input_text">텍스트 입력 폼</option> 
                                <option value="dropdown">드롭다운 선택지</option> 
                                <option value="text">수정불가 텍스트</option>
                                <option value="calculation">계산식</option>
                            </b-form-select>
                               
                            <!-- 텍스트 입력 폼일 경우 -->
                            <div v-if="option_cell_selected=='input_text'" class="d-flex justify-content-between editing-dfs">
                                기본 값
                                <b-form-input placeholder="미리 입력될 기본 값을 입력해주세요" v-model="row_item[col_item]" class="w-75">{{ row_item[col_item] }}</b-form-input>
                            </div>
                            <div v-if="option_cell_selected=='input_text'" class="d-flex justify-content-between editing-dfs">
                                가이드텍스트
                                <b-form-input placeholder="입력 가이드를 입력해주세요" v-model="row_item.placeholder[col_item]" class="w-75"> {{ row_item.placeholder[col_item] }}</b-form-input>
                            </div>

                            <!-- 드롭다운 선택지일 경우 -->
                            <div v-if="option_cell_selected=='dropdown'" class="mb-3 editing-dfs">
                                선택지
                                <b-form-input placeholder="선택지 항목을 쉼표로 구분하여 입력해주세요" v-model="dropdown" @update="updateCellDropdown(row_item,col_item,row_index,col_index)" ></b-form-input>
                            </div>
                            <div v-if="option_cell_selected=='dropdown'" class="d-flex justify-content-between align-items-center editing-dfs p-2 border rounded f-90 ">
                                미선택시 기본 값:
                                <b-form-input placeholder="기본값을 입력해 주세요" v-model="row_item[col_item]" class="w-75"></b-form-input>
                            </div>

                            <!-- 텍스트 선택지일 경우 -->
                            <div v-if="option_cell_selected=='text'" class="editing-dfs">
                                표시 텍스트
                                <b-form-input placeholder="셀에 직접 표시될 내용을 입력해 주세요" v-model="row_item[col_item]" >{{row_item[col_item]}}</b-form-input>
                            </div>

                            <!-- 계산식일 경우 -->
                            <div v-if="option_cell_selected=='calculation'"  class="editing-dfs">
                                계산식
                                <b-form-input :state="calculation_status" placeholder="계산식을 입력해 주세요(ex. A1+B1)" v-model="calculation_string"> {{ calculation_string }} </b-form-input>
                                {{ calculation_status_message }}
                            </div>
                        </b-modal>                      
                    </div>
                </td>
                <td>
                    <b-button variant="icon" size="sm" @click="addDuplicatedRow(row_item, row_index)">복제</b-button>
                    <b-button variant="icon" size="sm" @click="deleteRow(row_item, row_index)">삭제</b-button>
                </td>
            </tr>
        </b-table-simple>
                  
       <!-- Tags, Child 등 기본 공통 -->
        <div class="mt-4 d-flex editing-dfs">
            <span class="fw-600 mr-5 f-90">Tags </span>
            <b-input v-model="tags" placeholder="띄어쓰기 대신 _ 사용, 쉼표로 구분"> {{ tags }}</b-input>
        </div>

        <div v-if="checkAbleChild" class="mt-3 mb-4 d-flex">
            <span class="fw-600 mr-5 f-90">Child</span>
            <div class="bgray-200 w-100 p-2 rounded "><b-form-checkbox v-model="has_child" @change="handleHasChild" >파일 및 URL 첨부</b-form-checkbox></div>
        </div>

        <div class="mt-4 d-flex editing-dfs">
            <span class="fw-600 mr-5 f-90">Priority</span>
            <b-form-select v-model="sub_priority" :options="sub_priority_option"></b-form-select>
        </div>

        <div class="float-right">
            <b-button @click="handleSaveSub()" variant="blue" class="mr-2">저장</b-button>
            <b-button @click="$emit('click-cancel')" variant="dark">취소</b-button>
        </div>

</div>
</template>

<script>
import { EditingSubComponentMixin } from './EditingSubComponentMixin'
import * as ih from '../../../util'

export default {
    mixins: [EditingSubComponentMixin],
    data() {
        return {
            sub_data_header_information: `
            { "테이블 헤더 항목을 쉼표로 구분하여 입력해 주세요. (ex. 구분, 이름, 직책, 비고)" } `,
            sub_data_contents_information: `
             행 추가/복사: 행을 추가하거나 복사할 수 있습니다.
             각 셀의 설정을 변경하려면 셀을 클릭하십시오. 
             각 셀 별로 텍스트 입력 폼, 드롭다운, 수정불가 텍스트 중 선택할 수 있습니다. 
            `,
            
            sub_condition_string: '',
            sub_data_header_string: '',
            sub_data_contents_string: '',

            dropdown: '',
            option_cell_selected: '',

            table_header: '',

            // `[{ "No": "1", "이름": "홍길동", "독립성": "", "직업": "모름",
            //      "noteditables": ["No", "이름"],
            //      "dropdown": { "독립성": ["독립","비독립"] },
            //      "placeholders": { "독립성": "독립성 여부를 입력해 주세요" }
            //      },
            // { "No": "2", "이름": "누구", "독립성": "비독립", "직업": "모름" }]`

            alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
            
            calculation_string: '',
            calculation_validated_str: '',
            calculation_status: false,
            calculation_status_message: ''
        
        }
    },
    computed: {
        table_header_col: {
            get() {
                return ih.stringToArray(this.table_header)
            }
        }
    },
    methods: {
        handleSaveSub(){

            this.sub_data_contents['table_header'] = ih.stringToArray(this.table_header)
            this.saveSub()
            .then(this.$emit('click-save'))

        },
        editCell(row_item, col_item, row_index, col_index){
            //window.alert(row_index + '행' + col_index + '열을 클릭했습니다.')

            //순차적으로 판단하여 현재 셀이 input_text인지, dropdown인지, noteditable text인지, calculation인지 구분하여 초기화
            this.option_cell_selected = 'input_text'
            if(row_item.dropdown[col_item]) {
                this.option_cell_selected = 'dropdown'
                this.dropdown = ih.arrayToString(row_item.dropdown[col_item])
            }
            else { 
                this.dropdown = ''
            }
            if(row_item.calculation[col_item]) {
                this.option_cell_selected = 'calculation'
                this.calculation_string = row_item.calculation[col_item]
            }
            if(row_item.noteditables.includes(col_item)) this.option_cell_selected = 'text'

            this.$bvModal.show(`table-cell-editor-${this.sub_code}-${row_index}-${col_index}`)
        },
        updateCellDropdown(row_item, col_item, row_index, col_index){
            Vue.nextTick( () => {
                if(!row_item.dropdown) row_item.dropdown = {}
                row_item.dropdown[col_item] = ih.stringToArray(this.dropdown)
            })
        },
        handleCellEditorOK(bvModalEvt, row_item, col_item, row_index, col_index){

            bvModalEvt.preventDefault()

            //순차적으로 판단하여 저장 전 cell 상태변경을 각 셀 데이터에 반영
            if(this.option_cell_selected == 'input_text'){
                delete row_item.dropdown[col_item]
                let idx = row_item.noteditables.findIndex( item => { return item == col_item })
                if (idx > -1) row_item.noteditables.splice(idx, 1)
                delete row_item.calculation[col_item]

                Vue.nextTick( () => {
                    this.$bvModal.hide(`table-cell-editor-${this.sub_code}-${row_index}-${col_index}`)
                })
            }
            else if(this.option_cell_selected == 'dropdown'){
                delete row_item.placeholder[col_item]
                row_item.dropdown[col_item] = ih.stringToArray(this.dropdown)
                let idx = row_item.noteditables.findIndex ( item => { return item == col_item })
                if (idx > -1) row_item.noteditables.splice(idx, 1)
                delete row_item.calculation[col_item]
                
                Vue.nextTick( () => {
                    this.$bvModal.hide(`table-cell-editor-${this.sub_code}-${row_index}-${col_index}`)
                })
            }
            else if(this.option_cell_selected == 'text'){
                delete row_item.placeholder[col_item]
                delete row_item.dropdown[col_item]
                delete row_item.calculation[col_item]
                row_item.noteditables.push(col_item)

                Vue.nextTick( () => {
                    this.$bvModal.hide(`table-cell-editor-${this.sub_code}-${row_index}-${col_index}`)
                })
            }
            else if(this.option_cell_selected == 'calculation'){
                delete row_item.placeholder[col_item]
                delete row_item.dropdown[col_item]
                let idx = row_item.noteditables.findIndex( item => { return item == col_item })
                if (idx > -1) row_item.noteditables.splice(idx, 1)

                if(this.calculation_status != true){
                    return false
                }
                else{
                    row_item.calculation[col_item]=this.calculation_validated_str
                    Vue.nextTick( () => {
                        this.$bvModal.hide(`table-cell-editor-${this.sub_code}-${row_index}-${col_index}`)
                    })
                }
            }
            else { 
                console.log('열행 번호에 맞는 내용이 없음')
            }

        },
        addDuplicatedRow(row_item, row_index){
            let new_item = ih.deepCopy(row_item)
            this.sub_data_contents.structure.splice(row_index, 0, new_item)
        },
        deleteRow(row_item, row_index){
            this.sub_data_contents.structure.splice(row_index, 1)
        }
    },
    watch: {
        sub_data_contents: {
            immediate: true,
            handler(newValue, oldValue){
                if(newValue.hasOwnProperty('table_header')) this.table_header = ih.arrayToString(newValue.table_header)
                console.log(this.table_header)
            }
        },
        calculation_string: {
            handler(newValue, oldValue){
                
                this.calculation_status_message = '산식을 판단 중입니다'
                this.calculation_status = false 
                this.calculation_validated_str = ''

                let validated_str = newValue

                validated_str.replace(/[\{\}\[\]?.,;:|~`!^\_<>@\#$%&\\\=\'\"\s]/gi,'')

                let var_items = validated_str.match(/([A-Z0-9\(\)]+)/gi)
                let calculation_items = validated_str.match(/([+-/*])/g)

                if(var_items == null) var_items = []
                if(calculation_items == null) calculation_items = []

                if(!var_items[0]) {
                    this.calculation_status_message = '산식은 셀 참조(ex.A1), 괄호 또는 숫자로 시작해야 합니다'
                    //this.calculation_status_message = 'var_items' + var_items + ' calculation_items:' + calculation_items
                    this.calculation_status = false
                }
                else if(var_items.length != calculation_items.length + 1) {
                    this.calculation_status_message = '산식이 연산자로 끝나거나, 셀 참조(ex.A1)와 연산자(ex.+) 구성이 적절하지 않습니다'
                    //this.calculation_status_message = 'var_items:' + var_items + ' calculation_items:' + calculation_items
                    this.calculation_status = false
                }
                else {
                    this.calculation_validated_str = validated_str
                    this.calculation_status_message = '적절한 산식입니다'
                    this.calculation_status = true
                }
            }
        }

    },
    created(){
        if(this.sub_data_contents.structure.length==0){
            this.sub_data_contents.structure.push({})
        }
        for(let i=0; i<this.sub_data_contents.structure.length; i++)
            {
                if(!this.sub_data_contents.structure[i].placeholder) this.sub_data_contents.structure[i].placeholder = {}
                if(!this.sub_data_contents.structure[i].dropdown) this.sub_data_contents.structure[i].dropdown = {}
                if(!this.sub_data_contents.structure[i].noteditables) this.sub_data_contents.structure[i].noteditables = []
                if(!this.sub_data_contents.structure[i].calculation) this.sub_data_contents.structure[i].calculation = {}
            }
    },
}
</script>