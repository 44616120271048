<template>
<div :class="audit_mode!=true&&previous!=true ? 'mb-5 pb-4':'gray-600 font-scale-sm'">
    <div v-show="is_visible_by_pipeline==true" :class="previous==true ? 'p-3 gray-500 rounded border bgray-100':''">

        <div v-if="headers">            
           <template>
                <div v-if="sub_name!=''" :class="[custom_style==''? 'fw-500 f-110 border-bottom mb-2':'custom-sub-name', '' ]"> <span v-if="sub_data_header.required && sub_data_header.required==true">*</span>{{ byLang(sub_name,sub_name_e, sub_name_c ) }} </div>
                <div :class="[ sub_description != '' ? 'mb-4': '', 'mb-1']">
                    <div v-if="is_indicate_description" class="fw-500" :class="custom_style==''?'':'custom-sub-description'">
                        <span v-if="sub_name === '' && sub_data_header.required && sub_data_header.required === true">*</span>
                        {{ byLang(sub_description,sub_description_e, sub_description_c ) }}
                    </div>
                    <span v-for="item in tags" :key="item.id"> <span v-if="item != ''" class="ml-2 f-80 gray-500"> {{ item }} </span></span>
                    <!-- <b-button  v-if="sub_info != ''" class="ml-1 px-1 py-0 f-80" variant="teal" size="sm" :id="`i-${ sub_code }`" @click="toggleVisible" ><fa icon="book-open"></fa>Info</b-button> -->

                    <span v-if="sub_priority != ''"><b-badge :variant="badge_var" class="mx-2">{{ $t(sub_priority) }}</b-badge></span>
                    <b-button v-if="previous_sub.sub_code!=undefined && previous!=true" v-b-toggle="previous_sub.sub_code" size="sm" variant="red" class="py-0">{{ $t('과거 답변 보기') }}<fa icon="history" class="ml-2"></fa></b-button>

                    <!-- sub_info 정보 -->
                    <b-card v-if="sub_info != ''" :id="`sub_info-${ sub_code }`" class="mt-2 mb-3 border-0 teal8 bg-secondary2 f-90"> <fa icon="info-circle" v-if="custom_style==''" class="mr-1"></fa><span v-html="byLang(sub_info, sub_info_e, sub_info_c)"></span></b-card>

                    <!-- sub_condition -->
                    <div v-if="is_sub_condition_activated==true" :id="`sub_condition-${sub_code}`" class="my-2 py-2">
                        <b-form-checkbox :disabled="is_locked || is_disabled_by_parent" v-model="sub_condition.value" @change="radioSave()"> {{ sub_condition.text }} </b-form-checkbox>
                    </div>
                </div>
             </template>
        </div>

        <!-- previous component -->
        <b-collapse v-if="print_mode!=true" :id="previous_sub.sub_code" class="mb-4">
            <sub-component :disabled="true" v-if="previous_sub.sub_code != undefined && previous != true" :is_disabled_by_parent="true" :sub_type="previous_sub.sub_type" :item="previous_sub" :previous="true"></sub-component>
            <!-- {{ previous_sub }} -->
        </b-collapse>

        <b-overlay :show="is_visible_by_pipeline==false" rounded="sm" opacity="0.6" bg-color="#c8c8c8">
            <div class="pl-0 pl-md-3">
                <!-- Radio 유형 Sub Component -->
                <b-form-radio-group :id="sub_code" v-model="sub_data_contents.result" @change="handleChange" :stacked="!sub_data_header.horizontal || sub_data_header.horizontal==false">
                    <div v-for="(item, item_idx) in sub_data_contents.structure" :key="item.index" class="d-inline">
                        <!-- {{item}} -->
                        <b-form-radio :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent" :value="item.value" class="primary-check mb-3"> 
                            <span>{{ byLang(item.text, item.text_ea, item.text_ca)  }}</span>
                        </b-form-radio>
                        <df-element
                            v-for="(element_item, element_idx) in item.element_contents"
                            :key="elements_key+element_idx" 
                            :item_idx="item_idx"
                            :element_idx="element_idx"
                            :element_item="element_item"
                            @change="handleElementChange"
                            :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent || item.value != sub_data_contents.result"
                            :audit_mode="audit_mode"
                        ></df-element>
                        <!-- {{item.element_contents}} -->
                    </div>   
                    <b-form-radio v-if="sub_data_header.enable_na==true" :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent" class="primary-check d-inline mb-2" value="해당없음">{{ $t('해당없음') }}</b-form-radio>                 
                </b-form-radio-group>
                <div v-if="not_applicable==true"><b-form-input v-model="sub_data_contents.na_reason" :placeholder="$t('본 질문이 귀사에 해당되지 않는 사유를 간략히 입력해 주세요.')" @update="textInputSave"></b-form-input></div>
                <!-- file 저장 --> 
                <!-- <df-attached-file-url
                v-if="sub_data_header.has_attachment==true"
                class="mt-4"
                :sub_code="sub_code"
                :attachment="sub_data_contents.attachment"
                :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent || sub_data_contents.result==''"
                :audit_mode="audit_mode"
                @file-save="handleAttachmentSave"
                :previous="previous"
                :key="is_loaded + Math.random()"
                >
                </df-attached-file-url> -->
                <!-- 저장 validation -->
                <div v-if="previous != true && audit_mode!=true &&custom_style==''" :class="validation_style" class="mt-2"><fa v-if="validation_icon !=''" :icon="validation_icon"></fa> {{ validation_comment }} </div>
            </div>

            <!-- Child Component -->
                <sub-component v-for="(item, index) in child_subs" :key="item.id"
                :is_final_sub_component="index == child_subs.length -1 ? true : false"
                :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent" 
                :sub_type="item.sub_type" 
                :item="item" 
                :audit_mode="audit_mode" 
                :is_disabled_by_parent="is_disabled_by_parent" ></sub-component>            

            <!-- is_visible_by_pipeline 조건에 따른 해당없음 overlay -->
            <template v-slot:overlay>
                <div class="text-center ">
                    {{$t(`해당사항 없음`)}} <b-button variant="icon" v-b-tooltip.hover :title="pipeline_title"><fa icon="info-circle"></fa></b-button>
                </div>
            </template>
        </b-overlay>
    </div>



</div>
</template>
<script>
import { SubComponentMixin } from './SubComponentMixin'
import DFElement from './elements/DFElement'

export default {
    mixins: [SubComponentMixin],
    components: {
        'df-element': DFElement
    },
    data(){
        return {
            elements_key: Math.random(),
            button_title: this.$t(`평가정보`),
            pipeline_title: this.$t(`이 문항은 답변할 필요가 없습니다.`)
        }
    },
    watch:{
        is_loaded:{
            immediate: true,
            handler(new_val){
                if(new_val == true){
                    //console.log(this.sub_data_contents)
                    this.elements_key += 10
                }
            }
        }
    },
    methods: {
        byLang(ko, en, cn){
            if(this.$i18n.locale=='en') return en
            else if (this.$i18n.locale=='cn') return cn
            else return ko
        },
        async handleChange(){
            console.log(`handleChange ~ `);
            
            this.$nextTick( () => {
            //다른 라디오 선택한 경우 없애기

            //'해당없음' 기능 사용하는 경우 처리
            console.log(` ~ this.sub_data_header.enable_na => `, this.sub_data_header.enable_na);
            
            if(this.sub_data_header.enable_na == true){
                if(this.sub_data_contents.result == '해당없음'){
                    this.not_applicable = true                 
                }
                else {
                    this.not_applicable = false
                    delete this.sub_data_contents.na_reason
                }
            }

            console.log(` ~ this.sub_data_contents => `, this.sub_data_contents);
            
            this.sub_data_contents.structure.forEach(item => {
                console.log(`   ~ item.element_contents => `, item.element_contents);
                
                if(typeof item.element_contents !== 'undefined') {
                    if(item.value !== this.sub_data_contents.result){ //선택한 답안이 아니면
                        item.element_contents.forEach(element_item => { // 하위 질문을 모두 삭제
                            if (element_item.type == 'input_text' || element_item.type == 'input_number') {
                                element_item.result = ''
                                this.elements_key += 10 //for reactivity
                            } else if(element_item.type == 'table') {
                                element_item.result = ''
                                element_item.structure.forEach(row => {
                                    element_item.table_header.forEach(header => {
                                        if(row.noteditables.includes(header)==false){
                                            row[header] = ''
                                        }

                                    })
                                })
                            } else if (element_item.type == 'checkbox') { //'checkbox'
                                element_item.result.splice(0, element_item.result.length)
                                element_item.structure.forEach(element_checkbox_item => {
                                    if (typeof element_checkbox_item.description !== 'undefined') {
                                        element_checkbox_item.description = ''
                                    }
                                })
                            }
                        })
                    }
                    //let new_element_contents = [...item.element_contents]
                    //Vue.set(item, 'element_contents', new_element_contents)
                }
            })
            // console.log(this.sub_data_contents)
            //let new_subs = [ ...this.$store.state.questions.subs ]
            //this.$store.commit('questions/update_subs', new_subs) // for Reactivity
            //calculate score
            if(this.sub_data_header.scoring && this.sub_data_header.scoring=='auto'){
                let temp_score = 0
                let idx = this.sub_data_contents.structure.findIndex(x => { return x.value == this.sub_data_contents.result })
                if(idx > -1){
                    if(this.sub_data_contents.structure[idx].score){
                        temp_score += Number(this.sub_data_contents.structure[idx].score)
                    }
                }
                this.score_result = temp_score
            }
            })
            await this.radioSave()
        },
        handleElementChange(item_idx, element_idx, new_element_item){
            this.sub_data_contents.structure[item_idx].element_contents[element_idx] = new_element_item

            Vue.nextTick( () => {
                this.textInputSave(false)
            })
        }
    }
}
</script>

<style scoped>

</style>
