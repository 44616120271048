<template>
<div>
    <router-view name="user_header"></router-view>

    <router-view :value_new="value_new" :target_company_name="target_company_name" class="min-vh-100"></router-view>
    
        <!-- <b-row no-gutters class="flex-wrap position-relative text-left" id="user-main">
            <b-col id="user-sidebar" :class="[is_sidebar_collapsed? 'collapsed' : '', 'px-0 bgray-700']">
            <router-view name="user_aside"></router-view>
            </b-col>

            <b-col id="user-content" class="pr-2 pl-5 px-md-3 px-lg-5 py-3 w-100">
                <router-view :value_new="value_new" :target_company_name="target_company_name"></router-view>
            </b-col>
        </b-row> -->
    
    <router-view name="user_footer"></router-view>
</div>

</template>

<script>

export default {
    data () {
        return {
            is_sidebar_collapsed : false
        }
    },
    methods: {
        pageGo(destination){
            this.$router.push(destination)
        },
        makeToast(title, contents){
            this.$bvToast.toast(contents, {
                title: title,
                toaster: 'b-toaster-bottom-center',
                autoHideDelay: 1000,
                solid: true,
                appendToast: true, 
                variant: ''
            })
        },
        toggleSidebar(new_value) {
            this.is_sidebar_collapsed = new_value
        }
    },
    props: {
        value_new : { default: false },
        target_company_name: { default: '' },

        campaign_id: { type: String, default: ''},
        user_id: { type: String, default: '' },
        company_name: { type: String, default: '' },
        template_code: { type: String, default: ''}, 
        template_contents: { type: Object, default: () => ({}) }
    },
    created () {
        //EventBus.$on('toast-success', this.makeToast)
        EventBus.$on('toggle-sidebar', this.toggleSidebar)
    },
    computed: {
        user_name() {
            return this.$store.getters.getUserName
        },
        e_mail(){
            return this.$store.getters.getEMail
        },
        isAuthenticated(){
            return this.$store.getters.isAuthenticated
        },
        isLoggedIn() {
            return this.$store.getters.isLoggedIn
        },
    },
    watch: {
    //    e_mail: function () {
    //        if (this.e_mail=='undefined') {
    //            alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
    //            this.$store.dispatch('logout').then(() => { window.location.href = this.$store.state.frontend_host + '/login' })
     //           return
    //        }
    //    }
    },
}
</script>

<style scoped>


</style>
