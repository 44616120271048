<template>
<div>
    <div class="d-md-flex justify-content-between mb-5" style="margin-left:-1rem; margin-right:-1rem;">
        <!-- <div class="w-100 mx-3 mb-3 mb-md-0">
            <h6 class="f-120 mb-2"> 개선계획 제출률 </h6>
            <div class="monitoring-block bgray-200 w-100 d-flex align-items-center justify-content-center position-relative">
                <div class="f-260 mb-2">{{ Math.round((count_submitted/temp_target_companies.length)*100) }} <span class="f-100">%</span></div>
                <div class="text-right f-80 px-3 pb-2 w-100 position-absolute" style="bottom:0;"> * 전체 {{temp_target_companies.length}}개 기업 중 {{count_submitted}}개사 제출</div>                 
            </div>
        </div>
        <div class="w-100 mx-3 mb-3 mb-md-0">
            <h6 class="f-120 mb-2"> 전체 개선계획 및 개선과제 수 </h6>
            <div class="monitoring-block bgray-200 w-100 d-flex align-items-center justify-content-center position-relative"> 
                <div class="mb-2"><span class="f-260">{{ count_actions }}</span>개 계획</div> <span class="px-3">/</span>
                <div class="f-260 mb-2">{{ count_recommendations }}<span class="f-100">개 과제</span></div>
            </div>
        </div>
        <div class="w-100 mx-3 mb-3 mb-md-0">
            <h6 class="f-120 mb-2"> 전문가 컨펌 현황 </h6>
            <div class="monitoring-block bgray-200 w-100 d-flex align-items-center justify-content-center position-relative">
                <div class="mb-2"><span class="f-260">{{ Math.round((confirmed_companies/temp_target_companies.length)*100) }}</span>%</div>
                <div class="text-right f-80 px-3 pb-2 w-100 position-absolute" style="bottom:0;"> * 전체 {{temp_target_companies.length}}개 기업 중 {{confirmed_companies}}개사 컨펌완료</div>                 
            </div>
        </div> -->
    </div>

    <h4 class="mb-5 fw-600">기업별 후속 모니터링</h4>

    <b-table-simple hover responsive class="rep-table">
        <b-thead class="">
            <b-tr class="">
                <b-td>No.</b-td>
                <b-td>기업명</b-td>
                <b-td>개선계획 제출 현황</b-td>
                <b-td>입력 현황</b-td>
                <b-td>개선과제 및 계획 보고서</b-td>
                <b-td>평가자 승인</b-td>
            </b-tr>
        </b-thead>
        <b-tbody>
            <b-tr v-for="(item, index) in pa_by_campaign" :key="item.id">
                <b-td> {{ index+1 }} </b-td>
                <b-td>{{ findCompanyName(item.company_id) }} </b-td>
                <b-td>
                    {{ item.action_plan_submitted==true?'제출함':'미제출' }} 
                    <span class="ml-1 f-80 fw-300"> {{ item.action_plan_submitted==true? item.action_plan_submit_time_stamp.slice(0,10):'' }}</span>
                </b-td>
                <b-td>
                    {{ item.pa_action_plans.length }}<span class="f-90 fw-300">개 계획 입력 </span>
                </b-td>
                <b-td> 
                    <b-button @click="showMonitoringReport(item.company_id)" variant="dark-border" size="sm"> {{ item.action_plan_submitted==true? '보기':'작성현황 보기' }} </b-button> 
                </b-td>
                <b-td>
                    <span v-if="item.action_plan_submitted==true&&item.action_plan_confirmed==true" class="green"> <fa icon="check" class="mr-2"></fa>완료</span>
                    <span v-else-if="item.action_plan_submitted==true&&item.action_plan_confirmed!=true" class="red" v-b-tooltip.hover title="'보기'에서 '승인하기'를 클릭하여 승인할 수 있습니다."> 승인 필요 </span>
                    <span v-else class="gray-500">(미제출)</span>
                </b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>

    <b-modal id="improvement-b-report" size="xl" hide-footer :title="campaign.campaign_name">
        <improve-b-report :company_id="monitoring_company_id"></improve-b-report>
    </b-modal>

</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import ImproveBReport from './ImproveBReport.vue'

export default {
    data() {
        return {
            monitoring_company_id:''
        }
    },
    computed:{
        ...mapState(['campaign']),
        ...mapState('score', ['temp_target_companies']),
        ...mapState('reviews', ['reviews', 'is_corrective_actions_loaded', 'is_monitoring_confirmed_loaded']),
        ...mapState('postassess', ['pa_by_campaign'])

    },
    methods:{
        ...mapMutations('score', ['update_temp_target_companies', 'add_temp_target_companies']),
        ...mapMutations('postassess', ['update_pa_by_campaign_list','empty_pa_by_campaign_list']),
        ...mapActions('postassess', ['readPAActionPlansByCampaign', 'readPAActionPlansSubmitted','readMonitoringConfirm']),
        findCompanyName(company_id){
            // console.log(this.temp_target_companies);
            return this.temp_target_companies.find(x => x.company_id == company_id).company_name            
        },
        findMonitoringConfirm(company_id){
            let idx = this.reviews.findIndex(item => item.company_id == company_id)
            if (idx > -1){
                return this.reviews[idx].monitoring_confirm.confirmed
            }
            else return {}
        },  
        showMonitoringReport(company_id){
            this.monitoring_company_id = company_id
            this.$bvModal.show('improvement-b-report')
        },
        async readThings(){
            await this.readPAActionPlansSubmitted({campaign_id: this.campaign.campaign_id})
            await this.readPAActionPlansByCampaign({campaign_id: this.campaign.campaign_id})
            this.readMonitoringConfirm({campaign_id: this.campaign.campaign_id})
        }
    },
    async created(){
        let target_companies = [...this.temp_target_companies]
        target_companies.forEach(el => {
            this.update_pa_by_campaign_list( {company_id: el.company_id} )
        })
        await this.readPAActionPlansSubmitted({campaign_id: this.campaign.campaign_id})
        await this.readPAActionPlansByCampaign({campaign_id: this.campaign.campaign_id})
        this.readMonitoringConfirm({campaign_id: this.campaign.campaign_id})   
    },
    updated(){
        // console.log(this.pa_by_campaign);
    },
    beforeDestroy(){
        this.empty_pa_by_campaign_list()
    },
    components: { ImproveBReport },
    

}
</script>

<style>

</style>