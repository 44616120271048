<template>
<b-container>
    <div class="ih-background">
        <b-row class="mx-0 vh-100">
            <!-- <b-col class="col-0 col-md-1"></b-col> -->
            <b-col class="col-12 col-md-6 d-flex flex-column align-items-center justify-content-between"> 
                <div> </div>
                <div class="mb-4">          
                    <b-img src="@/assets/Tier Zero_basic.svg"  fluid style="width:340px"></b-img>
                    <div class="blue tail-line-blue">티 어 제 로</div> 
                </div>

                <div class="text-center mb-5">by
                    <a href="http://qesg.co.kr/" target="_blank" class="ml-2 text-reset"> {{ $t(`(주)퀀티파이드이에스지`)}} </a>
                </div>      
            </b-col>
            <b-col class="col-12 col-md-5 bgray-500 d-flex flex-column align-items-center justify-content-between">
                <div> </div>
                 <div class="w-100 position-relative">
                    <!-- 일반 로그인 -->
                    <b-card v-if="finding_password!=true && pls_check_email!=true" class="login-card login-card-bg text-white">
                        <div class="text-right mb-5">
                            <b-dropdown size="sm" variant="light-border" class="" >
                                <template #button-content> <fa icon="globe"></fa> {{selected_lang}} </template>
                                <b-dropdown-item  @click="$i18n.locale=lang.value" v-for="lang in langs" :key="lang.id"> {{ lang.text }} </b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <h6 class="text-left ml-2 mb-3"> {{ $t(`이메일로 로그인 하기`)}} </h6>
                        <b-form-input class="login-card-input mb-3" label="E-mail:" type="text" placeholder="name@example.com" v-model.trim="user.e_mail"></b-form-input>
                        <b-form-input :class="[user.password? 'password-font':'', 'login-card-input mb-3']" label="Password:" type="password" placeholder="password" v-model="user.password" @keyup.enter="login"></b-form-input>
                        <b-button :disabled="login_status == 'login_try'" class="login-card-submit mb-2" block variant="light-border" @click="login" >{{ $t(`로그인`)}}</b-button>
                        <div v-if="login_status=='login_fail'"> <span :class="login_status"> {{ msg }} </span> </div>
                        <div v-else-if="login_status=='login_try'"> <b-spinner class="login-spinner" small label="Small Spinner"></b-spinner></div>
                        <h6 class="text-left ml-2 mb-3 mt-5"> {{ $t(`프로젝트 코드를 받으셨나요?`)}}  </h6>
                        <b-button :disabled="login_status == 'login_try'" class="login-card-submit mb-2" block variant="light-border" @click="goRegister"> {{ $t(`가입 및 신청`)}}</b-button>

                        <div class="mt-5">
                            <span v-b-modal.customer-service class="mr-2 cursor"> {{ $t(`서비스 문의`) }}</span>|
                            <span @click="goTermsOfUse" target="_blank" :class="[login_status=='login_fail' ? 'mx-2':'ml-2','cursor']"> {{ $t(`이용약관`)}} </span> 
                            <span v-if="login_status=='login_fail'">| <span @click="findPassword" class="ml-2 cursor"> {{ $t(`비밀번호 찾기`)}} </span> </span>
                        </div>
                    </b-card>
                    <!-- 비밀번호 찾기 -->
                    <b-card v-else-if="finding_password==true && pls_check_email!=true" class="login-card login-card-bg text-white">
                        <div class="text-left ml-2 mb-3"> {{ $t(`이메일로 비밀번호 찾기`) }} </div>
                        <b-form-input class="login-card-input mb-4" label="E-mail:" type="text" :placeholder="$t(`가입하신 이메일 주소를 입력해주세요`)" v-model.trim="find_email"></b-form-input>
                        <b-button :disabled="finding_status=='finding'" class="login-card-submit mb-2" block variant="light-border" @click="sendPassword" >{{ $t(`찾기`)}}</b-button>
                        <div v-if="finding_status=='not_found'"> <span :class="login_status"> {{ findingStatus }} </span> </div>
                        <div v-else-if="finding_status=='finding'"> <b-spinner class="login-spinner" small label="Small Spinner"></b-spinner></div>
                        <div class="mt-5">
                            <span v-b-modal.customer-service class="cursor"> {{ $t(`서비스 문의`)}} </span>
                        </div>
                    </b-card>
                    <!-- 임시 비밀번호 전송 완료 -->
                    <b-card v-else-if="finding_password==true&&pls_check_email==true" class="login-card login-card-bg text-white">
                        <div class="py-5"> {{ $t(`입력하신 이메일로 임시 비밀번호가 전송되었습니다.`)}} <br> {{ $t(`이메일 확인 후 다시 로그인하여 주십시오.`)}} </div>
                        <b-button class="login-card-submit mb-2" variant="light-border" @click="loginRetry">{{ $t(`다시 로그인하기`)}}</b-button>
                    </b-card>
                </div>      
                <div class="text-center mb-5 gray-100 fw-300 f-95">
                    <span v-if="$i18n.locale=='ko'" >본 시스템은 <a href="https://www.google.com/intl/ko/chrome/" target="_blank" class="a-link-light"><b-img class="mx-1" style="width:20px; height:20px;" src="@/assets/chrome_i.svg"></b-img> Google Chrome </a>또는 
                    <a href="https://www.microsoft.com/ko-kr/edge" target="_blank" class="a-link-light"><b-img class="mx-1" style="width:20px; height:20px;" src="@/assets/edge.svg"></b-img>Microsoft Edge </a>에 최적화되어있습니다.</span>
                    <span v-else>This system is recommended for Google Chrome or MS Edge. Please check your browser.</span>
                </div>  
                
                
            </b-col>
            <b-col class="col-0 col-md-1"></b-col>
        </b-row>

        <b-modal id="server-error-info" title="긴급 점검 안내" hide-footer>
            <h4>긴급 공지 드립니다.</h4>
            <div class="mb-2">일시적인 서버 접속 오류로 인하여 잠시 점검 중입니다. </div>
            <div class="mb-2">8월 10일 오후 3시 이후 서버 연결이 정상화될 예정이오니 오후 중 다시 접속하여 주십시오.</div>
            <div class="mb-2">이용에 불편을 드려 대단히 죄송합니다. </div>
        </b-modal>

        <!-- 서비스 문의 모달 -->
        <b-modal id="customer-service" :title="$t(`서비스 문의하기`)" hide-footer>
            <customer-service></customer-service>
        </b-modal>           
    </div>
</b-container>
</template>

<script>
//import axios from 'axios'
import CustomerService from '@/components/provider_landing/CustomerService'
import {mapGetters} from 'vuex'

export default {
    data () {
        return {
            user: {
                e_mail: '',
                password: ''
            },
            login_status: 'login_normal',
            finding_status: 'no',
            selected: [],
            finding_password:false,
            pls_check_email:false,
            find_email: '',
            langs: [{text:'한국어', value:'ko'}, {text:'English', value:'en'}, {text:'中文', value:'cn'}]

        }
    },
    methods: {
        login() {
            this.login_status = 'login_try'
            this.$store.dispatch('login', this.user)            
            .then(result => {              
                this.$router.push({name:'Main'})               
                .catch(
                    error => {
                        this.login_status = 'login_fail'
                        //this.makeToast('로그인 실패', '이메일과 패스워드를 다시 확인하세요.', 'danger')
                    }
                )
            })
            .catch(error => {
                console.log('라우터 에러' + error)
                this.$store.dispatch('logout').then(() => { this.$router.push('/login') })
                })
        },
        logout() {
            this.$store.dispatch('logout').then(() => { this.$router.push('/login') })
        },
        makeToast(title, contents, variant){
            this.$bvToast.toast(contents, {
                title: title,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 1500,
                solid: true,
                appendToast: true,
                variant: ''
            })
        },
        goTermsOfUse(){
            this.$store.commit('update_service_policy_mode', '이용약관')
            this.$router.push({name: 'TermsOfUse'})
        },
        // 이하 비밀번호 찾기 관련 영역
        findPassword(){
            this.finding_password = true
        },
        sendPassword(){
        this.finding_status='finding'
        // console.log(this.finding_status);
        
        //  입력 받은 이메일로 User 정보를 찾음
            const path = this.$store.state.backend_host + '/find_user_by_email'

            axios.get(path, {params: {e_mail: this.find_email}})
            .then(response => {
                // console.log(response.data)

                // 이메일 찾은 경우: 이메일 보낸 다음, 이메일을 확인하라는 알림 띄움
                if(response.data.e_mail != ''){
                    this.pls_check_email = true
                    this.finding_password = true               
                }           
            })
            .catch(error => {       
                console.log(error.response.status)        
                if (error.response.status=404) {
                    // 해당 이메일로 user 정보를 찾을 수 없을 경우:
                    this.finding_status='not_found'
                    console.log(this.finding_status);
                    this.pls_check_email = false
                    // this.finding_password = true
                }
                this.pls_check_email = false
                // this.finding_password = true                
            })

        },
        loginRetry(){
            this.pls_check_email = false
            this.finding_password = false
            this.login_status='login_normal' 
        },
        goRegister(){
            this.$router.push({name:'Register'})   
        },
        toggleLang(){
            if (this.$i18n.locale == 'ko') { this.$i18n.locale='en' }
            else { this.$i18n.locale='ko' }
        }
    },
    computed: {
        selected_lang(){
            if (this.$i18n.locale=='ko'){ return 'Korean'
            }if (this.$i18n.locale=='en'){ return 'English'
            }if (this.$i18n.locale=='cn'){ return 'Chinese' }
        },
        msg() {
            if(this.login_status=='login_normal') { return '' }
            else if(this.login_status=='login_try') { return '' }
            else if(this.login_status=='login_fail') { return this.$t(`이메일 또는 비밀번호가 일치하지 않습니다.`)}
            else return ''
        },
        findingStatus() {
            if(this.finding_status=='no') {return ''}
            else if(this.finding_status=='finding') { return '' }
            else if(this.finding_status=='not_found') { return  this.$t(`등록되지 않은 이메일 입니다.`)}
        }
    },
    mounted(){
        // this.$bvModal.show('server-error-info')
    },
    created () {
    },
    components:{
        CustomerService,
    }
}

</script>
<style scoped>
input:-webkit-autofill::first-line {
    font-family: 'Noto Sans Kr';
    font-size: 1rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected{
  transition: background-color 5000s ease-in-out 0s;
  -webkit-transition: background-color 9999s ease-out;
  -webkit-box-shadow: 0px 0px 0px 1000px rgba(255,255,255,0) inset!important;
  -webkit-text-fill-color: #ffffff!important;
}

.ih-background {
    position: fixed;
    left: 0;
    top: 0;
    width:100vw;
    height:100vh;
    overflow: none;
    background-color: var(--gray-200);
    /* background-image:  linear-gradient(to right top, #c2c2c2, #b3aecf, #a19ada, #8c86e5, #7373f0, #656bf5, #5462f9, #3d5afe, #3d5afe, #3d5afe, #3d5afe, #3d5afe);; */
    /* -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-image: url(../../../assets/login_bg4.png);
    background-repeat: no-repeat; */ 
}

.login-spinner {
    color: #FFFFFF;
}

.login_fail {
    color: var(--gray-200);
    font-size:0.95rem;
    font-weight: 300;
}
.login-card-bg{
    background-color: transparent;
    /* background-color: rgba(10,10,10, 0.2); */
    border: none;
}
.login-card {
    min-width: 300px;
    padding: 10% 15% 5% 15%;
}
.login-card > div, 
.login-card > div > div{
    text-align: center;
}

.login-card-input {
    padding: 0.5rem 1.5rem;
    height: auto;
    color: white!important;
    background-color: rgba(255, 255, 255, 0.2)!important;
    border: none;
    border-radius: 2rem;
}

.password-font {
    font-family: Arial;
}

.login-card-submit {
    padding: 0.45rem 1.25rem;
    height: auto;
    color: white;
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid white;
    border-radius: 2rem;
    align-self: center;
}

.login-card > input.form-control:focus {
    background-color: rgba(255, 255, 255, 0.2)!important;
}

.login-card-submit:hover {
    background-color: transparent;
    border-color: white;
}

input::-webkit-input-placeholder,
input::-webkit-input-placeholder:focus{
    color: rgba(255, 255, 255, 0.7)!important;
    background: rgba(255, 255, 255, 0);
}
input::-ms-input-placeholder{
    color:rgba(255, 255, 255, 0.7)!important;
    background: rgba(255, 255, 255, 0);
}

.dataflare-logo{
    min-width: 300px;
    max-width: 80%;    
    margin-bottom: 8%;
}

.login_normal {
    color: white;
    margin-bottom: 1rem;
}

</style>
<style>


#customer-service___BV_modal_content_ >.modal-body {
    border-bottom-left-radius: calc(0.3rem - 1px);
    border-bottom-right-radius: calc(0.3rem - 1px);

}</style>
