<template>
<div class="">
    <b-container fluid class="px-0 h-80vh">
        <b-nav tabs align="center" class="sup-landing-nav">
            <b-nav-item @click="showPreface" data-toggle="button" :class="[tabs_show[0]==true ? 'show':'','f-105']">{{$t(`평가안내`)}}</b-nav-item>
            <b-nav-item @click="showDashboard" data-toggle="button" :class="[tabs_show[1]==true ? 'show':'','f-105']">{{$t(`진행현황`)}}</b-nav-item>
            <!-- <b-nav-item @click="showParticipants" data-toggle="button" :class="[tabs_show[2] ? 'show':'','f-105']"> 참여자 관리 </b-nav-item> -->
            <b-nav-item @click="showResult" data-toggle="button" :class="[tabs_show[2] ? 'show':'','f-105']">{{$t(`결과보고서`)}}</b-nav-item>
            <!-- <b-nav-item v-if="checkOptions(campaign_in_progress.process_options)==true" :disabled="is_admin<2" @click="showImprovements" 
                data-toggle="button" :class="[tabs_show[3] ? 'show':'','f-105']">{{$t(`개선계획`)}}
            </b-nav-item> -->
            <b-nav-item v-if="released==true&&checkOptions(campaign_in_progress.process_options)==true" @click="showImprovements" 
                data-toggle="button" :class="[tabs_show[3] ? 'show':'','f-105']">{{$t(`개선계획`)}}
            </b-nav-item>
        </b-nav>

            <div v-if="tabs_show[0]==true" class="py-4 py-md-5 px-4 px-md-5 bg-white"> <preface></preface> </div>
            <div v-if="tabs_show[1]==true" class="py-4 py-md-5 px-4 px-md-5 bg-white"> 
                <div class="mb-5"><sup-schedule></sup-schedule></div>
                <div class="pt-3"> <sup-progress :key="sup_progress_key"></sup-progress> </div>
            </div>
            <!-- <div v-if="tabs_show[2]==true"> 
                <mapping-question-users v-if="campaign_in_progress.campaign_id!=''" :key="mapping_question_users_key"></mapping-question-users> 
            </div> -->
            <div v-if="tabs_show[2]==true" class="py-4 py-md-5 px-4 px-md-5 bg-white"> 
                <sup-results v-if="campaign_in_progress.campaign_id!=''" :key="sup_results_key" :released="released"></sup-results>
            </div>
            <div v-if="tabs_show[3]==true" class="px-4 px-md-5 bg-white">
                <sup-improvements :key="sup_improvements_key" @show-dashboard="showDashboard"></sup-improvements>
            </div>
             
    </b-container>

    <!-- <div v-if="campaign_in_progress.campaign_id==75" class="bottom-alert mb-0 w-100" style="z-index:99">
        <b-alert variant="danger" show dismissible class="ie-alert text-center mb-0" style="height:60px;">
            <b>'LG생활건강 2020 협력회사 지속가능경영 평가'의 온라인 평가 제출이 7월 6일 오후 3시 종료 되었음을 알려드립니다.</b>
        </b-alert>
    </div> -->

</div>
</template>

<script>
import Preface from  '@/components/main_layout/Preface'
import MappingQuestionUsers from '@/components/main_layout/MappingQuestionUsers'
import SupProgress from './SupProgress'
import SupSchedule from './SupSchedule'
import SupResults from './SupResults'
import SupImprovements from './SupImprovements'

import * as ih from '@/util'
import PrintToPDF from '@/components/print_to_pdf/PrintToPDF'
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
    data() {
        return {
            tabs_show: [true, false, false, false],
            mapping_question_users_key: 0,
            sup_progress_key: 10,
            sup_results_key: 100,
            sup_improvements_key: 1000,
            released: false,
        }
    },
    computed: {
        ...mapState(['is_admin']),
        ...mapState('reviews', ['recommendations_by_company', 'is_recommendations_by_company_loaded']),
        ...mapState(['campaign_in_progress']),
        template_in_progress_loaded() {
            return this.$store.state.template_in_progress_loaded
        },
        campaign_id() {
            return this.$store.state.campaign_in_progress.campaign_id
        },
        company_id() {
            return this.$store.state.company_id
        },
        user_id(){
            return this.$store.state.user_id
        },
        company_name(){
            return this.$store.state.company_name
        },        
    },
    methods: {
        ...mapActions(['read_recieved_campaigns']),
        ...mapActions('reviews', ['readRecommendationsbyCompany']),
        showPreface(){
            this.tabs_show = [true, false, false, false]
            this.$emit('improvement-mode', 'open')
        },
        showDashboard(){
            this.tabs_show = [false, true, false, false]
            this.sup_progress_key += 1
            this.$emit('improvement-mode', 'open')
        },
        showResult(){
            this.tabs_show = [false, false, true, false]
            this.sup_results_key +=1
            this.$emit('improvement-mode', 'close')
        },
        showImprovements(){
            this.tabs_show = [false, false, false, true]
            this.sup_improvements_key +=1
            this.$emit('improvement-mode', 'close')
        },
        checkRelease(){
            const path = this.$store.state.backend_host + '/check_release'

            return axios.get(path, { params: {
                campaign_id: this.campaign_in_progress.campaign_id,
                company_id: this.company_id
            }})
            .then( response=>{
                // response.data = { 'company_id': company_id, 'release': True or False, 'time_stamp': str(q.time_stamp) }
                this.released = response.data.release   
                // console.log(this.released);
            })
        },
        checkOptions(process_options_array){
            if (process_options_array != null){
                let idx = process_options_array.indexOf('후속모니터링')
                if (idx > -1){ return true }
            }
            else {return false}            
        },
        async handleReadRecommendation(){
            await this.readRecommendationsbyCompany({campaign_id:this.campaign_in_progress.campaign_id, company_id:this.company_id})

        },
    },
    created(){
        this.checkRelease() // 결과보고서 released 여부 확인하여 supresults에 보냄
        // this.$store.dispatch('initiateSpace', { user_id: this.user_id, campaign_id: this.campaign_id })//comment.js 관련
        this.handleReadRecommendation()        
    },


    components: {
        Preface,
        MappingQuestionUsers,
        SupSchedule,
        SupProgress,
        SupResults,
        SupImprovements,
        'print-to-pdf': PrintToPDF,
    }
}
</script>

<style scoped>

.sup-landing-nav {
    background-color: var(--gray-200);
    border-bottom: 0;
}
.sup-landing-nav > .nav-item {
    border: 0;
    height: 44px;
    margin-top: 4px;
}
.sup-landing-nav > .nav-item:hover > a, 
.sup-landing-nav > .nav-item > a,
.sup-landing-nav > .nav-item.show > a {
    background-color: rgba(0,0,0,0);
    border: 0;
    font-weight: 500;
}
.sup-landing-nav > .nav-item > a {
    color: var(--gray-600);
    position: relative;
}
.sup-landing-nav > .nav-item:hover > a {
    color: var(--gray-900);
}
.sup-landing-nav > .nav-item.show > a {
    color: var(--gray-900);
}
.sup-landing-nav > .nav-item.show > a::after,
.sup-landing-nav > .nav-item:hover > a::after {
    content: "";
    position: absolute;
    top: 33px;
    left: 50%;
    margin-left: -10px;
    width: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
}



</style>
