<template>
<div :class="audit_mode!=true&&previous!=true ? 'mb-5':'gray-600 font-scale-sm'">
    <div v-show="is_visible_by_pipeline==true" :class="previous==true ? 'p-3 gray-500 rounded border bgray-100':''">

        <div v-if="headers">
          
                <div :class="[ byLang(sub_name, sub_name_e, sub_name_c) != '' ? 'fw-500 f-110 border-bottom mb-2':'', '' ]"> <span v-if="sub_data_header.required && sub_data_header.required==true">*</span>{{ sub_name }} </div>
                <div :class="[ byLang(sub_description,sub_description_e, sub_description_c) != '' ? 'mb-3': '', 'mb-1']">
                    <div v-if="is_indicate_description" class="mr-1 fw-500 d-inline px-0">
                        <span v-if="sub_name === '' && sub_data_header.required && sub_data_header.required === true">*</span>
                        {{ byLang(sub_description, sub_description_e, sub_description_c) }}
                    </div>
                    <span v-for="item in tags" :key="item.id"> <span v-if="item != ''" class="ml-2 f-80 gray-500"> {{ item }} </span></span>
                    <b-button  v-if="sub_info != ''" class="ml-1 px-1 py-0 f-80" variant="teal" size="sm" :id="`i-${ sub_code }`" @click="toggleVisible" ><fa icon="book-open"></fa>Info</b-button>

                    <span v-if="sub_priority != ''"><b-badge :variant="badge_var" class="mx-2">{{ sub_priority }}</b-badge></span>
                    <b-button v-if="previous_sub.sub_code!=undefined && previous!=true" v-b-toggle="previous_sub.sub_code" size="sm" variant="red" class="py-0">{{ $t('과거 답변 보기') }}<fa icon="history" class="ml-2"></fa></b-button>

                    <!-- sub_info 정보 -->
                    <div v-if="is_visible" :id="`sub_info-${ sub_code }`" class="mt-2 mb-3 px-1 blue f-90"> <fa icon="info-circle" class="mr-1"></fa><span v-html="sub_info_c"></span></div>

                    <!-- sub_condition -->
                    <div v-if="is_sub_condition_activated==true" :id="`sub_condition-${sub_code}`" class="my-2 py-2">
                        <b-form-checkbox :disabled="is_locked || is_disabled_by_parent" v-model="sub_condition.value" @change="radioSave()"> {{ byLang(sub_condition.text, sub_condition.text_e, sub_condition.text_c) }} </b-form-checkbox>
                    </div>
                </div>
        </div>

        
        <b-overlay :show="is_visible_by_pipeline==false" rounded="sm" opacity="0.6" bg-color="#c8c8c8">

            <div class="pl-0 pl-md-3">
                <!-- input_text 유형 Sub Component -->

                <!--해당없음 START -->
                <b-form-radio-group v-model="not_applicable" @change="toggleNA">
                <b-form-radio :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent" v-if="sub_data_header.enable_na==true" :value="false"></b-form-radio>
                
                <b-input-group :class="sub_data_header.enable_na==true? 'ml-4': ''">
                    <b-form-input :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent || is_disabled_by_na" v-model.trim="sub_data_contents.result" @update="textInputSave" @input="not_applicable=false" :placeholder="sub_data_contents['placeholder']" ></b-form-input>
                    <b-input-group-append><b-button :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent || is_disabled_by_na" class="f-80" variant="icon-append" v-b-modal="`input-text-${sub_code}`"><fa icon="pen"></fa></b-button></b-input-group-append>
                </b-input-group>

                <!--해당없음 END-->
                <b-form-radio :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent" v-if="sub_data_header.enable_na==true" :value="true">해당없음</b-form-radio>
                </b-form-radio-group>
                <div v-if="not_applicable==true"><b-form-input v-model="sub_data_contents.na_reason" placeholder="본 질문이 귀사에 해당되지 않는 사유를 간략히 입력해 주세요." @update="textInputSave"></b-form-input></div>


                <b-modal @ok="handleOK" size="lg" :id="`input-text-${sub_code}`" :title="sub_name ? sub_name : $t(`텍스트 상세입력`)" >
                    <p class="mb-2 f-90 gray-600"> {{$t(`상세설명을 작성하여 주십시오.`)}}</p>
                        <b-textarea :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent || is_disabled_by_na" v-model="sub_data_contents.result" @input="not_applicable=false" rows="5"></b-textarea>
                </b-modal>
                <div v-if="previous != true && audit_mode!=true" :class="validation_style" class="mt-2"><fa v-if="validation_icon !=''" :icon="validation_icon"></fa> {{ validation_comment }} </div>

                <!-- Child Component -->
                <sub-component v-for="(item, index) in child_subs" :key="item.id"
                :is_final_sub_component="index == child_subs.length -1 ? true : false"
                :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent" 
                :sub_type="item.sub_type" 
                :item="item" 
                :audit_mode="audit_mode" 
                :is_disabled_by_parent="is_disabled_by_parent" ></sub-component>                  
            </div>
            <!-- is_visible_by_pipeline 조건에 따른 해당없음 overlay -->
            <template v-slot:overlay>
                <div class="text-center ">
                    {{$t(`해당사항 없음`)}} <b-button variant="icon" v-b-tooltip.hover :title="pipeline_title"><fa icon="info-circle"></fa></b-button>
                </div>
            </template>
        </b-overlay>

    </div>

    <!-- previous component -->
    <b-collapse v-if="print_mode!=true" :id="previous_sub.sub_code">
        <sub-component :disabled="true" v-if="previous_sub.sub_code != undefined && previous != true" :is_disabled_by_parent="true" :sub_type="previous_sub.sub_type" :item="previous_sub" :previous="true"></sub-component>
    </b-collapse>

</div>
</template>
<script>
import { SubComponentMixin } from './SubComponentMixin'

export default {
    data(){
        return{
            button_title: this.$t(`평가정보`),
            pipeline_title: this.$t(`이 문항은 답변할 필요가 없습니다.`)
        }
    },
    mixins: [SubComponentMixin],
    methods:{
        byLang(ko, en, cn){
            if(this.$i18n.locale=='en') return en
            else if (this.$i18n.locale=='cn') return cn
            else return ko
        },
        toggleNA() {
            this.$nextTick(async () => {
                if(this.not_applicable == false){
                    this.sub_data_contents.result = ''
                    delete this.sub_data_contents.na_reason                    
                    //this.is_disabled_by_na = false
                }
                else {
                    this.sub_data_contents.result = '해당없음'
                    //this.is_disabled_by_na = true
                    await this.radioSave()
                }
            })
        },
    },
    mounted(){
        if(this.sub_data_contents.result.length==0 || this.sub_data_contents.result == '[]') { this.sub_data_contents.result='' }
    }
}
</script>

<style scoped>



</style>
