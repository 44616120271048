<template>
<div>       
    <!-- <div v-if="is_conclusions_loaded==true" class="py-3"> -->
        <div v-if="is_conclusions_saved==true"> {{ audit_conclusion.auditor_name }} {{ audit_conclusion.time_stamp }} </div>
        <h6 class="pb-3 f-120 tail-line"> {{$t(`전문가 종합의견`)}} </h6>
        <template v-if="is_editing==true||audit_conclusion.conclusion==undefined">
            <b-textarea rows="12" v-model="audit_conclusion.conclusion" :placeholder="answer_company.company_name+$t(`의 평가결과에 대한 전문가 종합 의견을 작성하여 주십시오.`)" class="mb-1"></b-textarea>
            <div class="text-right"> <b-button variant="blue" size="sm" @click.stop.prevent="handleSaveConclusion"> {{$t(`저장`)}}</b-button></div>
        </template>
        <template v-else>
            <div v-html="audit_conclusion.conclusion" class="audit-report-sheet"></div>
            <div class="text-right"> <b-button variant="dark-border" size="sm" @click="is_editing=true"> <fa icon="pen"></fa></b-button></div>
        </template>

        <hr class="border-0 mb-5">


        <h6 class="pb-3 f-120 tail-line"> {{$t(`핵심 개선과제`)}} </h6>
        <div v-for="(item, index) in audit_recommendations" :key="index" class="mb-4">

            <template v-if="is_editing_recommendation[index]==true">            
                <div class="d-flex justify-content-between align-items-end mb-2">
                    <div class="fw-500 mb-1 f-90">{{$t(`개선과제`)}} {{ index+1 }}.</div>
                    <b-form-input v-model="item.title" :placeholder="$t(`개선과제명을 입력하여 주십시오.`)" class="recommend-title" style="width:calc(100% - 80px)"></b-form-input>
                </div>
                <b-textarea rows="5" v-model="item.recommendation" :placeholder="$t(`개선과제 내용을 작성하여 주십시오.`)" class="mb-1"></b-textarea>
                <div class="text-right"> <b-button variant="blue" size="sm" @click.stop.prevent="handleSaveRecommendation(item, index)"> {{$t(`저장`)}}</b-button></div>
            </template>

            <template v-else>
                <div class="d-flex align-items-center mb-2 bgray-200 px-2 py-1">
                    <div class="gray-600 mr-2 f-90">{{$t(`개선과제`)}} {{ index+1 }}.</div><div v-html="item.title" class="fw-500 f-105"></div>
                </div>
                <div v-html="item.recommendation" class="px-2"></div>
                <div class="text-right"> 
                    <b-button variant="dark-border" size="sm" @click="is_editing_recommendation.splice(index, 1, true)"> <fa icon="pen"></fa></b-button>
                    <b-button variant="dark-border" size="sm" @click="handleDeleteRecommendation(index, item.rec_code)"> <fa icon="trash-alt"></fa></b-button>
                </div>
            </template>         
        </div>
        
        <b-button variant="dark-border" @click="addRecommend"> <fa icon="plus" class="mr-2"></fa> {{$t(`개선과제 추가`)}}</b-button>

        
    <!-- </div> -->
</div>  
</template>

<script>
import { mapState, mapActions } from 'vuex'
import * as ih from '@/util'

export default {
    data() {
        return {
            audit_conclusion: {
                auditor_name: '',
                conclusion: '',
                time_stamp: ''
            },
            audit_recommendations:[{
                rec_code: '0',
                auditor_name: '',
                title: '',
                recommendation: '',
                time_stamp: ''
                },
                {
                rec_code: '1', 
                auditor_name: '',
                title: '',
                recommendation: '',
                time_stamp: ''
                }]
            ,
            is_conclusions_saved: false,
            is_editing: false,
            is_editing_recommendation: []

        }
    },
    computed:{
        // ...mapState('score', ['is_conclusions_loaded', 'audit_conclusion']
        // ),
        ...mapState('questions', ['subs', 'subs_loaded', 'answer_company']),
        ...mapState('reviews', ['reviews', 'is_conclusion_loaded', 'is_recommendations_loaded']),
        user_name() {
            return this.$store.state.user_name
        },
        campaign() {
            return this.$store.state.campaign
        },
    },
    methods:{
        ...mapActions('reviews', ['saveAuditConclusion', 'saveAuditRecommendation','deleteRecommendation']),
        addRecommend(){
            this.audit_recommendations.push(
                {
                    rec_code: 'rc' + Math.floor(Math.random()*10000000000), 
                    campaign_id:this.campaign.campaign_id, 
                    company_id:this.answer_company.company_id, 
                    auditor_name: '',  
                    recommendation: '', 
                    time_stamp: ''
                })
            this.is_editing_recommendation.push(true)
        },
        findAuditConclusion(company_id){
            let idx = this.reviews.findIndex(item => item.company_id == company_id)
            if (idx > -1){
                return ih.deepCopy(this.reviews[idx].audit_conclusion)
            }
            else return undefined
        },
        findAuditRecommendations(company_id){
            let idx = this.reviews.findIndex(item => item.company_id == company_id)
            if (idx > -1){
                return ih.deepCopy(this.reviews[idx].audit_recommendations)
            }
            else return undefined
            // console.log(this.reviews)
        },
        async handleSaveConclusion(){
            let response = await this.saveAuditConclusion( {
                campaign_id: this.campaign.campaign_id, 
                company_id: this.answer_company.company_id, 
                auditor_name: this.$store.state.user_name, 
                conclusion: this.audit_conclusion.conclusion
            })

            this.is_editing = false
            this.audit_conclusion = response
        },
        async handleSaveRecommendation(item, index){

            let response = await this.saveAuditRecommendation( {
                rec_code: item.rec_code,
                campaign_id: this.campaign.campaign_id, 
                company_id: this.answer_company.company_id, 
                auditor_name: this.$store.state.user_name, 
                title: item.title, 
                recommendation: item.recommendation
            })
            // console.log(this.audit_recommendations)
            // console.log(this.reviews)
            this.audit_recommendations[index] = response
            this.is_editing_recommendation.splice(index, 1, false)

        },
        async handleDeleteRecommendation(index, rec_code){
            let response = await this.deleteRecommendation({company_id:this.answer_company.company_id, rec_code:rec_code})            
            this.audit_recommendations.splice(index, 1)
            this.is_editing_recommendation.splice(index, 1)
            EventBus.$emit('toast-success', '삭제완료', '개선과제를 삭제하였습니다.')
        },
        initiateAuditConclusion(){
            let temp_audit_conclusion = this.findAuditConclusion(this.answer_company.company_id) 
            //console.log(temp_rev)
            let conclusion_obj = {}
            if(temp_audit_conclusion != undefined){
                    conclusion_obj = temp_audit_conclusion
                }
            else {
                conclusion_obj = {
                    conclusion_id: null,
                    auditor_name: '',
                    conclusion: '',
                    time_stamp: ''
                }
                this.is_editing = true
            }
            this.audit_conclusion = conclusion_obj
        },
        initiateAuditRecommendations(){
            let temp_audit_recommendations = this.findAuditRecommendations(this.answer_company.company_id) 
            // console.log(temp_audit_recommendations)
            // console.log(this.reviews)
            //console.log(temp_rev)
            let temp_rec = []
            if(temp_audit_recommendations == undefined || temp_audit_recommendations.length == 0){
                temp_rec = [{
                    rec_code: 'rc'+ Math.floor(Math.random()*10000000000),
                    auditor_name: '',
                    title: '',
                    recommendation: '',
                    time_stamp: ''
                    },
                    {
                    rec_code: 'rc'+ Math.floor(Math.random()*10000000000), 
                    auditor_name: '',
                    title: '',
                    recommendation: '',
                    time_stamp: ''
                }]
                this.is_editing_recommendation = []
                for(let i=0; i<temp_rec.length; i++){
                    this.is_editing_recommendation.push(true)
                }
            }
            else{
                temp_rec = temp_audit_recommendations
                this.is_editing_recommendation = []
                for(let i=0; i<temp_rec.length; i++){
                    this.is_editing_recommendation.push(false)
                }                
            }
            this.audit_recommendations = temp_rec
        },
        
    },
    created(){
        this.initiateAuditConclusion()
        this.initiateAuditRecommendations()
    },
    beforeDestroy(){
    },
    watch: {
        // is_conclusions_loaded(newVal){
        //     if (newVal==true){
        //     }        
        // }
    },
}
</script>

<style>
.recommend-title.form-control{
    padding: 0.2rem ;
    font-size: 1.02rem;
    height: auto;
    border:0;
    border-radius: 0;
    border-bottom: 1px solid var(--gray-400);
}
.recommend-title.form-control:focus,
.recommend-title.form-control:hover{
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border:0;
    border-bottom: 1px solid var(--gray-700);
}

</style>