<template>
<div>
    <div class="bgray-200 mb-5 py-4">
        <b-container fluid class="my-2 px-3 px-md-5">
            <b-row>
                <b-col class="col-0 col-md-2 col-lg-3">
                    <div @click.stop="$router.push({path: '/template_list'})" class="a-link-gray cursor f-140 d-none d-md-flex">&lang;<div class="pl-2 mt-1">목록으로</div></div>
                </b-col>
                <b-col class="col-12 col-md-7 col-lg-6">
                    <div v-if="is_editing==true" class="d-flex justify-content-center ml-4">
                        <b-form-input type="text" @keyup.enter="updateTemplate()" v-model="template_name"  placeholder="질문지 명을 입력해주세요."  class="title-input-form"></b-form-input>
                        <b-button variant="dark-border" class="ml-2" size="sm" @click="updateTemplate()"><fa icon="save"></fa></b-button>
                    </div>
                    <div v-else class="d-flex justify-content-center ml-4">
                        <h3 class="mb-0 pr-2">{{ template_name }}</h3>
                        <b-button variant="dark-border" class="ml-2" size="sm" @click="is_editing=true"><fa icon="pen"></fa></b-button>
                    </div>
                </b-col>
                <b-col class="col-0 col-md-3 pr-3">
                    <b-form-select v-model="template_scoring" :options="template_scoring_mode" :text="template_scoring" @change="updateTemplate()" class="form-select-gray">
                        <template v-slot:first>
                            <b-form-select-option :value="null" disabled class="gray-600">-- 점수 산출 모드 --</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
        </b-container>
    </div>
</div>
</template>

<script>

export default {
    data() {
        return {
            is_editing: false,
            template_scoring_mode: [{value:'no' , text:'No Scoring'}, 
                                    {value:'auto' , text:'Auto Scoring'}, 
                                    {value:'manual' , text:'Manual Scoring'}]
        }
    },
    props:{
        value_new: { type: Boolean, default:false}
    },
    computed: {
        template_tree: {
            get() {
                return this.$store.state.template_tree
            },
            set(newValue){
                return this.$store.state.template_tree = newValue
            }
        },
        template_name: {
            get() {
                return this.template_tree.template_name
            },
            set(newValue) {
                return this.template_tree.template_name = newValue
            }
        },
        template_contents: {
            get() {
                return this.template_tree.template_contents
            },
            set(newValue) {
                return this.template_tree.template_contents = newValue
            }
        },
        template_scoring: {
            get() {
                return this.template_tree.template_scoring
            },
            set(newValue) {
                return this.template_tree.template_scoring = newValue
            }
        }
    },
    methods:{
        cancel() {
            this.$router.go(-1)
        },
        updateTemplate(){
            if (this.template_name != '' && this.template_name !=undefined) {
                    this.$store.dispatch('saveTemplateTree')
                    this.is_editing=false
            }
            else {
                    alert('템플릿 이름을 입력하여 주십시오.')
            }
        },
    },
    created() {
        if(this.value_new == true){
            this.is_editing = true
        }
    },

}
</script>

<style scoped>

</style>
