<template>
<b-container fluid class="m-0 p-0 ">

        <b-row no-gutters class="w-100 py-4 gray-400 f-90" style="background-color:#3f3f3f;">
                <b-col class="col-0 col-sm-1 d-md-none"></b-col>
                <b-col class="col-12 col-sm-6 col-md-6 pl-0 pl-md-4 d-flex justify-content-start flex-column flex-lg-row">
                       <div class="pl-3 pr-2 text-left"> Copyright 2022 QuantifiedESG, Inc.</div> 
                       <div class="pl-3 pl-lg-0 text-left" v-if="$i18n.locale=='ko'">(주)퀀티파이드이에스지</div>
                </b-col>
                <b-col class="col-12 col-sm-4 col-md-6 pr-0 pr-md-4 d-flex justify-content-end flex-column flex-md-row">
                        <div class="mb-2 mb-md-0 pr-3 footer-link text-right" @click="goPrivacyPolicy"> {{$t(`개인정보 처리방침`)}}</div><span class="d-none d-md-inline-block">|</span>
                        <div class="mb-2 mb-md-0 pr-3 pl-3 footer-link text-right" @click="goTermsOfUse"> {{$t(`이용약관`)}}</div><span class="d-none d-md-inline-block">|</span>
                        <div v-b-modal.customer-service class="mb-2 mb-md-0 pr-3 pl-3 footer-link text-right"> {{$t(`시스템 문의`)}}</div>
                </b-col>
                <b-col class="col-0 col-sm-1 d-md-none"></b-col>

                <!-- 서비스 문의 모달 -->
                <b-modal id="customer-service" :title="$t(`서비스 문의하기`)" hide-footer>
                    <customer-service></customer-service>
                </b-modal>
        </b-row>
</b-container>
</template>

<script>
import CustomerService from '@/components/provider_landing/CustomerService'
export default {
    data() {
            return {
            }
    },
    computed:{

    },
    methods:{
        goTermsOfUse(){
            this.$router.push({name: 'TermsOfUse'})
            this.$store.commit('update_service_policy_mode', '이용약관')
        },
        goPrivacyPolicy(){
            this.$router.push({name: 'PrivacyPolicy'})
            this.$store.commit('update_service_policy_mode','개인정보 처리방침')
        }
    },
    components:{
        CustomerService
    }
}
</script>
<style>
.bottom-line{
        padding-top:3rem;
        height: 4px;
        background-color: var(--gray-500);
}
.footer-link {
    cursor: pointer;
}
</style>