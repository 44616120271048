<template>
<div>
    <b-container class="text-left" >
        <div :style="is_printing==true? 'position:absolute; margin-left:-9999px; margin-top:-9999px; width:1200px':''">
        <div id="campaign_report_container" :class="is_printing==true?'p-5':''">
            <b-container :class="chart_data_loaded==true?'text-right position-relative':'py-3 mb-1'">
                <general-print-button @modal-opened="changeToImg" @modal-closed="imgToChart" v-if="chart_data_loaded==true" print_target="campaign_report_container" :btn_variant="$i18n.locale!='cn'?'subtop-gn':'subtop-gn-cn'"  :d_none="is_printing==true? 'd-none':''"><fa icon="print"></fa></general-print-button>
            </b-container>
            <b-container v-if="is_printing==true" class="bgray-200 text-center py-3 mb-5">
                <h3 class="f-160 py-5 mb-0"> {{ campaign.campaign_name }} {{$t(`진단 결과`)}}</h3> 
            </b-container>

            <template v-if="is_admin==4">
                <h4 class="pt-4 pb-2 fw-600"> {{$t(`전체 점수 요약`)}}</h4>
                <b-row v-if="chart_data_loaded==true" class="p-3 mx-0 mb-5 bgray-100 d-lg-flex">
                    <b-col class="">
                        <div class="pb-1 mb-3 border-bottom d-flex justify-content-between fw-500 f-105"> {{$t(`전체기업 종합 점수`)}} </div>
                        <div>
                            <bar-chart v-show="is_printing==false" ref="total_score_chart" v-if="is_score_loaded==true" :chartData="total_score_chart_data" :options="total_score_chart_option" class="report-chart mb-4 mb-lg-0" :style="is_printing==true? 'width:461px!important': ''"></bar-chart>
                            <div class="overflow-hidden" v-show="is_printing==true"><img style="width:461px; height: auto;" :src="total_score_chart_url" /></div>
                        </div>
                    </b-col>

                    <b-col class="">
                        <div class="pb-1 mb-3 border-bottom d-flex justify-content-between fw-500 f-105">{{$t(`영역별 평균 점수`)}} </div>
                        <div class="overflow-hidden">
                            <report-chart v-show="is_printing==false" ref="category_score_chart" v-if="is_score_loaded==true" :chartData="category_score_chart_data" :options="category_score_chart_option" class="report-chart" :style="is_printing==true? 'width:461px!important': ''"></report-chart>
                            <div class="overflow-hidden" v-show="is_printing==true"><img style="width:461px; height: auto;" :src="category_score_chart_url" /></div>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-else class="p-3 mx-0 mb-5 bgray-100 d-lg-flex">
                    <b-col class="blue text-center"> <b-spinner class="mx-2 p-1 my-1" small type="grow"></b-spinner> {{$t(`데이터를 가져오고 있습니다`)}}... </b-col>
                </b-row>
            </template>

            <div class="pt-4 mb-5 d-flex justify-content-between align-items-center">
                <h4 class="fw-600"> {{$t(`기업별 점수`)}}</h4>
                <b-dropdown v-if="is_admin==4" class="" :text="mode_selected=='all'? $t(`전체기업`): campaign.required_info.find(item=> {return item.title == mode_selected}).option_selected" variant="mode active">
                    <b-dropdown-item-button @click="showAll()"> {{$t(`전체기업`)}} </b-dropdown-item-button>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-group v-for="(item, id) in campaign.required_info" :key="id" :header="item.title">
                        <b-dropdown-item-button v-for="(option, index) in item.structure" :key="index" @click="handleSelect(item, id, option)">{{option}}</b-dropdown-item-button>
                    </b-dropdown-group>
                </b-dropdown>
            </div> 

            <b-table-simple hover responsive class="rep-table">
                <b-thead class="">
                    <b-tr class="">
                        <b-td class="text-left">{{$t(`기업명`)}}</b-td>
                        <b-td>{{$t(`총점`)}}</b-td>
                        <b-td v-for="(item, index) in category_names" :key="index">{{$t(item)}}</b-td>
                        <b-td>{{$t(`답변보기`)}}</b-td>
                        <b-td v-if="is_admin>=3">{{$t(`결과보고서`)}}</b-td>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr class="">
                        <b-td class="border-right border-bottom-dark bgray-200 gray-900 text-left">{{ mode_selected=='all'? $t(`전체평균`): campaign.required_info.find(item=> {return item.title == mode_selected}).option_selected + ' 부문 평균'}}</b-td>
                        <b-td class="border-right border-bottom-dark bgray-200 gray-900">{{ average(all_total_scores) }}</b-td>
                        <b-td class="border-bottom-dark bgray-200 gray-900" v-for="(item, index) in category_names" :key="index">{{ visible_category_average[Object.keys(visible_category_average)[index]] }}</b-td>
                        <b-td class="border-left border-bottom-dark bgray-200">-</b-td>
                        <b-td class="border-left border-bottom-dark bgray-200" v-if="is_admin>=3">-</b-td>
                    </b-tr>
                    <b-tr class="">
                        <b-td class="border-right gray-900 text-left">{{ mode_selected=='all'? $t(`최고점`): campaign.required_info.find(item=> {return item.title == mode_selected}).option_selected + ' 부문 최고점'}}</b-td>
                        <b-td class="border-right gray-900">{{ visible_max }}</b-td>
                        <b-td class=" gray-900" v-for="(item, index) in category_names" :key="index"> {{ visible_category_max[Object.keys(visible_category_max)[index]] }} </b-td>
                        <b-td class="border-left gray-900">-</b-td>
                        <b-td class="border-left gray-900" v-if="is_admin>=3">-</b-td>
                    </b-tr>
                    <b-tr class="">
                        <b-td class="border-bottom-dark-bold border-right gray-900 text-left">{{ mode_selected=='all'? $t(`최하점`): campaign.required_info.find(item=> {return item.title == mode_selected}).option_selected + ' 부문 최하점'}}</b-td>
                        <b-td class="border-bottom-dark-bold border-right gray-900">{{ visible_min }}</b-td>
                        <b-td class="border-bottom-dark-bold gray-900" v-for="(x, index) in category_names" :key="index"> {{ visible_category_min[Object.keys(visible_category_min)[index]] }} </b-td>
                        <b-td class="border-bottom-dark-bold border-left gray-900">-</b-td>
                        <b-td class="border-bottom-dark-bold border-left gray-900" v-if="is_admin>=3">-</b-td>
                    </b-tr>
                    <b-tr v-for="(item, index) in visible_companies" :key="index">
                        <b-td class="border-right text-left"><b-badge variant="advance" class="mr-1">{{ item.company_id }}</b-badge> {{item.company_name}}</b-td>
                        <b-td class="border-right">{{ !Number.isNaN(item.total_score)? Math.round(item.total_score*10)/10 : '-'  }}</b-td>
                        <b-td class="" v-for="(x, id) in category_names" :key="id">{{ item.category_score && item.category_score[x] != undefined ? Math.round(item.category_score[x]*10)/10 : '-' }}</b-td>
                        <b-td class="border-left"><b-button variant="dark-border" @click="goAnswers(item)" class="py-0 px-3"><fa icon="tasks"></fa> </b-button></b-td>
                        <b-td class="border-left" v-if="is_admin>=3">
                            <b-button-group>
                                <b-button class="" variant="dark-border" size="sm" @click="showNewReport(item)" :disabled="is_admin!=4"> 미리보기</b-button>
                                <!-- <b-dropdown text="미리보기" class="" variant="dark-border" size="sm">
                                    <b-dropdown-item @click="showReport(item)">구버전 </b-dropdown-item>
                                    <b-dropdown-item @click="showNewReport(item)">2021 버전</b-dropdown-item>
                                </b-dropdown> -->
                                <template v-if="is_admin==4">
                                    <b-button v-if="item.release==false" variant="dark-border" size="sm" @click="saveRelease({campaign_id: campaign.campaign_id, company_id: item.company_id, new_release: true})" 
                                        :disabled="checkAllowed(3)!=true" v-b-tooltip.hover="$t(`배포시 협력사가 메인페이지에서 결과보고서를 확인할 수 있습니다.`)">{{$t(`배포하기`)}}</b-button>
                                    <b-button v-else-if="item.release==true" variant="dark" size="sm" @click="saveRelease({campaign_id: campaign.campaign_id, company_id: item.company_id, new_release: false})"
                                    :disabled="checkAllowed(3)!=true"  v-b-tooltip.hover="$t(`취소시 협력사가 결과보고서를 확인할 수 없습니다.`)">{{$t(`배포취소`)}}</b-button>
                                </template>
                            </b-button-group>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

                    <!-- <b-button variant="dark-border" @click="downloadRawData()" disabled> -->
                    <b-button variant="dark-border" @click="downloadRawData()" :disabled="downloading_raw_data==true" v-if="is_admin==4">
                        <span v-if="downloading_raw_data!=true">{{$t(`기업별 데이터 전체 다운로드(엑셀)`)}}</span>
                        <span v-else><b-spinner small></b-spinner> {{$t(`파일을 준비하는 중`)}}... ({{$t(`최대 1분이 소요됩니다`)}})</span>
                    </b-button>
                    <b-button variant="dark-border" class="ml-2" @click="downloadEvaluation()" :disabled="downloading_evaluation==true" v-if="is_admin==4">
                        <span v-if="downloading_evaluation!=true">{{$t(`평가 로우데이터 전체 다운로드(엑셀)`)}}</span>
                        <span v-else><b-spinner small></b-spinner> {{$t(`파일을 준비하는 중`)}}... ({{$t(`최대 1분이 소요됩니다`)}})</span> 
                    </b-button>

                    <b-modal id="report-modal" size="xl" hide-footer :title="campaign.campaign_name">
                        <report-preview :report_company="report_company" :report_campaign="report_campaign"></report-preview>
                    </b-modal>

                     <b-modal id="report-new-modal" size="xl" hide-footer @close="initiateReportContents()" scrollable>
                        <full-report ></full-report>
                    </b-modal>

        </div>
        </div>
    <hr class="border-0 bottom-space">
    </b-container>
</div>
</template>
<script>
import ReportChart from '@/components/charts/ReportChart'
import BarChart from '@/components/charts/BarChart'
import * as ih from '@/util'
import * as th from 'tree-helper'
import GeneralPrintButton from '@/components/print_to_pdf/GeneralPrintButton'
import ReportPreview from '@/components/managing_campaigns/report_preview/ReportPreview'
import { mapActions, mapMutations, mapState } from 'vuex'
import UserSubLayoutVue from '../UserSubLayout.vue'
import FileSaver from 'file-saver'

import FullReport from '@/components/supplier_report/FullReport'

export default {
    data() {
        return {
            report_company: '',
            report_campaign: '',
            is_printing: false,
            total_score_chart_url: '',
            category_score_chart_url: '',

            mode_selected: 'all',

            temp_scores: [],
            modal_mode: false,

            local_template_tree: {},
            chart_data_loaded: false,

            total_score_chart_key: 0,
            category_score_chart_key: 100,

            is_audited: false,
            downloading_raw_data: false,
            downloading_evaluation: false,

            total_score_chart_data: {
                labels: [this.$t('평균'), this.$t('최고점'), this.$t('최하점'), this.$t('중앙값') ],
                datasets: [
                    {
                        label: '2020년',
                        data: [ 90, 92, 88, 100 ],
                        barPercentage: 0.6,
                        backgroundColor: 'rgba(0,0,0, 0.15)',
                        borderColor: 'rgba(0,0,0, 1)',
                        borderWidth: 1,
                    },
                    // {
                    //     label: '2019년',
                    //     data: [ 50, 60, 40, 55 ],
                    //     barPercentage: 0.9,
                    //     backgroundColor: "rgba(150, 150, 150, 0.2)",
                    //     borderColor: "rgba(150, 150, 150, 0.7)",
                    //     borderWidth: 1,
                    // },
                ]
            },
            total_score_chart_option: {
                maintainAspectRatio: false,
                responsive: true,
                legend: {
                    display:false
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            color:"rgba(0, 0, 0, 0)"
                        },
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero:true,
                            max: 100,
                            stepSize: 20
                        }

                    }]
                }
            },

            category_score_chart_data: {
                labels: [],
                datasets: [
                    {
                        label: '2020년',
                        data: [],
                        barPercentage: 0.7,
                        backgroundColor: "rgba(0,0,0, 0.15)",
                        borderColor: "rgba(0,0,0, 1)",
                        borderWidth: 1,
                    },
                    // {
                    //     label: '2019년',
                    //     data: [ 0, 0, 0 ],
                    //     barPercentage: 0.9,
                    //     backgroundColor: "rgba(150, 150, 150, 0.2)",
                    //     borderColor: "rgba(150, 150, 150, 0.7)",
                    //     borderWidth: 1,
                    // },
                ]
            },
            category_score_chart_option: {
                maintainAspectRatio: false,
                responsive: true,
                legend: {
                    display:false
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            color:"rgba(0, 0, 0, 0)"
                        },
                        ticks: {
                            beginAtZero:true,
                            max: 100,
                        }
                    }],
                    yAxes: [{

                    }]
                }
            }
        }
    },
    computed: {
        ...mapState(['is_admin', 'campaign']),
        template_contents(){
            return this.$store.state.template_tree.template_contents
        },
        category_names(){
            return this.$store.state.score.category_names
        },
        temp_target_companies(){
            return this.$store.state.score.temp_target_companies
        },
        is_score_loaded(){
            return this.$store.state.score.is_score_loaded
        },
        is_previous_score_loaded(){
            return this.$store.state.score.is_previous_score_loaded
        },
        admin_level(){
            return this.$store.getters.getAdminLevel
        },
        // 전체 기업 영역별 평균 - getters
        visible_category_average(){
            let response = {}
            for(let i=0; i<this.category_names.length; i++) {
                let temp_array=[]
                for(let j=0; j<this.visible_companies.length; j++) {
                    if (this.visible_companies[j].category_score) {
                        temp_array.push(this.visible_companies[j].category_score[this.category_names[i]])
                    }else continue
                }
                response[this.category_names[i]] = this.average(temp_array)
            }
            return response
        },
        visible_category_max(){
            let response = {}
            for(let i=0; i<this.category_names.length; i++) {
                let temp_array=[]
                for(let j=0; j<this.visible_companies.length; j++) {
                    if (this.visible_companies[j].category_score) {
                        temp_array.push(this.visible_companies[j].category_score[this.category_names[i]])
                    }else continue
                }
                response[this.category_names[i]] = this.max(temp_array)
            }
            return response
        },
        visible_category_min(){
            let response = {}
            for(let i=0; i<this.category_names.length; i++) {
                let temp_array=[]
                for(let j=0; j<this.visible_companies.length; j++) {
                    if (this.visible_companies[j].category_score) {
                        temp_array.push(this.visible_companies[j].category_score[this.category_names[i]])
                    }else continue
                }
                response[this.category_names[i]] = this.min(temp_array)
            }
            return response
        },
        all_average(){
            return this.$store.getters['score/getAllAverage']
        },
        visible_max(){
            let temp_array=[]
            for(let j=0; j<this.visible_companies.length; j++) {
                if (this.visible_companies[j].total_score) {
                    temp_array.push(this.visible_companies[j].total_score)
                }else continue
            }
            return this.max(temp_array)
        },
        visible_min(){
            let temp_array=[]
            for(let j=0; j<this.visible_companies.length; j++) {
                if (this.visible_companies[j].total_score) {
                    temp_array.push(this.visible_companies[j].total_score)
                }else continue
            }
            return this.min(temp_array)
        },
        visible_companies(){
            let mode = this.mode_selected // 'all, 'required_info.title'
            //만약 mode가 all이 아니면
            if(mode != 'all') {
                //먼저 해당 모드에서 option selected가 뭔지 확인
                let response = []
                if(this.campaign.required_info.length > 0){
                    let idx = this.campaign.required_info.findIndex(item => {return item.title == mode})
                    let option_selected = this.campaign.required_info[idx].option_selected
                    //temp_target_companies 기업 배열을 순회하면서, required_info title에서 structure가 option_selected 인 아이들만 남겨서 배열로 반환
                    for(let i=0; i<this.temp_target_companies.length; i++){
                        if(this.temp_target_companies[i].required_info[mode] && this.temp_target_companies[i].required_info[mode] == option_selected){
                            response.push(this.temp_target_companies[i])
                        }
                    }
                    return response
                }
                else return this.temp_target_companies
            }
            else return this.temp_target_companies
        },
        all_total_scores() {
            return Array.from(this.visible_companies.map(x => x.total_score))
        },
        isIE() {
            if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (navigator.userAgent.toLowerCase().indexOf("msie") != -1) )
                { return true }
            else
                { return false }
        }      
    },
    created(){
        this.initiateData()
    },
    methods: {
        ...mapActions('score', ['readAllEvaluateResult', 'readReleases', 'saveRelease']),
        ...mapActions('cr_scores', ['readQeCrFactors']),
        ...mapMutations('cr_scores', ['update_report_company']),
        ...mapActions('reviews', ['readConclusionbyCompany', 'readRecommendationsbyCompany']),
        ...mapMutations('reviews', ['update_conclusion_by_company', 'update_is_conclusion_by_company_loaded', 'update_is_recommendations_by_company_loaded']),

        openModal(code){
            this.modal_mode = code
            this.$refs['summary-modal'].show()
        },
        handleSelect(item, id, option){
            // console.log(option);
            this.campaign.required_info.splice(id, 1, {title: item.title, structure: item.structure, option_selected: option })
            this.mode_selected = item.title
        },
        showAll(){
            this.mode_selected = 'all'
        },
        checkNotStartedCompanies(){
            const path = this.$store.state.backend_host + '/read_not_started_company_ids'

            return axios.get(path, { params: {
                campaign_id: this.campaign.campaign_id
            }})
            .then( response => {
                let not_started_company_ids = response.data

                for(let i=0; i<this.temp_target_companies.length; i++)
                {
                    let idx = not_started_company_ids.findIndex(element => element == this.temp_target_companies[i].company_id)
                    if(idx >-1 && this.temp_target_companies[i].total_filled_rate ==0 ){
                        Vue.set(this.temp_target_companies[i], 'has_started', false) 
                    }
                    else{
                        Vue.set(this.temp_target_companies[i], 'has_started', true)
                    }
                }

            })
        },
        initiateData(){
            this.$store.dispatch('readTemplateTree', {template_code:this.campaign.template_code, is_previous:false})
            .then( ()=>{
                this.$store.commit('score/update_category_names', Array.from(this.template_contents.map(x => {
                    if (x.question_name!='기본정보'&&x.question_name!='기본 정보'&&x.question_name!='基本信息'){
                        // console.log(x.question_name);
                        return x.question_name
                    }}).filter(x=>x!=undefined)                    
                    ))
                this.$store.commit('score/update_local_template_tree_contents_original', this.template_contents)
                this.$store.dispatch('score/readAllScoresByCampaign', this.campaign.campaign_id)
                .then(()=>{
                    //평가에 참여하지 않은 기업은 모수에서 제외하기    
                    // let companies_progressed = []
                    // if (this.campaign.campaign_id==75){
                    //     for (let x of this.temp_target_companies){
                    //         if(x.total_score >= 10.5 ){ companies_progressed.push(x) }
                    //     }
                    // }
                    // else {  companies_progressed = this.temp_target_companies
                    // }
                    this.$store.commit('score/update_temp_target_companies', this.temp_target_companies )
                    this.updateChartData()
                    // console.log(this.temp_target_companies);
                })
            })
        },
        updateChartData(){
            if(this.is_score_loaded!=true) return false

            //total score 차트데이터 업데이트
            this.total_score_chart_data.datasets[0].data = [
            this.$store.getters['score/getAllAverage'], //평균, 최대, 최저, 중앙
            this.$store.getters['score/getAllMax'],
            this.$store.getters['score/getAllMin'],
            this.$store.getters['score/getAllMedian']
            ]
            // total score & category_score 차트 년도 label 업데이트
            let temp_label = ''
            let a = this.campaign.steps[0].start_date
            temp_label = a.slice(0,4) + '년'
            this.total_score_chart_data.datasets[0].label = temp_label
            this.category_score_chart_data.datasets[0].label = temp_label

            //category 차트 라벨 업데이트
            this.category_score_chart_data.labels = []
            this.category_names.forEach(item=>{
                this.category_score_chart_data.labels.push(this.$t(item))
            })

            //category 테이블 데이터 업데이트
            this.category_score_chart_data.datasets[0].data = [] //카테고리별 점수 평균
            // console.log(this.temp_target_companies);

            for(let i=0; i<this.category_names.length; i++)
            {
                let temp = this.average(Array.from(this.temp_target_companies.map(x => x.category_score[this.category_names[i]])) )
                this.category_score_chart_data.datasets[0].data.push(temp)
            }
            this.chart_data_loaded = true

        },
        average(target_scores) {
            //점수 재료 배열을 받아서 평균점 반환
            let sum = 0
            let num_of_q = 0
            for (var i=0; i<target_scores.length; i++) {
                if(target_scores[i]!=undefined && Number.isNaN(target_scores[i])==false)
                {
                    sum += target_scores[i]
                    num_of_q += 1
                }
            }
            let temp = Math.round(sum/num_of_q * 10)/10
            return (!Number.isNaN(temp) && temp != Infinity && temp != -Infinity ? temp : '-')
        },
        max(target_scores) {
            //점수 재료 배열을 받아서 최고점 반환
            let temp_array = []
            target_scores.forEach( item => {
                if(item!=undefined && Number.isNaN(item)==false){
                    temp_array.push(item)
                }
            })
            let temp = Math.round(Math.max.apply(null, temp_array) * 10)/10
            return (!Number.isNaN(temp) && temp != Infinity && temp != -Infinity ? temp : '-')
        },
        min(target_scores) {
            //점수 재료 배열을 받아서 최저점 반환
            let temp_array = []
            target_scores.forEach( item => {
                if(item!=undefined && Number.isNaN(item)==false){
                    temp_array.push(item)
                }
                // else if(Number.isNaN(item)==true){
                //     temp_array.push(item)
                // }
            })
            let temp = Math.round(Math.min.apply(null, temp_array) * 10)/10
            return (!Number.isNaN(temp) && temp != Infinity && temp != -Infinity ? temp : '-')
        },
        async goAnswers(target_company){
            // Answers에서 그려줄 Company의 정보를 company_name로 보냄
            // console.log(target_company);
            // console.log(this.template_contents);

            this.$store.commit('questions/update_answer_company', target_company)

            await this.$store.dispatch('makeTemplateTreeByCompanyId', target_company.company_id)
            this.readAllEvaluateResult({campaign_id: this.campaign.campaign_id, company_id: target_company.company_id})
            // TemplateTree.vue에서 Tree를 noneditable하도록 store.quesitons에 state 설정
            this.$store.commit('questions/change_edit_mode', false)

            this.$store.dispatch('questions/readSubsInit', this.template_contents[0].question_contents)
            // Questions에서 마땅한 subs를 읽도록 store.quesitons에 update 보냄
            // this.$store.commit('update_question_composition', {})

            this.$router.push({name:'Answers'})
        },
        // DownloadData(){
        //     this.downloading = true
        //     const path = this.$store.state.backend_host + '/get_csv'

        //     let mode = ''
        //     if (this.campaign.is_public == true) {
        //         mode = 'public'
        //     }

        //     return axios.get(path, { params: {
        //         campaign_id: this.campaign.campaign_id,
        //         mode: mode
        //     }})
        //     .then(response => {
        //         // console.log(response.data);
        //         const url = window.URL.createObjectURL(new Blob(["\ufeff" + response.data], {type: 'text/csv;charset=utf-8;'}));
        //         const link = document.createElement('a');
        //         link.href = url;
        //         link.setAttribute('download', 'test.csv');
        //         document.body.appendChild(link);
        //         link.click();

        //         this.downloading = false
        //     })
        //     .catch(error=>{console.log(error);
        //     })
        // },
        changeToImg(){
            this.is_printing = true
            this.total_score_chart_url = this.$refs['total_score_chart'].$data._chart.toBase64Image()
            this.category_score_chart_url = this.$refs['category_score_chart'].$data._chart.toBase64Image()
        },
        imgToChart(){
            this.is_printing = false
        },
        showReport(item){
            this.report_company = item
            this.report_campaign = this.campaign
            this.$bvModal.show('report-modal')
        },
        showNewReport(item){
            this.update_report_company(item)
            this.readConclusionbyCompany({campaign_id:this.campaign.campaign_id, company_id: item.company_id})
            this.readRecommendationsbyCompany({campaign_id:this.campaign.campaign_id, company_id: item.company_id})
            // this.readCompanyInfo(item.company_id)
            // this.readQeCrScores({company_id: item.company_id, campaign_id: this.campaign.campaign_id})
            // this.readAssessmentInfo({company_id: item.company_id, campaign_id: this.campaign.campaign_id})
            // this.readQeCrFactors({company_id: item.company_id, campaign_id: this.campaign.campaign_id})
            this.$bvModal.show('report-new-modal')
        },
        checkAllowed(target_admin_level){
            if(this.admin_level < target_admin_level){ return false }
            else { return true }
        },
        downloadRawData(){
            const path = this.$store.state.backend_host + '/get_csv'
            let mode = ''
            this.downloading_raw_data = true
            if (this.campaign.is_public == true) {
                mode = 'public'
            }

            return axios.get( path, { params: {
                campaign_id: this.campaign.campaign_id,
                mode: mode
            }, responseType: 'blob'})
            .catch(error => {
                console.log(error)
            })
            .then( response => {
                // console.log(response)
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.campaign.campaign_name + '_전체기업_데이터.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .finally( () => {
                this.downloading_raw_data = false
            })
        },
        downloadEvaluation(){
            const path = this.$store.state.backend_host + '/evaluate_download'

            this.downloading_evaluation = true

            return axios.get( path, { params: {
                campaign_id: this.campaign.campaign_id
            }, responseType: 'blob'})
            .then( response=> {
                // console.log(response)
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.campaign.campaign_name + '_전체기업_평가결과데이터.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.log(error)
            })
            .finally( () => {
                this.downloading_evaluation = false
            })
        },
        initiateReportContents(){
            this.update_report_company({})
            this.update_conclusion_by_company({auditor_name: '', conclusion: '', time_stamp: ''})
            this.update_is_conclusion_by_company_loaded(false)
            this.update_is_recommendations_by_company_loaded(false)
        }

    },
    mounted(){
        // console.log(this.temp_target_companies);
        // console.log(this.local_template_tree_contents_original);
    },
    // beforeDestroy(){
        // 미로드시 destroy 했을 때 작동하지 않아서 없앰. 대신 UserSubLayout에서 pageGo 할 때 새로 만들어서 보냄
    //     this.$store.commit('score/update_temp_target_companies', this.archived_temp_target_companies)
    // },
    beforeDestroy(){
        this.$store.commit('score/update_temp_target_companies', [])
        this.campaign.scoring_target_companies.forEach(item=>{
            this.$store.commit('score/add_temp_target_companies', { company_name: item.company_name, company_id: item.company_id, required_info: item.required_info })
        })
    },
    components:{
        ReportChart,
        BarChart,
        GeneralPrintButton,
        ReportPreview,
        FullReport
    }
}
</script>

<style>

.blue-bottom {
    border-bottom: 1px solid var(--blue);
    color: var(--blue);
}

.dropdown-simple > .btn-icon-dark {
    text-align: left;
    font-size: 0.95rem;
    padding: 0.3rem;
    overflow: hidden;
}
.dropdown-simple > .dropdown-toggle::after {
    margin-top: 0.5rem;
    float: right;
    border-top: 0.5em solid!important;
    border-right: 0.45em solid transparent!important;
    border-bottom: 0;
    border-left: 0.45em solid transparent!important;
}

.report-chart{
    max-height: 240px;
    width: 90%;
}

</style>
