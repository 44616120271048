<template>
<div class="editing-dfs">

        <!--제목, 컴포넌트 기본 공통 -->
        <div class="p-2 bgray-200 rounded d-flex justify-content-between align-items-center border">
            <div class="d-flex align-items-center">
                <span class="mx-3 f-110">{{ sub_code }}</span>
                <b-dropdown :text="sub_type" variant="dark-border" >
                    <b-dropdown-item-button @click="selectType(item)" v-for="item in sub_types" :key="item.id" > {{ item }} </b-dropdown-item-button>
                </b-dropdown>
            </div>
            <div class="d-flex align-items-center">             
                <b-form-checkbox v-model="sub_data_header.required">필수 응답(Public ONLY)</b-form-checkbox>
                <span class="mx-3 gray-600"> |</span>
                <b-dropdown :text="scoring" size="sm" variant="dark-border">
                    <b-dropdown-item @click="setScoring('none')" >No Scoring</b-dropdown-item>
                    <b-dropdown-item @click="setScoring('auto')">Auto Scoring</b-dropdown-item>
                </b-dropdown>
            </div>
        </div>

        <div class="mt-3 mb-1 fw-600 f-90">Title</div>
        <b-input v-model="sub_name" placeholder="Sub name"> {{ sub_name }} </b-input>

        <div class="mt-3 mb-1 fw-600 f-90">Description</div>
        <b-textarea v-model="sub_description" placeholder="A1. ㅇㅇㅇ을 관리하고 있습니까?"> {{ sub_description }} </b-textarea>

        <div class="mt-3 mb-1 fw-600 f-90">Info</div>
        <b-textarea v-model="sub_info" placeholder="용어설명, 참고사항, 평가기준 등 답변 참고정보" > {{ sub_info }} </b-textarea>

        <div class="mt-3 mb-1 fw-600 f-90" style="cursor:pointer" @click="conditionPopoverToggle()">Condition <fa :id="`sub_condition_information+${sub_code}`" icon="info-circle"></fa></div>
            <b-popover :target="`sub_condition_information+${sub_code}`" title="설명" :content="sub_condition_information" placement="left" :show="sub_condition_popover_show">
            </b-popover>
        <div  id="editing-template-condition">
            <b-form-checkbox v-model="is_condition_available" @input="manageConditionAvailability()" class="editing-dfs">
                <b-form-input placeholder="선행조건 표시내용을 입력해 주세요" v-model="sub_condition.text"> {{ sub_condition.text }} </b-form-input>
            </b-form-checkbox>      
        </div>

        <!-- DF Priority Header 옵션 조정 -->
        <div class="mt-3 mb-3 d-flex justify-content-between align-items-center border-bottom">
            <div>
                <span class="fw-600 f-90 mr-2">Mode: </span>       
                <b-dropdown :text="sub_data_header.mode">
                    <b-dropdown-item @click="sub_data_header.mode='dynamic'">dynamic</b-dropdown-item>
                    <b-dropdown-item @click="sub_data_header.mode='static'">static</b-dropdown-item>
                </b-dropdown>
            </div>
            <b-form-checkbox v-model="sub_data_header.duplicated" class="blue">중복 순위(duplicated) 허용</b-form-checkbox>
        </div>
        
            <div v-if="sub_data_header.mode=='dynamic'" class="d-flex justify-content-between align-items-center">
                <div>
                    <span class="mt-3 mb-1 fw-600 f-90 mr-2"> Sub_Code: </span> 
                    <b-dropdown :text="sub_data_header.sub_code">
                        <b-dropdown-item v-for="item in subs_universe" :key="item.id" @click="sub_data_header.sub_code=item.sub_code">
                            {{ item.question_name }} | {{ item.sub_code }} | {{ item.sub_name }} | {{ item.sub_description? item.sub_description.substring(0,10) : '' }}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <b-form-checkbox v-model="sub_data_header.answer_only" class="blue">질문에서 선택한 답변(answer only)만 순위매기기</b-form-checkbox>
            </div>          

            <div v-if="sub_data_header.mode=='static'">          
                <span class="mt-3 mb-1 fw-600 f-90" style="cursor:pointer" @click="contentsPopoverToggle()">
                Structure
                <fa :id="`sub_data_contents_information+${sub_code}`" icon="info-circle"></fa></span>
                <b-popover
                    :target="`sub_data_contents_information+${sub_code}`"
                    title="정의"
                    :content="sub_data_contents_information"
                    placement="left"
                    :show="sub_data_contents_popover_show"
                ></b-popover>
            </div>    

        <b-table-simple>
            <tr>
                <td> text </td><td> value </td> <td> 삭제 </td>
            </tr>
            <tr v-for="(item, index) in sub_data_contents.structure" :key="item.id">
                <td> 
                    <div> <b-form-input v-model="item.text">{{ item.text }}</b-form-input></div>
                </td>
                <td> 
                    <div> <b-form-input v-model="item.value">{{ item.value }}</b-form-input></div>
                </td>
                <td>
                    <b-button @click="sub_data_contents.structure.splice(index,1)" variant="icon" class="p-1"><fa icon="trash-alt"></fa></b-button>
                </td>
            </tr>
        </b-table-simple>
        <div class="text-right">
            <b-button @click="sub_data_contents.structure.push({ text:'', value: '' })" variant="blue" size="sm">순위 선택지 추가</b-button>
        </div>
         
        <!-- Tags, Child 등 기본 공통 -->
        <div class="mt-4 d-flex editing-dfs">
            <span class="fw-600 mr-5 f-90">Tags </span>
            <b-input v-model="tags" placeholder="띄어쓰기 대신 _ 사용, 쉼표로 구분"> {{ tags }}</b-input>
        </div>

        <div v-if="checkAbleChild" class="mt-3 mb-4 d-flex">
            <span class="fw-600 mr-5 f-90">Child</span>
            <div class="bgray-200 w-100 p-2 rounded "><b-form-checkbox v-model="has_child" @change="handleHasChild" >파일 및 URL 첨부</b-form-checkbox></div>
        </div>

        <div class="mt-4 d-flex editing-dfs">
            <span class="fw-600 mr-5 f-90">Priority</span>
            <b-form-select v-model="sub_priority" :options="sub_priority_option"></b-form-select>
        </div>

        <div class="float-right">
            <b-button @click="handleSaveSub()" variant="blue" class="mr-2">저장</b-button>
            <b-button @click="$emit('click-cancel')" variant="dark">취소</b-button>
        </div>
</div>
</template>

<script>
import { EditingSubComponentMixin } from './EditingSubComponentMixin'

export default {
    mixins: [EditingSubComponentMixin],
    data() {
        return {
            sub_data_contents_information: `
                text: 표시될 텍스트입니다. 
                value: 내부적으로 저장될 값입니다. text와 같을 수 있습니다. 
            `,

            subs_universe: [],
            
        }
    },
    computed:{
        template_code(){
            return this.$store.state.template_tree.template_code
        }
    },
    methods:{
        handleSaveSub(){
            this.saveSub()
            .then(this.$emit('click-save'))
        },
        addCheckboxItem(){
            let new_structure = {
                text: '',
                value: '',
                description: '',
                example: []
            }
            console.log(this.sub_data_contents)
            
            if(!this.sub_data_contents.structure) {
                Vue.set(this.sub_data_contents,'structure',[])
            }

            this.sub_data_contents.structure.push(new_structure)
        },
        prepareSubsUniverse(){
            const path = this.$store.state.backend_host + '/prepare_template_subs_list'

            return axios.get(path, { params: {
                template_code: this.template_code
            }})
            .then(response=>{
                this.subs_universe = []
                response.data.forEach(item => {
                    if(item.sub_type=='materiality' || item.sub_type=='checkbox')
                    this.subs_universe.push(item)

                    // console.log("유니버스 준비 결과")
                    // console.log(response.data)
                })
                    //'question_name': q.question_name,
                    //'sub_code': q.sub_code,
                    //'sub_name': q.sub_name,
                    //'sub_type': q.sub_type,
                    //'sub_data_contents': q.sub_data_contents
                
                // console.log(this.subs_universe)
            })
        },
        initiateData(){
            this.prepareSubsUniverse()
            if(!this.sub_data_header.mode) Vue.set(this.sub_data_header,'mode','static')
            if(!this.sub_data_header.answer_only) Vue.set(this.sub_data_header,'answer_only',false)
            if(!this.sub_data_header.sub_code) Vue.set(this.sub_data_header,'sub_code','')
            if(!this.sub_data_header.duplicated) Vue.set(this.sub_data_header,'duplicated', false)
        }
    },
    created(){
        console.log("EditingDFPriority")
        this.initiateData()
    }
}
</script>