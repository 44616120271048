<template>
    <div>
        <div v-if="read_only!=true">
            <b-table-simple  hover class="" >
                <b-thead><b-tr>
                    <b-td> No.</b-td><b-td class="w-100"> Scoring Method </b-td> <b-td> Score </b-td><b-td> Add/Del </b-td>
                </b-tr></b-thead>
                <b-tbody>
                    <b-tr v-for="(item, index) in scoring_structure" :key="index">
                        <b-td> {{ index+1 }} </b-td>
                        <b-td class="text-left py-2">
                            <span class="f-90">Scoring Formula</span>
                            <b-textarea row="1" class="mb-2" :class="temp_new==true? 'blue4':''" v-model="item.formula">{{ item.formula }}</b-textarea>
                                <span class="f-90">Index Name</span>
                            <b-form-input v-model="item.name" :class="temp_new==true? 'blue4':''" @input="item.reverse_name=getRevereseName(item.name)">{{ item.name }} </b-form-input> 
                        
                            <span class="f-90">Reverse Name</span>
                            <b-form-input v-model="item.reverse_name" :class="temp_new==true? 'blue4':''">{{ item.name }} </b-form-input>
                        </b-td>
                        <b-td>
                            <b-dropdown :text="item.score" :variant="item.score=='-1' ? 'red-border' : 'blue-border'" class="mb-3" :disabled="read_only==true">
                                <b-dropdown-item @click="setScoreMode('plus2', index)"> +2 </b-dropdown-item>
                                <b-dropdown-item @click="setScoreMode('plus', index)"> +1 </b-dropdown-item>
                                <b-dropdown-item @click="setScoreMode('plus_half', index)"> +0.5 </b-dropdown-item>
                                <b-dropdown-item @click="setScoreMode('minus', index)"> -1 </b-dropdown-item>
                            </b-dropdown>
                            <b-dropdown :text="item.applicable" :variant="item.applicable=='partial' ? 'red-border' : 'blue-border'" :disabled="read_only==true">
                                <b-dropdown-item @click="setApplicable('partial', index)">partial </b-dropdown-item>
                                <b-dropdown-item @click="setApplicable('full', index)">full </b-dropdown-item>
                            </b-dropdown>
                        </b-td>
                        <b-td> 
                            <b-button class="mb-2" @click="addMethod" variant="dark7" size="sm"> 추가 </b-button> 
                            <b-button @click="deleteMethod(index)" variant="dark5" size="sm"> 삭제 </b-button> 
                        </b-td>
                    </b-tr>                
                </b-tbody>
            </b-table-simple>
        </div>
        <div v-else>
            <b-table-simple v-if="temp_new!=true" hover class="f-90 table-text-left" >
                <b-thead><b-tr class="bgray-200">
                    <b-td style="max-width:14px;" class="p-0 "> N.</b-td><b-td colspan="2" > 점수조건 </b-td>
                </b-tr></b-thead>
                <b-tbody v-for="(item, index) in scoring_structure" :key="index">
                    <b-tr>
                        <b-td class="border-right bgray-200" rowspan="4" style="max-width:14px;"> {{ index +1 }}</b-td>
                    </b-tr>
                    <b-tr >
                        <b-td> 조건: </b-td><b-td>{{ item.formula }}</b-td>
                    </b-tr>
                    <b-tr >
                        <b-td> 충족: </b-td><b-td>{{ item.name }}</b-td>
                    </b-tr>
                    <b-tr >
                        <b-td> 미충족: </b-td><b-td>{{ item.reverse_name }}</b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <div v-else class="py-5 text-center gray-500">
                이 문항은 점수 조건이 정의되지 않았습니다. 
            </div>
        </div>
        <!-- Structure 미리보기 -->
        <b-card v-if="read_only==false" class="mb-4 bgray-200" :header="`${sub_code} / Preview`" id="structure-preview"> 

            <b-table-simple class="my-0" v-if="sub_type!='table'">
                <tr class="gray-500">
                    <td>{{ this.sub_type }}</td> <td> text </td><td> value </td>
                </tr>
                <tr v-for="(item, index) in sub_data_contents.structure" :key="item.id">
                    <td> {{ index }}</td>
                    <td> {{ item.text }}</td>
                    <td> {{ item.value }}</td>
                </tr>
            </b-table-simple>

            <b-table-simple class="my-0" v-else>
                <tr class="gray-500">
                    <td>{{ this.sub_type }}</td>
                    <td v-for="(col_item, index) in sub_data_contents.table_header" :key="col_item.id"> {{ alphabet[index] }} </td>
                </tr>
                <tr class="gray-500">
                    <td></td>
                    <td v-for="item in sub_data_contents.table_header" :key="item.id">{{item}}</td>
                </tr>
                <tr v-for="(row_item, row_index) in this.sub_data_contents.structure" :key="row_item.id">
                    <td class="gray-500"> {{ row_index }} </td>
                    <td v-for="col_item in sub_data_contents.table_header" :key="col_item.id">
                        {{row_item[col_item]}}
                    </td>
                </tr>
            </b-table-simple>
        </b-card>
        
        <div v-if="read_only==false" class="text-right">
            <b-button variant="blue" class="mr-2" @click="handleSave">저장</b-button>
            <b-button variant="dark" @click="$emit('click-cancel')">취소</b-button>
        </div>    

    </div>
</template>

<script>
import * as ih from '@/util'

export default {
    data() {
        return {
            scoring_structure: [],

            formula: '',
            name: '',
            reverse_name: '',
            score: '',
            temp_new: false,

            sub_data_header: {},
            sub_data_contents: {},
            sub_description: '',
            alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
        }
    },
    props:{
        sub_code: { type: String, default: '' },
        sub_type: { type: String, default: '' },
        read_only: {type: Boolean, default: false }
    },
    computed:{

    },
    methods:{
        setScoreMode(mode, x){
            if(mode=='plus2') {
                Vue.set(this.scoring_structure[x], 'score', '+2')
            }
            else if(mode=='plus') {
                Vue.set(this.scoring_structure[x], 'score', '+1')
            }
            else if(mode=='plus_half') {
                Vue.set(this.scoring_structure[x], 'score', '+0.5')
            }
            else if(mode=='minus') {
                Vue.set(this.scoring_structure[x], 'score', '-1')
            }
        },
        setApplicable(str, x){
            if(str=='partial') {
                Vue.set(this.scoring_structure[x], 'applicable', 'partial')
            }
            else if(str=='full') {
                Vue.set(this.scoring_structure[x], 'applicable', 'full')
            }
        },
        addMethod(){
            let new_scoring_method = {}
            
            if(this.sub_type=='radio') {
                new_scoring_method.formula = "result=='예'"
            }
            else if(this.sub_type=='checkbox') {
                new_scoring_method.formula = "'value' in result"
            }
            else if(this.sub_type=='input_number') {
                new_scoring_method.formula = "result >= 75"
            }
            else if(this.sub_type=='table') {
                new_scoring_method.formula = "structure[0]['FY2019'] >= 0"
            }
            else if(this.sub_type=='file_url2') {
                new_scoring_method.formula = "result[0]['filename'] !=''"
            }            
            else { 
                new_scoring_method.formula = ''               
            }

            if(this.sub_type=='radio'||this.sub_type=='input_number') {
                new_scoring_method.name = '참고 '+this.sub_description
                new_scoring_method.reverse_name = this.getRevereseName(new_scoring_method.name)
            }
            else { 
                new_scoring_method.name = ''
                new_scoring_method.reverse_name = ''               
            }

            new_scoring_method.score = '+1'
            
            this.scoring_structure.push(new_scoring_method)
        },
        addFullCheckboxMethod(){
            for (let i=0; i<this.sub_data_contents.structure.length; i++){
                let new_scoring_method = {}
                let value = this.sub_data_contents.structure[i].value
                
                new_scoring_method.formula = "'"+value+"'"+" in result"
                new_scoring_method.name = value
                new_scoring_method.reverse_name = this.getRevereseName(new_scoring_method.name)                   
                new_scoring_method.score = '+1'
            
                this.scoring_structure.push(new_scoring_method)
            }
        },
        handleSave(){
            this.saveScoringMethod()
            // .then( this.$emit('click-save') )
        },
        saveScoringMethod(){
            const path = this.$store.state.backend_host + '/save_evaluations'

            let evaluations = ih.deepCopy(this.scoring_structure)
                   
            const formData = new FormData()
            formData.append('sub_code', this.sub_code)
            formData.append('evaluations', JSON.stringify( evaluations ))

            axios.post(path, formData)
            .then(response => {
                // console.log(response)
                EventBus.$emit('toast-success','성공','점수계산 방법을 저장하였습니다.')
                this.$emit('scoring_method_saved')    
            })
            .catch(error => {
                console.log(error)
            })


        },
        readScoringStructure(){
            const path = this.$store.state.backend_host + '/read_evaluations'
            return axios.get(path, {params:{sub_code: this.sub_code}})
            .then(response => {
                // console.log(response.data);
                this.scoring_structure = response.data
                if (this.scoring_structure.length==0){
                    this.temp_new = true
                    this.$emit('score_not_defined', true)
                    if (this.sub_type!='checkbox'){
                        this.addMethod()
                    }
                    else{
                        this.addFullCheckboxMethod()
                    }
                }else {               
                    for(let i=0; i<this.scoring_structure.length; i++){ 
                        if (this.scoring_structure[i].score==1){
                            this.scoring_structure[i].score='+1'
                        }
                        else if (this.scoring_structure[i].score==2){
                            this.scoring_structure[i].score='+2'
                        }
                        else if (this.scoring_structure[i].score==0.5){
                            this.scoring_structure[i].score='+0.5'
                        }
                        else this.scoring_structure[i].score='-1'
                    }
                }       
            })
            .catch(error =>{
                console.log(error);
            })
        },
        deleteMethod(j){
            this.scoring_structure.splice(j,1)
            this.saveScoringMethod()            
        },
        getRevereseName(text){
            // console.log(text);
            //0. trim 처리
            let target_text = text.replace(".", '').trim()
            //1. 있음 <-> 없음 처리
            if(target_text.match(/있음$/gi) != null){
                target_text = target_text.replace(/있음$/gi, '없음')
            }
            else if(target_text.match(/없음$/gi) != null){
                target_text = target_text.replace(/없음$/gi, '있음')
            }

            //2. 함 <-> 하지 않음 처리
            if(target_text.match(/함$/gi) != null){
                target_text = target_text.replace(/함$/gi, '하지 않음')
            }
            else if(target_text.match(/하지 않음$/gi) != null){
                target_text = target_text.replace(/하지 않음$/gi, '함')
                
            }
            else if(target_text.match(/하지않음$/gi) != null){
                target_text = target_text.replace(/하지않음/gi, '함')
            }

            //3. 받음 <-> 받지 않음 처리
            if(target_text.match(/받음$/gi) != null){
                target_text = target_text.replace(/받음$/gi, '받지 않음')
            }
            else if(target_text.match(/받지 않음$/gi) != null){
                target_text = target_text.replace(/받지 않음$/gi, '받음')
            }
            else if(target_text.match(/받지않음$/gi) != null){
                target_text = target_text.replace(/받지않음/gi, '받음')
            }

            //4. 이상<->미만, 이하<->초과
            if(target_text.match(/이상/gi) != null){
                target_text = target_text.replace(/이상/gi, '미만')
            }
            else if(target_text.match(/미만/gi) != null){
                target_text = target_text.replace(/미만/gi, '이상')
            }
            else if(target_text.match(/이하/gi) != null){
                target_text = target_text.replace(/이하/gi, '초과')
            }
            else if(target_text.match(/초과/gi) != null){
                target_text = target_text.replace(/초과/gi, '이하')
            }
            
            return target_text
        }
    },
    created(){
        this.readScoringStructure()

        this.idx = this.$store.state.question_subs.findIndex( item => { return item.sub_code == this.sub_code })
        if (this.idx > -1){
            this.sub_data_contents = this.$store.state.question_subs[this.idx].sub_data_contents         
            this.sub_description = this.$store.state.question_subs[this.idx].sub_description
        }
        
    }

}
</script>

<style>
#structure-preview > .card-header {
    padding: 0.25rem 1.25rem;
}
#structure-preview > .card-body > .table td{
    text-align: left;
}
</style>