<template>
<div >
    <div :class="[previous!=true ? '':'bgray-200 p-3 gray-600 rounded', 'mb-5']">
        <div v-if="headers">

                <div :class="[ sub_name!= '' ? 'fw-500 f-110 border-bottom mb-2':'', '' ]"> <span v-if="sub_data_header.required && sub_data_header.required==true">*</span>{{  byLang(sub_name,sub_name_e, sub_name_c) }} </div>
                <div :class="[ sub_description != '' ? 'mb-3': '', 'mb-1']">
                    <div v-if="is_indicate_description" class="mr-1 fw-500 d-inline px-0"> {{ byLang(sub_description,sub_description_e, sub_description_c) }} </div>
                    <span v-for="item in tags" :key="item.id"> <span v-if="item != ''" class="ml-2 f-80 gray-500"> {{ item }} </span></span>
                    <b-button  v-if="sub_info != ''" class="ml-1 px-1 py-0 f-80" variant="teal" size="sm" :id="`i-${ sub_code }`" @click="toggleVisible" ><fa icon="book-open"></fa> {{byLang('작성 도움말', 'Info', '信息')}}</b-button>

                    <span v-if="sub_priority != ''"><b-badge :variant="badge_var" class="mx-2">{{ sub_priority }}</b-badge></span>
                    <b-button v-if="previous_sub.sub_code!=undefined && previous!=true" v-b-toggle="previous_sub.sub_code" size="sm" variant="icon" class="ml-2" v-b-tooltip.hover title="check latest years' answer"><fa icon="history"></fa></b-button>

                    <!-- sub_info 정보 -->
                    <div v-if="is_visible" :id="`sub_info-${ sub_code }`" class="mt-2 mb-3 px-1 blue f-90"> <fa icon="info-circle" class="mr-1"></fa><span v-html="sub_info_c"></span></div>

                    <!-- sub_condition -->
                    <div v-if="is_sub_condition_activated==true" :id="`sub_condition-${sub_code}`" class="my-2 py-2">
                        <b-form-checkbox :disabled="is_locked || is_disabled_by_parent" v-model="sub_condition.value" @change="radioSave()"> {{  byLang(sub_condition.text, sub_condition.text_e, sub_condition.text_c)}} </b-form-checkbox>
                    </div>
                </div>
 

        </div>


        <!-- <b-overlay :show="is_visible_by_pipeline==false" rounded="sm" opacity="0.6" bg-color="#c8c8c8"> -->

            <!-- Materiality Table 유형 Sub Component-->
            <b-form-checkbox-group v-model="sub_data_contents.result" @change="handleSave()" :state="is_valid_state">
                <div v-for="(item, index) in sub_data_contents.structure" :key="item.id">
                    <b-row no-gutters v-if="item.is_seperator && item.is_seperator == true" class="w-100 mb-2 pl-3 py-1 bgray-200">
                        <b-col class="fw-400 f-100">{{ item.text }}</b-col>
                    </b-row>
                    <b-row no-gutters v-else class="m-0 p-0 pl-3 w-100">                
                        <b-form-checkbox
                        :disabled="is_locked==true || is_disabled_by_condition==true || is_disabled_by_parent==true"
                        :value="item.value" class="m-0"
                        @mouseover="showTooltip($refs[index+'example-check'])">
                        </b-form-checkbox>
                
                        <b-col class="text-left mb-4">
                            <span :id="index+'example'">
                            <span class="f-105 fw-500 mb-2 gray-800"> {{ item.text }} </span> <br>
                            <span class="f-90 fw-300 gray-500"> {{ item.description }} </span>
                            </span>
                        </b-col>
                                
                        <b-tooltip :target="index+'example'" :ref="index+'example-check'" id="example-tooltip" placement="right" fallback-placement="clockwise" delay="0">
                            <ul class="custom-ul">
                                <li v-for="example_item in item.example" :key="example_item.id" class="f-90 fw-300 gray-300"> {{ example_item }}</li>
                            </ul>
                        </b-tooltip>
            
                    </b-row>
                </div>
                <b-form-invalid-feedback :state="is_valid_state">{{ sub_data_header.max_select }} 개 까지 선택할 수 있습니다.</b-form-invalid-feedback>
            </b-form-checkbox-group>
            <div v-if="previous != true" :class="validation_style" class="mt-2"><fa v-if="validation_icon !=''" :icon="validation_icon"></fa> {{ validation_comment }} </div>

            <!-- Child Component -->
                <sub-component v-for="(item, index) in child_subs" :key="item.id"
                :is_final_sub_component="index == child_subs.length -1 ? true : false"
                :disabled="is_locked || is_disabled_by_condition || is_disabled_by_parent" 
                :sub_type="item.sub_type" 
                :item="item" 
                :audit_mode="audit_mode" 
                :is_disabled_by_parent="is_disabled_by_parent" ></sub-component>              
            
            <!-- is_visible_by_pipeline 조건에 따른 해당없음 overlay -->
            <!-- <template v-slot:overlay>
                <div class="text-center ">
                    해당사항 없음 <b-button variant="icon" v-b-tooltip.hover title="이 문항은 답변할 필요가 없습니다."><fa icon="info-circle"></fa></b-button>
                </div>
            </template> -->
        <!-- </b-overlay> -->
    </div>


    <!-- previous component -->
    <b-collapse v-if="print_mode!=true" :id="previous_sub.sub_code">
        <sub-component :disabled="true" v-if="previous_sub.sub_code != undefined && previous != true" :is_disabled_by_parent="true" :sub_type="previous_sub.sub_type" :item="previous_sub" :previous="true"></sub-component>
    </b-collapse>

</div>
</template>


<script>
import { SubComponentMixin } from './SubComponentMixin'

export default {
    mixins: [SubComponentMixin],

    data(){
        return {
            button_title: this.$t(`평가정보`)
            //sub_data_contents: {
            //    structure: [ 
            //                { 
            //                    text: '전략 일반 - M&A 기반 시너지 창출 전략',
            //                    value: '전략 일반 - M&A 기반 시너지 창출 전략', 
            //                    description: '2019년 12월 넷마블의 코웨이 인수로 인한 시너지 창출 기대, 최대주주 변경에 따른 경영 전략의 변화',
            //                    example: ['넷마블의 IT기술력과 코웨이의 국내 1위 생활가전 렌탈 서비스 역량의 시너지에 대한 기대', '2019년 매출 3조원 돌파, 역대 최대 성과 창출']
            //                },
            //                {
            //                    text: '판매관행 및 제품 라벨링 - 브랜드 신뢰도 강화',
            //                    value: '판매관행 및 제품 라벨링 - 브랜드 신뢰도 강화', 
            //                    description: '브랜드 전략 내 지속가능한 판매(마케팅) 및 서비스 관행, 제품 정보제공의 투명성을 포함, 지속가능하고 신뢰감 있는 브랜드 가치 구축',
            //                    example: ['브랜드 전략 내 지속가능성 통합', '브랜드 고객 만족도, 소비자가 뽑은 참 좋은 브랜드, 베스트 코리아 브랜드 등 각종 수상']                                
            //                } 
            //            ],
            //    result: []
            //}             
        }
    },
    computed:{
        is_valid_state(){
            if(this.sub_data_header.limited && this.sub_data_header.limited==true){
                if(this.sub_data_header.max_select && this.sub_data_contents.result.length > this.sub_data_header.max_select) return false
            }
            return null
        },
    },
    methods: {
        byLang(ko, en, cn){
            if(this.$i18n.locale=='en') return en
            else if (this.$i18n.locale=='cn') return cn
            else return ko
        },
        handleSave(){
            this.$nextTick(async () => {
                if(this.sub_data_header.limited && this.sub_data_header.limited==true){
                    if(this.sub_data_header.max_select && this.sub_data_contents.result.length > this.sub_data_header.max_select) 
                    { return false }
                }
                
                await this.radioSave()
                console.log('저장되었습니다')
            })
        }
    },
    created(){

    }
}


</script>

<style>
#example-tooltip{
    max-width: 520px;
}
#example-tooltip > .tooltip-inner{
    max-width: 520px;
    text-align: left;
    padding: 0.5rem 1rem;
}
</style>