<template>
    <div>
        <h4 class="mt-3 mb-3 f-140"> {{$t(`문항별 제출현황`)}} </h4>
        <!-- <section class="mb-3">
            <div class="mb-2 d-flex align-items-end justify-content-between">
                <div class="fw-500"> 문항 <span class="f-180 ml-2"> {{ progress_rate }}</span>% </div>
                <div class="text-right f-90"> 전체 {{ number_of_questions }}개 문항 중 {{ number_of_completed_questions }}개 문항 제출 </div>
            </div>
            <b-progress id="sup-progress-bar" height="1rem">
                <b-progress-bar :value="progress_rate"></b-progress-bar>
            </b-progress>
        </section>  -->
        <template v-if="is_progress_rate_loaded==true">
            <section class="mb-45 px-2 px-lg-5"> 
                <div class="f-105 text-center mb-1"> {{$t(`전체 제출률`)}} <span class="f-180 ml-2 fw-500"> {{ total_progress_rate[0].completed_rate?Math.round(total_progress_rate[0].completed_rate*100):progress_rate }}</span>% </div>
                <b-progress id="sup-progress-bar" height="0.7rem">
                    <b-progress-bar :value="total_progress_rate[0].completed_rate?total_progress_rate[0].completed_rate*100:progress_rate"></b-progress-bar>
                </b-progress>
            </section>
            <section v-show="is_progress_received" class="d-flex flex-wrap justify-content-around mt-2 mb-5">
                <div id="progress-charter" v-for="(item, index) in category_progress_rate" :key="index" >
                    <progress-chart :category_name="$t(item.category)" :progress_rate="item.progress_rate" @progress-received="progressReceived" :background_color="company_category_dounut" class="progress-charter"></progress-chart>
                    <div id="progress-dounut-inner"> <span class="f-160 fw-600">{{ item.progress_rate }}</span>% </div>
                </div>
            </section>            
            <b-card class="bgray-100 gray-550 f-95">
                <p class="mb-1"><fa icon="info-circle" class="mr-1"></fa> {{$t(`답변 완료 후 각 문항 오른쪽 하단의 '제출하기'를 클릭하면 문항이 제출상태로 표시됩니다.`)}}</p> 
                <fa icon="info-circle" class="mr-1"></fa> {{$t(`우측 패널의 '전체 답변 제출'을 클릭하시면 모든 문항이 제출완료로 처리됩니다.`)}}
            </b-card>
        </template>
        
    </div> 
</template>
<script>
import { mapState, mapActions} from 'vuex'
import ProgressChart from '@/components/charts/ProgressChart'

export default {
    data () {
        return {
            is_progress_received: false,
            company_category_dounut: ['rgba(73,73,73,1)']
        }
    },
    computed: {
        ...mapState(['campaign_in_progress' ]),
        ...mapState('campaign_users', ['users_in_company', 'total_filled_rate', 'is_filled_rate_loaded', 'total_progress_rate', 'is_progress_rate_loaded']),
        campaign_description() {
            return this.campaign.campaign_description
        },
        number_of_questions() {
            return this.$store.getters.getNumberOfQuestions
        },
        number_of_completed_questions() {
            return this.$store.getters.getNumberOfCompletedQuestions
        },
        progress_rate() {
            return this.$store.getters.getProgressRate
        },
        category_progress_rate() {
            return this.$store.getters.getCategoryProgressRate
        },
        admin_level() {
            return this.$store.getters.getAdminLevel
        }
    },
    methods: {
        progressReceived() {
            this.is_progress_received = true
        },
        gotoParticipants(){
            this.$emit('show-participants')
        }
    },
    components: {
        ProgressChart
    }   
}
</script>

<style scoped>


#sup-progress-bar {
    background-color: var(--gray-300);
}

#sup-progress-bar > .progress-bar {
    background-color: var(--gray-700);
}

#category-chart {
    width: 100%;
}

#progress-charter, .progress-charter {
    max-width: 140px;
}
#progress-dounut-inner{
    margin-top: -75px;
    margin-bottom: 60px;
    text-align: center;
}

</style>