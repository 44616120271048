<template>
<div id="corrective-action-report">
    <b-container v-if="audit_recommendations!=[] && corrective_actions!=undefined" :class="is_printing==true ? 'px-5 py-5':'px-0 px-md-3'">
        <div class="text-right">
            <general-print-button @modal-opened="printing(true)"  @modal-closed="printing(false)" print_target="corrective-action-report" class="py-1" :btn_variant="'dark-border'" :d_none="is_printing==true? 'd-none':''">개선계획 보고서 출력 <fa icon="print" class="ml-2"></fa></general-print-button>
        </div>
        <div v-if="is_printing==true" class="mt-3 f-95 d-flex justify-content-between">
            <div>{{ campaign.campaign_name }}</div>
            <div>{{ campaign.start_date +' ~ '+ campaign.end_date }}</div>
        </div>
        <h2 class="pt-4 mb-5"> {{ company_name }} 개선과제 및 개선계획</h2>
        
        <hr class="border-0 pb-4">
    
            <b-row no-gutters class="mb-5">
                <b-col class="pr-4"><h5 class="tail-line gray-550">전문가 제안 개선과제</h5></b-col>
                <b-col><h5 class="tail-line-dark">{{ company_name }} 개선계획</h5></b-col>
            </b-row>

            <b-row no-gutters v-for="(item, index) in audit_recommendations" :key="index" class="pb-4 mb-5 border-bottom">
                <b-col class="d-flex align-items-start text-left pr-3 gray-600" variant="icon">  
                    <b-badge variant="advance" class="mb-2 mr-3">개선과제 {{ index+1 }}.</b-badge>
                    <div><h6 class="f-110"> {{item.title}}</h6>
                    <p> {{ item.recommendation }} </p></div>
                </b-col>

                <b-col>  
                    <b-card class="bg-transparent d-flex" v-if="corrective_action_item[item.rec_code].action_plan!=''">
                        <b-badge variant="basic" class="mr-2"> {{ corrective_action_item[item.rec_code].timeframe }} 목표</b-badge>
                        {{corrective_action_item[item.rec_code].action_plan}}
                    </b-card>  
                    <b-card v-else class="bg-transparent text-center"><fa icon="exclamation-circle" class="mr-2"></fa>제출된 개선계획이 없습니다.</b-card>                  
                </b-col>
            </b-row>

            <div class="text-center" v-if="is_printing!=true">
                <b-button-group>
                    <b-button :disabled="find_monitoring_confirm.confirmed==true"  :variant="find_monitoring_confirm.confirmed==true?'green':'red'" class="" size="lg" @click="saveMonitoringConfirm({campaign_id:campaign.campaign_id, company_id:monitoring_company_id, mode:true })"> 개선계획 컨펌하기 </b-button>
                    <b-button :disabled="find_monitoring_confirm.confirmed!=true" :variant="find_monitoring_confirm.confirmed==true?'green':'red'" class="" size="lg" @click="saveMonitoringConfirm({campaign_id:campaign.campaign_id, company_id:monitoring_company_id, mode:false })"> 컨펌취소 </b-button>
                </b-button-group>
                <div v-if="find_monitoring_confirm.confirmed==true" class="green mt-2"><fa icon="check" class="mr-2"></fa>컨펌 되었습니다.</div>
            </div>

    
            <hr class="border-0 pb-5">
            <div class="f-90 gray-500 d-flex justify-content-between mb-3">
                <div class="tail-line" style="width: calc(100% - 100px)"> {{ campaign.campaign_name }} </div> <div> tierzero.kr </div>
            </div>

        
    </b-container>
    <b-container v-else class="py-5">
        <b-card class="border-0 blue text-center"><b-spinner class="mx-2 p-1 my-1" small type="grow"></b-spinner> 개선과제 및 개선계획을 가져오고 있습니다.</b-card>
    </b-container>

</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import * as ih from '@/util'
import GeneralPrintButton from '@/components/print_to_pdf/GeneralPrintButton'


export default {
    data() {
        return {
            audit_recommendations: [],
            corrective_actions: [],
            // monitoring_confirm: {},
            is_printing: false
        }
    },
    props:{
        monitoring_company_id: {}
    },
    computed:{
        ...mapState('reviews', ['reviews', 'is_recommendations_loaded', 'is_monitoring_confirmed_loaded']),
        ...mapState('score', ['temp_target_companies']),
        campaign() {
            return this.$store.state.campaign
        },
        corrective_action_item(){
            if( this.corrective_actions!=undefined&& this.audit_recommendations!=[]){
                let obj = {}
                this.audit_recommendations.forEach(item => {
                    let idx = this.corrective_actions.findIndex(cor_item => cor_item.rec_code == item.rec_code)
                    if (idx >-1){
                        let temp = { 
                        action_plan: this.corrective_actions[idx].action_plan,
                        timeframe: this.corrective_actions[idx].timeframe,
                        time_stamp: this.corrective_actions[idx].time_stamp,
                        }
                        obj[item.rec_code] = temp
                    }
                    else {
                        let temp = {
                        action_plan: '',
                        timeframe: '',
                        time_stamp: '',
                        }
                        obj[item.rec_code] = temp
                    }
                
                })
                return obj
            }
        },
        company_name(){
            return this.temp_target_companies.find(x => x.company_id == this.monitoring_company_id).company_name            
        },     
        find_monitoring_confirm(){
            let idx = this.reviews.findIndex(item => item.company_id == this.monitoring_company_id)
            if (idx > -1){
                return this.reviews[idx].monitoring_confirm
            }
            else return {}
        },  

    },
    methods:{
        ...mapActions('reviews', ['saveMonitoringConfirm']),
        findAuditRecommendations(){
            let idx = this.reviews.findIndex(item => item.company_id == this.monitoring_company_id)
            if (idx > -1){
                this.audit_recommendations = ih.deepCopy(this.reviews[idx].audit_recommendations)
            }
            else this.audit_recommendations = []
        },
        findCorrectiveActions(){
            let idx = this.reviews.findIndex(item => item.company_id == this.monitoring_company_id)
            if (idx > -1){
                this.corrective_actions = ih.deepCopy(this.reviews[idx].corrective_actions)
            }
            else this.corrective_actions = undefined
        },
        printing(mode){
            this.is_printing = mode
        },
    },
    created(){
        this.findAuditRecommendations()
        this.findCorrectiveActions()
    },
    components:{
        GeneralPrintButton,
    }
}
</script>

<style>

</style>