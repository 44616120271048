<template>
	<div>
		<b-button @click.stop="printStart()" :variant="btn_variant" :size="btn_size"><slot></slot></b-button>
        <div v-if="print_started==true" style="margin-left:-9999px; margin-top:-9999px; width: 1100px; position:absolute;" :key="start_key">
			<div id="printout" :class="is_printing==true? 'printing' : 'not_printing'">
				<div>
					<b-table-simple class="my-5 f-110" bordered>
						<b-tbody class="text-left">
							<b-tr>
								<b-td class="fw-500">{{  $i18n.locale=='ko' ? '캠페인명' : 'ESG Assessment' }}</b-td> <b-td> {{ $i18n.locale=='ko' ? campaign_in_progress.campaign_name : campaign_in_progress.campaign_name_e  }} </b-td>
							</b-tr>
							<b-tr>
								<b-td class="fw-500">{{ $t('평가기간')}}</b-td> <b-td> {{ camgaign_start_date_string }} ~ {{ camgaign_end_date_string }} </b-td>
							</b-tr>
							<b-tr>
								<b-td class="fw-500">{{  $i18n.locale=='ko' ? '주최사': 'Requested by' }}</b-td> <b-td> {{  $i18n.locale=='ko' ? campaign_in_progress.operator_name: campaign_in_progress.operator_name }} </b-td>
							</b-tr>
							<b-tr>
								<b-td class="fw-500">{{ $t('기업명') }}</b-td> <b-td> {{ $store.state.company_name }} </b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>				
				</div>
				<div v-for="(question_code_item, index) in print_target_questions" :key="question_code_item.id">
                    <!-- {{question_code_item}} -->
					<questions-for-print 
					class="text-left"
					@load-started="handleLoadStarted" 
					@load-finished="handleLoadFinished"  
					:question_code="question_code_item" 
					:is_final_question_code="index==print_target_questions.length-1 ? true : false"
					></questions-for-print>				
				</div>
			</div>
        </div>

		<b-modal id="pdf_down_modal" size="lg" @show="is_printing=true" @hide="is_printing=false" hide-footer>
			<template v-slot:modal-title>
				<span> {{$t(`인쇄/다운로드`) }}</span>
			</template>
			<div class="d-block text-center">
				
				<!-- <div class="text-left">진행률 </div> -->
				<!--<div> 전체 개수: {{ num_of_print_target_questions }}</div>
				<div> load 시작 개수: {{ num_of_print_target_questions_started }} </div>
				<div> load 완료 개수: {{ num_of_print_target_questions_loaded }} </div>-->
				<div class="pb-3"> <b-progress :value="num_of_print_target_questions_started+num_of_print_target_questions_loaded" 
                :max="num_of_print_target_questions" animated></b-progress> </div>

				<div v-if="print_ready!=true">
					<div class="gray-500 d-flex align-items-center justify-content-center"> {{ $t(msg) }} </div>
				</div>
				<div v-else class="mt-3">
                    <p class="text-left fw-500"> {{ $t(`PDF로 다운로드 하실 경우 아래 설명을 확인 후 진행해 주세요.`)}}</p>

                    <b-tabs content-class="bg-white pt-3">
                        <b-tab :title="$t(`크롬(Google Chrome) 사용중인 경우`)" active>
                            <b-img v-if="$i18n.locale=='en'" src="@/assets/how_to_print_chrome_en.png" fluid style="max-width: 90%"></b-img>
                            <b-img v-else-if="$i18n.locale=='cn'" src="@/assets/how_to_print_chrome_cn.png" fluid style="max-width: 90%"></b-img>
                            <b-img v-else src="@/assets/how_to_print_chrome.png" fluid style="max-width: 90%"></b-img>
                        </b-tab>
                        <b-tab :title="$t(`엣지(Microsoft Edge) 사용중인 경우`)">
                            <b-img v-if="$i18n.locale=='en'" src="@/assets/how_to_print_edge_en.png" fluid style="max-width: 90%"></b-img>
                            <b-img v-else-if="$i18n.locale=='cn'" src="@/assets/how_to_print_edge_cn.png" fluid style="max-width: 90%"></b-img>
                            <b-img v-else src="@/assets/how_to_print_edge.png" fluid style="max-width: 90%"></b-img>
                        </b-tab>
                    </b-tabs>
                    
					<!--<b-button @click="pdf.save('download.pdf')">다운로드</b-button>-->
					<b-button v-if="isIE!=true" v-print="'#printout'" variant="blue" class="mt-3"> {{ $t(`인쇄 / PDF 다운로드`)}}</b-button>
					<b-button v-else @click="pdf.save('company_report.pdf')" variant="blue">{{ $t(`PDF 다운로드`)}}</b-button>
				</div>
			</div>
			<b-button class="float-right" @click="cancel()" variant="dark">{{ $t(`취소`) }}</b-button>
		</b-modal>
	</div>
</template>
<script>
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import QuestionsForPrint from '@/components/print_to_pdf/QuestionsForPrint'
import * as th from 'tree-helper'
import * as ih from '@/util'

export default {
    components: {
        QuestionsForPrint
    },
	name: 'pdf',
	data () {
		return {
			print_target_questions: [],
			start_key: 1011,
			propTitle: 'mypdf',
			print_started: false,
			print_ready: false,
			pdf: '',
			print_target_questions_started: [],
			print_target_questions_loaded: [],
			msg: 'PDF 파일 준비중... 최대 1분 정도 소요될 수 있습니다.',
			is_printing: false,
		}
    },
    props:{
        btn_size: {type:String, default:''},
        btn_variant: {type:String, default:'dark-border'},
    },
	computed:{
		num_of_print_target_questions(){
			return this.print_target_questions.length * 2
		},
		num_of_print_target_questions_started(){
			return this.print_target_questions_started.length
		},
		num_of_print_target_questions_loaded(){
			return this.print_target_questions_loaded.length
		},
		progress_rate(){
			return Math.floor( (this.num_of_print_target_questions_started + this.num_of_print_target_questions_loaded) / this.num_of_print_target_questions )
		},
		campaign_in_progress(){
			return this.$store.state.campaign_in_progress
		},
		camgaign_start_date_string(){
            return ih.dateToString(this.$store.state.campaign_in_progress.start_date)
        },
        camgaign_end_date_string(){
            return ih.dateToString(this.$store.state.campaign_in_progress.end_date)
		},
		isIE() {
            if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (navigator.userAgent.toLowerCase().indexOf("msie") != -1) )
                { return true }
            else
                { return false }
        }    		
	},
	methods: {
		handleLoadStarted(question_code){
			this.print_target_questions_started.push(question_code)
		},  
		handleLoadFinished(question_code){
			this.print_target_questions_loaded.push(question_code)
			if(question_code == this.print_target_questions[this.print_target_questions.length - 1]){
				while(this.progress_rate < 1)
				{	
					this.print_target_questions_loaded.push('dummy')
				}
			}
		},
		makePDF(sel) {
			window.html2canvas = html2canvas //Vue.js 특성상 window 객체에 직접 할당해야한다.
			let that = this
			let pdf = new jsPDF('p', 'mm', 'a4')
			let canvas = pdf.canvas
			const pageWidth = 210 //캔버스 너비 mm
			const pageHeight = 295 //캔버스 높이 mm
			canvas.width = pageWidth

            let ele = document.querySelector(sel)
			let width = ele.offsetWidth // 셀렉트한 요소의 px 너비
			let height = ele.offsetHeight // 셀렉트한 요소의 px 높이
			let imgHeight = pageWidth * height/width // 이미지 높이값 px to mm 변환

			if(!ele){
				console.warn(selector + ' is not exist.')
				return false
			}

            return html2canvas(ele)
            .then( function(canvas) {
					let position = 0
					const imgData = canvas.toDataURL('image/png')
					pdf.addImage(imgData, 'png', 0, position, pageWidth, imgHeight, undefined, 'slow')

					//Paging 처리
					let heightLeft = imgHeight //페이징 처리를 위해 남은 페이지 높이 세팅.
					heightLeft -= pageHeight
					while (heightLeft >= 0) {
						position = heightLeft - imgHeight
						pdf.addPage();
						pdf.addImage(imgData, 'png', 0, position, pageWidth, imgHeight)
						heightLeft -= pageHeight
					}
					
					return pdf
			})			
		},
		printStart(){
			this.$bvModal.show('pdf_down_modal')
			if(this.print_started != true){				
				this.initiatePrintTargetQuestions()
				this.print_target_questions_started = []
				this.print_target_questions_loaded = []
				this.print_started=true
			}	
		},
		cancel(){
			this.$bvModal.hide('pdf_down_modal')
		},
		initiatePrintTargetQuestions(){
			this.print_target_questions = []
			let template_contents = this.$store.state.template_in_progress.template_contents
			let temp_print_target = []
			th.breadthFirstSearch(template_contents, node => {
				if(node.children == undefined || node.children.length == 0){
					temp_print_target.push(node.question_code)
				}
			})
			this.print_target_questions = temp_print_target
		}
	},
	watch:{
		async progress_rate(new_value){
			if(new_value >= 1){
				if(this.isIE==true) { this.pdf = await this.makePDF('#printout') }
				this.print_ready = true
				this.msg = this.$t(`출력 준비가 완료되었습니다.`)

			}
		}
	}
}
</script>
<style scoped>
.printing{
	display: block;
}

.not_printing{
	display: none;
}
</style>