<template>
<b-container class="pt-5">
    <h4 class="py-3 px-2 fw-600">고객 문의사항 관리</h4>

    <div class="overflow-auto mb-5">    
    <b-table-simple hover responsive class="w-100">
        <b-thead><b-tr class="bgray-700 gray-400">
            <b-td>번호</b-td> <b-td>성함</b-td> <b-td>회사명</b-td> <b-td>소속</b-td> <b-td>전화번호</b-td> <b-td>이메일</b-td> <b-td>관심영역</b-td> <b-td>문의사항</b-td><b-td>문의시간</b-td><b-td>관리</b-td>
        </b-tr></b-thead>
        <b-tbody><b-tr v-for="(item, item_no) in customer_service" :key="item.index" class="f-90 fw-400">
            <b-td> {{ item_no+1 }} </b-td>
            <b-td> {{ item.name }} </b-td>
            <b-td> {{ item.company }} </b-td>
            <b-td> {{ item.department }} </b-td>
            <b-td> {{ item.office }} / {{ item.mobile }} </b-td>
            <b-td> {{ item.e_mail }}</b-td>
            <b-td> {{ parsedInterest(item.interest)}} </b-td>
            <b-td> <b-button @click="showContent(item)" variant="dark-border" size="sm">내용보기</b-button> </b-td>
            <b-td> {{ item.time_stamp }} </b-td>
            <b-td> <b-button @click="archiveCustomerService(item)" variant="dark-border" size="sm"><fa icon="trash-alt"></fa></b-button> </b-td>
        </b-tr></b-tbody>
    </b-table-simple>
    </div>

    <div v-if="show_content!=false" class="position-fixed container" style="bottom: 50px;">
        <b-card header-tag="header" id="cs-content-card" class="shadow" style="width:calc(100% - 40px);">
            <template v-slot:header > 
                <div class="row d-flex align-items-center">
                    <div class="col-12 col-md-6">{{ cs_content.name }}님의 문의사항 내용 </div> 
                    <div class="col-12 col-md-6 text-right f-90"> {{ cs_content.time_stamp }} </div>
                </div>
            </template>
            <b-card-text>             
                {{ cs_content.requirements }}
                <b-button class="my-3 float-right" variant="dark-border" @click="handleHide" size="sm"> 닫기 </b-button>
            </b-card-text>
        </b-card>
    </div>
    <hr class="border-0 bottom-space mb-5">

</b-container>
</template>

<script>
export default {
    data(){
        return {
            customer_service: [ ],
            cs_content: '',
            show_content: false,
        }
    },
    methods: {
        readCustomerService() {
            const path = this.$store.state.backend_host + '/read_customer_service_list'

            return axios.get(path)
            .then(response => {
                // console.log(response);  
                this.customer_service = response.data
                this.customer_service.forEach( item => {
                    item.interest = JSON.parse(item.interest)
                })
            })
        },
        parsedInterest(interest_array) {
            let x = ''
            if (interest_array != undefined)
            return interest_array.toString()
        },
        archiveCustomerService(item) {
            let result = confirm( `${item.name}님의 문의사항을 삭제하시겠습니까?`);
            if(result){
                const path = this.$store.state.backend_host + '/archive_customer_service'

                let formData = new FormData()
                formData.append('customer_service_id', item.item_id)
                return axios.post(path, formData)
                .then(response => {
                    console.log(response);

                    let idx = this.customer_service.findIndex( x => { return x.item_id == item.item_id })
                    if(idx >-1){
                        this.customer_service.splice(idx, 1)
                    }
                    //toast
                    EventBus.$emit('toast-success','고객 문의 아카이브', `${item.name}님의 문의사항을 삭제 했습니다.`)
                    // console.log(this.customer_service);
                    
                })
                .catch(error => {
                    console.log(error)
                })
            }
        },
        showContent(item) {
            this.show_content = true
            console.log(this.show_content + '클릭함');

            var idx = this.customer_service.findIndex( function(x) { return x.item_id == item.item_id} )
            if (idx > -1) {
                this.cs_content = this.customer_service[idx]   
            }                      
        },
        handleHide() {
            this.show_content = false
        }
    },
    created() {
        this.readCustomerService()
    } 

    
}
</script>

<style scoped>
/* #cs-content-card {
    z-index: 50;
    position: fixed;
    bottom: 5rem;
    right: 6rem;
    width: 40rem;
} */

#cs-content-card > .card-body > .card-text {
    padding: 0;
}

</style>