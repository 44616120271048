// User Service 중 Campaigns 관련 Store
// CampaingList.vue 로컬의 campaigns를 Notice에서도 읽어야 해서 Store로 옮겨옴.

const user_campaigns_list = {
    namespaced: true,
    state: {
        campaigns: [],
        is_campaigns_loaded: false
    },

    mutations: {
        update_user_campaigns_list(state, new_campaigns_list){
            state.campaigns = new_campaigns_list
            state.is_campaigns_loaded = true
        },
        update_campaign_status(state, { campaign_id, status }){
            let idx = state.campaigns.findIndex(item => item.campaign_id == campaign_id)
            if(idx >-1){
                let new_campaign = { ...state.campaigns[idx] }
                new_campaign.campaign_state = status
                state.campaigns.splice(idx, 1, new_campaign)
            }
        },
        delete_campaign( state, campaign_id ){
            let idx = state.campaigns.findIndex(item => item.campaign_id == campaign_id)
            if(idx >-1){
                state.campaigns.splice(idx, 1)
            }
        },
        update_is_campaigns_loaded( state, mode ){
            state.is_campaigns_loaded = mode
        }

    },

    getters: {

    },

    actions: {
        readCampaigns({commit, rootState}, user_id){
            commit('update_is_campaigns_loaded', false)
            const path = rootState.backend_host + '/read_campaigns'

            return axios.get(path, {
                            params: {
                                user_id: user_id
                            }
            }) 
            .then(response => {
                // console.log(response.data);
                commit('update_user_campaigns_list', response.data.campaigns)
                commit('update_is_campaigns_loaded', true)
            })
            .catch(error => {
                console.log(error)
            })
        },
        changeStatus({commit, state, rootState }, { campaign, status }){

            let idx = state.campaigns.findIndex(item => item.campaign_id == campaign.campaign_id)
            if(idx >-1){
                const path = rootState.backend_host + '/change_campaign_status'

                const formData = new FormData()
                formData.append('campaign_id', campaign.campaign_id)
                formData.append('status', status)
    
                return axios.post(path, formData)
                .then( response => {
                    commit('update_campaign_status', { campaign_id: campaign.campaign_id, status } )
                })
                .catch(error => {
                    console.log(error)
                })

            }
            else return Promise.resolve()
        },
        deleteCampaign({commit, state, rootState}, campaign){
            const idx = state.campaigns.findIndex( function(item) { return item.campaign_id == campaign.campaign_id })

            if (idx > -1) {
                const path = rootState.backend_host + '/delete_campaign'
                const formData = new FormData()
                formData.append('campaign_id',campaign.campaign_id)

                return axios.post(path, formData)
                .then(response => {
                    const result = response.data.response_message
                    commit('delete_campaign', campaign.campaign_id )
                    EventBus.$emit('toast-success', '알림', `${campaign.campaign_name}을 성공적으로 삭제하였습니다.`)
                })
                .catch(error => {
                    if (error_code == '401')
                        { alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                        this.dispatch('logout').then(() => { this.$router.push({name:'Login'}) })
                    }
                    else {
                        console.log(error)
                    }
                })
                
            }

            
        },
    }
}

export default user_campaigns_list