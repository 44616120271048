<template>
<div>
    <!-- <div style="max-height: 100vh">
        <b-embed id="gsc-esg-training" type="video" aspect="16by9" controls  style="max-height: 100vh">
            <source src="/document/gsc_esg_training.mp4" type="video/mp4">
        </b-embed>
    </div> -->
    <video id="gsc-esg-training" autoplay="autoplay" style="width:100%">
        <source src="/document/gsc_esg_training.mp4" type="video/mp4">
    </video>
</div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    computed:{

    },
    methods:{
        // this.$router.push({name:'Main'})

    },
    created(){
        // $("#gsc-esg-training")[0].play()
        // $('#gsc-esg-training').get(0).play()
    },

}
</script>

<style>

</style>