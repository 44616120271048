<template>
<div id="supplier_report_container">
    <b-container v-if="is_chart_loaded==true" :class="is_printing==true ? 'px-5 py-5':'px-0 px-md-3'">
        <div class="text-right">
            <general-print-button @modal-opened="changeToImg" @modal-closed="imgToChart" v-if="is_chart_loaded==true" print_target="supplier_report_container" class="py-1" :btn_variant="'dark-border'" :d_none="is_printing==true? 'd-none':''">{{$t(`결과 보고서 출력/다운로드`)}} <fa icon="print" class="ml-2"></fa></general-print-button>
        </div>
        <div class="mt-3 mb-3 f-95 d-flex justify-content-between">
            <div>{{ $t(campaign.campaign_name) }}</div>
            <div  :class="is_printing==true ?'':'d-none'">{{ camgaign_start_date_string +' ~ '+ camgaign_end_date_string }}</div>
        </div>

        <h2 class="mb-0 mb-5 tail-line-dark"> {{ report_company.company_name }} {{$t(`평가 결과`)}}</h2>
        
        <hr class="border-0 pb-4">

        <h4 class="fw-600 mb-45 tail-line"> {{$t(`결과 요약`)}}</h4>
        <b-row no-gutters class="mb-4">
            <b-col class="col-4 pr-3">
                <div class="rounded bgray-700 gray-50 py-2 px-3 h-100 d-flex flex-column align-items-between">
                    <div class="f-110"> {{$t(`총점`)}} <span class="fw-300">Total Score</span></div>
                    <h1 class="my-auto pl-2 text-center"> {{ company_total_score }}<span class="fw-500 f-100 ml-2"> / 100</span></h1>
                    <!-- <div class="f-85 fw-300 text-right" >* 평가대상 {{temp_target_companies.length}} 개 기업 중 {{ company_rank }} 위</div> -->
                </div>
            </b-col>
            <b-col class="col-8 pl-3">
                <div v-show="is_printing==false"><report-chart ref="category_result_chart" v-if="is_chart_loaded==true" :chartData="category_score_chart_data" :options="category_score_chart_option" class="category-result-chart"></report-chart></div>
                <div class="overflow-hidden" v-show="is_printing==true"><img style="width:660px; height: 220px;" :src="category_result_chart_url" /></div>                    
            </b-col>
        </b-row>

        <hr class="border-0 mb-5 pb-4">
        <h4 class="fw-600 mb-5 tail-line"> {{$t(`점수 비교표`)}}</h4>
        <div class="text-right" v-if="is_previous_score_loaded==true"><b-button size="sm" v-b-toggle.previous-table>전년도 점수와 비교</b-button></div>
            <b-table-simple hover responsive class="rep-table">
                <b-thead class="fw-500">
                    <b-tr>
                        <b-td></b-td><b-td class="">{{ camgaign_start_date_string.slice(0,4) }} {{ report_company.company_name }}</b-td>
                        <b-td class="">{{$t(`전체 평균`)}}</b-td>
                        <b-td class="">{{$t(`전체 최고점수`)}}</b-td>
                        <b-td class="">{{$t(`전체 최저점수`)}}</b-td>
                        <!-- <b-collapse id="previous-table" class="row">
                            <b-td class="w-50"> 년도 {{ company_name }}</b-td>
                            <b-td class="w-50"> 전년대비 증감 </b-td>
                        </b-collapse> -->
                    </b-tr>
                </b-thead>
                <b-tbody v-if="is_chart_loaded==true">
                    <b-tr class="">
                        <b-td class="border-bottom-dark text-left">{{$t(`총점`)}}</b-td>
                        <b-td class="border-bottom-dark"> {{ company_total_score }} </b-td>
                        <b-td class="border-bottom-dark"> {{ all_average }} </b-td>
                        <b-td class="border-bottom-dark"> {{ all_max }} </b-td>
                        <b-td class="border-bottom-dark"> {{ all_min }} </b-td>
                        <!-- <b-collapse id="previous-table" class="row">
                            <b-td class="w-50"> {{ previous_total_score }} </b-td>
                            <b-td class="w-50"> 전년대비 증감 </b-td>
                        </b-collapse>                    -->
                    </b-tr>
                    <b-tr v-for="item in category_names" :key="item.id" class="">
                        <b-td class="text-left"> {{$t(item)}} </b-td>
                        <b-td> {{ company_category_score[item] ? Math.round(company_category_score[item]*10)/10 : '-' }} </b-td>
                        <b-td> {{ all_category_average[item] }} </b-td>
                        <b-td> {{ all_category_max[item] }} </b-td>
                        <b-td> {{ all_category_min[item] }}  </b-td>
                        <!-- <b-collapse id="previous-table" class="row">
                                <b-td class="w-50"> {{ previous_category_score[item] ? Math.round(previous_category_score[item]*10)/10 : 0 }}</b-td>
                                <b-td class="w-50"> + - </b-td>
                        </b-collapse> -->
                    </b-tr>                
                </b-tbody>
            </b-table-simple>
        

        <div v-if="audit_conclusion.conclusion!='' || audit_recommendations[0].recommendation!=''">
            <hr class="border-0 pb-5">
            <h4 class="fw-600 mb-5 tail-line"> {{$t(`전문가 의견 및 개선과제`)}} </h4>
                <b-row class="mb-4">
                    <b-col class="col-4">
                        <h5 class="mb-3 pl-3 ">{{$t(`전문가 종합 의견`)}}</h5>
                    </b-col>
                    <b-col class="col-8"> 
                        <p>{{audit_conclusion.conclusion}}
                        </p>                       
                    </b-col>
                </b-row>
                <!-- <h5 class="mb-3"> 핵심 개선과제</h5> -->
                <div :class="[audit_recommendations.length<2?'ml-33':'','d-flex flex-wrap']">
                    <b-card  v-for="(item, index) in audit_recommendations" :key="index"
                    class="mx-2 mb-3 bg-transparent no-word-break" 
                    :style="audit_recommendations.length<=2?'width:47%':'width:31%'"> 
                        <b-badge variant="advance" class="mb-2">{{$t(`개선과제`)}} {{ index+1 }}.</b-badge><h6> {{item.title}}</h6>
                        <p> {{ item.recommendation }}
                        </p>
                    </b-card>
                </div>
        </div>
    
    
        <div v-if="is_evaluate_loaded==true">
            <hr class="border-0 mb-5">
            
            <h4 class="fw-600 mb-5 pt-4 tail-line"> {{$t(`상세결과`)}} </h4>  
            <result-report v-if="is_score_loaded==true" :report_company="report_company"></result-report>
        </div>
  
        <hr class="border-0 mb-5 pb-5">
        <div class="f-90 gray-500 d-flex justify-content-between mb-3">
            <div class="tail-line" style="width: calc(100% - 100px)"> {{ campaign.campaign_name }} </div> <div> tierzero.kr </div>
        </div>
    </b-container>
    <b-container v-else class="py-5 text-center"> <b-spinner class="mx-2 p-1 my-1" small type="grow"></b-spinner> {{$t(`결과 보고서를 가져오는 중입니다`)}}... </b-container>


</div>
</template>

<script>
import ReportChart from '@/components/charts/ReportChart'
import BarChart from '@/components/charts/BarChart'
import * as th from 'tree-helper'
import * as ih from '@/util'
import ResultReport from '@/components/provider_landing/scm/ResultReport'
import GeneralPrintButton from '@/components/print_to_pdf/GeneralPrintButton'
import { mapState } from 'vuex'

export default {
    data() {
        return {
            is_printing: false, 
            total_score_chart_url: '',
            category_result_chart_url: '',

            is_received: false,
            total_score_chart_key: 100,
            is_result_confirmed: false,
            auditor_name: '퀀티파이드이에스지',

            category_score_chart_data: {
                labels: [] ,
                datasets: [
                    {
                        label: '',
                        data: [80,70],
                        barPercentage: 0.8,
                        categoryPercentage: 0.6,
                        backgroundColor: ['rgba(31, 64, 255, 0.7)','rgba(0,0,0, 0.1)','rgba(0,0,0, 0.1)','rgba(0,0,0, 0.1)','rgba(0,0,0, 0.1)','rgba(0,0,0, 0.1)','rgba(0,0,0, 0.1)','rgba(0,0,0, 0.1)'],
                        borderColor: ['rgba(31, 64, 255, 1)', 'rgba(0,0,0, 0.2)', 'rgba(0,0,0, 0.2)', 'rgba(0,0,0, 0.2)', 'rgba(0,0,0, 0.2)', 'rgba(0,0,0, 0.2)', 'rgba(0,0,0, 0.2)', 'rgba(0,0,0, 0.2)'],
                        borderWidth: 1,
                        order: 1
                    },
                ]
            },
            category_score_chart_option: {
                maintainAspectRatio: false,
                responsive: true,
                legend: {
                    display:false
                },
                scales: {
                    xAxes: [{
                        // gridLines: {
                        //     color:"rgba(0, 0, 0, 0)"
                        // },
                        ticks: {
                            beginAtZero:true,
                            max: 100,
                            stepSize: 20
                        }                                        
                    }],
                    yAxes: [{
                        gridLines: {
                            color:"rgba(0, 0, 0, 0)"
                        },
                        // ticks: {
                        //     beginAtZero:true,
                        //     max: 100,
                        //     stepSize: 20
                        // },  
                    }]
                }
            },

            is_chart_loaded: false,
            audit_conclusion: {
                auditor_name: '',
                conclusion: '',
                time_stamp: ''
            },
            audit_recommendations:[{
                rec_code: '0',
                auditor_name: '',
                title: '',
                recommendation: '',
                time_stamp: ''
                },
                {
                rec_code: '1', 
                auditor_name: '',
                title: '',
                recommendation: '',
                time_stamp: ''
                }]
            ,
        }
    },
    props:{
        report_campaign:{type:Object},
        report_company:{type:Object}
    },
    computed:{
        ...mapState('user_campaigns_list',['campaigns']
        ),
        ...mapState('score', ['is_score_loaded','is_evaluate_loaded','is_previous_score_loaded', 
        'is_comment_loaded', 'category_names', 'temp_target_companies','previous_temp_target_companies']
        ),
        ...mapState('reviews', ['reviews', 'is_conclusion_loaded', 'is_recommendations_loaded']),
        campaign() {
            return this.$store.state.campaign
        },
        template_contents(){
            return this.$store.state.template_tree.template_contents
        },
        previous_template_tree(){
            return this.$store.state.previous_template_tree
        },
        camgaign_start_date_string(){
            return ih.dateToString(this.$store.state.campaign.start_date)
        },
        camgaign_end_date_string(){
            return ih.dateToString(this.$store.state.campaign.end_date)
        },
        company_rank(){
            let target_companies = ih.deepCopy(this.temp_target_companies)
            // total_score == null에 대한 전처리 필요
            target_companies.sort((a,b)=> b.total_score - a.total_score
            )
            let index = target_companies.findIndex(item => {return item.company_id == this.report_company.company_id})
            
            return index+1
        },
        // company의 영역별 점수
        company_category_score(){
            let temp = {}      
            let idx = this.temp_target_companies.findIndex(item => {return item.company_id == this.report_company.company_id })
            if(idx > -1){
                temp = this.temp_target_companies[idx].category_score
            }
            return temp
        },
        // company의 총점
        company_total_score(){
            let temp = {}      
            let idx = this.temp_target_companies.findIndex(item => {return item.company_id == this.report_company.company_id })
            if(idx > -1){
                temp = this.temp_target_companies[idx].total_score
            }            
            return Math.round(temp * 10)/10
        },
        // company의 영역별 점수
        previous_category_score(){
            let temp = {}      
            let idx = this.previous_temp_target_companies.findIndex(item => {return item.company_id == this.report_company.company_id })
            if(idx > -1){
                temp = this.previous_temp_target_companies[idx].category_score
            }
            return temp
        },
        // company의 총점
        previous_total_score(){
            let temp = {}      
            let idx = this.previous_temp_target_companies.findIndex(item => {return item.company_id == this.report_company.company_id })
            if(idx > -1){
                temp = this.previous_temp_target_companies[idx].total_score
            }            
            return Math.round(temp * 10)/10
        },
        // 전체 기업 영역별 평균 - getters
        all_category_average(){
            return this.$store.getters['score/getCategoryAverage']
        },
        all_category_max(){
            return this.$store.getters['score/getCategoryMax']
        },
        all_category_min(){
            return this.$store.getters['score/getCategoryMin']
        },
        // 전체 기업 평균 - getters
        all_average(){
            return this.$store.getters['score/getAllAverage']
        },
         // 전체 기업 최고점 - getters
        all_max(){
            return this.$store.getters['score/getAllMax']
        },
        // 전체 기업 최하점 - getters
        all_min(){
            return this.$store.getters['score/getAllMin']
        },
        progress_rate: {
            get() {
                return this.$store.getters.getProgressRate
            }
        },
        isIE() {
            if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (navigator.userAgent.toLowerCase().indexOf("msie") != -1) )
                { return true }
            else
                { return false }
        } 
    },
    methods:{
        updateChartData(){
            // console.log('업데이트 차트 데이터 까지 옴');
            
            if(this.is_score_loaded!=false){
                //category 차트데이터 업데이트
                this.category_score_chart_data.labels = []
                this.category_score_chart_data.labels.push(this.$t('총점')) 
                this.category_names.forEach(item=>{
                    this.category_score_chart_data.labels.push((this.$t(item))) 
                })
                // console.log(this.temp_target_companies);
                this.category_score_chart_data.datasets[0].data = []
                this.category_score_chart_data.datasets[0].data.push(this.company_total_score)
                this.category_names.forEach(item => {
                    let company = this.temp_target_companies.find(item => { return item.company_id == this.report_company.company_id})
                    this.category_score_chart_data.datasets[0].data.push(Math.round(company.category_score[item]*10)/10)
                    // console.log( this.category_score_chart_data.datasets[0].data);                    
                })        
                this.is_chart_loaded = true
                // this.updatePreviousScore()
            }
            else this.is_chart_loaded =false
        },
        updatePreviousScore(){      
            if (this.campaign.previous_campaign_id != null && this.campaigns.length!=0) {                
                let idx = this.campaigns.findIndex(item => item.campaign_id == this.campaign.previous_campaign_id)                
                this.campaigns[idx].target_companies_list.forEach(item =>{ 
                    this.$store.commit('score/add_previous_temp_target_companies', { company_name: item.company_name, company_id: item.company_id, required_info: item.required_info })
                })                
                this.$store.dispatch('readTemplateTree', {template_code: this.campaigns[idx].template_code, is_previous: true} )
                .then( ()=>{ 
                    this.$store.commit('score/update_previous_local_template_tree_contents', this.previous_template_tree.template_contents)  
                    this.$store.dispatch('score/readAllPreviousCampaignScores', { campaign_id: this.campaign.previous_campaign_id })
                })
            }  else console.log('previous campaign 없음') 
        },
        average(target_scores) {
            //점수 재료 배열을 받아서 평균점 반환
            let sum = 0
            let num_of_q = 0
            for (var i=0; i<target_scores.length; i++) {
                if(target_scores[i] && Number.isNaN(target_scores[i])==false){
                    sum += target_scores[i]
                    num_of_q += 1
                }
            }
            return Math.round(sum/num_of_q * 10)/10
        },
        changeToImg(){
            this.is_printing = true
            // this.total_score_chart_url = this.$refs['total_score_chart'].$data._chart.toBase64Image()
            this.category_result_chart_url = this.$refs['category_result_chart'].$data._chart.toBase64Image()
        },
        imgToChart(){
            this.is_printing = false
        },
        findAuditConclusion(company_id){
            let idx = this.reviews.findIndex(item => item.company_id == company_id)
            if (idx > -1){
                return ih.deepCopy(this.reviews[idx].audit_conclusion)
            }
            else return undefined
            // console.log(this.reviews)
        },
        findAuditRecommendations(company_id){
            let idx = this.reviews.findIndex(item => item.company_id == company_id)
            if (idx > -1){
                return ih.deepCopy(this.reviews[idx].audit_recommendations)
            }
            else return undefined
        },
        initiateAuditConclusion(){
            let temp_audit_conclusion = this.findAuditConclusion(this.report_company.company_id) 
            //console.log(temp_rev)
            let conclusion_obj = {}
            if(temp_audit_conclusion != undefined){
                    conclusion_obj = temp_audit_conclusion
                }
            else {
                conclusion_obj = {
                    conclusion_id: null,
                    auditor_name: '',
                    conclusion: '',
                    time_stamp: ''
                }
                this.is_editing = true
            }
            this.audit_conclusion = conclusion_obj
        },
        initiateAuditRecommendations(){
            let temp_audit_recommendations = this.findAuditRecommendations(this.report_company.company_id) 

            let temp_rec = []
            if(temp_audit_recommendations == undefined || temp_audit_recommendations.length == 0){
                temp_rec = [{
                rec_code: 'rc'+ Math.floor(Math.random()*10000000000),
                auditor_name: '',
                title: '',
                recommendation: '',
                time_stamp: ''
                },
                {
                rec_code: 'rc'+ Math.floor(Math.random()*10000000000), 
                auditor_name: '',
                title: '',
                recommendation: '',
                time_stamp: ''
                }]
                this.is_editing_recommendation = []
                for(let i=0; i<temp_rec.length; i++){
                    this.is_editing_recommendation.push(true)
                }
            }
            else{
                temp_rec = temp_audit_recommendations
                this.is_editing_recommendation = []
                for(let i=0; i<temp_rec.length; i++){
                    this.is_editing_recommendation.push(false)
                }                
            }
            this.audit_recommendations = temp_rec
        },
    },
    components:{
        ReportChart,
        BarChart,
        ResultReport,
        GeneralPrintButton
    },
    created(){
        if (this.campaign.campaign_id != '' && this.report_company !=undefined) {
                this.$store.dispatch('makeTemplateTreeByCompanyId', this.report_company.company_id)
                this.$store.dispatch('score/readAllEvaluateResult', { campaign_id: this.campaign.campaign_id, company_id: this.report_company.company_id})  
                .then(()=>{      
                    this.updateChartData() 
                    this.initiateAuditConclusion()
                    this.initiateAuditRecommendations()                   
                })
        }
        else console.log('캠페인 못받음')    
    },
    watch:{
        is_score_loaded:{
            handler(new_val, old_val) {
                if (new_val==true){
                    this.updateChartData()
                }
            }
        }
    },
}
</script>

<style>
.total-result-chart {
    max-height: 90px;
    /* margin: 0.5rem 0; */
}

.category-result-chart {
    max-height: 250px;
 }

.score-table > thead > tr > td, .score-table > tbody > tr > td {
    padding:0.5rem 0.75rem;
}
.score-table > tbody > tr:hover, .score-table > .table-hover tbody tr:hover{
    background-color: rgba(180, 180, 180, 0.4)!important;
    color: var(--gray-800)!important;
}
.small-component >table> tr > td {
    padding: 0.3rem 0.25rem;
}
.ml-33{
    margin-left: 33.3333%;
}
</style>