<template>
<div>
    <!-- {{ unique_subs.length }} -->
    <b-card id="searched-sub-component-card" v-for="sub in searched_subs" :key="sub.index" class="shadow-sm mb-3">
        <template v-slot:header>
            <span class="fw-600">{{ sub.sub_name }}</span>
            <b-button @click="addToQuestion" class="float-right" size="sm" variant="dark-border"> 질문에 추가</b-button>
        </template>        
        <div class="f-90 green"> <b-badge variant="advance" class="mr-1">{{ sub.sub_code }}</b-badge> 
        <b-badge variant="basic">{{ sub.sub_type }}</b-badge> 
        </div>
        <hr class="my-2">
        <div id="subdescription">{{ sub.sub_description }}</div>       
    </b-card>
</div>
</template>

<script>
import * as ih from '../../util.js'

export default {
    data() {
        return {
        }
    },
    computed: {
        searched_subs() {
            return this.$store.state.searched_subs
        },
        unique_subs(){
            let uniques = []
            this.searched_subs.forEach((item) =>{
                if (item.sub_description){
                    let idx = this.searched_subs.findIndex((x)=>{
                        item.sub_description == x.sub_description
                    })
                    if (idx>-1){
                        uniques.push(this.searched_subs.idx)
                    }
                }
            })
            return uniques
        },
        question_code(){
            return this.$store.state.question_composition.question_code
        },
    },
    methods: {
        addToQuestion() {           
            var x = {
                sub_code: 'rs' + Math.floor(Math.random() * 1000000000),
                sub_name: this.searched_subs.sub_name,
                sub_type: this.searched_subs.sub_type,
                sub_condition: this.searched_subs.sub_condition,
                sub_description: this.searched_subs.sub_description,
                sub_info: this.searched_subs.sub_info,
                sub_data_header: this.searched_subs.sub_data_header,
                sub_data_contents: this.searched_subs.sub_data_contents,
                sub_priority: this.searched_subs.sub_priority,
                sub_parent: this.searched_subs.sub_parent,
                sub_child: this.searched_subs.sub_child,
                question_code: this.question_code,
                tags: this.searched_subs.tags
            }
            this.$store.commit('add_question_sub', x)
            this.$store.dispatch('saveQuestionSub', x)
            .then( ()=>{
                this.$store.dispatch('saveQuestionComposition')
                .then( () => {
                    EventBus.$emit('toast-success','성공','질문 내용 변경사항을 저장하였습니다.')
                }) 
            })
            // console.log(this.$store.state.question_composition.question_contents);
            // console.log(this.$store.state.question_subs);            
        },
    },
    mounted(){
        // console.log(this.searched_subs.length);
        // console.log(this.unique_subs.length);
        
    },
    
}
</script>

<style scoped>
#searched-sub-component-card > .card-header {
    padding: 0.5rem 1.25rem;
}

</style>
