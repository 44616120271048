<template>
<div class="editing-dfs">
        <!--제목, 컴포넌트 기본 공통 -->
        <div class="p-2 bgray-200 rounded d-flex justify-content-between align-items-center border">
            <div class="d-flex align-items-center">
                <span class="mx-3 f-110">{{ sub_code }}</span>
                <b-dropdown :text="sub_type" variant="dark-border" >
                    <b-dropdown-item-button @click="selectType(item)" v-for="item in sub_types" :key="item.id" > {{ item }} </b-dropdown-item-button>
                </b-dropdown>
            </div>
            <div class="d-flex align-items-center">             
                <b-form-checkbox v-model="sub_data_header.required">필수 응답(Public ONLY)</b-form-checkbox>
                <span class="mx-3 gray-600"> |</span>
                <b-dropdown :text="scoring" size="sm" variant="dark-border">
                    <b-dropdown-item @click="setScoring('none')" >No Scoring</b-dropdown-item>
                    <b-dropdown-item @click="setScoring('auto')">Auto Scoring</b-dropdown-item>
                </b-dropdown>
            </div>
        </div>

        <div class="mt-3 mb-1 fw-600 f-90">Title</div>
        <b-input v-model="sub_name" placeholder="Sub name"> {{ sub_name }} </b-input>

        <div class="mt-3 mb-1 fw-600 f-90">Description</div>
        <b-textarea v-model="sub_description" placeholder="A1. ㅇㅇㅇ을 관리하고 있습니까?"> {{ sub_description }} </b-textarea>

        <div class="mt-3 mb-1 fw-600 f-90">Info</div>
        <b-textarea v-model="sub_info" placeholder="용어설명, 참고사항, 평가기준 등 답변 참고정보" > {{ sub_info }} </b-textarea>

        <div class="mt-3 mb-1 fw-600 f-90" style="cursor:pointer" @click="conditionPopoverToggle()">Condition <fa :id="`sub_condition_information+${sub_code}`" icon="info-circle"></fa></div>
            <b-popover :target="`sub_condition_information+${sub_code}`" title="설명" :content="sub_condition_information" placement="left" :show="sub_condition_popover_show">
            </b-popover>
        <div  id="editing-template-condition">
            <b-form-checkbox v-model="is_condition_available" @input="manageConditionAvailability()" class="editing-dfs">
                <b-form-input placeholder="선행조건 표시내용을 입력해 주세요" v-model="sub_condition.text"> {{ sub_condition.text }} </b-form-input>
            </b-form-checkbox>      
        </div>
                  
        <!-- DF Checkbox 데이터 구조 수정 --> 
        <div class="mt-3 mb-1 fw-600 f-90" style="cursor:pointer" @click="contentsPopoverToggle()">Structure  <fa :id="`sub_data_contents_information+${sub_code}`" icon="info-circle"></fa></div>
            <b-popover :target="`sub_data_contents_information+${sub_code}`" title="정의"  :content="sub_data_contents_information" placement="left" :show="sub_data_contents_popover_show">
            </b-popover>

        <!-- DF Materiality 옵션 조정 -->
        <div class="d-flex justify-content-between align-items-center">
            <b-form-checkbox v-model="sub_data_header.limited" class="py-2 blue">선택 개수 제한</b-form-checkbox>
            <div class="w-50 d-flex" v-if="sub_data_header.limited==true"><b-form-input type="number" v-model="sub_data_header.max_select" class="mr-2"></b-form-input>개
            </div>
        </div>

            <div v-for="(item, index) in sub_data_contents.structure" :key="item.id">
                <b-card v-if="item.is_seperator == true">
                    <b-row bordered>
                        <b-col class="col-2">제목</b-col>
                        <b-col><b-form-input placeholder="구분명(그룹 이름)을 입력해 주세요" v-model="item.text"></b-form-input></b-col>
                    </b-row>
                    <b-row bordered>
                        <b-col>
                            <b-button @click="moveUp(item, index)">위로</b-button>
                            <b-button @click="moveDown(item, index)">아래로</b-button>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card v-else header-tag="header">                  
                    <template v-slot:header>
                        <div class="d-flex justify-content-between">
                            <div>선택지 {{ index+1 }}</div>
                            <b-button @click="sub_data_contents.structure.splice(index,1)" variant="icon" class="p-0"><fa icon="trash-alt"></fa></b-button>
                        </div>
                    </template>
                    <b-row bordered>
                        <b-col class="col-2"> text </b-col> 
                        <b-col> <b-form-input placeholder="화면에 나타날 값을 입력해 주세요" v-model="item.text"></b-form-input></b-col>
                    </b-row>
                    <b-row bordered>
                        <b-col class="col-2"> value </b-col>
                        <b-col> <b-form-input placeholder="선택시 저장될 값을 입력해 주세요" v-model="item.value"></b-form-input></b-col>
                    </b-row>
                    <b-row bordered>
                        <b-col class="col-2"> description </b-col>
                        <b-col> <b-form-input placeholder="주제에 대한 가이드 텍스트를 입력해 주세요" v-model="item.description"></b-form-input></b-col>
                    </b-row>
                    <b-row bordered>
                        <b-col class="col-2"> example </b-col>
                        <b-col>         
                            <div v-for="(example_item, example_index) in item.example" :key="example_item.id">
                                <b-form-input v-model="item.example[example_index]" placeholder="예시 정보를 입력해 주세요"></b-form-input>
                                <b-button @click="item.example.splice(example_index,1)">삭제</b-button>
                            </div>
                            <div><b-button @click="item.example.push('')">예시정보 추가</b-button></div>
                        </b-col>
                    </b-row>
                    <div class="text-right">
                        <b-button @click="moveUp(item, index)" variant="blue-border" class="mr-2"><fa icon="caret-up"></fa></b-button>
                        <b-button @click="moveDown(item, index)" variant="blue-border" class=""><fa icon="caret-down"></fa></b-button>
                    </div> 
                </b-card>
            </div>
           
                <b-button @click="addCheckboxItem()" size="sm">선택지 추가</b-button>
                <b-button @click="addSeperator()" size="sm">구분 줄(Seperation) 추가</b-button>
                  

       <!-- Tags, Child 등 기본 공통 -->
        <div class="mt-4 d-flex editing-dfs">
            <span class="fw-600 mr-5 f-90">Tags </span>
            <b-input v-model="tags" placeholder="띄어쓰기 대신 _ 사용, 쉼표로 구분"> {{ tags }}</b-input>
        </div>

        <div v-if="checkAbleChild" class="mt-3 mb-4 d-flex">
            <span class="fw-600 mr-5 f-90">Child</span>
            <div class="bgray-200 w-100 p-2 rounded "><b-form-checkbox v-model="has_child" @change="handleHasChild" >파일 및 URL 첨부</b-form-checkbox></div>
        </div>

        <div class="float-right">
            <b-button @click="handleSaveSub()" variant="blue" class="mr-2">저장</b-button>
            <b-button @click="$emit('click-cancel')" variant="dark">취소</b-button>
        </div>

</div>
</template>

<script>
import { EditingSubComponentMixin } from './EditingSubComponentMixin'

export default {
    mixins: [EditingSubComponentMixin],
    data() {
        return {
            sub_data_contents_information: `
                text: 체크박스 옆에 표시될 텍스트입니다. 
                value: 체크박스 선택시 저장될 값입니다. text와 같을 수 있습니다. 
                description: 주제에 대한 설명입니다. 
                example: 주제에 대한 예시입니다. 
            `
            //sub_data_contents: {
            //    structure: [ 
            //                { 
            //                    text: '전략 일반 - M&A 기반 시너지 창출 전략',
            //                    value: '전략 일반 - M&A 기반 시너지 창출 전략', 
            //                    description: '2019년 12월 넷마블의 코웨이 인수로 인한 시너지 창출 기대, 최대주주 변경에 따른 경영 전략의 변화',
            //                    example: ['넷마블의 IT기술력과 코웨이의 국내 1위 생활가전 렌탈 서비스 역량의 시너지에 대한 기대', '2019년 매출 3조원 돌파, 역대 최대 성과 창출']
            //                },
            //                {
            //                    text: '판매관행 및 제품 라벨링 - 브랜드 신뢰도 강화',
            //                    value: '판매관행 및 제품 라벨링 - 브랜드 신뢰도 강화', 
            //                    description: '브랜드 전략 내 지속가능한 판매(마케팅) 및 서비스 관행, 제품 정보제공의 투명성을 포함, 지속가능하고 신뢰감 있는 브랜드 가치 구축',
            //                    example: ['브랜드 전략 내 지속가능성 통합', '브랜드 고객 만족도, 소비자가 뽑은 참 좋은 브랜드, 베스트 코리아 브랜드 등 각종 수상']                                
            //                } 
            //            ],
            //    result: []
            //}   
        }
    },
    methods:{
        handleSaveSub(){
            this.saveSub()
            .then(this.$emit('click-save'))
        },
        addCheckboxItem(){
            let new_structure = {
                text: '',
                value: '',
                description: '',
                example: []
            }
            console.log(this.sub_data_contents)
            
            if(!this.sub_data_contents.structure) {
                Vue.set(this.sub_data_contents,'structure',[])
            }

            this.sub_data_contents.structure.push(new_structure)
        },
        addSeperator(){
            let new_seperator = {
                text: '',
                is_seperator: true
            }
            console.log(this.sub_data_contents)

            if(!this.sub_data_contents.structure) {
                Vue.set(this.sub_data_contents, 'structure', [])
            }

            this.sub_data_contents.structure.push(new_seperator)
        },
        moveUp(item, index){
            if(index==0) return false
            
            let temp = this.sub_data_contents.structure[index]
            this.sub_data_contents.structure.splice(index, 1, this.sub_data_contents.structure[index-1])
            this.sub_data_contents.structure.splice(index-1, 1, temp) 

        },
        moveDown(item, index){
            if(index==this.sub_data_contents.structure.length-1) return false
            
            let temp = this.sub_data_contents.structure[index]
            this.sub_data_contents.structure.splice(index, 1, this.sub_data_contents.structure[index+1])
            this.sub_data_contents.structure.splice(index+1, 1, temp) 
        },
        pushExample(index){

            // if (this.sub_data_contents.structure[index].hasOwnProperty('example')==true){
                this.sub_data_contents.structure[index].example.push('')
            // }
            // else this.sub_data_contents.structure[index].example = ['']
        }
    },
    created(){
        console.log(this.sub_data_contents.structure);
        for (let i=0; i<this.sub_data_contents.structure.length; i++){
            if (this.sub_data_contents.structure[i].hasOwnProperty('example')==false){
                this.sub_data_contents.structure[i].example = ['-']
            }
        }
    }
}
</script>