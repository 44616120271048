//Provider Service 중 Auditors 관련 전역 Vuex Store

const auditors = {
    namespaced: true,
    state: {
        admin_user_space: [],
        is_admin_space_loaded: false
    },

    mutations: {
        update_space(state, new_user_space){
            state.admin_user_space=new_user_space
            state.is_admin_space_loaded = true
        }

    },

    getters: {

    },

    actions: {
        initiateSpace({state, commit, rootState }, user_id ){
            const path = rootState.backend_host + '/read_admin_user_space'
            //console.log('request: user_id:'+user_id)
            return axios.get(path, { params: {
                user_id: user_id
            }})
            .then( response => {
                //console.log(response)
                let new_user_space = response.data
                new_user_space.forEach(item => {
                    item.value = item.e_mail + ' ' + item.user_name
                })
                commit('update_space', new_user_space )
            })
            .catch( error => {
                console.log(error)
            })
        }
    }
}

export default auditors