<template>
<div>
    <!-- editing sub_component가 radio type일 경우 --> 
    <div v-if="sub_type=='radio'"><editing-df-radio @click-save="$emit('click-save')" @click-cancel="$emit('click-cancel')" :sub_code="sub_code"></editing-df-radio></div>

    <!-- editing sub component가 input_text type일 경우 --> 
    <div v-else-if="sub_type=='input_text'"><editing-df-input-text @click-save="$emit('click-save')" @click-cancel="$emit('click-cancel')" :sub_code="sub_code"></editing-df-input-text></div>

    <!-- editing sub component가 input_number type일 경우 --> 
    <div v-else-if="sub_type=='input_number'"><editing-df-input-number @click-save="$emit('click-save')" @click-cancel="$emit('click-cancel')" :sub_code="sub_code"></editing-df-input-number></div>

    <!-- editing sub component가 textbox type일 경우-->
    <div v-else-if="sub_type=='textbox'"><editing-df-textbox @click-save="$emit('click-save')" @click-cancel="$emit('click-cancel')" :sub_code="sub_code"></editing-df-textbox></div>

    <!-- editing sub component가 checkbox type일 경우-->
    <div v-else-if="sub_type=='checkbox'"><editing-df-checkbox @click-save="$emit('click-save')" @click-cancel="$emit('click-cancel')" :sub_code="sub_code"></editing-df-checkbox></div> 

    <!-- editing sub component가 file_URL2 type일 경우-->
    <div v-else-if="sub_type=='file_URL2'"><editing-df-file-url2 @click-save="$emit('click-save')" @click-cancel="$emit('click-cancel')" :sub_code="sub_code"></editing-df-file-url2></div> 

    <!-- editing sub component가 table type일 경우 --> 
    <div v-else-if="sub_type=='table'"><editing-df-table @click-save="$emit('click-save')" @click-cancel="$emit('click-cancel')" :sub_code="sub_code"></editing-df-table></div>

    <!-- editing sub component가 materiality일 경우 --> 
    <div v-else-if="sub_type=='materiality'"><editing-df-materiality @click-save="$emit('click-save')" @click-cancel="$emit('click-cancel')" :sub_code="sub_code"></editing-df-materiality></div>

    <!-- editing sub component가 priority일 경우 --> 
    <div v-else-if="sub_type=='priority'"><editing-df-priority @click-save="$emit('click-save')" @click-cancel="$emit('click-cancel')" :sub_code="sub_code"></editing-df-priority></div>

    <!-- editing sub component가 guide_text일 경우-->
    <div v-else-if="sub_type=='guide_text'"><editing-df-guide-text @click-save="$emit('click-save')" @click-cancel="$emit('click-cancel')" :sub_code="sub_code"></editing-df-guide-text></div>    

    <!-- editing sub component가 ohters 일 경우 --> 
    <div v-else><editing-others @click-save="$emit('click-save')" @click-cancel="$emit('click-cancel')" :sub_code="sub_code"></editing-others></div>

    <!-- deprecicated
    <div v-else-if="sub_type=='file'"><editing-df-file @click-save="$emit('click-save')" @click-cancel="$emit('click-cancel')" :sub_code="sub_code"></editing-df-file></div> 
    <div v-else-if="sub_type=='URL'"><editing-df-url @click-save="$emit('click-save')" @click-cancel="$emit('click-cancel')" :sub_code="sub_code"></editing-df-url></div> 
    <div v-else-if="sub_type=='file_URL'"><editing-df-file-url @click-save="$emit('click-save')" @click-cancel="$emit('click-cancel')" :sub_code="sub_code"></editing-df-file-url></div> 
    <div v-else-if="sub_type=='rich_textbox'"><editing-df-rich-textbox @click-save="$emit('click-save')" @click-cancel="$emit('click-cancel')" :sub_code="sub_code"></editing-df-rich-textbox></div>
    <div v-else-if="sub_type=='editable_table'"><editing-df-editable-table @click-save="$emit('click-save')" @click-cancel="$emit('click-cancel')" :sub_code="sub_code"></editing-df-editable-table></div>

    -->

</div>
</template>

<script>
import EditingDFRadio from './EditingDFRadio'
import EditingDFInputText from './EditingDFInputText'
import EditingOthers from './EditingOthers'
import EditingDFInputNumber from './EditingDFInputNumber'
import EditingDFTextbox from './EditingDFTextbox'
import EditingDFCheckbox from './EditingDFCheckbox'
import EditingDFFile_URL2 from './EditingDFFile_URL2'
import EditingDFMateriality from './EditingDFMateriality'
import EditingDFTable from './EditingDFTable'
import EditingDFPriority from './EditingDFPriority'
import EditingDFGuideText from './EditingDFGuideText'

//import EditingDFFile from './EditingDFFile'
//import EditingDFURL from './EditingDFURL'
//import EditingDFFile_URL from './EditingDFFile_URL'
//import EditingDFEditableTable from './EditingDFEditableTable'
//import EditingDFRichTextbox from './EditingDFRichTextbox'


export default {
    data(){
        return {

        }
    },
    props:{
        sub_code: { type: String, required: true },
        sub_type: { type: String, required: true }
    },
    components: {
        'editing-df-radio': EditingDFRadio,
        'editing-df-input-text': EditingDFInputText,
        'editing-others': EditingOthers,
        'editing-df-input-number': EditingDFInputNumber,
        'editing-df-textbox': EditingDFTextbox,
        'editing-df-checkbox': EditingDFCheckbox,
        'editing-df-file-url2': EditingDFFile_URL2,
        'editing-df-table': EditingDFTable,
        'editing-df-materiality': EditingDFMateriality,
        'editing-df-priority': EditingDFPriority,
        'editing-df-guide-text': EditingDFGuideText,
        //'editing-df-file': EditingDFFile,
        //'editing-df-url': EditingDFURL,
        //'editing-df-file-url': EditingDFFile_URL,
        //'editing-df-rich-textbox': EditingDFRichTextbox,
        //'editing-df-editable-table': EditingDFEditableTable,
    },
    created() {
        //this.$on('change-type', this.reRender)
    },
    methods: {
        //reRender(type) {
        //    this.$forceUpdate()
        //    console.log('type: ' + type)
        //    console.log('sub_type: ' + this.sub_type)
        //}
    }
}
</script>

<style scoped>
.handle{
    cursor: pointer;
}

</style>
