<template>
	<div>
        <b-button @click.stop="printStart()" :variant="btn_variant" :class="d_none">
            <slot></slot>
        </b-button> 

        <b-modal :id="'print_modal'+modal_id" size="lg" @show="handleShow()" @hide="handleClose()" hide-footer>
			<template v-slot:modal-title>
				<span v-if="isIE!=true" class="fw-600">{{$t(`인쇄/다운로드`)}}</span>
				<span v-else>{{$t(`다운로드`)}}</span>
			</template>
			<div class="d-block text-center">
				<div v-if="print_ready!=true">
					<div class="align-middle"> <b-spinner small class="mr-2" type="grow"></b-spinner>  {{ $t(msg) }} </div>
				</div>
				<div v-else>
					<div v-if="isIE!=true"><b-button v-print="print_target" :variant="btn_variant=='primary'? btn_variant:'blue'">{{$t(`PDF 인쇄 / 다운로드`)}}</b-button></div>
					<div v-else>
						<b-button @click="pdf.save('company_report.pdf')" variant="blue">{{$t(`PDF 다운로드`)}}</b-button> 
					</div>
				</div>
			</div>
			<b-button  class="float-right" @click="cancel()" variant="dark">{{$t(`취소`)}}</b-button>
		</b-modal>
    </div>
</template>
<script>
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import QuestionsForPrint from '@/components/print_to_pdf/QuestionsForPrint'
import * as th from 'tree-helper'
import * as ih from '@/util'

export default {
    components: {
        QuestionsForPrint
    },
	name: 'pdf',
	data () {
		return {
			propTitle: 'mypdf',
			print_ready: false,
			pdf: '',
			msg: 'PDF 파일 준비중... 최대 1분 정도 소요될 수 있습니다.',
			is_printing: false,
			modal_id: Math.random()*1000
		}
    },
    props:{
        print_target: { type: String, required: true },
        btn_variant: {type: String, default: 'dark-border'},
        d_none: {type: String, default: ''}
    },
	computed:{
		isIE() {
            if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (navigator.userAgent.toLowerCase().indexOf("msie") != -1) )
                { return true }
            else
                { return false }
        }    		
	},
	methods: {
		makePDF(sel) {
			window.html2canvas = html2canvas //Vue.js 특성상 window 객체에 직접 할당해야한다.
			let that = this
			let pdf = new jsPDF('p', 'mm', 'a4')
			let canvas = pdf.canvas
			const pageWidth = 210 //캔버스 너비 mm
			const pageHeight = 297 //캔버스 높이 mm
			canvas.width = pageWidth

            let ele = document.querySelector(sel)
			let width = ele.offsetWidth // 셀렉트한 요소의 px 너비
			let height = ele.offsetHeight // 셀렉트한 요소의 px 높이
			let imgHeight = pageWidth * height/width // 이미지 높이값 px to mm 변환

			if(!ele){
				console.warn(selector + ' is not exist.')
				return false
			}

            return html2canvas(ele)
            .then( function(canvas) {
					let position = 0
					const imgData = canvas.toDataURL('image/png')
					pdf.addImage(imgData, 'png', 0, position, pageWidth, imgHeight, undefined, 'slow')

					//Paging 처리
					let heightLeft = imgHeight //페이징 처리를 위해 남은 페이지 높이 세팅.
					heightLeft -= pageHeight
					while (heightLeft >= 0) {
						position = heightLeft - imgHeight
						pdf.addPage();
						pdf.addImage(imgData, 'png', 0, position, pageWidth, imgHeight)
						heightLeft -= pageHeight
					}
					
					return pdf
			})			
		},
		async printStart(){
			this.$bvModal.show('print_modal'+this.modal_id)

			if(this.isIE==true) { 
				this.pdf = await this.makePDF('#'+this.print_target) 
			}

			this.msg = this.$t('출력 준비가 완료되었습니다.')
            this.print_ready=true
		},
		cancel(){
			this.$bvModal.hide('print_modal'+this.modal_id)
		},
		handleShow(){
			this.is_printing=true
			this.$emit('modal-opened')
		},
		handleClose(){
			this.is_printing=false
			this.$emit('modal-closed')
		},
	},
}
</script>
<style scoped>
.printing{
	display: block;
}

.not_printing{
	display: none;
}
</style>
