<template>
<div>
    <div class="d-flex justify-content-between mb-2">
        <div><b-badge variant="basic">{{ sub_description.match(/^[A-Z.0-9]+/g)[0] }}</b-badge></div>
        <div class="ml-3 f-90 gray-500 fw-300" >
            {{ temp_review.auditor_name!=''?'Audited :':'' }} {{ temp_review.auditor_name }} {{ temp_review.time_stamp }} 
        </div>
    </div>
    <!-- Auditor 평가영역 -->
    <template v-if="is_evaluate_loaded==true">
        <div v-if="sub_evaluation[sub_code]==[]||sub_evaluation[sub_code]==''" class="border-0" >
            {{$t(`평가요소가 없습니다`)}}
        </div>
        <div v-else class="">
            <b-row no-gutters class="mx-0 border-bottom pb-1 gray-550">
                <b-col class="col-9 f-90"> {{$t(`평가기준`)}}</b-col>
                <b-col class="col-2 text-center f-90">{{$t(`점수`)}}</b-col>
                <b-col class="col-1 text-center f-90">{{$t(`초기화`)}}</b-col>
            </b-row>
            <!-- {{sub_evaluation[sub_code]}} -->
            <b-row no-gutters v-for="(item, index) in sub_evaluation[sub_code]" :key="index" :class="[item.author_id!=null?'bg-blue2':'', 'mx-0 mb-2 d-flex justify-content-bewteen align-items-center py-1']">
                <b-col class="col-9">
                    <b-form-checkbox v-model="item.satisfied" @change="handleChange(sub_code, index)" class="f-95" :disabled="item.score==null||disabled==true">
                        {{ item.evaluation_name }}
                    </b-form-checkbox>
                </b-col>
                <b-col :class="[item.score==null?'red5 f-90':'','col-2 text-center']"><span :class="disabled==true?'gray-550':''">{{ item.score!=null? item.score :  $t(`해당없음`) }}</span></b-col>
                <b-col class="col-1 text-center">
                    <b-button :variant="item.author_id!=null?'icon-blue':'icon-dark'" @click="resetEvaluation(sub_code, index)" :disabled="item.score==null||disabled==true"><fa icon="history"></fa></b-button>
                </b-col>
                
            </b-row>                     
        </div> 
        <div v-if="is_reviews_loaded==true">
                <!-- <div>{{ temp_review.auditor_name }} {{ temp_review.time_stamp }}</div> -->
                <b-textarea v-model="temp_review.review_comment" rows="2" :disabled="disabled"  :placeholder="$t(`현장에서 확인한 사항을 작성하여 주십시오.`)" :class="[temp_review.auditor_name!=''?'bg-blue2 border-white':'', 'mb-2']"></b-textarea>
                <b-textarea v-model="temp_review.task_comment" rows="2" :disabled="disabled"  :placeholder="$t(`개선과제를 작성하여 주십시오.`)" :class="[temp_review.auditor_name!=''?'bg-blue2 border-white':'', 'mb-2']"></b-textarea>
                <div class="d-flex justify-content-between">
                    <b-button v-if="sub_evaluation[sub_code]!=[]&&sub_evaluation[sub_code]!=''" :variant="is_not_applicable==true?'dark':'dark-border'" @click="handleMakeNA( sub_code, !is_not_applicable )">{{ is_not_applicable==true ? $t(`해당없음 취소`) : $t(`해당없음 처리`) }}</b-button>
                    <b-button @click="saveReviewComments(
                        {
                            campaign_id : campaign.campaign_id, 
                            company_id: answer_company.company_id, 
                            sub_code: sub_code, 
                            auditor_name: user_name, 
                            review_comment: temp_review.review_comment, 
                            task_comment: temp_review.task_comment
                        })"
                        :variant="disabled==true?'mode':'blue'"
                        size="sm"
                        :disabled="disabled"  
                        class="ml-auto"
                    >{{$t(`저장하기`)}}</b-button>
                </div>
        </div>
    </template>
</div>  
</template>

<script>
import { mapState, mapActions } from 'vuex'
import debounce from 'lodash.debounce'

export default {
    data() {
        return {
            temp_review: {
                sub_code: '', 
                auditor_name: '',
                review_comment: '',
                task_comment: '',
                time_stamp: '',
            },

            save_state:'not_saved',
            validation_comment:'',
            validation_icon:'',
            validation_style:'',
        }
    },
    props: {
        sub_code: { type: String },
        sub_description: { type: String },
        disabled: {type: Boolean, default: false}
    },
    computed:{
        ...mapState('reviews', {
            reviews: state => state.reviews, 
            review_campaign_id: state => state.campaign_id,
            is_reviews_loaded: state => state.is_reviews_loaded,
            is_review_saved: state => state.is_review_saved
        }),
        ...mapState('score', ['is_evaluate_loaded', 'is_comment_loaded', 'overall_comment', 'evaluate_results']
        ),
        ...mapState('questions', ['subs', 'subs_loaded', 'answer_company']),
        user_name() {
            return this.$store.state.user_name
        },
        campaign() {
            return this.$store.state.campaign
        },
        question_name(){
            return this.$store.state.question_composition.question_name
        },
        question_code(){
            return this.$store.state.question_composition.question_code
        },
        sub_evaluation(){
            let sub_evaluations = {}
            if (this.is_evaluate_loaded==true){
                let temp_result = []
                this.evaluate_results.forEach(item =>{
                    if (item.sub_code == this.sub_code){
                        temp_result.push({
                        evaluation_name: item.evaluation_name,
                        ev_id: item.ev_id, 
                        score: item.score,
                        satisfied: item.satisfied,
                        author_id: item.author_id,
                        author_name: item.author_name,
                        audit_comment: item.audit_comment,
                        time_stamp: item.time_stamp,
                        sub_code: item.sub_code,
                        formula: item.formula,
                        })
                    }
                })
                sub_evaluations[this.sub_code] = temp_result
            }
            return sub_evaluations      
        },
        is_not_applicable(){
            let check = false

            this.sub_evaluation[this.sub_code].forEach( item => {
                if(item.score==null) { check = true}
            })
            return check
        }     
    },
    methods:{
        ...mapActions('reviews', ['initiateReviews', 'saveSubReview']),
        ...mapActions('score', ['readAllEvaluateResult']),
        tempChange: debounce( function(sub_code, i) { 
            this.evaluationSave(sub_code, i)
            },800 //사용자 입력을 기다리는 시간
        ),
        handleChange(sub_code, i){
            Vue.nextTick(() => { 
                this.evaluationSave(sub_code, i)
            })            
        },
        async handleMakeNA(code, not_applicable){
            const path = this.$store.state.backend_host + '/save_audit'

            console.log(this.answer_company);
             
            const promises = []
            this.sub_evaluation[code].forEach( item => {
                let formData = new FormData()
                formData.append('target_company_id', this.answer_company.company_id)
                // formData.append('formula', item.formula)
                // formData.append('name', item.evaluation_name)
                formData.append('campaign_id', this.campaign.campaign_id)
                formData.append('ev_id', item.ev_id)
                formData.append('author_id', this.$store.state.user_id)
                formData.append('satisfied', item.satisfied)
                formData.append('not_applicable', not_applicable)
                formData.append('comment', item.audit_comment)

                promises.push( 
                    axios.post( path, formData )
                    .catch( error => console.log(error ))
                )
            })
            await Promise.all( promises )
            this.readAllEvaluateResult({campaign_id: this.campaign.campaign_id, company_id: this.answer_company.company_id})
            
        },
        evaluationSave(code, i){                
            const path = this.$store.state.backend_host + '/save_audit' 

            let formData = new FormData()
            formData.append('target_company_id', this.answer_company.company_id)
            formData.append('ev_id', this.sub_evaluation[code][i].ev_id)
            // formData.append('formula', this.sub_evaluation[code][i].formula)
            // formData.append('name', this.sub_evaluation[code][i].evaluation_name )
            formData.append('campaign_id', this.campaign.campaign_id)
            formData.append('author_id', this.$store.state.user_id )
            formData.append('satisfied', this.sub_evaluation[code][i].satisfied )
            formData.append('comment', this.sub_evaluation[code][i].audit_comment )

            axios.post(path, formData)
            .then(response => {
                //console.log('save했음')
                this.readAllEvaluateResult({campaign_id: this.campaign.campaign_id, company_id: this.answer_company.company_id})
            })
        },
        handleOverallComment(){
            this.save_state = 'saving'
            this.debounceOverallComment()
        },
        debounceOverallComment: debounce( function() { 
            this.overallCommentSave()
            }, 800 //사용자 입력을 기다리는 시간
        ),
        overallCommentSave(){
            const path = this.$store.state.backend_host + '/save_audit_overall_comment'

            let formData = new FormData()
            formData.append('company_id', this.answer_company.company_id)
            formData.append('campaign_id', this.campaign.campaign_id)
            formData.append('question_code', this.question_code )
            formData.append('author_id', this.$store.state.user_id)
            formData.append('overall_comment', this.overall_comment.overall_comment)

            axios.post(path, formData)
            .then(response => {
                this.save_state = 'resaved'
            })
        },        
        resetEvaluation(code, i){
            const path = this.$store.state.backend_host + '/delete_audit' 

            let formData = new FormData()
            formData.append('company_id', this.answer_company.company_id)
            formData.append('ev_id', this.sub_evaluation[code][i].ev_id)
            formData.append('campaign_id', this.campaign.campaign_id)
            // formData.append('formula', this.sub_evaluation[code][i].formula)
            // formData.append('name', this.sub_evaluation[code][i].evaluation_name )

            axios.post(path, formData)
            .then(response => {
                // console.log(response.data)
                this.readAllEvaluateResult({campaign_id: this.campaign.campaign_id, company_id: this.answer_company.company_id}) 
            })
        },
        findReviewObj(company_id, sub_code){
            let idx = this.reviews.findIndex(item => item.company_id == company_id)
            // console.log(idx);
            if(this.is_reviews_loaded==true){
                if (idx > -1){
                    let temp_rev = this.reviews[idx].review_results
                    let review_idx = temp_rev.findIndex(review_item => review_item.sub_code == sub_code)
                    //console.log(review_idx);
                    if(review_idx > -1){
                        return this.reviews[idx].review_results[review_idx]
                        // { sub_code, auditor_name, review_comment, task_comment, time_stamp }
                    }
                }
                else return undefined
            }
            
            return undefined
        },
        initiateTempReview(){
            let temp_rev = this.findReviewObj(this.answer_company.company_id, this.sub_code) 
            console.log(temp_rev)
            let review_obj = {}
            if(temp_rev != undefined){
                    review_obj = {
                        sub_code: temp_rev.sub_code,
                        auditor_name: temp_rev.auditor_name,
                        review_comment: temp_rev.review_comment,
                        task_comment: temp_rev.task_comment,
                        time_stamp: temp_rev.time_stamp
                    }
                }
            else {
                review_obj = {
                    sub_code: this.sub_code, 
                    auditor_name: '',
                    review_comment: '',
                    task_comment: '',
                    time_stamp: '',
                }
            }
            this.temp_review = review_obj
        },
        async saveReviewComments(review_obj){
            let { sub_code, auditor_name, time_stamp } = await this.saveSubReview(review_obj)
            let new_temp_review = { ...this.temp_review }
            new_temp_review.auditor_name = auditor_name
            new_temp_review.time_stamp = time_stamp
            this.temp_review = new_temp_review
            //화면 갱신
        }   
    },
    created(){
        //this.$store.dispatch('score/readAllEvaluateResult', {campaign_id: this.campaign.campaign_id, company_name: this.answer_company.company_name})
    },
    beforeDestroy(){ 
    },
    watch: {
        save_state(newValue) {
            if (newValue == 'saving') {
                this.validation_comment = this.$t(`저장 중 입니다.`)
                this.validation_icon = 'hourglass'
                this.validation_style = 'ih-validation-saving'
            }
            else if (newValue == 'saved') {
                this.validation_comment = this.$t(`저장 되어있습니다`)
                this.validation_icon = 'check'
                this.validation_style = 'ih-validation-saved'
            }
            else if (newValue == 'resaved') {
                this.validation_comment = this.$t(`변경 저장 되었습니다`)
                this.validation_icon = 'check'
                this.validation_style = 'ih-validation-resaved'
            }
        },
        overall_comment: {
            handler(newVal) {
                if(newVal.id==undefined){
                    this.save_state = 'not_saved',
                    this.validation_comment = '',
                    this.validation_icon = '',
                    this.validation_style = ''
                }else if (newVal.id!=undefined){
                    this.save_state ='saved'
                }
            }
        },
        subs_loaded:{
            immediate: true,
            handler(newVal){
                newVal == true
                this.initiateTempReview()
            }
        },
        //is_review_saved(newVal){
        //    if (newVal==true){
        //        this.initiateTempReview()
        //    }        
        //}
    },
    mounted(){
        // let j = this.evaluate_results.filter(x => x.sub_code=='Rrs717865466' )
        // console.log(j)
    }
}
</script>

<style>

</style>