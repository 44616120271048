//Provider Service 중 유저 정보 읽어오기
//import axios from 'axios'

const campaign_users = {
    namespaced: true,
    state: {
        users_in_company : [],
        category1_filled_rate: [],
        total_filled_rate: [],
        is_filled_rate_loaded: false,
        category1_progress_rate: [],
        total_progress_rate: [],
        is_progress_rate_loaded: false,
    },
    mutations: {
        update_users_in_company(state, new_users){
            state.users_in_company = new_users
        },
        update_filled_rates(state, new_list){
            let temp_cat1_filled = new_list.category1_filled_rate
            let temp_total_filled = new_list.total_filled_rate
            state.category1_filled_rate = temp_cat1_filled
            state.total_filled_rate = temp_total_filled
        },
        update_filled_rate_loaded(state, flag){
            state.is_filled_rate_loaded = flag
        },
        update_progress_rates(state, new_list){
            let temp_cat1_progress = new_list.category1_progress_rate
            let temp_total_progress = new_list.total_progress_rate
            state.category1_progress_rate = temp_cat1_progress
            state.total_progress_rate = temp_total_progress
        },
        update_progress_rate_loaded(state, flag){
            state.is_progress_rate_loaded = flag
        }, 
    },
    getters: {
    },
    actions: {
        readUsersByCompany( {commit, rootState}, company_id ){
            const path = rootState.backend_host + '/read_users_by_company'
            return axios.get(path, {params : {company_id: company_id}})
            .then(response => {
                // console.log(response.data.all_users);
                commit('update_users_in_company', response.data.all_users)
            })
        },
        readFilledRate({commit, rootState}, {campaign_id, company_id}){
            const path = rootState.backend_host + '/read_qe_filled_rate'

            return axios.get(path, { params: { campaign_id: campaign_id, company_id: company_id }
            }) 
            .then(response => {
                // console.log(response.data)
                commit('update_filled_rates', response.data)
                commit('update_filled_rate_loaded', true)
            })
            .catch(error => {
                console.log(error)
            })
        },
        readProgressRate({commit, rootState}, {campaign_id, company_id}){
            //console.log(campaign_id)
            const path = rootState.backend_host + '/read_qe_progress'

            return axios.get(path, { params: { campaign_id: campaign_id, company_id: company_id, mode: 'tierzero' }
            }) 
            .then(response => {
                commit('update_progress_rates', response.data)
                commit('update_progress_rate_loaded', true)
            })
            .catch(error => {
                console.log(error)
            })
        },

    }
}


export default campaign_users