<template>
<b-container fluid class="p-0">
        <b-table-simple responsive hover class="rep-table">
            <b-tbody>
            <b-tr class="my-info-h">
                <b-td class="gray-600 fw-500"> {{$t(`성함`)}} </b-td>
                <b-td class="text-left"> <div class="ml-2 py-1"> {{ user_name }} </div></b-td>
            </b-tr>
            <b-tr class="my-info-h">
                <b-td class="gray-600 fw-500"> {{$t(`이메일`)}}</b-td>
                <b-td class="text-left"> <div class="ml-2 py-1"> {{ e_mail }} </div> </b-td>
            </b-tr>
            <b-tr>
                <b-td class="gray-600 fw-500"> {{$t(`회사 / 부서`)}} </b-td>
                <b-td class="d-md-flex align-items-center justify-content-between">
                    <div class="w-50 text-left pl-2"> {{ company_name }}  </div> <b-form-input type='text' v-model="department_name" class="ml-md-2 w-50" :placeholder="$t(`부서명`)"> {{ department_name }}</b-form-input>
                </b-td>
            </b-tr>
            <b-tr>
                <b-td class="gray-600 fw-500"> {{$t(`전화번호`)}} </b-td>
                <b-td class="d-md-flex">
                    <b-form-input type='text' v-model="tel_office" placeholder="office." > {{ tel_office }} </b-form-input>
                    <b-form-input type='text' v-model="tel_mobile" placeholder="mobile." class="ml-md-2"> {{ tel_mobile }} </b-form-input>
                </b-td>
            </b-tr>
                <b-tr>
                <b-td class="gray-600 fw-500"> {{$t(`주소`)}} </b-td>
                <b-td>
                    <b-form-input type='text' v-model="address" :placeholder="$t(`현재 근무지 주소를 입력해 주세요`)" > {{ address }} </b-form-input>
                </b-td>
            </b-tr>
            <b-tr>
                <b-td class="gray-600 fw-500"> {{$t(`비밀번호 변경`)}} </b-td>
                <b-td> <b-button @click="toggleChangePassword()" variant="dark" block v-if="is_password_change==false"> {{$t(`비밀번호 변경`)}}</b-button>
                     <b-button @click="toggleChangePassword()" variant="dark" block v-else> {{$t(`비밀번호 변경 취소`)}}</b-button></b-td>
            </b-tr>
            <b-tr v-if="is_password_change">
                <b-td class="gray-600 fw-500"> {{$t(`기존 비밀번호`)}} </b-td>
                <b-td><b-form-input type='password' v-model="password_old" :placeholder="$t(`기존 비밀번호를 입력해 주세요`)"> {{ tel_mobile }} </b-form-input></b-td>
            </b-tr>
            <b-tr v-if="is_password_change">
                <b-td class="gray-600 fw-500"> {{$t(`새 비밀번호`)}} </b-td>
                <b-td><b-form-input type='password' v-model="password_new1" :placeholder="$t(`4자리 이상 입력해 주세요`)"></b-form-input>
                    <div class="f-90 text-left red">{{ passwordLengthCheck }}</div>
                </b-td>
            </b-tr>
            <b-tr v-if="is_password_change">
                <b-td class="gray-600 fw-500"> {{$t(`새 비밀번호 확인`)}} </b-td>
                <b-td><b-form-input type='password' v-model="password_new2" :placeholder="$t(`새 비밀번호를 한번 더 입력해 주세요`)" ></b-form-input>
                    <div class="f-90 text-left red">{{ passwordLengthAlign }}</div>
                </b-td>
            </b-tr>
            </b-tbody>
        </b-table-simple>

    <div class="mb-2 d-md-flex justify-content-between align-items-top">
        <div :class="validation_style"><fa :icon="validation_icon" v-if="validation_icon !=''"></fa> {{ validation_comment }} </div>
        <b-button @click="handleOK()" variant="blue"> {{$t(`저장`)}} </b-button>
    </div>

</b-container>
</template>


<script>
//import axios from 'axios'
import debounce from 'lodash.debounce'

export default {
    data () {
        return {
            question_name: '기업 기본정보',
            question_infobox: '아래의 기업 기본정보를 입력하여 주십시오',

            tel_office: '',
            tel_mobile: '',
            address: '',
            headquarter_address:'',
            department_name:'',

            save_state: 'not_saved',
            save_icon: '',
            save_validation: '',

            validation_comment: '',
            validation_icon: '',
            validation_style: 'ih-validation-saved',

            is_password_change: false,

            password_old: '',
            password_new1: '',
            password_new2: ''
        }
    },
    methods: {
        userSave: function () {
            const path = this.$store.state.backend_host + '/save_user'

            const attached_info = JSON.stringify(this.user_info)

            const formData = new FormData()
            formData.append('is_password_change', this.is_password_change)
            formData.append('password_old', this.password_old)
            formData.append('password_new', this.password_new1)

            formData.append('user_name', this.user_name)
            formData.append('company_id', this.company_id)
            formData.append('company_name', this.company_name)
            formData.append('e_mail', this.e_mail)
            formData.append('tel_office', this.tel_office)
            formData.append('tel_mobile', this.tel_mobile)
            formData.append('address', this.address)
            //formData.append('headquarter_address', this.headquarter_address)
            formData.append('department_name', this.department_name)
            formData.append('is_admin', this.is_admin)

            axios.post(path, formData)
            .then(response => {
                let result = response.data

                if(response.data['result_code'] && response.data['result_code'] == '000' ) { 
                    window.alert(response.data['result_msg']) 
                }
                else {
                    this.$emit('user-save')
                    this.save_state = 'resaved'
                }
            })
            .catch(error => {
                console.log(error)
            })


        },
        textInputSave() {
            this.save_state = 'saving',
            this.debouncedSave()
        },
        debouncedSave: debounce (
            function() {
                Vue.nextTick(() =>
                {
                    this.userSave()
                })
            },
            //사용자 입력을 기다리는 시간
            800
        ),
        initiateData () {
            const path = this.$store.state.backend_host + '/read_user'
            axios.get(path, {
                            params: {
                                e_mail: this.$store.getters.getEMail
                            }
            })
            .then(response => {
                let result = response.data
                this.tel_office = result.tel_office
                this.tel_mobile = result.tel_mobile
                this.address = result.address
                this.headquarter_address = result.headquarter_address
                this.department_name = result.department_name
                this.save_state = 'saved'
            })
            .catch(error => {
                this.save_state = 'not_saved'
                console.log(error)
            })
        },
        handleOK(){

            if( this.is_password_change == true ) {
                //1) 패스워드 체크: 기존 패스워드 입력했는지? 
                    if(this.password_old == '') 
                    {
                        window.alert(this.$t(`기존 비밀번호를 입력해 주세요`)) 
                        return false
                    }

                //2) 패스워드 신규 2개가 일치하는지? 
                    if(this.password_new1 == '' || this.password_new1 != this.password_new2) 
                    {
                        window.alert(this.$t(`변경할 비밀번호를 재확인해 주세요`))
                        return false
                    }
            }
            // console.log(this.password_old);

            this.userSave()


        },
        toggleChangePassword(){
            this.is_password_change = !this.is_password_change
        }
    },
    computed: {
        user_name() {
            return this.$store.getters.getUserName
        },
        company_name() {
            return this.$store.getters.getCompanyName
        },
        company_id() {
            return this.$store.state.company_id
        },
        is_admin() {
            return this.$store.state.is_admin
        },
        e_mail() {
            return this.$store.getters.getEMail
        },
        passwordLengthCheck() {
            if (this.password_new1 != ''){
                return this.password_new1.length > 3 ? '' : this.$t(`비밀번호는 4자리 이상 입력해 주세요`) 
            }
        },
        passwordLengthAlign() {
            if (this.password_new2 != '') {
                return this.password_new1.length == this.password_new2.length ? '' : this.$t(`패스워드가 일치하는지 확인해 주세요`)
            }
        }
    },
    watch: {
        save_state: function() {
            if (this.save_state == 'saving') {
                this.validation_comment = this.$t('저장 중입니다.')
                this.validation_icon = 'hourglass'
                this.validation_style = 'ih-validation-saving'
            }
            else if (this.save_state == 'saved') {
                this.validation_comment = this.$t(`저장 되어있습니다`)
                this.validation_icon = 'check'
                this.validation_style = 'ih-validation-saved'
            }
            else if (this.save_state == 'resaved') {
                this.validation_comment = this.$t(`변경 저장 되었습니다`)
                this.validation_icon = 'check'
                this.validation_style = 'ih-validation-resaved'
            }
        }
    },
    created(){
        this.initiateData()
    },
    components: {
    }

}
</script>

<style scoped>

.company_info{
    margin-top: 80px;
}

.my-info-h{
    min-height: 38px;
}

.ih-validation-saved {
    color: var(--green);
    font-size: 0.9rem;
}

.ih-validation-saving {
    color:var(--red);
    font-size: 0.9rem;
}

.ih-validation-resaved {
    color: var(--green);
    font-size: 0.9rem;
}

.user-info-bg {
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    overflow: none;
    background-color: var(--light);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    overflow: hidden;
}

 @media (max-width:736px) {
    #user-info-box {
        max-width: 90vw;

    }
}



</style>
