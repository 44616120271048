<template>
<div>
    <div class="w-100 bgray-200 mb-5 fw-400"> <h2 class="py-5 fw-400 text-center"> CAMPAIGN LIST </h2> 
        
        <b-container class="d-flex justify-content-between align-items-end pb-5">
                <div class="col camp-status-box text-center border-bottom-dark">                    
                    <div class="d-inline" v-for="(camps,index) in camp_created" :key="index+1">
                        <span v-b-tooltip.hover :title="camps.campaign_name"> <fa icon="map-marker-alt" :class="[index%2==0?'floating2':'floating1','f-110 mx-1 hover-blue']"></fa></span>
                    </div>
                    <p class='mt-3 mb-2 f-95 fw-500'>{{ $t(`생성/편집`)}}</p>
                </div>  
                <div class="col camp-status-box text-center border-bottom-dark">
                    <div class="d-inline" v-for="(camps,index) in camp_answering" :key="index">
                        <span v-b-tooltip.hover :title="camps.campaign_name"><fa icon="map-marker-alt" :class="[index%2==0?'floating2':'floating1','f-110 mx-1 hover-blue']"></fa></span>
                    </div>
                    <p class='mt-3 mb-2 f-95 fw-500'>{{ $t(`답변중`)}}</p>
                </div>  
                <div class="col camp-status-box text-center border-bottom-dark">
                    <div class="d-inline" v-for="(camps,index) in camp_auditing" :key="index">
                        <span v-b-tooltip.hover :title="camps.campaign_name"><fa icon="map-marker-alt" :class="[index%2==0?'floating2':'floating1','f-110 mx-1 hover-blue']"></fa></span>
                    </div>
                    <p class='mt-3 mb-2 f-95 fw-500'>{{ $t(`평가중`)}}</p>
                </div>  
                <div class="col camp-status-box text-center border-bottom-dark mr-3">
                    <div class="d-inline" v-for="(camps,index) in camp_reporting" :key="index">
                        <span v-b-tooltip.hover :title="camps.campaign_name"><fa icon="map-marker-alt" :class="[index%2==0?'floating2':'floating1','f-110 mx-1 hover-blue']"></fa></span>
                    </div>
                    <p class='mt-3 mb-2 f-95 fw-500'>{{ $t(`결과배포`)}}</p>
                    <div class="to-right"></div>
                </div>        
                <div class="col camp-status-box text-center border-bottom-dark-dashed ml-3">
                    <div class="d-inline" v-for="camps in campaigns_archived"  :key="camps.id">
                        <span v-b-tooltip.hover :title="camps.campaign_name"> <fa icon="map-marker" class="f-110 mx-1 gray-500 hover-dark"></fa></span>
                    </div>
                    <p class='mt-3 mb-2 f-95 fw-500'>{{ $t(`종료`)}}</p>
                    <div class="to-right-dashed"></div>
                </div>
        </b-container>    

    </div>

    <!-- campaign list 영역 시작 -->
    <!-- campaign list 영역 시작 -->
    <!-- campaign list 영역 시작 -->
    <!-- campaign list 영역 시작 -->

    <b-container class="py-0 py-md-3" v-if="is_campaigns_loaded==true">
        <div class="mb-5">
            <div class="pt-3 mb-45 d-flex align-items-center"> 
                <h4 class="mr-auto fw-600"> {{ $t(`진행중 캠페인`)}} </h4> 
                <b-button v-if="is_admin >=4" class="f-105 fw-500" variant="dark-border"  @click="newCampaign"><fa icon="plus" class="mr-2"></fa>{{ $t(`새 캠페인`)}}</b-button> 
            </div>
            <div v-if="campaigns_active !=''">
                <b-table-simple responsive hover class="rep-table">
                    <b-thead><b-tr class="">
                        <b-td>{{ $t(`상태`)}}</b-td><b-td>{{ $t(`주최사`)}}</b-td><b-td>{{ $t(`캠페인명`)}}</b-td><b-td>{{ $t(`관리`)}}</b-td><b-td>{{ $t(`편집`)}}</b-td>
                    </b-tr></b-thead>
                    <b-tbody><b-tr v-for="item in campaigns_active" :key="item.index" class="">
                        <b-td class="py-2"> 
                            <b-dropdown :text="$t(`${item.campaign_state}`)" :disabled="is_admin<4" :variant="item.campaign_state=='답변중'?'icon-blue':'icon-dark'">
                                <b-dropdown-item-button @click="changeStatus({campaign: item, status: '생성/편집'})">{{$t(`생성/편집`)}}</b-dropdown-item-button>
                                <b-dropdown-item-button @click="changeStatus({campaign: item, status: '답변중'})">{{$t(`답변중`)}}</b-dropdown-item-button>
                                <b-dropdown-item-button @click="changeStatus({campaign: item, status: '평가중'})">{{$t(`평가중`)}}</b-dropdown-item-button>
                                <b-dropdown-item-button  @click="changeStatus({campaign: item, status: '결과배포'})">{{$t(`결과배포`)}}</b-dropdown-item-button>
                                <b-dropdown-item-button  @click="changeStatus({campaign: item, status: '종료'})">{{$t(`종료`)}}</b-dropdown-item-button>
                            </b-dropdown>
                        </b-td>
                        <b-td class="py-2 text-left"> {{ item.operator_name}} </b-td>
                        <b-td class="py-2 text-left"> <b-badge variant="advance" class="mr-2">{{item.campaign_id}}</b-badge> {{ item.campaign_name }} </b-td> 
                        <b-td class="py-2">
                            <b-button-group>
                                <b-button @click="progressCampaign(item)" variant="gray">{{ $t(`진행현황`)}}</b-button>
                                <b-button :disabled="checkAllowed(item.campaign_state, item.type, is_admin, 2)!=true" @click="goAnswer(item)" variant="gray">{{ $t(`기업별 답변`)}}</b-button>
                                <b-button :disabled="checkAllowed(item.campaign_state, item.type, is_admin, 3)!=true" @click="reportCampaign(item)" variant="gray">{{ $t(`평가결과`)}}</b-button>
                                <b-button :disabled="checkAllowed(item.campaign_state, item.type, is_admin, 3)!=true||checkOptions(item.campaign_state, item.process_options)!=true" @click="monitorCampaign(item)" variant="gray" v-if="is_admin==4">{{ $t(`후속 모니터링`)}}</b-button>
                            </b-button-group>
                            <!-- <b-button class="py-1 px-2 ml-1" variant="blue-border" size="sm" @click="progressCampaign(item)" >진행현황</b-button>
                            &nbsp;<b-button v-if="checkAllowed(item.type, is_admin, 3)==true" class="py-1 px-2 f-80" variant="dark-border" @click="reportCampaign(item)" ><fa icon="file-contract" class="f-110 mr-1"></fa>Report</b-button>  -->
                        </b-td>                       
                        <b-td class="py-2">
                            <div v-if="is_admin>=4" >
                                <div v-if="item.campaign_state=='생성/편집'"><b-button class="py-1 px-2 ml-1" variant="dark-border" :disabled="item.campaign_state!='생성/편집'" @click="editCampaign(item)" size="sm"> <fa icon="pen"></fa> </b-button></div> 
                                <div v-else :id="`camp-edit-btn_${item.campaign_id}`" >
                                    <b-button class="py-1 px-2 ml-1" variant="dark-border" :disabled="item.campaign_state!='생성/편집'" @click="editCampaign(item)" size="sm"> <fa icon="pen"></fa> </b-button>
                                    <b-tooltip :target="`camp-edit-btn_${item.campaign_id}`" triggers="hover"> 캠페인 상태를 '생성/편집'으로 변경 후 편집할 수 있습니다.</b-tooltip>
                                </div> 
                            </div>
                            <div v-else> {{ $t(`권한 없음`) }} </div>     
                        </b-td>
                    </b-tr></b-tbody>
                </b-table-simple>
            </div>
            <div v-if="campaigns_active==''" class="mt-2 py-2 text-center gray-600 bgray-200"> {{ $t(`진행중인 캠페인이 없습니다.`) }}</div>
        </div>

        <hr class="border-0 pb-5">


        <div class="mb-5">
            <h4 class="pt-4 mb-4 gray-500 fw-600"> {{ $t(`종료된 캠페인`)}} </h4>
            <div v-if="campaigns_archived !=''">
                <b-table-simple hover responsive class="rep-table">
                    <b-thead><b-tr class="border-bottom-bold">
                        <b-td>{{ $t(`상태`)}}</b-td><b-td>{{ $t(`주최사`)}}</b-td><b-td>{{ $t(`캠페인명`)}}</b-td><b-td>{{ $t(`관리`)}}</b-td><b-td>{{ $t(`편집`)}}</b-td>
                    </b-tr></b-thead>
                    <b-tbody><b-tr v-for="item in campaigns_archived" :key="item.index" class="border-bottom">
                        <b-td><b-dropdown :text="item.campaign_state" :disabled="is_admin<4" :variant="item.campaign_state=='종료'?'icon-gray':'icon-blue'">
                                <b-dropdown-item-button @click="changeStatus({campaign: item, status: '생성/편집'})">{{$t(`생성/편집`)}}</b-dropdown-item-button>
                                <b-dropdown-item-button @click="changeStatus({campaign: item, status: '답변중'})">{{ $t(`답변중`)}}</b-dropdown-item-button>
                                <b-dropdown-item-button @click="changeStatus({campaign: item, status: '평가중'})">{{ $t(`평가중`)}}</b-dropdown-item-button>
                                <b-dropdown-item-button  @click="changeStatus({campaign: item, status: '결과배포'})">{{ $t(`결과배포`)}}</b-dropdown-item-button>
                                <b-dropdown-item-button  @click="changeStatus({campaign: item, status: '종료'})">{{ $t(`종료`)}}</b-dropdown-item-button>
                            </b-dropdown>
                        </b-td>
                        <b-td class="py-2"> {{ item.operator_name}} </b-td>
                        <b-td class="py-2"> {{ item.campaign_name }} </b-td>
                        <b-td class="py-2">
                            <b-button-group>
                                <b-button @click="progressCampaign(item)" variant="gray">{{ $t(`진행현황`)}}</b-button>
                                <b-button :disabled="checkAllowed(item.type, is_admin, 3)!=true" @click="goAnswer(item)" variant="gray">{{ $t(`기업별 답변`)}}</b-button>
                                <b-button :disabled="checkAllowed(item.type, is_admin, 3)!=true" @click="reportCampaign(item)" variant="gray">{{ $t(`평가결과`)}}</b-button>
                                <b-button :disabled="checkAllowed(item.type, is_admin, 3)!=true||checkOptions(item.campaign_state, item.process_options)!=true" @click="monitorCampaign(item)" variant="gray" v-if="is_admin==4">{{ $t(`후속 모니터링`)}}</b-button>
                            </b-button-group>
                            <!-- <b-button class="py-1 px-2 ml-1" variant="dark-border" size="sm" @click="progressCampaign(item)" >Progress</b-button> &nbsp;
                            <b-button :disabled="checkAllowed(item.type, is_admin, 2)==false" class="py-1 px-2 f-80" variant="red-border" @click="reportCampaign(item)" ><fa icon="file-contract" class="f-110 mr-1"></fa>Report</b-button>  -->
                        </b-td>          
                        <b-td class="py-2">
                            <div v-if="is_admin>=4">
                                <div :id="`camp-edit-btn_${item.campaign_id}`" >
                                    <b-button class="py-1 px-2 ml-1" variant="dark-border" :disabled="item.campaign_state!='생성/편집'" @click="editCampaign(item)" size="sm"> <fa icon="pen"></fa> </b-button> 
                                    <b-tooltip :target="`camp-edit-btn_${item.campaign_id}`" triggers="hover"> 캠페인 상태를 '생성/편집'으로 변경 후 편집할 수 있습니다.</b-tooltip>
                                </div>
                            </div>
                            <div v-else> {{ $t(`권한 없음`) }} </div>                                                
                        </b-td>
                    </b-tr></b-tbody>
                </b-table-simple>
            </div>
            <div v-else class="mt-2 py-2 text-center gray-600 bgray-200">{{ $t(`완료된 캠페인이 없습니다.`) }}</div>
        </div>

        <b-card id="campaign-mgt-tip" class="mb-5 bgray-100 border-0">
            <b-card-sub-title class="mb-0 text-black-50"> <fa icon="info-circle" class="mr-1"></fa>{{ $t(`캠페인 상태 설정`)}}</b-card-sub-title>
            <b-card-text class="text-black-50 pb-1">
                {{ $t(`진행/생성/편집/완료 상태는 캠페인 기간과 상관없이 상태바꾸기로 설정 가능합니다.`)}}<br> 
                {{ $t(`캠페인 편집은 생성/편집 상태에서만 가능하며, 생성/편집중인 캠페인은 캠페인 페이지에 나타나지 않습니다.
                신규 캠페인 구축 시 생성/편집 상태로 저장됩니다.`)}}
            </b-card-text>
        </b-card>
        <hr class="bottom-space border-0">
    </b-container>
    <b-container v-else class="py-5">
        <b-skeleton-table
            :rows="4"
            :columns="4"
            :table-props="{ bordered: false, striped: false }"
        ></b-skeleton-table>
        <!-- <b-card class="border-0 blue text-center"><b-spinner class="mx-2 p-1 my-1" small type="grow"></b-spinner> 캠페인 목록을 가져오고 있습니다.</b-card> -->
    </b-container>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {

        }
    },
    props: {
        is_reloaded: { default: false, type: Boolean }
    },
    computed: {
        ...mapState('auditors', {
            admin_user_space: state => state.admin_user_space,
            is_admin_space_loaded: state => state.admin_space_loaded
        }),
        ...mapState('user_campaigns_list', {
            campaigns: state => state.campaigns,
            is_campaigns_loaded: state => state.is_campaigns_loaded
        }),
        ...mapState('score', ['temp_target_companies']),
        user_id() {
            return this.$store.state.user_id
        },
        is_admin() {
            return this.$store.getters.isAdmin
        },
        campaigns_paused: {
            get() {
                let camp_paused = []
                for (let i=0; i<this.campaigns.length; i++) {
                    if (this.campaigns[i].campaign_state == '생성/편집') camp_paused.push(this.campaigns[i])
                }
                return camp_paused
            },
            set(newValue) {
                return campaigns_paused = newValue
            }
        },
        campaigns_active: {
            get() {
                let camp_active = []
                for (let i=0; i<this.campaigns.length; i++) {
                    if (this.campaigns[i].campaign_state != '종료') camp_active.push(this.campaigns[i])
                }
                return camp_active.sort((a, b) => a.campaign_state - b.campaign_state)
            },
            set(newValue) {
                return campaigns_active = newValue
            }            
        },
        campaigns_archived: {
            get() {
                let camp_archived = []
                for (let i=0; i<this.campaigns.length; i++) {
                    if (this.campaigns[i].campaign_state == '종료') camp_archived.push(this.campaigns[i])
                }
                return camp_archived
            },
            set(newValue) {
                return campaigns_archived = newValue
            }
        },
        // campaign_state별 campaigns 돌려주기
        camp_created(){
            let temp_camp_created = []
            this.campaigns.forEach(item =>{if(item.campaign_state == '생성/편집') temp_camp_created.push(item)})
            return temp_camp_created
        },
        camp_answering(){
            let temp_answering = []
            this.campaigns.forEach(item =>{if(item.campaign_state == '답변중') temp_answering.push(item)})
            return temp_answering
        },  
        camp_auditing(){
            let temp_camp_auditing = []
            this.campaigns.forEach(item =>{if(item.campaign_state == '평가중') temp_camp_auditing.push(item)})
            return temp_camp_auditing
        },
        camp_reporting(){
            let temp_camp_reporting = []
            this.campaigns.forEach(item =>{if(item.campaign_state == '결과배포') temp_camp_reporting.push(item)})
            return temp_camp_reporting
        },       
    },
    watch: {
        // is_reloaded: {
        //     immediate: true,
        //     handler(is_reloaded){
        //         if (is_reloaded == true) {
        //         this.readCampaigns(this.user_id)
        //         return is_reloaded = false
        //         }
        //         else return is_reloaded = false
        //     }
        // }
    },
    methods: {
        ...mapActions('auditors', ['initiateSpace']),
        ...mapActions('user_campaigns_list', ['readCampaigns', 'changeStatus', 'deleteCampaign']),
        ...mapActions('reviews', ['initiateReviews']),
        newCampaign(){
            this.$router.push({ name: 'EditingCampaignAttr', params: { value_new: true }})
        },
        async editCampaign(campaign){
            await this.readCampaignForEdit(campaign.campaign_id)
            this.$router.push({name:'EditingCampaignAttr', params: { new: false }})
        },
        reportCampaign(campaign){
            this.$store.commit('score/update_temp_target_companies', [])
            this.$store.commit('update_campaign', campaign)
            campaign.scoring_target_companies.forEach(item=>{
                this.$store.commit('score/add_temp_target_companies', { company_name: item.company_name, company_id: item.company_id, required_info: item.required_info })
            })
            this.initiateReviews(campaign.campaign_id)
            .then ( () => {
                this.$router.push({name:'CampaignReport'})
            })
        },
        monitorCampaign(campaign){
            this.$store.commit('score/update_temp_target_companies', [])
            this.$store.commit('update_campaign', campaign)
            campaign.scoring_target_companies.forEach(item=>{
                this.$store.commit('score/add_temp_target_companies', { company_name: item.company_name, company_id: item.company_id, required_info: item.required_info })
            })
            this.initiateReviews(campaign.campaign_id)
            .then ( () => {
                this.$router.push({name:'ImprovementMonitoring'})
            })
        },
        progressCampaign(campaign){     
            if (campaign.is_public != true) {
                this.$store.commit('score/update_temp_target_companies', [])
                this.$store.commit('update_campaign', campaign)
                campaign.scoring_target_companies.forEach(item=>{
                    this.$store.commit('score/add_temp_target_companies', { company_name: item.company_name, company_id: item.company_id, required_info: item.required_info })
                })
                // console.log(this.temp_target_companies);
                // this.initiateReviews(campaign.campaign_id)
                // .then ( () => {
                    this.$router.push({name:'CampaignProgress'})
                // })
            }
            else alert('Public Survey 캠페인은 진행현황 확인이 어렵습니다. 관리자에게 문의하세요')
        },
        goAnswer(campaign){
            this.$store.commit('score/update_temp_target_companies', [])
            this.$store.commit('update_campaign', campaign)
            this.$store.dispatch('readTemplateTree', {template_code:campaign.template_code, is_previous:false})
            campaign.scoring_target_companies.forEach(item=>{
                this.$store.commit('score/add_temp_target_companies', { company_name: item.company_name, company_id: item.company_id, required_info: item.required_info })
            })
            this.initiateReviews(campaign.campaign_id)
            .then( ()=> {
                this.$router.push({name:'Answers'})
            }) 
        },
        checkAllowed(campaign_state, campaign_type, user_admin_level, target_admin_level){
            if(campaign_state == '평가중'||campaign_state == '결과배포' ||campaign_state == '종료') { return true }
            else if(user_admin_level == 4) { return true }
            else if(campaign_type=='owner') { return true }
            else if(campaign_type=='auditor' && user_admin_level < target_admin_level){
                return false
            }
            else 
            {
                return true
            }
        },        
        checkOptions(campaign_state, process_options_array){
            if (process_options_array != null){
                let idx = process_options_array.indexOf('후속모니터링')
                if (idx>-1 && ( campaign_state=='결과배포' || campaign_state=='종료')){ return true }
            }
            else {return false}            
        },
        readCampaignForEdit(campaign_id){
            // console.log(campaign_id);
            const path = this.$store.state.backend_host + '/read_campaign_for_edit'
            return axios.get(path, {params: {
                campaign_id : campaign_id, user_id: this.user_id
            }})
            .then(response => {
                // console.log(response.data);
                this.$store.commit('update_campaign', response.data)
            })
        }
    },
    created() {
        this.readCampaigns(this.user_id)
        if (this.is_admin_space_loaded != true){
            this.initiateSpace(this.user_id)
        }
    },
    mounted(){
        // console.log(this.campaigns_active);
    },
    components: {
    }

}
</script>

<style scoped>
#campaign-pause, #campaign-stop, #campaign-play {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    padding: 0.25rem 0.5rem;
}

.to-right {
  border:0;
  width:100%;
  height:14px;
  position: absolute;
  right:0;
  bottom: 0;
}

.to-right::before{
  content:"";
  position:absolute;
  right: 0;
  margin-right: -6px; 
  border-top:1px solid var(--gray-700);
  width:20.5px;
  transform: rotate(45deg);
  transform-Origin: 0% 0%;
}
.to-right-dashed {
  border:0;
  width:100%;
  height:14px;
  position: absolute;
  right:0;
  bottom: 0;
}

.to-right-dashed::before{
  content:"";
  position:absolute;
  right: 0;
  margin-right: -6px; 
  border-top:1px dashed var(--gray-700);
  width:20.5px;
  transform: rotate(45deg);
  transform-Origin: 0% 0%;
}

.floating1 {  
    animation-name: floating1;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}
@keyframes floating1 {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 4px); }
    to   { transform: translate(0, -0px); }    
}

.floating2 {  
    animation-name: floating2;
    animation-delay: 0.5s;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}
@keyframes floating2 {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 4px); }
    to   { transform: translate(0, -0px); }    
}

.hover-blue:hover{
    color: var(--blue);
}
.hover-dark:hover{
    color: var(--gray-700);
}
</style>
