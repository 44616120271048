<template>
<div>
    <b-card class="mb-3 bgray-100 text-left">
        슈퍼관리자 전용 가상로그인(기업변경) 입니다. 해당 기업의 관리자로 로그인한 것 처럼 보여집니다.  
    </b-card>

    <b-row class="mx-0"><b-col class="px-0 mb-2 d-flex justify-content-between align-items-center">
        <b-input v-model="search_keyword" placeholder="기업명을 검색해 주십시오" @keyup.enter="companySearch(search_keyword)"></b-input>
        <b-input-group-append><b-button variant="dark-border" @click="companySearch(search_keyword)"><fa icon="search"></fa></b-button></b-input-group-append>
    </b-col></b-row>

    <b-row class="mx-0"><b-col class="px-0">
        <div v-if="searched_companies.length > 0">
            <b-list-group id="company-search-list">
                <b-list-group-item class="px-3 py-2 search-result" v-for="item in searched_companies" :key="item.key" @click="selectCompany(item)">
                    <div class="f-90 fw-500 gray-700"> {{ item.company_name }}, {{ item.company_code }} <span class="f-80 fw-400 gray-500">(사업자등록번호)</span></div>
                </b-list-group-item>
            </b-list-group>
        </div>
        <div>
            회사명을 검색하여 주십시오
        </div>
    </b-col></b-row>
    
</div>    

</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
    data(){
        return {
            is_selected_company: false,
            searched_companies: [],
            search_keyword: '',

        }
    },
    computed:{
        ...mapState({ 
            campaigns: state => state.campaigns
        })
    },
    methods:{
        ...mapActions(['read_recieved_campaigns']),
        companySearch(search_keyword){
            this.is_selected_company = false
            if (search_keyword == '') {
                window.alert('검색할 기업명을 입력하여 주십시오')
                return false
            }
            else {
                const path = this.$store.state.backend_host + '/search_company'
                this.searched_companies = []

                return axios.get(path,{
                    params: {
                        search_keyword:search_keyword
                    }
                })
                .then(response => {
                    this.searched_companies = response.data.searched_companies
                })
                .catch(error => {
                    console.log(error)
                })
            }
        },
        selectCompany(company_item){
            //company_item
            // company_id: '',
            // company_name: '',
            // company_code: '',
            // company_address: '',
            // company_tel: '',
            // company_other_info: '',
            // admin_users: []
            this.$store.commit('change_login_company', { company_id: company_item.company_id, company_name: company_item.company_name })  
            console.log(company_item.admin_users[0])   

            if(company_item.admin_users.length != 0){ 
                this.read_recieved_campaigns(company_item.admin_users[0].admin_id)
                .then(() => {
                    this.$store.commit('update_campaign_in_progress',this.campaigns[0])
                })
            }            
        },

    }    
}
</script>