<template>
    <div class="py-3 px-0 px-md-1">  
        <b-card class="mb-3 bgray-100 text-left">
            캠페인에 함께 참여 중인 다른 참여자 또는 실사 전문가가 나에게 남긴 코멘트 및 메모 입니다. 
            코멘트에 대한 답변은 해당 질문 페이지로 이동 후 작성 가능합니다.
            <b-card-text class="bgray-300 f-90 gray-600 rounded">
                <div class="mb-1 d-flex"><div class="px-1 mr-1 blue border-blue bgray-50 rounded fw-500" style="width: 22px">캠</div> : 참여중인 캠페인 이니셜 </div>
                <b-badge variant="blue">홍길동</b-badge> : 코멘트 작성자 및 작성 시간
            </b-card-text>
        </b-card>

        <div class="mb-1 pr-2 gray-600 f-90 text-right"> 최신 코멘트 순 </div>
        <b-card v-if="my_comments_loaded && my_comments.length > 0" class="bgray-100 position-relative">
            <b-row class="mx-0 mb-3" v-for="item in my_comments" :key="item.id">
                <b-col class="px-0 col-1 position-relative">
                    <div class="comment-history-head"><campaign-plate :campaign_name="item.campaign_name" :campaign_id="item.campaign_id"></campaign-plate></div>
                    <div class="comment-history-line"></div>
                </b-col>
                <b-col class="pl-3">
                    <name-plate :user_name="item.author_name" :user_id="item.author_id"></name-plate> 
                    <span class="ml-2 f-90 gray-500"> {{ item.time_stamp }}</span><br>
                    <div class="mt-1"><span v-html="item.contents"></span></div>
                </b-col>
            </b-row>
        </b-card>

        <b-card v-else-if="!my_comments_loaded" class="bgray-100 p-5 text-center">
            <b-spinner label="comments loading"></b-spinner>
        </b-card>

        <b-card v-else-if="my_comments.length==0" class="bgray-100 p-5 text-center">
            <span class=""> 나에게 온 코멘트가 없습니다. </span>
        </b-card>

    </div>
</template>

<script>
import { Editor, EditorContent } from 'tiptap'
import * as ih from '../../../util'
import {
  Mention,
  Bold,
  Italic,
} from 'tiptap-extensions'

export default {
    data() {
        return {
            my_comments: [],
            my_comments_loaded: false,
        }
    },
    computed: {
        campaign_name: {
            get() {
                let campaign_name_temp = this.campaign.campaign_name
                if(campaign_name_temp == '') campaign_name_temp = '시작할 캠페인을 선택해 주세요'
                return campaign_name_temp
            },
            set(new_value) {
                this.campaign_name = new_value
            }
        },
        campaign_id() {
            return this.$store.state.campaign_in_progress.campaign_id
        },
        user_name(){
            return this.$store.state.user_name
        },
        user_id(){
            return this.$store.state.user_id
        },
    },
    methods:{
        readMyComment(){
            const path = this.$store.state.backend_host + '/read_my_comment'

            return axios.get(path, { params: {
                user_id: this.user_id,
            }})
            .then( response => {
                this.my_comments = response.data.my_comments
                //'mode': my_mention.mode,
                //'face': user1.company.company_name,
                //'contents': my_mention.contents,
                //'time_stamp': str(my_mention.time_stamp),
                //'campaign_id': my_mention.campaign_id,
                //'author_id': my_mention.user_id,
                //'campaign_name': my_mention.campaign_name,
                //'author_name': my_mention.user_name

                this.my_comments.forEach( item => {
                    let temp_editor = new Editor({
                        content: ih.JSONParse(item.contents),
                        editable: false,
                        extensions: [new Mention(), new Bold(), new Italic()]
                    })
                    item.contents = temp_editor.getHTML()
                    //console.log(item.contents)
                    temp_editor.destroy()
                })

                this.my_comments_loaded=true
            })
        },

    },
    created(){
        this.readMyComment()
    },
    components: {
       
    }

    
}
</script>
<style scoped>
.comment-history-head {
    position: absolute;
    width: 100%;
    z-index: 10;
}
.comment-history-line {
    position: absolute;
    height: 70%;
    margin-left: 1.5rem; 
    border-left: 1px solid var(--gray-300); 
    top: 2.8rem; 
    z-index: 1;
}

</style>