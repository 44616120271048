<template>
<div>
    <b-container fluid class="m-0 px-0 min-vh-100">

        <router-view name="provider_header"></router-view>
        <b-progress :max="100" class="sticky-top answering-progress" style="z-index: 100" v-if="progress_rate>0">
            <b-progress-bar :value="progress_rate" :label="progress_rate>4?$t(`답변 제출률`) + ` ${progress_rate.toFixed(0)}%`:''" :key="progress_key"></b-progress-bar>
        </b-progress>

        <b-row no-gutters class="m-0 p-0 align-items-sm-stretch w-100 position-relative" style="overflow-x:hidden;">
            <!-- 왼쪽 사이드 패널 -->
            <b-col id="sidebar" :class="[is_sidebar_collapsed ? 'collapsed':'', 'm-0 p-0 ']">
                <div :class="home_active_sytle==true?'bgray-200':'bgray-600'" class="h-100">
                    <router-view name="provider_aside" ></router-view>
                    <!-- <b-button class="border-0 rounded-0" variant="side-collapse" @click.stop="toggleSidebar">
                        <span v-if="!is_sidebar_collapsed"><fa icon="angle-double-left"></fa> </span>
                        <span v-else> <fa icon="angle-double-right"></fa> </span>
                    </b-button> -->
                </div>
            </b-col>              

            <b-col class="m-0 p-0">
                <b-container fluid class="m-0 p-0 h-100">
                <b-row no-gutters id="main" class="w-100 h-100">
                    <!-- 메인 패널 -->
                    <b-col class="m-0 text-left" > 
                        <router-view :question_code="question_code" @improvement-mode="toggleSides" :class="container_style==true?'container px-0':''"></router-view>
                    </b-col>

                    <!-- 오른쪽 사이드 패널 -->
                    <div class="d-none d-sm-block h-100">
                    <b-col id="right-side" :class="[is_right_side_collapsed ? 'collapsed' : '', ' m-0 px-4 bgray-200 position-relative h-100']">
                        <provider-tools></provider-tools>
                    
                        <b-button variant="right-collapse" class="border-0 rounded-0" @click.stop="toggleRightSide">
                            <span v-if="!is_right_side_collapsed"> <fa icon="angle-double-right"></fa> </span>
                            <span v-else> <fa icon="angle-double-left"></fa> </span>
                        </b-button>
                    </b-col>
                    </div>
                    
                    <!-- 도움이 필요하신가요 모달 -->
                    <b-button id="need-help" class="rounded-circle" variant="dark" size="lg" @click="showNeedHelp"><fa icon="question"></fa></b-button>
                    <b-tooltip target="need-help" triggers="hover"> {{$t(`도움이 필요하신가요?`)}} </b-tooltip>
                    <b-modal ref="need-help" size="lg" :title="$t(help_modal)" id="modify-modal-top" scrollable hide-footer><customer-service @close-need-help="hideNeedHelp"></customer-service></b-modal>

                    <!-- <back-to-top></back-to-top> -->
                </b-row>
                </b-container>
            </b-col>

        </b-row>

        <b-row no-gutters class="m-0">
            <b-col id="footer" class="px-0">
                <router-view name="provider_footer"></router-view>
            </b-col>
        </b-row>
        
    </b-container>
    <!-- <back-to-top></back-to-top> -->
</div>
</template>

<script>
import CustomerService from '@/components/provider_landing/CustomerService'
import BackToTop from '../utility/BackToTop'
import ProviderTools from './ProviderTools'

export default {
    data () {
        return {
            is_sidebar_collapsed: false,
            is_right_side_collapsed: false,
            home_active_sytle: true,
            help_modal: "도움이 필요하신가요?",
            progress_key: 123,
            container_style: false
        }
    },
    props: {
        question_code: {type: String, default: ''}
    },
    methods: {
        toggleSidebar(){
            this.is_sidebar_collapsed = !this.is_sidebar_collapsed
        },
        toggleRightSide() {
            this.is_right_side_collapsed = !this.is_right_side_collapsed
        },
        showNeedHelp(){
            this.$refs['need-help'].show()
        },
        hideNeedHelp(){
            this.$refs['need-help'].hide()
        },
        toggleSides(mode){
            if(mode=='close'){
                this.is_sidebar_collapsed = true
                this.is_right_side_collapsed = true
            }
            else {
                this.is_sidebar_collapsed = false
                this.is_right_side_collapsed = false
            }            
        }
    },
    created () {
        EventBus.$on('toggle-sidebar',this.toggleSidebar)
    },
    mounted(){
        if (this.campaign_in_progress.campaign_id==185){
            this.is_sidebar_collapsed = true
            this.is_right_side_collapsed = true
            this.container_style = true
        }
    },
    computed: {
        user_name() {
            return this.$store.getters.getUserName
        },
        user_id() {
            return this.$store.state.user_id
        },
        company_name() {
            return this.$store.getters.getCompanyName
        },
        e_mail(){
            return this.$store.getters.getEMail
        },
        isAuthenticated(){
            return this.$store.getters.isAuthenticated
        },
        isLoggedIn() {
            return this.$store.getters.isLoggedIn
        },
        campaign_in_progress() {
            return this.$store.state.campaign_in_progress
        },
        template_in_progress() {
            return this.$store.state.template_in_progress
        },
        template_code() {
            return this.campaign_in_progress.template_code
        },
        progress_rate(){
            return this.$store.getters.getProgressRate
        },
    },
    watch: {
        e_mail: function () {
            if (this.e_mail=='undefined') {
                alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                this.$store.dispatch('logout').then(() => { window.location.href = this.$store.state.frontend_host + '/login' })
                return
            }
        },
        $route:{
            immediate: true,
            handler(to, from){
                if(to.path.includes('/questions')){
                    this.home_active_sytle = false
                }
                else if(to.path=='/provider_preface'){
                    this.home_active_sytle = true
                }
            }
        }
    },
    components: {
        CustomerService,
        BackToTop,
        ProviderTools
    }
}
</script>

<style scoped>
.ih-tree{
    text-align: left;
}

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

.progress.answering-progress{
    border-radius: 0;
    background-color: var(--gray-100);
}
.progress.answering-progress > .progress-bar {
    background-color: var(--blue);
}


</style>
