<template>
<b-container class="px-0">
    <b-row class="mx-0"><b-col class="px-0 mb-2 d-flex justify-content-between align-items-center">
        <b-input-group>
            <b-form-input :disabled="disabled" v-model="search_keyword" placeholder="기업명을 검색해 주십시오" @keyup.enter="companySearch(search_keyword)"></b-form-input>
            <b-input-group-append><b-button variant="icon-append-wide" @click="companySearch(search_keyword)"><fa icon="search"></fa></b-button></b-input-group-append>
        </b-input-group>
    </b-col></b-row>

    <b-row class="mx-0"><b-col class="px-0">
        
        <div v-if="is_selected_company != true"> 
            <b-list-group id="company-search-list">
                <b-list-group-item class="px-3 py-2 search-result" v-for="item in searched_companies" :key="item.key" @click="selectCompany(item.company_id)">
                    <div class="f-90 gray-700"> {{ item.company_name }}, {{ item.company_code }} <span class="f-80 gray-500">(사업자등록번호)</span></div>
                </b-list-group-item>
            </b-list-group>
        </div>
        <div v-else></div>
    </b-col></b-row>

    <div v-if="is_selected_company == true" class="pl-1 f-90"> 
        <span class="gray-550"> <span class="fw-500">{{ selected_company.company_name }}</span> 선택되었습니다. </span>
    </div>
    <div v-if="company_not_found == true&&newly_created!=true" class="pl-1 f-90"> 
        <span class="red"><fa icon="info-circle" class="mr-1"></fa> 검색하신 기업을 찾을 수 없습니다. 새로 등록해 주세요.  </span>
    </div>
</b-container>
</template>

<script>
export default {
    data(){
        return {
            search_keyword: '',
            searched_companies: [],

            // selected_id: '',
            // is_loading_company: false,
            is_selected_company: false,
            company_not_found: false
        }
    },
    props: {
        before_search_keyword: { default: '' },
        target_company_name: { default: '' },
        disabled: {type: Boolean, default: false},
        no_portfolio: {type: Boolean, default: false},
        newly_created: {type: Boolean, default: false},
        campaign_id: {default:''}
    },
    computed: {
        selected_company(){
            return this.$store.state.selected_company
        }
    }, 
    methods: {
        companySearch(search_keyword){
            this.is_selected_company = false
            // this.$emit('company-selected', false)
            // this.is_loading_company =false           

            if (search_keyword == '') {
                window.alert('검색할 기업명을 입력하여 주십시오')
                return false
            }
            else {
                this.searched_companies = []
                const path = this.$store.state.backend_host + '/search_company'
                return axios.get(path,{
                    params: {
                        search_keyword:search_keyword,
                        campaign_id:this.campaign_id
                    }
                })
                .then(response => {
                    this.searched_companies = response.data.searched_companies
                    // console.log(this.searched_companies)

                    if(this.searched_companies.length == 0)
                    {                         
                        this.$emit('company-not-found', true)
                        this.company_not_found = true      
                        this.$emit('company-search-keyword', this.search_keyword)                 
                    }
                    else{
                        this.$emit('company-not-found', false)
                        this.company_not_found = false
                        // this.$emit('company-search-keyword', this.search_keyword)
                    }
                })
                .catch(error => {
                    console.log(error)
                    if (error.response && error.response.status == '401')
                        { alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                        this.dispatch('logout').then(() => { this.$router.push({name:'Login'}) })
                    }
                })
            }
        },
        selectCompany(company_id){
            // this.selected_id = company_id
            // console.log(company_id);

            const idx = this.searched_companies.findIndex(item => item.company_id == company_id)
            // console.log(idx);
            if(idx > -1){
                let selected_comp = {}
                selected_comp.company_id = this.searched_companies[idx].company_id
                selected_comp.company_name = this.searched_companies[idx].company_name
                selected_comp.company_code = this.searched_companies[idx].company_code
                selected_comp.company_address = this.searched_companies[idx].company_address
                selected_comp.company_tel = this.searched_companies[idx].company_tel
                selected_comp.company_other_info = this.searched_companies[idx].company_other_info
                selected_comp.admin_users = this.searched_companies[idx].admin_users
                selected_comp.all_users = this.searched_companies[idx].all_users
                
                this.$store.commit('update_selected_company', selected_comp)
                // console.log(selected_comp);
                this.is_selected_company = true
                // this.is_loading_company = true
                // this.$emit('company-selected', true, selected_comp)

                //기존 EditingPortfolioAttr에서 '@click=addTargetCompany()' 없이 바로 포트폴리오에 add하기 위해 스토어에 커밋함

                if (this.no_portfolio==false){
                    this.$store.commit('add_portfolio_target_company', selected_comp)
                }
            }
        },
        initiateCompanyFinder(company_name){
            this.companySearch(company_name)
            .then( ()=> {
                if (this.searched_companies.length>-1){
                    let idx = this.searched_companies.findIndex((item) => item.company_name == company_name)
                    this.selectCompany(this.searched_companies[idx].company_id)
                }
            })
        }
    },
    created() {
        // 일단 store의 selected_company 초기화
        let temp_company = {
            company_id: '',
            company_name: '',
            company_code: '',
            company_address: '',
            company_tel: '',
            company_other_info: '',
            admin_users: [],
            all_users:[]
        }
        this.$store.commit('update_selected_company', temp_company)

        // prop으로 받은게 있음면 넣어주기
        if(this.target_company_name != '') { this.initiateCompanyFinder(this.target_company_name) }
        if(this.before_search_keyword != '') { this.search_keyword = this.before_search_keyword }
    },
}
</script>

<style scoped>

</style>
