<template>
<div>
    <div class="mb-2 d-flex">
        <general-print-button @modal-opened="is_printing=true" @modal-closed="is_printing=false"
         print_target="full-report" :btn_variant="'dark-border'"> 인쇄하기 <fa icon="print" class="ml-2"></fa>
        </general-print-button>
        <b-button :variant=" $i18n.locale=='ko'?'red-border':'dark-border'" class="ml-3" @click="toggleLang()"> {{ $i18n.locale=='ko'? 'ENG' : 'KOR' }}</b-button>
    </div>
    <div id="full-report">
        <section v-if="local_campaign.campaign_id==124||local_campaign.campaign_id==177" class="min-vh-100 bg-white" :class="is_printing==true?'':'mb-5 shadow-md'">
            <report-header></report-header>
            <section class="px-8 py-5" style="min-height: calc(100vh - 145px)">
                <gsc-cover :is_printing="is_printing"  :class="is_printing==true?'':'mb-5'" style="min-height: calc(100vh - 300px)"></gsc-cover>
            </section>
            <report-footer></report-footer>
        </section>
        <section class="min-vh-100 bg-white" :class="is_printing==true?'':'mb-5 shadow-md'">
            <report-header></report-header>
            <section class="px-8 py-5" style="min-height: calc(100vh - 145px)">
                <first-page :is_printing="is_printing"  :class="is_printing==true?'':'mb-5'"></first-page>
            </section>
            <report-footer></report-footer>
        </section>
        <section v-if="conclusion_by_company.conclusion!=''" class="min-vh-100 bg-white" :class="is_printing==true?'':'mb-5 shadow-md'">
            <report-header></report-header>
            <section class="px-8 py-5" style="min-height: calc(100vh - 145px)">
                <audit-report :is_printing="is_printing"  :class="is_printing==true?'':'mb-5'"></audit-report>
            </section>
            <report-footer></report-footer>
        </section>
        <section class="min-vh-100 bg-white" :class="is_printing==true?'':'mb-5 shadow-md'">
            <report-header></report-header>
            <section class="px-8 py-5" style="min-height: calc(100vh - 145px)">
                <h2 class="pt-5 mb-5 tail-line-dark"> ESG {{local_campaign.campaign_id==124||local_campaign.campaign_id==177? $t('자가진단'):''}} {{$t(`상세 결과`)}} </h2>
                <result-report class="pb-5 mb-5"></result-report>

                <div class="d-flex justify-content-end py-5">
                    <b-card v-if="local_campaign.campaign_id==124||local_campaign.campaign_id==177" class="mt-auto p-4 border-0" style="width: 50vw">
                        <div class="f-140 mb-3">{{$t('2021 GS칼텍스 협력사 지속가능경영 진단 보고서')}}</div>
                        <div class="d-flex mb-2"> <div style="width: 120px" >{{$t('주관')}} </div>| {{$t('GS칼텍스 전략구매팀')}} / {{$t('CSR팀')}} </div>
                        <div class="d-flex" > <div style="width: 120px" >{{$t('평가기관')}} </div> | {{$t('퀀티파이드이에스지(QESG)')}} </div>
                        <div style="padding-left: 130px"> info@qesg.co.kr / 02-6956-2410 </div>
                    </b-card>
                </div>
            </section>
            <report-footer></report-footer>
        </section>
    </div>
</div>
</template>

<script>
import GeneralPrintButton from '@/components/print_to_pdf/GeneralPrintButton'
import ReportHeader from '@/components/supplier_report/ReportHeader'
import ReportFooter from '@/components/supplier_report/ReportFooter'

import FirstPage from '@/components/supplier_report/report_pages/FirstPage'
import ResultReport from '@/components/provider_landing/scm/ResultReport'
import GscCover from '@/components/supplier_report/report_pages/GscCover'
import AuditReport from '@/components/supplier_report/report_pages/AuditReport'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    components:{
        GeneralPrintButton,
        ReportHeader,
        ReportFooter,
        FirstPage,
        ResultReport,
        GscCover,
        AuditReport
    },
    data() {
        return {
            is_printing: false,
            local_campaign: {}
        }
    },
    computed:{
        ...mapState('score', ['is_score_loaded','is_evaluate_loaded','is_previous_score_loaded', 
        'is_comment_loaded', 'category_names', 'temp_target_companies','previous_temp_target_companies']
        ),
        ...mapState(['view_mode', 'campaign_in_progress', 'campaign']),
        ...mapState('reviews', ['conclusion_by_company', 'is_conclusion_by_company_loaded', 'recommendations_by_company', 'is_recommendations_by_company_loaded']),

        // ...mapState('industry_scores', ['category2_score', 'company_info', 'industry', 'assessment_info', 'total_score']),
        
    },
    methods:{
        // ...mapMutations('industry_scores', ['update_is_qe_factors_loaded']),
        checkOpened(){
            this.is_printing=true
        },
        toggleLang(){
            if (this.$i18n.locale == 'ko') { this.$i18n.locale='en' }
            else { this.$i18n.locale='ko' }
        }
    },
    created(){
        if (this.view_mode=='provider'){
            this.local_campaign = this.campaign_in_progress
        } else {
            this.local_campaign = this.campaign
        }
    },
    beforeDestroy(){
    }
   
}
</script>

<style>
#full-report {
    font-size: 1.1rem;
    color: var(--gray-900);
}
#full-report p {
    font-weight: 400;
}
.shadow-md{
    box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.15) !important;
}
</style>