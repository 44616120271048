<template>
<div>
    <b-container class="pt-5">
        <b-row>
            <b-col :class="is_editing_portfolio==true? 'pr-4':''">
                <div class="d-flex justify-content-between mb-3 border-bottom-dark"> 
                    <h5 class="">참여대상 그룹 리스트</h5>
                    <b-button v-if="value_new!=true&&is_admin>=4" @click="newPortfolio" class="py-1 fw-500 bottom-radius-0" variant="dark-border"><fa icon="plus" class="mr-2"></fa>새 그룹</b-button>
                </div>
                <template v-if="is_portfolios_loaded==true">
                    <div v-if="portfolios.length>0"> 
                        <b-button v-for="(item, index) in portfolios" :key="item.index" class=" d-block w-100 mb-2 text-left" variant="white-dark" @click="editPortfolioAttr(item)"> 
                            {{ index+1 }}. {{ item.portfolio_name }} <b-badge variant="emerging" class="ml-2">{{ item.portfolio_target_companies.length }} 개사</b-badge>
                        </b-button>
                    </div>
                    <b-card v-else class="bgray-100 border-0 text-center py-5 gray-550">참여대상 그룹 리스트가 없습니다. 새 그룹을 만들어 주세요.</b-card> 
                </template>
                <template v-else>
                    <b-card class="border-0 blue text-center"><b-spinner class="mx-2 p-1 my-1" small type="grow"></b-spinner> 참여대상 그룹 리스트를 가져오고 있습니다.</b-card>
                </template>
            </b-col>
            <b-col v-if="is_editing_portfolio==true" class="col-7 col-xl-8">
                <h5 class="mb-3 pb-2 border-bottom-dark">{{value_new==true?'새 리스트 만들기':'리스트 수정'}}</h5>
                <editing-portfolio-attr :value_new="value_new" @stop-edit="is_editing_portfolio=false" @read-again="readPortfolios"></editing-portfolio-attr>
            </b-col>
        </b-row>

    <hr class="border-0 bottom-space">

    </b-container>
</div>
</template>

<script>
import EditingPortfolioAttr from './EditingPortfolioAttr'

export default {
    data(){
        return {
            portfolios: [],
            value_new: false,
            is_editing_portfolio: false,
            is_portfolios_loaded: false
        }
    },
    props: {
        initiate_list: { type: Boolean, default:true }
    },
    computed: {
        portfolio() {
            return this.$store.state.portfolio
        },
        user_id() {
            return this.$store.state.user_id
        },
        is_admin() {
            return this.$store.getters.isAdmin
        },
    },
    methods: {
        readPortfolios(){
            this.is_portfolios_loaded = false
            const path = this.$store.state.backend_host + '/read_portfolio'

            return axios.get(path, {
                            params: {
                                user_id: this.user_id
                            }
            })
            .then(response => {
                // console.log('portfolios')
                // console.log(response.data.portfolios)
                this.portfolios = response.data.portfolios
                this.is_portfolios_loaded = true
            })
            .catch(error => {
                console.log('axios 실패')
                console.log(error)
                if (error.response.status == '401')
                    { alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
                     this.$store.dispatch('logout').then(() => { this.$router.push({name:'Login'}) })
                }
            })
        },
        editPortfolioAttr(portfolio){
            this.$store.commit('update_portfolio', portfolio)
            this.is_editing_portfolio = true
            this.value_new = false
        },
        // deletePortfolio(item){
            // const idx = this.portfolios.findIndex( function(x) { return x.portfolio_id == item.portfolio_id} )
            // if (idx > -1) this.portfolios.splice(idx, 1)

            // this.savePortfolios()
            // .then( EventBus.$emit('toast-success', '알림', `${item.portfolio_name}을 성공적으로 삭제하였습니다.`))

        // },
        // savePortfolios(){

        //     const path = this.$store.state.backend_host + '/save_portfolios'

        //     const formData = new FormData()

        //     let portfolios_id = []
        //     for(let i=0; i<this.portfolios.length; i++)
        //         portfolios_id.push(this.portfolios[i].portfolio_id)

        //     formData.append('user_id', this.$store.state.user_id)
        //     formData.append('portfolios_id', JSON.stringify(portfolios_id))

        //     return axios.post(path, formData)
        //     .then(response => {
        //         console.log(response.data)
        //     })
        //     .catch(error => {
        //         console.log('axios 실패')
        //         console.log(error)
        //         if (error.response.status == '401')
        //             { alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다. 다시 로그인해 주십시오.`))
        //              this.$store.dispatch('logout').then(() => { this.$router.push({name:'Login'}) })
        //         }
        //     })

        // },
        newPortfolio(){
            this.value_new = true
            this.is_editing_portfolio = true
        }
    },
    created() {
        this.readPortfolios(this.user_id)
    },
    components:{
        EditingPortfolioAttr
    }
}
</script>

<style scoped>

</style>
