//Campaign Policies agree/disagree 관련 전역 Vuex Store

const policies = {
    namespaced: true,
    state: {
      is_agree: false,
      policy_content: '',
      policy_content_e: '',
      policy_content_c: '',
      policy_id : null
    },

    mutations: {
        set_is_agree(state, mode){
            state.is_agree = mode
        },
        update_policy_content(state, new_item){
            state.policy_content = new_item
        },
        update_policy_id(state, new_id){
            state.policy_id = new_id
        },
        update_policy_content_e(state, new_item_e){
            state.policy_content_e = new_item_e
        },
        update_policy_content_c(state, new_item_c){
            state.policy_content_c = new_item_c
        }
    },

    getters: {

    },

    actions: {
        readAgree({commit, rootState}, {company_id, campaign_id}){
            //접속한 캠페인의 campaign_id, 로그인한 기업의 company_id 보내서 AGREE 상태 확인
            // console.log(company_id)
            const path = rootState.backend_host + '/read_agree'
            return axios.get(path, {
                params:{
                    company_id: company_id,
                    campaign_id: campaign_id
                }
            })
            .then(response =>{
                // console.log(response.data)   
                let result = response.data
                
                if(result.policy_id!=null){
                    commit('update_policy_id', result.policy_id)
                    commit('update_policy_content', result.contents)
                    commit('update_policy_content_e', result.contents_e)
                    commit('update_policy_content_c', result.contents_c)

                    if(result.agree==true){
                        commit('set_is_agree', true)
                    }else commit('set_is_agree', false)
                }
                else {
                    commit('update_policy_id', null)
                    commit('update_policy_content', '')
                    commit('set_is_agree', false)
                }

            })
            .catch(error => console.log(error))
            
        },
        saveAgree({commit, rootState}, {company_id, campaign_id, mode}){
            //접속한 캠페인의 campaign_id, 로그인한 기업의 company_id 보내서 save 상태 저장
            const path = rootState.backend_host + '/save_agree'

            const formData = new FormData()
            formData.append('company_id', company_id)
            formData.append('campaign_id', campaign_id)
            formData.append('user_id', rootState.user_id)
            formData.append('agree', mode)
            
            return axios.post(path, formData)
            .then(response => {
                // console.log(response)
                commit('set_is_agree', mode)
            })
            .catch(error =>console.log(error))
        },
    }
}

export default policies