<template>
    <b-container fluid class="px-0 px-md-5 py-0 py-md-3">
        <h4 class="py-3 px-2 fw-600"> 문의사항 메세지 관리(Need Help)</h4>
        
            <h6 class="fw-500"> 새 메시지 </h6>
                <b-table-simple hover class="mb-5 f-90 table-responsive">
                    <tr class="bgray-600 gray-100 border-0 f-90">
                        <td> 번호 </td> <td> 보낸사람</td> <td>받는사람</td><td class="w-50" style="min-width: 150px;">메시지 내용</td><td style="width: 20%;">날짜</td><td>확인여부</td><td>삭제</td>
                    </tr>
                    <tr v-for="(item, index) in help_direct_messages_new" :key="index" class="border-bottom f-90">
                        <td> {{ index }} </td>
                        <td> <b> {{ item.author_name }} </b></td>
                        <td> {{ item.target_name }}</td>
                        <td id="inboxed-message" class="align-items-center"> <span v-html="item.contents"></span> </td>
                        <td class="f-80"> {{ item.time_stamp }}</td>
                        <td> <b-button @click="makeReadMyDirectMessage(item.message_id)" variant="blue-border" size="sm"> 확인 <fa icon="check" class="ml-1"></fa></b-button></td>
                        <td> <b-button @click="makeArchiveMyDirectMessage(item.message_id)" variant="icon"><fa icon="trash-alt"></fa></b-button></td>
                    </tr>
                </b-table-simple>
            <h6 class="fw-500"> 받은메시지 </h6>
                <b-table-simple hover class="mb-5 f-90 table-responsive">
                    <tr class="bgray-600 gray-100 border-0 f-90">
                        <td> 번호 </td> <td> 보낸사람</td> <td>받는사람</td><td class="w-50" style="min-width: 150px;">메시지 내용</td><td style="width: 20%;">날짜</td><td>확인여부</td><td>삭제</td>
                    </tr>
                    <tr v-for="(item, index) in help_direct_messages_old" :key="index"  class="border-bottom f-90">
                        <td> {{ index }} </td>
                        <td> <b> {{ item.author_name }} </b></td>
                        <td> {{ item.target_name }} </td>
                        <td id="inboxed-message"> <span v-html="item.contents"></span> </td>
                        <td class="f-80"> {{ item.time_stamp }}</td>
                        <td> {{ item.has_read ? '확인함':'' }} </td>
                        <td> <b-button @click="makeArchiveMyDirectMessage(item.message_id)" variant="icon"><fa icon="trash-alt"></fa></b-button></td>
                    </tr>
                </b-table-simple>

            <h6 class="fw-500">보낸메시지 </h6> 
                <b-table-simple hover class="mb-5 f-90 table-responsive ">
                    <tr class="bgray-600 gray-100 border-0 f-90">
                        <td> 번호 </td> <td> 보낸사람</td> <td>받는사람</td><td class="w-50" style="min-width: 150px;">메시지 내용</td><td style="width: 20%;">날짜</td><td>확인여부</td><td>삭제</td>
                    </tr>
                    <tr v-for="(item, index) in sent_direct_messages" :key="index" class="border-bottom f-90">
                        <td> {{ index }} </td>
                        <td> {{ item.author_name }} </td>
                        <td> <b> {{ item.target_name }} </b> </td>
                        <td id="inboxed-message"> <span v-html="item.contents"></span> </td>
                        <td class="f-80 gray-500"> {{ item.time_stamp }}</td>
                        <td> {{ item.has_read ? '확인함':'미확인' }} </td>
                        <td> <b-button @click="deleteDirectMessage(item.message_id)" variant="icon"><fa icon="trash-alt"></fa></b-button></td>
                    </tr>
                </b-table-simple>

            <h6 class="fw-500">새메시지 보내기</h6> 
            <div v-if="user_universe_loaded" class="f-90 hj-user-finder comment_editor">
            <!--<editor-content id="user_finder" ref="user_finder" class="comment_editor_content text-left" :editor="user_finder"></editor-content>-->
                <b-input 
                autocomplete="off" 
                type="text" 
                id="user_finder" 
                ref="user_finder" 
                class="tippy-user-finder f-90 hj-user-finder comment_editor" 
                v-model="direct_message_target_name" 
                @focus="showTippy()" 
                @blur="hideTippy()" 
                placeholder="이름, e-mail로 검색"
                @keydown.up="upHandler()"
                @keydown.down="downHandler()"
                @keyup.enter="enterHandler()"
                @keydown.32="spaceHandler()"
                @keydown.9="enterHandler()">
                </b-input>
            </div>
            <div class="f-90 mb-0">
                <!--<editor-content id="direct_message" ref="direct_message" class="comment_editor_content text-left" :editor="message_contents"></editor-content>-->
                <b-textarea rows="5" ref="direct_message_textarea" class="f-90 comment_editor_content hj-commented" v-model="direct_message_textarea_contents"></b-textarea>
            </div>
            <div class="text-right"><b-button @click="saveDirectMessage()" class="py-1" variant="dark-border" size="sm"><fa icon="paper-plane"></fa></b-button></div>

            <div v-show="tippy_popup" class="suggestion-list" ref="suggestions">
            <template>
                <div v-if="hasResult">
                    <div
                    v-for="(user, index) in filteredUsers"
                    :key="user.user_id"
                    class="suggestion-list_item"
                    :class="{ 'is-selected': navigatedUserIndex === index }"
                    @click="selectUser(user)"
                    >
                    {{ user.user_name }}({{user.e_mail }})
                    </div>
                </div>
                <div v-else class="suggestion-list_item is-empty">
                    이름 또는 이메일 입력
                </div>
            </template>
            </div>
    </b-container>
</template>
<script>
import Fuse from 'fuse.js'
import tippy, { roundArrow }  from 'tippy.js'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import 'tippy.js/animations/shift-away.css'
import '../../assets/tiptap_editor.scss'
import * as ih from '../../util'
import * as hangul from 'hangul-js'
//import axios from 'axios'

import {
  HardBreak,
  Heading,
  Mention,
  Code,
  Bold,
  Italic,
  Placeholder
} from 'tiptap-extensions'

export default {
    data() {
        return {
            user_universe: [],
            user_universe_loaded: false,

            help_direct_messages: [],
            sent_direct_messages: [],

            direct_message_target_id: '',
            direct_message_target_name: '',
            direct_message_textarea_contents: '',

            message_contents: new Editor({
                content: ' ',
            }),

            suggestionRange: null,
            navigatedUserIndex: 0,
            insertMention: () => {},
            observer: null,
            tippy_popup: null,
            fuse: null,
        }
    },
    computed:{
        user_name() {
            return this.$store.getters.getUserName
        },
        company_name() {
            return this.$store.getters.getCompanyName
        },
        e_mail(){
            return this.$store.getters.getEMail
        },
        user_id(){
            return this.$store.state.user_id
        },
        isAuthenticated(){
            return this.$store.getters.isAuthenticated
        },
        isLoggedIn() {
            return this.$store.getters.isLoggedIn
        },
        is_admin(){
            return this.$store.getters.isAdmin
        },
        help_direct_messages_new_length(){
            return this.help_direct_messages_new.length
        },
        help_direct_messages_new(){
            let help_direct_messages_temp = []
            this.help_direct_messages.forEach( item => {
                if(item.has_read == false) help_direct_messages_temp.push(item)
            })
            return help_direct_messages_temp
        },
        help_direct_messages_old(){
            let help_direct_messages_temp = []
            this.help_direct_messages.forEach( item => {
                if(item.has_read == true) help_direct_messages_temp.push(item)
            })
            return help_direct_messages_temp
        },
        hasResult(){
            return this.filteredUsers.length
        },
        showSuggestions() {
            return this.direct_messages_target_name || this.hasResult
        },
        filteredUsers() {
            if(this.user_universe_loaded){
                let fuse_options = {
                    shouldSort: true,
                    findAllMatches: true,
                    threshold: 0.2,
                    location: 0,
                    distance: 100,
                    maxPatternLength: 32,
                    minMatchCharLength: 1,
                    keys: [
                        "user_name",
                        "e_mail"
                    ]}
                
                let fuse = new Fuse(this.user_universe, fuse_options)                
                let result = fuse.search(this.direct_message_target_name)
                if(this.tippy_popup) {
                    this.navigatedUserIndex = 0
                    this.tippy_popup[0].setContent(this.$refs.suggestions)
                }
                return result
            }
            else return []
        }
    },
    methods:{
        //윗 유저로 이동
        upHandler() {
            this.navigatedUserIndex = ((this.navigatedUserIndex + this.filteredUsers.length) - 1) % this.filteredUsers.length
        },

        //다음 유저로 이동. 마지막 유저일 경우 처음으로 이동
        downHandler() {
            this.navigatedUserIndex = (this.navigatedUserIndex + 1) % this.filteredUsers.length
        },

        //엔터 키 쳤을 경우
        enterHandler() {
            const user = this.filteredUsers[this.navigatedUserIndex]
            if (user) {
                this.selectUser(user)
            }
        },

        //스페이스키 쳤을 경우
        spaceHandler() {
            const user = this.filteredUsers[this.navigatedUserIndex]
            if (user) {
                this.selectUser(user)
            }
        },

        //우리가 추천한 것을 멘션을 포함한 텍스트로 변동시켜야 함
        selectUser(user){
            this.direct_message_target_name = user.user_name
            this.direct_message_target_id = user.user_id
            this.$refs['direct_message_textarea'].$el.focus()
        },
        initiateUserUniverse(){
            const path = this.$store.state.backend_host + '/read_user_universe'

            return axios.get(path, { params: {
                user_id : 63
            }})
            .then(response => {
                //console.log(response.data)
                this.user_universe = response.data.user_universe
                this.user_universe_loaded = true
                //'user_id': user.id,
                //'user_name': user.user_name,
                //'e_mail': user.e_mail,
                //'department': user.departement_name,
                //'company_name': user.company_name
            })
            .catch( error => {
                console.log(error)
            })

        },
        prepareSuggestions(){
            return this.user_universe
        },
        new_comment_contents() {
            return this.comment_editor.getJSON()
        },
        saveDirectMessage(){
            const path = this.$store.backend_host + 'save_direct_message'
            
            this.message_contents.setContent(this.direct_message_textarea_contents)
            let message = this.message_contents.getJSON()
            this.direct_message_textarea_contents = ''

            //받는 사람 입력하지 않았을 경우 핸들
            //let treated_target = this.user_finder.getHTML().replace(/(<([^>]+)>)/g,"").replace(/ /g,"")
            if(this.direct_message_target_id=='')
            {
                EventBus.$emit('toast-success','전송실패','메시지를 보낼 사람을 입력해야 합니다. 검색창에서 @을 눌러 검색한 뒤 [Enter] 또는 [Space]로 선택할 수 있습니다.')
                return false
            }

            //저장함수 Call
            let message_target = {
                user_name: this.direct_message_target_name,
                user_id: this.direct_message_target_id
            }
            let message_targets = []
            message_targets.push(message_target)

            this.saveDirectMessages(63, message_targets, message)
        },
        saveDirectMessages(user_id, targets, contents){
            const path = this.$store.state.backend_host + '/save_direct_message'

            let promises = []

            targets.forEach( target => {
                let formData = new FormData()
                formData.append('author_id',user_id)
                formData.append('target_id',target.user_id)
                formData.append('contents',JSON.stringify(contents))

                promises.push(
                    axios.post(path, formData)
                    .catch(error => {
                        console.log(error)
                    })
                )
            })

            return Promise.all(promises)
            .then( results => {
                results.forEach(
                    response=> {
                        EventBus.$emit('toast-success','성공', response.data.target_name + '님께 메시지를 보냈습니다.')
                        //console.log(response)
                    })
                //this.user_finder.setContent('')
                this.direct_message_target_name = ''
                this.direct_message_target_id = ''
                this.message_contents.setContent('')
            })
        },
        // readMyDirectMessages(){
        //     const path = this.$store.state.backend_host + '/read_my_direct_messages'

        //     return axios.get(path, { params: {
        //         user_id: this.user_id
        //     }})
        //     .then( response => {
        //         this.my_direct_messages = response.data.direct_messages
        //         //'message_id': message.id,
        //         //'author_id': message.author_id,
        //         //'author_name': author.user_name,
        //         //'target_id': message.target_id,
        //         //'target_name': target.user_name,
        //         //'contents': message.contents,
        //         //'time_stamp': message.time_stamp,
        //         //'has_read': message.has_read,
        //         //'has_archived': message.has_archived

        //         this.my_direct_messages.forEach( item => {
        //             let temp_editor = new Editor({
        //                 content: ih.JSONParse(item.contents),
        //                 editable: false,
        //                 extensions: [new Mention(), new Bold(), new Italic()]
        //             })
        //             item.contents = temp_editor.getHTML()
        //             temp_editor.destroy()
        //         })
        //     })
        // },
        readHelpMessages(){
        const path = this.$store.state.backend_host + '/read_my_direct_messages'

            return axios.get(path, { params: {
                user_id: 63
            }})
            .then( response => {
                this.help_direct_messages = response.data.direct_messages
                //'message_id': message.id,
                //'author_id': message.author_id,
                //'author_name': author.user_name,
                //'target_id': message.target_id,
                //'target_name': target.user_name,
                //'contents': message.contents,
                //'time_stamp': message.time_stamp,
                //'has_read': message.has_read,
                //'has_archived': message.has_archived

                this.help_direct_messages.forEach( item => {
                    let temp_editor = new Editor({
                        content: ih.JSONParse(item.contents),
                        editable: false,
                        extensions: [new Mention(), new Bold(), new Italic()]
                    })
                    item.contents = temp_editor.getHTML()
                    temp_editor.destroy()
                })
            })
        },
        makeReadMyDirectMessage(message_id){
            //DB처리
            const path = this.$store.state.backend_host + '/make_read_my_direct_message'

            let formData = new FormData()
            formData.append('message_id', message_id)
            return axios.post(path, formData)
            .then(response => {
                //로컬 데이터 처리
                let idx = this.help_direct_messages.findIndex( item => { return item.message_id == message_id })
                if(idx >-1){
                    Vue.set(this.help_direct_messages[idx], 'has_read', true)
                }
                //toast
                EventBus.$emit('toast-success','메시지 보관', '메시지를 성공적으로 읽음처리 하였습니다.')
            })
            .catch(error => {
                console.log(error)
            })
        },
        makeArchiveMyDirectMessage(message_id){
            const path = this.$store.state.backend_host + '/make_archive_my_direct_message'

            let formData = new FormData()
            formData.append('message_id', message_id)
            return axios.post(path, formData)
            .then(response => {
                //로컬 데이터 처리
                let idx = this.help_direct_messages.findIndex( item => { return item.message_id == message_id })
                if(idx >-1){
                    this.help_direct_messages.splice(idx, 1)
                }
                //toast
                EventBus.$emit('toast-success','메시지 삭제', '메시지를 성공적으로 삭제하였습니다.')
            })
            .catch(error => {
                console.log(error)
            })
        },
        readSentDirectMessages(){
            const path = this.$store.state.backend_host + '/read_sent_direct_messages'

            return axios.get(path, { params: {
                user_id: 63
            }})
            .then( response => {
                this.sent_direct_messages = response.data.sent_direct_messages
                //'message_id': message.id,
                //'author_id': message.author_id,
                //'author_name': author.user_name,
                //'target_id': message.target_id,
                //'target_name': target.user_name,
                //'contents': message.contents,
                //'time_stamp': message.time_stamp,
                //'has_read': message.has_read,
                //'has_archived': message.has_archived

                this.sent_direct_messages.forEach( item => {
                    let temp_editor = new Editor({
                        content: ih.JSONParse(item.contents),
                        editable: false,
                        extensions: [new Mention(), new Bold(), new Italic()]
                    })
                    item.contents = temp_editor.getHTML()
                    temp_editor.destroy()
                })
            })
        },
        deleteDirectMessage(message_id){
            const path = this.$store.state.backend_host + '/delete_direct_message'

            let formData = new FormData()
            formData.append('message_id', message_id)
            return axios.post(path, formData)
            .then(response => {
                //로컬 데이터 처리
                let idx = this.sent_direct_messages.findIndex( item => { return item.message_id == message_id })
                if(idx >-1){
                    this.sent_direct_messages.splice(idx, 1)
                }
                //toast
                EventBus.$emit('toast-success','메시지 삭제', '메시지를 삭제하였습니다. 주의: 받은 사람이 메시지를 더 이상 확인할 수 없습니다.')
            })
            .catch(error => {
                console.log(error)
            })
        },
        // sentDirectMessagesModal(){
        //     //먼저 로딩
        //     this.readSentDirectMessages()
        //     .then ( () => {
        //         this.$bvModal.show('my-sent-direct-message')
        //     })
        // },
        showTippy(){
            if(!this.tippy_popup)
            {
                this.tippy_popup = tippy('#user_finder',{

                    lazy:false, 
                    onTrigger(instance) {
                        instance.popperInstance.reference = this.$refs['user_finder']
                    },
                    content: this.$refs.suggestions,
                    trigger: 'manual',
                    interactive: true,
                    theme: 'dark',
                    placement: 'bottom',
                    inertia: true,
                    duration: 500,
                    showOnCreate: true,
                    arrow: true,
                    animation: 'shift-away',
                    popperOptions: {
                        positionFixed: true,
                    },

                })
            }
            this.tippy_popup[0].show()
        },
        hideTippy(){
            if(!this.tippy_popup) return false
            else {
                this.tippy_popup[0].hide()
            }
        }
    },
    created() {
        this.initiateUserUniverse()
        this.readHelpMessages()
        this.readSentDirectMessages()
    },
    mounted() {

    },
    watch: {
    },
    beforeDestroy(){
        if(this.fuse) this.fuse.destroy()
        if(this.message_contents) this.message_contents.destroy()
    },
    components: {
        Editor,
        EditorContent,
        EditorMenuBar,
    }
}
</script>
<style scoped>

</style>
