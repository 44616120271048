//Provider Service 중 Comments 관련 전역 Vuex Store

const comments = {
    namespaced: false,
    state: {
        user_space: [], 
        department_space: [], 
        company_space: []
    },

    mutations: {
        update_space(state, { user_space, department_space, company_space }){
            state.user_space = user_space
            state.department_space = department_space
            state.company_space = company_space
        }

    },
    getters: {
        getUserSpace(state){
            return state.user_space
        },
        getDepartmentSpace(state){
            return state.department_space
        },
        getCompanySpace(state){
            return state.company_space
        },
        getAllSpace(state){
            return state.user_space.concat(state.department_space).concat(state.company_space)
        }
    },
    actions: {
        initiateSpace({state, commit, rootState }, { user_id, campaign_id }){
            const path = rootState.backend_host + '/read_space'
            //console.log('request: user_id:'+user_id)
            return axios.get(path, { params: {
                user_id : user_id,
                campaign_id : campaign_id //추후 received_campaign 중심으로 불러올 수 있게 함
            }})
            .then( response => {
                //console.log(response)
                let new_user_space = response.data.user_space
                //user_space: {
                // 'user_id': item.user_id,
                // 'user_name': item.user_name,
                // 'e_mail': item.e_mail,
                // 'departement_id': item.department_id,
                // 'department_name': item.department_name,
                // 'company_id': item.company_id,
                // 'company_name': item.company_name
                // }
                let i=0;
                    new_user_space.forEach( item => {
                        Vue.set(item, 'id', i++)
                        Vue.set(item, 'mode', 'user')
                        Vue.set(item, 'face', item.user_name)
                    })

                let new_department_space = response.data.department_space
                //department_space: {
                //    'department_id': item2.department_id,
                //    'department_name': item2.department_name,
                //    'company_id': item2.company_id,
                //    'company_name': item2.company_name,
                //}
                    new_department_space.forEach( item => {
                        Vue.set(item, 'id', i++)
                        Vue.set(item, 'mode', 'department')
                        Vue.set(item, 'face', item.department_name)
                        Vue.set(item, 'user_id', 0)
                        Vue.set(item, 'user_name', '')
                    })

                let new_company_space = response.data.company_space
                //company_space: {
                // 'company_id': item3.id,
                // 'company_name': item3.company_name
                //}
                    new_company_space.forEach( item => {
                        Vue.set(item, 'id', i++)
                        Vue.set(item,'mode', 'company')
                        Vue.set(item, 'face', item.company_name)
                        Vue.set(item, 'user_id', 0)
                        Vue.set(item, 'user_name', '')
                        Vue.set(item, 'department_id', 0)
                        Vue.set(item, 'department_name', '')
                    })
                commit('update_space', { user_space:new_user_space, department_space: new_department_space, company_space: new_company_space })
            })
            .catch( error => {
                console.log(error)
            })

        }
    }
}

export default comments