<template>
<div>
    
        <!-- CheckBox 유형 Sub Component-->
        <div v-if="sub_type == 'checkbox'" class="pr-0">
            <df-checkbox :previous="previous" :preview="preview" :is_disabled_by_parent="is_disabled_by_parent" v-bind="item" :audit_mode="audit_mode"
            @update-parent-contents="handleUpdate" @update-sub-data-contents="handleSubDataContents" @update-pipeline="handleUpdatePipeline"
            :is_final_sub_component="is_final_sub_component" :print_mode="print_mode" :custom_style="custom_style"
            @load-finished="handleLoadFinished"></df-checkbox>
        </div>

        <!-- Table 유형 Sub Component -->
        <div v-else-if="sub_type == 'table'" class="mx-0" :class="custom_style=='saq'?'saq-card card-body bg-white mb-5':''">
            <df-table :previous="previous" :preview="preview" :is_disabled_by_parent="is_disabled_by_parent" v-bind="item" :audit_mode="audit_mode"
            @update-parent-contents="handleUpdate" @update-sub-data-contents="handleSubDataContents" @update-pipeline="handleUpdatePipeline"
            :is_final_sub_component="is_final_sub_component" :print_mode="print_mode" :custom_style="custom_style"
            @load-finished="handleLoadFinished"></DF-table>
        </div>

        <!-- Radio 유형 Sub Component -->
        <div v-else-if="sub_type == 'radio'" :class="custom_style=='saq'?'saq-card card-body bg-white mb-5':''">
            <df-radio :previous="previous" :preview="preview" :is_disabled_by_parent="is_disabled_by_parent" v-bind="item" :audit_mode="audit_mode"
            @update-parent-contents="handleUpdate" @update-sub-data-contents="handleSubDataContents" @update-pipeline="handleUpdatePipeline"
            :is_final_sub_component="is_final_sub_component" :print_mode="print_mode" :custom_style="custom_style"
            @load-finished="handleLoadFinished"></df-radio>
        </div>

        <!-- file_URL2 유형 Sub Component -->
        <div v-else-if="sub_type == 'file_URL2'">
            <df-file-url2 :previous="previous" :preview="preview" :is_disabled_by_parent="is_disabled_by_parent" v-bind="item" :audit_mode="audit_mode"
            @update-parent-contents="handleUpdate" @update-sub-data-contents="handleSubDataContents" @update-pipeline="handleUpdatePipeline"
            :is_final_sub_component="is_final_sub_component" :print_mode="print_mode" :custom_style="custom_style"
            @load-finished="handleLoadFinished"></df-file-url2>
        </div>

        <!-- input_text 유형 Sub Component -->
        <div v-else-if="sub_type == 'input_text'">
            <df-input-text :previous="previous" :preview="preview" :is_disabled_by_parent="is_disabled_by_parent" v-bind="item" :audit_mode="audit_mode"
            @update-parent-contents="handleUpdate" @update-sub-data-contents="handleSubDataContents" @update-pipeline="handleUpdatePipeline"
            :is_final_sub_component="is_final_sub_component" :print_mode="print_mode" :custom_style="custom_style"
            @load-finished="handleLoadFinished"></df-input-text>
        </div>

        <!-- input_number 유형 Sub Component -->
        <div v-else-if="sub_type == 'input_number'">
            <df-input-number :previous="previous" :preview="preview" :is_disabled_by_parent="is_disabled_by_parent" v-bind="item" :audit_mode="audit_mode"
            @update-parent-contents="handleUpdate" @update-sub-data-contents="handleSubDataContents" @update-pipeline="handleUpdatePipeline"
            :is_final_sub_component="is_final_sub_component" :print_mode="print_mode" :custom_style="custom_style"
            @load-finished="handleLoadFinished"></df-input-number>
        </div>

        <!-- textbox 유형 Sub Component -->
        <div v-else-if="sub_type == 'textbox'">
            <df-textbox :previous="previous" :preview="preview" :is_disabled_by_parent="is_disabled_by_parent" v-bind="item" :audit_mode="audit_mode"
            @update-parent-contents="handleUpdate" @update-sub-data-contents="handleSubDataContents" @update-pipeline="handleUpdatePipeline"
            :is_final_sub_component="is_final_sub_component" :print_mode="print_mode" :custom_style="custom_style"
            @load-finished="handleLoadFinished"></df-textbox>
        </div>

        <!-- materiality 유형 Sub Component --> 
        <div v-else-if="sub_type == 'materiality'">
            <df-materiality :previous="previous" :preview="preview" :is_disabled_by_parent="is_disabled_by_parent" v-bind="item" :audit_mode="audit_mode"
            @update-parent-contents="handleUpdate" @update-sub-data-contents="handleSubDataContents" @update-pipeline="handleUpdatePipeline"
            :is_final_sub_component="is_final_sub_component" :print_mode="print_mode" :custom_style="custom_style"
            @load-finished="handleLoadFinished"></df-materiality>
        </div>

        <!-- priority 유형 Sub Component --> 
        <div v-else-if="sub_type == 'priority'">
            <df-priority :previous="previous" :preview="preview" :is_disabled_by_parent="is_disabled_by_parent" v-bind="item" :audit_mode="audit_mode"
            @update-parent-contents="handleUpdate" @update-sub-data-contents="handleSubDataContents" @update-pipeline="handleUpdatePipeline"
            :is_final_sub_component="is_final_sub_component" :print_mode="print_mode" :custom_style="custom_style"
            @load-finished="handleLoadFinished"></df-priority>
        </div>

        <!-- guide_text 유형 Sub Component --> 
        <div v-else-if="sub_type == 'guide_text'">
            <df-guide-text :previous="previous" :preview="preview" :is_disabled_by_parent="is_disabled_by_parent" v-bind="item" :audit_mode="audit_mode"
            @update-parent-contents="handleUpdate" @update-sub-data-contents="handleSubDataContents" @update-pipeline="handleUpdatePipeline"
            :is_final_sub_component="is_final_sub_component" :print_mode="print_mode" :custom_style="custom_style"
            @load-finished="handleLoadFinished"></df-guide-text>
        </div>


        <!-- 기타 -->
        <div v-else>
            <p> 타입 {{ item.sub_type }} </p>
            <p> 내용 {{ item }} </p>
        </div>
                
        <!-- Depreciated 
        <div fluid v-else-if="sub_type == 'radio_group'"><df-radio-group :is_disabled_by_parent="is_disabled_by_parent" v-bind="item"></df-radio-group></div>
        <div v-else-if="sub_type == 'file'" class="mb-5"><df-file :is_disabled_by_parent="is_disabled_by_parent" v-bind="item"></df-file></div
        <div v-else-if="sub_type == 'URL'" class="mb-5"><df-url :is_disabled_by_parent="is_disabled_by_parent" v-bind="item"></df-url></div>
        <div v-else-if="sub_type == 'file_URL'" class="mb-5"><df-file-url :is_disabled_by_parent="is_disabled_by_parent" v-bind="item"></df-file-url></div>
        <div v-else-if="sub_type == 'container_radio'"><df-container-radio :is_disabled_by_parent="is_disabled_by_parent" v-bind="item"></df-container-radio></div>
        <div v-else-if="sub_type == 'rich_textbox'"><df-rich-textbox :is_disabled_by_parent="is_disabled_by_parent" v-bind="item"></df-rich-textbox></div>
        <div v-else-if="sub_type == 'editable_table'"><df-editable-table :is_disabled_by_parent="is_disabled_by_parent" v-bind="item"></df-editable-table></div>
        -->

</div>
</template>

<script>
import DFCheckBox from './DFCheckBox'
import DFFile_URL2 from './DFFile_URL2'
import DFInputNumber from './DFInputNumber'
import DFInputText from './DFInputText'
import DFRadio from './DFRadio'
import DFTable from './DFTable'
import DFTextbox from './DFTextbox'
import DFEditableTable from './DFEditableTable'
import DFMateriality from './DFMateriality'
import DFPriority from './DFPriority'
import DFGuideText from './DFGuideText'
//import DFContainerRadio from './DFContainerRadio'
//import DFRichTextbox from './DFRichTextbox'
//import DFFile_URL from './DFFile_URL'
//import DFURL from './DFURL'
//import DFRadioGroup from './DFRadioGroup'
//import DFFile from './DFFile'

export default {
    data() {
        return {

        }
    },
    props:{
        item: { type: Object },
        sub_type: { type: String },
        is_disabled_by_parent: { type: Boolean },
        preview: { type:Boolean },
        previous: { type: Boolean },
        audit_mode: { type: Boolean },
        is_final_sub_component: { type: Boolean, default: false },
        print_mode: { type: Boolean, default: false },
        custom_style: { type: String, default: ''}

    },
    components:{
        'df-checkbox': DFCheckBox,
        'df-file-url2': DFFile_URL2,
        'df-input-number': DFInputNumber,
        'df-input-text': DFInputText,
        'df-radio': DFRadio,
        'df-table': DFTable,
        'df-textbox': DFTextbox,
        'df-materiality': DFMateriality,
        'df-priority': DFPriority,
        'df-guide-text': DFGuideText,
        //'df-container-radio': DFContainerRadio,
        //'df-rich-textbox': DFRichTextbox,
        //'df-url': DFURL,
        //'df-radio-group': DFRadioGroup,
        //'df-file': DFFile,
        //'df-file-url': DFFile_URL,
        //'df-editable-table': DFEditableTable,
    },
    methods:{
        handleUpdate(sub_data_contents, sub_code){
            this.$emit('update-parent-contents',sub_data_contents, sub_code)
        },
        handleSubDataContents(sub_data_contents, sub_code){
            this.$emit('update-sub-data-contents',sub_data_contents, sub_code)
        },
        handleUpdatePipeline(){
            this.$emit('update-pipeline')
        },
        handleLoadFinished(){
            this.$emit('load-finished')
        },
    },
    
}
</script>

<style scoped>

</style>
