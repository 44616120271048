<template>
    <div class="pb-5" style="z-index:10;">
        <b-button @click="clickCampaignName()" class="px-3 text-left border-0" block :variant="home_active_sytle==true?'icon-blue':'icon-gray'" style="height: 48px;"><fa icon="map-marker-alt" class="mr-1"/> {{byLang('안내', 'Home', '主页')}} </b-button> 
        <div v-if="template_in_progress_loaded==true" style="z-index:0">
            <div v-for="(lv1,idx) in new_question_list" :key="lv1.id" :class="home_active_sytle==false?'question-active' : ''" class="mb-1">
                <div class="pl-3 py-1 fw-500 category1s"  :aria-controls="`cat-${idx}`" @click="toggleList(idx)">
                    <fa :icon="visible[idx]==true?'caret-down' : 'caret-right'" class="mr-1"></fa> {{ byLang(lv1.category1_name, lv1.category1_name_e, lv1.category1_name_c) }} 
                </div> 
                <b-collapse v-model="visible[idx]" :id="`cat-${idx}`">
                    <b-list-group v-for="lv2 in lv1.category2" :key="lv2.question_code"> 
                        <div @click="pageGo(lv2)" :class="[is_active == lv2.question_code ? `active`: '', 'category3s']" class="d-flex justify-content-between"> {{ byLang(lv2.question_name, lv2.question_name_e, lv2.question_name_c) }}
                            <!-- <template v-if="lv2.children.length==0">
                                <span v-if="lv2.is_completed=='true'" v-b-tooltip.hover :title="$t(`답변 완료`)"> <fa style="width:0.8rem;" :icon="['fas', 'square']" class="ml-2 mr-1"></fa></span>
                                <span v-else v-b-tooltip.hover :title="$t('답변 작성중')" class="gray-550"> <fa style="width:0.8rem;" :icon="['far','square']" class="ml-2 mr-1"></fa></span>
                            </template> -->
                        </div>
                        <b-list-group-item v-for="lv3 in lv2.children" :key="lv3.question_code" @click="pageGo(lv3)" :class="[is_active == lv3.question_code ? `active`: '', 'category3s']" class="d-flex justify-content-between"> {{ byLang(lv3.question_name, lv3.category1_name_e, lv3.category1_name_c) }}
                            <span v-if="lv3.is_completed=='true'" v-b-tooltip.hover :title="$t(`답변 완료`)"> <fa style="width:0.8rem;" :icon="['fas', 'square']" class="ml-2 mr-1"></fa></span>
                            <span v-else v-b-tooltip.hover :title="$t('답변 작성중')" class="gray-550"> <fa style="width:0.8rem;" :icon="['far','square']" class="ml-2 mr-1"></fa></span>
                        </b-list-group-item>
                    </b-list-group>
                </b-collapse>
            </div>
        </div>
        <div v-else>    
            <div class="px-3">
                <b-skeleton animation="wave" width="85%" height="30px" class="my-3"></b-skeleton>
                <b-skeleton animation="wave" width="70%"  height="30px" class="my-3"></b-skeleton>
                <b-skeleton animation="wave" width="70%"  height="30px" class="my-3"></b-skeleton>
                <b-skeleton animation="wave" width="70%" height="30px" class="my-3"></b-skeleton>
                <b-skeleton animation="wave" width="55%"  height="30px" class="my-3"></b-skeleton>
                <b-skeleton animation="wave" width="85%"  height="30px" class="my-3"></b-skeleton>
                <b-skeleton animation="wave" width="70%" height="30px" class="my-3"></b-skeleton>
                <b-skeleton animation="wave" width="70%"  height="30px" class="my-3"></b-skeleton>
                <b-skeleton animation="wave" width="70%"  height="30px" class="my-3"></b-skeleton>
            </div>
        </div>           
        <hr class="border-0 pb-5">
    </div>
</template>

<script>
import {DraggableTree} from 'vue-draggable-nested-tree'
import * as th from 'tree-helper'
import * as ih from '@/util'
import PrintToPDF from '../print_to_pdf/PrintToPDF'
import MakeAllCompleteButton from '@/components/main_layout/MakeAllCompleteButton'
import { mapState, mapMutations } from 'vuex'

export default {
    data(){
        return {
            is_active: 'false',
            is_sidebar_collapsed: false,
            tree_key: 21918481, //random number
            home_active_sytle: true,
            visible: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true]
        }
    },
    props:{
    },
    computed: {
        ...mapState(['template_in_progress','template_in_progress_loaded', 'question_in_progress',  ]),
        
        template_code() {
            return this.template_in_progress.template_code
        },
        template_contents() {
            return this.template_in_progress.template_contents
        },
        isIE() {
            if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (navigator.userAgent.toLowerCase().indexOf("msie") != -1) )
                {return true }
            else return false
        },
        new_question_list(){
            let new_list = []
            this.template_contents.forEach( x=> {
                let new_obj = {}
                new_obj.category1_name = x.question_name
                new_obj.category1_name_e = x.question_name_e
                new_obj.category1_name_c = x.question_name_c
                new_obj.category2 = []
                x.children.forEach(y => {
                    new_obj.category2.push(y)                   
                })
                new_list.push(new_obj)
            })
            // console.log(new_list);
            return new_list
        }
    },
    methods: {
        byLang(ko, en, cn){
            if(this.$i18n.locale=='en') return en
            else if (this.$i18n.locale=='cn') return cn
            else return ko
        },
        pageGo(category2){
            // console.log(category2);
            this.is_active = category2.question_code

            let temp_question = {...category2}
            if(typeof(category2.question_contents)=='string') { // string 형태로 가지고 있는 것 parsing 해야함
                temp_question.question_contents = JSON.parse(category2.question_contents)
            }
            this.$store.commit('update_question_in_progress', temp_question)
            this.$store.dispatch('questions/readSubsInit', temp_question.question_contents)
            .then( () => {
                Vue.nextTick( () => {
                    // console.log('퀘스천 코드' + temp_question.question_code)
                    this.$router.push( { name: 'Questions', params: { question_code: temp_question.question_code }} )
                })
            })
            .catch(error => { console.log(error)
            })
        },
        clickCampaignName(){
            this.$router.push({name:'ProviderPreface'})
        },
        toggleList(idx){
            if(this.visible[idx]==true){
                this.visible.splice(idx, 1, false)
            }
            else this.visible.splice(idx, 1, true)
        },
        handleMoveNextQuestion(new_question_code){
            this.is_active = new_question_code
        },
        refreshTree(){
            this.tree_key += 10
        }
    },
    created(){
        EventBus.$on('move-next-question', this.handleMoveNextQuestion)
        EventBus.$on('refresh-tree', this.refreshTree) // from Question or Make Progress Complete All
    },
    updated(){
        // console.log(this.template_in_progress);
        //초기 상태면 모두 접기
        //if(this.initiating == true) this.collapseAll()
    },
    watch:{
        $route:{
            immediate: true,
            handler(to, from){
                if(to.path.includes('/questions')){
                    this.home_active_sytle = false
                }
                else if(to.path=='/provider_preface'){
                    this.home_active_sytle = true
                    this.is_active = ''
                }
            }
        }
    },
    components: {
        Tree: DraggableTree,
        'print-to-pdf': PrintToPDF,
        MakeAllCompleteButton: MakeAllCompleteButton
    }

}
</script>

<style>
.category1s{
    /* background: linear-gradient(90deg, var(--gray-300), var(--gray-200)); */
   border-bottom: 1px solid var(--gray-300);

}
.category3s{
    border: 0;
    border-radius: 0;
    letter-spacing: -0.2px;
    cursor: pointer;
    background-color: var(--gray-200);
    font-size: 0.9rem;
    color: var(--gray-600);
    padding: 0.25rem 1rem 0.25rem 1.75rem ;
}
.category3s:hover{
    background-color: var(--gray-300);
}

.question-active .category1s {
    color: var(--gray-100);
    border-bottom: 1px solid var(--gray-400);
    font-weight: 400;
}

.question-active .category3s{
    color: var(--gray-300);
    font-weight: 300;
    background-color: var(--gray-600);
}
.question-active .node-level-1:hover {
    color: var(--gray-50);
    border-bottom: 1px solid var(--gray-400);
}
.question-active .category3s:hover{
    background-color: var(--gray-700);
    color: var(--gray-100);
}
.question-active .node-level-1-active,
.question-active .category3s.active,
.question-active .node-level-3-active,
.question-active .node-level-4-active {
    background-color: var(--blue);
    color: var(--gray-100);
}
.question-active .node-level-1-active:hover,
.question-active .category3s.active:hover,
.question-active .node-level-3-active:hover,
.question-active .node-level-4-active:hover {
    background-color: var(--blue5);
    color: var(--gray-100);
}

[id^='cat'] > .list-group-item.active {
    background-color: var(--blue);
    border: 0;
}

.print-aside{
    top: 0;
    right:0; 
    margin-right:-44px;
    z-index: 1;
}
.print-aside:hover{
    top: 0;
    right:0; 
    margin-right:-195px;
}

</style>
