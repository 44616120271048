<template>
<div>
    <div v-for="(lv1,idx) in new_question_list" :key="lv1.id" class="mb-1">
        <div class="py-1 fw-500 category1s"  :aria-controls="`cat-${idx}`" @click="toggleList(idx)">
            <fa :icon="visible[idx]==true?'caret-down' : 'caret-right'" class="mr-1"></fa> {{ lv1.category1_name }} 
        </div>
        <b-collapse v-model="visible[idx]" :id="`cat-${idx}`">
            <b-list-group v-for="lv2 in lv1.category2" :key="lv2.question_code">
                <div @click="pageGo(lv2)" :class="[is_active == lv2.question_code ? 'active': '', 'category3s']" > {{ lv2.question_name }} </div>
                <b-list-group-item v-for="lv3 in lv2.children" :key="lv3.question_code" @click="pageGo(lv3)" :class="[is_active == lv3.question_code ? `active`: '', 'category3s']" 
                  class="pl-3 d-flex justify-content-between"> {{lv3.question_name}}
                </b-list-group-item>
            </b-list-group>
        </b-collapse>
    </div> 
</div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
export default {
    data(){
        return {
            is_active: 'false',
            tree_key: 21918481, //random number
            visible: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true]
        }
    },
    props:{
    },
    computed: {
        ...mapState(['template_tree', 'campaign']),
        answer_company(){
            return this.$store.state.questions.answer_company
        },
        new_question_list(){
            let new_list = []
            this.template_tree.template_contents.forEach( x=> {
                let new_obj = {}
                new_obj.category1_name = x.question_name
                new_obj.category2 = []
                x.children.forEach(y => {
                    new_obj.category2.push(y)                   
                })
                new_list.push(new_obj)
            })
            return new_list
        }
    },
    methods:{
        pageGo(category3) {
            this.is_active = category3.question_code
            // console.log(this.is_active);
            // console.log(this.$store.state.question_composition.question_code);
            this.$store.commit('update_is_question_subs_loaded',false)
            this.$store.dispatch('readQuestionComposition', category3.question_code)
            .then( () => {
                this.$store.dispatch('readQuestionSubs')
                .then( () => {
                    this.$store.dispatch('questions/readSubsInit', this.$store.state.question_composition.question_contents)
                    this.$store.dispatch('score/readOverallComment', {campaign_id: this.campaign.campaign_id, 
                                                                        company_id: this.answer_company.company_id, 
                                                                        question_code: category3.question_code})
                })        
            })
        },
        toggleList(idx){
            if(this.visible[idx]==true){
                this.visible.splice(idx, 1, false)
            }
            else this.visible.splice(idx, 1, true)
        },
    },
    created() {
        // this.$store.dispatch('readTemplateTree',{ template_code: this.template_tree.template_code, is_previous: false})
        // EventBus.$on('update-tree-contents',this.updateTree)        
    },
    mounted(){
        // console.log(this.new_question_list);
    }
}
</script>

<style scoped>
.category1s{
   border-bottom: 1px solid var(--gray-300);
}
.category3s{
    border: 0;
    border-radius: 0;
    letter-spacing: -0.2px;
    cursor: pointer;
    background-color: var(--gray-100);
    font-size: 0.9rem;
    color: var(--gray-600);
    padding: 0.25rem 0.5rem ;
}
.category3s:hover{
    background-color: var(--gray-300);
}

.question-active .category1s {
    color: var(--gray-100);
    border-bottom: 1px solid var(--gray-400);
    font-weight: 400;
}

.question-active .category3s{
    color: var(--gray-300);
    background-color: var(--gray-650);
    font-weight: 300;
}
.question-active .category3s:hover{
    background-color: var(--gray-700);
    color: var(--gray-100);
}
.category3s.active,
.question-active .category3s.active {
    background-color: var(--blue);
    color: var(--gray-100);
}
.category3s.active:hover,
.question-active .category3s.active:hover {
    background-color: var(--blue5);
    color: var(--gray-100);
}

[id^='cat'] > .list-group-item.active {
    background-color: var(--blue);
    border: 0;
}

</style>