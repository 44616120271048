<template>
<div class="py-5 bgray-100"> 
    <b-container class="h-80vh py-sm-5">
        <div class="d-flex justify-content-between mb-4">
            <h5 class="mb-0 fw-600">{{ $t(`나의 캠페인`) }}</h5>
            <div v-if="campaign_on_list.length>=4" class="">{{ $t(`최신순`) }} <fa icon="caret-down" class="ml-1"></fa></div>
        </div>
        <template v-if="is_campaigns_loaded==true">
            <div v-if="campaigns.length>0">
                <b-card-group v-if="campaign_on_list.length>0" deck class="my-auto mb-5 pb-5">
                    <b-card v-for="company_camp in campaign_on_list" :key="company_camp.id" no-body @click="pageGo(company_camp)" 
                    class="intro-camp-card mb-4 mb-md-5 position-relative shadow-sm cursor ">
                        <b-overlay :show="checkExcluded(company_camp)==true" rounded="sm" class="camp-overlay h-100 gray-550">
                            <div class="p-3 d-flex flex-column h-100">
                                <h5 class="mb-4"> {{ byLang(company_camp.campaign_name, company_camp.campaign_name_e, company_camp.campaign_name_c) }} </h5>
                                <div class="mt-auto">
                                    <div class="f-120 mb-2"><b-badge :variant="badges(company_camp.campaign_state)" size="sm">{{ state_text(company_camp.campaign_state) }}</b-badge></div>
                                    <div class="d-flex justify-content-between">
                                        <div class="f-90 gray-550 fw-300"> {{ date_to_string(company_camp.start_date) }} ~ {{ date_to_string(company_camp.end_date) }}</div>
                                        <div class="f-90 gray-550"> {{ $t(company_camp.operator_name) }} </div>
                                    </div>
                                </div>
                            </div>
                            <template v-slot:overlay>  <!-- excluded campaign일 경우 -->
                                <div class="text-center " >                               
                                    <p class="mb-2"> <fa icon="exclamation-circle" class="mr-1"></fa>{{ $t(`참여대상이 아닙니다.`) }}</p> 
                                    <p class="mb-0 f-90">{{ $t(`참여를 원하실 경우 문의해주세요.`) }}</p> 
                                </div>
                            </template>
                        </b-overlay>
                    </b-card>
                </b-card-group>
                <div class="d-flex justify-content-between mb-4" v-if="campaign_off_list.length>0">
                    <h5 class="mb-0">{{ $t(`종료된 캠페인`) }}</h5>
                    <div v-if="campaign_off_list.length>=4" class="">{{ $t(`최신순`) }} <fa icon="caret-down" class="ml-1"></fa></div>
                </div>
                <b-card-group v-if="campaign_off_list.length>0" deck class="my-auto mb-5 pb-5">
                    <b-card v-for="company_camp in campaign_off_list" :key="company_camp.id" no-body @click="pageGo(company_camp)" 
                    class="intro-camp-card mb-4 mb-md-5 position-relative shadow-sm cursor ">
                        <b-overlay :show="checkExcluded(company_camp)==true" rounded="sm" class="camp-overlay h-100 gray-550">
                            <div class="p-3 d-flex flex-column h-100">
                                <h5 class="mb-4"> {{ $t(company_camp.campaign_name) }} </h5>
                                <div class="mt-auto">
                                    <div class="f-120 mb-2"><b-badge :variant="badges(company_camp.campaign_state)" size="sm">{{ state_text(company_camp.campaign_state) }}</b-badge></div>
                                    <div class="d-flex justify-content-between">
                                        <div class="f-90 gray-550 fw-300"> {{ date_to_string(company_camp.start_date) }} ~ {{ date_to_string(company_camp.end_date) }}</div>
                                        <div class="f-90 gray-550"> {{ $t(company_camp.operator_name) }} </div>
                                    </div>
                                </div>
                            </div>
                            <template v-slot:overlay>  <!-- excluded campaign일 경우 -->
                                <div class="text-center " >                               
                                    <p class="mb-2"> <fa icon="exclamation-circle" class="mr-1"></fa>{{ $t(`참여대상이 아닙니다.`) }}</p> 
                                    <p class="mb-0 f-90">{{ $t(`참여를 원하실 경우 문의해주세요.`) }}</p> 
                                </div>
                            </template>
                        </b-overlay>
                    </b-card>
                </b-card-group>
            </div>
            <div v-else>
                <b-card class="intro-camp-card">
                    <h5 class="gray-600 mb-4">{{ $t(`등록된 캠페인이 없습니다.`) }}</h5> 
                    <p class="gray-500 fw-400">{{ $t(`캠페인 셋팅이 완료되면 이메일로 안내될 예정이니 잠시만 기다려주세요.`) }}</p>
                    <p class="gray-500 mb-0 fw-400"> <fa icon="exclamation-circle" class="mr-1"></fa>{{ $t(`이미 안내를 받으셨는데 캠페인이 보이지 않는다면`) }} <b-button variant="icon-dark" class="p-0 mb-2 f-100 hover-bottomline" @click="showNeedHelp">{{ $t(`문의해주세요.`) }}</b-button> </p>
                </b-card>
            </div>
        </template>
        <template v-else>
            <b-row>
                <b-col><b-skeleton-img height="150px"></b-skeleton-img></b-col>
                <b-col><b-skeleton-img height="150px"></b-skeleton-img></b-col>
            </b-row>
        </template>

        <div class="text-center pt-5">
            <a href="http://qesg.co.kr/" target="_blank" class="a-link-dark">                
                <b-img src="@/assets/QuantifiedESG_Logo_v3_Basic.svg" fluid class="header-logo"></b-img>
                <div class="pt-3 f-90 gray-500">{{ $t(`(주)퀀티파이드이에스지`) }}</div>
            </a>
        </div>
       
    </b-container>
    <!-- 도움이 필요하신가요 모달 -->
    <b-button id="need-help" class="rounded-circle" variant="dark" size="lg" @click="showNeedHelp"><fa icon="question"></fa></b-button>
    <b-tooltip target="need-help" triggers="hover">{{ $t(`도움이 필요하신가요?`) }}</b-tooltip>
    <b-modal ref="need-help" size="lg" :title="$t(`도움이 필요하신가요?`)"  hide-footer><customer-service @close-need-help="hideNeedHelp"></customer-service></b-modal>


     <!-- IE 제한 안내문구 -->
    <div v-if="show_alert==true && isIE==true" class="top-alert w-100">
        <b-alert show dismissible class="ie-alert text-center">
        {{ $t(`티어제로 접속을 환영합니다. 본 시스템은 Google Chrome 또는 Microsoft Edge에 최적화되어 있음을 알려드립니다. (ex. 인터넷익스플로러는 출력 사용불가)`) }}
        </b-alert>
    </div>

    <b-modal id="gsc-esg" hide-footer size="xl" hide-header no-close-on-esc no-close-on-backdrop >
        <h5>{{$i18n.locale=='ko'?'교육영상 시청(총 6분 소요)을 완료하신 후 진단에 참여하실 수 있습니다.':'After you have finished watching this video (takes a total of 6 minutes), you can participate in the self assessment.'}}</h5>
        <gsc-video></gsc-video>
    </b-modal>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import * as ih from '@/util'
import CustomerService from '@/components/provider_landing/CustomerService'
import GscVideo from '@/components/provider_landing/scm/gsc/GscVideo'


export default {
    data() {
        return {
            is_campaigns_loaded: false            
        }
    },
    computed:{
        ...mapState(['campaigns','campaign_in_progress','user_id','user_name', 'company_id', 'is_admin']),
        ...mapState('campaign_users', ['users_in_company']),
        campaign_on_list(){
            let temp_campaign_list = [...this.campaigns]
            let temp_list = temp_campaign_list.filter(x => x.campaign_state!='종료')
            return temp_list.sort((a,b) =>{
                return b.start_date - a.start_date
            })            
        },
        campaign_off_list(){
            let temp_campaign_list = [...this.campaigns]
            let temp_list = temp_campaign_list.filter(x => x.campaign_state=='종료')
            return temp_list.sort((a,b) =>{
                return b.start_date - a.start_date
            })            
        },
        show_alert(){
            return this.$store.state.login_alert
        },
        isIE() {
            if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (navigator.userAgent.toLowerCase().indexOf("msie") != -1) )
                { return true }
            else
                { return false }
        }
    },
    methods:{
        ...mapActions(['read_recieved_campaigns']),
        ...mapActions('campaign_users', ['readUsersByCompany']),
        byLang(ko, en, cn){
            if(this.$i18n.locale=='en') return en
            else if (this.$i18n.locale=='cn') return cn
            else return ko
        },
        date_to_string(date){
            return ih.dateToString(date)
        },
        state_text(campaign_state){
            if (campaign_state=='생성/편집'){
                return this.$t(`준비중`)
            }
            if (campaign_state=='답변중'){
                return this.$t(`답변중`)
            }
            if (campaign_state=='평가중'){
                return this.$t(`평가중`)
            }
            if (campaign_state=='결과배포'){
                return this.$t(`결과배포`)
            }
            else return this.$t(`종료`)
        },
        badges(campaign_state){
            if (campaign_state=='생성/편집'){
                return 'emerging'
            }
            if (campaign_state=='답변중'){
                return 'blue-basic'
            }
            if (campaign_state=='평가중'){
                return 'basic'
            }
            if (campaign_state=='결과배포'){
                return 'red'
            }
            else return 'advance'
        },
        async pageGo(campaign){
            if(this.checkExcluded(campaign)!=true&&this.checkEditing(campaign.campaign_state)!=true){
                this.$store.commit('update_login_alert', false)
                await this.selectCampaign(campaign) // 1.store에 campaign_in_progress 커밋
                // console.log(this.campaign_in_progress); 
                if (this.campaign_in_progress.template_code != '' || this.campaign_in_progress.campaign_id != ''){
                    this.$store.dispatch('readTemplateInProgress', this.campaign_in_progress.template_code)
                }
                this.saveVisitLog() //참여하러 캠페인 클릭했는지 log 저장
                .then( response => {
                    // console.log(response);
                    if (response.visit_num==1&&this.$i18n.locale=='ko'&&(this.campaign_in_progress.campaign_id==124||this.campaign_in_progress.campaign_id==177)){
                        this.$bvModal.show('gsc-esg')  
                        this.goMain()
                    }
                    else this.$router.push({path: '/provider_preface'}) 
                })
            }
            else if (this.checkExcluded(campaign)==true){
                alert(`${this.user_name}` + this.$t(`님은 해당 캠페인의 참여대상으로 설정되어있지 않습니다.`))
            }
        },
        goMain(){
            setTimeout(()=>{
                this.$bvModal.hide('gsc-esg')  
                this.$router.push({path: '/provider_preface'}) 
            }, 365000)
        },
        selectCampaign(campaign){
            this.$store.commit('update_campaign_in_progress',campaign)     
        },
        saveVisitLog(){
            const path = this.$store.state.backend_host + '/save_visit_log'

            const formData = new FormData()
            formData.append('campaign_id',this.campaign_in_progress.campaign_id)
            formData.append('user_id', this.user_id)

            return axios.post(path, formData)
            .then(response=>{
                // console.log(response.data);
                return response.data
            })
            .catch( error => {
                console.log(error)
            })
        },
        informAuditors(mode){ // 특정 기업이 캠페인 클릭했을 때 Auditor에게 알리는 기능 ...saveVisitLog와 셋트임. 일단 사용하지 않음
            const path = this.$store.state.backend_host + '/inform_auditors'

            const formData = new FormData()
            formData.append('campaign_id', this.campaign_id)
            formData.append('company_id', this.company_id)
            formData.append('mode', mode) //'start_campaign', 'end_campaign'

            return axios.post(path, formData)
            .catch (error => {
                console.log(error)
            })
        },
        checkExcluded(campaign, index){
            if (this.is_admin!=4){
                // console.log(this.users_in_company);
                let my_idx = this.users_in_company.findIndex(user => user.user_id == this.user_id)
                let excluded_idx =  this.users_in_company[my_idx].excluded_campaigns.findIndex( z => z == campaign.campaign_name)
                // console.log(excluded_idx);
                if (excluded_idx > -1 ){                    
                    return true
                }
                else return false
            }
            else return false
        },
        checkEditing(state){
            if(state=='생성/편집'&&this.is_admin<2){ //캠페인이 '생성/편집'상태일 경우, admin 2 이하는 캠페인 접근 불가
                alert (this.$t(`'준비중' 상태인 캠페인은 접속하실 수 없습니다.`) + this.$t(`캠페인 셋팅이 완료되면 이메일로 안내될 예정이니 잠시만 기다려주세요.`))
                return true
            }else false
        },
        showNeedHelp(){
            this.$refs['need-help'].show()
        },
        hideNeedHelp(){
            this.$refs['need-help'].hide()
        },       

    },
    created(){
        this.is_campaigns_loaded = false
        this.readUsersByCompany(this.$store.state.company_id)
        this.read_recieved_campaigns(this.user_id)
        .then(
            (error) => {
                this.is_campaigns_loaded = true

                if(error && error.response && error.response.status && error.response.status == '401') {
                    alert(this.$t(`다중 로그인 등 권한 오류가 발생했습니다.`), this.$t(`다시 로그인해 주십시오.`))
                this.$store.dispatch('logout').then(() => { this.$router.push({name:'Login'}) })
                }               
                this.$store.commit('update_login_alert', true) //로그인 직후 얼렅 띄우는 플래그
            }
        )
    },
    components:{
        CustomerService,
        GscVideo
    },
    

}
</script>

<style scoped>
.intro-camp-card{
    min-width: 30%;
    border: 1.5px solid rgba(0,0,0,0);
}
.intro-camp-card,
.intro-camp-card >h5 {
    transition: all 0.2s ease;
}
.intro-camp-card:hover{
    border: 1.5px solid var(--gray-400);
}
.intro-camp-card:hover >h5 {
    color: black;
}

.top-alert {
    position: absolute;
    top:0;
}
.bottom-alert{
    position: fixed;
    bottom:0;
}
.ie-alert{
    position: sticky;
    /* background-color: rgba(0,0,0,0.5); */
    border:0;
    border-radius: 0;
    /* color: var(--gray-200); */
}
</style>
<style>
.camp-overlay{
    margin: 0px;
}
.camp-overlay > .b-overlay > .bg-light{
    background-color: var(--gray-300)!important;
}
.camp-overlay > .b-overlay > div.position-absolute{
    min-width: 80%;
}
</style>