<template>
    <b-container id="question-for-print" fluid class="py-4 px-0 position-relative">

        <!-- Question & Question info 영역 -->
        <div class="px-7 pb-2 border-bottom">
            <h4 class="fw-600">{{ byLang(question_name, question_name_e, question_name_c)}}</h4>
        </div>
        <div id="question_infobox" :class="[ question_infobox != '' ? 'px-7 blue7 bg-blue2 f-95':'', 'py-2 mb-5 border-0 ']"  v-html="question_infobox"></div>

        <!-- Sub-component 영역 -->
        <div v-if="subs_loaded && subs.length > 0" class="px-7 pb-5 mb-5" :key="pipeline_key">
            <sub-component v-for="(item, index) in subs" :key="item.index" 
            :sub_type="item.sub_type"
            :item.sync="item"
            :is_disabled_by_parent="false"
            :print_mode="true"
            @update-pipeline="handleUpdatePipeline"
            :is_final_sub_component="index==subs.length-1? true: false"
            @load-finished="handleLoadFinished"            
            ></sub-component>
        </div>
        
        <!-- Question not editable -->
        <div v-if="is_writable==false" class="w-100 question-blocked">
            <b-alert show dismissible class="question-block-alert w-100 d-flex justify-content-center align-items-center">
               <div class="text-center fw-500"> <fa icon="info-circle" class='mr-1'></fa> 이 문항은 보기만 가능합니다. <br> <span class="f-90 fw-400">작성 및 편집을 원하실 경우 관리자에게 문의하세요.</span></div>
            </b-alert>
        </div>

</b-container>
</template>

<script>
import SubComponent from '@/components/main_layout/sub_component/SubComponent'
import * as ih from '@/util.js'
import * as th from 'tree-helper'
//import axios from 'axios'
import BackToTop from '@/components/utility/BackToTop'

export default {
    data () {
        return {
            question_name: '',
            question_name_e: '',
            question_name_c: '',
            question_infobox: '',
            question_infobox_e: '',
            question_infobox_c: '',
            template_name: '',
            is_writable: true,
            is_visible: true,
            is_completed: false,
            comment: '',
            pipeline_key: 100,
            subs: [],
            subs_loaded: false,
            question_contents: [],

            load_finished: false
        }
    },
    props:{
        question_code: { type: String, default: '' },
        is_final_question_code: { type: Boolean, default: false }
    },
    computed: {
        view_mode() {
            return this.$store.state.view_mode
        },
        target_campaign(){
            if(this.view_mode=='provider'){
                return this.$store.state.campaign_in_progress
            }
            else{
                return this.$store.state.campaign
            }
        },
        target_template_contents() {
            if(this.view_mode=='provider') { return this.$store.state.template_in_progress.template_contents }
            else {
                return this.$store.state.template_tree.template_contents
            }
        },
        user_id() {
            if(this.view_mode=='provider'){
                return this.$store.state.user_id
            }
            else{
                return this.$store.state.template_tree.user_id
            }
        },
        is_disabled_by_parent(){
            if(this.target_campaign.campaign_state=='완료'){
                return true
            }else return false
        }
    },
    methods: {
        byLang(ko, en, cn){
            if(this.$i18n.locale=='en') return en
            else if (this.$i18n.locale=='cn') return cn
            else return ko
        },
        initializeQuestion(question_code){
            //if(this.view_mode=='provider'){
                // console.log(this.target_template_contents);
                th.breadthFirstSearch(this.target_template_contents, node => {
                    if(node.question_code == question_code){
                        // console.log(node.question_name);
                        this.comment = node.comment
                        this.question_name = node.question_name
                        this.question_name_e = node.question_name_e
                        this.question_name_c = node.question_name_c
                        this.question_infobox = node.question_infobox
                        this.question_infobox_e = node.question_infobox_e
                        this.question_infobox_c = node.question_infobox_c
                        this.is_writable = node.is_writable
                        this.is_visible = node.is_visible

                        if(typeof(node.question_contents)=='string') {
                            this.question_contents = JSON.parse(node.question_contents)
                        }
                        else{
                            this.question_contents = node.question_contents
                        }
                        this.is_completed = node.is_completed
                    }
                })

            if(this.question_contents.length==0){
                this.handleLoadFinished()
            }
            this.readSubsInit(this.question_contents)
        },
        readSubsInit(question_contents){
            this.subs=[]
            this.subs_loaded=false

            var promises = []
            const path = this.$store.state.backend_host + '/read_sub_init'

            question_contents.forEach(function (sub_code) {
                promises.push(
                    axios.get(path, { params: {sub_code: sub_code}})
                    .catch( error => { console.log(error)})
                )}                
            )

            let temp_result = []
            return Promise.all(promises)
            .then( results => {
                    this.$emit('load-started', this.question_code)
                    results.forEach(
                        response => {       
                            // console.log(response);                     
                            if (response != undefined){
                                let result = response.data
                                let new_sub = {}
                                new_sub.sub_code = result.sub_code
                                new_sub.sub_name = result.sub_name
                                new_sub.sub_name_e = result.sub_name_e
                                new_sub.sub_name_c = result.sub_name_c
                                new_sub.sub_description = result.sub_description
                                new_sub.sub_description_e = result.sub_description_e
                                new_sub.sub_description_c = result.sub_description_c
                                new_sub.sub_condition = result.sub_condition
                                new_sub.sub_info = result.sub_info
                                new_sub.sub_info_e = result.sub_info_e
                                new_sub.sub_info_c = result.sub_info_c
                                new_sub.sub_type = result.sub_type
                                new_sub.sub_data_header = result.sub_data_header
                                new_sub.sub_data_contents = result.sub_data_contents
                                new_sub.sub_priority = result.sub_priority
                                new_sub.sub_parent = result.sub_parent
                                new_sub.sub_child = result.sub_child
                                new_sub.tags = result.tags
                                new_sub.has_pipeline = result.has_pipeline
                            
                                new_sub.question_code=result.question_code
                                temp_result.push(new_sub)
                            }
                        })    
            })
            .finally( () => {
                this.subs=temp_result
                this.subs_loaded=true
            })
        },
        handleLoadFinished(){
            this.$emit('load-finished', this.question_code)
        },
        handleUpdatePipeline(){
            this.pipeline_key += 1
        }
    },
    created(){
        this.initializeQuestion(this.question_code)
    }

}
</script>
<style>

.question-blocked{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    flex-grow: 1;
    height: 100%;
    background: rgb(255,255,255, 0.2);
}

.question-block-alert {
    top: 0px;
    height: 60px;
    background-color: rgba(0,0,0,0.5);
    color:white;
    border:0;
    border-radius: 0;
    z-index: 60;
}

#question_infobox > a {
    color: var(--blue7);
}

</style>
