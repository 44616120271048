<template>
<div id="supplier_report_container">
    <div v-if="released==true" :class="is_printing==true ? 'px-5 py-5':'px-3 px-md-0'">
        <div v-if="is_chart_loaded==true&&is_evaluate_loaded==true&&is_score_loaded==true">
            <b-alert v-if="isIE==true" show>* 인터넷 익스플로러(IE) 환경에서 다운로드가 원활하지 않을 수 있습니다. 출력 및 다운로드시 Chrome 또는 Edge 브라우저를 이용하여 주시기 바랍니다.</b-alert> 

            <full-report v-if="is_evaluate_loaded==true&&is_score_loaded==true"></full-report>
            
            <!-- <div class="float-right" v-if="isIE!==true">
                <general-print-button @modal-opened="changeToImg" @modal-closed="imgToChart" v-if="is_chart_loaded==true" print_target="supplier_report_container" class="" :btn_variant="'dark-border'"  :d_none="is_printing==true? 'd-none':''">결과 보고서 <fa icon="print" class="ml-2"></fa></general-print-button>
            </div>
            <div class="mt-3 mb-3 f-95 d-flex justify-content-between">
                <div>{{ campaign_in_progress.campaign_name }}</div>
                <div :class="is_printing==true ?'':'d-none'">{{ camgaign_start_date_string +' ~ '+ camgaign_end_date_string }}</div>
            </div>

            <h2 class="mb-0 mb-5 tail-line-dark"> {{ company_name }} {{$t(`평가 결과`)}}</h2>
            

            <hr class="border-0 pb-4">
            <h4 class="mb-45 tail-line"> {{$t(`결과 요약`)}}</h4>
            <b-row no-gutters class="mb-4">
                <b-col class="col-4 pr-3">
                    <div class="rounded bgray-700 gray-50 py-2 px-3 h-100 d-flex flex-column align-items-between">
                        <div class="f-110"> {{$t(`총점`)}} <span class="fw-300">Total Score</span></div>
                        <h1 class="my-auto pl-2 text-center"> {{ company_total_score }}<span class="fw-500 f-100 ml-2"> / 100</span></h1>
                    </div>
                </b-col>
                <b-col class="col-8 pl-3">
                    <div v-show="is_printing==false"><report-chart ref="category_result_chart" v-if="is_chart_loaded==true" :chartData="category_score_chart_data" :options="category_score_chart_option" class="category-result-chart"></report-chart></div>
                    <div class="overflow-hidden" v-show="is_printing==true"><img style="width:660px; height: 220px;" :src="category_result_chart_url" /></div>                    
                </b-col>
            </b-row>

            <hr class="border-0 mb-5 pb-4">
            <h4 class="mb-5 tail-line"> {{$t(`점수 비교표`)}}</h4>
            <div class="text-right" v-if="is_previous_score_loaded==true"><b-button size="sm" v-b-toggle.previous-table>{{$t(`전년도 점수와 비교`)}}</b-button></div>
            <b-table-simple hover responsive class="f-95 rep-table mb-5 pt-2">
                <b-thead class="fw-500">
                    <b-tr>
                        <b-td></b-td><b-td>{{ this.camgaign_start_date_string.slice(0,4) }} {{ company_name }}</b-td>
                        <b-td class="">{{$t(`전체 평균`)}}</b-td>
                        <b-td class="">{{$t(`전체 최고점수`)}}</b-td>
                        <b-td class="">{{$t(`전체 최저점수`)}}</b-td>
                    </b-tr>
                </b-thead>
                <b-tbody v-if="is_chart_loaded==true">
                    <b-tr class="">
                        <b-td class="border-bottom-dark text-left">{{$t(`총점`)}}</b-td>
                        <b-td class="border-bottom-dark"> {{ company_total_score }} </b-td>
                        <b-td class="border-bottom-dark"> {{ all_average }} </b-td>
                        <b-td class="border-bottom-dark"> {{ all_max }} </b-td>
                        <b-td class="border-bottom-dark"> {{ all_min }} </b-td>

                    </b-tr>
                    <b-tr v-for="item in category_names" :key="item.id" class="">
                        <b-td class="text-left"> {{item}} </b-td>
                        <b-td> {{ company_category_score[item] ? Math.round(company_category_score[item]*10)/10 : 0 }} </b-td>
                        <b-td> {{ all_category_average[item] }} </b-td>
                        <b-td> {{ all_category_max[item] }} </b-td>
                        <b-td> {{ all_category_min[item] }}  </b-td>
                    </b-tr>                
                </b-tbody>
            </b-table-simple>

            <div v-if="is_conclusion_by_company_loaded==true || is_recommendations_by_company_loaded==true" class="mb-3">
                <hr class="border-0 pb-4">
                <h4 class="mb-45 tail-line"> {{ is_recommendations_by_company_loaded==true?'전문가 의견 및 개선과제':'전문가 의견' }}</h4>
                    <b-row class="mb-4">
                        <b-col class="col-4">
                            <h5 class="mb-3 pl-3 "> {{$t(`전문가 종합 의견`)}}</h5>
                        </b-col>
                        <b-col class="col-8"> 
                            <p>{{conclusion_by_company.conclusion}}
                            </p>                       
                        </b-col>
                    </b-row>
                    <div :class="[recommendations_by_company.length<=2?'ml-33':'','d-flex flex-wrap']">
                        <b-card  v-for="(item, index) in recommendations_by_company" :key="index"
                        class="mx-2 mb-3 bg-transparent no-word-break" 
                        :style="recommendations_by_company.length<=2?'width:47%':'width:31%'"> 
                            <b-badge variant="advance" class="mb-2">{{$t(`개선과제`)}} {{ index+1 }}.</b-badge><h6> {{item.title}}</h6>
                            <p> {{ item.recommendation }}
                            </p>
                        </b-card>
                    </div>
            </div>

            <div v-if="is_evaluate_loaded==true||is_comment_loaded==true">
                <hr class="border-0 pb-4">
                <h4 class="mb-3 tail-line"> {{$t(`상세결과`)}} </h4>
                <div class="mb-5 py-3"><result-report v-if="is_score_loaded==true"></result-report></div>
            </div>

            <hr class="border-0 mb-5 pb-5">
            <div class="f-90 gray-500 d-flex justify-content-between mb-3">
                <div class="tail-line" style="width: calc(100% - 100px)"> {{ campaign_in_progress.campaign_name }} </div> <div> tierzero.kr </div>
            </div> -->
        </div>
        <div v-else class="my-5 py-5">
            <b-card class="border-0 blue text-center"><b-spinner class="mx-2 p-1 my-1" small type="grow"></b-spinner> 결과보고서를 가져오고 있습니다.</b-card>
        </div>
    </div>
    <div v-else class="my-5 py-5 text-center f-110">
        <b-card class="bgray-200 py-5 my-5 border-0"> {{$t(`평가 완료 후 확인하실 수 있습니다. 관리자에게 문의하세요.`)}} </b-card>
    </div>

</div>
</template>

<script>
import ReportChart from '@/components/charts/ReportChart'
import BarChart from '@/components/charts/BarChart'
import * as th from 'tree-helper'
import * as ih from '@/util'
import ResultReport from './ResultReport'
import GeneralPrintButton from '@/components/print_to_pdf/GeneralPrintButton'
import { mapState, mapActions, mapMutations } from 'vuex'
import FullReport from '@/components/supplier_report/FullReport'

export default {
    data() {
        return {
            is_printing: false, 
            total_score_chart_url: '',
            category_result_chart_url: '',

            is_received: false,
            total_score_chart_key: 100,
            is_result_confirmed: false,
            auditor_name: '퀀티파이드이에스지',

            category_score_chart_data: {
                labels: [] ,
                datasets: [
                    {
                        label: '',
                        data: [80,70],
                        barPercentage: 0.8,
                        categoryPercentage: 0.6,
                        backgroundColor: ['rgba(31, 64, 255, 0.7)','rgba(0,0,0, 0.1)','rgba(0,0,0, 0.1)','rgba(0,0,0, 0.1)','rgba(0,0,0, 0.1)','rgba(0,0,0, 0.1)','rgba(0,0,0, 0.1)','rgba(0,0,0, 0.1)'],
                        borderColor: ['rgba(31, 64, 255, 1)', 'rgba(0,0,0, 0.2)', 'rgba(0,0,0, 0.2)', 'rgba(0,0,0, 0.2)', 'rgba(0,0,0, 0.2)', 'rgba(0,0,0, 0.2)', 'rgba(0,0,0, 0.2)', 'rgba(0,0,0, 0.2)'],
                        borderWidth: 1,
                        order: 1
                    },
                ]
            },
            category_score_chart_option: {
                maintainAspectRatio: false,
                responsive: true,
                legend: {
                    display:false
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            color:"rgba(0, 0, 0, 0)"
                        },
                        ticks: {
                            beginAtZero:true,
                            max: 100,
                            stepSize: 20
                        }                                             
                    }],
                    yAxes: [{
                        gridLines: {
                            color:"rgba(0, 0, 0, 0)"
                        },
                        ticks: {
                            // beginAtZero:true,
                            // max: 100,
                            // stepSize: 20 
                            // autoSkip: true,
                            // maxTicksLimit: 20
                        }   
                    }]
                }
            },

            is_chart_loaded: false,
            // released: false,

            is_chart_loaded: false,
        }
    },
    props:{
        released: {type:Boolean }
    },
    computed:{
        ...mapState('score', ['is_score_loaded','is_evaluate_loaded','is_previous_score_loaded', 
        'is_comment_loaded', 'category_names', 'temp_target_companies','previous_temp_target_companies']
        ),
        ...mapState('reviews', ['conclusion_by_company', 'is_conclusion_by_company_loaded', 'recommendations_by_company', 'is_recommendations_by_company_loaded']),
        campaigns(){
            return this.$store.state.campaigns
        },
        campaign_in_progress() {
            return this.$store.state.campaign_in_progress
        },
        template_contents(){
            return this.$store.state.template_tree.template_contents
        },
        previous_template_tree(){
            return this.$store.state.previous_template_tree
        },
        company_name(){
            return this.$store.state.company_name
        },
        company_id() {
            return this.$store.state.company_id
        },
        camgaign_start_date_string(){
            return ih.dateToString(this.$store.state.campaign_in_progress.start_date)
        },
        camgaign_end_date_string(){
            return ih.dateToString(this.$store.state.campaign_in_progress.end_date)
        },
        company_rank(){
            let target_companies = ih.deepCopy(this.temp_target_companies)
            // total_score == null에 대한 전처리 필요
            target_companies.sort((a,b)=> b.total_score - a.total_score
            )
            let index = target_companies.findIndex(item => {return item.company_name == this.company_name})
            
            return index+1
        },
        // company의 영역별 점수
        company_category_score(){
            let temp = {}      
            let idx = this.$store.state.score.temp_target_companies.findIndex(item => {return item.company_id == this.company_id })
            if(idx > -1){
                temp = this.$store.state.score.temp_target_companies[idx].category_score
            }
            return temp
        },
        // company의 총점
        company_total_score(){
            let temp = {}      
            let idx = this.$store.state.score.temp_target_companies.findIndex(item => {return item.company_id == this.company_id })
            if(idx > -1){
                temp = this.$store.state.score.temp_target_companies[idx].total_score
            }            
            return Math.round(temp * 10)/10
        },
        // company의 영역별 점수
        previous_category_score(){
            let temp = {}      
            let idx = this.$store.state.score.previous_temp_target_companies.findIndex(item => {return item.company_id == this.company_id })
            if(idx > -1){
                temp = this.$store.state.score.previous_temp_target_companies[idx].category_score
            }
            return temp
        },
        // company의 총점
        previous_total_score(){
            let temp = {}      
            let idx = this.$store.state.score.previous_temp_target_companies.findIndex(item => {return item.company_id == this.company_id })
            if(idx > -1){
                temp = this.$store.state.score.previous_temp_target_companies[idx].total_score
            }            
            return Math.round(temp * 10)/10
        },
        // 전체 기업 영역별 평균 - getters
        all_category_average(){
            return this.$store.getters['score/getCategoryAverage']
        },
        all_category_max(){
            return this.$store.getters['score/getCategoryMax']
        },
        all_category_min(){
            return this.$store.getters['score/getCategoryMin']
        },
        // 전체 기업 평균 - getters
        all_average(){
            return this.$store.getters['score/getAllAverage']
        },
         // 전체 기업 최고점 - getters
        all_max(){
            return this.$store.getters['score/getAllMax']
        },
        // 전체 기업 최하점 - getters
        all_min(){
            return this.$store.getters['score/getAllMin']
        },
        progress_rate: {
            get() {
                return this.$store.getters.getProgressRate
            }
        },
        isIE() {
            if ( (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (navigator.userAgent.toLowerCase().indexOf("msie") != -1) )
                { return true }
            else
                { return false }
        },
        score_contents(){
            let temp = {}      
            let company_id = '' 
            if(this.local_company!=undefined){ company_id = this.local_company.company_id } 
            else (company_id = this.$store.state.company_id)
            // console.log(company_id);

            let idx = this.$store.state.score.temp_target_companies.findIndex(item => {return item.company_id == company_id })
            if(idx > -1){
                temp = this.$store.state.score.temp_target_companies[idx]
            }
            // console.log(temp.score_contents);
            return temp.score_contents
        },
    },
    methods:{
        ...mapMutations('reviews', ['update_is_conclusion_by_company_loaded', 'update_is_recommendations_by_company_loaded']),
        ...mapActions('reviews', ['readConclusionbyCompany', 'readRecommendationsbyCompany']),
        ...mapMutations('cr_scores', ['update_report_company']),
        updateChartData(){
            if(this.is_score_loaded!=false){
            
                //category 차트데이터 업데이트
                this.category_score_chart_data.labels = []
                this.category_score_chart_data.labels.push(this.$t('총점')) 
                this.category_names.forEach(item=>{
                    this.category_score_chart_data.labels.push(item) 
                })
                            
                this.category_score_chart_data.datasets[0].data = []
                this.category_score_chart_data.datasets[0].data.push(this.company_total_score)
                this.category_names.forEach(item => {
                    let company = this.temp_target_companies.find(item => { return item.company_id == this.company_id})
                    this.category_score_chart_data.datasets[0].data.push(Math.round(company.category_score[item]*10)/10)
                    // console.log( this.category_score_chart_data.datasets[0].data);                    
                })        
                this.is_chart_loaded = true
                // this.updatePreviousScore()
            }
            else this.is_chart_loaded =false
        },
        updatePreviousScore(){      
            if (this.campaign_in_progress.previous_campaign_id != null && this.campaigns.length!=0) {                
                let idx = this.campaigns.findIndex(item => item.campaign_id == this.campaign_in_progress.previous_campaign_id)                
                this.campaigns[idx].target_companies_list.forEach(item =>{ 
                    this.$store.commit('score/add_previous_temp_target_companies', { company_name: item.company_name, company_id: item.company_id, required_info: item.required_info })
                })                
                this.$store.dispatch('readTemplateTree', {template_code: this.campaigns[idx].template_code, is_previous: true} )
                .then( ()=>{ 
                    this.$store.commit('score/update_previous_local_template_tree_contents', this.previous_template_tree.template_contents)  
                    this.$store.dispatch('score/readAllPreviousCampaignScores', { campaign_id: this.campaign_in_progress.previous_campaign_id })
                })
            }  else console.log('previous campaign 없음') 
        },
        average(target_scores) {
            //점수 재료 배열을 받아서 평균점 반환
            let sum = 0
            let num_of_q = 0
            for (var i=0; i<target_scores.length; i++) {
                if(target_scores[i] && Number.isNaN(target_scores[i])==false){
                    sum += target_scores[i]
                    num_of_q += 1
                }
            }
            return Math.round(sum/num_of_q * 10)/10
        },
        changeToImg(){
            this.is_printing = true
            // this.total_score_chart_url = this.$refs['total_score_chart'].$data._chart.toBase64Image()
            this.category_result_chart_url = this.$refs['category_result_chart'].$data._chart.toBase64Image()
        },
        imgToChart(){
            this.is_printing = false
        },
        checkRelease(){
            const path = this.$store.state.backend_host + '/check_release'

            return axios.get(path, { params: {
                campaign_id: this.campaign_in_progress.campaign_id,
                company_id: this.company_id
            }})
            .then( response=>{
                // response.data = { 'company_id': company_id, 'release': True or False, 'time_stamp': str(q.time_stamp) }
                this.released = response.data.release   
            })
        },
        async handleReadConclusion(){
            let response = await this.readConclusionbyCompany({campaign_id:this.campaign_in_progress.campaign_id, company_id:this.company_id})
            // console.log(response);
        },
        async handleReadRecommendation(){
            let response = await this.readRecommendationsbyCompany({campaign_id:this.campaign_in_progress.campaign_id, company_id:this.company_id})
            // console.log(response);
        }

    },
    components:{
        ReportChart,
        BarChart,
        ResultReport,
        GeneralPrintButton,
        FullReport,
    },
    beforeCreate(){

    },
    created(){
        this.$store.commit('score/update_temp_target_companies', [])
        this.$store.commit('score/update_previous_temp_target_companies', [])
        this.$store.commit('score/update_is_score_loaded', false)
        this.$store.commit('score/update_is_evaluate_loaded', false)
        this.update_is_conclusion_by_company_loaded(false),
        this.update_is_recommendations_by_company_loaded(false)

        if (this.campaign_in_progress.campaign_id != '' && this.campaign_in_progress !=undefined) {
            // console.log(this.campaign_in_progress.target_companies_list);

            // this.checkRelease()
            this.campaign_in_progress.target_companies_list.forEach(item=>{
                this.$store.commit('score/add_temp_target_companies', { company_name: item.company_name, company_id: item.company_id, required_info: item.required_info })
            })        
            this.$store.dispatch('readTemplateTree', {template_code: this.campaign_in_progress.template_code, is_previous: false} )
            .then( ()=>{                
                this.$store.commit('score/update_category_names', Array.from(this.template_contents.map(x => {
                    if (x.question_name!='기본정보'&&x.question_name!='기본 정보'){
                        return x.question_name
                    }}).filter(x=>x!=undefined)                    
                    ))
                this.$store.commit('score/update_local_template_tree_contents_original', this.template_contents)                
                this.$store.dispatch('score/readAllScoresByCompany', { campaign_id: this.campaign_in_progress.campaign_id, company_id: this.company_id })
                this.$store.dispatch('score/readAllScoresByCampaign', this.campaign_in_progress.campaign_id )
                this.$store.dispatch('score/readAllEvaluateResult', { campaign_id: this.campaign_in_progress.campaign_id, company_id: this.company_id})  
                .then(()=>{      
                    //this.updateChartData()       
                    this.handleReadConclusion()
                    this.handleReadRecommendation()
                    let new_company = {
                        category_score: this.company_category_score,
                        company_id: this.company_id,
                        company_name: this.company_name,
                        release: this.released,
                        required_info: {},
                        score_contents: this.score_contents,
                        total_score: this.company_total_score
                    }
                    // console.log(new_company);
                    this.update_report_company(new_company)
                })
            })  
            
        }
        else console.log('캠페인 인 프로그래스 못받음')    
    },
    updated(){
        // console.log(this.all_average);
        // console.log(this.all_max);
        // console.log(this.all_min);
    },
    watch:{
        is_score_loaded:{
            handler(new_val, old_val) {
                if (new_val==true){
                    this.updateChartData()
                }
            }, 
        }
    },

}
</script>

<style scoped>

.small-component > tr > td {
    padding: 0.3rem 0.25rem;
}

</style>