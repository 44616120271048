<template>
<div>
    <b-button v-b-modal.submit-all :variant="btn_variant" :size="btn_size" :disabled="check_disable==true">
        <slot></slot>
    </b-button>
    <b-modal  id="submit-all" :title="$t(modal_title)" hide-footer>
        <p class="fw-500"> {{$t(`전체 답변을 제출하실 경우, 모든 문항의 상태가`)}} <span class="blue">{{$t(`'제출완료'`)}}</span> {{$t(`상태로 전환됩니다.`)}} </p>
        <p> {{$t(`'제출완료' 상태에서는 답변 수정이 불가능하며,`)}} 
            {{$t(`온라인 답변 기간 종료 시점까지는 각 문항별 우측 하단의 '제출하기' 해제 후 답변을 수정할 수 있습니다.`)}} </p>
        <!-- <p> 제출완료 시 답변 수정이 불가능합니다.  {{$t(`계속 하시겠습니까?`)}} </p> -->
        <div class="text-right">
            <b-button variant="dark" class="mr-2" :disabled="is_completing==true" @click="$bvModal.hide('submit-all')">{{$t(`취소`)}}</b-button>
            <b-button variant="blue" @click.stop="makeAllComplete"><b-spinner v-if="is_completing==true" class="white"></b-spinner>{{is_completing==true?'': $t(`제출하기`) }} </b-button>
        </div>
    </b-modal>
</div>
</template>
<script>
import { mapState } from 'vuex'
import * as th from 'tree-helper'
//import axios from 'axios'

export default {
    data(){
        return { 
            is_completing : false,
            // question_in_progress: {
            // question_code: '',
            // question_name: '',
            // question_infobox: '',
            // question_contents: [],

            // is_completed: 'false',
            // visible: 'true',
            // writable: 'true',
            // comment: ''
            // },
            modal_title: "전체 답변 제출하기"
        }
    },
    props:{
        btn_size: {type:String, default:''},
        btn_variant: {type:String, default:''},
        disable_status: {type:Boolean, default: false}

    },
    computed:{
        ...mapState({
            user_id: state => state.user_id,
            campaign_id: state => state.campaign_in_progress.campaign_id,
            campaign_state: state => state.campaign_in_progress.campaign_state,
            company_id: state => state.company_id, 
            template_in_progress: state => state.template_in_progress,
            template_contents: state => state.template_in_progress.template_contents,
            question_in_progress: state => state.question_in_progress,
        }),
        progress_rate(){
            return this.$store.getters.getProgressRate
        },
        check_disable(){
            if(this.disable_status==true){
                return true
            }
            if (this.campaign_state!='답변중'&&this.campaign_state!='생성/편집'){
                return true
            }
            else if (this.progress_rate==100){
                return true
            }
            else return false
        }
    },
    methods:{
        makeAllComplete(){
            this.is_completing = true
            //1) 현재 template_contents 에서 question_in_progress 객체를 복사해옴
            //2) question_code, question_contents(string 상태이므로 주의), question_infobox, question_name, 
            //   is_completed, visible, writable, comment
            
            //3) 여기에서 children length가 0일 경우, is_completed = 'true'로 원본을 만들고, 
            let question_in_progresses = []
            th.breadthFirstSearch(this.template_contents, node => {
                if(node.children && node.children.length == 0 && node.is_completed != 'true'){
                    //node.is_completed = 'true'
                    let new_question_in_progress = {
                        question_code: node.question_code,
                        is_completed: 'true',
                        comment: node.comment
                    }
                    question_in_progresses.push(new_question_in_progress)
                }
            })

            // console.log(question_in_progresses)
      
            //4) DB에 저장한다
            //4-1) Question_in_progress 객체 저장
              //API: /save_question_answer (question_code, campaign_id, company_id, is_completed, comment)
            const save_question_answer_path = this.$store.state.backend_host + '/save_question_answer'
            let save_question_answer_promises = []

            question_in_progresses.forEach(item => {
                let formData = new FormData()
                formData.append('question_code', item.question_code)
                formData.append('campaign_id', this.campaign_id)
                formData.append('company_id', this.company_id)
                formData.append('is_completed', item.is_completed)
                formData.append('comment', item.comment)

                save_question_answer_promises.push(
                    axios.post(save_question_answer_path, formData)
                    .catch(error => console.log(error))
                )
            })

            return Promise.all(save_question_answer_promises)
            .then( results => {
                results.forEach( response => {
                    // console.log(response)
                    if(response != undefined){
                        th.breadthFirstSearch(this.template_contents, node => {
                            if(node.question_code==response.data.question_code){
                                console.log(node.question_code)
                                node.is_completed='true'

                                let new_template_in_progress = { ...this.template_in_progress }
                                this.$store.commit('update_template_in_progress', new_template_in_progress)

                                // console.log(this.progress_rate)
                            }
                        })
                    }
                })
            })
            .finally( ()=> {
                this.question_in_progress.is_completed = 'true'
                let new_question_in_progress = { ...this.question_in_progress }
                this.$store.commit('update_question_in_progress', new_question_in_progress)
                this.saveProgressLog(question_in_progresses)
                .then( ()=>{
                    //this.informAuditors('end_campaign')
                })
                this.is_completing = false
                this.$bvModal.hide('submit-all')
                // console.log('이벤트 발신! refresh-tree')
                EventBus.$emit('refresh-tree')
                EventBus.$emit('toast-success', this.$t('알림'), this.$t('전체 제출을 완료하였습니다.'))
                
            })            
        },
        saveProgressLog(question_in_progresses){
            //4-2) 로그 저장
            //API: /save_progress_log (user_id, campaign_id, company_id, question_code, is_completed, progress_rate = this.$store.getters.getProgressRate)
            
            const save_progress_log_path = this.$store.state.backend_host + '/save_progress_log'

            let save_progress_log_promises = []
            question_in_progresses.forEach(item => {
                let formData = new FormData()
                formData.append('user_id', this.user_id)
                formData.append('campaign_id', this.campaign_id)
                formData.append('company_id', this.company_id)
                formData.append('question_code', item.question_code)
                formData.append('is_completed', item.is_completed)
                formData.append('progress_rate', this.progress_rate)

                save_progress_log_promises.push(
                    axios.post(save_progress_log_path, formData)
                    .catch(error => console.log(error))
                )
            })

            return Promise.all(save_progress_log_promises)
            .finally(
                // console.log(this.progress_rate)
            )
        },
        informAuditors(mode){
            const path = this.$store.state.backend_host + '/inform_auditors'

            const formData = new FormData()
            formData.append('campaign_id', this.campaign_id)
            formData.append('company_id', this.company_id)
            formData.append('mode', mode) //'start_campaign', 'end_campaign'

            return axios.post(path, formData)
            .catch (error => {
                console.log(error)
            })
        },
        
    },
    created(){
        // console.log(this.template_contents)
    }
}
</script>