//Provider Service 중 Question에 나타나는 Sub들 전역 Vuex
//import axios from 'axios'
import * as ih from '@/util'
import * as th from 'tree-helper'
import hmc_total_score_obj from '../hmc_total_score_obj' 

const questions = {
    namespaced: true,
    state: {
        category_names: [],
        temp_target_companies: [],
        previous_temp_target_companies: [],
            // { company_name: '기업 A', company_id, total_score, category_score: { CategoryA: 100, Category B: 50, Category C: 35 }, required_info: {sector: '포장재', revenue: '100억원 미만'}, score_contents: [ {tree having score_result} ] },
            // { company_name: '기업 B', required_info: {sector: '포장재', revenue: '100억원 미만'}, score_contents: [ {tree having score_result} ] },
            // { company_name: '기업 C', required_info: {sector: '포장재', revenue: '100억원 미만'}, score_contents: [ {tree having score_result} ] },
            // { company_name: '기업 D', required_info: {sector: '포장재', revenue: '100억원 미만'}, score_contents: [ {tree having score_result} ] }
        local_template_tree_contents_original: [],
        previous_local_template_tree_contents:[],
        is_score_loaded: false,
        is_evaluate_loaded: false,
        is_comment_loaded: false,
        is_previous_score_loaded: false,
        
        evaluate_results: [],
            // ['campaign_id', 'template_code', 'company_id', 'company_name', 'question_code', 'question_name', 'sub_code', 'formula', 'evaluation_name', 'satisfied', 'score', 'law_info', 'author_id', 'author_name', 'audit_comment', 'time_stamp']
        evaluate_result_by_question_code: [],
        overall_comment: {}
    },
    mutations: {
        update_category_names(state, new_category_names){
            state.category_names = new_category_names
        },
        update_temp_target_companies(state, new_temp_target_companies){
            state.temp_target_companies = new_temp_target_companies
        },
        add_temp_target_companies(state, new_item){
            state.temp_target_companies.push(new_item)
        },
        update_local_template_tree_contents_original(state, new_tree) {
            state.local_template_tree_contents_original = new_tree
        },
        set_score_contents(state, {company_id, new_template_contents}){
            let company_item = state.temp_target_companies.find(item => {return item.company_id == company_id})
            Vue.set(company_item,'score_contents', new_template_contents)
        },
        set_total_score(state, {company_id, total_score}){

            let company_item = state.temp_target_companies.find(item => {return item.company_id == company_id})
            Vue.set(company_item,'total_score', total_score)
        },
        set_category_score(state, {company_id, category_score}){
            let company_item = state.temp_target_companies.find(item => {return item.company_id == company_id})
            Vue.set(company_item,'category_score', category_score)
        },
        // >> campaign_report가 발행되었는지 관련
        set_releases(state, new_array){
            let new_temp_target_companies = [ ...state.temp_target_companies ]
            //new_array: [ { 'company_id', 'release' : True, 'time_stamp: 언제 } ] 배열임. False인 ID는 반환자체를 안함
            new_temp_target_companies.forEach(company_item => {
                let idx = new_array.findIndex(item => {return item.company_id == company_item.company_id})
                if (idx > -1){
                    Vue.set(company_item, 'release', new_array[idx].release )
                }
                else {
                    Vue.set(company_item, 'release', false)
                }
            })
            state.temp_target_companies = new_temp_target_companies
            // console.log(state.temp_target_companies)           
        },
        set_release(state, { company_id, new_release }){
            let new_temp_target_companies = [ ...state.temp_target_companies ]
            let idx = new_temp_target_companies.findIndex( item => {return item.company_id == company_id })
            if(idx >-1){
                new_temp_target_companies[idx].release = new_release
                state.temp_target_companies = new_temp_target_companies
            }
        },
        // >> previous score 관련        
        update_previous_temp_target_companies(state, new_temp_target_companies){
            state.previous_temp_target_companies = new_temp_target_companies
        },
        add_previous_temp_target_companies(state, new_item){
            state.previous_temp_target_companies.push(new_item)
        },
        set_previous_score_contents(state, {company_id, new_template_contents}){
            let company_item = state.previous_temp_target_companies.find(item => {return item.company_id == company_id})
            Vue.set(company_item,'previous_score_contents', new_template_contents)
        },
        set_previous_total_score(state, {company_id, total_score}){
            let company_item = state.previous_temp_target_companies.find(item => {return item.company_id == company_id})
            Vue.set(company_item,'previous_total_score', total_score)
        },
        set_previous_category_score(state, {company_id, category_score}){
            let company_item = state.previous_temp_target_companies.find(item => {return item.company_id == company_id})
            Vue.set(company_item,'previous_category_score', category_score)
        },
        update_previous_local_template_tree_contents(state, new_tree) {
            state.previous_local_template_tree_contents = new_tree
        },
        // >> 데이터 로드 상태 확인
        update_is_score_loaded(state, flag){
            state.is_score_loaded=flag
        },
        update_is_evaluate_loaded(state, flag){
            state.is_evaluate_loaded=flag
        },
        update_is_comment_loaded(state, flag){
            state.is_comment_loaded=flag
        },
        update_is_previous_score_loaded(state, flag){
            state.is_previous_score_loaded=flag
        },
        update_evaluate_results(state, new_evaluate_result){
            state.evaluate_results = new_evaluate_result
        },
        update_result_by_q_code(state, factors){
            state.evaluate_result_by_question_code = factors
        },
        update_overall_comment(state, new_comment_item){
            state.overall_comment = new_comment_item
        }
    },
    getters: {
        getAllAverage(state){
            return average(Array.from(state.temp_target_companies.map(x=> x.total_score)))
        }, 
        getAllMax(state){
            return max(Array.from(state.temp_target_companies.map(x=> x.total_score)))
        },
        getAllMin(state){
            return min(Array.from(state.temp_target_companies.map(x=> x.total_score)))
        },
        getAllMedian(state){
            return median(Array.from(state.temp_target_companies.map(x=> x.total_score)))
        },
        getCategoryAverage(state){
            let response = {}
            for(let i=0; i<state.category_names.length; i++) {  
                let temp_array=[]                          
                for(let j=0; j<state.temp_target_companies.length; j++) {
                    if (state.temp_target_companies[j].category_score) {
                        temp_array.push(state.temp_target_companies[j].category_score[state.category_names[i]])
                    }else continue
                }
                response[state.category_names[i]] = average(temp_array)
            }
            return response
        }, 
        getCategoryMax(state){
            let response = {}
            for(let i=0; i<state.category_names.length; i++) {  
                let temp_array=[]                          
                for(let j=0; j<state.temp_target_companies.length; j++) {
                    if (state.temp_target_companies[j].category_score) {
                        temp_array.push(state.temp_target_companies[j].category_score[state.category_names[i]])
                    }else continue
                }
                response[state.category_names[i]] = max(temp_array)
            }
            return response
        },
        getCategoryMin(state){
            let response = {}
            for(let i=0; i<state.category_names.length; i++) {  
                let temp_array=[]                          
                for(let j=0; j<state.temp_target_companies.length; j++) {
                    if (state.temp_target_companies[j].category_score) {
                        temp_array.push(state.temp_target_companies[j].category_score[state.category_names[i]])
                    }else continue
                }
                response[state.category_names[i]] = min(temp_array)
            }
            return response
        },
        getCategoryMedian(state){
            let response = {}
            for(let i=0; i<state.category_names.length; i++) {  
                let temp_array=[]                          
                for(let j=0; j<state.temp_target_companies.length; j++) {
                    if (state.temp_target_companies[j].category_score) {
                        temp_array.push(state.temp_target_companies[j].category_score[state.category_names[i]])
                    }else continue
                }
                response[state.category_names[i]] = median(temp_array)
            }
            return response
        }
    },
    actions: {
        readAllScoresByCampaign({state, dispatch, commit, rootState}, campaign_id){
            commit('update_is_score_loaded', false)
            //Campaign_id를 보내면 백엔드로 부터 기업-Question Code-점수 객체를 반환 받음
            
            let path = ''
            if (rootState.template_tree.template_scoring == 'auto'){
                path = rootState.backend_host + '/read_all_scores_by_campaign_id'
            }
            else path = rootState.backend_host + '/evaluate_result'
            
            
            return axios.get(path, { params: {
                campaign_id: campaign_id,
                aggregate: 'question'
            }})
            .then(response=>{
                //[ { campaign_id, template_code, company_id, company_name, question_code, question_name, num_of_calculated_subs, scoring, score_result }]
                //Question별로 Score는 100점 만점 기준으로 환산되서 들어옴.
                let result = response.data
                // console.log(result);
                state.temp_target_companies.forEach(company_item => {
                    
                    let new_template_contents = ih.deepCopy(state.local_template_tree_contents_original)
                    //DB에서 읽어온 문항별 점수 값을 new_template_contents 각 노드에 넣어줌
                    th.breadthFirstSearch(new_template_contents, node => {
                        for(let i=0; i<result.length; i++){
                            if(node.question_code == result[i].question_code && company_item.company_name == result[i].company_name){
                                node.num_of_calculated_subs = result[i].num_of_calculated_subs
                                node.score_result = result[i].score_result
                            }
                        }
                    }) 
                    // console.log(new_template_contents);
                    
                    //평균 점수 산정
                    calculateAllScore(new_template_contents)
                    commit('set_score_contents', { company_id: company_item.company_id, new_template_contents} )
                    //console.log(new_template_contents)
                    
                    //총점 점수 산정
                    
                    //2021.03.22 KT&G 특별코드
                    //
                    if(campaign_id == 134 || campaign_id == 135){
                        commit('set_total_score', { company_id: company_item.company_id, total_score: calculateTotalScoreKTG(new_template_contents) })
                    }
                    else if(campaign_id == 124) {
                        commit('set_total_score', { company_id: company_item.company_id, total_score: calculateTotalScoreGSC1(new_template_contents) })
                    }
                    else if(campaign_id == 177) {
                        commit('set_total_score', { company_id: company_item.company_id, total_score: calculateTotalScoreGSC2(new_template_contents) })
                    }
                    else if(campaign_id == 288){ //HMCS: Hyundai Motor Company Self Assessment
                        commit('set_total_score', { company_id: company_item.company_id, total_score: calculateTotalScoreHMCS(company_item.company_id, hmc_total_score_obj) })
                    }
                    else{
                        commit('set_total_score', { company_id: company_item.company_id, total_score: calculateTotalScore(new_template_contents) })
                    }
                    let category_score = {}

                    //카테고리 점수 산정
                    new_template_contents.forEach(item => {
                        if(item.score_result!=null){category_score[item.question_name] = item.score_result}  
                    })
                    commit('set_category_score', {company_id: company_item.company_id, category_score })

                    //LG생건의 경우, 시작하지 않은 기업은 모수에서 제외하기
                    // console.log(state.temp_target_companies);
                    // let companies_progressed = []
                    // state.temp_target_companies.forEach(x => {
                    //     if(x.total_score >= 10 ){ companies_progressed.push(x) }
                    // })
                    // console.log( companies_progressed );
                    // commit('update_temp_target_companies', companies_progressed )

                })                                  
            })
            .catch(error => {
                console.log(error)
            })
            .finally( () => {
                // console.log(state.temp_target_companies);
                commit('update_is_score_loaded', true)
                dispatch('readReleases', campaign_id)
            })
        },
        readAllScoresByCompany({state, dispatch, commit, rootState}, { campaign_id, company_id } ){
            //commit('update_is_score_loaded', false)
            //Campaign_id를 보내면 백엔드로 부터 기업-Question Code-점수 객체를 반환 받음
            
            const path = rootState.backend_host + '/evaluate_result'
            
            return axios.get(path, { params: {
                campaign_id: campaign_id,
                company_id: company_id, 
                aggregate: 'question'
            }})
            .then(response=>{
                // console.log(response.data);
                //[ { campaign_id, template_code, company_id, company_name, question_code, question_name, num_of_calculated_subs, scoring, score_result }]
                //Question별로 Score는 100점 만점 기준으로 환산되서 들어옴.
                let result = response.data
                
                let new_template_contents = ih.deepCopy(state.local_template_tree_contents_original)
                //DB에서 읽어온 문항별 점수 값을 new_template_contents 각 노드에 넣어줌
                th.breadthFirstSearch(new_template_contents, node => {
                    for(let i=0; i<result.length; i++){
                        if(node.question_code == result[i].question_code && company_id == result[i].company_id){
                            node.num_of_calculated_subs = result[i].num_of_calculated_subs
                            node.score_result = result[i].score_result
                        }
                    }
                }) 
                
                //평균 점수 산정
                calculateAllScore(new_template_contents)
                commit('set_score_contents', { company_id: company_id, new_template_contents} )
                
                //총점 점수 산정
                //2021.03.22 KT&G 특별코드
                // if(campaign_id == 134 || campaign_id == 135){
                //     commit('set_total_score', { company_id: company_id, total_score: calculateTotalScoreKTG(new_template_contents) })
                // }
                // else if(campaign_id == 124) {
                //     commit('set_total_score', { company_id: company_id, total_score: calculateTotalScoreGSC1(new_template_contents) })
                // }
                // else if(campaign_id == 177) {
                //     commit('set_total_score', { company_id: company_id, total_score: calculateTotalScoreGSC2(new_template_contents) })
                // }
                // else{
                    commit('set_total_score', { company_id: company_id, total_score: calculateTotalScore(new_template_contents) })
                // }
                let category_score = {}

                //카테고리 점수 산정
                new_template_contents.forEach(item => {
                    if(item.score_result!=null){category_score[item.question_name] = item.score_result}  
                })
                commit('set_category_score', {company_id: company_id, category_score })

                //LG생건의 경우, 시작하지 않은 기업은 모수에서 제외하기
                // console.log(state.temp_target_companies);
                // let companies_progressed = []
                // state.temp_target_companies.forEach(x => {
                //     if(x.total_score >= 10 ){ companies_progressed.push(x) }
                // })
                // console.log( companies_progressed );
                // commit('update_temp_target_companies', companies_progressed )
                                 
            })
            .catch(error => {
                console.log(error)
            })
            .finally( () => {
                //commit('update_is_score_loaded', true)
                dispatch('readReleases', campaign_id)
            })
        },
        readAllEvaluateResult({commit, rootState}, {campaign_id, company_id} ){
            //commit('update_is_evaluate_loaded', false) 
            if (rootState.template_tree.template_scoring == 'auto'){
                return Promise.resolve('Maual Scoring이 아님')
            }
            const path = rootState.backend_host + '/evaluate_result'

            return axios.get(path, { params: {
                campaign_id: campaign_id, 
                company_id: company_id, 
            }})
            .then(response=>{
                // console.log(response.data)
                let result = response.data  
                if(Array.isArray(result) != true){ result = [] }           
                commit('update_evaluate_results', result)   
                commit('update_is_evaluate_loaded', true)         
            })
        },
        readOverallComment({commit, rootState}, {campaign_id, company_id, question_code} ){
            commit('update_is_comment_loaded', false) 
            const path = rootState.backend_host + '/read_audit_overall_comment'

            return axios.get(path, {params:{
                campaign_id: campaign_id,
                company_id: company_id,
                question_code: question_code }
            })
            .then(response=>{
                let result = response.data
                
                commit('update_overall_comment', result)
                if (result.length!=0){
                    commit('update_is_comment_loaded', true) 
                }
            })
        },
        readAllPreviousCampaignScores({state, commit, rootState}, campaign_id){
            commit('update_is_previous_score_loaded', false)
            
            let path = ''
            if (rootState.template_tree.template_scoring == 'auto'){
                path = rootState.backend_host + '/read_all_scores_by_campaign_id'
            }
            else if (rootState.template_tree.template_scoring == 'manual'){
                path = rootState.backend_host + '/evaluate_result'
            } 
            // console.log(path);                      

            return axios.get(path, { params: {
                campaign_id: campaign_id.campaign_id,
                aggregate: 'question'
            }})
            .then(response=>{
                //[ { campaign_id, template_code, company_id, company_name, question_code, question_name, num_of_calculated_subs, scoring, score_result }]
                //Question별로 Score는 100점 만점 기준으로 환산되서 들어옴.
                let result = response.data
                // console.log(state.previous_temp_target_companies);
                state.previous_temp_target_companies.forEach(company_item => {                    
                    
                    let new_template_contents = ih.deepCopy(state.previous_local_template_tree_contents)
                    //DB에서 읽어온 문항별 점수 값을 new_template_contents 각 노드에 넣어줌
                    th.breadthFirstSearch(new_template_contents, node => {
                        for(let i=0; i<result.length; i++){
                            if(node.question_code == result[i].question_code && company_item.company_name == result[i].company_name){
                                node.num_of_calculated_subs = result[i].num_of_calculated_subs
                                node.score_result = result[i].score_result
                            }
                        }
                    })                    
                    
                    //평균 점수 산정
                    calculateAllScore(new_template_contents)
                    commit('set_previous_score_contents', { company_id: company_item.company_id, new_template_contents} )

                    //총점 점수 산정
                    commit('set_previous_total_score', { company_id: company_item.company_id, total_score: calculateTotalScore(new_template_contents) })

                    let category_score = {}
                    //카테고리 점수 산정
                    new_template_contents.forEach(item => {
                        if(item.score_result){category_score[item.question_name] = item.score_result}  
                    })
                    commit('set_previous_category_score', {company_id: company_item.company_id, category_score })
                })
                commit('update_is_previous_score_loaded', true)
                                
            })
            .catch(error => {
                console.log(error)
            })
        },
        readReleases({state, commit, rootState}, campaign_id){
            const path = rootState.backend_host + '/check_release'

            return axios.get(path, { params: {
                campaign_id: campaign_id
                // [ { 'company_id', 'release' : True, 'time_stamp: 언제 } ] 배열 반환. False인 애는 반환 자체를 안함
            }})
            .then( response => {
                // console.log(response.data)
                commit('set_releases', response.data )
            })
            .catch(error => {
                console.log(error)
            })
        },
        saveRelease({state, commit, rootState}, { campaign_id, company_id, new_release }){
            const path = rootState.backend_host + '/save_release'

            let formData = new FormData()
            formData.append('campaign_id', campaign_id)
            formData.append('company_id', company_id)
            formData.append('new_release', new_release)

            return axios.post(path, formData)
            .then( response => {
                // response.data = { id, campaign_id, company_id, time_stamp, release: true or false }
                commit('set_release', { company_id: response.data.company_id, new_release: response.data.release })
            })
        },
        
    }

}
// function getFactor(q_code, result){
//     let factors = []
//     result.forEach(item => {
//         if (item.question_code == q_code){
//             factors.push(item)
//         }
//     })
//     return factors
// }

function calculateAllScore(tree){
    th.breadthFirstSearch(tree, (node, parent) =>{
        if(node.children && node.children.length!=0){
            let temp_score = 0
            let num_of_q = 0
            node.children.map(x => { 
                if(x.hasOwnProperty('score_result') && x.score_result != null) 
                {
                    temp_score = temp_score + x.score_result 
                    num_of_q += 1
                }
            })
            if(!node.hasOwnProperty('score_result') && num_of_q == 0){
                node.score_result = null
            }
            else if(!node.hasOwnProperty('score_result') && num_of_q > 0){
                node.score_result = temp_score / num_of_q
            }
            else if(node.hasOwnProperty('score_result') && num_of_q == 0 ){
                node.score_result = node.score_result
            }
            else {
                node.score_result = temp_score / num_of_q
            }
            if ( parent != undefined ) calculateAllScore(parent)
        }
    })   
}

function calculateTotalScore(score_contents){
    // console.log(score_contents)
    let total_score = 0
    let temp_total_score = 0
    let number_of_q = 0
    for(let i=0; i<score_contents.length; i++){
        if(score_contents[i].score_result != null && score_contents[i].score_result != undefined){
            temp_total_score += score_contents[i].score_result
            number_of_q += 1
        }
    }
    if (!Number.isNaN(temp_total_score / number_of_q)){
        total_score = temp_total_score / number_of_q
    }
    return total_score
}

function calculateTotalScoreGSC1(score_contents){
    let total_score = 0
    let temp_total_score = 0
    let number_of_q = 0

    let weight = [40, 25, 19, 16]
    for(let i=0; i<score_contents.length; i++){
        if(score_contents[i].score_result != null && score_contents[i].score_result != undefined){
            temp_total_score += score_contents[i].score_result * weight[i]
            number_of_q += weight[i]
        }
    }
    if (!Number.isNaN(temp_total_score/number_of_q)){
        total_score = temp_total_score/number_of_q
    }
    return total_score
}

function calculateTotalScoreGSC2(score_contents){
    let total_score = 0
    let temp_total_score = 0
    let number_of_q = 0

    let weight = [52, 25, 3, 20]
    for(let i=0; i<score_contents.length; i++){
        if(score_contents[i].score_result != null && score_contents[i].score_result != undefined){
            temp_total_score += score_contents[i].score_result * weight[i]
            number_of_q += weight[i]
        }
    }
    if (!Number.isNaN(temp_total_score /number_of_q)){
        total_score = temp_total_score/number_of_q
    }
    return total_score
}

function calculateTotalScoreKTG(score_contents){
    //score_contents
    //console.log(score_contents)
    let total_score = 0
    let temp_total_score = 0
    let number_of_q = 0
    for (let i=0; i<score_contents.length - 1; i++){
        if(score_contents[i].score_result != null && score_contents[i].score_result != undefined){
            temp_total_score += score_contents[i].score_result
            number_of_q += 1
        }
    }
    if (!Number.isNaN(temp_total_score / number_of_q)){
        total_score = temp_total_score / number_of_q
    }

    //가점
    let last = score_contents.length - 1
    if(!Number.isNaN(score_contents[last])){
        if(score_contents[last].score_result == 0 ){
            total_score
        }
        else if(score_contents[last].score_result > 0 && score_contents[last].score_result <= 33.3 ) {
            total_score += 1
        }
        else if( score_contents[last].score_result > 33.3 && score_contents[last].score_result <= 66.7){
            total_score += 2
        }
        else if (score_contents[last].score_result > 66.7){
            total_score +=3
        }
    }
    return total_score
}

function calculateTotalScoreHMCS(company_id, score_contents){
    //여기서 score_contents는 front에 임의로 정의된 점수표
    //[{ company_id: xxx, total_score: xxx }, ... ]
    //console.log(score_contents)

    let idx = score_contents.findIndex(item => { return item.company_id == company_id}  )
    if(idx > -1){
        return score_contents[idx].total_score
    }
    else{
        return null
    }
}


function max(target_scores) {
    //점수 재료 배열을 받아서 최고점 반환 
    let temp_array = []
    target_scores.forEach( item => {
        if(item != undefined && item != null && Number.isNaN(item)==false){
            temp_array.push(item)
        }
    })
    let temp = Math.round(Math.max.apply(null, temp_array) * 10)/10
    return (!Number.isNaN(temp) && temp != Infinity && temp != -Infinity ? temp : '-') 
}

function min(target_scores) {
    //점수 재료 배열을 받아서 최저점 반환
    let temp_array = []
    target_scores.forEach( item => {
        if(item != undefined && item != null && Number.isNaN(item)==false){
            temp_array.push(item)
        }
    })
    let temp = Math.round(Math.min.apply(null, temp_array) * 10)/10
    return (!Number.isNaN(temp) && temp != Infinity && temp != -Infinity ? temp : '-') 
}

function average(target_scores) {
    //점수 재료 배열을 받아서 평균점 반환
    let sum = 0
    let num_of_q = 0
    for (var i=0; i<target_scores.length; i++) {
        if(target_scores[i] != undefined && target_scores[i] != null && Number.isNaN(target_scores[i])==false)
        {
            sum += target_scores[i]
            num_of_q += 1
        }
    }
    let temp = Math.round(sum/num_of_q * 10)/10
    if(!Number.isNaN(temp)) { return temp }
    else { return '-' }
}

function median(target_scores) {
    //점수 재료 배열을 받아서 평균점 반환
    let temp_array = []
    target_scores.forEach( item => {
        if(item != undefined && item != null && Number.isNaN(item)==false){
            temp_array.push(item)
        }
    })
    temp_array.sort(function(a, b) {
    return a - b;
    });
    let mid = temp_array.length / 2;
    return mid % 1 ? Math.round(temp_array[mid - 0.5]*10)/10 : Math.round((temp_array[mid - 1] + temp_array[mid]) / 2 *10)/10;
}

export default questions